import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Tab, Tabs, Typography, Modal, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import { baseUrl } from "./modules/general";
import AlertMessage from "./modules/AlertMessage";
import avatar from '../image/dashboard/avatar.png';
import { setCanonicalTag } from "./modules/general";
import Loader from "./Loader";

let dataHead = [];
let dataSelect = [];
const NewUser = () => {

  const { t, i18n } = useTranslation();
  const [TabSize, setTabSize] = useState(window.innerWidth <= 600 ? "vertical" : "horizontal");
  const [value, setValue] = useState("2");
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState(null);
  const [btnActivation, setBtnActivation] = useState(false)
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ alertCheck: false, message: "", severity: "" });
  const headerPersonal = ["number", "name&fullName", "mobile"];
  const headerOrganization = ["number", "nameOrganization", "mobile"];

  useEffect(() => {
    document.title = "کاربران جدید در بهترین سایت طراحی نرم افزار در ایران | وب گستران سورین";
    document.getElementById("main-heading").innerHTML ="کاربران جدید در بهترین سایت طراحی نرم افزار در ایران | وب گستران سورین";
    document.getElementsByTagName('meta')["description"].content = "پنل پشتیبان وب گستران سورین جهت تایید کاربران جدیدالورود : کاربرانی که در سایت سورین ثبت نام کرده اند و درخواست سفارش طراحی وب سایت توسط وب گستران سورین را دارند.";
    document.getElementsByTagName('meta')["keywords"].content = "صفحه کاربران جدید در بهترین شرکت طراحی وب سایت | ";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => resizeTab());
    return (() => window.removeEventListener('resize', () => resizeTab()));
  }, [window.innerWidth]);

  useEffect(() => {
    getNewUser();
  }, [])

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const modalHandeler = (item) => {
    dataSelect = item;
    handleOpenModal();
  }

  const clickOtherLocation = (e) => {
    if (e.target.id === 'modal-status') {
      handleCloseModal();
    }
  }

  const activation = (id) => {
    setBtnActivation(true)
    setLoading(true)
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    let data = { user_id: id }
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/user/active',
      method: "POST",
      data: data,
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        getNewUser();
        setBtnActivation(false)
        setLoading(false)
        setAlert({ alertCheck: true, message: t("AM_activationSuccess"), severity: "success" })
      })
      .catch((error) => {
        const { status } = error.response;
        switch (status) {
          case (500):
            setAlert({ alertCheck: true, message: t("AM_intervalServer"), severity: "error" })
            break;
          case (400):
            setAlert({ alertCheck: true, message: t("alert_defualt"), severity: "error" })
            break;
          case (404):
            setAlert({ alertCheck: true, message: t("notFoundUser"), severity: "error" })
            break;
        }
        setBtnActivation(false)
        setLoading(false)
      })
  }

  const getNewUser = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/user/new`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setUser(res.data.data)
      })
  }

  const resizeTab = () => {
    if (window.innerWidth < 600 && TabSize === 'horizontal') {
      setTabSize('vertical')
    }
    else if (window.innerWidth > 600 && TabSize === 'vertical') {
      setTabSize('horizontal')
    }
  }

  const head = () => {
    let list = [];
    dataHead = value === "2" ? headerPersonal : headerOrganization;
    dataHead.forEach((item, index) => {
      list.push(
        <Grid key={index} sx={{ display: { xs: item === "number" ? "none" : "flex", sm: "flex" }, height: { xs: "60px", sm: "15%" }, alignItems: "center", justifyContent: "center", width: { xs: "50%", sm: "20%" } }}>
          <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px" }, color: "#070707", fontWeight: "700", textAlign: 'center', }}>{t(item)}</Typography>
        </Grid>
      )
    })
    return list;
  }

  const dataGrid = () => {
    let list = [];
    if (user === null) {
      list.push(
        <Loader key={"grid1"} />
      )
    }
    else if (user.length === 0) {
      list.push(
        <Typography key={"grid2"} sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707" }}>{t("notUser")}</Typography>
      )
    }
    else if (user !== null && user.length > 0) {
      user.forEach((item) => {
        if (+value === item.user_type_id) {
          list.push(
            <Grid key={item.id} onClick={() => modalHandeler(item)} sx={{
              display: 'flex', alignItems: "center", justifyContent: 'center', width: "100%", backgroundColor: parseInt(item.number) % 2 === 0 ? "#FFFFFF" : "#EDEDED",
              border: "1px solid #757575", borderRadius: "8px", margin: { xs: "15px 5px 0 0", sm: "15px 0 0 0" }, cursor: "pointer", "&:hover": { boxShadow: " 0px 8px 3px 2px rgba(0, 0, 0, 0.25)" }
            }}>
              <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-evenly", width: "100%", height: "40px", flexDirection: "row", }}>
                {dataHead.map((value, index) => (
                  value === "number" ?
                    <Grid key={index} sx={{ display: { xs: value === "number" ? "none" : "flex", sm: "flex" }, alignItems: "center", justifyContent: "center", width: { xs: "50%", sm: "20%" }, height: { xs: "60px", sm: "unset" } }}>
                      <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{index + 1}</Typography>
                    </Grid>
                    :
                    value === "name&fullName" ?
                      <Grid key={index} sx={{ display: { xs: value === "number" ? "none" : "flex", sm: "flex" }, alignItems: "center", justifyContent: "center", width: { xs: "50%", sm: "20%" }, height: { xs: "60px", sm: "unset" } }}>
                        <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{item.name !== null ? item.name + item.lastname : t("NotCompleted")}</Typography>
                      </Grid>
                      :
                      value === "nameOrganization" ?
                        <Grid key={index} sx={{ display: { xs: value === "number" ? "none" : "flex", sm: "flex" }, alignItems: "center", justifyContent: "center", width: { xs: "50%", sm: "20%" }, height: { xs: "60px", sm: "unset" } }}>
                          <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{item.organ_name !== null ? item.organ_name : t("NotCompleted")}</Typography>
                        </Grid>
                        :
                        <Grid key={index} sx={{ display: { xs: value === "number" ? "none" : "flex", sm: "flex" }, alignItems: "center", justifyContent: "center", width: { xs: "50%", sm: "20%" }, height: { xs: "60px", sm: "unset" } }}>
                          <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{t(item[value] !== null ? item[value] : "NotCompleted")}</Typography>
                        </Grid>
                ))}
              </Grid>
            </Grid>
          )
        }
      })
    }
    list.length === 0 && list.push(<Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707" }}>{t("notUser")}</Typography>)
    return list;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };
  return (
    <>
      <Grid sx={{
        display: 'flex', width: "100%", height: "100%", flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
        direction: i18n.language === "fa" ? "rtl" : 'ltr'
      }}>
        <Grid sx={{ display: 'flex', width: "95%", alignItems: 'center', justifyContent: 'center', marginTop: { xs: "0", sm: "20px", md: "90px" }, }}>
          <Tabs
            value={value}
            onChange={handleChange}
            orientation={TabSize}
            sx={{
              width: "100%", minHeight: { xs: "40px !important", sm: "45px !important" },
              '& .Mui-selected': { color: "#FFFFFF !important", background: "linear-gradient(0deg, #FF3951 0%, #FF3951 22%, #FF4057 22.01%, #FF6F80 53%, #FFA7B2 69%, #FFB9C2 76%, #FEB9C2 86%, #FFA7B2 100%) !important", zIndex: "400" }
              , '& .MuiTabs-indicator': { backgroundColor: "transparent" }
              , "& .MuiTab-root": {
                fontSize: i18n.language === "fa" ? { xs: "11px", sm: "12px", md: "12px", lg: "14px", xl: "18px" } : { xs: "10px", sm: "12px", md: "16px" }, fontWeight: "700", width: { xs: "105px", sm: "25%", md: "23%", lg: "18%" }, color: "#757575",
                background: " linear-gradient(90deg, #D1D1D1 0%, #A8A8A8 100%)", borderRadius: { xs: "45px 45px 0 0", sm: "50px 50px 0 0", lg: "100px 100px 0 0" }, border: "1px solid #CCCCCC"
              }
            }}
            aria-label="visible arrows tabs example"
          >
            <Tab value="2" label={t("personalPanel")}
              sx={{ position: "absolute", right: i18n.language === "fa" ? { xs: "5px", sm: "0px" } : "unset", left: i18n.language === "en" ? { xs: "5px", sm: "0px" } : "unset", zIndex: "300", textTransform: "lowercase" }} />
            <Tab value="1" label={t("organizationPanel")}
              sx={{ position: "absolute", right: i18n.language === "fa" ? { xs: "90px", sm: "18%", md: "16%" } : "unset", left: i18n.language === "en" ? { xs: "90px", sm: "18%", md: "16%" } : "unset", zIndex: "250", textTransform: "lowercase" }} />
          </Tabs>
        </Grid>
        <Grid sx={{
          display: 'flex', alignItems: "center", justifyContent: 'center', width: "95%", height: { xs: "43px", sm: "69px" }, backgroundColor: "#FFFFFF",
          border: "1px solid #CBCBCB", borderRadius: { xs: "8px", sm: "0 0 8px 8px" }
        }}>
          <Grid sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: "space-evenly", width: "100%", flexDirection: "row", overflow: { xs: "scroll", sm: "unset" } }}>
            {head()}
          </Grid>
        </Grid>
        <Grid sx={{
          display: 'flex', alignItems: "center", justifyContent: 'flex-start', width: "95%", height: "100%", margin: { xs: "5px 0 0 5px", sm: "15px 0 0 0" }
          , flexDirection: "column"
        }}>
          {dataGrid()}
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid id="modal-status" onClick={(e) => clickOtherLocation(e)} sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh", backgroundColor: 'rgba(105,99,99,0.6)', direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
          <Grid sx={{
            display: "flex", alignItems: "center", justifyContent: "space-evenly", flexDirection: 'column', background: "#FFFFFF", width: { xs: "95%", sm: "674px" },
            borderRadius: "16px", padding: { xs: "25px 0", md: "30px 0" }
          }}>
            <Grid sx={{ display: value === "2" ? "flex" : "none", alignItems: "center", backgroundColor: "#EDEDED", width: { xs: "95%", sm: "616px" }, borderRadius: "16px", flexDirection: "column" }}>
              <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "flex-start", flexDirection: "row", width: "95%", padding: "15px 0" }}>
                <Typography alt="avatar"
                  sx={{
                    width: { xs: "69px", sm: "90px" }, height: { xs: "69px", sm: "90px" }, margin: "0 10px", borderRadius: "50px"
                    , background: `url(${dataSelect.picture_path !== null ? dataSelect.picture_path : avatar})`, backgroundPosition: "center", backgroundSize: "115% 115%", backgroundRepeat: "repeat",
                  }} ></Typography>
                <Typography sx={{ fontSize: { xs: "14px", sm: "24px" }, margin: "0 10px", fontWeight: "700" }}>{dataSelect.name !== null ? dataSelect.name + dataSelect.lastname : t("NotCompleted")}</Typography>
              </Grid>
              <Grid sx={{ width: "90%", display: "flex", height: '1px', backgroundColor: "#CBCBCB" }}></Grid>
              <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "70%", flexDirection: "row", marginTop: "10px" }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <Typography sx={{ fontSize: { xs: "14px", sm: "20px" }, fontWeight: "700", color: "#00000" }}>{t("phoneNumber")}</Typography>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "18px" }, color: "#000000" }}>{dataSelect.mobile}</Typography>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <Typography sx={{ fontSize: { xs: "14px", sm: "20px" }, fontWeight: "700", color: "#00000" }}>{t("activity")}</Typography>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "18px" }, color: "#000000" }}>{dataSelect.hasOwnProperty("activity") && dataSelect !== null && dataSelect.activity !== null ? dataSelect.activity.description : t("NotCompleted")}</Typography>
                </Grid>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", width: "80%", margin: "20px 0" }}>
                <Typography sx={{ fontSize: { xs: "14px", sm: "20px" }, fontWeight: "700", color: "#00000" }}>{t("fieldAddress")}</Typography>
                <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#000000" }}>{dataSelect.address !== null ? dataSelect.address : t("NotCompleted")}</Typography>
              </Grid>
            </Grid>
            <Grid sx={{ display: value === "1" ? "flex" : "none", alignItems: "center", backgroundColor: "#EDEDED", width: { xs: "90%", sm: "616px" }, borderRadius: "16px", flexDirection: "column" }}>
              <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "flex-start", flexDirection: "row", width: "95%", padding: "15px 0" }}>
                <Typography alt="avatar"
                  sx={{
                    width: { xs: "69px", sm: "90px" }, height: { xs: "69px", sm: "90px" }, margin: "0 10px", borderRadius: "50px"
                    , background: `url(${dataSelect.picture_path !== null ? dataSelect.picture_path : avatar})`, backgroundPosition: "center", backgroundSize: "115% 115%", backgroundRepeat: "repeat",
                  }} ></Typography>
                <Typography sx={{ fontSize: { xs: "14px", sm: "20px" }, margin: "0 10px", fontWeight: "700" }}>{dataSelect.organ_name !== null ? dataSelect.organ_name : t("NotCompleted")}</Typography>
              </Grid>
              <Grid sx={{ width: "90%", display: "flex", height: '1px', backgroundColor: "#CBCBCB" }}></Grid>
              <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-evenly", width: "70%", flexDirection: "row", margin: "20px 0" }}>
                <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", width: "50%" }}>
                  <Typography sx={{ fontSize: { xs: "14px", sm: "20px" }, fontWeight: "700", color: "#00000" }}>{t("nameOfCEO")}</Typography>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "18px" }, color: "#000000" }}>{dataSelect.organ_manager_name !== null ? dataSelect.organ_manager_name : t("NotCompleted")}</Typography>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", width: "50%" }}>
                  <Typography sx={{ fontSize: { xs: "14px", sm: "20px" }, fontWeight: "700", color: "#00000" }}>{t("nameOrganizationLegalRepresentative")}</Typography>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "18px" }, color: "#000000" }}>{dataSelect.organ_legal_representative_name !== null ? dataSelect.organ_legal_representative_name : t("NotCompleted")}</Typography>
                </Grid>
              </Grid>
              <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: 'space-evenly', width: "70%", flexDirection: "row" }}>
                <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", width: "50%" }}>
                  <Typography sx={{ fontSize: { xs: "14px", sm: "20px" }, fontWeight: "700", color: "#00000" }}>{t("activity")}</Typography>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "18px" }, color: "#000000" }}>{dataSelect.hasOwnProperty("activity") && dataSelect.activity !== null ? dataSelect.activity.description : t("NotCompleted")}</Typography>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", width: "50%" }}>
                  <Typography sx={{ fontSize: { xs: "14px", sm: "20px" }, fontWeight: "700", color: "#00000" }}>{t("phoneNumber")}</Typography>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "18px" }, color: "#000000" }}>{dataSelect.mobile}</Typography>
                </Grid>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", width: "80%", margin: "20px 0" }}>
                <Typography sx={{ fontSize: { xs: "14px", sm: "20px" }, fontWeight: "700", color: "#00000" }}>{t("fieldAddress")}</Typography>
                <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#000000" }}>{dataSelect.address !== null ? dataSelect.address : t("NotCompleted")}</Typography>
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: 'row', width: "100%", paddingTop: '20px' }}>
              <Button onClick={() => activation(dataSelect.id)} disabled={btnActivation ? true : false} sx={{
                width: { xs: "100px", sm: "110px" }, height: { xs: "33px", sm: "40px" }, boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", fontSize: { xs: "14px", sm: "16px" },
                background: "#FF3951", color: "#FFFFFF", "&:hover": { background: "#FF3951" }, margin: "0 10px"
              }}>
                {btnActivation === true && loading === true ? <CircularProgress size={20} color="inherit" sx={{ color: "#ffffff" }} /> : t("activation")}
              </Button>
              <Button onClick={handleCloseModal} sx={{
                width: { xs: "100px", sm: "110px" }, height: { xs: "33px", sm: "40px" }, boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", fontSize: { xs: "14px", sm: "16px" }, border: "2px solid #FF3951",
                background: "#FFFFFF", color: "#FF3951", "&:hover": { background: "#FFFFFFF" }, margin: "0 10px"
              }}>
                {t("cancel")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal >
      <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
    </>
  )
}

export default NewUser;