import { SvgIcon } from '@mui/material';
import React from 'react';

const StatusIcon = ({ color }) => {
  return (
    <SvgIcon component="object" sx={{height:"30px"}}>
      <svg width="22" height="22" viewBox="0 0 20 20" fill="transparent" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.275 17.25L17.75 13.8L16.7 12.75L14.275 15.125L13.3 14.15L12.25 15.225L14.275 17.25ZM3 6H15V4H3V6ZM15 20C13.6167 20 12.4373 19.5123 11.462 18.537C10.4867 17.5617 9.99933 16.3827 10 15C10 13.6167 10.4877 12.4373 11.463 11.462C12.4383 10.4867 13.6173 9.99933 15 10C16.3833 10 17.5627 10.4877 18.538 11.463C19.5133 12.4383 20.0007 13.6173 20 15C20 16.3833 19.5123 17.5627 18.537 18.538C17.5617 19.5133 16.3827 20.0007 15 20ZM0 19V0H18V8.675C17.6833 8.525 17.3583 8.4 17.025 8.3C16.6917 8.2 16.35 8.125 16 8.075V2H2V16.05H8.075C8.15833 16.5667 8.28767 17.0583 8.463 17.525C8.63833 17.9917 8.86733 18.4333 9.15 18.85L9 19L7.5 17.5L6 19L4.5 17.5L3 19L1.5 17.5L0 19ZM3 14H8.075C8.125 13.65 8.2 13.3083 8.3 12.975C8.4 12.6417 8.525 12.3167 8.675 12H3V14ZM3 10H10.1C10.7333 9.38333 11.471 8.89567 12.313 8.537C13.155 8.17833 14.0507 7.99933 15 8H3V10Z" fill={color} />
      </svg>
    </SvgIcon>
  );
};
export default StatusIcon;