import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import { author, date, h1, headPhoto, largePhoto, linkArticle, rootArticle, tags, paragraph, timeRead, view, listItemCircle, h2, h4, smallPhoto, sourceLink } from "../articleFunction/ArticleFunction";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { Articles } from "./Articles";
import ArticleSide from "../articleFunction/ArticleSide";

//import photo
import photo1 from '../../image/articles/05/photo1.jpg';
import photo2 from '../../image/articles/05/photo2.jpg';
import photo3 from '../../image/articles/05/photo3.jpg';
import photo4 from '../../image/articles/05/photo4.jpg';
import photo5 from '../../image/articles/05/photo5.jpg';
import Loader from "../Loader";
import PortfolioButton from "../PortfolioButton";


let articleData = null;
const Article05 = () => {

  //NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${articleData !== null && articleData.title}`
    document.getElementById("main-heading").innerHTML = articleData !== null && articleData.h1;
    document.getElementsByTagName('meta')["description"].content = articleData !== null && articleData.desc;
    document.getElementsByTagName('meta')["keywords"].content = articleData !== null && articleData.keywords;
    document.getElementsByTagName('meta')["author"].content = articleData !== null && articleData.author;
    setCanonicalTag()
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item
    })
  }, [])

  useEffect(() => {
    articleData !== null && addView(); getView();
  }, [])

  const addView = () => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v1/article/addView",
      method: "POST",
      data: { id: articleData.id }
    }))
      .then((res) => { })
  }
  const getView = () => {
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/article/getView`,
      method: "GET",
      params: { id: articleData.id }
    }))
      .then((res) => {
        setDataView(res.data.data)
      })
  }

  //change This part

  //read More
  const readMore = [
    {
      id: 1,
      title: "نکاتی که باید برای سفارش طراحی وب سایت در نظر گرفت",
      link: "/Points_to_consider_when_ordering_a_website_design"
    },
    {
      id: 2,
      title: "بهترین لوگوهای جهان",
      link: "/the-best-logos-in-the-world"
    }

  ]
  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: "rtl", alignItems: "center" }}>
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid container sx={{ display: "flex", alignItems: { xs: "unset", sm: 'flex-start' }, justifyContent: "space-between", flexDirection: "row", flexWrap: "wrap", width: { xs: "100%", sm: "85%" } }}>
          <ArticleSide tags={articleData !== null && articleData.tags} readMore={readMore} />
          {
            articleData !== null ?
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: 'center', sm: "flex-end" }, flexDirection: "column", width: { xs: "100%", sm: "60%", md: "70%" } }} >
                {/* do not change */}

                {headPhoto(articleData.image)}
                {h1(articleData.h1, t)}
                {timeRead(articleData.timeRead, t)}
                {date(articleData.date, i18n)}
                <Grid sx={{ display: 'flex', alignItems: { xs: "center", sm: 'flex-start' }, justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "column" }}>
                  {/* The text of the article */}
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    مقدمه
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    با توجه به پیشرفت تکنولوژی و افزایش روزانه استفاده از اینستاگرام، خیلی از افراد به فکر ایجاد یک پیج کسب و کار در <Typography component={"a"} target="_blank" href="https://www.instagram.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >اینستاگرام </Typography>
                    هستند. البته برخی از صاحبان کسب و کار نیز برای گسترش کسب و کار خود و افزایش فروش، با راه اندازی پیج کاری؛ به کار خود رونق می بخشند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    ما در این مقاله از سورین بر آنیم تا صفر تا صد راه اندازی پیج کاری اصولی و حرفه ای را برای شما بیان کنیم. پس با ما همراه باشید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    علت استفاده از پیج کاری در اینستاگرام
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo1} alt="استفاده از پیج کاری در اینستاگرام - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        پیج کاری در اینستاگرام
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    با توجه به مشغله های امروزی، افراد دیگر مانند گذشته نمی توانند زمان زیادی را صرف گشتن و خرید کالا یا خدمات مورد نیاز خود کنند. از طرفی اجاره بالای مغازه ها و هزینه های سنگینی که برای داشتن یک مکان مشخص برای ارائه محصولات نیاز است افراد را به پیج های کسب و کار و تجاری در اینستاگرام سوق می دهد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    البته ممکن است گاهی افراد تصور کنند که راه اندازی پیج نیازی به تخصص خاصی ندارد اما اصلا اینطور نیست. قبل از تاسیس پیج باید پاسخ یکسری از سوالات را در خود پیدا کرده باشید. اینکه هدف از راه اندازی پیج اینستاگرام چیست؟ آیا زمان کافی برای مدیریت و رسیدگی به پیج کاری را دارید؟ آیا حاضر به هزینه کردن جهت رسیدن به اهدافتان هستید؟ کسب و کار شما شامل چه نوع کالاها و خدماتی می باشد که بتوانید عکس های جذابی از آن در داخل سایت قرار دهید؟ و اینکه در آینده چه انتظاری از سایت خود دارید؟
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    این ها و سوالات مشابه آن سوالاتی هستند که قبل از شروع یک پیج کاری باید به آن جواب دهید تا بتوانید با بررسی آن یک پیج حرفه ای کسب و کار راه اندازی کنید.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در اینجا این نکته را به شما یادآوری می کنیم که چنانچه تایم کافی برای فعالیت در اینستاگرام ندارید بهتر است سراغ اینستاگرام نروید. با توجه به رقیبان بسیاری که در اینستاگرام وجود دارد درصد موفقیت شما تقریبا صفر می شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    صفر تا صد پیج کاری اینستاگرام
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بعد از ایجاد حساب کاربری در اینستاگرام باید تمام نکاتی که برای دستیابی به پیج ایده آل نیاز است را رعایت فرمایید. در زیر به بررسی این موارد می پردازیم.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تکمیل حرفه ای بخش مشخصات
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    اولین قسمتی که بعد از راه اندازی پیج کسب و کار باید مورد توجه قرار گیرد قسمت بیو اینستاگرام می باشد. این قسمت بیان کننده فعالیت شما می باشد. برای بیو اینستاگرام خود بدون هیچ توضیح اضافی کسب و کار خود را معرفی کنید و بهتر است از فونت خاص و ایموجی برای جذب مخاطب بهره ببرید. بنابراین تکمیل درست و حرفه ای آن در جذب مخاطب و ماندگاری مشتری تاثیر زیادی دارد.
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo2} alt="تکمیل بخش مشخصات - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        تکمیل بخش مشخصات
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    انتخاب نام کاربری مناسب
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    از آنجا که نام کاربری در نتایج جست و جو نشان داده می شود در نتیجه انتخاب نامی مناسب باعث افزایش ویوی پیج می شود. بهتر است نامی که انتخاب می کنید در عین کوتاهی و سادگی نامی باشد که در ذهن ماندگار شود. همچنین می توانید از اسم برند فعالیت خود به عنوان نام کاربری پیج استفاده کنید. اما اگر نام خاصی در ذهنتان ندارید می توانید از نام خود به همراه زمینه کاریتان برای نام کاربری پیجتان استفاده کنید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    نام مناسب در بیو
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بهتر است نامی که در بیو انتخاب می شود مرتبط با زمینه فعالیت و حوزه کاری باشد. بدین ترتیب هنگام جستجو با نام و یا زمینه فعالیتی که دارید پیج به مخاطب نشان داده شود. همچنین این نام ها در لیست دایرکت افراد نشان داده می شود.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تبدیل اکانت به اکانت بیزینسی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    اینستاگرام امکانات ویژه ای را به اکانت های تجاری اختصاص داده است. بنابراین برای پیشبرد بهتر اهدافتان بهتر است که اکانت خود را به بیزینسی تغییر دهید. با انجام این کار از مخاطبین و سن و سال آنها و همچنین فعالیت مخاطب مطلع می شوید و متوجه خواهید شد که گذاشتن چه محتوایی برای جذب مخاطب پیج بهتر است. تمامی این موارد برای رسیدن شما به اهدافتان کمک شایانی می کند.
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo3} alt="اکانت بیزینسی - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        اکانت بیزینسی
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    بیوگرافی مرتبط
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در این قسمت باید در کوتاهترین جمله کسب و کار خود را معرفی کنید. بیوگرافی تاثیر فراوانی در ماندگاری پیج در ذهن مخاطبین دارد. برای نوشته بیوگرافی باید به این نکات توجه کنید:
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width: "100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    تعریفی از کالا یا خدماتی که ارائه می دهید داشته باشید.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width: "100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    انتظاراتی که از مشتری دارید را مشخص کنید.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width: "100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    از کلمات کلیدی مرتبط با حوزه کسب و کار خود استفاده کنید.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width: "100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    با توجه به مخاطب هدف، لحن بیان و نوع نگارش را انتخاب نمایید.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width: "100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    از تعدادی ایموجی برای جذب کاربر بهره ببرید.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width: "100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    یک call to action در بیوگرافی قرار دهید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    افزودن اطلاعات تماس
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در ابتدای پیج ممکن است مشتری کمتر به پیج شما اعتماد کند. قرار دادن شماره تماس و آدرس ایمیل و وب سایت سبب می شود که مشتریان راحت تر به کسب و کارتان اعتماد کنند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    افزودن لینک
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در بیو اینستاگرام شما تنها قادرید که یک لینک در قسمت وب سایت اضافه کنید. اما چنانچه تمایل دارید که لینک های بیشتری قرار دهید می توانید از ربات های لینک ساز استفاده کنید. این ربات ها دارای این قابلیت هستند که تمامی لینک های شما را در یک لینک قرار می دهند. با کلیک روی هر کدام از این لینک ها مشتری به صفحه مورد نظر هدایت می شود.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تنظیم هایلات ها
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای مخاطبین دیدن استوری جذاب تر از پست می باشد. بنابراین داشتن استوری های روزانه و مرتبط با فعالیت کاری تاثیر بیشتری بر افزایش مخاطبین دارد. داشتن هایلات این امکان را فراهم می سازد که مشتریان پیج و نیازهایشان شاسایی شود. بنابراین به سوالاتی که ممکن است در ورود به پیج برایشان پیش آید در هایلات پاسخ دهید.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    موضوعاتی که بهتر است در هایلات قرار گیرد، در زیر به آن اشاره می کنیم:
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width: "100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    چنانچه کالا یا خدماتی ارائه می کنید در یک هایلات تحت عنوان رضایت مشتری، نظرات مشتریان را برای سایر مخاطبین نشان دهید.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width: "100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    اگر صاحب کسب و کار هستید یک هایلات برای معرفی محصولات و خدمات داشته باشید.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width: "100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    برخی از پست های پر بازدید خود را برای دسترسی سریع تر در هایلات قرار دهید.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width: "100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    می توانید آموزش ها و نکات کاربری کوتاه را با عناوین جذاب هایلات کنید.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width: "100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    نکات ضروری برای استفاده از خدمات و یا محصولات را در هایلات آموزش دهید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    نکاتی که در مورد پیج کاری باید در نظر داشت
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای داشتن پیج کاری حرفه ای رعایت یکسری اصول از ضروریات است که ما در زیر به این نکات می پردازیم:
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    مشخص کردن زمینه فعالیت
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo4} alt="زمینه فعالیت اینستاگرام - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        زمینه فعالیت اینستاگرام
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    این که برای چه منظوری می خواهید پیج کاری را راه اندازی کنید نکته بسیار مهمی است. بنابراین قبل از ایجاد پیج بهتر است که زمینه کاری و فعالیتتان را مشخص نمایید سپس به ایجاد پیج اقدام نمایید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تعیین اهداف
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    اینکه با زدن پیج به چه اهدافی می خواهید برسید. بطور یقین اهداف مورد نظر شما یک شبه محقق نمی شود بنابراین باید لیستی از اهداف کوتاه مدت و بلند مدت خود تهیه کنید. بنابراین باید فعالیت های پیج را با توجه به این اهداف مشخص کنید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    گذاشتن تبلیغ
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بعد از راه اندازی پیج پیج و گذاشتن حداقل بیست و پنج پست بهتر است برای تبلیغ اقدام کنید. البته امکان دارد با یک بار تبلیغ به نتیجه مورد نظر دست پیدا نکنید بنابراین با پیج های حرفه ای تبلیغ صحبت کنید و چند تبلیغ کارا را برای پیجتان انجام دهید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    رشد دادن پیج کاری در اینستاگرام
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    یکی از نکات مهم بعد از راه اندازی پیج کاری در اینستاگرام رشد پیج می باشد. با برنامه ریزی و صرف زمان مناسب می توانید باعث رشد پیج خود شوید و از رقیبان حوزه کاری خود پیشی بگیرید. هچنین باید اطلاعات خود را در این زمینه گسترش دهید. همچنین باید از مشتری خود شناخت کافی داشته باشید. داشتن محتوای مناسب نیز به رشد پیج کمک شایانی می کند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    سعی کنید ویترین جذابی برای پیج خود داشته باشید تا مشتری با نگاه اول جذب پیج شما شود. برای ویترین جذاب باید به هماهنگی رنگ ها توجه داشته باشید. برای این منظور بهتر است قالب اختصاصی خود را داشته باشید و با محتوای خودتان پیج را رونق ببخشید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    روش های دیده شدن در اینستاگرام
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای دیده شدن در اینستاگرام باید یکسری نکات و قوانین را بکار گرفت تا پیج پر بازدیدی داشت. در زیر به بررسی این نکات می پردازیم.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تولید محتوای جذاب
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo5} alt="تولید محتوای جذاب - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        گذاشتن محتوای جذاب
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    تولید محتوا تنها به گذاشتنن متن و عکس محدود نمی شود بلکه باید به کاربر دقیقا آن چیزی را نمایش داد که بدنبالش است. اخبار جذاب و به روز، مطالب آموزشی، مسابقه، رفع مشکلات کاربر و اطلاعات جذاب همگی از مواردی هستند که در تولید محتوای جذاب نقش تاثیر گذاری را ایفا می کنند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    ایجاد کپشن مناسب
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای داشتن کپشن مناسب و جذاب بهتر است خط اول کپشن را با کلمات هیجان انگیز و تلنگر یا پرسیدن سوال آغاز کنید تا بتوانید مخاطب را جذب کنید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    هشتگ گذاری
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    هشتگ گذاری به دو صورت انجام می پذیرد. در وهله اول باید از هشتگ هایی که ترند هستند و پست با سرچ آنها دیده می شود، استفاده کرد. در مرحله دوم باید از هشتگ های مرتبط با کسب و کار پیج استفاده کرد. نکته ای که باید در هشتگ گذاری رعایت کرد این است که بیشتر از 15 تا هشتگ در یک پست استفاده نکنید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    جذب فالوور
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بعد از راه اندازی پیج کاری برای داشتن یک کسب و کار حرفه ای نیاز به جذب فالوور می باشد. برای این امر باید چند روزی زمان بگذارید و پیج رقیبانتان را مورد بررسی قرار دهید. بدین ترتیب متوجه خواهید شد که فالوورهای آن ها چه مطالبی را دنبال می کنند. بنابراین با ایجاد محتوای مناسب و مطابق سلیقه مشتریان می توانید به افزایش فالوور در پیجتان کمک کنید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    کلام آخر
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در این مقاله سعی شد تا نکات کلیدی برای داشتن یک پیج حرفه ای کسب و کار بیان شود اما هر کدام از مطالبی که بیان شد خود شامل مباحث گسترده ای می باشد که در مقالات بعدی به طور کامل به آن می پردازیم.
                  </Typography>


                  {/* do not change */}

                  {author(articleData.author, t)}
                  {tags(articleData.tags, t)}
                  {linkArticle(t, url)}
                  {sourceLink(articleData.source, t)}
                  {view(dataview, t)}
                </Grid>
              </Grid>
              :
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "80%", sm: "60%", md: "70%" } }}>
                <Loader />
              </Grid>
          }
        </Grid>
      </Grid>
      <PortfolioButton />
      <Footer />
    </>
  );
}
export default Article05;

