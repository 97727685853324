import React from "react";
import { Grid, Typography } from "@mui/material";
import arrowRightPurple from '../image/icon/arrowRightPurple.png';
import arrowLeftPurple from '../image/icon/arrowLeftPurple.png';
import { useTranslation } from 'react-i18next';

const WhySite = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Grid sx={{
        display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: { xs: "20px", sm: "50px", md: "30px" }, direction: i18n.language === "fa" ? "rtl" : "ltr",
        position: "relative"
      }} >
        <Grid sx={{ display: "flex", flexDirection: i18n.language === "fa" ? "row" : "row-reverse", justifyContent: "center", alignItems: "center", marginBottom: { xs: "20px", sm: "35px", md: "50px" }, width: "100%" }}>
          <Typography component={"img"} alt="icon" src={arrowRightPurple} sx={{ width: { xs: "14px", sm: "39px", md: "40px", lg: "54px" }, height: { xs: "17px", sm: "53px", md: "55px", lg: "69px" }, margin: "0 3px" }} />
          <Typography component={"h2"} sx={{ fontWeight: "700", fontSize: { xs: "12px", sm: "24px", md: "32px" }, textAlign: "center", lineHeight: { xs: "17px", md: "45px" } }}>{t("whySiteTitle")}</Typography>
          <Typography component={"img"} alt="icon" src={arrowLeftPurple} sx={{ width: { xs: "14px", sm: "39px", md: "40px", lg: "54px" }, height: { xs: "17px", sm: "53px", md: "55px", lg: "69px" }, margin: "0 3px" }} />
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "90%", border: "1px solid #FF3951", borderRadius: "8px", background: "#FFFDFD", margin: { xs: "40px 0", md: "60px 0", lg: "80px 0" } }}>
          <Grid sx={{ display: "flex", justifyContent: "center", alignItems: 'center', width: "100%", height: { xs: "150px", sm: "178px" }, flexDirection: "row", borderBottom: "1px solid #FF3951" }}>
            <Grid sx={{
              display: "flex", alignItems: "center", justifyContent: "center", minWidth: { xs: "20px", sm: "39px", md: "41px", lg: "43px", xl: "45px" }, minHeight: { xs: "20px", sm: "39px", md: "41px", lg: "43px", xl: "45px" },
              border: "2px solid #8439FF", filter: "drop-shadow(0px 0px 5px #8439FF)", borderRadius: "50%", background: "#FCFAFF"
            }}>
              <Typography sx={{ color: "#8439FF", fontSize: { xs: "10px", sm: "14px", md: "16px", lg: "18px", xl: "20px" } }}>1</Typography>
            </Grid>
            <Typography component={"p"} sx={{ textAlign: "justify", fontSize: { xs: "8px", sm: "14px", lg: "16px", xl: "18px" }, width: "85%", lineHeight: { xs: "20px", md: "35px" }, fontWeight: "700", padding: "0 10px" }}>
              {t("whySiteDesc1")}
            </Typography>
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "center", alignItems: 'center', width: "100%", height: { xs: "150px", sm: "178px" }, flexDirection: "row", borderBottom: "1px solid #FF3951" }}>
            <Grid sx={{
              display: "flex", alignItems: "center", justifyContent: "center", minWidth: { xs: "20px", sm: "39px", md: "41px", lg: "43px", xl: "45px" }, minHeight: { xs: "20px", sm: "39px", md: "41px", lg: "43px", xl: "45px" },
              border: "2px solid #8439FF", filter: "drop-shadow(0px 0px 5px #8439FF)", borderRadius: "50%", background: "#FCFAFF"
            }}>
              <Typography sx={{ color: "#8439FF", fontSize: { xs: "10px", sm: "14px", md: "16px", lg: "18px", xl: "20px" } }}>2</Typography>
            </Grid>
            <Typography component={"p"} sx={{ textAlign: "justify", fontSize: { xs: "8px", sm: "14px", lg: "16px", xl: "18px" }, padding: "0 10px", width: "85%", lineHeight: { xs: "20px", md: "35px" }, fontWeight: "700" }}>
              {t("whySiteDesc2")}
            </Typography>
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "center", alignItems: 'center', width: "100%", height: { xs: "150px", sm: "178px" }, flexDirection: "row", borderBottom: "1px solid #FF3951" }}>
            <Grid sx={{
              display: "flex", alignItems: "center", justifyContent: "center", minWidth: { xs: "20px", sm: "39px", md: "41px", lg: "43px", xl: "45px" }, minHeight: { xs: "20px", sm: "39px", md: "41px", lg: "43px", xl: "45px" },
              border: "2px solid #8439FF", filter: "drop-shadow(0px 0px 5px #8439FF)", borderRadius: "50%", background: "#FCFAFF"
            }}>
              <Typography sx={{ color: "#8439FF", fontSize: { xs: "10px", sm: "14px", md: "16px", lg: "18px", xl: "20px" } }}>3</Typography>
            </Grid>
            <Typography component={"p"} sx={{ textAlign: "justify", fontSize: { xs: "8px", sm: "14px", lg: "16px", xl: "18px" }, padding: "0 10px", width: "85%", lineHeight: { xs: "20px", md: "35px" }, fontWeight: "700" }}>
              {t("whySiteDesc3")}
            </Typography>
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "center", alignItems: 'center', width: "100%", height: { xs: "150px", sm: "178px" }, flexDirection: "row" }}>
            <Grid sx={{
              display: "flex", alignItems: "center", justifyContent: "center", minWidth: { xs: "20px", sm: "39px", md: "41px", lg: "43px", xl: "45px" }, minHeight: { xs: "20px", sm: "39px", md: "41px", lg: "43px", xl: "45px" },
              border: "2px solid #8439FF", filter: "drop-shadow(0px 0px 5px #8439FF)", borderRadius: "50%", background: "#FCFAFF"
            }}>
              <Typography sx={{ color: "#8439FF", fontSize: { xs: "10px", sm: "14px", md: "16px", lg: "18px", xl: "20px" } }}>4</Typography>
            </Grid>
            <Typography component={"p"} sx={{ textAlign: "justify", fontSize: { xs: "8px", sm: "14px", lg: "16px", xl: "18px" }, padding: "0 10px", width: "85%", lineHeight: { xs: "20px", md: "35px" }, fontWeight: "700" }}>
              {t("whySiteDesc4")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default WhySite;