import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import {
  author,
  date,
  h1,
  headPhoto,
  largePhoto,
  linkArticle,
  rootArticle,
  tags,
  paragraph,
  timeRead,
  view,
  listItemCircle,
  h2,
  h4,
  smallPhoto,
  sourceLink,
} from "../articleFunction/ArticleFunction";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { Articles } from "./Articles";
import ArticleSide from "../articleFunction/ArticleSide";
import { NavLink } from "react-router-dom";
//import photo
import photo1 from "../../image/articles/16/photo1.jpg";
import photo2 from "../../image/articles/16/photo2.jpg";
import photo3 from "../../image/articles/16/photo3.jpg";
import photo4 from "../../image/articles/16/photo4.jpg";
import photo5 from "../../image/articles/16/photo5.jpg";
import photo6 from "../../image/articles/16/photo6.jpg";
import photo7 from "../../image/articles/16/photo7.jpg";


import Loader from "../Loader";
import PortfolioButton from "../PortfolioButton";

let articleData = null;
const Article16 = () => {
  //NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${
      articleData !== null && articleData.title
    }`;
    document.getElementById("main-heading").innerHTML =
      articleData !== null && articleData.h1;
    document.getElementsByTagName("meta")["description"].content =
      articleData !== null && articleData.desc;
    document.getElementsByTagName("meta")["keywords"].content =
      articleData !== null && articleData.keywords;
    document.getElementsByTagName("meta")["author"].content =
      articleData !== null && articleData.author;
    setCanonicalTag();
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item;
    });
  }, []);

  useEffect(() => {
    articleData !== null && addView();
    getView();
  }, []);

  const addView = () => {
    Promise.resolve(
      axios({
        url: baseUrl + "/api/v1/article/addView",
        method: "POST",
        data: { id: articleData.id },
      })
    ).then((res) => {});
  };
  const getView = () => {
    Promise.resolve(
      axios({
        url: `${baseUrl}/api/v1/article/getView`,
        method: "GET",
        params: { id: articleData.id },
      })
    ).then((res) => {
      setDataView(res.data.data);
    });
  };

  //change This part

  //read More
  const readMore = [
    {
      id: 1,
      title: "ترند طراحی UI و UX سال 2023",
      link: "/2023-UI-UX-design-trend",
    },
    {
      id: 2,
      title: "معرفی بهترین لوگوهای جهان در سال 2023",
      link: "/the-best-logos-in-the-world",
    },
    {
      id: 3,
      title: "دیجیتال مارکتینگ یا بازاریابی اینترنتی چیست",
      link: "/What-Is-Digital-Marketing",
    },
    {
      id: 4,
      title: "صفر تا صد راه اندازی پیج حرفه ای کاری اینستاگرام",
      link: "/instragram-business-page",
    },
    {
      id: 5,
      title: "سئو سایت چیست و چرا باید به سئو سایت اهمیت دهیم؟",
      link: "/SEO-Importance",
    },
  ];
  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          direction: "rtl",
          alignItems: "center",
        }}
      >
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: { xs: "unset", sm: "flex-start" },
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            width: { xs: "100%", sm: "85%" },
          }}
        >
          <ArticleSide
            tags={articleData !== null && articleData.tags}
            readMore={readMore}
          />
          {articleData !== null ? (
            <Grid
              item
              xs={12}
              sm={7.5}
              md={8}
              lg={8.5}
              xl={9}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", sm: "flex-end" },
                flexDirection: "column",
                width: { xs: "100%", sm: "60%", md: "70%" },
              }}
            >
              {/* do not change */}
              {headPhoto(articleData.image)}
              {h1(articleData.h1, t)}
              {timeRead(articleData.timeRead, t)}
              {date(articleData.date, i18n)}
              <Grid
                sx={{
                  display: "flex",
                  alignItems: { xs: "center", sm: "flex-start" },
                  justifyContent: "flex-start",
                  width: { xs: "90%", sm: "100%" },
                  flexDirection: "column",
                }}
              >
                {/* The text of the article */}
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  کاتالوگ چیست؟
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  کاتالوگ را می توان یکی از ابزارهای مهم{" "}
                  <NavLink
                    to="/Academy/What-Is-Digital-Marketing"
                    target="_blank"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    تبلیغاتی{" "}
                  </NavLink>
                  نامید. صاحبان کسب وکار برای معرفی محصول و برند خود از کاتالوگ
                  در کنار بقیه روش های تبلیغاتی استفاده می کنند.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  اولین گام برای طراحی کاتالوگ مشخص کردن هدف از طراحی آن می
                  باشد. این امر سبب می‌شود که کاتالوگ با نیاز مشتریان بالاترین
                  تطابق را داشته باشد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  مهم‌ترین هدف از طراحی یک کاتالوگ، معرفی و شناساندن محصولات خاص
                  و جدید یک برند است؛ بنابراین سفارش کاتالوگ تنها برای برندهای
                  جدید ثبت نمی‌شود و برندهای شناخته شده برای معرفی محصولاتی که
                  با توجه به نیاز مشتریان تولید شده‌اند از این دفترچه راهنما
                  استفاده می‌کنند.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  برای تعریف حرفه ای تر از کاتالوگ باید بگوییم که کاتالوگ شامل
                  دفترچه‌ای از اطلاعات کامل محصولات و یا خدمات می باشد که مخاطب
                  با مطالعه آن از نحوه استفاده محصولات و خدمات آشنا می شود.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  چرا کاتالوگ طراحی می کنیم؟
                </Typography>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo1} alt="طراحی کاتالوگ - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        طراحی کاتالوگ
                      </Typography>
                    </Grid>
                  </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  طراحی کاتالوگ زیبا و حرفه‌ای یکی از مهمترین اقدامات اولیه برای
                  معرفی هر کسب و کاری می باشد. با طراحی کاتالوگ حرفه ای کار و
                  تجارت شما به صورت برندینگ معرفی می شود. به عبارتی کاتالوگ به
                  نوعی{" "}
                  <NavLink
                    to="/Portfolio"
                    target="_blank"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    ویترین{" "}
                  </NavLink>
                  کار و حرفه شما می باشد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  زمانی که کاتالوگ متناسب و هم‌راستا با محصول و خدمات شما طراحی
                  می شود، مشتری آن را نگه داری می کند و در مدت زمان می‌تواند به
                  فروش محصولات شرکت کمک شایانی کند.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  انواع کاتالوگ
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  دسته بندی واحدی برای کاتالوگ ها وجود ندارد بنابراین ما کاتالوگ
                  ها را در دسته بندی های مختلفی قرار می دهیم. در ادامه به بررسی
                  هر دسته می پردازیم.
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  کاربری کاتالوگ
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  ابعاد کاتالوگ
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  کاغذ چاپ کاتالوگ
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  صحافی کاتالوگ
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  1- کاربری کاتالوگ
                </Typography>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo2} alt="کاربری کاتالوگ - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        کاربری کاتالوگ
                      </Typography>
                    </Grid>
                  </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  در این دسته بندی کاتالوگ‌ها به دو دسته کاتالوگ‌ صنعتی و
                  کاتالوگ‌ تبلیغاتی تقسیم می‌شوند. منظور از کاتالوگ‌های صنعتی
                  کاتالوگ‌هایی هستند که برای جذب سرمایه استفاده می شوند. هدف از
                  چاپ کاتالوگ صنعتی، فروش تجهیزات صنعتی به سایر صنعت ها می باشد.
                  در طراحی کاتالوگ صنعتی تمام توجه باید به گواهینامه‌ها،
                  توانمندی‌ها و پروژه‌های صنعتی موجود در رزومه باشد تا بتواند
                  بهترین و مناسب‌ترین سرمایه گذار را جذب کند.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  در طراحی کاتالوگ صنعتی نباید در استفاده از افکت ها و اغراق های
                  تبلیغاتی زیاده روی کرد و فونت و رنگ این کاتالوگ باید متناسب با
                  سیاق و هدف آن طراحی گردد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  کاتالوگ‌های تبلیغاتی نوعی از کاتالوگ‌ها هستند که با هدف
                  تبلیغات، طراحی و چاپ می‌شوند. کاربرد کاتالوگ‌های تبلیغاتی برای
                  ارائه در نمایشگاه‌ها، همایش‌ها و جشنواره‌ها می باشد. با
                  کاتالوگ تبلیغاتی می توان ویژگی‌ها، امکانات و معرفی یک محصول یا
                  خدمات را به نمایش گذاشت.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  2- ابعاد کاتالوگ
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  با این که در طراحی کاتالوگ هیچگونه محدودیتی برای ابعاد وجود
                  ندارد اما استفاده از اندازه‌های از پیش تعیین شده جهانی
                  می‌تواند روند طراحی و کارایی آن طراحی کاتالوگ را به میزان قابل
                  توجه‌ای افزایش دهد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  از اندازه‌های معمول و رایج طراحی کاتالوگ می‌توان به اندازه‌های
                  A4 و A5 اشاره کرد. توجه داشته باشید که شما می‌توانید در هر
                  ابعاد و اندازه‌ای کاتالوگ مورد نظر خود را پیاده سازی کنید اما
                  پرتی و هدر رفت کاغذ به میزان زیادی خواهد بود و همین مورد باعث
                  افزایش هزینه خواهد شد.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  3- کاغذ چاپ کاتالوگ
                </Typography>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo3} alt="کاغذ کاتالوگ - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        کاغذ کاتالوگ
                      </Typography>
                    </Grid>
                  </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  بعد از طراحی کاتالوگ، یکی از موارد مهم در چاپ کاتالوگ انتخاب
                  کاغذ مناسب است. در صورتیکه کاغذ کاتالوگ به درستی انتخاب شود،
                  تاثیر زیادی در برند سازی کمپانی شما خواهد داشت. همچنین در
                  صورتی که از کاغذ نامرغوب برای کاتالوگ خود استفاده نمایید پس از
                  مدت کوتاهی کاتالوگ شما علاوه بر بلا استفاده شدن، تاثیر منفی
                  نیز در دید مخاطب بهجا خواهد گذاشت. <strong> کاتالوگ </strong>
                  ، معرف شما نزد مخاطب است و در صورتی که طراحی و چاپ آن بی کیفیت
                  باشد، مشتری تمام خدمات و محصولات را به همان میزان بی کیفیت و
                  غیر قابل استفاده می بیند.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  کاتالوگ ها انواع متفاوتی دارند و این انواع متفاوت ناشی از
                  انواع مختلف کاغذ می باشد. با توجه به نوع فعالیت و میزان بودجه
                  خود می توانید نوع کاغذ کاتالوگ را انتخاب نمایید.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  4- صحافی کاتالوگ
                </Typography>

                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  یکی از موارد مهم در دسته بندی کاتالوگ صحافی آن می باشد. با
                  توجه به نوع کسب و کار و تعداد صفحات، بودجه و مخاطبانی که هدف
                  این کاتالوگ هستند، سبک مناسب برای صحافی کاتالوگ انتخاب می شود.
                  صحافی کاتالوگ با روش‌ها و دستگاه های مختلفی انجام می شود.
                  اصولا در صحافی کاتالوگ از چسب گرم، چسب و دوخت، جلد سخت، پانچ و
                  فنر، مفتول تخت و مفتول لوپ استفاده می شود. عملیات صحافی پایان
                  دهنده کار{" "}
                  <NavLink
                    to="/Academy/Graphic"
                    target="_blank"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    طراحی{" "}
                  </NavLink>
                  و چاپ کاتالوگ است.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  ویژگی های کاتالوگ حرفه ای
                </Typography>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo4} alt="کاتالوگ حرفه ای - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        کاتالوگ حرفه ای
                      </Typography>
                    </Grid>
                  </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  طراحی کاتالوگ کاری کاملا تخصصی و حرفه ای است. در تمام دنیا
                  کاتالوگ اولین و مهمترین گام برای فروش محصول می باشد. زمانی که
                  کاتالوگ، حرفه ای طراحی می شود به عنوان ابزاری برای تبلیغات عمل
                  می کند و مشتری را برای فروش جذب می کند.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  در طراحی کاتالوگ باید دقت لازم را داشته باشید تا در ذهن مخاطب
                  ماندگار شود. بدین منظور باید کاتالوگ ها با رعایت موارد و نکات
                  ظریفی طراحی شوند تا با سرعت بیشتری در ذهن مخاطبان جای بگیرند.
                  پس بهتر است به برخی نکات و تکنیک های مهم توجه داشته باشید تا
                  کاتالوگهای جذاب و تاثیر گذاری طراحی کنید.
                </Typography>
                <Typography
                  component={"h4"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "12px", sm: "16px", md: "24px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  مهمترین ویژگی هایی که یک طرح تبلیغاتی دارد به شرح زیر می باشد
                  :
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  1-     کاتالوگ حرفه ای باید جمع و جور و قابل حمل طراحی شود.
                  طراحی باید به گونه ای باشد که مصرف کننده آن را در کوتاهترین
                  زمان مطالعه کند. طول و تفسیر دادن به این ابزار تبلیغاتی نتیجه
                  معکوس در بر خواهد داشت.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  2-     در توصیف و ارائه محصول باید صداقت و درستی رعایت شود و
                  از اغراق دوری کرد. اغراق در معرفی محصول و خدمات باعث بی
                  اعتمادی مشتری و انصراف از خرید می شود زیرا باعث حس فریب خوردگی
                  در او می شود.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  3-     طراحی و توضیحات ارائه شده در کاتالوگ باید به شکلی باشد
                  که همه سوالات و ابهامات مشتری را پاسخ دهد و نیازی به تحقیقات
                  بعدی نباشد. مثلا نام و مشخصات کامل محصول و تولید کننده آن و
                  خدمات ارائه شده توسط شرکت مورد نظر را به طور دقیق توضیح دهد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  4-     تصاویری که برای طراحی کاتالوگ انتخاب می شود باید با نوع
                  محصول یا خدمات همخوانی داشته باشد. برای این منظور بهتر است که
                  از محصول یا سازمانی که خدمات ارائه می دهد، عکسبرداری حرفه ای
                  کرد. این کار باعث جذابیت طرح تبلیغاتی می شود.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  5-     برای طراحی کاتالوگ بهتر است که چهار نوع رنگ را به کار
                  برد که نحوه استفاده از رنگ ها نیاز به خلاقیت و تخصص دارد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  6-      در برخی کاتالوگ ها برای جلوه بیشتر طرح تبلیغاتی از
                  روکش سلفون یا یووی موضعی استفاده می شود.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  تفاوت کاتالوگ و بروشور
                </Typography>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo5} alt="تفاوت کاتالوگ و بروشور - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        تفاوت کاتالوگ و بروشور
                      </Typography>
                    </Grid>
                  </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  یکی از دلایلی که هر کسب و کاری به کاتالوگ نیاز پیدا می کند،
                  ماهیت غنی و کاربردی آن می باشد. از انجا که وظیفه کاتالوگ معرفی
                  ساده و سطحی محصولات و برند نیست بدین منظور از بروشور استفاده
                  می شود.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  تفاوت کاتالوگ و بروشور در نوع هدف مشتریان هدف می باشد. بروشور
                  معمولا رسانه ای است که برای معرفی اولیه محصولات و خدمات یک
                  برند برای مشتریان جزئی می باشد. اما کاتالوگ برای معرفی کالاها
                  و خدمات یک برند به توزیع کنندگان بزرگ کالا طراحی می شود.
                  جذابیت و طراحی صحیح هر یک از این دو نوع رسانه ی بسیار حائز
                  اهمیت است اما در کاتالوگ اجباری نیست که خود را به معرفیِ سرسری
                  چند محصول محدود کنید. 
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  مشخصات جزئی، وزن، رنگ، تعداد، نحوه مصرف و نگه داری، قیمت ،
                  مزیت‌های رقابتی محصول، همه و همه میتوانند در کاتالوگ شما بدون
                  گیج کردن مخاطب جای بگیرند. از همه مهمتر نقش معرفی برند در
                  کاتالوگ است که آن را از بروشور تا این حد متمایز میکند. در
                  حالیکه بروشور به شرح کوتاه و مواردی مثل دستاوردهای شرکت بسنده
                  کرده، کاتالوگ بعنوان یک مستند جامع از برند شما خود را معرفی
                  می‌کند که با جزئیات و توضیحات دقیق و کامل خود به کاربران کمک
                  می‌کنند تا به درک عمیق‌تری از برند یا محصولات شرکتی برسند.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  تعرفه طراحی کاتالوگ
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  تعرفه طراحی کاتالوگ در کانون تبلیغاتی مختلف مورد بررسی قرار می
                  گیرد و با توجه به چارچوب و قواعد مورد تایید مشتریان تهیه می
                  شود. این تعرفه طراحی کاتالوگ برای مشتریان در سه سطح مختلف عرضه
                  می گردد. سطح اول مربوط به طراحان معروف اروپایی می باشد و برای
                  طراحی های خاص پیشنهاد می شود. تعرفه سطح دو طراحی کاتالوگ ،
                  شامل طراحان با تجربه با بیش از 10 سال سابقه کاری و مدارج علمی
                  بالا و اساتید دانشگاه می باشد. تعرفه طراحی کاتالوگ در سطح سه
                  شامل طراحان ساده با تجربه بین دو تا سه سال انجام می پذیرد. در
                  واقع تعرفه طراحی کاتالوگ سطح سه برای کارهای ساده و با اهمیت
                  کمتر می باشد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  لازم به ذکر این نکته است که طراحی کاتالوگ و طراحی بروشور دو
                  مقوله جدا از هم می باشد. در صورت کسب اطلاعات بیشتر و نیاز به
                  مشاوره برای طراحی کاتالوگ به سایت وب گستران سورین مراجعه
                  نمایید.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  هزینه چاپ کاتالوگ
                </Typography>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo6} alt="هزینه چاپ کاتالوگ - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        هزینه چاپ کاتالوگ
                      </Typography>
                    </Grid>
                  </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  هزینه چاپ کاتالوگ به عوامل متعددی بستگی دارد. تعرفه چاپ
                  کاتالوگ هر سال توسط انجمن طراحان گرافیک معین می شود. همچنین
                  مواردی مانند نوع چاپ ، استفاده از رنگ پنجم در چاپ (رنگ طلایی
                  یا نقره ای )، بکارگیری انواع لمینت گرم و سرد یا حتی پودری یا
                  مایع، استفاده از انواع منگنه ( منگنه ساده یا لوپی ) و همچنین
                  وزن کاغذ در محاسبه هزینه چاپ کاتالوگ و بروشور بسیار موثر است.
                  هزینه چاپ در تمام کشور ثابت می باشد. زیرا هزینه های خرید مواد
                  اولیه چاپ در همه جای ایران یکسان است. مهمترین نکته ای که در
                  محاسبه هزینه چاپ کاتالوگ موثراست، تیراژ چاپ می باشد. لازم به
                  یاداوری است که هر چه تعداد یا تیراژ چاپ بالاتر باشد، قیمت چاپ
                  به صورت میانگین پایین می آید.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  نرم افزارهای طراحی کاتالوگ
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  نرم افزارهای متنوعی برای طراحی کاتالوگ وجود دارند اما بستگی به
                  سلیقه طراحان دارد که کدام نرم افزار را برای طراحی استفاده
                  کنند. برخی از این نرم افزارها دارای ویژگی های منحصر به فردی
                  هستند که نسبت به نرم افزارهای دیگر اولویت بالاتری دارند.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  در این بخش سعی داریم تا 5 مورد از بهترین نرم افزارهای طراحی
                  کاتالوگ را معرفی کنیم. این نرم افزارها کارایی فوق العاده ای
                  دارند و در واقع ابزار کار هر طراحی به شمار می آیند.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  1. نرم افزارAdobe Illustrator CC
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  <Typography
                    component={"a"}
                    target="_blank"
                    href="https://www.adobe.com/products/illustrator.html"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    Illustrator{" "}
                  </Typography>
                  برای طراحان حرفه ای می باشد که قصد دارند هنرهای برتری خلق
                  کنند. همچنین برای طراحی یک کاتالوگ، این نرم افزار می تواند
                  برنامه برای طراحی باشد.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  2. نرم افزارAdobe InDesign CC
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  InDesign همان نرم افزاری است که برای طراحی کاتالوگ چند صفحه ای
                  ساخته شده است. همچنین InDesign بخشی از مجموعه نرم افزار Adobe
                  Creative Cloud می باشد که استاندارد خاصی در این صنعت به حساب
                  می آید. این نر افزار نقاط قوت زیاد و نقاط ضعف بسیار کمی دارد.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  3. نرم افزارScribus
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  <Typography
                    component={"a"}
                    target="_blank"
                    href="https://www.scribus.net"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    Scribus
                  </Typography>
                  نرم افزاری است که برای سیستم های لینوکس، BSD و دبیان طراحی شده
                  است. Scribus به اندازه نرم افزار Adobe قدرتمند و انعطاف پذیر
                  نمی باشد، اما ویژگی قابل توجه ای دارد که با استفاده از آن می
                  توانید طرح کاتالوگ کامل خود را ایجاد نمایید. یادگیری این نرم
                  افزار راحت نمی باشد و باید در محیط آن کار کرد تا بتوان بهره
                  برداری درستی از آن به دست آورد. اگر قصد طراحی کاتالوگ با این
                  نرم افزار را دارید، بهتر است برنامه ویرایش تصویر Gimp را نیز
                  تهیه کنید، زیرا تعامل خوبی با یکدیگر برقرار می کنند.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  4. نرم افزارMicrosoft Publisher
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  این نرم افزار با اینکه امتیاز کمتری در بین نرم افزار های طراحی
                  دارد اما در میان طراحان برای خود نام و شهرتی ایجاد نموده است.{" "}
                  <Typography
                    component={"a"}
                    target="_blank"
                    href="https://www.microsoft.com/en-us/microsoft-365/publisher"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    Microsoft Publisher{" "}
                  </Typography>
                  قالب های داخلی بسیاری دارد که بیشتر به صورت آنلاین می باشند و
                  دارای رابط کاربری می باشد که برای کاربران دیگر نرم افزارهای
                  Microsoft مایکروسافت آشنا می باشد.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  5. نرم افزارInkscape
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  <Typography
                    component={"a"}
                    target="_blank"
                    href="https://inkscape.org"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    Inkscape{" "}
                  </Typography>
                  برای Illustrator مانند Scribus برای InDesign می باشد. این نرم
                  افزار یک برنامه ویرایش هنری با کاربردهای کامل و متن باز می
                  باشد که از استاندارد SVG به عنوان پرونده اصلی خود استفاده می
                  نماید. این نرم افزار نیز مانند Scribus به صورت رایگان دانلود و
                  استفاده می شود. از آنجا که Inkscape استاندارد SVG را تأیید می
                  نماید، فقط از یک صفحه یا بوم پشتیبانی می کند و از این رو فاقد
                  همه ابزارهای جالب بوم می باشد. این ویژگی سبب می شود که ایجاد
                  یک کاتالوگ چند صفحه ای کمی آسان تر شود. با این حال، وقتی تمامی
                  کارها به اتمام رسید، قادر خواهید بود فقط دو پرونده (جلو و عقب)
                  ایجاد کنید. همچنین Inkscape به شما این اجازه را می دهد که هر
                  دو فایل را به صورت همزمان باز نمایید.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  میزان اطلاعات مورد نیاز برای طراحی کاتالوگ
                </Typography>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo7} alt="اطلاعات مورد نیاز کاتالوگ - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        اطلاعات کاتالوگ
                      </Typography>
                    </Grid>
                  </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  در کاتالوگ گنجاندن مقدار زیادی از اطلاعات و محصولات جایز نیست
                  زیرا مشتری با حجم زیاد اطلاعات، گیج می شود و از مطالعه کاتالوگ
                  تا انتها منصرف می گردد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  بسیاری از کمپانی‌ها برای تهیه کاتالوگ یک طراح استخدام می‌کنند
                  تا طرح کاتالوگ را تنظیم نماید. طراحانی که مهارت و تجربه‌ی کافی
                  برای آماده‌سازی و چاپ کاتالوگ را دارند. لازم به ذکر است که
                  برای تهیه یک کاتالوگ موفق نیاز به هزاران آیتم و محصول نیست.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  چاپ کاتالوگ می‌تواند شامل یک مجموعه‌ی چهارصفحه‌ای مختصر یا یک
                  کتاب چند صد صفحه‌ای باشد. حتی کمپانی‌هایی که محصولات کمی دارند
                  می‌توانند از کاتالوگ برای فروش بالاتر استفاده نمایند. گاهی
                  کاتالوگ‌های کوچک‌تر نسبن به نسخه‌های بزرگ بازخورد بیشتری دارند
                  و بیشتر روی مشتریان بالقوه تاثیر می گذارند. در هرحال مطالعه در
                  کاتالوگی با ۱۵۰۰ آیتم می‌تواند برای برخی از افراد کسل کننده
                  باشد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  زمان تحلیل مزایا و معایب چاپ کاتالوگ باید احتمال سفارش
                  دوباره‌ی آن را نیز مورد بررسی قرار دهید. با این کار امکان ثبت
                  درخواست دوباره ی کاتالوگ از سوی مشتریان وجود دارد. همچنین
                  مخاطبان اولیه نیز می‌توانند کاتالوگ شما را به دیگر افراد نشان
                  دهند و بدین‌ترتیب فروش بیشتری را برای رقم بزنند.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  اطلاعات مورد نیاز در طراحی کاتالوگ
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  در صفحات آغازین کاتالوگ باید خلاصه ای از رزومه شرکت و فعالیت
                  هایی که انجام می شود درج نمایید.
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  افتخارات و جایزه ها و استانداردها و ایزو های کمپانی در کاتالوگ
                  قید شود.
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  در مورد بهترین کالای ساخته شده یا بهترین خدماتی که انجام شده
                  است توضیح داده شود.
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  از مزایای رقابتی کالا نسبت به دیگر رقبا صحبت شود.
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  از عکس ها و متن ها برای توضیح بهتر به مخاطبان و مشتریان
                  استفاده شود.
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  در مورد هر کالا بصورت جدا توضیحاتی بیان شود بطوری که مشتری
                  قادر باشد کالای مورد نظر خود با بقیه کالاها مقایسه و انتخاب
                  کند .
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  آدرس سایت و آدرس و نشانی برای مراجعه حضوری و تلفن های شرکت
                  برای پاسخگویی به سوالات مشتریان در کاتالوگ ذکر شود.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  کلام آخر
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  اگر آداب دادن کاتالوگ را بلد نباشید، احتمال نابودی کسب و
                  کارتان وجود دارد. دراین مقاله ما سعی کردیم توضیحات کارایی در
                  مورد کاتالوگ ارائه دهیم. با مطالعه کامل این مقاله و مشاوره با
                  شرکت وب گستران سورین قادر خواهید بود بهترین و تاثیر گذارترین
                  کاتالوگ را برای خدمات و محصولات شرکت و کمپانی خود عرضه نمایید.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  سوالات رایج در مورد کاتالوگ
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  در این قسمت به بعضی از سوالات متداول در رابطه با طراحی و نوع
                  نگارش کاتالوگ و هم چنین تاثیری کاتالوگ در بازاریابی موفق را
                  پاسخ می دهیم.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  1. چرا کسب و کار من به کاتالوگ نیاز دارد؟
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  کاتالوگ جذاب بهتر از هر فرد یا ابزار فروش دیگری محصول و خدمات
                  شما را عرضه می کند و به فروش بالاتر محصول کمک می کند. با طراحی
                  درست کاتالوگ نه تنها مخاطب به خرید محصول ترغیب می شود بلکه با
                  دیگر محصولات برند شما نیز آشنا می‌ شود.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  2. قیمت طراحی کاتالوگ چگونه محاسبه می شود؟
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  اینکه کاتالوگ مورد نیاز چند صفحه داشته باشد؟ چه سبک طراحی
                  مدنظر است؟ آیا نیازی به عکاسی اختصاصی هست؟ آیا عکس های موجود
                  در آرشیو نیاز به ادیت و طراحی دارند؟ این ها تمامی سوالاتی
                  هستند که پاسخ به آنها در قیمت کاتالوگ موثر است. اما شما می
                  توانید با استفاده از خدمات رایگان مشاوره وب گستران سورین جدا
                  از کسب راهنمایی‌های لازم، تخمینی از قیمت طراحی کاتالوگ خود
                  داشته باشید.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  3. چه محتوایی باید در کاتالوگ باشد؟
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  در کاتالوگ باید از تصاویر، آیکون‌ها و متن برای معرفی محصولات و
                  خدمات شرکت استفاده کرد. همچنین می‌توانید از نقل قول‌های
                  مشتریان وفادار خود برای اعتماد سازی برند خود در کاتالوگ بهره
                  ببرید.
                </Typography>

                {/* do not change */}

                {author(articleData.author, t)}
                {tags(articleData.tags, t)}
                {linkArticle(t, url)}
                {sourceLink(articleData.source, t)}
                {view(dataview, t)}
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={7.5}
              md={8}
              lg={8.5}
              xl={9}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: { xs: "80%", sm: "60%", md: "70%" },
              }}
            >
              <Loader />
            </Grid>
          )}
        </Grid>
      </Grid>
      <PortfolioButton />
      <Footer />
    </>
  );
};
export default Article16;
