export const baseUrl = "https://api.sorinapp.com";

export const setCanonicalTag = () => {
  let link = !!document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
  link.setAttribute('rel', 'canonical');
  link.setAttribute('href', window.location.protocol + '//' + window.location.host + window.location.pathname);
  document.head.appendChild(link);
}

export function stringValue(value) {
  var output = "";
  value = value.split(",");
  for (var i = 0; i < value.length; i++) {
      output += value[i].toString();
  }
  output = output.replace("/",".");
  return output;
}

export const separator = (input) =>{
  return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function onlyFloatnaumber(value){
  value = value.trim();
  value = stringValue(value);
  var regex = new RegExp("^[0-9]*[.]*[0-9]*$");
  if (regex.test(value)){
    if (value[0] !== '0'){
      return value;
    }
    else{
      if (value.length === 1){
        return value;
      }
      else{
        if (value.length === 2){
          if (value[0] === '0' && value[1] === '.'){
            return value;
          }
          else{
            if (value[0] === '0' && value[1] !== '.'){
              return value[1];
            }
          }
        }
        else{
          if (value.length > 2 ){
            if (value.substr(2).includes('.')){
              return value[0] + value[1];
            }
            else{
              return value;
            }
          }
        }
      }
    }
  }
  else{
    var len= value.length;
    return value.substr(0, len-1);
  }
}

export function onlyDigit(value){
  value = value.trim();
  var regex = new RegExp("^[0-9]*$");
  if (regex.test(value)){
    return value
  }
  else {
    var len= value.length;
    return value.substr(0, len-1)
  } 
}