import { SvgIcon } from '@mui/material';
import React from 'react';
import "../../styles/Nav.css"
const TicketIcon = ({ color }) => {

  return (
    <SvgIcon className='iconSize' component="object" sx={{ height: "30px" }}>
      <svg width="24" height="24" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4H11V6H0V4ZM0 2H11V0H0V2ZM0 10H7V8H0V10ZM15.01 6.87L15.72 6.16C15.8125 6.0673 15.9224 5.99375 16.0434 5.94357C16.1643 5.89339 16.294 5.86756 16.425 5.86756C16.556 5.86756 16.6856 5.89339 16.8066 5.94357C16.9276 5.99375 17.0375 6.0673 17.13 6.16L17.84 6.87C18.23 7.26 18.23 7.89 17.84 8.28L17.13 8.99L15.01 6.87ZM14.3 7.58L9 12.88V15H11.12L16.42 9.7L14.3 7.58Z" fill={color} />
      </svg>
    </SvgIcon>
  );
};
export default TicketIcon;