import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import { author, date, h1, headPhoto, largePhoto, linkArticle, rootArticle, tags, paragraph, timeRead, view, listItemCircle, h2, h4, smallPhoto, sourceLink } from "../articleFunction/ArticleFunction";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { Articles } from "./Articles";
import ArticleSide from "../articleFunction/ArticleSide";
import { NavLink } from "react-router-dom";
//import photo
import photo1 from '../../image/articles/11/photo1.jpg';
import photo2 from '../../image/articles/11/photo2.jpg';
import photo3 from '../../image/articles/11/photo3.jpg';
import photo4 from '../../image/articles/11/photo4.jpg';
import photo5 from '../../image/articles/11/photo5.jpg';
import photo6 from '../../image/articles/11/photo6.jpg';
import photo7 from '../../image/articles/11/photo7.jpg';
import photo8 from '../../image/articles/11/photo8.jpg';
import photo9 from '../../image/articles/11/photo9.jpg';
import photo10 from '../../image/articles/11/photo10.jpg';


import Loader from "../Loader";
import PortfolioButton from "../PortfolioButton";


let articleData = null;
const Article11 = () => {

  //NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${articleData !== null && articleData.title}`
    document.getElementById("main-heading").innerHTML = articleData !== null && articleData.h1;
    document.getElementsByTagName('meta')["description"].content = articleData !== null && articleData.desc;
    document.getElementsByTagName('meta')["keywords"].content = articleData !== null && articleData.keywords;
    document.getElementsByTagName('meta')["author"].content = articleData !== null && articleData.author;
    setCanonicalTag()
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item
    })
  }, [])

  useEffect(() => {
    articleData !== null && addView(); getView();
  }, [])

  const addView = () => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v1/article/addView",
      method: "POST",
      data: { id: articleData.id }
    }))
      .then((res) => { })
  }
  const getView = () => {
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/article/getView`,
      method: "GET",
      params: { id: articleData.id }
    }))
      .then((res) => {
        setDataView(res.data.data)
      })
  }

  //change This part

  //read More
  const readMore = [
    {
      id: 1,
      title: "اصطلاحات پرکاربرد طراحی سایت",
      link: "/common-words-in-web-design"
    },
    {
      id: 2,
      title: "سئو سایت چیست و چرا باید به سئو سایت اهمیت دهیم؟",
      link: "/SEO-Importance"
    },
    {
        id: 3,
        title: "نکاتی که باید برای سفارش طراحی وب سایت در نظر گرفت",
        link: "/Points_to_consider_when_ordering_a_website_design"
      },
      {
        id: 4,
        title: "اصطلاحات پرکاربرد طراحی سایت",
        link: "/common-words-in-web-design"
      },
      {
        id: 5,
        title: "صفر تا صد راه اندازی پیج حرفه ای کاری اینستاگرام",
        link: "/instragram-business-page"
      },


  ]
  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: "rtl", alignItems: "center" }}>
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid container sx={{ display: "flex", alignItems: { xs: "unset", sm: 'flex-start' }, justifyContent: "space-between", flexDirection: "row", flexWrap: "wrap", width: { xs: "100%", sm: "85%" } }}>
          <ArticleSide tags={articleData !== null && articleData.tags} readMore={readMore} />
          {
            articleData !== null ?
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: 'center', sm: "flex-end" }, flexDirection: "column", width: { xs: "100%", sm: "60%", md: "70%" } }} >
                {/* do not change */}

                {headPhoto(articleData.image)}
                {h1(articleData.h1, t)}
                {timeRead(articleData.timeRead, t)}
                {date(articleData.date, i18n)}
                <Grid sx={{ display: 'flex', alignItems: { xs: 'center', sm: 'flex-start' }, justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "column" }}>
                  {/* The text of the article */}
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    اشتیاق به یادگیری برنامه نویسی امروزه بسیار زیاد شده است. خیلی از افراد برنامه نویسی را شغلی پولساز می دانند و تمایل دارند در این حوزه فعالیت کنند. اما معمولا نمی دانند از چه زبانی باید شروع کنند؟ خیلی از افراد می پرسند چه زبان برنامه نویسی محبوب تر است؟ در برنامه نویسی چیزی به نام برتر و محبوب تر وجود ندارد. بسته به کاربرد و حوزه کاری، محبوبیت هر زبان برنامه نویسی مشخص می شود. بر اساس تحقیقات به عمل آمده ما در این مقاله از <NavLink to="/Academy" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >آکادمی </NavLink>
                 سورین به معرفی ده زبان برنامه نویسی پر کاربرد و محبوب سال 2023 می پردازیم.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    عوامل مهم در انتخاب زبان برنامه نویسی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بالغ بر 700 زبان <NavLink to="/Academy/Programming" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >برنامه نویسی </NavLink>
                    وجود دارد اما اینکه کدام زبان انتخاب شوند به عوامل متفاوتی بستگی دارد. برخی از زبان های برنامه نویسی تنها برای هدف و کاربرد خاصی تولید شده اند. مثلا زبان برنامه نویسی سوئیفت تنها برای ساخت اپلیکیشن های <Typography component={"a"} target="_blank" href="https://myket.ir/app/com.cryptocommerceapp" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >ios </Typography>
                    کارایی دارد. از طرفی برخی از زبان های برنامه نویسی چند منظوره هستند. مثلا زبان پایتون را می توان زبانی چند منظوره دانست.  با اینکه اکثرا زبان پایتون را زبانی برای هوش مصنوعی می دانند اما <NavLink to="/Academy/What-Is-Python-Programming-Language" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >پایتون </NavLink>
                    را هم می توان در  برنامه نویسی وب و هم در توسعه <NavLink to="/Academy/what-is-application" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >اپلیکیشن </NavLink>
                    ‌های تلفن‌‌های هوشمند و هم در علم داده بکار برد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بنابراین برخی زبان ها تک منظوره و برخی از زبان های برنامه نویسی چند منظوره هستند. این موضوع را می توان یکی از عوامل مهم در انتخاب زبان برنامه نویسی دانست.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    یکی از عوامل مهم دیگر در انتخاب زبان برنامه نویسی سادگی یادگیری آن زبان برنامه نویسی می باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    محبوب ترین زبان برنامه نویسی 
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در این قسمت به معرفی زبان های برنامه نویسی محبوب سال 2023 می پردازیم تا با بررسی آنها مناسب ترین زبان را متناسب با حوزه کاری خود انتخاب کنید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                     1. جاوا اسکریپت
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo1} alt="جاوا اسکریپت - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        جاوا اسکریپت
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    این زبان در سال 1995 توسط شخصی به نام برندان آیک به بازار عرضه شد. جاوا اسکریپت یا <Typography component={"a"} target="_blank" href="https://www.javascript.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >js </Typography>
                    یک زبان برنامه نویسی محبوب مبتنی بر شی گرایی و مفسری می باشد. این زبان برنامه نویسی پویا و پرکاربرد به سایت های بی روح جان تازه ای می بخشد. جاوا اسکریپت به دلیل انعطافی که دارد بسیار بین برنامه نویسان محبوب است. جاوا اسکریپت زبانی است که هم برای برنامه نویسی در فرانت و هم برای توسعه بک اند به کار می رود. همچنین js را می توان تنها زبانی معرفی کرد که هم در اپلیکیشن های تحت وب استفاده می شود و هم در اپلیکیشن های موبایل کارایی دارد. از قابلیت های این زبان برنامه نویسی می توان رفرش کردن فید های توییتر و جایگذاری ویدئوها در یوتیوب را نام برد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                   2. پایتون
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo2} alt="پایتون - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                       پایتون
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    پایتون در سال 1991 توسط خیدوفان روسوم توسعه یافت. این زبان را می توان یک زبان برنامه نویسی شی گرا و مفسری سطح بالا دانست. یادگیری این زبان بسیار ساده است و این را یکی از دلایل محبوبیت پایتون <Typography component={"a"} target="_blank" href="https://www.python.org" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >payton </Typography>
                    می توان دانست. بسیاری از فناوری های بزرگ دنیا مانند <Typography component={"a"} target="_blank" href="https://www.google.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >گوگل </Typography>
                    ، یوتیوب، یاهو و فیس بوک برای اهداف خود از پایتون استفاده می کنند. این زبان در حوزه های متفاوت مانند توسعه وب، توسعه اپلیکیشن و هوش مصنوعی نیز کارایی دارد. پایتون زبانی چند منظوره است که در تکنولوژی های جدید و به روز دنیا از جمله <Typography component={"a"} target="_blank" href="https://homeinja.com/IOT" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >اینترنت اشیا (IOT) </Typography>
                    و یادگیری ماشین (ML) نقشی مهمی را ایفا می کند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    3. جاوا
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo3} alt="جاوا - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        جاوا
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    از دیگر زبان های محبوب برنامه نویسی می توان زبان <Typography component={"a"} target="_blank" href="https://www.java.com/en" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >جاوا </Typography>
                    را نام برد. این زبان در سال 1991  توسط جیمز گاسلینگ به دنیای برنامه نویسی معرفی شد. جاوا را می توان زبان اصلی برنامه های اندرویدی دانست که 85 درصد از بازار موبایل را در اختیار خود گرفته است. بسیاری از شرکت های بزرگ از جمله ناسا، لینکدین و آمازون از جاوا استفاده می کنند. جاوا یک زبان برنامه نویسی همه منظوره و کارآمد محسوب می شود. این زبان برنامه نویسی برای داده های بسیار حجیم (Big Data) و توسعه وب کارایی بالایی دارد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    4. php
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo4} alt="پی اچ پی - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        php
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    php در سال 1995 توسط اسموس لرادرف به دنیای برنامه نویسی عرضه شد. این زبان را یک زبان کامل نمی توان دانست بلکه اصطلاحا یک زبان اسکریپت نویسی می باشد. php در ابتدا با کلمه personal home pages معرفی شد اما با گذشت زمان جای خود را به کلمه hypertext proprocessor به معنای پیش پردازنده ابر متن داد. این زبان برنامه نویسی سرعت و امنیت بالایی برای توسعه وب دارد. یکی از دلایل محبوبیت php بین توسعه دهندگان بازبودن کدهای آن و رایگان بودن این زبان می باشد. php یک زبان برنامه نویسی سمت سرور می باشد. بیشتر وب سایت های برتر دنیا از جمله ویکی پدیا، فیسبوک و <Typography component={"a"} target="_blank" href="https://mycryptocommerce.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >کریپتوکامرس </Typography>
                    با این زبان طراحی شدند. از مزیت های پی اچ پی می توان به سازگاری این زبان با وب سرورهای برتر دنیا مانند آپاچی و Nginx <Typography component={"a"} target="_blank" href="https://www.microsoft.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >مایکروسافت </Typography>
                    اشاره کرد. یادگیری و استفاده از این زبان بسیار ساده است. 
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    5. کاتلین
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo5} alt="کاتلین - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        کاتلین
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    کاتلین در ژوئن 2011 در روسیه و توسط شرکت <Typography component={"a"} target="_blank" href="https://www.jetbrains.com/opensource/kotlin" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >Jet Brain </Typography>
                  تولید شد. نام این زبان از روی جزیره کاتلین در سن پتزربورگ  الهام گرفت. کاتلین یک زبان برنامه نویسی عمومی و کاربردی برای برنامه های اندروید می باشد. همچنین این زبان یک زبان برنامه نویسی هوشمند و سمت سرور است و در توسعه اپلیکیشن های اندرویدی نیز بسیار کارا می باشد. کاتلین با توجه به شاخص های معتبر جایگاه چهارم را در سال 2022 از آن خود کرده است. این زبان بازار کار خوبی را برای برنامه نویسان فراهم آورده است.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                   6. روبی
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo6} alt="روبی - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        روبی
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    Ruby را می توان از دیگر زبان های برنامه نویسی برای توسعه وب نام برد که یادگیری آن برای افراد مبتدی ساده می باشد. این زبان در دهه 1990 توسط یوکیهیرو و ماتسوموتو در ژاپن ظهور یافت. <Typography component={"a"} target="_blank" href="https://www.ruby-lang.org/en" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >روبی </Typography>
                    یک زبان مفسری و کاملا شی گرا می باشد. این زبان همچنین برای اپلیکیشن های اندروید و ios به کار گرفته می شود. Ruby همچنین از تمام پلتفرم های اصلی مانند ویندوز و مک و لینوکس پشتیبانی می کند. یکی از دلایل محبوبیت روبی بین توسعه دهندگان به کارگیری آسان و انعطاف پذیری و سطح بالای این زبان می باشد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    7. GO
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo7} alt="go - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        go
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زبان GO یا golang یک زبان برنامه نویسی ترجمه شده است که توسط رابرت گریسمیر، کن تامپسون، راب پایک در شرکت گوگل توسعه یافت. این زبان در نوامبر 2009 به دنیای برنامه نویسی عرضه شد. زبان برنامه نویسی GO برای برنامه نویسی حرفه ای زبان مناسبی می باشد. این زبان با اینکه حداقل ابزارها را دارا میی باشد ولی کارایی زیادی را برای توسعه دهندگان فراهم می آورد. این زبان در ظاهر بسیار شبیه زبان C عمل می کند اما بسیار فراتر از این زبان برنامه نویسی می باشد. 
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زبان گو گوگل یک زبان شی گرا و سیستم محور است و سرعت بالایی در اجرای برنامه ها دارد. کمپانی های بزرگی مانند پی پل، توییتر و مایکروسافت از این زبان برنامه نویسی استفاده می کنند. این زبان امکان کد نویسی ساده را مهیا می کند و در علم داده و هوش مصنوعی به کار می رود. مدیریت داده زبان گوگل برخلاف جاوا اسکریپت و پایتون که از نوع پویا می باشد در <Typography component={"a"} target="_blank" href="https://go.dev" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >GO </Typography>
                    مدیریت داده ایستا است.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    8. سوئیفت
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo8} alt="سوئیفت - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        سوئیفت
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    این زبان که از نوع کامپایلری می باشد اولین بار توسط شرکت اپل و شخصی به نام کریس لاتنر در سال 2010 روانه بازار شد. هدف از این زبان ایجاد اپلیکیشن های ios، macos و watchos می باشد. سوئیفت در اصل به جای زبان objective-c که قبلا در اپل استفاده می شد قرار گرفت. از آنجا که محصولات اپل از کیفیت بالایی برخوردار است بنابراین این زبان جایگاه ویژه ای در بین برنامه نویسان دارد و در لیست محبوب ترین زبان  برنامه نویسی 2022 قرار گرفته است. دسترسی به منابع سوئیفت به سادگی انجام می پذیرد. همچنین سرعت و امنیت بالای این زبان از قابلیت های ممتاز این زبان می باشد. <Typography component={"a"} target="_blank" href="https://swift.org" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >swift </Typography>
                    یک زبان برنامه نویسی همه منظوره و منبع باز است.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    9. #C
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo9} alt="سی شارپ - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        سی شارپ
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    سی شارپ یک زبان برنامه نویسی شی گرا و همه منظوره می باشد که بر بستر دات نت اجرا می شود. این زبان توسط آندرس هنربرگ در سال 1999 ارائه شد. #C  را می توان یکی از محبوب ترین و پرکاربردترین زبانهای برنامه نویسی دنیا دانست. این زبان برای ساخت برنامه های تحت ویندوز و تحت وب و همچنین بازی ها کاربرد مورد استفاده قرار می گیرد. کد نویسی در سی شارپ بسیار شبیه به جاوا و ++C می باشد. دستورات این زبان بسیار به زبان انسان نزدیک می باشد، بنابراین یادگیری این زبان بسیار ساده است. حمایت مایکروسافت از این زبان باعث شده که این زبان جزء قدرتندترین زبان های برنامه نویسی قرار گیرد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                   10. perl
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo10} alt="پرل - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                       پرل
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زبان پرل یکی از زبان های سطح بالا و مفسری برنامه نویسی می باشد. پرل در سال 1987 توسط لری وال به عنوان یک زبان اسکریپتی همه منظوره ارائه شد. هدف از انتشار این زبان راحتی در پردازش ها بود. اما این زبان به مرور دچار تغییراتی شد. امروزه برای دستکاری متون طراحی شده، مدیریت سیستم، توسعه وب، برنامه نویسی شبکه و هوش مصنوعی به کار می رود. این زبان نیز شی گرا می باشد و برای کد نویسی سمت سرور کاربرد دارد. زبان perl بسیار شبیه به زبان C و Shell Script می باشد و ویژگی های آنها را دارد. از مزیت های پرل که بسیاری از توسعه دهندگان را جذب خود کرده است می توان به امنیت و سرعت بالای آن اشاره کرد. همچین کار با پرل آسان است و در صورت بروز مشکل در کدنویسی می توان از راهنمای آنلاین کمک گرفت.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای سفارش برنامه نویسی در سایت <NavLink to="/" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >سورین </NavLink>
                    با زبان دلخواه کلیک کنید.
                  </Typography>





                   {/* do not change */}

                   {author(articleData.author, t)}
                  {tags(articleData.tags, t)}
                  {linkArticle(t, url)}
                  {sourceLink(articleData.source, t)}
                  {view(dataview, t)}
                </Grid>
              </Grid>
              :
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "80%", sm: "60%", md: "70%" } }}>
                <Loader />
              </Grid>
          }
        </Grid>
      </Grid>
      <PortfolioButton />
      <Footer />
    </>
  );
}
export default Article11;
