import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import iconArrowRight from '../image/icon/iconArrowRight.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import programming from '../image/articles/sorinMag/programming.png';
import application from '../image/services/application.png';
import SEO from '../image/services/SEO.png';
import SITEDESIGN from '../image/services/SITEDESIGN.png';
import UX from '../image/services/UX.png'
import graphic from '../image/articles/sorinMag/graphic.png';
import { Articles } from "./Magzin/Articles.js";
import searchIcon from '../image/icon/search.png';
import Footer from "./Footer";
import axios from "axios";
import { baseUrl } from "./modules/general";
import frame from '../image/articles/sorinMag/frame.png';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import "swiper/css";
import 'swiper/css/pagination';
import '../styles/WorkSample.css';
import 'swiper/css/navigation';
import bannerHomeinjaSM from '../image/articles/sorinMag/bannerHomeinjaSM.gif';
import bannerHomeinjaMD from '../image/articles/sorinMag/bannerHomeinjaMD.gif';
import bannerHomeinjaXS from '../image/articles/sorinMag/bannerHomeinjaXS.gif';
import advanced from '../image/articles/sorinMag/advanced.png';
import intermediate from '../image/articles/sorinMag/intermediate.png';
import beginner from '../image/articles/sorinMag/beginner.png';
import { setCanonicalTag } from "./modules/general";
import PortfolioButton from "./PortfolioButton";
SwiperCore.use([Navigation, Autoplay]);

let LastestArticle = [];
const SorinMag = () => {
  const { t, i18n } = useTranslation();
  const [serachOpen, setSearchOpen] = useState(false);
  const [slides, setSlides] = useState("1");
  const [search, setSearch] = useState("");
  const [listSuggested, setListSuggested] = useState(null);
  const [mostVisited, setMostVisited] = useState(null)
  const [dataMostVisit, setDataMostVisit] = useState(null);
  const Navigate = useNavigate();

  useEffect(() => {
    document.title = "اموزش طراحی سایت | یادگیری سئو سایت | تدریس طراحی UI/UX | آموزش برنامه نویسی"
    document.getElementById("main-heading").innerHTML = "آموزش طراحی سایت | اموزش سئو سایت | اموزش طراحی UI/UX | آموزش برنامه نویسی";
    document.getElementsByTagName('meta')["description"].content = "آموزش از مبتدی تا پیشرفته مباحث نرم افزاری و ارایه مقالات آموزشی مفید در حوزه طراحی سایت توسط شرکت وب گستران سورین ؛ بهترین شرکت طراحی سایت تهران ";
    document.getElementsByTagName('meta')["keywords"].content = "آموزش طراحی رابط کاربری | آموزش طراحی تجربه کاربری | اموزش ارتقا رتبه سایت در گوگل | آموزش طراحی گرافیکی | آموزش HTML  , CSS | مباحث آموزشی نرم افزار | مقالات آموزشی طراحی سایت | مطالب آموزش طراحی وب سایت | آموزش طراحی اپلیکیشن موبایل |";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    suggested()
    getMostVisit();
  }, [])

  useEffect(() => {
    setSlidesPerview();
    window.addEventListener("resize", setSlidesPerview);
    return () => {
      window.removeEventListener("resize", setSlidesPerview);
    };
  }, []);

  useEffect(() => {
    LastestArticle.push(Articles[Articles.length - 1])
    LastestArticle.push(Articles[Articles.length - 2])
    LastestArticle.push(Articles[Articles.length - 3])
  }, [])

  const getMostVisit = () => {
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/article/mostVist`,
      method: "GET",
    }))
      .then((res) => {
        setDataMostVisit(res.data.data)
      })

  }
  useEffect(() => {
    let data = []
    if (dataMostVisit !== null) {
      dataMostVisit.forEach((item) => {
        Articles.forEach((element) => {
          if (item.article_id === element.id) data.push(element)
        })
      })
    }
    setMostVisited(data)
  }, [dataMostVisit])


  const setSlidesPerview = () => {
    setSlides(
      window.innerWidth <= 600
        ? 1
        : window.innerWidth <= 900
          ? 2
          : window.innerWidth > 720
            ? 3
            : 0
    );
  }

  const serachHandler = () => {
    setSearchOpen(true);
  }
  const category = [
    {
      id: 1,
      image: advanced,
      title: "Advanced"
    },
    {
      id: 2,
      image: intermediate,
      title: "Intermediate"
    },
    {
      id: 3,
      image: beginner,
      title: "Beginner"
    }
  ]

  const suggested = () => {
    let list = [];
    let randomList = [];
    let data = []
    Articles.forEach((item) => {
      item.suggested === "yes" && list.push(item)
    })
    for (let i = 1; randomList.length < 3; i++) {
      let randomNum = Math.floor(Math.random() * list.length);
      if ((randomList.find((item) => item === randomNum)) === undefined) {
        randomList.push(randomNum);
      }
    }
    for (let i = 0; i < randomList.length; i++) {
      let randomNum = randomList[i];
      data.push(list[randomNum]);
    }
    setListSuggested(data)
  }

  const searchHandeler = () => {
    if (search !== "") {
      Navigate("/Academy/" + search)
    }
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchHandeler();
    }
  }

  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: i18n.language === "fa" ? "rtl" : "ltr", alignItems: "center" }}>
        <Grid sx={{ display: "flex", width: "100%", backgroundColor: "#EDEDED", padding: "15px 0", justifyContent: "center" }}>
          <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: { xs: "95%", md: "86%" } }}>
            <NavLink className="navlink" to="/" >
              <Typography sx={{ fontSize: "12px", color: "#757575", padding: "0 5px" }} >{t("mainPage")}</Typography>
            </NavLink>
            <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
            <Typography sx={{ fontSize: "12px", color: "#070707", padding: "0 5px" }}>{t("Academy")}</Typography>
          </Grid>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: { xs: "flex-start", sm: "center" }, justifyContent: "space-between", flexDirection: { xs: "column-reverse", sm: "row" }, width: { xs: "95%", lg: "85%" }, margin: { xs: "15px 0 8px 0", sm: "20px 0 10px 0", md: "50px 0 25px 0" } }}>
          <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
            <Typography component={"img"} alt="icon" src={iconArrowRight} sx={{ transform: i18n.language === "en" && "rotate(180deg)", width: { xs: "14px", sm: "29px" }, height: { xs: "18px", sm: "37px" }, margin: i18n.language === "fa" ? "0 0 0 10px" : "0 10px 0 0", filter: " drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.25))" }} />
            <Typography sx={{ fontSize: { xs: "14px", sm: "16px", md: "24px" }, color: "#000000", textShadow: "0px 7px 4px rgba(0, 0, 0, 0.25)" }}>{t("relatedArticleService")}</Typography>
          </Grid>
          <Grid onClick={serachHandler} sx={{
            display: serachOpen === false ? 'flex' : "none", justifyContent: "center", alignItems: 'center', border: "1px solid rgba(255, 57, 81, 0.64)", boxShadow: "0px 0px 6px rgba(255, 57, 81, 0.82)",
            borderRadius: "8px", width: { xs: "42px", sm: "50px" }, height: { xs: "37px", sm: "45px" }, cursor: "pointer", margin: { xs: "10px 0", sm: "unset" }
          }}>
            <Typography component={"img"} alt="search" src={searchIcon} />
          </Grid>
          <TextField label={t("search")}
            className="textField"
            variant='outlined'
            value={search}
            onKeyDown={handleKeyDown}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
            size="small"
            sx={{
              width: { xs: "100%", sm: "40%", lg: "307px" }, display: serachOpen ? 'flex' : "none", alignItems: "center", border: "1px solid rgba(255, 57, 81, 0.64)",
              boxShadow: "0px 0px 6px rgba(255, 57, 81, 0.82)", borderRadius: "8px", margin: { xs: "10px 0", sm: "unset" }
              , "& .MuiOutlinedInput-input": { padding: { xs: "2px 0", sm: "10px 0" }, alignItems: "center" }
            }}
            InputLabelProps={{ sx: { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "14px" : "12px" } } }}
            InputProps={{
              endAdornment: <Button onClick={searchHandeler}><Typography component={"img"} alt="search" src={searchIcon} sx={{ padding: "0 5px" }} /></Button>,
            }}
          />
        </Grid>
        <Grid container sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: { xs: "95%", lg: "85%" } }}>
          <Grid item xs={6} sm={6} md={4}
            sx={{ display: "flex", alignItems: 'center', justifyContent: "space-evenly", flexDirection: 'column', width: "30%" }}>
            <NavLink to="/Academy/WebDesign" target="_blank" style={{ textDecoration: "none", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
              <Grid sx={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", background: "#F4F4F4", borderRadius: "8px", display: "flex", alignItems: 'center', justifyContent: "space-evenly", flexDirection: 'column',
                height: { xs: "122px", sm: "267px", md: "270px", lg: "307px" }, padding: "20px", width: "90%", margin: "20px 0", cursor: "pointer", filter: "grayscale(100%)", "&:hover": { filter: "none" }
              }}>
                <Grid sx={{
                  backgroundSize: "100% 100%", backgroundImage: `url(${SITEDESIGN})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
                  width: { xs: "74px", sm: "161px", md: "185px" }, height: { xs: "76px", sm: "166px", md: "191px" }
                }}></Grid>
                <Typography sx={{ fontSize: { xs: "10px", sm: '16px' }, fontWeight: '700', color: "#FF3951" }}>{t("WebDesign")}</Typography>
              </Grid>
            </NavLink>
          </Grid>
          <Grid item xs={6} sm={6} md={4}
            sx={{ display: "flex", alignItems: 'center', justifyContent: "space-evenly", flexDirection: 'column', width: "30%" }}>
            <NavLink to="/Academy/UIUX" target="_blank" style={{ textDecoration: "none", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
              <Grid
                sx={{
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", background: "#F4F4F4", borderRadius: "8px", display: "flex", alignItems: 'center', justifyContent: "space-evenly", flexDirection: 'column',
                  height: { xs: "122px", sm: "267px", md: "270px", lg: "307px" }, padding: "20px", width: "90%", margin: "20px 0", cursor: "pointer", filter: "grayscale(100%)",
                  "&:hover": { filter: "none" }, transition: "all 0.3s"
                }}>
                <Grid sx={{
                  backgroundSize: "100% 100%", backgroundImage: `url(${UX})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
                  width: { xs: "115px", sm: "246px", md: "250px" }, height: { xs: "96px", sm: "207px", md: "230px" }
                }}></Grid>
                <Typography sx={{ fontSize: { xs: "10px", sm: '16px' }, fontWeight: '700', color: "#FF3951" }}>{t("UIUX")}</Typography>
              </Grid>
            </NavLink>
          </Grid>
          <Grid item xs={6} sm={6} md={4}
            sx={{ display: "flex", alignItems: 'center', justifyContent: "space-evenly", flexDirection: 'column', width: "30%" }}>
            <NavLink to="/Academy/Application" target="_blank" style={{ textDecoration: "none", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
              <Grid sx={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", background: "#F4F4F4", borderRadius: "8px", display: "flex", alignItems: 'center', justifyContent: "space-evenly", flexDirection: 'column',
                height: { xs: "122px", sm: "267px", md: "270px", lg: "307px" }, padding: "20px", width: "90%", margin: "20px 0", cursor: "pointer", filter: "grayscale(100%)",
                "&:hover": { filter: "none" }, transition: "all 0.3s"
              }}>
                <Grid sx={{
                  backgroundSize: "100% 100%", backgroundImage: `url(${application})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
                  width: { xs: "90px", sm: "246px", md: "232px" }, height: { xs: "96px", sm: "207px", md: "239px" }
                }}></Grid>
                <Typography sx={{ fontSize: { xs: "10px", sm: '16px' }, fontWeight: '700', color: "#FF3951" }}>{t("Application")}</Typography>
              </Grid>
            </NavLink>
          </Grid>
          <Grid item xs={6} sm={6} md={4}
            sx={{ display: "flex", alignItems: 'center', justifyContent: "space-evenly", flexDirection: 'column', width: "30%" }}>
            <NavLink to="/Academy/Seo" target="_blank" style={{ textDecoration: "none", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
              <Grid sx={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", background: "#F4F4F4", borderRadius: "8px", display: "flex", alignItems: 'center', justifyContent: "space-evenly", flexDirection: 'column',
                height: { xs: "122px", sm: "267px", md: "270px", lg: "307px" }, padding: "20px", width: "90%", margin: "20px 0", cursor: "pointer", filter: "grayscale(100%)",
                "&:hover": { filter: "none" }, transition: "all 0.3s"
              }}>
                <Grid sx={{
                  backgroundSize: "100% 100%", backgroundImage: `url(${SEO})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
                  width: { xs: "100px", sm: "243px", md: "240px" }, height: { xs: "80px", sm: "200px", md: "240px" }
                }}></Grid>
                <Typography sx={{ fontSize: { xs: "10px", sm: '16px' }, fontWeight: '700', color: "#FF3951" }}>{t("Seo")}</Typography>
              </Grid>
            </NavLink>
          </Grid>
          <Grid item xs={6} sm={6} md={4}
            sx={{ display: "flex", alignItems: 'center', justifyContent: "space-evenly", flexDirection: 'column', width: "30%" }}>
            <NavLink to="/Academy/Programming" target="_blank" style={{ textDecoration: "none", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
              <Grid sx={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", background: "#F4F4F4", borderRadius: "8px", display: "flex", alignItems: 'center', justifyContent: "space-evenly", flexDirection: 'column',
                height: { xs: "122px", sm: "267px", md: "270px", lg: "307px" }, padding: "20px", width: "90%", margin: "20px 0", cursor: "pointer", filter: "grayscale(100%)",
                "&:hover": { filter: "none" }, transition: "all 0.3s"
              }}>
                <Grid sx={{
                  backgroundSize: "100% 100%", backgroundImage: `url(${programming})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
                  width: { xs: "95px", sm: "214px", md: "244px" }, height: { xs: "97px", sm: "211px", md: "241px" }
                }}></Grid>
                <Typography sx={{ fontSize: { xs: "10px", sm: '16px' }, fontWeight: '700', color: "#FF3951" }}>{t("Programming")}</Typography>
              </Grid>
            </NavLink>
          </Grid>
          <Grid item xs={6} sm={6} md={4}
            sx={{ display: "flex", alignItems: 'center', justifyContent: "space-evenly", flexDirection: 'column', width: "30%" }}>
            <NavLink to="/Academy/Graphic" target="_blank" style={{ textDecoration: "none", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
              <Grid sx={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", background: "#F4F4F4", borderRadius: "8px", display: "flex", alignItems: 'center', justifyContent: "space-evenly", flexDirection: 'column',
                height: { xs: "122px", sm: "267px", md: "270px", lg: "307px" }, padding: "20px", width: "90%", margin: "20px 0", cursor: "pointer", filter: "grayscale(100%)",
                "&:hover": { filter: "none" }, transition: "all 0.3s"
              }}>
                <Grid sx={{
                  backgroundSize: "100% 100%", backgroundImage: `url(${graphic})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
                  width: { xs: "95px", sm: "206px", md: "235px" }, height: { xs: "97px", sm: "209px", md: "239px" }
                }}></Grid>
                <Typography sx={{ fontSize: { xs: "10px", sm: '16px' }, fontWeight: '700', color: "#FF3951" }}>{t("Graphic")}</Typography>
              </Grid>
            </NavLink>
          </Grid>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", width: { xs: "95%", lg: "85%" }, margin: { xs: "25px 0", sm: "20px 0", md: "50px 0" } }}>
          <Typography component={"img"} alt="icon" src={iconArrowRight} sx={{ transform: i18n.language === "en" && "rotate(180deg)", width: { xs: "14px", sm: "29px" }, height: { xs: "18px", sm: "37px" }, margin: i18n.language === "fa" ? "0 0 0 10px" : "0 10px 0 0", filter: " drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.25))" }} />
          <Typography sx={{ fontSize: { xs: "14px", sm: "16px", md: "24px" }, color: "#000000", textShadow: "0px 7px 4px rgba(0, 0, 0, 0.25)" }}>{t("LastestArticles")}</Typography>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', width: '100%', flexDirection: "column" }}>
          {
            LastestArticle !== null && LastestArticle.length > 0 ?
              <Grid sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", }}>
                <Grid sx={{
                  backgroundSize: "100% 100%", backgroundImage: `url(${LastestArticle[0].image})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", "&:hover": { backgroundColor: "rgba(219, 219, 219, 0.85)" },
                  width: { xs: "85%", md: "70%" }, height: "40%", borderRadius: "16px", filter: "drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.3))"
                }}>
                  <Grid sx={{
                    display: "flex", opacity: "0", justifyContent: 'center', alignItems: "center", width: "100%", flexDirection: "column",
                    height: { xs: "123px", sm: "238px", md: "300px", lg: "393px" }, transition: "all 0.3s",
                    "&:hover": { background: "rgba(219, 219, 219, 0.85)", opacity: "1", backdropFilter: "blur(2px)" }, borderRadius: "16px"
                  }} >
                    <Typography sx={{ fontSize: { xs: "10px", sm: "15px", md: "24px" }, fontWeight: "700", color: ' #000000', textAlign: "center", margin: { xs: '10px 0', md: "20px 0" } }}>{t(LastestArticle[0].h1)}</Typography>
                    <Typography sx={{ fontSize: { xs: "8px", sm: "11px", md: "14px" }, color: ' #000000', textAlign: "center", margin: { xs: '10px', md: "20px 30px" } }}>{t(LastestArticle[0].desc)}</Typography>
                    <Grid sx={{ display: "flex", justifyContent: "center", margin: { xs: '10px 0', md: "20px 0" }, alignItems: "center" }}>
                      <NavLink className="navlink" target="_blank" to={`/Academy${LastestArticle[0].link}`} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                        <Typography component={"p"} sx={{
                          display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row", color: "#FF3951",
                          fontSize: { xs: "8px", sm: "12px", md: "14px" }, filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.31))"
                        }}>
                          {t("readMore")} <ArrowBackIcon sx={{ fontSize: { xs: "10px", sm: "18px" }, transform: i18n.language === "en" ? "rotateZ(180deg)" : "null", filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.31))" }} />
                        </Typography>
                      </NavLink>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", flexDirection: "row", margin: "20px 0", width: { xs: "85%", md: "60%" }, }}>
                  <Grid sx={{
                    backgroundSize: "100% 100%", backgroundImage: `url(${LastestArticle[1].image})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", "&:hover": { backgroundColor: "rgba(219, 219, 219, 0.85)" },
                    width: { xs: "48%", sm: "42%" }, height: "40%", borderRadius: "16px", filter: "drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.3))"
                  }}>
                    <Grid sx={{
                      display: "flex", opacity: "0", justifyContent: 'center', alignItems: "center", width: "100%", flexDirection: "column",
                      height: { xs: "92px", sm: "141px", md: "200px", lg: "253px" }, transition: "all 0.3s",
                      "&:hover": { background: "rgba(219, 219, 219, 0.85)", opacity: "1", backdropFilter: "blur(2px)" }, borderRadius: "16px"
                    }} >
                      <Typography sx={{ fontSize: { xs: "10px", sm: "15px", md: "24px" }, fontWeight: "700", color: ' #000000', textAlign: "center", margin: { xs: '2px 0', sm: "10px 0", lg: "20px 0" } }}>{t(LastestArticle[1].h1)}</Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "11px", md: "14px" }, color: ' #000000', textAlign: "center", margin: { xs: '2px 0', sm: "5px 0", lg: "20px 0" } }}>{t(LastestArticle[1].desc.length <= 80 ? LastestArticle[1].desc : (LastestArticle[1].desc.substr(0, 80) + "..."))}</Typography>
                      <Grid sx={{ display: "flex", justifyContent: "center", margin: { xs: '2px 0', sm: "10px 0", lg: "20px 0" }, alignItems: "center" }}>
                        <NavLink className="navlink" target="_blank" to={`/Academy${LastestArticle[1].link}`} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                          <Typography component={"p"} sx={{
                            display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row", color: "#FF3951",
                            fontSize: { xs: "8px", sm: "12px", md: "14px" }, filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.31))"
                          }}>
                            {t("readMore")} <ArrowBackIcon sx={{ fontSize: { xs: "10px", sm: "18px" }, transform: i18n.language === "en" ? "rotateZ(180deg)" : "null", filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.31))" }} />
                          </Typography>
                        </NavLink>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid sx={{
                    backgroundSize: "100% 100%", backgroundImage: `url(${LastestArticle[2].image})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", "&:hover": { backgroundColor: "rgba(219, 219, 219, 0.85)" },
                    width: { xs: "48%", sm: "42%" }, height: "40%", borderRadius: "16px", filter: "drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.3))"
                  }}>
                    <Grid sx={{
                      display: "flex", opacity: "0", justifyContent: 'center', alignItems: "center", width: "100%", flexDirection: "column",
                      height: { xs: "92px", sm: "141px", md: "200px", lg: "253px" }, transition: "0.3s ease",
                      "&:hover": { background: "rgba(219, 219, 219, 0.85)", opacity: "1", backdropFilter: "blur(2px)" }, borderRadius: "16px"
                    }} >
                      <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "20px" }, fontWeight: "700", color: ' #000000', textAlign: "center", margin: { xs: '2px 5px', sm: "10px 5px", lg: "20px 10px" } }}>{t(LastestArticle[2].h1)}</Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "11px", md: "14px" }, color: ' #000000', textAlign: "center", margin: { xs: '2px 0', sm: "5px 0", lg: "20px 0" } }}>{t(LastestArticle[2].desc.length <= 80 ? LastestArticle[2].desc : (LastestArticle[2].desc.substr(0, 80) + "..."))}</Typography>
                      <Grid sx={{ display: "flex", justifyContent: "center", margin: { xs: '2px 0', sm: "10px 0", lg: "20px 0" }, alignItems: "center" }}>
                        <NavLink className="navlink" target="_blank" to={`/Academy${LastestArticle[2].link}`} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                          <Typography component={"p"} sx={{
                            display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row", color: "#FF3951",
                            fontSize: { xs: "8px", sm: "12px", md: "14px" }, filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.31))"
                          }}>
                            {t("readMore")} <ArrowBackIcon sx={{ fontSize: { xs: "10px", sm: "18px" }, transform: i18n.language === "en" ? "rotateZ(180deg)" : "null", filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.31))" }} />
                          </Typography>
                        </NavLink>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", width: { xs: "95%", lg: "85%" }, margin: { xs: "8px 0", sm: "10px 0", md: "25px 0" } }}>
                  <Typography component={"img"} alt="icon" src={iconArrowRight} sx={{ transform: i18n.language === "en" && "rotate(180deg)", width: { xs: "14px", sm: "29px" }, height: { xs: "18px", sm: "37px" }, margin: i18n.language === "fa" ? "0 0 0 10px" : "0 10px 0 0", filter: " drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.25))" }} />
                  <Typography sx={{ fontSize: { xs: "14px", sm: "16px", md: "24px" }, color: "#000000", textShadow: "0px 7px 4px rgba(0, 0, 0, 0.25)" }}>{t("mostVisited")}</Typography>
                </Grid>

                <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', width: { xs: "95%", md: "85%" }, margin: { xs: "8px 0", sm: "10px 0", md: "25px 0" } }}>
                  <Swiper
                    slidesPerView={slides}
                    spaceBetween={5}
                    navigation
                    autoplay={{ delay: 5000, disableOnInteraction: false, reverseDirection: true, waitForTransition: true }}
                    className="swiperSorinMag"
                  >
                    {
                      mostVisited === null ?
                        <Typography></Typography>
                        :
                        mostVisited.length > 0 ?
                          mostVisited.map((item, index) => (
                            <SwiperSlide key={index}>
                              <Grid sx={{
                                backgroundSize: "100% 100%", backgroundImage: `url(${frame})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", flexDirection: "column", padding: "10px", margin: "10px 0",
                                width: { xs: "229px", sm: "278px", md: "282px", lg: "362px" }, height: { xs: "237px", sm: "288px", md: "360px", lg: "456px" }, display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'
                              }}>
                                <Typography alt="img" component={"img"} src={item.image} sx={{ width: { xs: "191px", sm: "231px", md: "210px", lg: "280px" }, height: { xs: "125px", sm: "151px", md: "180px", lg: "225px" }, borderRadius: "8px 100px 8px 8px" }} />
                                <Typography sx={{ fontSize: { xs: '15px', md: "20px", lg: "24px" }, fontWeight: "700", color: "#000000", textAlign: "center" }}>{t(item.h1)}</Typography>
                                <Typography sx={{ fontSize: { xs: '11px', md: "14px" }, color: "#000000", textAlign: "center" }}>{t(item.desc.length <= 100 ? item.desc : (item.desc.substr(0, 100) + "..."))}</Typography>
                                <NavLink className="navlink" target="_blank" to={`/Academy${item.link}`} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                                  <Typography component={"p"} sx={{
                                    display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row", color: "#FF3951",
                                    fontSize: { xs: "8px", sm: "12px", md: "14px" }, filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.31))"
                                  }}>
                                    {t("readMore")} <ArrowBackIcon sx={{ fontSize: { xs: "10px", sm: "18px" }, transform: i18n.language === "en" ? "rotateZ(180deg)" : "null", filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.31))" }} />
                                  </Typography>
                                </NavLink>
                              </Grid>
                            </SwiperSlide>
                          ))
                          :
                          <Typography></Typography>
                    }
                  </Swiper>
                </Grid>
              </Grid>
              :
              <Typography>null</Typography>
          }
        </Grid>
        <Grid sx={{
          display: 'flex', alignItems: 'center', justifyContent: "center", with: "100%", margin: { xs: "15px 0", sm: "20px 0", md: "50px 0" }
        }}>
          <Typography component={"a"} href="https://homeinja.com/" target="blank">
            <Typography alt="banner" component={"img"} src={slides === 3 ? bannerHomeinjaMD : slides === 2 ? bannerHomeinjaSM : bannerHomeinjaXS}
              sx={{
                width: { xs: "unset", sm: "585px", md: "872px", lg: "1206px" }, height: { xs: "unset", sm: "130px", md: "180px", lg: "212px" },
                boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)"
              }} />
          </Typography>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", width: { xs: "95%", lg: "85%" }, margin: { xs: "8px 0", sm: "10px 0", md: "25px 0" } }}>
          <Typography component={"img"} alt="icon" src={iconArrowRight} sx={{ transform: i18n.language === "en" && "rotate(180deg)", width: { xs: "14px", sm: "29px" }, height: { xs: "18px", sm: "37px" }, margin: i18n.language === "fa" ? "0 0 0 10px" : "0 10px 0 0", filter: " drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.25))" }} />
          <Typography sx={{ fontSize: { xs: "14px", sm: "16px", md: "24px" }, color: "#000000", textShadow: "0px 7px 4px rgba(0, 0, 0, 0.25)" }}>{t("categoryArticle")}</Typography>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', width: { xs: "95%", md: "85%" }, margin: { xs: "8px 0", sm: "10px 0", md: "25px 0" } }}>
          <Swiper
            slidesPerView={slides}
            spaceBetween={5}
            navigation
            autoplay={{ delay: 5000, disableOnInteraction: false, reverseDirection: true, waitForTransition: true }}
            className="swiperSorinMag"
          >
            {category.map((item, index) => (
              <SwiperSlide key={index}>
                <Grid
                  sx={{ display: "flex", alignItems: 'center', justifyContent: "space-evenly", flexDirection: 'column', width: { xs: "70%", sm: "84%" } }}>
                  <NavLink to={"/Academy/" + item.title} target="_blank" style={{ textDecoration: 'none', display: "flex", alignItems: 'center', justifyContent: "center", width: "100%" }}>
                    <Grid sx={{
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", background: "#F4F4F4", borderRadius: "8px", display: "flex", alignItems: 'center', width: "100%", maxWidth: { xs: "229px", sm: "450px" },
                      justifyContent: "space-evenly", flexDirection: 'column', height: { xs: "221px", sm: "263px", md: "270px", lg: "307px" }, padding: "20px", margin: "10px 0", cursor: "pointer",
                      filter: "grayscale(100%)", "&:hover": { filter: "none" }, transition: "all 0.3s"
                    }}>
                      <Grid sx={{
                        backgroundSize: "100% 100%", backgroundImage: `url(${item.image})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
                        width: { xs: "190px", sm: "240px", md: "95%", lg: "250px" }, height: { xs: "171px", sm: "211px", lg: "246px" }
                      }}></Grid>
                      <Typography sx={{ fontSize: { xs: "10px", sm: '16px' }, fontWeight: '700', color: "#FF3951" }}>{t(item.title)}</Typography>
                    </Grid>
                  </NavLink>
                </Grid>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", width: { xs: "95%", lg: "85%" }, margin: { xs: "8px 0", sm: "10px 0", md: "25px 0" } }}>
          <Typography component={"img"} alt="icon" src={iconArrowRight} sx={{ transform: i18n.language === "en" && "rotate(180deg)", width: { xs: "14px", sm: "29px" }, height: { xs: "18px", sm: "37px" }, margin: i18n.language === "fa" ? "0 0 0 10px" : "0 10px 0 0", filter: " drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.25))" }} />
          <Typography sx={{ fontSize: { xs: "14px", sm: "16px", md: "24px" }, color: "#000000", textShadow: "0px 7px 4px rgba(0, 0, 0, 0.25)" }}>{t("suggested")}</Typography>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', width: { xs: "95%", md: "85%" }, margin: { xs: "8px 0", sm: "10px 0", md: "25px 0" } }}>
          {
            listSuggested === null ?
              <Typography></Typography>
              :
              <Swiper
                slidesPerView={slides}
                spaceBetween={5}
                navigation
                autoplay={{ delay: 5000, disableOnInteraction: false, reverseDirection: true, waitForTransition: true }}
                className="swiperSorinMag"
              >
                {listSuggested.map((item, index) => (
                  <SwiperSlide key={index}>
                    <Grid sx={{
                      backgroundSize: "100% 100%", backgroundImage: `url(${frame})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", flexDirection: "column", padding: "10px", margin: "10px 0",
                      width: { xs: "229px", sm: "278px", md: "282px", lg: "362px" }, height: { xs: "237px", sm: "288px", md: "360px", lg: "456px" }, display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'
                    }}>
                      <Typography alt="img" component={"img"} src={item.image} sx={{ width: { xs: "191px", sm: "231px", md: "210px", lg: "280px" }, height: { xs: "125px", sm: "151px", md: "180px", lg: "225px" }, borderRadius: "8px 100px 8px 8px" }} />
                      <Typography sx={{ fontSize: { xs: '15px', md: "20px", lg: "24px" }, fontWeight: "700", color: "#000000", textAlign: "center" }}>{t(item.h1)}</Typography>
                      <Typography sx={{ fontSize: { xs: '11px', md: "14px" }, color: "#000000", textAlign: "center" }}>{t(item.desc.length <= 100 ? item.desc : (item.desc.substr(0, 100) + "..."))}</Typography>
                      <NavLink className="navlink" target="_blank" to={`/Academy${item.link}`} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                        <Typography component={"p"} sx={{
                          display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row", color: "#FF3951",
                          fontSize: { xs: "8px", sm: "12px", md: "14px" }, filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.31))",
                        }}>
                          {t("readMore")} <ArrowBackIcon sx={{ fontSize: { xs: "10px", sm: "18px" }, transform: i18n.language === "en" ? "rotateZ(180deg)" : "null", filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.31))" }} />
                        </Typography>
                      </NavLink>
                    </Grid>
                  </SwiperSlide>
                ))}
              </Swiper>
          }
        </Grid>
        <Grid sx={{ display: "none" }}>
          <Typography>
            اموزش طراحی سایت | یادگیری سئو سایت | تدریس طراحی می پردازیم. UI/UX | ما در این صفحه از سورین به بررسی مقالاتی در زمینه آموزش برنامه نویسی
          </Typography>
        </Grid>
      </Grid >
      <PortfolioButton />
      <Footer />
    </>
  );
}
export default SorinMag;