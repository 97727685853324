import { SvgIcon } from '@mui/material';
import React from 'react';
const ApplicationIcon = ({ color }) => {
  return (
    <SvgIcon component="object" sx={{ height: "30px" }}>
      <svg width="22" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.6222 9.46441C18.4373 9.46441 19.2189 9.17843 19.7953 8.66938M17.6222 9.46441C16.8072 9.46441 16.0255 9.17843 15.4492 8.66938M17.6222 9.46441V11.5M19.7953 8.66938C20.3716 8.16033 20.6954 7.46991 20.6954 6.75M19.7953 8.66938L21.4248 10.1087M20.6954 6.75C20.6954 6.03009 20.3716 5.33967 19.7953 4.83062M20.6954 6.75H23M19.7953 4.83062C19.2189 4.32157 18.4373 4.03559 17.6222 4.03559M19.7953 4.83062L21.4248 3.39132M17.6222 4.03559C16.8072 4.03559 16.0255 4.32157 15.4492 4.83062M17.6222 4.03559V2M15.4492 4.83062C14.8728 5.33967 14.5491 6.03009 14.5491 6.75M15.4492 4.83062L13.8196 3.39132M14.5491 6.75C14.5491 7.46991 14.8728 8.16033 15.4492 8.66938M14.5491 6.75H12.2444M15.4492 8.66938L13.8196 10.1087M17.1099 2H18.1346M21.063 3.07177L21.7866 3.71086M23 6.29745V7.20255M21.7866 9.78914L21.063 10.4282M18.1346 11.5H17.1099M14.1814 10.4282L13.4579 9.78914M12.2444 7.20255V6.29745M13.4579 3.71086L14.1814 3.07177M7.6 13.875L9.23582 12.7911C9.40269 12.6804 9.59338 12.601 9.79622 12.5577C9.99907 12.5143 10.2098 12.5081 10.4155 12.5392C10.6212 12.5703 10.8175 12.6382 10.9924 12.7387C11.1674 12.8392 11.3173 12.9702 11.4329 13.1236C11.6008 13.3462 11.6903 13.6076 11.6903 13.875C11.6903 14.1424 11.6008 14.4038 11.4329 14.6264L9.82444 16.757C9.33019 17.4114 8.62272 17.9179 7.79556 18.2096L5.4 19.0568M8.33333 9.34091L4.81822 10.6718C3.77967 11.0647 2.95203 11.7954 2.50676 12.7125L1.35591 15.0841C1.12096 15.5682 0.999867 16.0895 1 16.6162V21" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23 13V19C23 19.5304 22.7629 20.0391 22.341 20.4142C21.919 20.7893 21.3467 21 20.75 21H10.25C9.65326 21 9.08097 20.7893 8.65901 20.4142C8.23705 20.0391 8 19.5304 8 19V17.8933M8 13.1662V3C8 2.46957 8.23705 1.96086 8.65901 1.58579C9.08097 1.21071 9.65326 1 10.25 1H12" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  );
};
export default ApplicationIcon;