import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import arrow from '../image/sample/arrow.png';
import Footer from "./Footer";
import { setCanonicalTag } from "./modules/general";
import { dataCategory } from "./DataSample";

const SampleCategory = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = "انواع وب سایت های شرکت سورین | طراحی سایت وب گستران سورین"
    document.getElementById("main-heading").innerHTML = "انواع وب سایت های شرکت سورین | طراحی سایت وب گستران سورین";
    document.getElementsByTagName('meta')["description"].content = "شرکت وب گستران سورین با سابقه طولانی و تجربه بالا به همراه تیم کامل و تخصصی آمادگی طراحی، ساخت و پیاده سازی انواع سایت، پلتفرم و نرم افزار را داراست . نمونه کارهای طراحی وب سایت در شرکت وب گستران سورین را مشاهده میفرمایید";
    document.getElementsByTagName('meta')["keywords"].content ="طراحی انواع سایت | سفارش طراحی وب سایت | ";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  const dataGrid = () => {
    let list = [];
    dataCategory.forEach((item) => {
      list.push(
        <Grid key={item.id} item xs={12} sm={12} md={5}
          sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", margin: "10px 0" }}
        >
          <NavLink to={`/Portfolio/${item.title}`} style={{ textDecoration: "none", color: "#000000", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%" }}>
            <Grid sx={{
              display: 'flex', alignItems: 'flex-start', justifyContent: "center", width: { xs: "75%", sm: "80%", md: "532px" }, maxWidth: { xs: "95%", sm: "80%", md: "unset" },
              height: { xs: "238px", sm: "467px" }, backgroundSize: "100% 100%", backgroundImage: `url(${item.image})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
              "&:hover": { transform: "scale(1.1)" },transition:"all 0.3s"
            }}>
              <Typography sx={{ marginTop: { xs: "20px", sm: "30px", md: "40px" }, textShadow: "0px 7px 4px rgba(0, 0, 0, 0.25)", fontSize: { xs: "14px", sm: "20px" }, fontWeight: '700' }}>{t(item.title)}</Typography>
            </Grid>
          </NavLink>

        </Grid>
      )
    })
    return list;
  }
  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: i18n.language === "fa" ? "rtl" : "ltr", alignItems: "center" }}>
        <Grid sx={{ display: "flex", width: "100%", backgroundColor: "#EDEDED", padding: "15px 0", justifyContent: "center" }}>
          <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: { xs: "95%", md: "86%" } }}>
            <NavLink className="navlink" to="/">
              <Typography component={"p"} sx={{ fontSize: "12px", color: "#757575", padding: "0 5px" }} >{t("mainPage")}</Typography>
            </NavLink>
            <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
            <Typography component={"p"} sx={{ fontSize: "12px", color: "#070707", padding: "0 5px" }}>{t("sampleWork")}</Typography>
          </Grid>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'flex-start', flexDirection: 'row', width: { xs: "95%", md: "86%" }, margin: "30px 0" }}>
          <Typography component={"img"} alt="arrow" src={arrow}
            sx={{
              width: { xs: "19px", sm: "29px" }, height: { xs: "24px", sm: "37px" }, filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))", padding: "0 3px"
              , transform: i18n.language === "en" && "rotate(180deg)"
            }} />
          <Typography sx={{ fontSize: { xs: "16px", sm: "20px", md: "24px" }, color: "#070707", fontWeight: "700" }}>{t("sampleWork")}</Typography>
        </Grid>
        <Grid container sx={{ display: 'flex', alignItems: "center", justifyContent: "space-evenly" }}>
          {dataGrid()}
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}

export default SampleCategory;