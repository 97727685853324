const errors = {};

export const passValidate = (data, t) => {
  if (!data) {
    errors.pass = t("passwordRequierd");
  } else if (data.length < 8) {
    errors.pass = t("passwordShort");
  } else {
    delete errors.pass;
  }
  return errors;
}

export const phoneNumberValidate = (data, t) => {
  if (!data) {
    errors.phoneNumber = t("phoneRequierd");
  }
  else if (data.length < 11) {
    errors.phoneNumber = t("phoneShort");
  } else {
    delete errors.phoneNumber;
  }
  return errors;
}
export const codeSmsValidate = (data , t) =>{
  if (!data) {
    errors.codeSms = t("codeSmsRequierd");
  } else if (data.length <6 ){
    errors.codeSms = t("codeSmsShort");
  } else {
    delete errors.codeSms;
  }
  return errors;
}
export const repeatPassValidate = (data , t) => {
  if(!data) {
    errors.repeatPass = t("passwordRequierd")
  } else if (data.length < 8 ){
    errors.repeatPass = t("passwordShort");
  }else {
    delete errors.repeatPass;
  }
  return errors;
}