import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Nav from './component/Nav';
import Home from './component/Home';
import SorinMag from './component/SorinMag';
import Article01 from './component/Magzin/Article01';
import ContactUs from './component/ContactUs';
import AboutUs from './component/AboutUs';
import axios from 'axios';
import { baseUrl } from './component/modules/general';
import { useState } from 'react';
import { useEffect } from 'react';
import Panel from './component/Panel';
import SampleCategory from './component/SampleCategory';
import SamplePage from './component/SamplePage';
import ArticlesPage from './component/ArticlesPage';
import ScrollToTop from './component/ScrollToTop';
import AuthenticationNull from './component/AuthenticationNull'
import TempeleteSample from './component/TempeleteSample';
import NotFound from './component/NotFound';
import CareerOpportunities from './component/CareerOpportunities';
import CareerOpportunitiesDetail from './component/CareerOpportunitiesDetail';
import Article02 from './component/Magzin/Article02';
import Article03 from './component/Magzin/Article03';
import Article04 from './component/Magzin/Article04';
import Article05 from './component/Magzin/Article05';
import Article06 from './component/Magzin/Article06';
import Article07 from './component/Magzin/Article07';
import Article08 from './component/Magzin/Article08';
import Article09 from './component/Magzin/Article09';
import Article10 from './component/Magzin/Article10';
import Article11 from './component/Magzin/Article11';
import Article12 from './component/Magzin/Article12';
import Article13 from './component/Magzin/Article13';
import Article14 from './component/Magzin/Article14';
import Article15 from './component/Magzin/Article15';
import Article16 from './component/Magzin/Article16';
import Article17 from './component/Magzin/Article17';
import Article18 from './component/Magzin/Article18';

import Scroll from './component/Scroll';

const App = () => {
  const [auth, setAuth] = useState(false);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    getCheckAuth();
    const updateAuthCheackInterval = setInterval(() => {
      getCheckAuth();
    }, 100000);
    return () => {
      clearInterval(updateAuthCheackInterval);
    };
  }, [auth]);

  const getCheckAuth = async () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/user/authCheck`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setAuth(res.data.data);

        if (res.data.data === true) {
          getInfoUser().then((res) => { localStorage.setItem("sorinInfoUser", JSON.stringify(res)); setInfo(res) })

        }
        else {
          localStorage.removeItem("sorinInfoUser")
        }
      })
      .catch(
        (error) => {
          if (error.hasOwnProperty('status')) {
            const { status } = error.status;
            if ((status > 500) || (status < 600)) {
              const timeout = setTimeout(() => {
                return Promise.reject(error)
              }, 15000)
              return () => {
                clearTimeout(timeout)
              };
            }
          }
          else {
            return (Promise.reject(error));
          }
        }
      )
  }

  const getInfoUser = () => {
    const AuthStr = 'Bearer ' + (localStorage.getItem('TACC'));
    const Response = Promise.resolve(axios({
      url: baseUrl + "/api/v1/user/info",
      method: "GET",
      headers: { 'Authorization': AuthStr }
    }))
      .then((res) => {
        return (res.data.data)
      })
    return Response;
  }

  return (
    <>
      <BrowserRouter>
        <Nav auth={auth} />
        <ScrollToTop />
        <Scroll />
        <Routes>
          <Route path='/' element={<Home auth={auth} />} />
          <Route path='/Academy' element={<SorinMag />} />
          <Route path='/Academy/:value' element={<ArticlesPage auth={auth} />} />
          <Route path="/ContactSorin" element={<ContactUs info={info} />} />
          <Route path='/AboutSorin' element={<AboutUs />} />
          <Route path='/Portfolio' element={<SampleCategory />} />
          <Route path='/Portfolio/:value' element={<SamplePage />} />
          <Route path='/Portfolio/:type/:title' element={<TempeleteSample auth={auth} />} />
          <Route path='/SorinBoard/:value' element={localStorage.getItem('TACC') ? <Panel info={info} auth={auth} /> : <AuthenticationNull />} />
          <Route path='/JobOffer' element={<CareerOpportunities />} />
          <Route path='/JobOffer/:value' element={<CareerOpportunitiesDetail />} />
          <Route path='/Academy/Points_to_consider_when_ordering_a_website_design' element={<Article01 />} />
          <Route path='/Academy/what-is-seo' element={<Article02 />} />
          <Route path='/Academy/the-best-logos-in-the-world' element={<Article03 />} />
          <Route path='/Academy/2023-UI-UX-design-trend' element={<Article04 />} />
          <Route path='/Academy/instragram-business-page' element={<Article05 />} />
          <Route path='/Academy/what-is-application' element={<Article06 />} />
          <Route path='/Academy/common-words-in-web-design' element={<Article07 />} />
          <Route path='/Academy/all-about-instagram' element={<Article08 />} />
          <Route path='/Academy/SEO-Importance' element={<Article09 />} />
          <Route path='/Academy/What-Is-Digital-Marketing' element={<Article10 />} />
          <Route path='/Academy/popular-programming-language-2023' element={<Article11 />} />
          <Route path='/Academy/What-Is-Python-Programming-Language' element={<Article12 />} />
          <Route path='/Academy/What-Is-Javascript-Programming-Language' element={<Article13 />} />
          <Route path='/Academy/What-Is-JAVA-Programming-Language' element={<Article14 />} />
          <Route path='/Academy/What-Is-Artificial-Intelligence' element={<Article15 />} />
          <Route path='/Academy/All-About-Catalog-Design' element={<Article16 />} />
          <Route path='/Academy/What-Is-CSharp-Programming-Language' element={<Article17 />} />
          <Route path='/Academy/What-Is-Kotlin-Programming-Language' element={<Article18 />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;