import { Grid, TextField, Typography, IconButton, Input, CircularProgress, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import pallet from '../image/dashboard/pallet.png';
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import InputColor from 'react-input-color';
import '../styles/dashboard.css';
import CloseIcon from '@mui/icons-material/Close';
import AlertMessage from "./modules/AlertMessage";
import axios from "axios";
import { baseUrl } from "./modules/general";
import Resizer from "react-image-file-resizer";
import { setCanonicalTag } from "./modules/general";
import Loader from "./Loader";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

let imageFile = null;
let imageResize = null;
const OrderUi = () => {
  const [t, i18n] = useTranslation();
  const [textFieldSize, setTextFiledSize] = useState(window.innerWidth <= 900 ? "small" : "medium");
  const [color1, setColor1] = useState("#ffffff");
  const [color2, setColor2] = useState("#ffffff");
  const [color3, setColor3] = useState("#ffffff");
  const [color4, setColor4] = useState("#ffffff");
  const [goal_customer, setgoal_customer] = useState("");
  const [purposeSite, setPurposeSite] = useState("");
  const [description, setDescription] = useState("");
  const [alert, setAlert] = useState({ alertCheck: false, message: "", severity: "" });
  const [btnSubmit, setBtnSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState("");
  const [info, setInfo] = useState(null);

  useEffect(() => {
    document.title = "سفارش طراحی رابط  و تجربه کاربری سایت | درخواست طراحی UIUX | وب گستران سورین";
    document.getElementById("main-heading").innerHTML = "سفارش طراحی رابط  و تجربه کاربری سایت | درخواست طراحی UIUX | وب گستران سورین";
    document.getElementsByTagName('meta')["description"].content = "شما در صفحه مربوط به ثبت سفارش طراحی رابط و تجربه کاربری در بهترین شرکت طراح رابط و تجربه کاربری در ایران هستید. طراحی اختصاصی یو آی و یو ایکس وب سایت شما تخصص ماست.";
    document.getElementsByTagName('meta')["keywords"].content = "درخواست طراحی رابط و تجربه کاربری اختصاصی وب سایت | سفارش طراحی یو آی و یو ایکس سایت در بهترین سایت طراحی رابط کاربری در ایران";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    setInfo(JSON.parse(localStorage.getItem('sorinInfoUser')));
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => resizeTextFiled());
    return (() => window.removeEventListener('resize', () => resizeTextFiled()));
  }, [window.innerWidth]);

  const onChangeLicence = (e) => {
    if (e.target.files[0]) {
      imageFile = e.target.files[0];
      if (imageFile.size > 10000) {
        setAlert({ alertCheck: true, message: t("alert_sizeImageLarge"), severity: "error" })
      }
      else {
        setLogo(imageFile.name)
        imageResizer()
      }
    }
  }

  const imageResizer = () => {
    try {
      Resizer.imageFileResizer(
        imageFile,
        400,
        400,
        "png",
        100,
        0,
        (uri) => {
          imageResize = uri;
        },
        "file",
      );
    } catch (err) {
      setAlert({ alertCheck: true, message: t("alert_notPic"), severity: "error" })
    }
  }

  const submitInfo = () => {
    let colorExist = false;
    let color = [color1, color2, color3, color4]
    color.forEach((item) => {
      if (item !== "#ffffff") {
        return colorExist = true;
      }
    })
    color.forEach((item, index) => {
      if (item === "#ffffff") {
        color.splice(index, 1);
      }
    })
    const formData = new FormData();
    if (goal_customer === "") {
      setAlert({ alertCheck: true, message: t("alert_goal_customerFaild"), severity: "error" })
    } else {
      if (imageResize !== null) { formData.append("logo", imageResize) };
      if (colorExist === true) { formData.append("color", JSON.stringify(color)) };
      if (goal_customer !== "") { formData.append("goal", goal_customer) };
      if (purposeSite !== "") { formData.append("use", purposeSite) };
      if (description !== "") { formData.append("description", description) };
      submitData(formData);
    }
  }

  const submitData = (data) => {
    setLoading(true)
    setBtnSubmit(true)
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/front/create',
      method: "POST",
      data: data,
      headers: {
        'Authorization': AuthStr,
        "Content-Type": "multipart/form-data"
      },
    }))
      .then(() => {
        setAlert({ alertCheck: true, message: t("alert_dataSuccess"), severity: "success" })
        setBtnSubmit(false);
        setLoading(false);
        setColor1("#ffffff"); setColor2("#ffffff"); setColor3("#ffffff"); setColor4("#ffffff"); setLogo(""); setgoal_customer(""); setPurposeSite(""); setDescription("");
      })
      .catch(
        (error) => {
          const { status } = error.response;
          switch (status) {
            case (500):
              setAlert({ alertCheck: true, message: t("AM_intervalServer"), severity: "error" })
              break;
            default:
              setAlert({ alertCheck: true, message: t("alert_defualt"), severity: "error" })
          }
          setBtnSubmit(false)
          setLoading(false)
        }
      )
  }

  const resizeTextFiled = () => {
    if (window.innerWidth < 900 && textFieldSize === 'medium') {
      setTextFiledSize('small')
    }
    else if (window.innerWidth > 900 && textFieldSize === 'small') {
      setTextFiledSize('medium')
    }
  }

  const color1Handeler = (event) => {
    setColor1(event.hex)
  }
  const color2Handeler = (event) => {
    setColor2(event.hex)
  }
  const color3Handeler = (event) => {
    setColor3(event.hex)
  }
  const color4Handeler = (event) => {
    setColor4(event.hex)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };

  return (
    info === null ?
      <Loader />
      :
      info.active === 0 ?
        <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "18px" }, margin: "20px" }}>{t("compeleteInfo")}</Typography>
        :
        <>
          <Grid sx={{ display: 'flex', width: "100%", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: { xs: 'flex-start', sm: "center" }, direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" }, marginTop: { xs: "0", md: "100px" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("suggestedColors")}</Typography>
                <Tooltip title={t("tooltipColor")}>
                  <IconButton>
                    <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
                <Grid sx={{
                  display: "flex", width: "100%", background: "#FFFFFF", height: { xs: "40px", md: "56px" }, boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}>
                  <Typography component={"label"} htmlFor="upload-color" sx={{ display: "flex", alignItems: "center", width: "100%", padding: "0 10px" }} >
                    <Grid
                      sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", "&:hover": { backgroundColor: "transparent" } }} >
                      <Grid className="inputColor1" sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                        <CloseIcon onClick={() => setColor1("#FFFFFF")} sx={{ fontSize: { xs: "15px", sm: "20px", md: "15px", lg: "20px" }, cursor: "pointer" }} />
                        <InputColor
                          initialValue={color1}
                          onChange={color1Handeler}
                          placement="right"
                          className="colorStyle"
                        />
                      </Grid>
                      <Grid className="inputColor2" sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                        <CloseIcon onClick={() => setColor2("#FFFFFF")} sx={{ fontSize: { xs: "15px", sm: "20px", md: "15px", lg: "20px" }, cursor: "pointer" }} />
                        <InputColor
                          initialValue={color2}
                          onChange={color2Handeler}
                          placement="right"
                          className="colorStyle"
                        />
                      </Grid>
                      <Grid className="inputColor3" sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                        <CloseIcon onClick={() => setColor3("#FFFFFF")} sx={{ fontSize: { xs: "15px", sm: "20px", md: "15px", lg: "20px" }, cursor: "pointer" }} />
                        <InputColor
                          initialValue={color3}
                          onChange={color3Handeler}
                          placement="right"
                          className="colorStyle"
                        />
                      </Grid>
                      <Grid className="inputColor4" sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                        <CloseIcon onClick={() => setColor4("#FFFFFF")} sx={{ fontSize: { xs: "15px", sm: "20px", md: "15px", lg: "20px" }, cursor: "pointer" }} />
                        <InputColor
                          initialValue={color4}
                          onChange={color4Handeler}
                          placement="right"
                          className="colorStyle"
                        />
                      </Grid>
                      <Typography component={"img"} alt="pallet" src={pallet} />
                    </Grid>
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("logo")}</Typography>
                  <Tooltip title={t("alert_sizeImageLarge")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid sx={{
                  display: "flex", width: "100%", background: "#FFFFFF", height: { xs: "40px", md: "56px" }, boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}>
                  <Input id="upload-logo" type="file" sx={{ display: "none" }} onChange={(e) => onChangeLicence(e)} accept="image/*" />
                  <Typography component={"label"} htmlFor="upload-logo" sx={{ display: "flex", alignItems: "center", width: "100%", padding: "0 10px" }} >
                    <IconButton component="span" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", "&:hover": { backgroundColor: "transparent" } }} >
                      <Typography sx={{ color: "#7C7C7C", fontSize: { xs: "12px", sm: "14px", } }}>{logo === "" ? t("uploadLogo") : logo}</Typography>
                      <CloudUploadOutlinedIcon sx={{ color: "#000000" }} />
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", marginTop: { xs: "0", md: "3%" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("goal_customer")}</Typography>
                  <Tooltip title={t("tooltipSiteGoalCustomer")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  fullWidth
                  value={goal_customer}
                  onChange={(e) => setgoal_customer(e.target.value)}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("purposeSite")}</Typography>
                  <Tooltip title={t("tooltipPurposeSite")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  value={purposeSite}
                  onChange={(e) => setPurposeSite(e.target.value)}
                  fullWidth
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column", width: { xs: "95%", md: "90%" }, alignItems: 'flex-start', marginTop: { xs: "0", md: "3%" }, justifyContent: 'center', marginBottom: { xs: "5%", sm: "0" } }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("description")}</Typography>
                <Tooltip title={t("tooltipDescriptionUI")}>
                  <IconButton>
                    <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <TextField
                className="textField"
                variant='outlined'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                multiline
                rows={4}
                sx={{
                  width: "100%", background: "#FFFFFF",
                  boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}
              />
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column", marginTop: "40px", width: { xs: "95%", md: "90%" }, alignItems: 'center', justifyContent: 'center' }}>
              <Button onClick={submitInfo} disabled={btnSubmit ? true : false} sx={{
                background: "#FF3951", boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", color: "#FFFFFF", fontSize: { xs: "12px", sm: "16px", md: "18px" },
                width: { xs: i18n.language === "fa" ? "100px" : "125px", sm: "159px", md: "193px" }, height: { xs: i18n.language === "fa" ? '33px' : "40px", sm: '50px', md: "60px" }, "&:hover": { backgroundColor: "#FF3951" }, textTransform: "lowercase"
              }}>
                {btnSubmit === true && loading === true ? <CircularProgress size={20} color="inherit" sx={{ color: "white" }} /> : t("submitInfo")}
              </Button>
            </Grid>
          </Grid >
          <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
        </>
  )
}

export default OrderUi;