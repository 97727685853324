import mockupDesign1 from '../image/sample/designing/site1/mockupDesign1.png'
import aboutUsDesign1 from '../image/sample/designing/site1/desktop/aboutUsDesign1.png'
import contactusDesign1 from '../image/sample/designing/site1/desktop/contactusDesign1.png'
import desktopDesign1 from '../image/sample/designing/site1/desktop/desktopDesign1.png'
import projectDesign1 from '../image/sample/designing/site1/desktop/projectDesign1.png'
import aboutUsDesignSM1 from '../image/sample/designing/site1/tablet/aboutUsDesignSM1.png'
import contactusDesignSM1 from '../image/sample/designing/site1/tablet/contactusDesignSM1.png'
import tabletDesignSM1 from '../image/sample/designing/site1/tablet/tabletDesignSM1.png'
import projectDesignSM1 from '../image/sample/designing/site1/tablet/projectDesignSM1.png'
import aboutUsDesignXS1 from '../image/sample/designing/site1/mobile/aboutUsDesignXS1.png'
import contactusDesignXS1 from '../image/sample/designing/site1/mobile/contactusDesignXS1.png'
import mobileDesignXS1 from '../image/sample/designing/site1/mobile/mobileDesignXS1.png'
import projectDesignXS1 from '../image/sample/designing/site1/mobile/projectDesignXS1.png';
import designing1 from '../image/sample/designing/site1/designing1.mov';
import mockupDesign2 from '../image/sample/designing/site2/mockupDesign2.png'
import aboutUsDesignMD2 from '../image/sample/designing/site2/desktop/aboutUsDesignMD2.png'
import contactusDesignMD2 from '../image/sample/designing/site2/desktop/contactusDesignMD2.png'
import desktopDesignMD2 from '../image/sample/designing/site2/desktop/desktopDesignMD2.png'
import projectDesignMD2 from '../image/sample/designing/site2/desktop/projectDesignMD2.png'
import aboutUsDesignSM2 from '../image/sample/designing/site2/tablet/aboutUsDesignSM2.png'
import contactusDesignSM2 from '../image/sample/designing/site2/tablet/contactusDesignSM2.png'
import tabletDesignSM2 from '../image/sample/designing/site2/tablet/tabletDesignSM2.png'
import projectDesignSM2 from '../image/sample/designing/site2/tablet/projectDesignSM2.png'
import aboutUsDesignXS2 from '../image/sample/designing/site2/mobile/aboutUsDesignXS2.png'
import contactusDesignXS2 from '../image/sample/designing/site2/mobile/contactusDesignXS2.png'
import mobileDesignXS2 from '../image/sample/designing/site2/mobile/mobileDesignXS2.png'
import projectDesignXS2 from '../image/sample/designing/site2/mobile/projectDesignXS2.png'
import designing2 from '../image/sample/designing/site2/designing2.mov';
import mockupDesign3 from '../image/sample/designing/site3/mockupDesign3.png'
import aboutUsDesignMD3 from '../image/sample/designing/site3/desktop/aboutUsDesignMD3.png'
import contactusDesignMD3 from '../image/sample/designing/site3/desktop/contactusDesignMD3.png'
import desktopDesignMD3 from '../image/sample/designing/site3/desktop/desktopDesignMD3.png'
import projectDesignMD3 from '../image/sample/designing/site3/desktop/projectDesignMD3.png'
import ArticlesDesignMD3 from '../image/sample/designing/site3/desktop/ArticlesDesignMD3.png'
import ArticleDesignMD3 from '../image/sample/designing/site3/desktop/ArticleDesignMD3.png'
import aboutUsDesignSM3 from '../image/sample/designing/site3/tablet/aboutUsDesignSM3.png'
import articleDesignSM3 from '../image/sample/designing/site3/tablet/articleDesignSM3.png'
import ArticlesDesignSM3 from '../image/sample/designing/site3/tablet/ArticlesDesignSM3.png'
import contactusDesignSM3 from '../image/sample/designing/site3/tablet/contactusDesignSM3.png'
import tabletDesignSM3 from '../image/sample/designing/site3/tablet/tabletDesignSM3.png'
import projectDesignSM3 from '../image/sample/designing/site3/tablet/projectDesignSM3.png'
import aboutUsDesignXS3 from '../image/sample/designing/site3/mobile/aboutUsDesignXS3.png'
import contactusDesignXS3 from '../image/sample/designing/site3/mobile/contactusDesignXS3.png'
import mobileDesignXS3 from '../image/sample/designing/site3/mobile/mobileDesignXS3.png'
import projectDesignXS3 from '../image/sample/designing/site3/mobile/projectDesignXS3.png'
import articleDesignXS3 from '../image/sample/designing/site3/mobile/articleDesignXS3.png'
import articlesDesignXS3 from '../image/sample/designing/site3/mobile/articlesDesignXS3.png'
import designing3 from '../image/sample/designing/site3/designing3.mov';
import amozeshMD11 from '../image/sample/amozeshi/site1/desktop/amozeshMD11.png'
import amozeshMD12 from '../image/sample/amozeshi/site1/desktop/amozeshMD12.png'
import amozeshMD13 from '../image/sample/amozeshi/site1/desktop/amozeshMD13.png'
import amozeshMD14 from '../image/sample/amozeshi/site1/desktop/amozeshMD14.png'
import amozeshMD15 from '../image/sample/amozeshi/site1/desktop/amozeshMD15.png'
import amozeshMD16 from '../image/sample/amozeshi/site1/desktop/amozeshMD16.png'
import amozeshMD17 from '../image/sample/amozeshi/site1/desktop/amozeshMD17.png'
import amozeshMD18 from '../image/sample/amozeshi/site1/desktop/amozeshMD18.png'
import amozeshMD19 from '../image/sample/amozeshi/site1/desktop/amozeshMD19.png'
import amozeshSM11 from '../image/sample/amozeshi/site1/tablet/amozeshSM11.png'
import amozeshSM12 from '../image/sample/amozeshi/site1/tablet/amozeshSM12.png'
import amozeshSM13 from '../image/sample/amozeshi/site1/tablet/amozeshSM13.png'
import amozeshSM14 from '../image/sample/amozeshi/site1/tablet/amozeshSM14.png'
import amozeshSM15 from '../image/sample/amozeshi/site1/tablet/amozeshSM15.png'
import amozeshSM16 from '../image/sample/amozeshi/site1/tablet/amozeshSM16.png'
import amozeshSM17 from '../image/sample/amozeshi/site1/tablet/amozeshSM17.png'
import amozeshSM18 from '../image/sample/amozeshi/site1/tablet/amozeshSM18.png'
import amozeshSM19 from '../image/sample/amozeshi/site1/tablet/amozeshSM19.png'
import amozeshXS11 from '../image/sample/amozeshi/site1/mobile/amozeshXS11.png'
import amozeshXS12 from '../image/sample/amozeshi/site1/mobile/amozeshXS12.png'
import amozeshXS13 from '../image/sample/amozeshi/site1/mobile/amozeshXS13.png'
import amozeshXS14 from '../image/sample/amozeshi/site1/mobile/amozeshXS14.png'
import amozeshXS15 from '../image/sample/amozeshi/site1/mobile/amozeshXS15.png'
import amozeshXS16 from '../image/sample/amozeshi/site1/mobile/amozeshXS16.png'
import amozeshXS17 from '../image/sample/amozeshi/site1/mobile/amozeshXS17.png'
import amozeshXS18 from '../image/sample/amozeshi/site1/mobile/amozeshXS18.png'
import amozeshXS19 from '../image/sample/amozeshi/site1/mobile/amozeshXS19.png'
import amozeshi1 from '../image/sample/amozeshi/site1/amozeshi1.mov';
import mockupAmozeshi1 from '../image/sample/amozeshi/site1/mockupAmozeshi1.png'
import amozeshMD21 from '../image/sample/amozeshi/site2/desktop/amozeshMD21.png'
import amozeshMD22 from '../image/sample/amozeshi/site2/desktop/amozeshMD22.png'
import amozeshMD23 from '../image/sample/amozeshi/site2/desktop/amozeshMD23.png'
import amozeshSM21 from '../image/sample/amozeshi/site2/tablet/amozeshSM21.png'
import amozeshSM22 from '../image/sample/amozeshi/site2/tablet/amozeshSM22.png'
import amozeshSM23 from '../image/sample/amozeshi/site2/tablet/amozeshSM23.png'
import amozeshXS21 from '../image/sample/amozeshi/site2/mobile/amozeshXS21.png'
import amozeshXS22 from '../image/sample/amozeshi/site2/mobile/amozeshXS22.png'
import amozeshXS23 from '../image/sample/amozeshi/site2/mobile/amozeshXS23.png'
import mockupAmozeshi2 from '../image/sample/amozeshi/site2/mockupAmozeshi2.png'
import amozeshi2 from '../image/sample/amozeshi/site2/amozeshi2.mov';
import amozeshMD31 from '../image/sample/amozeshi/site3/desktop/amozeshMD31.png'
import amozeshMD32 from '../image/sample/amozeshi/site3/desktop/amozeshMD32.png'
import amozeshMD33 from '../image/sample/amozeshi/site3/desktop/amozeshMD33.png'
import amozeshMD34 from '../image/sample/amozeshi/site3/desktop/amozeshMD34.png'
import amozeshSM31 from '../image/sample/amozeshi/site3/tablet/amozeshSM31.png'
import amozeshSM32 from '../image/sample/amozeshi/site3/tablet/amozeshSM32.png'
import amozeshSM33 from '../image/sample/amozeshi/site3/tablet/amozeshSM33.png'
import amozeshSM34 from '../image/sample/amozeshi/site3/tablet/amozeshSM34.png'
import amozeshXS31 from '../image/sample/amozeshi/site3/mobile/amozeshXS31.png'
import amozeshXS32 from '../image/sample/amozeshi/site3/mobile/amozeshXS32.png'
import amozeshXS33 from '../image/sample/amozeshi/site3/mobile/amozeshXS33.png'
import amozeshXS34 from '../image/sample/amozeshi/site3/mobile/amozeshXS34.png'
import mockupAmozeshi3 from '../image/sample/amozeshi/site3/mockupAmozeshi3.png'
import amozeshi3 from '../image/sample/amozeshi/site3/amozeshi3.mov';
import foodMD11 from '../image/sample/food/site1/desktop/foodMD11.png'
import foodMD12 from '../image/sample/food/site1/desktop/foodMD12.png'
import foodMD13 from '../image/sample/food/site1/desktop/foodMD13.png'
import foodMD14 from '../image/sample/food/site1/desktop/foodMD14.png'
import foodMD15 from '../image/sample/food/site1/desktop/foodMD15.png'
import foodSM11 from '../image/sample/food/site1/tablet/foodSM11.png'
import foodSM12 from '../image/sample/food/site1/tablet/foodSM12.png'
import foodSM13 from '../image/sample/food/site1/tablet/foodSM13.png'
import foodSM14 from '../image/sample/food/site1/tablet/foodSM14.png'
import foodSM15 from '../image/sample/food/site1/tablet/foodSM15.png'
import foodXS11 from '../image/sample/food/site1/mobile/foodXS11.png'
import foodXS12 from '../image/sample/food/site1/mobile/foodXS12.png'
import foodXS13 from '../image/sample/food/site1/mobile/foodXS13.png'
import foodXS14 from '../image/sample/food/site1/mobile/foodXS14.png'
import foodXS15 from '../image/sample/food/site1/mobile/foodXS15.png'
import mockupFood1 from '../image/sample/food/site1/mockupFood1.png'
import food1 from '../image/sample/food/site1/food1.mov'
import foodMD21 from '../image/sample/food/site2/desktop/foodMD21.png'
import foodMD22 from '../image/sample/food/site2/desktop/foodMD22.png'
import foodMD23 from '../image/sample/food/site2/desktop/foodMD23.png'
import foodMD24 from '../image/sample/food/site2/desktop/foodMD24.png'
import foodMD25 from '../image/sample/food/site2/desktop/foodMD25.png'
import foodSM21 from '../image/sample/food/site2/tablet/foodSM21.png'
import foodSM22 from '../image/sample/food/site2/tablet/foodSM22.png'
import foodSM23 from '../image/sample/food/site2/tablet/foodSM23.png'
import foodSM24 from '../image/sample/food/site2/tablet/foodSM24.png'
import foodSM25 from '../image/sample/food/site2/tablet/foodSM25.png'
import foodXS21 from '../image/sample/food/site2/mobile/foodXS21.png'
import foodXS22 from '../image/sample/food/site2/mobile/foodXS22.png'
import foodXS23 from '../image/sample/food/site2/mobile/foodXS23.png'
import foodXS24 from '../image/sample/food/site2/mobile/foodXS24.png'
import foodXS25 from '../image/sample/food/site2/mobile/foodXS25.png'
import mockupFood2 from '../image/sample/food/site2/mockupFood2.png'
import food2 from '../image/sample/food/site2/food2.mov'
import foodMD31 from '../image/sample/food/site3/desktop/foodMD31.png'
import foodMD32 from '../image/sample/food/site3/desktop/foodMD32.png'
import foodMD33 from '../image/sample/food/site3/desktop/foodMD33.png'
import foodSM31 from '../image/sample/food/site3/tablet/foodSM31.png'
import foodSM32 from '../image/sample/food/site3/tablet/foodSM32.png'
import foodSM33 from '../image/sample/food/site3/tablet/foodSM33.png'
import foodXS31 from '../image/sample/food/site3/mobile/foodXS31.png'
import foodXS32 from '../image/sample/food/site3/mobile/foodXS32.png'
import foodXS33 from '../image/sample/food/site3/mobile/foodXS33.png'
import mockupFood3 from '../image/sample/food/site3/mockupFood3.png'
import food3 from '../image/sample/food/site3/food3.mov'
import medicalMD11 from '../image/sample/pezeshki/site1/desktop/medicalMD11.png'
import medicalMD12 from '../image/sample/pezeshki/site1/desktop/medicalMD12.png'
import medicalMD13 from '../image/sample/pezeshki/site1/desktop/medicalMD13.png'
import medicalMD14 from '../image/sample/pezeshki/site1/desktop/medicalMD14.png'
import medicalMD15 from '../image/sample/pezeshki/site1/desktop/medicalMD15.png'
import medicalSM11 from '../image/sample/pezeshki/site1/tablet/medicalSM11.png'
import medicalSM12 from '../image/sample/pezeshki/site1/tablet/medicalSM12.png'
import medicalSM13 from '../image/sample/pezeshki/site1/tablet/medicalSM13.png'
import medicalSM14 from '../image/sample/pezeshki/site1/tablet/medicalSM14.png'
import medicalSM15 from '../image/sample/pezeshki/site1/tablet/medicalSM15.png'
import medicalXS11 from '../image/sample/pezeshki/site1/mobile/medicalXS11.png'
import medicalXS12 from '../image/sample/pezeshki/site1/mobile/medicalXS12.png'
import medicalXS13 from '../image/sample/pezeshki/site1/mobile/medicalXS13.png'
import medicalXS14 from '../image/sample/pezeshki/site1/mobile/medicalXS14.png'
import medicalXS15 from '../image/sample/pezeshki/site1/mobile/medicalXS15.png'
import mockupMedical1 from '../image/sample/pezeshki/site1/mockupMedical1.png'
import medical1 from '../image/sample/pezeshki/site1/medical1.mov';
import medicalMD21 from '../image/sample/pezeshki/site2/desktop/medicalMD21.png'
import medicalMD22 from '../image/sample/pezeshki/site2/desktop/medicalMD22.png'
import medicalMD23 from '../image/sample/pezeshki/site2/desktop/medicalMD23.png'
import medicalMD24 from '../image/sample/pezeshki/site2/desktop/medicalMD24.png'
import medicalMD25 from '../image/sample/pezeshki/site2/desktop/medicalMD25.png'
import medicalMD26 from '../image/sample/pezeshki/site2/desktop/medicalMD26.png'
import medicalMD27 from '../image/sample/pezeshki/site2/desktop/medicalMD27.png'
import medicalSM21 from '../image/sample/pezeshki/site2/tablet/medicalSM21.png'
import medicalSM22 from '../image/sample/pezeshki/site2/tablet/medicalSM22.png'
import medicalSM23 from '../image/sample/pezeshki/site2/tablet/medicalSM23.png'
import medicalSM24 from '../image/sample/pezeshki/site2/tablet/medicalSM24.png'
import medicalSM25 from '../image/sample/pezeshki/site2/tablet/medicalSM25.png'
import medicalSM26 from '../image/sample/pezeshki/site2/tablet/medicalSM26.png'
import medicalSM27 from '../image/sample/pezeshki/site2/tablet/medicalSM27.png'
import medicalXS21 from '../image/sample/pezeshki/site2/mobile/medicalXS21.png'
import medicalXS22 from '../image/sample/pezeshki/site2/mobile/medicalXS22.png'
import medicalXS23 from '../image/sample/pezeshki/site2/mobile/medicalXS23.png'
import medicalXS24 from '../image/sample/pezeshki/site2/mobile/medicalXS24.png'
import medicalXS25 from '../image/sample/pezeshki/site2/mobile/medicalXS25.png'
import medicalXS26 from '../image/sample/pezeshki/site2/mobile/medicalXS26.png'
import medicalXS27 from '../image/sample/pezeshki/site2/mobile/medicalXS27.png'
import mockupMedical2 from '../image/sample/pezeshki/site2/mockupMedical2.png'
import medical2 from '../image/sample/pezeshki/site2/medical2.mov';
import medicalMD31 from '../image/sample/pezeshki/site3/desktop/medicalMD31.png'
import medicalMD32 from '../image/sample/pezeshki/site3/desktop/medicalMD32.png'
import medicalMD33 from '../image/sample/pezeshki/site3/desktop/medicalMD33.png'
import medicalMD34 from '../image/sample/pezeshki/site3/desktop/medicalMD34.png'
import medicalSM31 from '../image/sample/pezeshki/site3/tablet/medicalSM31.png'
import medicalSM32 from '../image/sample/pezeshki/site3/tablet/medicalSM32.png'
import medicalSM33 from '../image/sample/pezeshki/site3/tablet/medicalSM33.png'
import medicalSM34 from '../image/sample/pezeshki/site3/tablet/medicalSM34.png'
import medicalXS31 from '../image/sample/pezeshki/site3/mobile/medicalXS31.png'
import medicalXS32 from '../image/sample/pezeshki/site3/mobile/medicalXS32.png'
import medicalXS33 from '../image/sample/pezeshki/site3/mobile/medicalXS33.png'
import medicalXS34 from '../image/sample/pezeshki/site3/mobile/medicalXS34.png'
import mockupMedical3 from '../image/sample/pezeshki/site3/mockupMedical3.png'
import medical3 from '../image/sample/pezeshki/site3/medical3.mov';
import travelMD11 from '../image/sample/travel/site1/desktop/travelMD11.png'
import travelMD12 from '../image/sample/travel/site1/desktop/travelMD12.png'
import travelMD13 from '../image/sample/travel/site1/desktop/travelMD13.png'
import travelMD14 from '../image/sample/travel/site1/desktop/travelMD14.png'
import travelSM11 from '../image/sample/travel/site1/tablet/travelSM11.png'
import travelSM12 from '../image/sample/travel/site1/tablet/travelSM12.png'
import travelSM13 from '../image/sample/travel/site1/tablet/travelSM13.png'
import travelSM14 from '../image/sample/travel/site1/tablet/travelSM14.png'
import travelXS11 from '../image/sample/travel/site1/mobile/travelXS11.png'
import travelXS12 from '../image/sample/travel/site1/mobile/travelXS12.png'
import travelXS13 from '../image/sample/travel/site1/mobile/travelXS13.png'
import travelXS14 from '../image/sample/travel/site1/mobile/travelXS14.png'
import mockupTravel1 from '../image/sample/travel/site1/mockupTravel1.png'
import travel1 from '../image/sample/travel/site1/travel1.mov'
import travelMD21 from '../image/sample/travel/site2/desktop/travelMD21.png'
import travelMD22 from '../image/sample/travel/site2/desktop/travelMD22.png'
import travelMD23 from '../image/sample/travel/site2/desktop/travelMD23.png'
import travelMD24 from '../image/sample/travel/site2/desktop/travelMD24.png'
import travelSM21 from '../image/sample/travel/site2/tablet/travelSM21.png'
import travelSM22 from '../image/sample/travel/site2/tablet/travelSM22.png'
import travelSM23 from '../image/sample/travel/site2/tablet/travelSM23.png'
import travelSM24 from '../image/sample/travel/site2/tablet/travelSM24.png'
import travelXS21 from '../image/sample/travel/site2/mobile/travelXS21.png'
import travelXS22 from '../image/sample/travel/site2/mobile/travelXS22.png'
import travelXS23 from '../image/sample/travel/site2/mobile/travelXS23.png'
import travelXS24 from '../image/sample/travel/site2/mobile/travelXS24.png'
import mockupTravel2 from '../image/sample/travel/site2/mockupTravel2.png'
import travel2 from '../image/sample/travel/site2/travel2.mov'
import travelMD31 from '../image/sample/travel/site3/desktop/travelMD31.png'
import travelMD32 from '../image/sample/travel/site3/desktop/travelMD32.png'
import travelMD33 from '../image/sample/travel/site3/desktop/travelMD33.png'
import travelMD34 from '../image/sample/travel/site3/desktop/travelMD34.png'
import travelMD35 from '../image/sample/travel/site3/desktop/travelMD35.png'
import travelMD36 from '../image/sample/travel/site3/desktop/travelMD36.png'
import travelMD37 from '../image/sample/travel/site3/desktop/travelMD37.png'
import travelSM31 from '../image/sample/travel/site3/tablet/travelSM31.png'
import travelSM32 from '../image/sample/travel/site3/tablet/travelSM32.png'
import travelSM33 from '../image/sample/travel/site3/tablet/travelSM33.png'
import travelSM34 from '../image/sample/travel/site3/tablet/travelSM34.png'
import travelSM35 from '../image/sample/travel/site3/tablet/travelSM35.png'
import travelSM36 from '../image/sample/travel/site3/tablet/travelSM36.png'
import travelSM37 from '../image/sample/travel/site3/tablet/travelSM37.png'
import travelXS31 from '../image/sample/travel/site3/mobile/travelXS31.png'
import travelXS32 from '../image/sample/travel/site3/mobile/travelXS32.png'
import travelXS33 from '../image/sample/travel/site3/mobile/travelXS33.png'
import travelXS34 from '../image/sample/travel/site3/mobile/travelXS34.png'
import travelXS35 from '../image/sample/travel/site3/mobile/travelXS35.png'
import travelXS36 from '../image/sample/travel/site3/mobile/travelXS36.png'
import travelXS37 from '../image/sample/travel/site3/mobile/travelXS37.png'
import mockupTravel3 from '../image/sample/travel/site3/mockupTravel3.png'
import travel3 from '../image/sample/travel/site3/travel3.mov'
import BeautyAndHealth from '../image/sample/BeautyAndHealth.png';
import Blog from '../image/sample/Blog.png';
import Business from '../image/sample/Business.png';
import designing from '../image/sample/designing.png';
import ECommerce from '../image/sample/ECommerce.png';
import educational from '../image/sample/educational.png';
import Entertainmentgame from '../image/sample/Entertainmentgame.png';
import Financial from '../image/sample/Financial.png';
import Food from '../image/sample/Food.png';
import Marketing from '../image/sample/Marketing.png';
import Other from '../image/sample/Other.png';
import picVideoMusic from '../image/sample/picVideoMusic.png';
import Technology from '../image/sample/Technology.png';
import medical from '../image/sample/medical.png';
import Travel from '../image/sample/Travel.png';


export const dataCategory = [
  {
    id: 1,
    title: "Designing",
    image: designing
  },
  {
    id: 2,
    title: "Food",
    image: Food
  },
  {
    id: 3,
    title: "Educational",
    image: educational
  },
  {
    id: 4,
    title: "Travel",
    image: Travel
  },
  {
    id: 5,
    title: "Medical",
    image: medical
  },
  {
    id: 6,
    title: "Technology",
    image: Technology
  },
  {
    id: 7,
    title: "Financial",
    image: Financial
  },
  {
    id: 8,
    title: "PicVideoMusic",
    image: picVideoMusic
  },
  {
    id: 9,
    title: "BeautyAndHealth",
    image: BeautyAndHealth
  },
  {
    id: 10,
    title: "Business",
    image: Business
  },
  {
    id: 11,
    title: "ECommerce",
    image: ECommerce
  },
  {
    id: 12,
    title: "Marketing",
    image: Marketing
  },
  {
    id: 13,
    title: "Entertainmentgame",
    image: Entertainmentgame
  },
  {
    id: 14,
    title: "Blog",
    image: Blog
  },
  {
    id: 15,
    title: "Other",
    image: Other
  }
]

export const dataDesign = [{
  id: 1,
  mockup: mockupDesign1,
  title: "TemplateOne",
  video: designing1,
  type: "Designing",
  hoverText: "5 صفحه شامل صفحه اصلی، پروژه ها و توضیحات پروژه، درباره ما و تماس با ما",
  desc: "این سایت شامل آیتم های خدمات، طراحی داخلی، روف گاردن، پروژه های در دست اقدام و پروژه های انجام شده می باشد. پروژه ها در این سایت به تفکیک نوع اطلاعات پروژه ، طراحی، مساحت و لوکیشن نمایش داده می شوند. همچنین خدمات ارائه شده در زمینه طراحی داخلی نیز نمایش داده می شود. بنابراین این قالب سایت برای طراحی داخلی مناسب می باشد. برای مثال سایت مهندسین 808 از این قالب استفاده کرده است.",
  loptop: [desktopDesign1, aboutUsDesign1, contactusDesign1, projectDesign1],
  tablet: [tabletDesignSM1, aboutUsDesignSM1, contactusDesignSM1, projectDesignSM1],
  mobile: [mobileDesignXS1, aboutUsDesignXS1, contactusDesignXS1, projectDesignXS1]
},
{
  id: 2,
  mockup: mockupDesign2,
  title: "TemplateTwo",
  video: designing2,
  type: "Designing",
  hoverText: "5 صفحه شامل لندینگ، پروژه ها و توضیحات پروژه، درباره ما و تماس با ما",
  desc: "در این سایت خدمات مقالات و پرژه های انجام شده نمایش داده می شود و در هر پروژه موارد انجام شده و تغییرات شکل گرفته کاملا توضیح داده می شود. این قالب برای پیمانکاران و ساختمان سازان بسیار مناسب است و در عین زیبایی تمام خدمات و پروژه ها را با تمام جزئیات به نمایش در می آورد. سایت معمار 98 هم از نسخه قدیمی این قالب استفاده کرده است.",
  loptop: [desktopDesignMD2, aboutUsDesignMD2, contactusDesignMD2, projectDesignMD2],
  tablet: [tabletDesignSM2, aboutUsDesignSM2, contactusDesignSM2, projectDesignSM2],
  mobile: [mobileDesignXS2, aboutUsDesignXS2, contactusDesignXS2, projectDesignXS2]
},
{
  id: 3,
  mockup: mockupDesign3,
  title: "TemplateThree",
  video: designing3,
  type: "Designing",
  hoverText: "6 صفحه شامل لندینگ، صفحه پروژه ها و توضیحات پروژه، درباره ما و تماس با ما",
  desc: "در این سایت مقالات مرتبط با طراحی داخلی و همچنین پروژه های انجام شده برای کاربران نمایش داده می شود. ویدئوی مرتبط با طراحی داخلی در سایت قرار می گیرد. قابلیت اضافه کردن انواع خدمات و محصولات صنعت ساختمان در این سایت به راحتی امکان پذیر است. سایت ایرانیان شهرساز از این نوع قالب استفاده کرده است.",
  loptop: [desktopDesignMD3, aboutUsDesignMD3, contactusDesignMD3, projectDesignMD3, ArticlesDesignMD3, ArticleDesignMD3],
  tablet: [tabletDesignSM3, aboutUsDesignSM3, contactusDesignSM3, projectDesignSM3, articleDesignSM3, ArticlesDesignSM3],
  mobile: [mobileDesignXS3, aboutUsDesignXS3, contactusDesignXS3, projectDesignXS3, articleDesignXS3, articlesDesignXS3]
},
]

export const dataAmozeshi = [{
  id: 1,
  mockup: mockupAmozeshi1,
  title: "TemplateOne",
  video: amozeshi1,
  type: "Educational",
  hoverText: "9 صفحه شامل صفحه لندینگ، ایندکس دوره، شو دوره، استادان، درباره ما، تماس با ما، مجوزها، استایل گاید و تغییرات یک سایت جامع و همه جانبه برای آموزشگاه ها و مراکز آموزشی می باشد که شامل آیتم هایی از قبیل دوره ها، بلاگ، معرفی استادان، مجوزها، استایل گاید و خرید دوره ها می باشد.",
  desc: "دوره ها شامل فیلم های آموزشی می باشد که برای هر دوره توضیحات نتایج مورد انتظار و جزئیات نمایش داده می شود. در قسمت معرفی استادان توضیحاتی درباره اساتید و راه ارتباطی با آنها نمایش داده می شود.در قسمت استایل گاید روش کلی و موجود برای بهره مندی از دوره ها توضیح داده می شود.",
  loptop: [amozeshMD11, amozeshMD12, amozeshMD13, amozeshMD14, amozeshMD15, amozeshMD16, amozeshMD17, amozeshMD18, amozeshMD19],
  tablet: [amozeshSM11, amozeshSM12, amozeshSM13, amozeshSM14, amozeshSM15, amozeshSM16, amozeshSM17, amozeshSM18, amozeshSM19],
  mobile: [amozeshXS11, amozeshXS12, amozeshXS13, amozeshXS14, amozeshXS15, amozeshXS16, amozeshXS17, amozeshXS18, amozeshXS19],
},
{
  id: 2,
  mockup: mockupAmozeshi2,
  title: "TemplateTwo",
  video: amozeshi2,
  type: "Educational",
  hoverText: "3 صفحه شامل لندینگ، اساتید و دوره ها",
  desc: "این سایت شامل دوره ها، معرفی اساتید، اخبار مرتبط با مجتمع آموزشی و دوره ها می باشد. در قسمت دوره با توجه به فیلترهای گذاشته شده کاربر می تواند به راحتی دوره مورد نظر خود را پیدا کند. این قالب برای کسب و کارهای حوزه فروش انواع دوره های انلاین مناسب است. قابلیت دانلود و مشاهده ویدئو آموزشی و پنل کاربری برای نمایش دوره ها را دارد.",
  loptop: [amozeshMD21, amozeshMD22, amozeshMD23],
  tablet: [amozeshSM21, amozeshSM22, amozeshSM23],
  mobile: [amozeshXS21, amozeshXS22, amozeshXS23],
},
{
  id: 3,
  mockup: mockupAmozeshi3,
  title: "TemplateThree",
  video: amozeshi3,
  type: "Educational",
  hoverText: "4 صفحه شامل لندینگ، درباره ما، ایندکس مقاله و نمایش مقاله",
  desc: "این سایت آموزشی آیتم های دوره ها، بلاگ را دارا می باشد. در این سایت تعرفه دوره ها و عنوان دوره و مدت زمان دوره مشخص شده است که برای فروش دوره های آموزشی به صورت آنلاین بسیار کاربردی می باشد. این قالب برای آموزشگاه های فیزیکی که تمایل به داشتن وب سایت برای برندینگ و معرفی اموزشگاه دارند، مناسب است.",
  loptop: [amozeshMD31, amozeshMD32, amozeshMD33, amozeshMD34],
  tablet: [amozeshSM31, amozeshSM32, amozeshSM33, amozeshSM34],
  mobile: [amozeshXS31, amozeshXS32, amozeshXS33, amozeshXS34],
},
]

export const dataFood = [{
  id: 1,
  mockup: mockupFood1,
  title: "TemplateOne",
  video: food1,
  type: "Food",
  hoverText: "5 صفحه شامل صفحه لندینگ، رزرو، منو، شعب و خدمات، ثبت نام شعبه، ثبت نام کاربر",
  desc: "سایتی با طراحی کاملا منحصر به فرد و متمایز برای افرادی که تمایل دارند سایتی کاملا خاص و زیبا داشته باشند که تمام آیتم های سایت در قسمت فوتر و پایین صفحه قرار گرفته شده است که شامل رزرو، منو، شعب ( رستوران ها ) و خدمات می باشد. این قالب برای رستوران های بزرگ و زنجیره ای مناسب است که بتواند بسته به موقعیت کاربر شعبه مناسب را معرفی  ثبت سفارش کند. سایت رستوران شاندیز از این نوع قالب استفاده کرده است.",
  loptop: [foodMD11, foodMD12, foodMD13, foodMD14, foodMD15],
  tablet: [foodSM11, foodSM12, foodSM13, foodSM14, foodSM15],
  mobile: [foodXS11, foodXS12, foodXS13, foodXS14, foodXS15],
},
{
  id: 2,
  mockup: mockupFood2,
  title: "TemplateTwo",
  video: food2,
  type: "Food",
  hoverText: "1 صفحه شامل لندگیگ می باشد که قابلیت رزرو میز را دارا است",
  desc: "ک سایت تک صفحه ای با قابلیت استفاده آسان برای تمام کاربران می باشد که آیتم هایی شامل منو غذا، معرفی تیم و رزرو را دارا می باشد. این قالب برای رستوران های تک شعبه ای و کوچک مناسب است. هزینه کمتر و تمام کارایی سفارش و خرید غذا را شامل می شود. سایت رستوران شمشیری از این قالب استفاده کرده است.",
  loptop: [foodMD21, foodMD22, foodMD23, foodMD24, foodMD25],
  tablet: [foodSM21, foodSM22, foodSM23, foodSM24, foodSM25],
  mobile: [foodXS21, foodXS22, foodXS23, foodXS24, foodXS25],
},
{
  id: 3,
  mockup: mockupFood3,
  title: "TemplateThree",
  video: food3,
  type: "Food",
  hoverText: "5 صفحه شامل صفحه لندینگ، ایندکس مقاله، شو مقاله، درباره ما، ثبت نام رستوران، ثبت نام کافه ها، ثبت نام و ورود کاربران، صفحه تاریخچه سفارشات، صفحه تبلیغات",
  desc: "این سایت که در حالت دارک طراحی شده است آیتم های منو، معرفی تیم و مقالات مرتبط با غذا را دارا می باشد.این قالب برای پلتفرم و مارکت پلیس هایی نظیر اسنپ فود مناسب است و هر نوع خدمات و محصولات غذایی قابلیت اضافه شدن در آن را دارد.",
  loptop: [foodMD31, foodMD32, foodMD33],
  tablet: [foodSM31, foodSM32, foodSM33],
  mobile: [foodXS31, foodXS32, foodXS33],
},
]

export const dataPezeshki = [{
  id: 1,
  mockup: mockupMedical1,
  title: "TemplateOne",
  video: medical1,
  type: "Medical",
  hoverText: "5 صفحه شامل لندینگ، درباره ما، ایندکس مقالات، شو مقالات، تماس با ما ",
  desc: "سایتی با طراحی بسیار ساده اما کاربردی است که در این سایت به معرفی پزشکان، مراحل درمان در مجموعه، تعیین وقت و مقالات می پردازد. سایت پزشکا از این قالب استفاده کرده است.",
  loptop: [medicalMD11, medicalMD12, medicalMD13, medicalMD14, medicalMD15],
  tablet: [medicalSM11, medicalSM12, medicalSM13, medicalSM14, medicalSM15],
  mobile: [medicalXS11, medicalXS12, medicalXS13, medicalXS14, medicalXS15],
},
{
  id: 2,
  mockup: mockupMedical2,
  title: "TemplateTwo",
  video: medical2,
  type: "Medical",
  hoverText: "5 صفحه شامل لندینگ، درباره ما، ایندکس مقالات، شو مقالات و تماس با ما",
  desc: "این سایت شامل آیتم های مفید برای معرفی کامل و دقیق یک سایت پزشکی شامل تعیین وقت آنلاین، خدمات، معرفی مجموعه و مقالات پزشکی مرتبط می باشد که در بخش خدمات تمام امکانات موجود در مجموعه نمایش داده می شود. همچنین در بخش معرفی مجموعه اطلاعات دقیقی درباره مجموعه سلامتی مدنظر قرار دارد. سایت پذیرش 24 از نسخه قدیمی این قالب استفاده می کند. این قالب برای پلتفرم های نوبت دهی که هم پزشکان و هم مراجعان دارای پنل متفاوت هستند بسیار کارا می باشد.",
  loptop: [medicalMD21, medicalMD22, medicalMD23, medicalMD24, medicalMD25, medicalMD26, medicalMD27],
  tablet: [medicalSM21, medicalSM22, medicalSM23, medicalSM24, medicalSM25, medicalSM26, medicalSM27],
  mobile: [medicalXS21, medicalXS22, medicalXS23, medicalXS24, medicalXS25, medicalXS26, medicalXS27],
},
{
  id: 3,
  mockup: mockupMedical3,
  title: "TemplateThree",
  video: medical3,
  type: "Medical",
  hoverText: "4 صفحه شامل لندینگ، فروشگاه، درباره ما و تماس با ما",
  desc: "این سایت برای فروش محصولات آرایشی و بهداشتی مناسب می باشد که امکان سرچ محصول در سایت قرار دارد. در قسمت فروشگاه که تمام محصولات قرار دارند فیلترهایی برای قرار دادن قیمت مدنظر کاربر در نظر گرفته شده  است تا کاربر به راحتی بتواند محصول مورد نظر خود را خریداری کند. این قالب برای کسب و کارهایی که محصولات آرایشی و بهداشتی بفروش می رسانند ایده آل است.برای مثال سایت سیب از این قالب استفاده می کند.",
  loptop: [medicalMD31, medicalMD32, medicalMD33, medicalMD34],
  tablet: [medicalSM31, medicalSM32, medicalSM33, medicalSM34],
  mobile: [medicalXS31, medicalXS32, medicalXS33, medicalXS34],
},
]

export const dataTravel = [{
  id: 1,
  mockup: mockupTravel1,
  title: "TemplateOne",
  video: travel1,
  type: "Travel",
  hoverText: "4 صفحه شامل لندینگ، مقالات، معرفی مکان، گالری تصاویر و فیلم، تماس با ما",
  desc: "سایتی مناسب برای گردشگری و طبیعت گردی که سفرهای مختلف صورت گرفته در سایت نمایش داده می شود و مقالات مرتبط با گردشگری نیز در این سایت قرار دارد. سایت کجارو از این نوع قالب استفاده کرده است.",
  loptop: [travelMD11, travelMD12, travelMD13, travelMD14],
  tablet: [travelSM11, travelSM12, travelSM13, travelSM14],
  mobile: [travelXS11, travelXS12, travelXS13, travelXS14],
},
{
  id: 2,
  mockup: mockupTravel2,
  title: "TemplateTwo",
  video: travel2,
  type: "Travel",
  hoverText: "4 صفحه شامل لندینگ، ایندکس مقاله، شو مقاله، تماس با ما",
  desc: "در این سایت تورهای مسافرتی به مکان های مختلف قرار دارد که قابیلت رزرو تور امکان پذیر می باشد. همچنین کاربر می تواند با سرچ، مکان مورد نظر خود برای مسافرت را پیدا کند. تورهایی که شامل تخفیف هستند و ویدئو از تورهای برگزار شده نیز وجود دارد. همچنین در این سایت با طراحی ترکیبی عکس و وکتور شکل خاص و جذابی برای کاربران نمایش می دهد که تورها و زمان برگزاری و رزرو آنها نیز در سایت قرار می گیرد. علاوه بر این در سایت ذکر شده مقالات مرتبط با گردشگری نیز قرار می گیرد. سایت علی بابا از این نوع قالب استفاده کرده است.",
  loptop: [travelMD21, travelMD22, travelMD23, travelMD24],
  tablet: [travelSM21, travelSM22, travelSM23, travelSM24],
  mobile: [travelXS21, travelXS22, travelXS23, travelXS24],
},
{
  id: 3,
  mockup: mockupTravel3,
  title: "TemplateThree",
  video: travel3,
  type: "Travel",
  hoverText: "7 صفحه شامل لندینگ، ایندکس تور، شو تور، ایندکس مقاله، شو مقاله، درباره ما و تماس با ما",
  desc: "این سایت با طراحی ترکیبی عکس و وکتور شکل خاص و جذابی را برای کاربران می تواند داشته باشد که تورها و زمان برگزاری و رزرو آنها در سایت قرار دارد. علاوه بر این مقالات مرتبط با گردشگری برای علاقه مندان در سایت قرار گرفته است. برای نمونه سایت اسنپ تریپ و الی گشت را می توان نام برد. ",
  loptop: [travelMD31, travelMD32, travelMD33, travelMD34, travelMD35, travelMD36, travelMD37],
  tablet: [travelSM31, travelSM32, travelSM33, travelSM34, travelSM35, travelSM36, travelSM37],
  mobile: [travelXS31, travelXS32, travelXS33, travelXS34, travelXS35, travelXS36, travelXS37],
},
]
