import { SvgIcon } from '@mui/material';
import React from 'react';
const DashboardIcon = ({ color }) => {
  return (
    <SvgIcon component="object" sx={{ height: "30px" }}>
      <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 7.41665V3.74998C13 3.49026 13.096 3.2724 13.288 3.0964C13.48 2.9204 13.7173 2.83271 14 2.83332H20C20.2833 2.83332 20.521 2.92132 20.713 3.09732C20.905 3.27332 21.0007 3.49087 21 3.74998V7.41665C21 7.67637 20.904 7.89423 20.712 8.07023C20.52 8.24623 20.2827 8.33393 20 8.33332H14C13.7167 8.33332 13.479 8.24532 13.287 8.06932C13.095 7.89332 12.9993 7.67576 13 7.41665ZM3 11.0833V3.74998C3 3.49026 3.096 3.2724 3.288 3.0964C3.48 2.9204 3.71734 2.83271 4 2.83332H10C10.2833 2.83332 10.521 2.92132 10.713 3.09732C10.905 3.27332 11.0007 3.49087 11 3.74998V11.0833C11 11.343 10.904 11.5609 10.712 11.7369C10.52 11.9129 10.2827 12.0006 10 12H4C3.71667 12 3.479 11.912 3.287 11.736C3.095 11.56 2.99934 11.3424 3 11.0833ZM13 18.4166V11.0833C13 10.8236 13.096 10.6057 13.288 10.4297C13.48 10.2537 13.7173 10.166 14 10.1666H20C20.2833 10.1666 20.521 10.2547 20.713 10.4307C20.905 10.6066 21.0007 10.8242 21 11.0833V18.4166C21 18.6764 20.904 18.8942 20.712 19.0702C20.52 19.2462 20.2827 19.3339 20 19.3333H14C13.7167 19.3333 13.479 19.2453 13.287 19.0693C13.095 18.8933 12.9993 18.6758 13 18.4166ZM3 18.4166V14.75C3 14.4903 3.096 14.2724 3.288 14.0964C3.48 13.9204 3.71734 13.8327 4 13.8333H10C10.2833 13.8333 10.521 13.9213 10.713 14.0973C10.905 14.2733 11.0007 14.4909 11 14.75V18.4166C11 18.6764 10.904 18.8942 10.712 19.0702C10.52 19.2462 10.2827 19.3339 10 19.3333H4C3.71667 19.3333 3.479 19.2453 3.287 19.0693C3.095 18.8933 2.99934 18.6758 3 18.4166Z" fill={color} />
      </svg>
    </SvgIcon>
  );
};
export default DashboardIcon;