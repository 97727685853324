import { Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import arrowRightPurple from '../image/icon/arrowRightPurple.png';
import arrowLeftPurple from '../image/icon/arrowLeftPurple.png';
import { useTranslation } from "react-i18next";
import application from '../image/services/application.png';
import SEO from '../image/services/SEO.png';
import SITEDESIGN from '../image/services/SITEDESIGN.png';
import UX from '../image/services/UX.png'
import { useNavigate } from "react-router-dom";
import ModalLogin from "./ModalLogin";

const Services = ({ auth }) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [selected, setSelected] = useState("W")
  const Navigate = useNavigate();

  const loginHandler = () => {
    setType("L");
    setOpen(true);
  }

  const handleCloseModal = () => {
    setOpen(false);
  }

  const btnHandeler = (value) => {
    let info = JSON.parse(localStorage.getItem('sorinInfoUser'));
    auth === false ? loginHandler() : info.user_type_id !== null && info.user_type_id === 1 || info.user_type_id !== null && info.user_type_id === 2 ? Navigate(`/SorinBoard/${value}`) : Navigate("/SorinBoard/NewOrder");
  }
  return (
    <>
      <Grid sx={{ display: "flex", width: "100%", justifyContent: "center", flexDirection: 'column', alignItems: "center", marginTop: { xs: "30px", sm: "60px", md: "100px" }, direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
        <Grid sx={{ display: "flex", width: "90%", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <Grid sx={{ display: "flex", flexDirection: i18n.language === "fa" ? "row" : "row-reverse", justifyContent: "center", alignItems: "center", width: "100%", marginBottom: { xs: "20px", sm: "35px", md: "50px" }, marginTop: { xs: "20px", md: "0" } }}>
            <Typography component={"img"} alt="icon" src={arrowRightPurple} sx={{ width: { xs: "14px", sm: "39px", md: "40px", lg: "54px" }, height: { xs: "17px", sm: "53px", md: "55px", lg: "69px" }, margin: "0 3px" }} />
            <Typography component={"p"} sx={{
              fontWeight: "700", fontSize: { xs: "12px", sm: "24px", md: "32px" }, textAlign: "center", lineHeight: { xs: "17px", md: "45px" }
            }}>{t("servicesTitle")}</Typography>
            <Typography component={"img"} alt="icon" src={arrowLeftPurple} sx={{ width: { xs: "14px", sm: "39px", md: "40px", lg: "54px" }, height: { xs: "17px", sm: "53px", md: "55px", lg: "69px" }, margin: "0 3px" }} />
          </Grid>
          <Grid sx={{
            display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row", width: "100%", background: " #FFFCFC", border: "0.5px solid #FF3951", borderRadius: "16px",
            height: { xs: "45px", sm: "77px", lg: "84px" }, padding: { xs: "0 15px", md: "0 50px", lg: "0 100px" }
          }}>
            <Grid onClick={() => setSelected("W")} sx={{
              display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer",
              borderBottom: selected === "W" ? { xs: "3px solid #FF3951", sm: "4px solid #FF3951", xl: "6px solid #FF3951" } : "unset", height: { xs: "45px", sm: "77px", lg: "84px" }
            }}>
              <Typography sx={{ fontSize: { xs: "10px", sm: "18px", md: "20px", xl: "22px" }, color: selected === "W" ? "#FF3951" : "#062B33", transition: "all 0.5s", "&:hover": { transform: "translateY(-5px)" } }}>{t("WebDesign")}</Typography>
            </Grid>
            <Grid onClick={() => setSelected("U")} sx={{
              display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer",
              borderBottom: selected === "U" ? { xs: "3px solid #FF3951", sm: "4px solid #FF3951", xl: "6px solid #FF3951" } : "unset", height: { xs: "45px", sm: "77px", lg: "84px" }
            }}>
              <Typography sx={{ fontSize: { xs: "10px", sm: "18px", md: "20px", xl: "22px" }, color: selected === "U" ? "#FF3951" : "#062B33", transition: "all 0.5s", "&:hover": { transform: "translateY(-5px)" } }}>{t("UIUX")}</Typography>
            </Grid>
            <Grid onClick={() => setSelected("A")} sx={{
              display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer",
              borderBottom: selected === "A" ? { xs: "3px solid #FF3951", sm: "4px solid #FF3951", xl: "6px solid #FF3951" } : "unset", height: { xs: "45px", sm: "77px", lg: "84px" }
            }}>
              <Typography sx={{ fontSize: { xs: "10px", sm: "18px", md: "20px", xl: "22px" }, color: selected === "A" ? "#FF3951" : "#062B33", transition: "all 0.5s", "&:hover": { transform: "translateY(-5px)" } }}>{t("Application")}</Typography>
            </Grid>
            <Grid onClick={() => setSelected("S")} sx={{
              display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer",
              borderBottom: selected === "S" ? { xs: "3px solid #FF3951", sm: "4px solid #FF3951", xl: "6px solid #FF3951" } : "unset", height: { xs: "45px", sm: "77px", lg: "84px" }
            }}>
              <Typography sx={{ fontSize: { xs: "10px", sm: "18px", md: "20px", xl: "22px" }, color: selected === "S" ? "#FF3951" : "#062B33", transition: "all 0.5s", "&:hover": { transform: "translateY(-5px)" } }}>{t("seoLabel")}</Typography>
            </Grid>
          </Grid>
          <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", margin: { xs: "30px 0", md: "50px 0", lg: "100px 0" } }}>
            <Grid sx={{ display: selected === "W" ? "flex" : "none", alignItems: "center", justifyContent: "space-evenly", width: "100%", flexDirection: "row" }}>
              <Grid title={"Web Design"} sx={{
                display: "flex", alignItems: "center", justifyContent: "center", background: `url(${SITEDESIGN})`, backgroundPosition: "center", backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat", width: { xs: "132px", md: "360px", lg: "600px", xl: "633px" }, height: { xs: "144px", md: "360px", lg: "600px", xl: "633px" }
              }}></Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "154px", sm: "360px", lg: "612px", xl: "676px" }, flexDirection: "column" }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "22px", md: "24px", lg: "32px", xl: "36px" }, color: "#FF3951", fontWeight: "700" }}>{t("WebDesign")}</Typography>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", width: "100%", flexDirection: "column" }}>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("webDesignDesc1")}</Typography>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("webDesignDesc2")}</Typography>
                </Grid>
                <Button onClick={() => btnHandeler("WebsiteOrder")} sx={{
                  bgcolor: "#FF3951", width: { xs: "155px", sm: "184px", lg: "277px", xl: "318px" }, borderRadius: "8px", color: "white", fontSize: { xs: "12px", lg: "14px", xl: "20px" },
                  height: { xs: "34px", sm: "50px", md: "50px", lg: "73px", xl: "67px" }, boxShadow: " #FF3951 0px 5px 15px", margin: "30px auto", "&:hover": { backgroundColor: "#FF3951" }
                }}>
                  {t("orderWebDesign")}
                </Button>
              </Grid>
            </Grid>
            <Grid sx={{ display: selected === "U" ? "flex" : "none", alignItems: "center", justifyContent: "space-evenly", width: "100%", flexDirection: "row" }}>
              <Grid title={"UIUX"} sx={{
                display: "flex", alignItems: "center", justifyContent: "center", background: `url(${UX})`, backgroundPosition: "center", backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat", width: { xs: "132px", md: "360px", lg: "600px", xl: "633px" }, height: { xs: "144px", md: "360px", lg: "600px", xl: "633px" }
              }}></Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "154px", sm: "360px", lg: "612px", xl: "676px" }, flexDirection: "column" }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "22px", md: "24px", lg: "32px", xl: "36px" }, color: "#FF3951", fontWeight: "700" }}>{t("UIUX")}</Typography>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", width: "100%", flexDirection: "column" }}>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("uiuxDesc1")}</Typography>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("uiuxDesc2")}</Typography>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("uiuxDesc3")}</Typography>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("uiuxDesc4")}</Typography>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("uiuxDesc5")}</Typography>
                </Grid>
                <Button onClick={() => btnHandeler("UIUXorder")} sx={{
                  bgcolor: "#FF3951", width: { xs: "155px", sm: "184px", lg: "277px", xl: "318px" }, borderRadius: "8px", color: "white", fontSize: { xs: "12px", lg: "14px", xl: "20px" },
                  height: { xs: "34px", sm: "50px", md: "50px", lg: "73px", xl: "67px" }, boxShadow: " #FF3951 0px 5px 15px", margin: "30px auto", "&:hover": { backgroundColor: "#FF3951" }
                }}>
                  {t("UIUXorder")}
                </Button>
              </Grid>
            </Grid>
            <Grid sx={{ display: selected === "A" ? "flex" : "none", alignItems: "center", justifyContent: "space-evenly", width: "100%", flexDirection: "row" }}>
              <Grid title={"Application"} sx={{
                display: "flex", alignItems: "center", justifyContent: "center", background: `url(${application})`, backgroundPosition: "center", backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat", width: { xs: "132px", md: "360px", lg: "600px", xl: "633px" }, height: { xs: "144px", md: "360px", lg: "600px", xl: "633px" }
              }}></Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "154px", sm: "360px", lg: "612px", xl: "676px" }, flexDirection: "column" }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "22px", md: "24px", lg: "32px", xl: "36px" }, color: "#FF3951", fontWeight: "700" }}>{t("Application")}</Typography>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", width: "100%", flexDirection: "column" }}>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("appDesc1")}</Typography>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("appDesc2")}</Typography>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("appDesc3")}</Typography>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("appDesc4")}</Typography>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("appDesc5")}</Typography>
                </Grid>
                <Button onClick={() => btnHandeler("AppOrder")} sx={{
                  bgcolor: "#FF3951", width: { xs: "155px", sm: "184px", lg: "277px", xl: "318px" }, borderRadius: "8px", color: "white", fontSize: { xs: "12px", lg: "14px", xl: "20px" },
                  height: { xs: "34px", sm: "50px", md: "50px", lg: "73px", xl: "67px" }, boxShadow: " #FF3951 0px 5px 15px", margin: "30px auto", "&:hover": { backgroundColor: "#FF3951" }
                }}>
                  {t("orderApplication")}
                </Button>
              </Grid>
            </Grid>
            <Grid sx={{ display: selected === "S" ? "flex" : "none", alignItems: "center", justifyContent: "space-evenly", width: "100%", flexDirection: "row" }}>
              <Grid title={"Application"} sx={{
                display: "flex", alignItems: "center", justifyContent: "center", background: `url(${SEO})`, backgroundPosition: "center", backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat", width: { xs: "132px", md: "360px", lg: "600px", xl: "633px" }, height: { xs: "144px", md: "360px", lg: "600px", xl: "633px" }
              }}></Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "154px", sm: "360px", lg: "612px", xl: "676px" }, flexDirection: "column" }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "22px", md: "24px", lg: "32px", xl: "36px" }, color: "#FF3951", fontWeight: "700" }}>{t("seoLabel")}</Typography>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", width: "100%", flexDirection: "column" }}>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("seoDesc1")}</Typography>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("seoDesc2")}</Typography>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("seoDesc3")}</Typography>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("seoDesc4")}</Typography>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("seoDesc5")}</Typography>
                  <Typography component={"p"} sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px", xl: "18px" }, textAlign: "justify", color: "#757575", margin: "3px 0" }}>{t("seoDesc6")}</Typography>
                </Grid>
                <Button onClick={() => btnHandeler("SeoOrder")} sx={{
                  bgcolor: "#FF3951", width: { xs: "155px", sm: "184px", lg: "277px", xl: "318px" }, borderRadius: "8px", color: "white", fontSize: { xs: "12px", lg: "14px", xl: "20px" },
                  height: { xs: "34px", sm: "50px", md: "50px", lg: "73px", xl: "67px" }, boxShadow: " #FF3951 0px 5px 15px", margin: "30px auto", "&:hover": { backgroundColor: "#FF3951" }
                }}>
                  {t("orderSeo")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ModalLogin open={open} close={handleCloseModal} data={type} />
    </>
  );
}

export default Services