
import { Grid, Tab, Tabs, Typography, FormControl, Select, MenuItem, Modal, Button, TextField, Radio, FormControlLabel, RadioGroup, CircularProgress, IconButton, Input, FormGroup, Checkbox } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import InputColor from 'react-input-color';
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { baseUrl, separator, onlyFloatnaumber } from "./modules/general";
import axios from "axios";
import AlertMessage from "./modules/AlertMessage";
import logo from '../image/dashboard/logo.png';
import avatar from '../image/dashboard/avatar.png';
import upload from '../image/dashboard/upload.png'
import Resizer from "react-image-file-resizer";
import { setCanonicalTag } from "./modules/general";
import Loader from "./Loader";


let dataHead = [];
let color = [];
let dataEdit = null;
let titleModal = [];
let imageFile = null;
const StatusOrder = ({ infoUser }) => {

  const { t, i18n } = useTranslation();
  const [value, setValue] = useState('OrderSite');
  const [TabSize, setTabSize] = useState(window.innerWidth <= 600 ? "vertical" : "horizontal");
  const [openModal, setOpenModal] = useState(false);
  const [dataTextFiled, setDataTextFiled] = useState({})
  const [color1, setColor1] = useState("");
  const [color2, setColor2] = useState("");
  const [color3, setColor3] = useState("");
  const [color4, setColor4] = useState("");
  const [alert, setAlert] = useState({ alertCheck: false, message: "", severity: "" });
  const [dataSite, setDataSite] = useState(null);
  const [dataActivity, setDataActivity] = useState(null);
  const [dataSiteType, setDataSiteType] = useState(null);
  const [dataCondition, setDataCondition] = useState(null);
  const [activity, setActivity] = useState("");
  const [type, setType] = useState("");
  const [radioSeo, setRadioSeo] = useState(1);
  const [radioDomain, setRadioDomain] = useState(1);
  const [radioHost, setRadioHost] = useState(1);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [btnEdit, setBtnEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [btnDelete, setBtnDelete] = useState(false);
  const [dataFront, setDataFront] = useState(null);
  const [imageResize, setImageResize] = useState(null)
  const [imageSrc, setImageSrc] = useState(null);
  const [dataProgram, setDataProgram] = useState(null);
  const [fileDocumation, setFileDocumation] = useState(null);
  const [nameFile, setNameFile] = useState(null);
  const [dataSeo, setDataSeo] = useState(null);
  const [dataGraphic, setDataGraphic] = useState(null);
  const [dataGraphicType, setDataGraphicType] = useState(null);
  const [graphicType, setGraphicType] = useState("")
  const [dataApp, setDataApp] = useState(null);
  const [android, setAndroid] = useState(0);
  const [pwa, setPwa] = useState(0);
  const [ios, setIos] = useState(0);
  const [info, setInfo] = useState(null);

  const headerSite = ["number", "type", "activity", "maximum_budget", "minimum_budget"];
  const headerUI = ["number", "color", "goal", "use"];
  const headerGraphic = ["number", "color", "graphic_type_id"];
  const headerProgramming = ["number", "activity", "minimum_budget", "maximum_budget"];
  const headerApplication = ["number", "app_name", "activity"];
  const headerSeo = ["number", "site_name", "site_address", "activity"]
  const titleSite = ["condition", "type", "activity", "maximum_budget", "minimum_budget", "domain", "seo", "host", "goal_customer", "description", "similar_sites", "special_features"]
  const titleUi = ["condition", "goal", "use", "color", "description", "logo_path"];
  const titleProgrammer = ["condition", "activity", "minimum_budget", "maximum_budget", "description", "documentation"];
  const titleSeo = ["condition", "site_name", "site_address", "activity", "keyword", "minimum_budget", "maximum_budget", "textual_cotent_number", "visual_cotent_number", "video_cotent_number", "description"];
  const titleGraphic = ["condition", "graphic_type_id", "color", "description"];
  const titleApplication = ["condition", "app_name", "site_name", "site_address", "activity", "maximum_budget", "minimum_budget", "goal_customer", "similar_apps", "special_features", "description", "applicationOperatingSystem"]

  useEffect(() => {
    document.title = "وضعیت سفارش طراحی سایت | وضعیت سفارش UI/UX |  وضعیت سفارش سئو";
    document.getElementById("main-heading").innerHTML = "وضعیت سفارش طراحی سایت | وضعیت سفارش UI/UX |  وضعیت سفارش سئو";
    document.getElementsByTagName('meta')["description"].content = "";
    document.getElementsByTagName('meta')["keywords"].content = "وضعیت سفارش برنامه نویسی | وضعیت سفارش طراحی گرافیک | وضعیت سفارش طراحی کاربری | وضعیت سفارش تجربه کاربری | وضعیت سفارش بهینه سازی سایت برای موتور جستجوگر";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    setInfo(JSON.parse(localStorage.getItem('sorinInfoUser')));
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => resizeTab());
    return (() => window.removeEventListener('resize', () => resizeTab()));
  }, [window.innerWidth]);

  useEffect(() => {
    getActivity();
    getSiteType();
    getCondition();
    getGraphicType();
  }, [])

  useEffect(() => {
    switch (value) {
      case ("OrderSite"):
        getSite();
        break;
      case ("UIUXorder"):
        getFront();
        break;
      case ("OrderProgrammer"):
        getProgram();
        break;
      case ("OrderSeo"):
        getSeo();
        break;
      case ("OrderGraphic"):
        getGraphic();
        break;
      case ("OrderApplication"):
        getApplication();
        break;
    }
  }, [value])

  useEffect(() => {
    if (dataEdit !== null) {
      color = dataEdit.hasOwnProperty("color") && dataEdit.color !== null ? JSON.parse(dataEdit.color) : "";
      if (color !== "") {
        setColor1(color[0] !== undefined ? color[0] : "#ffffff")
        setColor2(color[1] !== undefined ? color[1] : "#ffffff")
        setColor3(color[2] !== undefined ? color[2] : "#ffffff")
        setColor4(color[3] !== undefined ? color[3] : "#ffffff")
      }
    }
  }, [dataEdit])

  const getSite = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/site/history`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataSite(res.data.data)
      })
  }

  const getProgram = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/program/history`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataProgram(res.data.data)
      })
  }

  const getFront = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/front/history`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataFront(res.data.data)
      })
  }

  const getSeo = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/seo/history`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataSeo(res.data.data)
      })
  }

  const getGraphic = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/graphic/history`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataGraphic(res.data.data)
      })
  }

  const getApplication = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/app/history`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataApp(res.data.data)
      })
  }

  const getActivity = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/activity/get`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataActivity(res.data.data)
      })
  }

  const getCondition = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/condition/get`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataCondition(res.data.data)
      })
  }

  const getSiteType = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/st/get`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataSiteType(res.data.data)
      })
  }

  const getGraphicType = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/tg/get`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataGraphicType(res.data.data)
      })
  }

  const descActivity = (data) => {
    let findElement;
    dataActivity !== null ?
      findElement = dataActivity.find(element => element.id === data)
      :
      findElement = null;
    return findElement !== null ? findElement.description : null;
  }

  const descCondition = (data) => {
    let findElement;
    dataCondition !== null ?
      findElement = dataCondition.find(element => element.id === data)
      :
      findElement = null
    return findElement !== null ? findElement.description : null;
  }

  const descSiteType = (data) => {
    let findElement;
    dataActivity !== null ?
      findElement = dataSiteType.find(element => element.id === data)
      :
      findElement = null;
    return findElement !== null ? findElement.description : null;
  }

  const descGraphicType = (data) => {
    let findElement;
    dataGraphicType !== null ?
      findElement = dataGraphicType.find(element => element.id === data)
      :
      findElement = null;
    return findElement !== null ? findElement.description : null;
  }

  const head = () => {
    let list = [];
    dataHead = value === "OrderSite" ? headerSite : value === "UIUXorder" ? headerUI : value === "OrderProgrammer" ? headerProgramming : value === "OrderSeo" ? headerSeo : value === "OrderGraphic" ? headerGraphic : value === "OrderApplication" ? headerApplication : "";
    dataHead.forEach((item, index) => {
      list.push(
        <Grid key={index} sx={{ display: { xs: item === "number" ? "none" : "flex", sm: "flex" }, height: { xs: "60px", sm: "15%" }, alignItems: "center", justifyContent: "center", width: "20%" }}>
          <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px" }, color: "#070707", fontWeight: "700", textAlign: 'center', }}>{t(item)}</Typography>
        </Grid>
      )
    })
    return list;
  }

  const dataGrid = () => {
    let data = null;
    switch (value) {
      case ("OrderSite"):
        if (dataSite !== null) data = dataSite
        break;
      case ("UIUXorder"):
        if (dataFront !== null) data = dataFront
        break;
      case ("OrderProgrammer"):
        if (dataProgram !== null) data = dataProgram
        break;
      case ("OrderSeo"):
        if (dataSeo !== null) data = dataSeo
        break;
      case ("OrderGraphic"):
        if (dataGraphic !== null) data = dataGraphic
        break;
      case ("OrderApplication"):
        if (dataApp !== null) data = dataApp
    }
    let list = [];
    if ((data === null) || (dataActivity === null) || (dataGraphicType === null) || (dataSiteType === null)) {
      list.push(
        <Loader key={"data1"} />
      )
    }
    else if (data.length === 0) {
      list.push(
        <Typography key={"data2"} sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707" }}>{t("notOrder")}</Typography>
      )
    }
    else if ((data !== null) && (data.length !== 0) && (dataActivity !== null) && (dataGraphicType !== null) && (dataSiteType !== null)) {
      data.forEach((item, index) => {
        list.push(
          <Grid key={item.id} onClick={() => modalHandeler(item)} sx={{
            display: 'flex', alignItems: "center", justifyContent: 'center', width: "100%", backgroundColor: parseInt(index + 1) % 2 === 0 ? "#FFFFFF" : "#EDEDED",
            border: "1px solid #757575", borderRadius: "8px", margin: { xs: "15px 5px 0 0", sm: "15px 0 0 0" }, cursor: "pointer", "&:hover": { boxShadow: " 0px 8px 3px 2px rgba(0, 0, 0, 0.25)" }
          }}>
            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-evenly", width: "100%", height: "40px", flexDirection: "row", }}>
              {dataHead.map((value, index) => (
                value === "number" ?
                  <Grid key={index} sx={{ display: { xs: value === "number" ? "none" : "flex", sm: "flex" }, alignItems: "center", justifyContent: "center", width: "20%", height: { xs: "60px", sm: "unset" } }}>
                    <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{index + 1}</Typography>
                  </Grid>
                  :
                  value === "type" ?
                    <Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "20%", height: { xs: "60px", sm: "unset" } }}>
                      <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{descSiteType(item.type.id)}</Typography>
                    </Grid>
                    :
                    value === "activity" ?
                      <Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "20%", height: { xs: "60px", sm: "unset" } }}>
                        <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{item.activity !== null ? descActivity(item.activity_type_id) : t("NotCompleted")}</Typography>
                      </Grid>
                      :
                      value === "graphic_type_id" ?
                        <Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "20%", height: { xs: "60px", sm: "unset" } }}>
                          <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{descGraphicType(item.graphic_type_id)}</Typography>
                        </Grid>
                        :
                        value === "color" ?
                          <Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "20%", height: { xs: "60px", sm: "unset" } }}>
                            {item[value] !== null ? colorPalletFun(item[value]) :
                              <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{t("NotCompleted")}</Typography>}
                          </Grid>
                          :
                          <Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "20%", height: { xs: "60px", sm: "unset" } }}>
                            <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{item[value] !== null ? t(item[value]) : t("NotCompleted")}</Typography>
                          </Grid>
              ))}
            </Grid>
          </Grid>
        )
      })
    }
    return list;
  }

  const siteData = () => {
    let site = {
      special_features: dataTextFiled.special_features,
      seo: radioSeo,
      domain: radioDomain,
      host: radioHost,
      minimum_budget: dataTextFiled.minimum_budget,
      maximum_budget: dataTextFiled.maximum_budget,
      similar_sites: dataTextFiled.similar_sites,
      description: dataTextFiled.description,
      site_id: dataEdit.id,
      type_site_id: dataTextFiled.type.id,
      activity_type_id: activity,
      goal_customer: dataTextFiled.goal_customer
    }
    return site;
  }

  const uiData = () => {
    let color = [];
    const formData = new FormData();
    color = [color1, color2, color3, color4]
    color.forEach((item, index) => {
      if (item === "#ffffff") {
        color.splice(index, 1);
      }
    })
    if (imageResize !== null) { formData.append("logo", imageResize) }
    formData.append("color", JSON.stringify(color))
    formData.append("goal", dataTextFiled.goal)
    formData.append("use", dataTextFiled.use)
    formData.append("description", dataTextFiled.description)
    formData.append("front_id", dataEdit.id)
    return formData;
  }

  const programmerData = () => {
    const formData = new FormData();
    if (fileDocumation !== null) { formData.append("documentation", fileDocumation) }
    formData.append("activity_type_id", activity)
    formData.append("minimum_budget", dataTextFiled.minimum_budget)
    formData.append("maximum_budget", dataTextFiled.maximum_budget)
    formData.append("description", dataTextFiled.description)
    formData.append("program_id", dataEdit.id)
    return formData;
  }

  const seoData = () => {
    let seo = {
      site_name: dataTextFiled.site_name,
      site_address: dataTextFiled.site_address,
      activity_type_id: activity,
      keyword: dataTextFiled.keyword,
      minimum_budget: dataTextFiled.minimum_budget,
      maximum_budget: dataTextFiled.maximum_budget,
      textual_cotent_number: dataTextFiled.textual_cotent_number,
      visual_cotent_number: dataTextFiled.visual_cotent_number,
      video_cotent_number: dataTextFiled.video_cotent_number,
      description: dataTextFiled.description,
      seo_id: dataEdit.id,
    }
    return seo;
  }

  const GraphicData = () => {
    let color = [];
    color = [color1, color2, color3, color4]
    color.forEach((item, index) => {
      if (item === "#ffffff") {
        color.splice(index, 1);
      }
    })
    let graphic = {
      color: color,
      graphic_type_id: graphicType,
      description: dataTextFiled.description,
      graphic_id: dataEdit.id,
    }
    return graphic;
  }

  const applicationData = () => {
    let data = {
      site_name: dataTextFiled.site_name,
      app_name: dataTextFiled.app_name,
      activity_type_id: activity,
      site_address: dataTextFiled.site_address,
      goal_customer: dataTextFiled.goal_customer,
      minimum_budget: dataTextFiled.minimum_budget,
      maximum_budget: dataTextFiled.maximum_budget,
      special_features: dataTextFiled.special_features,
      similar_apps: dataTextFiled.similar_apps,
      android: android ? 1 : 0,
      ios: ios ? 1 : 0,
      pwa: pwa ? 1 : 0,
      description: dataTextFiled.description,
      app_id: dataEdit.id
    }
    return data;
  }

  const editData = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    let header = null;
    let data = null;

    switch (value) {
      case ("OrderSite"):
        data = siteData();
        header = { 'Authorization': AuthStr }
        edit(data, "S", header);
        break;

      case ("UIUXorder"):
        data = uiData();
        header = {
          'Authorization': AuthStr,
          "Content-Type": "multipart/form-data"
        }
        edit(data, "U", header);
        break;
      case ("OrderProgrammer"):
        data = programmerData();
        header = {
          'Authorization': AuthStr,
          "Content-Type": "multipart/form-data"
        }
        edit(data, "P", header);
        break
      case ("OrderSeo"):
        data = seoData();
        header = { 'Authorization': AuthStr }
        edit(data, "SE", header);
        break;
      case ("OrderGraphic"):
        data = GraphicData();
        header = { 'Authorization': AuthStr }
        edit(data, "G", header);
        break
      case ("OrderApplication"):
        data = applicationData();
        header = { 'Authorization': AuthStr }
        edit(data, "A", header);
        break
    }
  }

  const edit = (data, type, header) => {
    let url;
    setBtnEdit(true);
    setLoadingEdit(true);

    switch (type) {
      case ("S"):
        url = '/api/v1/site/edit';
        break;
      case ("U"):
        url = '/api/v1/front/edit';
        break;
      case ("P"):
        url = '/api/v1/program/edit';
        break;
      case ("SE"):
        url = '/api/v1/seo/edit';
        break;
      case ("G"):
        url = '/api/v1/graphic/edit';
        break;
      case ("A"):
        url = '/api/v1/app/edit';
        break;
    }

    Promise.resolve(axios({
      url: baseUrl + url,
      method: "POST",
      headers: header,
      data: data
    }))
      .then((res) => {
        setBtnEdit(false);
        setLoadingEdit(false);
        setAlert({ alertCheck: true, message: t("AM_editSuccess"), severity: "success" })
        switch (type) {
          case ("S"):
            getSite();
            break;
          case ("U"):
            getFront();
            break;
          case ("P"):
            getProgram();
            break;
          case ("SE"):
            getSeo();
            break;
          case ("G"):
            getGraphic();
            break;
          case ("A"):
            getApplication();
            break;
        }
        handleCloseModal()
      })
      .catch(
        (error) => {
          const { status } = error.response;
          switch (status) {
            case (500):
              setAlert({ alertCheck: true, message: t("AM_intervalServer"), severity: "error" })
              break;
            case (400):
              setAlert({ alertCheck: true, message: t("AM_BadRequest"), severity: "error" })
              break;
            case (404):
              setAlert({ alertCheck: true, message: t("AM_NotOrder"), severity: "error" })
              break;
            default:
              setAlert({ alertCheck: true, message: t("alert_defualt"), severity: "error" })
          }
          setBtnEdit(false);
          setLoadingEdit(false);
        }
      )

  }

  const deleteOrderHandeler = () => {
    let data = null;
    switch (value) {
      case ("OrderSite"):
        data = { site_id: dataEdit.id };
        deleteOrder(data, "S");
        break;
      case ("UIUXorder"):
        data = { front_id: dataEdit.id };
        deleteOrder(data, "U");
        break;
      case ("OrderProgrammer"):
        data = { program_id: dataEdit.id };
        deleteOrder(data, "P");
        break;
      case ("OrderSeo"):
        data = { seo_id: dataEdit.id };
        deleteOrder(data, "SE");
        break;
      case ("OrderGraphic"):
        data = { graphic_id: dataEdit.id };
        deleteOrder(data, "G");
        break;
      case ("OrderApplication"):
        data = { app_id: dataEdit.id };
        deleteOrder(data, "A");
        break;
    }
  }

  const deleteOrder = (data, type) => {
    let url;
    setBtnDelete(true);
    setLoadingDelete(true);
    switch (type) {
      case ("S"):
        url = '/api/v1/site/delete';
        break;
      case ("U"):
        url = '/api/v1/front/delete';
        break;
      case ("P"):
        url = '/api/v1/program/delete';
        break;
      case ("SE"):
        url = '/api/v1/seo/delete';
        break;
      case ("G"):
        url = '/api/v1/graphic/delete';
        break;
      case ("A"):
        url = '/api/v1/app/delete';
        break;
    }
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + url,
      method: "POST",
      headers: { 'Authorization': AuthStr },
      data: data
    }))
      .then(() => {
        setBtnDelete(false);
        setLoadingDelete(false);
        setAlert({ alertCheck: true, message: t("AM_DeleteSuccess"), severity: "success" })
        switch (type) {
          case ("S"):
            getSite();
            break;
          case ("U"):
            getFront();
            break;
          case ("P"):
            getProgram();
            break;
          case ("G"):
            getGraphic();
            break;
          case ("A"):
            getApplication();
            break;
        }
        handleCloseModal()
      })
      .catch((error) => {
        const { status } = error.response;
        switch (status) {
          case (400):
            setAlert({ alertCheck: true, message: t("AM_BadRequest"), severity: "error" })
            break;
          case (404):
            setAlert({ alertCheck: true, message: t("AM_NotOrder"), severity: "error" })
            break;
          default:
            setAlert({ alertCheck: true, message: t("alert_defualt"), severity: "error" })
        }
        setBtnDelete(false);
        setLoadingDelete(false);
        setAlert({ alertCheck: true, message: t("AM_DeleteFaild"), severity: "error" })
      }
      )

  }

  const editColorFun = () => {
    let list = [];
    if (color.length > 0) {
      list.push(
        <Grid className="inputColorStatus1" sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
          <CloseIcon onClick={() => setColor1(color1 === color[0] ? "#ffffff" : color[0])} sx={{ fontSize: { xs: "15px", sm: "20px", md: "15px", lg: "20px" }, cursor: "pointer" }} />
          <InputColor
            initialValue={color1}
            onChange={(e) => setColor1(e.hex)}
            placement="right"
            className="colorStyleStatus"
          />
        </Grid>
      )

      list.push(
        <Grid className="inputColorStatus2" sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
          <CloseIcon onClick={() => setColor2(color2 === color[1] ? "#ffffff" : color[1])} sx={{ fontSize: { xs: "15px", sm: "20px", md: "15px", lg: "20px" }, cursor: "pointer" }} />
          <InputColor
            initialValue={color2}
            onChange={(e) => setColor2(e.hex)}
            placement="right"
            className="colorStyleStatus"
          />
        </Grid>
      )
      list.push(
        <Grid className="inputColorStatus3" sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
          <CloseIcon onClick={() => setColor3(color3 === color[2] ? "#ffffff" : color[2])} sx={{ fontSize: { xs: "15px", sm: "20px", md: "15px", lg: "20px" }, cursor: "pointer" }} />
          <InputColor
            initialValue={color3}
            onChange={(e) => setColor3(e.hex)}
            placement="right"
            className="colorStyleStatus"
          />
        </Grid>
      )
      list.push(
        <Grid className="inputColorStatus4" sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
          <CloseIcon onClick={() => setColor4(color4 === color[3] ? "#ffffff" : color[3])} sx={{ fontSize: { xs: "15px", sm: "20px", md: "15px", lg: "20px" }, cursor: "pointer" }} />
          <InputColor
            initialValue={color4}
            onChange={(e) => setColor4(e.hex)}
            placement="right"
            className="colorStyleStatus"
          />
        </Grid>
      )
    }
    return list;
  }

  const colorPalletFun = (item) => {
    let list = [];
    let color = JSON.parse(item);
    for (let i in color) {
      list.push(
        <Grid sx={{ height: "20px", width: "20px", backgroundColor: color[i] }}></Grid>
      )
    }
    return list;
  }

  const onChangeLicence = (e) => {
    if (e.target.files[0]) {
      imageFile = e.target.files[0];
      imageResizer()
    }
  }

  const imageResizer = () => {
    try {
      Resizer.imageFileResizer(
        imageFile,
        400,
        400,
        "png",
        100,
        0,
        (uri) => {
          let reader = new FileReader();
          reader.readAsDataURL(uri);
          reader.onload = () => {
            setImageSrc(reader.result)
          }
          setImageResize(uri)
        },
        "file",
      );
    } catch (err) {
      setAlert({ alertCheck: true, message: t("alert_notPic"), severity: "error" })
    }
  }

  const onChangeLicenceDocument = (e) => {
    if (e.target.files[0]) {
      if ((e.target.files[0].type === "Application/pdf") || (e.target.files[0].type === "Application/msword") || (e.target.files[0].type === "Application/zip")
        || (e.target.files[0].type === "Application/x-zip-compressed")) {
        setFileDocumation(e.target.files[0]);
        setNameFile(e.target.files[0].name)
      }
      else {
        setAlert({ alertCheck: true, message: t("Alert_notFile"), severity: "error" })
      }
    }
  }

  const modalHandeler = (item) => {
    dataEdit = item
    setDataTextFiled(item !== null ? item : "");
    switch (value) {
      case ("OrderSite"):
        titleModal = titleSite;
        setActivity(item.activity.id)
        setType(item.type.id)
        setRadioSeo(item.seo)
        setRadioHost(item.host)
        setRadioDomain(item.domain);
        break;
      case ("UIUXorder"):
        titleModal = titleUi;
        break;
      case ("OrderProgrammer"):
        titleModal = titleProgrammer;
        setActivity(item.activity.id)
        break;
      case ("OrderSeo"):
        titleModal = titleSeo;
        setActivity(item.activity.id)
        break;
      case ("OrderGraphic"):
        titleModal = titleGraphic;
        setGraphicType(item.graphic_type_id)
        break;
      case ("OrderApplication"):
        titleModal = titleApplication;
        setActivity(item.activity.id)
        setAndroid(item.android === 1 ? true : false)
        setIos(item.ios === 1 ? true : false)
        setPwa(item.pwa === 1 ? true : false)
        break;
    }
    handleOpenModal();
  }

  const setInput = (setter) => (event) => {
    setter(event.target.value);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };

  const clickOtherLocation = (e) => {
    if (e.target.id === 'modal-status') {
      handleCloseModal();
    }
  }

  const resizeTab = () => {
    if (window.innerWidth < 600 && TabSize === 'horizontal') {
      setTabSize('vertical')
    }
    else if (window.innerWidth > 600 && TabSize === 'vertical') {
      setTabSize('horizontal')
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const changeHandeler = (event) => {
    if ((event.target.name === "minimum_budget") || (event.target.name === "maximum_budget") || (event.target.name === "textual_cotent_number") || (event.target.name === "visual_cotent_number") || (event.target.name === "video_cotent_number")) {
      let value = onlyFloatnaumber(event.target.value);
      setDataTextFiled({ ...dataTextFiled, [event.target.name]: value })
    } else {
      setDataTextFiled({ ...dataTextFiled, [event.target.name]: event.target.value })
    }
  }

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setImageSrc(null);
    setImageResize(null);
    setNameFile(null);
    setFileDocumation(null);
    setColor1(color[0]);
    setColor2(color[1]);
    setColor3(color[2]);
    setColor4(color[3])
  };

  return (
    info === null ?
      <Loader />
      :
      info.active === 0 ?
        <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "18px" }, margin: "20px" }}>{t("compeleteInfo")}</Typography>
        :
        <>
          <Grid sx={{
            display: 'flex', width: "100%", height: "100%", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', direction: i18n.language === "fa" ? "rtl !important" : 'ltr !important'

          }}>
            <Grid sx={{ display: { xs: "none", sm: 'flex' }, width: { xs: "30%", sm: "95%" }, alignItems: 'center', justifyContent: 'center', marginTop: { xs: "0", sm: "20px", md: "90px" }, }}>
              <Tabs
                value={value}
                onChange={handleChange}
                orientation={TabSize}
                sx={{
                  width: "100%", minHeight: "45px !important",
                  '& .Mui-selected': {
                    color: "#FFFFFF !important", background: "linear-gradient(0deg, #FF3951 0%, #FF3951 22%, #FF4057 22.01%, #FF6F80 53%, #FFA7B2 69%, #FFB9C2 76%, #FEB9C2 86%, #FFA7B2 100%) !important"
                    , zIndex: "400", border: "none",
                  }
                  , '& .MuiTabs-indicator': { backgroundColor: "transparent" }
                  , "& .MuiTab-root": {
                    fontSize: { xs: "11px", sm: "12px", md: "12px", lg: "14px", xl: "18px" }, fontWeight: "700", width: { xs: "40px", sm: "18%" }, color: "#757575",
                    background: " linear-gradient(90deg, #D1D1D1 0%, #A8A8A8 100%)", borderRadius: { sm: "35px 35px 0 0", lg: "100px 100px 0 0" }, border: "1px solid #CCCCCC",
                  }
                }}
                aria-label="visible arrows tabs example"
              >
                <Tab value="OrderSite" label={t("site")} sx={{ position: "absolute", right: i18n.language === "fa" ? "0px" : "unset", left: i18n.language === "en" ? "0" : "unset", zIndex: "300" }} />
                <Tab value="UIUXorder" label={t("uiTab")} sx={{ position: "absolute", right: i18n.language === "fa" ? "16%" : "unset", left: i18n.language === "en" ? "16%" : "unset", zIndex: "250" }} />
                <Tab value="OrderProgrammer" label={t("Programming")} sx={{ position: "absolute", right: i18n.language === "fa" ? "33%" : "unset", left: i18n.language === "en" ? "33%" : "unset", zIndex: "200" }} />
                <Tab value="OrderSeo" label={t("seoTab")} sx={{ position: "absolute", right: i18n.language === "fa" ? "50%" : "unset", left: i18n.language === "en" ? "50%" : "unset", zIndex: "150" }} />
                <Tab value="OrderGraphic" label={t("graphicDesign")} sx={{ position: "absolute", right: i18n.language === "fa" ? "66%" : "unset", left: i18n.language === "en" ? "66%" : "unset", zIndex: "100" }} />
                <Tab value="OrderApplication" label={t("Application")} sx={{ position: "absolute", right: i18n.language === "fa" ? "82%" : "unset", left: i18n.language === "en" ? "82%" : "unset", zIndex: "50" }} />
              </Tabs>
            </Grid>
            <Grid sx={{ display: { xs: "flex", sm: "none" }, alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
              <FormControl sx={{
                width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: "20px 0 0 0",
              }}
                size="small">
                <Select
                  value={value}
                  onChange={(event) => setValue(event.target.value)}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  sx={{
                    "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important" }
                    , "& .MuiPaper-root": { top: "297px !important" }, "& input": { fontSize: "12px !importat" }
                  }}
                >
                  <MenuItem value="OrderSite" className="menuItem">{t("OrderSite")}</MenuItem>
                  <MenuItem value="UIUXorder" className="menuItem">{t("UIUXorder")}</MenuItem>
                  <MenuItem value="OrderProgrammer" className="menuItem">{t("OrderProgrammer")}</MenuItem>
                  <MenuItem value="OrderSeo" className="menuItem">{t("OrderSeo")}</MenuItem>
                  <MenuItem value="OrderGraphic" className="menuItem">{t("OrderGraphicDesign")}</MenuItem>
                  <MenuItem value="OrderApplication" className="menuItem">{t("OrderApplication")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid sx={{
              display: 'flex', alignItems: "center", justifyContent: 'center', width: "95%", height: { xs: "43px", sm: "69px" }, backgroundColor: "#FFFFFF",
              border: "1px solid #CBCBCB", borderRadius: { xs: "8px", sm: "0 0 8px 8px" }, margin: { xs: "15px 0 0 5px", sm: "0" }
            }}>
              <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-evenly", width: "100%", flexDirection: "row", overflow: { xs: "scroll", sm: "unset" } }}>
                {head()}
              </Grid>
            </Grid>
            <Grid sx={{
              display: 'flex', alignItems: "center", justifyContent: 'flex-start', width: "95%", height: "100%", margin: { xs: "5px 0 0 5px", sm: "15px 0 0 0" }
              , flexDirection: "column"
            }}>
              {dataGrid()}
            </Grid>
          </Grid>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Grid id="modal-status" onClick={(e) => clickOtherLocation(e)} sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh", backgroundColor: 'rgba(105,99,99,0.6)', direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
              <Grid sx={{
                display: "flex", alignItems: "center", justifyContent: "space-evenly", flexDirection: 'column', background: "#FFFFFF", width: { xs: "95%", sm: "600px" },
                borderRadius: "12px",
              }}>
                <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: "row", margin: "20px 0" }}>
                  <Typography alt="avatar"
                    sx={{
                      width: { xs: "69px", sm: "80px" }, height: { xs: "69px", sm: "80px" }, margin: "0 10px", borderRadius: "50px"
                      , background: `url(${infoUser.picture_path !== null ? infoUser.picture_path : avatar})`, backgroundPosition: "center", backgroundSize: "115% 115%", backgroundRepeat: "repeat",
                    }} ></Typography>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "24px" }, margin: "0 10px" }}>
                    {infoUser.user_type_id === 1 ? infoUser.name === null ? "" : infoUser.name + " " + infoUser.lastname :
                      infoUser.user_type_id === 2 ? infoUser.organ_manager_name === null ? "" : infoUser.organ_manager_name : ""
                    }
                  </Typography>
                </Grid>
                <Grid sx={{
                  display: 'flex', alignItems: "center", justifyContent: 'center', width: { xs: "95%", sm: "455px" }, boxShadow: "3px 12px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "8px", flexDirection: "row", margin: "10px 0", background: "#757575"
                }}>
                  <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "flex-start", width: { xs: "50%", sm: "227px" }, height: "100%", background: "#FFFFFF", flexDirection: "column", "& :nth-of-type(even)": { backgroundColor: "#EDEDED" } }}>
                    {
                      titleModal.map((element, index) => (
                        <Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "100%", sm: "227px" }, height: element === "logo_path" ? "60px" : "32px" }}>
                          <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, color: "#070707", textAlign: 'center' }}>{t(element)}</Typography>
                        </Grid>
                      ))}
                  </Grid>
                  <Grid sx={{ height: "100%", width: "1px", background: "#D9D9D9" }}></Grid>
                  <Grid sx={{
                    display: 'flex', alignItems: 'center', justifyContent: "flex-start", width: { xs: "50%", sm: "227px" }, height: "100%", flexDirection: "column", background: "#FFFFFF"
                  }}>
                    {
                      dataEdit !== null ?
                        titleModal.map((item, index) => (
                          item === "type" ?
                            < Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "100%", sm: "unset" }, backgroundColor: (index + 1) % 2 === 0 ? "#EDEDED" : "#FFFFF" }}>
                              <FormControl sx={{
                                width: { xs: "100%", sm: "227px" }, height: "32px !important", border: "none !important",
                                "& .MuiOutlinedInput-input": { padding: "0px !important", border: "none !important", color: "#FF3951 !important", textAlign: "center", fontSize: { xs: "10px", sm: "16px" } },
                                "& input": { borderColor: "none !important" },
                              }}
                              >
                                <Select
                                  labelId="demo-select-small-label"
                                  id="demo-select-small"
                                  value={type}
                                  onChange={(event) => setType(event.target.value)}
                                  sx={{
                                    "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important", }
                                    , boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }
                                  }}
                                >
                                  {
                                    dataSiteType !== null ?
                                      dataSiteType.map(element => (
                                        <MenuItem key={element.id} value={element.id} className="menuItem">{element.description}</MenuItem>
                                      ))
                                      :
                                      null
                                  }
                                </Select>
                              </FormControl>
                            </Grid>

                            :

                            item === "activity" ?

                              < Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "100%", sm: "unset" }, backgroundColor: (index + 1) % 2 === 0 ? "#EDEDED" : "#FFFFF" }}>
                                <FormControl sx={{
                                  width: { xs: "100%", sm: "227px" }, height: "32px !important", border: "none !important",
                                  "& .MuiOutlinedInput-input": { padding: "0px !important", border: "none !important", color: "#FF3951 !important", textAlign: "center", fontSize: { xs: "10px", sm: "16px" } },
                                  "& input": { borderColor: "none !important" }, '.MuiOutlinedInput-notchedOutline': { border: "0 !important" }
                                }}

                                >
                                  <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={activity}
                                    onChange={(event) => setActivity(event.target.value)}
                                    sx={{
                                      "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important", }
                                      , boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: "0 !important" }
                                    }}
                                  >
                                    {
                                      dataActivity !== null ?
                                        dataActivity.map(element => (
                                          <MenuItem key={element.id} value={element.id} className="menuItem">{element.description}</MenuItem>
                                        ))
                                        :
                                        null
                                    }
                                  </Select>
                                </FormControl>
                              </Grid>

                              :
                              item === "graphic_type_id" ?

                                < Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "100%", sm: "unset" }, backgroundColor: (index + 1) % 2 === 0 ? "#EDEDED" : "#FFFFF" }}>
                                  <FormControl sx={{
                                    width: { xs: "100%", sm: "227px" }, height: "32px !important", border: "none !important", minHeight: "32px !important",
                                    "& .MuiOutlinedInput-input": { padding: "0px !important", border: "none !important", color: "#FF3951 !important", textAlign: "center", fontSize: { xs: "10px", sm: "16px" } },
                                    "& input": { borderColor: "none !important" },
                                  }}

                                  >
                                    <Select
                                      labelId="demo-select-small-label"
                                      id="demo-select-small"
                                      value={graphicType}
                                      onChange={(event) => setGraphicType(event.target.value)}
                                      sx={{
                                        "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important", }
                                        , boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: "0 !important" }
                                      }}
                                    >
                                      {
                                        dataGraphicType !== null ?
                                          dataGraphicType.map(element => (
                                            <MenuItem key={element.id} value={element.id} className="menuItem">{element.description}</MenuItem>
                                          ))
                                          :
                                          null
                                      }
                                    </Select>
                                  </FormControl>
                                </Grid>

                                :

                                item === "condition" ?

                                  < Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "100%", sm: "unset" }, backgroundColor: (index + 1) % 2 === 0 ? "#EDEDED" : "#FFFFF" }}>
                                    <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, color: "#FF3951 !important", textAlign: "center", height: "32px", width: { xs: "100%", sm: "227px" }, }} >
                                      {descCondition(dataEdit.condition.id)}
                                    </Typography>
                                  </Grid>

                                  :

                                  item === "color" ?

                                    <Grid key={index} sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", flexDirection: "row", height: "32px", backgroundColor: (index + 1) % 2 === 0 ? "#EDEDED" : "#FFFFF" }}>
                                      {editColorFun()}
                                    </Grid>
                                    :

                                    item === "documentation" ?

                                      <Grid key={index} sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", flexDirection: "row", height: "32px", backgroundColor: (index + 1) % 2 === 0 ? "#EDEDED" : "#FFFFF" }}>
                                        <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, color: "#FF3951 !important", textAlign: "center", margin: "0 10px" }} >
                                          {nameFile !== null ? nameFile : dataEdit.documentation_path !== null ? dataEdit.documentation_path.substring(dataEdit.documentation_path.length - 10, dataEdit.documentation_path.length) : t("NotCompleted")}
                                        </Typography>
                                        <Grid sx={{ display: "flex", alignItems: 'center', }}>
                                          <Input id="upload-document" type="file" sx={{ display: "none" }} onChange={(e) => onChangeLicenceDocument(e)} accept="Application/msword, Application/pdf" />
                                          <Typography component={"label"} htmlFor="upload-document" sx={{ display: "flex", alignItems: "center", width: "100%", padding: "0 10px" }} >
                                            <IconButton component="span" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", "&:hover": { backgroundColor: "transparent" } }} >
                                              <Typography component={"img"} alt="uplad" src={upload} />
                                            </IconButton>
                                          </Typography>
                                        </Grid>
                                      </Grid>

                                      :

                                      item === "seo" ?

                                        < Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "100%", sm: "227px" }, height: "32px", backgroundColor: (index + 1) % 2 === 0 ? "#EDEDED" : "#FFFFF" }}>
                                          <FormControl sx={{ display: "flex", flexDirection: 'row', alignItems: "center", "& input": { backgroundColor: "transparent !important" }, backgroundColor: "transparent !important" }} >
                                            <RadioGroup
                                              aria-labelledby="demo-controlled-radio-buttons-group"
                                              name="controlled-radio-buttons-group"
                                              value={radioSeo}
                                              onChange={setInput(setRadioSeo)}
                                              sx={{
                                                display: "flex", flexDirection: 'row', alignItems: "center",
                                                "& .MuiFormControlLabel-root": { margin: "5px !important", backgroundColor: "transparent !important" }
                                              }}
                                            >
                                              <FormControlLabel value={1} sx={{ "& .MuiTypography-root": { fontSize: { xs: "10px", sm: "16px" }, backgroundColor: "transparent !important", color: "#FF3951 !important" } }}
                                                control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 10 }, '&.Mui-checked': { color: "#FF3951 !important", backgroundColor: "transparent !important", } }} />} label={t("yes")} />
                                              <FormControlLabel value={0} sx={{ "& .MuiTypography-root": { fontSize: { xs: "10px", sm: "16px" }, backgroundColor: "transparent !important", color: "#FF3951 !important" } }}
                                                control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 10 }, '&.Mui-checked': { color: "#FF3951 !important", backgroundColor: "transparent !important", } }} />} label={t("no")} />
                                            </RadioGroup>
                                          </FormControl>
                                        </Grid>
                                        :

                                        item === "domain" ?

                                          < Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "100%", sm: "227px" }, height: "32px", backgroundColor: (index + 1) % 2 === 0 ? "#EDEDED" : "#FFFFF" }}>
                                            <FormControl sx={{ display: "flex", flexDirection: 'row', alignItems: "center", "& input": { backgroundColor: "transparent !important" }, backgroundColor: "transparent !important" }} >
                                              <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={radioDomain}
                                                onChange={setInput(setRadioDomain)}
                                                sx={{
                                                  display: "flex", flexDirection: 'row', alignItems: "center",
                                                  "& .MuiFormControlLabel-root": { margin: "5px !important", backgroundColor: "transparent !important" }
                                                }}
                                              >
                                                <FormControlLabel value={1} sx={{ "& .MuiTypography-root": { fontSize: { xs: "10px", sm: "16px" }, backgroundColor: "transparent !important", color: "#FF3951 !important" } }}
                                                  control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 10 }, '&.Mui-checked': { color: "#FF3951 !important", backgroundColor: "transparent !important", } }} />} label={t("yes")} />
                                                <FormControlLabel value={0} sx={{ "& .MuiTypography-root": { fontSize: { xs: "10px", sm: "16px" }, backgroundColor: "transparent !important", color: "#FF3951 !important" } }}
                                                  control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 10 }, '&.Mui-checked': { color: "#FF3951 !important", backgroundColor: "transparent !important", } }} />} label={t("no")} />
                                              </RadioGroup>
                                            </FormControl>
                                          </Grid>
                                          :

                                          item === "host" ?

                                            < Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "100%", sm: "227px" }, height: "32px", backgroundColor: (index + 1) % 2 === 0 ? "#EDEDED" : "#FFFFF" }}>
                                              <FormControl sx={{ display: "flex", flexDirection: 'row', alignItems: "center", "& input": { backgroundColor: "transparent !important" }, backgroundColor: "transparent !important" }} >
                                                <RadioGroup
                                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                                  name="controlled-radio-buttons-group"
                                                  value={radioHost}
                                                  onChange={setInput(setRadioHost)}
                                                  sx={{
                                                    display: "flex", flexDirection: 'row', alignItems: "center",
                                                    "& .MuiFormControlLabel-root": { margin: "5px !important", backgroundColor: "transparent !important" }
                                                  }}
                                                >
                                                  <FormControlLabel value={1} sx={{ "& .MuiTypography-root": { fontSize: { xs: "10px", sm: "16px" }, backgroundColor: "transparent !important", color: "#FF3951 !important" } }}
                                                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 10 }, '&.Mui-checked': { color: "#FF3951 !important", backgroundColor: "transparent !important", } }} />} label={t("yes")} />
                                                  <FormControlLabel value={0} sx={{ "& .MuiTypography-root": { fontSize: { xs: "10px", sm: "16px" }, backgroundColor: "transparent !important", color: "#FF3951 !important" } }}
                                                    control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 10 }, '&.Mui-checked': { color: "#FF3951 !important", backgroundColor: "transparent !important", } }} />} label={t("no")} />
                                                </RadioGroup>
                                              </FormControl>
                                            </Grid>

                                            :
                                            item === "logo_path" ?

                                              < Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "100%", sm: "227px" }, height: "60px", backgroundColor: (index + 1) % 2 === 0 ? "#EDEDED" : "#FFFFF" }}>
                                                <Grid sx={{
                                                  display: 'flex', alignItems: 'center', justifyContent: 'center', width: { xs: "69px", sm: "59px" }, margin: "0 10px",
                                                  borderRadius: "4px", backgroundColor: "#FFFFFF", boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)"
                                                }}>
                                                  <Grid
                                                    sx={{
                                                      width: { xs: "40px", sm: "50px" }, height: { xs: "40px", sm: "40px" }, margin: "0 10px", borderRadius: "4px"
                                                      , background: `url(${imageSrc !== null ? imageSrc : dataEdit.logo_path !== null ? dataEdit.logo_path : logo})`, backgroundPosition: "center", backgroundSize: "100% 100%",
                                                    }} ></Grid>
                                                </Grid>
                                                <Grid sx={{ display: "flex", alignItems: 'center', }}>
                                                  <Input id="upload-logo" type="file" sx={{ display: "none" }} onChange={(e) => onChangeLicence(e)} accept="image/*" />
                                                  <Typography component={"label"} htmlFor="upload-logo" sx={{ display: "flex", alignItems: "center", width: "100%", padding: "0 10px" }} >
                                                    <IconButton component="span" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", "&:hover": { backgroundColor: "transparent" } }} >
                                                      <Typography component={"img"} alt="uplad" src={upload} />
                                                    </IconButton>
                                                  </Typography>
                                                </Grid>
                                              </Grid>

                                              :
                                              item === "applicationOperatingSystem" ?
                                                < Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "100%", sm: "227px" }, height: "32px", backgroundColor: (index + 1) % 2 === 0 ? "#EDEDED" : "#FFFFF" }}>
                                                  <FormGroup sx={{ display: "flex", alignItems: 'center', flexDirection: 'row', justifyContent: "space-evenly", width: '100%' }}>
                                                    <FormControlLabel sx={{ "& .MuiTypography-root": { fontSize: { xs: "10px", sm: "14px" }, color: "#FF3951 !important" }, margin: "0 !important" }}
                                                      control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 15 }, '&.Mui-checked': { color: "#FF3951", } }} checked={android} onChange={() => setAndroid(!android)} />} label="Android" />
                                                    <FormControlLabel sx={{ "& .MuiTypography-root": { fontSize: { xs: "10px", sm: "14px" }, color: "#FF3951 !important" }, margin: "0 !important" }}
                                                      control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 15 }, '&.Mui-checked': { color: "#FF3951", } }} checked={pwa} onChange={() => setPwa(!pwa)} />} label="PWA" />
                                                    <FormControlLabel sx={{ "& .MuiTypography-root": { fontSize: { xs: "10px", sm: "14px" }, color: "#FF3951 !important" }, margin: "0 !important" }}
                                                      control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 15 }, '&.Mui-checked': { color: "#FF3951", } }} checked={ios} onChange={() => setIos(!ios)} />} label="IOS" />
                                                  </FormGroup>
                                                </Grid>
                                                :
                                                item === "minimum_budget" || item === "maximum_budget" || item === "textual_cotent_number" || item === "visual_cotent_number" || item === "video_cotent_number" ?
                                                  < Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "100%", sm: "unset" }, backgroundColor: (index + 1) % 2 === 0 ? "#EDEDED" : "#FFFFF" }}>
                                                    <TextField variant="standard" name={item} id={item} onChange={(event) => changeHandeler(event)} value={dataTextFiled[item] !== null ? separator(t(dataTextFiled[item])) : t("NotCompleted")} InputProps={{ disableUnderline: true, }}
                                                      InputLabelProps={{ sx: { fontSize: { xs: "10px", sm: "16px" } } }}
                                                      sx={{ "& input": { color: "#FF3951 !important", textAlign: "center", fontSize: { xs: "10px", sm: "16px" }, height: "23px" }, width: { xs: "100%", sm: "227px" }, }} />
                                                  </Grid>
                                                  :
                                                  < Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: { xs: "100%", sm: "unset" }, backgroundColor: (index + 1) % 2 === 0 ? "#EDEDED" : "#FFFFF" }}>
                                                    <TextField variant="standard" name={item} id={item} onChange={(event) => changeHandeler(event)} value={dataTextFiled[item] !== null ? t(dataTextFiled[item]) : t("NotCompleted")} InputProps={{ disableUnderline: true, }}
                                                      InputLabelProps={{ sx: { fontSize: { xs: "10px", sm: "16px" } } }}
                                                      sx={{ "& input": { color: "#FF3951 !important", textAlign: "center", fontSize: { xs: "10px", sm: "16px" }, height: "23px" }, width: { xs: "100%", sm: "227px" }, }} />
                                                  </Grid>
                        ))
                        :
                        <Typography> </Typography>
                    }
                  </Grid>
                </Grid>
                <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-evenly", flexDirection: 'row', width: "100%", margin: "30px" }}>
                  <Button onClick={editData} disabled={btnEdit ? true : false} sx={{
                    width: { xs: "80px", sm: "110px" }, height: { xs: "33px", sm: "40px" }, boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", fontSize: { xs: "14px", sm: "16px" },
                    background: "#FF3951", color: "#FFFFFF", "&:hover": { background: "#FF3951" }
                  }}>
                    {btnEdit === true && loadingEdit === true ? <CircularProgress size={20} color="inherit" sx={{ color: "white" }} /> : t("edit")}
                  </Button>
                  <Button onClick={deleteOrderHandeler} disabled={btnDelete ? true : false} sx={{
                    width: { xs: "80px", sm: "110px" }, height: { xs: "33px", sm: "40px" }, borderRadius: "8px", fontSize: { xs: "14px", sm: "16px" }, border: "2px solid #FF3951",
                    background: "#FFFFFF", color: "#FF3951", "&:hover": { background: "#FFFFFFF" }
                  }}>
                    {btnDelete === true && loadingDelete === true ? <CircularProgress size={20} color="inherit" sx={{ color: "#FF3951" }} /> : t("delete")}
                  </Button>
                  <Button onClick={handleCloseModal} sx={{
                    width: { xs: "80px", sm: "110px" }, height: { xs: "33px", sm: "40px" }, boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", fontSize: { xs: "14px", sm: "16px" }, border: "2px solid #FF3951",
                    background: "#FFFFFF", color: "#FF3951", "&:hover": { background: "#FFFFFFF" }
                  }}>
                    {t("cancel")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Modal >
          <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
        </>
  )
}

export default StatusOrder;