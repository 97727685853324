import { SvgIcon, setMinimum_budget } from '@mui/material';
import React from 'react';
const InfoIcon = ({ color }) => {
  return (
    <SvgIcon component="object" sx={{ height: "30px" }}>
      <svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.3 5.29C10.5 5.11 10.74 5 11 5C11.27 5 11.5 5.11 11.71 5.29C11.9 5.5 12 5.74 12 6C12 6.27 11.9 6.5 11.71 6.71C11.5 6.9 11.27 7 11 7C10.74 7 10.5 6.9 10.3 6.71C10.11 6.5 10 6.27 10 6C10 5.74 10.11 5.5 10.3 5.29ZM7.8 9.97C7.8 9.97 9.97 8.25 10.76 8.18C11.5 8.12 11.35 8.97 11.28 9.41L11.27 9.47C11.13 10 10.96 10.64 10.79 11.25C10.41 12.64 10.04 14 10.13 14.25C10.23 14.59 10.85 14.16 11.3 13.86C11.36 13.82 11.41 13.78 11.46 13.75C11.46 13.75 11.54 13.67 11.62 13.78C11.64 13.81 11.66 13.84 11.68 13.86C11.77 14 11.82 14.05 11.7 14.13L11.66 14.15C11.44 14.3 10.5 14.96 10.12 15.2C9.71 15.47 8.14 16.37 8.38 14.62C8.59 13.39 8.87 12.33 9.09 11.5C9.5 10 9.68 9.32 8.76 9.91C8.39 10.13 8.17 10.27 8.04 10.36C7.93 10.44 7.92 10.44 7.85 10.31L7.82 10.25L7.77 10.17C7.7 10.07 7.7 10.06 7.8 9.97ZM20 10C20 15.5 15.5 20 10 20C4.5 20 0 15.5 0 10C0 4.5 4.5 0 10 0C15.5 0 20 4.5 20 10ZM18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10C2 14.42 5.58 18 10 18C14.42 18 18 14.42 18 10Z" fill={color} />
      </svg>
    </SvgIcon>
  );
};
export default InfoIcon;