import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import jobFa from '../image/job/jobFa.png';
import jobEn from '../image/job/jobEn.png';
import { setCanonicalTag } from "./modules/general";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Footer from "./Footer";
import { dataJob } from "./DataJob";

const CareerOpportunities = () => {
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();
  useEffect(() => {
    document.title = "فرصت های شغلی نرم افزاری در بهترین شرکت طراحی سایت در ایران | وب گستران سورین";
    document.getElementById("main-heading").innerHTML = "فرصت های شغلی نرم افزاری در بهترین شرکت طراحی سایت در ایران | وب گستران سورین";
    document.getElementsByTagName('meta')["description"].content = "شما میتوانید در بهترین شرکت طراحی نرم فزار در ایران مشغول به کار شوید.فرصت های شغلی نظیر برنامه نویس frontend، برنامه نویس backend، طراح رابط کاربری و تجربه کاربری، برنامه نویس ،  کاراموز برنامه نویسی ";
    document.getElementsByTagName('meta')["keywords"].content = "صفحه مر بوط به استخدام کاراموزان و برنامه نویسان نرم افزاری | ";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  const detailHandeler = (value) => {
    Navigate(`/JobOffer/${value}`)
  }

  return (
    <>
      <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', flexDirection: "column", width: "100%", direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
        <Grid sx={{ display: "flex", width: "100%", backgroundColor: "#EDEDED", padding: "15px 0", justifyContent: "center" }}>
          <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: { xs: "95%", md: "86%" } }}>
            <NavLink className="navlink" to="/">
              <Typography component={"p"} sx={{ fontSize: "12px", color: "#757575", padding: "0 5px" }} >{t("mainPage")}</Typography>
            </NavLink>
            <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
            <Typography component={"p"} sx={{ fontSize: "12px", color: "#070707", padding: "0 5px" }}>{t("JobOffer")}</Typography>
          </Grid>
        </Grid>
        {
          dataJob.length === 0 ?
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", padding: "50px" }}>
              <Grid sx={{
                display: "flex", alignItems: "center", justifyContent: "center", width: "100%"
              }}>
                <Typography component={"img"} alt="job_offer" src={i18n.language === "fa" ? jobFa : jobEn} sx={{ width: { xs: "70%", sm: "60%", md: "40%" } }} />
              </Grid>
            </Grid>
            :
            <Grid sx={{ display: "flex", alignItems: 'flex-start', justifyContent: "flex-start", flexDirection: "column", width: { xs: "95%", md: "80%" }, margin: "40px 0 150px 0" }}>
              <Typography sx={{ fontSize: { xs: "18px", sm: "22px", md: "32px" }, color: "#000000", textAlign: "justify" }}>{t("cooperation")}</Typography>
              <Typography sx={{ fontSize: { xs: "14px", sm: "16px", md: "20px" }, color: "#000000", margin: "20px 0", textAlign: "justify" }}>{t("cooperationDesc")}</Typography>
              {
                dataJob.map((item) => (
                  <Grid key={item.id} sx={{
                    display: 'flex', alignItems: "center", width: "100%", height: { xs: "110px", md: "170px" }, flexDirection: "row", background: "#F0F0F0",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)", borderRadius: "31px", marginTop: { xs: "20px", md: "30px" }
                  }}>
                    <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "column", width: "70%", margin: { xs: "0 15px", md: "0 40px" } }}>
                      <Typography sx={{ fontSize: { xs: "18px", sm: "22px", md: "32px" }, color: "#000000", margin: "10px 0" }}>{t(item.title)}</Typography>
                      <Typography sx={{ fontSize: { xs: "14px", sm: "16px", md: "20px" }, color: "#000000", margin: "10px 0", textAlign: "justify" }}>{item.desc}</Typography>
                    </Grid>
                    <Grid sx={{ display: "flex", alignItems: "flex-end", justifyContent: 'flex-end', width: "30%", margin: { xs: "0 15px", md: "0 40px" } }}>
                      <Button onClick={() => detailHandeler(item.title)} sx={{
                        width: { xs: "100px", md: "140px" }, height: { xs: "40px", md: "50px" }, background: "#FF3951", borderRadius: "8px",
                        boxShadow: "0px 0px 16px #FF3951", color: "#FFFFFF", fontSize: { xs: "12px", sm: "14px", md: "18px" }, "&:hover": { background: "#FF3951" }
                      }}>
                        {t("detail")}
                      </Button>
                      <Grid sx={{display:"none"}}>
                      <Typography>
                      ما در این صفحه به توضیحی در مورد فرصت های شغلی نرم افزاری در بهترین شرکت طراحی سایت در ایران | وب گستران سورین می پردازیم.
                      </Typography>
                      <Typography>
                      شما میتوانید در بهترین شرکت طراحی نرم فزار در ایران مشغول به کار شوید.فرصت های شغلی نظیر برنامه نویس frontend، برنامه نویس backend، طراح رابط کاربری و تجربه کاربری، برنامه نویس ،  کاراموز برنامه نویسی
                      </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              }
            </Grid>
        }
      </Grid>
      <Footer />
    </>
  )
}
export default CareerOpportunities;