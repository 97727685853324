import { SvgIcon } from '@mui/material';
import React from 'react';
const OrdeUiIcon = ({ color }) => {
  return (
    <SvgIcon component="object">
      <svg width="24" height="24" viewBox="0 0 19 15" fill="transparent" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.35267 2.618H17.3753M6.41667 14.1217V2.618M1 1H17.6667V14.3327H1V1Z" stroke={color}/>
        <path d="M16.1435 12.982H7.96752M16.1435 12.982V8.83764V4.18031M16.1435 12.982L7.96752 4.18031M7.96752 12.982V4.18031M7.96752 12.982L16.1435 4.18031M7.96752 4.18031H16.1435M2.29785 9.94031H3.99718M2.29785 11.2736H5.06719M2.29785 12.607H3.99718M2.32819 4.00964H4.99485V7.70398H2.32819V4.00964Z" stroke={color} />
      </svg>

    </SvgIcon>
  );
};
export default OrdeUiIcon;