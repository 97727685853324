import React, { useState, useEffect } from "react";
import { Button, Typography, Grid, TextField, DialogContent, RadioGroup, Radio, FormControlLabel, IconButton, CircularProgress, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import '../styles/textField.css';
import ReCAPTCHA from "react-google-recaptcha";
import CloseIcon from '@mui/icons-material/Close';
import { passValidate, phoneNumberValidate, codeSmsValidate, repeatPassValidate } from "./modules/validate";
import '../styles/recapcha.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { onlyDigit } from "./modules/general";
import axios from "axios";
import AlertMessage from './modules/AlertMessage';
import { baseUrl } from "./modules/general";
import clock from '../image/loginModal/clock.png'
import '../styles/modalLogin.css'

const ModalLogin = ({ open, close, data }) => {
  const [type, setType] = useState("L");
  const [captcha, setCaptcha] = useState(false);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [sendSms, setSendSms] = useState(false);
  const [alert, setAlert] = useState({ alertCheck: false, message: "", severity: "" });
  const [showPass, setShowPass] = useState(false);
  const [smsCode, setSmsCode] = useState("");
  const [phoneNumberREG, setPhoneNumberREG] = useState("");
  const [passswordREG, setPasswordREG] = useState("");
  const [phoneNumberLOG, setPhoneNumberLOG] = useState("");
  const [passswordLOG, setPassswordLOG] = useState("");
  const [panelType, setPanelType] = useState("1");
  const [loadingReg, setLoadingReg] = useState(false);
  const [loadingLog, setLoadingLog] = useState(false)
  const [btnRegister, setBtnRegister] = useState(false);
  const [btnLogin, setBtnLogin] = useState(false);
  const { t, i18n } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(0);
  const [btnForgot, setBtnForgot] = useState(false);
  const [loadingForgot, setLoadingForgot] = useState(false);
  const [sendSmsForgot, setSendSmsForgot] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [repeatPass, setRepeatPass] = useState("")

  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => {
      clearInterval(intervalId)
    };
  }, [timeLeft]);

  useEffect(() => {
    if (timeLeft === 0) {
      setSendSms(false)
      setSendSmsForgot(false)
      setSmsCode("")
    }
  }, [timeLeft]);

  useEffect(() => {
    if (open === false) {
      setSendSms(false);
      setPhoneNumberREG("");
      setPasswordREG("");
      setPhoneNumberLOG("");
      setPassswordLOG("");
      setRepeatPass("")
      setNewPass("")
      setSmsCode("")
      setErrors({})
      type === "F" ? setType("L") : setType("R")
    }
  }, [open]);

  useEffect(() => {
    setType(data);
  }, [open]);

  const format = (timeLeft) => {
    const date = new Date(timeLeft * 1000);
    let mm = date.getUTCMinutes();
    let ss = date.getSeconds();
    if (mm < 10) { mm = "0" + mm; }
    if (ss < 10) { ss = "0" + ss; }
    return '00' !== mm ? mm + ":" + ss : mm + ":" + ss;
  }

  const registerHandeler = () => {
    if ((phoneNumberREG === "") || (passswordREG === "")) {
      setAlert({ alertCheck: true, message: t("AM_fieldNull"), severity: "error" })
    }
    else if (errors.hasOwnProperty('phoneNumber')) {
      setAlert({ alertCheck: true, message: t("AM_phoneNumber"), severity: "error" })
    }
    else if (errors.hasOwnProperty('pass')) {
      setAlert({ alertCheck: true, message: t("AM_password"), severity: "error" })
    }
    else if (errors.hasOwnProperty('codeSms')) {
      setAlert({ alertCheck: true, message: t("AM_codesms"), severity: "error" })
    }
    else if (captcha === false) {
      setAlert({ alertCheck: true, message: t("AM_captcha"), severity: "error" })
    }
    else if ((!sendSms) && (!Object.keys(errors).length) && (captcha)) {
      sendMessage();
    }
    else if ((sendSms) && (smsCode === "")) {
      setAlert({ alertCheck: true, message: t("AM_smsCode_fieldNull"), severity: "error" })
    }
    else if ((sendSms) && (!Object.keys(errors).length) && (captcha)) {
      register();
    }
  }

  const getInfoUser = () => {
    const AuthStr = 'Bearer ' + (localStorage.getItem('TACC'));
    const Response = Promise.resolve(axios({
      url: baseUrl + "/api/v1/user/info",
      method: "GET",
      headers: { 'Authorization': AuthStr }
    }))
      .then((res) => {
        return (res.data.data)
      })
    return Response;
  }

  const register = () => {
    let data = { mobile: phoneNumberREG, password: passswordREG, otp: smsCode, panel: panelType }
    setBtnRegister(true);
    setLoadingReg(true);
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/user/register',
      method: "POST",
      data: data
    }))
      .then((res) => {
        setSendSms(false);
        setBtnRegister(false);
        setLoadingReg(false);
        setPhoneNumberREG("");
        setPasswordREG("");
        setSmsCode("");
        setAlert({ alertCheck: true, message: t("AM_register_success"), severity: "success" })
        setType("L")
      })
      .catch(
        (error) => {
          setBtnRegister(false);
          setLoadingReg(false);
          const { status } = error.response;
          switch (status) {
            case (409):
              setAlert({ alertCheck: true, message: t("AM_conflit"), severity: "error" })
              break;
            case (400):
              setAlert({ alertCheck: true, message: t("AM_BadRequest"), severity: "error" })
              break;
            case (431):
              setAlert({ alertCheck: true, message: t("AM_sms_code"), severity: "error" })
              break;
          }
        }
      )
  }

  const sendMessage = () => {
    let data;
    if (type === "R") {
      data = { mobile: phoneNumberREG };
      setBtnRegister(true);
      setLoadingReg(true);
    }
    else if (type === "F") {
      data = { mobile: phoneNumberLOG };
      setBtnForgot(true);
      setLoadingForgot(true)
    }
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/otp/sendMessage',
      method: "POST",
      data: data
    }))
      .then((res) => {
        if (type === "R") {
          setSendSms(true);
          setBtnRegister(false);
          setLoadingReg(false);

        }
        else if (type === "F") {
          setSendSmsForgot(true);
          setBtnForgot(false);
          setLoadingForgot(false);
        }
        setTimeLeft(res.data.data)
      })
      .catch(
        (error) => {
          setBtnRegister(false);
          setLoadingReg(false);
          setBtnForgot(false);
          setLoadingForgot(false);
          const { status } = error.response;
          switch (status) {
            case (500):
              setAlert({ alertCheck: true, message: t("AM_intervalServer"), severity: "error" })
              break;
            case (400):
              setAlert({ alertCheck: true, message: t("AM_BadRequest"), severity: "error" })
              break;
          }
        }
      )
  }

  const loginHandeler = () => {
    if ((phoneNumberLOG === "") || (passswordLOG === "")) {
      setAlert({ alertCheck: true, message: t("AM_fieldNull"), severity: "error" })
    }
    else if (errors.hasOwnProperty('phoneNumber')) {
      setAlert({ alertCheck: true, message: t("AM_phoneNumber"), severity: "error" })
    }
    else if (errors.hasOwnProperty('pass')) {
      setAlert({ alertCheck: true, message: t("AM_password"), severity: "error" })
    }
    else if (captcha === false) {
      setAlert({ alertCheck: true, message: t("AM_captcha"), severity: "error" })
    }
    else if ((!Object.keys(errors).length) && (captcha)) {
      login()
    }
  }

  const login = () => {
    let data = { mobile: phoneNumberLOG, password: passswordLOG }
    setBtnLogin(true);
    setLoadingLog(true);
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/user/login',
      method: "POST",
      data: data
    }))
      .then((res) => {
        setBtnLogin(false);
        setLoadingLog(false);
        localStorage.setItem('TACC', res.data.data.access_token);
        getInfoUser().then((res) => { localStorage.setItem("sorinInfoUser", JSON.stringify(res)) })
        window.location.reload();
        close();
      })
      .catch(
        (error) => {
          setBtnLogin(false);
          setLoadingLog(false);
          const { status } = error.response;
          switch (status) {
            case (404):
              setAlert({ alertCheck: true, message: t("AM_NotRegister"), severity: "error" })
              break;
            case (430):
              setAlert({ alertCheck: true, message: t("AM_password_Wrong"), severity: "error" })
              break;
            case (400):
              setAlert({ alertCheck: true, message: t("AM_BadRequest"), severity: "error" })
              break;
          }
        }
      )
  }

  const forgotHandeler = () => {
    if ((phoneNumberLOG === "") || (newPass === "") || (repeatPass === "")) {
      setAlert({ alertCheck: true, message: t("AM_fieldNUll"), severity: "error" })
    }
    else if (errors.hasOwnProperty('phoneNumber')) {
      setAlert({ alertCheck: true, message: t("AM_phoneNumber"), severity: "error" })
    }
    else if (errors.hasOwnProperty('pass')) {
      setAlert({ alertCheck: true, message: t("AM_password"), severity: "error" })
    }
    else if (newPass !== repeatPass) {
      setAlert({ alertCheck: true, message: t("repeatPasswordNotMatch"), severity: "error" })
    }
    else if (captcha === false) {
      setAlert({ alertCheck: true, message: t("AM_captcha"), severity: "error" })
    }
    else if ((sendSmsForgot) && (!Object.keys(errors).length) && (captcha)) {
      changePass();
    }
    else if ((!Object.keys(errors).length) && (captcha) && (!sendSmsForgot)) {
      sendMessage()
    }
  }

  const changePass = () => {
    let data = { mobile: phoneNumberLOG, password: newPass, otp: smsCode }
    setBtnForgot(true);
    setLoadingForgot(true);
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/user/changePassword',
      method: "POST",
      data: data
    }))
      .then((res) => {
        setBtnForgot(false);
        setLoadingForgot(false);
        setAlert({ alertCheck: true, message: t("AM_changePass_success"), severity: "success" })
        setType("L")
      })
      .catch(
        (error) => {
          setBtnForgot(false);
          setLoadingForgot(false);
          const { status } = error.response;
          switch (status) {
            case (404):
              setAlert({ alertCheck: true, message: t("AM_NotRegister"), severity: "error" })
              break;
            case (400):
              setAlert({ alertCheck: true, message: t("AM_BadRequest"), severity: "error" })
              break;
            case (431):
              setAlert({ alertCheck: true, message: t("AM_sms_code"), severity: "error" })
              break;
          }
        }
      )
  }

  const clickOtherLocation = (e) => {
    if (e.target.id === 'modal_close') {
      close();
    }
  }

  const loginBtn = () => {
    setType("L")
  }

  const registerBtn = () => {
    setType("R")
  }

  const handleClickShowPass = () => setShowPass((show) => !show);

  const handleMouseDownPass = (event) => {
    event.preventDefault();
  }

  const onChangeRechapta = (value) => {
    value != null ? setCaptcha(true) : setCaptcha(false)
  }

  const focusHandler = (event) => {
    setTouched({ ...touched, [event.target.name]: true })
  }

  const passLogHandeler = (event) => {
    setPassswordLOG(event.target.value)
    setErrors(passValidate(event.target.value, t))
  }

  const phoneNumberLogHandeler = (event) => {
    setPhoneNumberLOG(event.target.value)
    setErrors(phoneNumberValidate(event.target.value, t))
  }

  const setPhoneNumberRegHandeler = (event) => {
    setPhoneNumberREG(event.target.value)
    setErrors(phoneNumberValidate(event.target.value, t))
  }

  const passRegHandeler = (event) => {
    setPasswordREG(event.target.value)
    setErrors(passValidate(event.target.value, t))
  }

  const smsCodeHandeler = (event) => {
    setSmsCode(event.target.value)
    setErrors(codeSmsValidate(event.target.value, t))
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };

  const repeatPassHandeler = (event) => {
    setRepeatPass(event.target.value)
    setErrors(repeatPassValidate(event.target.value, t))
  }

  const newPassHandeler = (event) => {
    setNewPass(event.target.value)
    setErrors(passValidate(event.target.value, t))
  }
  return (
    <>
      <Modal open={open} onClose={close}  >
        <Grid onClick={(e) => clickOtherLocation(e)} id="modal_close" sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: "100vw", height: "100vh", backgroundColor: 'rgba(105,99,99,0.6)' }}>
          <Grid sx={{
            display: "flex", flexDirection: "column", alignItems: 'center', background: "#FFFFFF", width: { xs: "95%", sm: "624px" },
            height: type === "R" && sendSms ? "700px" : type === "L" ? "530px" : type === "R" ? { xs: "550px", sm: "608px" } : sendSmsForgot === true ? "550px" : type === "F" ? "500px" : "",
            direction: i18n.language === "fa" ? "rtl" : "ltr", borderRadius: "8px"
          }}>
            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "flex-start", padding: "15px 20px 0 20px", width: "100%" }}>
              <CloseIcon onClick={close} sx={{ color: "#31373D", cursor: 'pointer', fontSize: "24px" }} />
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: i18n.language === "fa" ? "row" : "row-reverse", borderBottom: "1px solid #CBCBCB", width: "100%", alignItems: "center", justifyContent: "space-evenly" }}>
              <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "center", borderBottom: type === "L" || type === "F" ? "2px solid #FF3951" : "null" }}>
                <Button sx={{ backgroundColor: "white" }} onClick={loginBtn}>
                  <Typography sx={{ fontSize: { xs: i18n.language === "fa" ? "14px" : "12px", md: i18n.language === "fa" ? "20px" : "15px" }, lineHeight: "30px", fontWeight: "700", color: type === "L" || type === "F" ? "#FF3951" : "black" }}>{t("login")}</Typography>
                </Button>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "center", borderBottom: type === "R" ? "2px solid #FF3951" : "null" }}>
                <Button sx={{ backgroundColor: "white" }} onClick={registerBtn}>
                  <Typography sx={{ fontSize: { xs: i18n.language === "fa" ? "14px" : "12px", md: i18n.language === "fa" ? "20px" : "15px" }, lineHeight: "30px", fontWeight: "700", color: type === "R" ? "#FF3951" : "black" }}>{t("register")}</Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid sx={{ display: type === "L" ? "flex" : "none", alignItems: "center", justifyContent: "space-evenly", flexDirection: "column", height: "100%" }}>
              <TextField
                className="textField"
                label={t("phoneNumber")}
                variant='outlined'
                value={onlyDigit(phoneNumberLOG)}
                onFocus={focusHandler}
                name="phoneNumberLOG"
                inputMode="tel"
                onChange={(event) => phoneNumberLogHandeler(event)}
                error={errors.phoneNumber && touched.phoneNumberLOG}
                helperText={errors.phoneNumber && touched.phoneNumberLOG && errors.phoneNumber}
                inputProps={{ maxLength: 11 }}
                fullWidth
                sx={{ width: { xs: "90%", sm: "492px" }, textAlign: "center", display: "flex", borderRadius: "14px", "& .MuiOutlinedInput-input": { padding: "14px" } }}
                InputLabelProps={{ sx: { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "14px" : "12px" } } }}
              />
              <TextField
                className="textField"
                label={t("password")}
                variant='outlined'
                type={showPass ? 'text' : 'password'}
                value={passswordLOG}
                onChange={(event) => passLogHandeler(event)}
                onFocus={focusHandler}
                name="passLog"
                error={errors.pass && touched.passLog}
                helperText={errors.pass && touched.passLog && errors.pass}
                fullWidth
                sx={{ width: { xs: "90%", sm: "492px" }, textAlign: "center", display: "flex", borderRadius: "14px", "& .MuiOutlinedInput-input": { padding: "14px" } }}
                InputLabelProps={{ sx: { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "12px" : "10px" } } }}
                InputProps={{
                  endAdornment:
                    <IconButton
                      aria-label="toggle pass visibility"
                      onClick={handleClickShowPass}
                      onMouseDown={handleMouseDownPass}
                      edge="end"
                    >
                      {showPass ? <VisibilityIcon sx={{ fontSize: { sm: "18px", md: "24px" } }} /> : <VisibilityOffIcon sx={{ fontSize: { sm: "18px", md: "24px" } }} />}
                    </IconButton>
                }}
              />
              <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "center", width: "100%", padding: "10px" }}>
                <Typography sx={{ color: "##070707", fontSize: { xs: "12px", sm: "14px", md: "16px" } }}>{t("forgotPass")}</Typography>
                <Typography onClick={() => setType("F")} sx={{ color: "#FF3951", fontSize: { xs: "12px", sm: "14px", md: "16px" }, cursor: "pointer", textShadow: "-1px 4px 3px rgba(0, 0, 0, 0.25)", padding: "0 5px" }}>{t("passwordRecovery")}</Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "center" }} className="g-recaptcha-inner">
                <ReCAPTCHA
                  sitekey="6LfKh64mAAAAAPBcT_cK-ExJsLxXAdzBKWAJa1q1"
                  onChange={(value) => onChangeRechapta(value)}
                  className="g-recaptcha-login"
                  hl="fa"
                />
              </Grid>
              <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%" }}>
                <Button onClick={loginHandeler} disabled={btnLogin ? true : false} sx={{
                  display: "flex", bgcolor: "#FF3951", width: { xs: "158px", sm: "300px" }, height: { xs: "40px", sm: "50px" }, boxShadow: " #FF3951 0px 5px 15px", margin: "0px auto",
                  "&:hover": { backgroundColor: "#FF3951" }
                }}>
                  <Typography component={"p"} sx={{ color: "white", fontSize: { xs: "12px", md: "14px" } }}>
                    {btnLogin === true && loadingLog === true ? <CircularProgress size={20} color="inherit" /> : t("login")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid sx={{ display: type === "F" ? "flex" : "none", alignItems: "center", justifyContent: "space-evenly", height: "100%", flexDirection: "column", }}>
              <TextField
                className="textField"
                label={t("phoneNumber")}
                variant='outlined'
                value={onlyDigit(phoneNumberLOG)}
                onFocus={focusHandler}
                name="phoneNumberForgot"
                inputMode="tel"
                onChange={(event) => phoneNumberLogHandeler(event)}
                error={errors.phoneNumber && touched.phoneNumberForgot}
                helperText={errors.phoneNumber && touched.phoneNumberForgot && errors.phoneNumber}
                inputProps={{ maxLength: 11 }}
                fullWidth
                sx={{ width: { xs: "90%", sm: "492px" }, textAlign: "center", display: "flex", borderRadius: "14px", "& .MuiOutlinedInput-input": { padding: "14px" } }}
                InputLabelProps={{ sx: { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "14px" : "12px" } } }}
              />
              <TextField
                className="textField"
                label={t("newPassword")}
                variant='outlined'
                type={showPass ? 'text' : 'password'}
                value={newPass}
                onChange={(event) => newPassHandeler(event)}
                onFocus={focusHandler}
                name="newPass"
                error={errors.pass && touched.newPass}
                helperText={errors.pass && touched.newPass && errors.pass}
                fullWidth
                sx={{ width: { xs: "90%", sm: "492px" }, textAlign: "center", display: "flex", borderRadius: "14px", "& .MuiOutlinedInput-input": { padding: "14px" } }}
                InputLabelProps={{ sx: { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "14px" : "12px" } } }}
                InputProps={{
                  endAdornment:
                    <IconButton
                      aria-label="toggle pass visibility"
                      onClick={handleClickShowPass}
                      onMouseDown={handleMouseDownPass}
                      edge="end"
                    >
                      {showPass ? <VisibilityIcon sx={{ fontSize: { sm: "18px", md: "24px" } }} /> : <VisibilityOffIcon sx={{ fontSize: { sm: "18px", md: "24px" } }} />}
                    </IconButton>
                }}
              />
              <TextField
                className="textField"
                label={t("repeatPass")}
                variant='outlined'
                type={showPass ? 'text' : 'password'}
                value={repeatPass}
                onChange={(event) => repeatPassHandeler(event)}
                onFocus={focusHandler}
                name="repeatPass"
                error={errors.repeatPass && touched.repeatPass}
                helperText={errors.repeatPass && touched.repeatPass && errors.repeatPass}
                fullWidth
                sx={{ width: { xs: "90%", sm: "492px" }, textAlign: "center", display: "flex", borderRadius: "14px", "& .MuiOutlinedInput-input": { padding: "14px" } }}
                InputLabelProps={{ sx: { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "14px" : "12px" } } }}
                InputProps={{
                  endAdornment:
                    <IconButton
                      aria-label="toggle pass visibility"
                      onClick={handleClickShowPass}
                      onMouseDown={handleMouseDownPass}
                      edge="end"
                    >
                      {showPass ? <VisibilityIcon sx={{ fontSize: { sm: "18px", md: "24px" } }} /> : <VisibilityOffIcon sx={{ fontSize: { sm: "18px", md: "24px" } }} />}
                    </IconButton>
                }}
              />
              <Grid sx={{ display: sendSmsForgot ? "flex" : "none", justifyContent: "center", alignItems: "center", }} >
                <TextField
                  className="textField"
                  label={t("enterCode")}
                  variant='outlined'
                  value={onlyDigit(smsCode)}
                  onFocus={focusHandler}
                  name="codeSms"
                  inputMode="numeric"
                  inputProps={{ maxLength: 6 }}
                  error={errors.codeSms && touched.codeSms}
                  helperText={errors.codeSms && touched.codeSms && errors.codeSms}
                  onChange={(event) => smsCodeHandeler(event)}
                  fullWidth
                  sx={{ width: { xs: "90%", sm: "492px" }, textAlign: "center", borderRadius: "14px", "& .MuiOutlinedInput-input": { padding: "14px" } }}
                  InputLabelProps={{ sx: { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "14px" : "12px" } } }}
                  InputProps={{
                    endAdornment:
                      <Grid
                        sx={{
                          width: "100px", textAlign: "center", marginLeft: "5px", transition: "all 0.1s ease-in-out", display: 'flex', alignItems: "center", flexDirection: "row"
                        }}
                      >
                        <Typography sx={{ fontSize: "14px", color: "#000000" }}>
                          {format(timeLeft)}
                        </Typography>
                        <Typography component={"img"} alt="clock" className="clock" src={clock} sx={{ width: "17px", height: "25px", marginRight: "10px" }} />
                      </Grid>
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "center" }} className="g-recaptcha-inner">
                <ReCAPTCHA
                  sitekey="6LfKh64mAAAAAPBcT_cK-ExJsLxXAdzBKWAJa1q1"
                  onChange={(value) => onChangeRechapta(value)}
                  className="g-recaptcha-login"
                  hl="fa"
                />
              </Grid>
              <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", }}>
                <Button onClick={forgotHandeler} disabled={btnForgot ? true : false}
                  sx={{
                    display: "flex", bgcolor: "#FF3951", width: { xs: "158px", sm: "300px" }, height: { xs: "40px", sm: "50px" }, boxShadow: " #FF3951 0px 5px 15px", margin: "0px auto",
                    "&:hover": { backgroundColor: "#FF3951" }
                  }}>
                  <Typography component={"p"} sx={{ color: "white", fontSize: { xs: "12px", md: "14px" } }}>
                    {btnForgot === true && loadingForgot === true ? <CircularProgress size={20} color="inherit" /> : sendSmsForgot ? t("changePass") : t("sendCode")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid sx={{ display: type === "R" ? "flex" : "none", alignItems: "center", justifyContent: "space-evenly", height: "100%", flexDirection: "column", }}>
              <TextField
                className="textField"
                label={t("phoneNumber")}
                variant='outlined'
                value={onlyDigit(phoneNumberREG)}
                name="phoneNumberReg"
                inputMode="tel"
                inputProps={{ maxLength: 11 }}
                onFocus={focusHandler}
                error={errors.phoneNumber && touched.phoneNumberReg}
                helperText={errors.phoneNumber && touched.phoneNumberReg && errors.phoneNumber}
                onChange={(event) => setPhoneNumberRegHandeler(event)}
                fullWidth
                sx={{
                  width: { xs: "90%", sm: "492px" }, textAlign: "center", display: "flex", borderRadius: "14px"
                  , "& .MuiOutlinedInput-input": { padding: "14px" }
                }}
                InputLabelProps={{ sx: { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "14px" : "12px" } } }}
              />
              <TextField
                className="textField"
                label={t("password")}
                variant='outlined'
                type={showPass ? 'text' : 'password'}
                value={passswordREG}
                onChange={(event) => passRegHandeler(event)}
                onFocus={focusHandler}
                name="passReg"
                error={errors.pass && touched.passReg}
                helperText={errors.pass && touched.passReg && errors.pass}
                fullWidth
                sx={{ width: { xs: "90%", sm: "492px" }, textAlign: "center", display: "flex", borderRadius: "14px", "& .MuiOutlinedInput-input": { padding: "14px" } }}
                InputLabelProps={{ sx: { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "14px" : "12px" } } }}
                InputProps={{
                  endAdornment:
                    <IconButton
                      aria-label="toggle pass visibility"
                      onClick={handleClickShowPass}
                      onMouseDown={handleMouseDownPass}
                      edge="end"
                    >
                      {showPass ? <VisibilityIcon sx={{ fontSize: { sm: "18px", md: "24px" } }} /> : <VisibilityOffIcon sx={{ fontSize: { sm: "18px", md: "24px" } }} />}
                    </IconButton>
                }}
              />
              <Grid sx={{ display: 'flex', flexDirection: "column", alignItems: "flex-start", justifyContent: "center", border: "1px solid #CBCBCB", borderRadius: "14px", width: "100%" }}>
                <Typography sx={{ fontSize: { xs: "12px", md: i18n.language === "fa" ? "16px" : "14px" }, margin: "10px 20px", lineHeight: "150%" }}>{t("panelType")}</Typography>
                <Grid sx={{ borderTop: "1px solid #CBCBCB", width: "100%", padding: i18n.language === "en" ? "0 15px" : "0" }}>
                  <RadioGroup name="use-radio-group" defaultValue="first" value={panelType} onChange={(event) => setPanelType(event.target.value)} >
                    <FormControlLabel value="2" label={t("organizationPanel")}
                      control={<Radio sx={{ "&.Mui-checked": { color: "#FF3951" }, '& .MuiSvgIcon-root': { fontSize: "20px" } }} />}
                      sx={{ ".MuiFormControlLabel-label": { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "16px" : "14px", color: "#070707" } } }} />
                    <FormControlLabel value="1" label={t("personalPanel")}
                      control={<Radio sx={{ "&.Mui-checked": { color: "#FF3951" }, '& .MuiSvgIcon-root': { fontSize: "20px" } }} />}
                      sx={{ ".MuiFormControlLabel-label": { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "16px" : "14px", color: "#070707" } } }} />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid sx={{ display: sendSms ? "flex" : "none", justifyContent: "center", alignItems: "center", margin: "5% 0 0", }} >
                <TextField
                  className="textField"
                  label={t("enterCode")}
                  variant='outlined'
                  value={onlyDigit(smsCode)}
                  onFocus={focusHandler}
                  name="codeSms"
                  inputMode="numeric"
                  inputProps={{ maxLength: 6 }}
                  error={errors.codeSms && touched.codeSms}
                  helperText={errors.codeSms && touched.codeSms && errors.codeSms}
                  onChange={(event) => smsCodeHandeler(event)}
                  fullWidth
                  sx={{ width: { xs: "90%", sm: "492px" }, textAlign: "center", borderRadius: "14px", "& .MuiOutlinedInput-input": { padding: "14px" } }}
                  InputLabelProps={{ sx: { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "14px" : "12px" } } }}
                  InputProps={{
                    endAdornment:
                      <Grid
                        sx={{
                          width: "100px", textAlign: "center", marginLeft: "5px", transition: "all 0.1s ease-in-out", display: 'flex', alignItems: "center", flexDirection: "row"
                        }}
                      >
                        <Typography sx={{ fontSize: "14px", color: "#000000" }}>
                          {format(timeLeft)}
                        </Typography>
                        <Typography component={"img"} alt="clock" className="clock" src={clock} sx={{ width: "17px", height: "25px", marginRight: "10px" }} />
                      </Grid>
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "center" }} className="g-recaptcha-inner">
                <ReCAPTCHA
                  sitekey="6LfKh64mAAAAAPBcT_cK-ExJsLxXAdzBKWAJa1q1"
                  onChange={(value) => { onChangeRechapta(value) }}
                  className="g-recaptcha-login"
                  hl="fa"
                />
              </Grid>
              <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%" }}>
                <Button onClick={registerHandeler} disabled={btnRegister ? true : false}
                  sx={{
                    display: "flex", bgcolor: "#FF3951", width: { xs: "158px", sm: "300px" }, height: { xs: "40px", sm: "50px" }, boxShadow: " #FF3951 0px 5px 15px", margin: "0px auto",
                    "&:hover": { backgroundColor: "#FF3951" }
                  }}>
                  <Typography component={"p"} sx={{ color: "white", fontSize: { xs: "12px", md: "14px" } }}>
                    {btnRegister === true && loadingReg === true ? <CircularProgress size={20} color="inherit" sx={{ color: "white" }} /> : sendSms ? t("register") : t("sendCode")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
    </>
  );
}
export default ModalLogin