import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import { author, date, h1, headPhoto, largePhoto, linkArticle, rootArticle, tags, paragraph, timeRead, view, listItemCircle, h2, h4, smallPhoto, sourceLink } from "../articleFunction/ArticleFunction";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { Articles } from "./Articles";
import ArticleSide from "../articleFunction/ArticleSide";
import { NavLink } from "react-router-dom";
//import photo
import photo1 from '../../image/articles/08/photo1.jpg';
import photo2 from '../../image/articles/08/photo2.jpg';
import photo3 from '../../image/articles/08/photo3.jpg';
import photo4 from '../../image/articles/08/photo4.jpg';
import photo5 from '../../image/articles/08/photo5.jpg';
import photo6 from '../../image/articles/08/photo6.jpg';
import photo7 from '../../image/articles/08/photo7.jpg';

import Loader from "../Loader";
import PortfolioButton from "../PortfolioButton";


let articleData = null;
const Article08 = () => {

  //NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${articleData !== null && articleData.title}`
    document.getElementById("main-heading").innerHTML = articleData !== null && articleData.h1;
    document.getElementsByTagName('meta')["description"].content = articleData !== null && articleData.desc;
    document.getElementsByTagName('meta')["keywords"].content = articleData !== null && articleData.keywords;
    document.getElementsByTagName('meta')["author"].content = articleData !== null && articleData.author;
    setCanonicalTag()
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item
    })
  }, [])

  useEffect(() => {
    articleData !== null && addView(); getView();
  }, [])

  const addView = () => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v1/article/addView",
      method: "POST",
      data: { id: articleData.id }
    }))
      .then((res) => { })
  }
  const getView = () => {
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/article/getView`,
      method: "GET",
      params: { id: articleData.id }
    }))
      .then((res) => {
        setDataView(res.data.data)
      })
  }

  //change This part

  //read More
  const readMore = [
    {
      id: 1,
      title: "معرفی بهترین لوگوهای جهان در سال 2023      ",
      link: "/Graphic"
    },
    {
      id: 2,
      title: "صفر تا صد راه اندازی پیج حرفه ای کاری اینستاگرام ",
      link: "/instagram-business-page"
    }

  ]
  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: "rtl", alignItems: "center" }}>
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid container sx={{ display: "flex", alignItems: { xs: "unset", sm: 'flex-start' }, justifyContent: "space-between", flexDirection: "row", flexWrap: "wrap", width: { xs: "100%", sm: "85%" } }}>
          <ArticleSide tags={articleData !== null && articleData.tags} readMore={readMore} />
          {
            articleData !== null ?
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: 'center', sm: "flex-end" }, flexDirection: "column", width: { xs: "100%", sm: "60%", md: "70%" } }} >
                {/* do not change */}
                {headPhoto(articleData.image)}
                {h1(articleData.h1, t)}
                {timeRead(articleData.timeRead, t)}
                {date(articleData.date, i18n)}
                <Grid sx={{ display: 'flex', alignItems: {xs:"center",sm:'flex-start'}, justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "column" }}>
                  {/* The text of the article */}
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    تاریخچه اینستاگرام
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    در فوریه 2010 برای اولین بار <Typography component={"a"} target="_blank" href="https://www.instagram.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >برنامه </Typography>
                    اینستاگرام برای گوشی های ایفون به بازار آمد. بعد از دو سال یعنی در آوریل 2012 نسخه اندروید این <NavLink to="/Academy/what-is-application" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >اپلیکیشن </NavLink>
                    نیز منتشر یافت. در همان ابتدای پیدایش اینستاگرام بیش از یک میلیون کاربر از این برنامه استفاده می کردند و روز به روز به تعداد کاربران این اپلیکیشن اضافه می شد. بر طبق اخرین امار رسمی از <Typography component={"a"} target="_blank" href="https://about.meta.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >متا </Typography>
                    تا سال 2022 بیش از دو میلیارد کاربر، ماهانه در اینستاگرام فعالیت داشتند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    اینستاگرام در آغاز تنها روی عکس فعالیت داشت اما با گذشت زمان و آپدیت نسخه آن، بخش ریلز نیز به این اپلیکیشن اضافه شد و بیشتر فعالیت خود را روی محتوای ویدئویی متمرکز کرد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify", textAlign: "start", width: { xs: "90%", sm: "100%" } }}>
                    مفاهیم اینستاگرام
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo1} alt="مفاهیم اینستاگرام - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        مفاهیم اینستاگرام
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    در اینستاگرام اصطلاحاتی مطرح می شود که بهتر است کاربرانی که از این اپلیکیشن استفاده می کنند آشنایی نسبی با این مفاهیم داشته باشند. در زیر به بررسی این لغات می پردازیم.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    بایو
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    بایو اینستاگرام متنی است که در زیر نام کاربری پروفایل قرار می گیرد. اکثر افراد بایو را به اشتباه بیو می نامند. در بایو اطلاعات شخصی یا اطلاعات مربوط به برند نوشته می شود و این قسمت برای جذب مخاطب بسیار موثر است.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    الگوریتم
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    الگوریتم نرم افزاری در صفحه اصلی اینستاگرام به چشم می خورد. این الگوریتم مشخص می کند که پست در صفحه اصلی اینستاگرام با چه ترتیبی نمایش داده شود. الگوریتم ها در شبکه های اجتماعی مسئول هشتگ های ترند و پست های مشاهده شده توسط کاربر می باشند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    آیدی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    آی دی اینستاگرام یا نام کاربری، به هندل اینستاگرام نیز معروف است. آی دی برای نمایش حساب های مختلف کاربری به کار می رود و در بالای صفحه با متن مشکی نمایش داده می شود. در هنگام ایجاد حساب کاربری، انتخاب نام کاربری اولین کاری است که باید انجام پذیرد. برای افراد معروف دنیا نام کاربری همراه با تیک آبی نشان داده می شود که نشان دهنده رسمی بودن آن است.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    کپشن
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    کپشن اطلاعاتی در مورد پست می دهد که البته دارای محدودیت کلمه نیز می باشد. ایجاد کپشن جذاب در گذاشتن کامنت توسط کاربران تاثیر فراوانی دارد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: { xs: "90%", sm: "100%" }}}>
                    گالری
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo2} alt="گالری اینستاگرام - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        اینستاگرام
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    در اینستاگرام این قابلیت وجود دارد که برای هر پست حدود ده عکس و ویدئو قرار دهید که این محتواهای تصویری در قالب گالری به کاربر نشان داده می شود.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    آرشیو
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    در این بخش شما این امکان را دارید که پست ها و استوری ها را بدون حذف کردن، از دید کاربر مخفی کنید اما در قسمت آرشیو تنظیمات این پست ها و استوری ها را قابل مشاهده است.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    فید
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    به صفحه اصلی اینستاگرام فید می گویند. پست‌های جدیدی که در حساب‌های فالو شده، گذاشته می شود در صفحه‌ی فید قابل مشاهده است. فید همچنین پست‌هایی را نمایش می دهد که الگوریتم به شما پیشنهاد می‌دهد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    ذخیره پست
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    با این قابلیت می توانید پست های اینستاگرام را ذخیره کنید که هر زمان نیاز داشتید دوباره به این پست های ذخیره شده دسترسی داشته باشید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    استوری
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo3} alt="استوری اینستگرام - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        استوری اینستاگرام
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    این قسمت در بالای فید اصلی اینستاگرام قرار دارد. استوری را می‌توان در قالب عکس یا ویدیو منتشر کرد. مدت زمان نمایش استوری ها 24 ساعت می باشد که برای ماندگاری آن می توانید در بخش‌های‌لایت استوری را ذخیره نمایید. با استوری مناسب می توانید با کاربران تعامل برقرار کنید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    های لایت
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    چنانچه قصد دارید استوری شما در صفحه باقی بماند و از بین نرود با های لایت این امکان فراهم می شود که استوری شما ذخیره شود.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    تعامل
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    در شبکه های اجتماعی برقراری ارتباط با دیگر کاربران، تعامل یا Engagement نام دارد که این تعامل می تواند از طریق لایک کردن یا پاسخ دهی به کامنت ها انجام شود.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: { xs: "90%", sm: "100%" }}}>
                    نرخ تعامل
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    برای محاسبه نرخ تعامل یا Engagement Rate نیاز به یکسری محاسبات دارد. برای این امر باید مجموع تعداد لایک ها و کامنت ها را بر تعداد فالورها تقسیم کرد و سپس این عدد را در 100 ضرب کرد تا نرخ تعامل پست بدست آید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    ریچ
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    این اصطلاح در مورد تعداد افرادی که پست و استوری شما را دیدند اشاره دارد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: { xs: "90%", sm: "100%" }}}>
                    ایمپرشن
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    به مجموع بازدید های پست ایمپرشن یا Impressions می گویند. ریچ به تعداد کاربران یکتایی که پست ها را مشاهده نمودند می گویند اما ایمپرشن مجموع دفعات نمایش پست می باشد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    دایرکت
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo4} alt="دایرکت اینستاگرام" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        دایرکت اینستاگرام
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    در این قسمت امکان پیام خصوصی فراهم است. این بخش شبیه پیام رسان عمل می کند اما با محدودیت بیشتر. پیام های بخش دایرکت تاثیری در نرخ تعامل ندارد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    اینسایت
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    این بخش، آماری از حساب کاربری را ارائه می دهد که مختص حساب های تجاری می باشد. داده‌های این بخش اطلاعات جامعی از مخاطبین، محتوا و عملکرد حساب کاربری ارائه می‌دهد. تحلیل داده‌های اینسایت کمک شایانی در رشد صفحات اینستاگرام دارد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: { xs: "90%", sm: "100%" }}}>
                    تگ کردن
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    زمانی که نام فرد دیگری در پست پیوست می شود این عمل را تگ کردن می نامند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    فالور
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    کاربرانی که حساب اینستاگرامی را دنبال می کنند فالور یا دنبال کننده نام دارند. تعداد فالورها را در بالای صفحه می توان مشاهده نمود.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    نوتیفیکیشن
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    نوتیفیکیشن یا اعلان برای اطلاع رسانی فعالیت های جدید در اینستاگرام کارایی دارد. این بخش فعالیت های متنوعی را دربر می گیرد از جمله لایک کردن و کامنت گذاشتن. همچنین شروع‌شدن لایو و اطلاع‌رسانی درباره‌ی پست‌های پربازدید و حساب‌هایی که به آن‌ها فالوبک نداده‌اید نیز توسط نوتیفیکیشن یا اعلان، اطلاع رسانی می شود.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    هشتگ
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    هشتگ یا # برای دسته بندی پست ها بکار می رود. با هشتگ گذاری درست می توان بازدید از پست را افزایش داد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    جئوتگ
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    زمانی که به پست های خود لوکیشین پیوست می کنید در اصل جئوتگ یا لوکیشن اضافه کرده اید. جئوتگ در زیر نام کاربری شما به هنگام ارسال پست نمایش داده می‌شود.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    اینفلوئنسر
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo5} alt="اینفلوئنسر - سورین " sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        اینفلوئنسر
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    اینفلوئنسرها افراد معروفی هستند که روی موضوع خاصی در اینستاگرام فعالیت دارند و افراد زیادی نیز آنها را دنبال می کند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: { xs: "90%", sm: "100%" }}}>
                    لایو
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    لایو این امکان فراهم می شود که برای کاربران ویدئوی زنده پخش شود. همچنین می تواند این ویدئو را ذخیره نمایید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    IGTV
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    در نسخه قبلی اینستاگرام قابلیت پخش ویدئوهای طولانی فراهم نبود و تنها روی ویدئوهای کوتاه تمرکز داشت. اما با اضافه شدن IGTV نیز این امکان فراهم شده است. البته IGTV به Instagram Video تغییر نام داد و ارسال ویدیوهای یک ساعته امکانپذیر شد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    صفحه اکسپلور
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    با دکمه ذره بینی پایین صفحه می توانید وارد اکسپلور شوید و پست هایی که از لحاظ بازدید، امار بالایی داشتند را در اکسپلور ملاحظه کنید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    اکانت شخصی اینستاگرام
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    Personal Account یا اکانت شخصی حسابی از اینستاگرام می باشد که برای فعالیت های عادی کاربران طراحی شده است. این حساب کاربری برای افرادی مناسب می باشد که قصد جذب فالوور بالا یا فروش محصول را ندارند و تنها برای دنبال کردن صفحات مورد علاقه شان اینستاگرام را انتخاب کرده اند. اکانت شخصی می تواند در حالت عمومی یا Public قرار گیرد. یا می توانید حالت خصوصی و Private را انتخاب کنید و تنها افراد محدودی پیج را دنبال کنند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    اکانت تولید محتوا
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo6} alt="اکانت تولید محتوا - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        اکانت تولید محتوا
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    Creator Account یا اکانت تولید محتوا بیشتر توسط اینفلوئنسرها یا افرادی که کار تولید محتوا را انجام می دهند مورد استفاده قرار می گیرد. کسب و کارهای کوچک نیز از این نوع حساب کاربری استفاده می نمایند. به ابزارهای آنالیز پیج در این حساب به راحتی دسترسی دارید. همچنین قادرید حساب خود را برای کارهای تبلیغاتی به صفحه فیسبوک خود متصل کنید. همچنین می‌توانید برای پاسخگویی از روش Quick Reply بهره بگیرید. در اکانت Creator، قابلیت اتصال به اکانت از طریق نسخه وب و بررسی آمارهای پیج نیز امکانپذیر است.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    اکانت کاری و تجاری
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    Business Account یا اکانت تجاری تخصصی ترین نوع پیج در اینستاگرام می باشد. این نوع اکانت، برای انواع کسب و کار، شرکت‌ها، فروشگاه‌های بزرگ و کوچک و ارائه دهندگان خدمات تعبیه شده است. در این اکان می‌توان به آمارهای دقیقی درباره پیج دسترسی داشته باشید. همچنین در این اکانت می توانید کمپین تبلیغاتی داشته باشید. بعلاوه اینکه تمامی امکاناتی که در صفحه تولید محتوا قرار دارد در این صفحه نیز قابلیت اجرا دارد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    نکاتی که در زدن پیج اینستاگرام مهم است
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    برای داشتن یک پیج اینستاگرامی نیاز است که یکسری نکات را بازگو کنیم تا با رعایت این مطالب از آسیب به پیج تان جلوگیری کنید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    آیدی پیج
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    برای انتخاب آی دی پیج از همان ابتدا دقت لازم را داشته باشید تا بعدها مجبور به تغییر نشوید. آیدی اینستاگرام باید کوتاه باشد. همچنین از لحاظ نوشتاری سخت نباشد تا کاربر هنگام سرچ دچار مشکل نشود. نکته مهمی که در آیدی حائز اهمیت است این است که آیدی را نباید زیاد عوض کرد زیرا رنکینگ پیج پایین می آید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    عکس پروفایل
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    چنانچه تصمیم دارید که برای پروفایل پیج از لوگو استفاده کنید بهتر است که لوگوهای واضح و شفاف را قرار دهید. همچنین برای پروفایل رنگ های جذاب را بکار ببرید. همچنین می توانید به جای لوگو از عکس مخاطب به جای لوگو استفاده نمایید. سعی کنید تا حد ممکن پروفایل را زیاد عوض نکنید چون امکان دارد پیج از دسترس خارج شود.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    اسم پیج
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo7} alt="اسم پیج - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        نام پیج
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    برای اسم پیج بهتر است از نام های فارسی در حوزه کاری پیج استفاده کنید که در سرچ به راحتی پیدا شود. نام های مبهم و نامفهوم را برای اسم پیج بکار نبرید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    طراحی کاور مناسب
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    کاور جذاب در جذب مخاطب تاثیرگذار است. طراحی کاور مناسب سبب می شود که مخاطب روی پست ضربه بزند و بدین ترتیب پست وارد اکسپلور شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    کلام آخر
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    ما در ای مقاله سعی بر این داشتیم تا مفاهیم اولیه مرتبط با اینستاگرام را مورد بررسی قرار دهیم. چنانچه آشنایی نسبی با اینستاگرام دارید به شما پیشنهاد می دهیم تا مقاله صفر تا صد پیج حرفه ا ی اینستاگرام را مطالعه نمایید.
                  </Typography>
                  <Typography component={"h4"}
                    sx={{ color: "#000000", fontSize: { xs: "12px", sm: "16px", md: "24px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    برای درخواست سفارش اپلیکیشن به صفحه اصلی سایت <NavLink to="/" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >سورین </NavLink>
                    مراجعه نمایید.
                  </Typography>




























                  {/* do not change */}

                  {author(articleData.author, t)}
                  {tags(articleData.tags, t)}
                  {linkArticle(t, url)}
                  {sourceLink(articleData.source, t)}
                  {view(dataview, t)}
                </Grid>

              </Grid>
              :
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "80%", sm: "60%", md: "70%" } }}>
                <Loader />
              </Grid>
          }
        </Grid>
      </Grid>
      <PortfolioButton />
      <Footer />
    </>
  );
}
export default Article08;