import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Button, Typography, Modal, CircularProgress } from "@mui/material";
import searchIcon from '../image/icon/search.png';
import Loader from "./Loader";
import avatar from '../image/dashboard/avatar.png';
import { baseUrl, setCanonicalTag } from "./modules/general";
import axios from "axios";
import AlertMessage from "./modules/AlertMessage";

let dataSelect = [];
const SearchUser = () => {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState("");
  const [dataSearch, setDataSearch] = useState(null);
  const [loadingGrid, setLoadingGrid] = useState(false)
  const [openModal, setOpenModal] = useState(false);
  const [btnActivation, setBtnActivation] = useState(false)
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ alertCheck: false, message: "", severity: "" });
  const head = ["fullNameORorganName", "mobile", "panelType"]

  useEffect(() => {
    document.title = "تاریخچه سفارشات طراحی سایت دربهترین شرکت طراحی سایت در ایران | وب گستران سورین";
    document.getElementById("main-heading").innerHTML = "تاریخچه سفارشات طراحی سایت دربهترین شرکت طراحی سایت در ایران | وب گستران سورین";
    document.getElementsByTagName('meta')["description"].content = " صفحه مربوط به تاریخچه سفارشات ثبت شده در پنل پشتیبان عالی نرم افزارهای شرکت وب گستران سورین. در این قسمت کاربرانی که سفارشات قدیمی ثبت شده نظیر : سفارش طراحی سایت، سفارش طراحی وب سایت، سفارش طراحی رابط کاربری UI، سفارش طراحی تجربه کاربری UX، را دارا هستند.";
    document.getElementsByTagName('meta')["keywords"].content = "تاریخچه سفارشات طراحی وب سایت | تاریخچه سفارشات طراحی رابط کاربری| تاریخچه سفارشات طراحی تجربه کاربری | تاریخچه سفارشات بهینه سازی سایت | تاریخچه سفارشات طراحی گرافیکی | تاریخچه سفارشات طراحی اپلیکیشن";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {

  }, [dataSearch])

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const searchHandeler = () => {
    searchApi()
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchHandeler();
    }
  }

  const dataGrid = () => {
    let list = [];
    if (loadingGrid === true) {
      list.push(
        <Loader key={"data1"} />
      )
    }
    else if (dataSearch === null) {
      list.push(
        <Grid key={"data2"} ></Grid>
      )
    }
    else if (dataSearch.length === 0) {
      list.push(
        <Typography key={"data4"} sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707" }}>{t("notFoundUser")}</Typography>
      )
    }
    else if ((dataSearch !== null) && (head !== null)) {
      dataSearch.forEach((item, index) => {
        list.push(
          <Grid key={index}
            onClick={() => modalHandeler(item)}
            sx={{
              display: 'flex', alignItems: "center", justifyContent: 'center', width: "100%", backgroundColor: parseInt(index + 1) % 2 === 0 ? "#FFFFFF" : "#EDEDED",
              border: "1px solid #757575", borderRadius: "8px", margin: { xs: "15px 5px 0 0", sm: "15px 0 0 0" }, cursor: "pointer", "&:hover": { boxShadow: " 0px 8px 3px 2px rgba(0, 0, 0, 0.25)" }
            }}>
            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-evenly", width: "100%", height: "40px", flexDirection: "row", }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "25%", height: { xs: "60px", sm: "unset" } }}>
                <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{item.user_type_id === 1 ? item.name === null && item.lastname === null ? t("NotCompleted") : `${item.name !== null ? item.name : ""} ${item.lastname !== null ? item.lastname : ""}` : item.organ_name !== null ? item.organ_name : t("NotCompleted")}</Typography>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "25%", height: { xs: "60px", sm: "unset" } }}>
                <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{item.mobile !== null ? item.mobile : t("NotCompleted")}</Typography>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "25%", height: { xs: "60px", sm: "unset" } }}>
                <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{item.user_type_id === "1" ? t("personalPanel") : t("organizationPanel")}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )
      })
    }
    return list;
  }

  const searchApi = () => {
    setLoadingGrid(true)
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/user/search`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
      params: { search: search }
    }))
      .then((res) => {
        setDataSearch(res.data.data)
        setLoadingGrid(false)
      })
  }
  const modalHandeler = (item) => {
    dataSelect = item;
    handleOpenModal();
  }

  const clickOtherLocation = (e) => {
    if (e.target.id === 'modal-search') {
      handleCloseModal();
    }
  }

  const activation = (id, active) => {
    let url;
    setBtnActivation(true)
    setLoading(true)
    url = active === 1 ? "/api/v1/user/deActive" : "/api/v1/user/active"
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    let data = { user_id: id }
    Promise.resolve(axios({
      url: baseUrl + url,
      method: "POST",
      data: data,
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        searchApi();
        setBtnActivation(false)
        setLoading(false)
        setAlert({ alertCheck: true, message: active === 1 ? t("AM_deActivationSuccess") : t("AM_activationSuccess"), severity: "success" })
        handleCloseModal()
      })
      .catch((error) => {
        const { status } = error.response;
        switch (status) {
          case (500):
            setAlert({ alertCheck: true, message: t("AM_intervalServer"), severity: "error" })
            break;
          case (400):
            setAlert({ alertCheck: true, message: t("alert_defualt"), severity: "error" })
            break;
          case (404):
            setAlert({ alertCheck: true, message: t("notFoundUser"), severity: "error" })
            break;
        }
        setBtnActivation(false)
        setLoading(false)
      })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };
  return (
    <>
      <Grid sx={{
        display: 'flex', width: "100%", height: "100%", flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
        direction: i18n.language === "fa" ? "rtl" : 'ltr'
      }}>
        <Grid sx={{ display: 'flex', width: "95%", alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: { xs: "0", sm: "20px", md: "70px" }, }}>
          <TextField placeholder={t("search")}
            className="textField"
            variant='outlined'
            fullWidth
            size="small"
            value={search}
            onKeyDown={handleKeyDown}
            onChange={(e) => setSearch(e.target.value)}
            sx={{
              width: { xs: "80%", sm: "300px", md: "350px" }, display: 'flex', alignItems: "center",
              borderRadius: "8px", margin: { xs: "20px 0px 0 0px", sm: "0px 10px" }
              , "& .MuiOutlinedInput-input": { padding: { xs: "2px 0", sm: "10px 0" }, alignItems: "center" }, background: "#ffffff",
              "& .MuiOutlinedInput-root": { border: "1px solid rgba(255, 57, 81, 0.64)", boxShadow: "0px 0px 6px rgba(255, 57, 81, 0.82)" }
            }}
            InputLabelProps={{ sx: { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "14px" : "12px" } } }}
            InputProps={{
              endAdornment: <Button onClick={() => searchHandeler(search)}><Typography component={"img"} alt="search" src={searchIcon} sx={{ padding: "5px" }} /></Button>,
            }}
          />

          <Grid sx={{
            display: 'flex', alignItems: "center", justifyContent: 'center', width: "95%", height: { xs: "50px", sm: "69px" }, backgroundColor: "#FFFFFF",
            border: "1px solid #CBCBCB", borderRadius: "8px", margin: { xs: "15px 0 0 5px", sm: "30px 0" }
          }}>
            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-evenly", width: "100%", flexDirection: "row", overflow: { xs: "scroll", sm: "unset" } }}>
              {head.map((item, index) => (
                <Grid key={index} sx={{ display: "flex", height: { xs: "50px", sm: "15%" }, alignItems: "center", justifyContent: "center", width: "25%" }}>
                  <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px" }, color: "#070707", fontWeight: "700", textAlign: 'center', }}>{t(item)}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid sx={{
            display: 'flex', alignItems: "center", justifyContent: "space-evenly", width: "95%", height: "100%", margin: { xs: "5px 0 0 5px", sm: "15px 0 0 0" }
            , flexDirection: "column"
          }}>
            {dataGrid()}
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Grid id="modal-search" onClick={(e) => clickOtherLocation(e)} sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh", backgroundColor: 'rgba(105,99,99,0.6)', direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
          {
            dataSelect !== null && dataSelect.length !== 0 &&
            <Grid sx={{
              display: "flex", alignItems: "center", justifyContent: "space-evenly", flexDirection: 'column', background: "#FFFFFF", width: { xs: "95%", md: "700px" },
              borderRadius: "16px", padding: { xs: "25px 0", md: "30px 0" }, maxHeight: "90%", overflow: "scroll"
            }}>
              <Grid sx={{ display: "flex", alignItems: "center", backgroundColor: "#EDEDED", width: { xs: "95%", md: "650px" }, borderRadius: "16px", flexDirection: "column", padding: { xs: "10px 0", md: "15px 0" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'flex-start', width: "80%", flexDirection: "row", padding: { xs: "10px 0", sm: "15px 0", md: "30px 0" } }}>
                  <Grid
                    sx={{
                      width: { xs: "47px", sm: "75px", md: "90px" }, height: { xs: "47px", sm: "75px", md: "90px" }, margin: { xs: "0 3px", sm: "0 10px" }, borderRadius: "50px"
                      , backgroundPosition: "center", backgroundSize: "100% 100%", backgroundRepeat: "repeat", backgroundColor: "#FFFFFF", backgroundImage: `url(${dataSelect.picture_path !== null ? dataSelect.picture_path : avatar})`
                    }} ></Grid>
                  <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: "column", margin: "15px 0" }}>
                    <Typography sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px", xl: "20px" }, color: "#070707", fontWeight: '600' }}>{dataSelect.user_type_id === 1 ? dataSelect.name === null && dataSelect.lastname === null ? t("NotCompleted") : `${dataSelect.name !== null ? dataSelect.name : ""} ${dataSelect.lastname !== null ? dataSelect.lastname : ""}` : dataSelect.organ_name !== null ? dataSelect.organ_name : t("NotCompleted")}</Typography>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px", xl: "18px" }, color: "#070707" }}>{dataSelect.user_type_id === "1" ? t("personalPanel") : t("organizationPanel")}</Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "column", width: "80%" }}>
                  <Grid sx={{ display: dataSelect.user_type_id === 2 ? "flex" : "none", alignItems: "center", justifyContent: "center", flexDirection: "row", margin: "10px 0" }}>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, fontWeight: "600", color: "#070707" }}>{t("nameOfCEO")}:</Typography>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, color: "#070707", textAlign: "justify", padding: "0 5px" }}>{dataSelect.organ_manager_name !== null ? dataSelect.organ_manager_name : t("NotCompleted")}</Typography>
                  </Grid>
                  <Grid sx={{ display: dataSelect.user_type_id === 2 ? "flex" : "none", alignItems: "center", justifyContent: "center", flexDirection: "row", margin: "10px 0" }}>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, fontWeight: "600", color: "#070707" }}>{t("nameOrganizationLegalRepresentative")}:</Typography>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, color: "#070707", textAlign: "justify", padding: "0 5px" }}>{dataSelect.organ_legal_representative_name !== null ? dataSelect.organ_legal_representative_name : t("NotCompleted")}</Typography>
                  </Grid>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", margin: "10px 0" }}>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, fontWeight: "600", color: "#070707" }}>{t("activity")}:</Typography>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, color: "#070707", textAlign: "justify", padding: "0 5px" }}>{dataSelect.activity !== null && dataSelect.hasOwnProperty("activity") ? dataSelect.activity.description : t("NotCompleted")}</Typography>
                  </Grid>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", margin: "10px 0" }}>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, fontWeight: "600", color: "#070707" }}>{t("phoneNumber")}:</Typography>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, color: "#070707", padding: "0 5px" }}>{dataSelect.mobile !== null ? dataSelect.mobile : t("NotCompleted")}</Typography>
                  </Grid>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", margin: "10px 0" }}>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, fontWeight: "600", color: "#070707" }}>{t("addressLabel")}</Typography>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, color: "#070707", textAlign: "justify", padding: "0 5px" }}>{dataSelect.address !== null ? dataSelect.address : t("NotCompleted")}</Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-evenly", flexDirection: 'row', width: "100%", paddingTop: '20px' }}>
                  <Button disabled={btnActivation ? true : false} onClick={() => activation(dataSelect.id, dataSelect.active)} sx={{
                    width: { xs: "100px", sm: "110px" }, height: { xs: "33px", sm: "40px" }, boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", fontSize: { xs: "14px", sm: "16px" },
                    background: "#FF3951", color: "#FFFFFF", "&:hover": { background: "#FF3951" }, margin: "0 10px"
                  }}>
                    {btnActivation === true && loading === true ? <CircularProgress size={20} color="inherit" sx={{ color: "#ffffff" }} /> : dataSelect.active === "0" ? t("activation") : t("Deactivation")}
                  </Button>
                  <Button onClick={handleCloseModal} sx={{
                    width: { xs: "100px", sm: "110px" }, height: { xs: "33px", sm: "40px" }, boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", fontSize: { xs: "14px", sm: "16px" }, border: "2px solid #FF3951",
                    background: "#FFFFFF", color: "#FF3951", "&:hover": { background: "#FFFFFFF" }, margin: "0 10px"
                  }}>
                    {t("cancel")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

          }
        </Grid>
      </Modal>
      <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
    </>
  )
}

export default SearchUser