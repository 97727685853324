import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import {
  author,
  date,
  h1,
  headPhoto,
  largePhoto,
  linkArticle,
  rootArticle,
  tags,
  paragraph,
  timeRead,
  view,
  listItemCircle,
  h2,
  h4,
  smallPhoto,
  sourceLink,
} from "../articleFunction/ArticleFunction";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { Articles } from "./Articles";
import ArticleSide from "../articleFunction/ArticleSide";
import { NavLink } from "react-router-dom";
//import photo
import photo1 from "../../image/articles/15/photo1.jpg";
 import photo2 from "../../image/articles/15/photo2.jpg";
 import photo3 from "../../image/articles/15/photo3.jpg";
 import photo4 from "../../image/articles/15/photo4.jpg";
 import photo5 from "../../image/articles/15/photo5.jpg";
 import photo6 from "../../image/articles/15/photo6.jpeg";
 import photo7 from "../../image/articles/15/photo7.jpg";
 import photo8 from "../../image/articles/15/photo8.png";
import photo9 from "../../image/articles/15/photo9.jpg";

import Loader from "../Loader";
import PortfolioButton from "../PortfolioButton";

let articleData = null;
const Article15 = () => {
  //NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${
      articleData !== null && articleData.title
    }`;
    document.getElementById("main-heading").innerHTML =
      articleData !== null && articleData.h1;
    document.getElementsByTagName("meta")["description"].content =
      articleData !== null && articleData.desc;
    document.getElementsByTagName("meta")["keywords"].content =
      articleData !== null && articleData.keywords;
    document.getElementsByTagName("meta")["author"].content =
      articleData !== null && articleData.author;
    setCanonicalTag();
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item;
    });
  }, []);

  useEffect(() => {
    articleData !== null && addView();
    getView();
  }, []);

  const addView = () => {
    Promise.resolve(
      axios({
        url: baseUrl + "/api/v1/article/addView",
        method: "POST",
        data: { id: articleData.id },
      })
    ).then((res) => {});
  };
  const getView = () => {
    Promise.resolve(
      axios({
        url: `${baseUrl}/api/v1/article/getView`,
        method: "GET",
        params: { id: articleData.id },
      })
    ).then((res) => {
      setDataView(res.data.data);
    });
  };

  //change This part

  //read More
  const readMore = [
    {
      id: 1,
      title: "ده زبان برنامه نویسی محبوب 2023",
      link: "/popular-programming-language-2023",
    },
    {
      id: 2,
      title: "اپلیکیشن تلفن همراه چیست و چگونه کار می کند",
      link: "/what-is-application",
    },
    {
      id: 3,
      title: "نکاتی که باید برای سفارش طراحی وب سایت در نظر گرفت",
      link: "/Points_to_consider_when_ordering_a_website_design",
    },
    {
      id: 4,
      title: "ترند طراحی UI و UX سال 2023",
      link: "/2023-UI-UX-design-trend",
    },
    {
      id: 5,
      title: "معرفی صفر تا صد زبان برنامه پایتون برای مبتدی ها",
      link: "/What-Is-Python-Programming-Language",
    },
  ];
  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          direction: "rtl",
          alignItems: "center",
        }}
      >
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: { xs: "unset", sm: "flex-start" },
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            width: { xs: "100%", sm: "85%" },
          }}
        >
          <ArticleSide
            tags={articleData !== null && articleData.tags}
            readMore={readMore}
          />
          {articleData !== null ? (
            <Grid
              item
              xs={12}
              sm={7.5}
              md={8}
              lg={8.5}
              xl={9}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", sm: "flex-end" },
                flexDirection: "column",
                width: { xs: "100%", sm: "60%", md: "70%" },
              }}
            >
              {/* do not change */}
              {headPhoto(articleData.image)}
              {h1(articleData.h1, t)}
              {timeRead(articleData.timeRead, t)}
              {date(articleData.date, i18n)}
              <Grid
                sx={{
                  display: "flex",
                  alignItems: { xs: "center", sm: "flex-start" },
                  justifyContent: "flex-start",
                  width: { xs: "90%", sm: "100%" },
                  flexDirection: "column",
                }}
              >
                {/* The text of the article */}
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  هوش مصنوعی شاخه‌ای وسیع از علوم کامپیوتر می باشد که نحوه
                  عملکرد آن مشابه مغز انسان در حل مسائل مختلف است.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  مقدمه :
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  اینکه هوش مصنوعی چیست و چگونه در دنیای تکنولوژی کار می
                  کند؟ این سوال و ده ها سوال دیگر در مورد این تکنولوژی مطالبی
                  هستند که در این مقاله از{" "}
                  <NavLink
                    to="/Academy"
                    target="_blank"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    آکادمی{" "}
                  </NavLink>
                  سورین به آن می پردازیم. همچنین هوش مصنوعی به زبان ساده ؛ آشنایی با مزایا و کاربردهای هوش مصنوعی را به طور کامل مورد بررسی قرار می دهیم. امید است که مطالعه این مطالب برای شما
                  مفید واقع شود.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  هوش مصنوعی چیست؟
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={photo1}
                      alt="هوش مصنوعی - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      هوش مصنوعی
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  هوش مصنوعی (Artificial Intelligence) که به اختصار 
                  <strong>AI </strong> نامیده می‌شود، مجموعه‌ای از فناوری‌های
                  مختلف می باشد که ماشین‌ها را قادر می‌سازند تا مشابه انسان عمل
                  کنند. این کلمه از ترکیب دو واژه «<strong> هوش </strong>» +
                  «مصنوعی» است. واژه «هوش» به موجودات زنده، به‌خصوص انسان اشاره
                  دارد. واژه «<strong> مصنوعی </strong>» نیز به این مفهوم اشاره
                  می کند که چیزی را به به‌صورت <strong>غیر طبیعی </strong> ایجاد
                  کنیم.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  به زبان ساده AI را می توان به‌کارگیری مجموعه‌ای از فناوری‌ها و
                  تکنولوژی‌ها برای تبدیل کردن رفتار کامپیوتر به انسان توصیف کرد.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  تاریخچه هوش مصنوعی
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  در جنگ جهانی دوم نیروهای آلمانی برای رمز نگاری و ارسال ایمن
                  پیام ها از ماشین enigma استفاده می کردند. آلن تورینگ، دانشمند
                  انگلیسی تصمیم گرفت که این کدها را رمزیابی کند. بنابراین با کمک
                  تیمش ماشین bombe را ابداع کرد که enigma را رمز گشایی می کرد.
                  این دو ماشین enigma و bombe برای یادگیری ماشینی (machine
                  learning) کارایی دارند که یکی از شاخه های هوش مصنوعی می‌باشد.
                  تورینگ ماشینی را هوشمند می‌دانست که همانند انسان فکر، تصمیم
                  گیری و عمل کند. به این ترتیب بود که AI پا به عرصه تکنولوژی
                  نهاد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  به تدریج با پیشرفت فناوری و سایر سخت افزارهای مورد نیاز برای
                  توسعه هوش مصنوعی، ابزار هوشمند و سرویس‌های هوشمندی به بازار
                  ارائه شدند که از AI در بسیاری از فرآیندهایشان استفاده می‌ شد.
                  سرویس‌های معروف بسیاری مانند موتورهای جستجو، ماهواره‌ها از AI
                  استفاده می‌کردند. با معرفی گوشی‌های هوشمند و پس از آن گجت‌های
                  هوشمند، این فناوری نقش پر رنگ تری را در زندگی انسان‌ ایفا کرد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  برای کسب اطلاعات بیشتر در زمینه فناوری های نوین شما را به
                  مطالعه مقاله{" "}
                  <Typography
                    component={"a"}
                    target="_blank"
                    href="https://homeinja.com/IOT"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    اینترنت اشیا{" "}
                  </Typography>
                  (IOT) در وب سایت هومینجا دعوت می کنیم.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  تاریخچه هوش مصنوعی در ایران
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                     <Typography
                      component={"img"}
                      src={photo2}
                      alt="تاریخچه هوش مصنوعی در ایران - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography> 
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      هوش مصنوعی در ایران
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  اختراع ماشین تورینگ در خلال جنگ جهانی دوم باعث شد تا جنب‌وجوش
                  عظیمی در دانشمندان و متخصصین علوم اعصاب و مهندسین کامپیوتر
                  آغاز گردد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  از تولد AI، زمان زیادی نگذشته بود که هوش مصنوعی از اوایل دهه
                  ۸۰ شمسی به ایران وارد شد. سپس بسیاری از متخصصین به کاوش‌های
                  نظری درباره آن پرداختند، به نحوی که بر اساس دستیابی به حجم
                  تولیدات علمی، به رتبه ۱۳ جهان در زمینه نظریه‌پردازی Artificial
                  Intelligence رسیدیم. در ایران زنده‌یاد، دکتر کارو لوکاس
                  پیش‌گام معرفی و توسعه رشته هوش‌مصنوعی و رباتیک در کشور بود.
                  ایشان به جهت فعالیت‌های بسیار در رشته‌های مختلف علوم محض نظیر
                  محاسبات، ریاضیات و احتمالات، علوم مهندسی نظیر رباتیک، بسیار
                  مورد توجه محافل علمی جهان قرار گرفت. دکتر لوکاس از سال ۱۳۸۱
                  تدریس رشته رباتیک و AI را پس از سال‌ها فعالیت علمی در غرب، در
                  دانشگاه تهران آغاز نمود.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  با وجود اینکه تولیدات علمی و نظری هوش ماشینی در کشورمان سابقه
                  کوتاهی دارد، اما بر اساس تحقیقات و مستندات علمی وسیعی که از
                  دانشمندان ایرانی دست یافتند، بی شک می‌توان آینده درخشانی را
                  برای این رشته از علم در ایران پیش بینی کرد.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  تفاوت هوش مصنوعی و برنامه نویسی
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  ورودی هایی که در برنامه نویسی به کار می بریم معلوم و مشخص
                  هستند و با استفاده از دستورات شرطی مانند if و else قادر خواهیم
                  بود که معاملات را به راحتی حل کنیم. اما در AI مسائلی که مطرح
                  می شود دارای تنوع ورودی زیادی هستند. بنابراین با برنامه نویسی
                  معمولی پوشش تمام جوانب امکانپذیر نیست. مثل یک سیستم تبدیل صدا
                  به متن یا تشخیص چهره که داده‌های ورودی متنوعی دارد. به همین
                  دلیل باید از مدل‌های هوش مصنوعی برای انجام این امور بهره برد.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  شاخه های هوش مصنوعی
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                     <Typography
                      component={"img"}
                      src={photo3}
                      alt="شاخه های هوش مصنوعی - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography> 
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      شاخه های AI
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  هوش مصنوعی یک علم نوین و گسترده است که دارای شاخه های متنوعی
                  نیز می باشد. در ذیل به این شاخه ها اشاره می کنیم و در مقالات
                  بعدی AI به تعریف جامع از هر کدام می پردازیم.
                </Typography>
                <Typography component={"ol"}>
                  <Typography
                    component={"li"}
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textAlign: "start",
                      width: "100%",
                      fontWeight: "700",
                      marginBottom: { xs: "10px", md: "15px" },
                    }}
                  >
                    رباتیک (Robotics)
                  </Typography>
                  <Typography
                    component={"li"}
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textAlign: "start",
                      width: "100%",
                      fontWeight: "700",
                      marginBottom: { xs: "10px", md: "15px" },
                    }}
                  >
                    سیستم خبره (Experts Systems)
                  </Typography>
                  <Typography
                    component={"li"}
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textAlign: "start",
                      width: "100%",
                      fontWeight: "700",
                      marginBottom: { xs: "10px", md: "15px" },
                    }}
                  >
                    شبکه عصبی (Neural Network)
                  </Typography>
                  <Typography
                    component={"li"}
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textAlign: "start",
                      width: "100%",
                      fontWeight: "700",
                      marginBottom: { xs: "10px", md: "15px" },
                    }}
                  >
                    پردازش زبان طبیعی (Natural Language Processing)
                  </Typography>
                  <Typography
                    component={"li"}
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textAlign: "start",
                      width: "100%",
                      fontWeight: "700",
                      marginBottom: { xs: "10px", md: "15px" },
                    }}
                  >
                    منطق فازی (Fuzzy Logic)
                  </Typography>
                  <Typography
                    component={"li"}
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textAlign: "start",
                      width: "100%",
                      fontWeight: "700",
                      marginBottom: { xs: "10px", md: "15px" },
                    }}
                  >
                    یادگیری ماشین (Machine Learning)
                  </Typography>
                </Typography>

                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  کارهایی که هوش مصنوعی انجام می دهد
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  تقلید از ساختار مغز انسان، درک متقابل، خودآموزی و بازاندیشی در
                  مورد انواع مختلف حیات بیولوژیکی، جایگزینی افراد در مشاغل مختلف
                  و تقلب در بازی‌های کامپیوتری اینها تنها نمونه ای از انواع
                  اموری هستند که این تکنولوژی انجام می دهد. در این بخش به برخی
                  از توانایی‌ها و قدرت‌های ماورایی AI می پردازیم.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  هوش مصنوعی در پزشکی
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={photo4}
                      alt="هوش مصنوعی در پزشکی - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      هوش مصنوعی و پزشکی
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  در سال های اخیر کاربردهای هوش مصنوعی در پزشکی و سلامت رواج
                  پیدا کرده است. پژوهش‌های صورت گرفته در حوزه­‌های مرتبط با
                  پزشکی، دارو و خدمات به معلولین، حاکی از آن است که فناوری AI
                  می‌تواند تحولات عظیمی را در زمینه‌­هایی مانند تشخیص بیماری،
                  روش‌­های درمان، اختلالات دارویی و پردازش تصاویر پزشکی ایجاد
                  نماید. همچنین این تکنولوژی در پردازش داده‌های پزشکی نقش موثری
                  را ایفا می کند و به متخصصین پزشکی بینش‌های مهمی را ارائه می
                  دهد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  در دنیای امروز اصلی ترین نقش AI در تنظیمات پزشکی، پشتیبانی
                  تصمیم گیری بالینی و تجزیه و تحلیل تصویربرداری می باشد.
                  ابزارهای پشتیبانی تصمیم بالینی با فراهم ساختن دسترسی سریع به
                  اطلاعات یا تحقیقات مرتبط با بیمار، درباره درمان‌ها، داروها،
                  سلامت روان و سایر نیازهای بیمار، به ارائه دهندگان خدمات سلامت
                  کمک می کند تا بهترین تصمیم‌ را بگیرند. ابزارهای AI در
                  تصویربرداری پزشکی، برای تجزیه و تحلیل سی تی اسکن، اشعه ایکس،
                  MRI و سایر تصاویر برای ضایعات کاربرد دارد.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  آموزش ربات ها برای انجام امور مختلف با هوش مصنوعی
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  امروزه به طور گسترده از AI برای آموزش ربات ها استفاده می شود.
                  برای نمونه با استفاده از شیوه ای جدید از هوش مصنوعی، مهندسان
                  دانشگاه{" "}
                  <Typography
                    component={"a"}
                    target="_blank"
                    href="https://www.mit.edu"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    MIT{" "}
                  </Typography>
                  موفق شدند تا به ربات‌ها آموزش دهند که از بازوهای مکانیکی خود
                  برای حمل و نگه داشتن هزاران شی مختلف استفاده کنند. این کار
                  بدون شبیه‌سازی انجام شد و نتیجه کار ساخت شبیه‌سازی دست انسان
                  بود که قادر است بیش از ۲ هزار شی مختلف را برداشته و آن‌ها را
                  با بازوهای مکانیکی خود لمس کند و حرکت دهد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  این سیستم برای بلند کردن و نگه داشتن شی در دستان خود نیازی
                  ندارد که بداند دقیقاً چه چیزی را باید بلند کند. تا اینجا نرخ
                  موفقیت رباتی که از این سیستم استفاده می‌کند بسته به نوع شی
                  متفاوت می باشد اما در طول زمان با ارتقا دادن الگوریتم خود
                  ربات‌ها می توانند به مهارت بیشتری دست یابند.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  استفاده از هوش مصنوعی برای آموزش مهارت های اجتماعی ربات ها
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                   <Typography
                      component={"img"}
                      src={photo5}
                      alt="مهارت اجتماعی ربات ها - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      مهارت اجتماعی ربات ها
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  محققان دانشگاه MIT الگوریتم دیگری برای اموزش مهارت‌های اجتماعی
                  و همکاری دوجانبه ربات ها ساختند. مدل‌های ریاضی جدید طوری طراحی
                  شدند که به ماشین‌ها، درکی از رفتارهای فیزیکی و اجتماعی
                  ربات‌های دیگر را اموزش می دهند. بنابراین اگر رباتی رفتاری
                  منطقی و با معنی را به لحاظ اجتماعی انجام دهد، چون رفتار خوبی
                  است، ربات دیگر باید به این ربات در انجام این کار یاری رساند.
                  همچنین اگر رباتی قصد انجام کار بدی را داشته باشد، ربات فرضی
                  دیگر باید از انجام آن جلوگیری نماید.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  محققان همواره سعی دارند تا ربات‌ها را به شبکه عصبی مخصوصی
                  تجهیز کنند که فرایند تجربه اجتماعی سرعت یابد. همچنین آن‌ها روی
                  سیستم حسگر سه بعدی فعالیت می کنند تا ربات ها قادر باشند عملیات
                  پیچیده‌ را به تنهایی انجام دهند.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  شبیه سازی ساختار مغز انسان با هوش مصنوعی
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  دانشمندان موسسه فناوری ماساچوست به این نتیجه مهم دست یافتند که
                  در حین طبقه‌بندی رایحه‌ها، شبکه‌های عصبی مصنوعی از ساختاری
                  استفاده می کنند که به ساختار بویایی مغز انسان بسیار شباهت
                  دارد. انسان‌ها و سایر حیوانات، اطلاعات بویایی را به طور مشابه
                  در مغزشان انجام می‌دهند.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  دانشمندان در فرایند آموزش الگوریتم‌ها برای طبقه‌بندی رایحه‌ها
                  قصد کپی‌برداری از مغز موجودات زنده را نداشتند و در روند حل این
                  مسئله شبکه عصبی مصنوعی به میل خود شبکه بیولوژیکی بویایی را
                  بازتولید کردند. این رویداد شگفت‌انگیز نشان دهنده طراحی بهینه
                  سیستم‌های بیولوژیکی است. از طرف دیگر، این مسئله امکان مدلسازی
                  کل مغز انسان را هم فراهم می‌ آورد. به تازگی تخصص در یکی از
                  مهم‌ترین کارکردها و قابلیت‌های مغز به وسیله هوش مصنوعی بدست
                  آمده است. این دستاورد جدید، شناسایی ارتباط بین علت و معلولی را
                  مشخص می کند.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  بازارکار رشته های مرتبط با هوش مصنوعی
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={photo6}
                      alt="بازار کار هوش مصنوعی - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      بازار کار هوش مصنوعی
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  بازار کار هوش مصنوعی در سال های اخیر در ایران بسیار رونق گرفته
                  است. برخی از شرکت‌ها چند سالی است که فعالیت‌هایی مرتبط با AI
                  را به‌طور مستقیم یا غیرمستقیم انجام می دهند. با توجه به این
                  نکته که این فناوری در صنایع و کسب‌ و کارها بسیار مهم و
                  تاثیرگذار می باشد، پیش‌بینیها نشان می دهد که بازار کار آن هم
                  رشد گسترده ای در ایران و جهان دارد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  بنابراین انتظار می‌رود که تقاضا برای استخدام متخصصینی که در
                  زمینه AI فعالیت می‌کنند با گذشت زمان افزایش پیدا کند. طی بررسی
                  از وب سایت‌های مطرح کاریابی در ایران، برخی از موقعیت‌های شغلی
                  حوزه هوش مصنوعی در زیر فهرست شده‌اند. سعی کردیم ترتیب‌بندی این
                  فهرست براساس میزان تقاضا و تعداد آگهی‌ها صورت پذیرد.
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  کارشناس هوش تجاری (BI)
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  کارشناس AI
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  کارشناس تحلیل داده و هوش تجای
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  کاراموز رباتیک
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  پژوهشگر AI
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  سرپرست هوش تجاری
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  برنامه نویس هوش مصنوعی
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  مهندس AI
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  برنامه نویس پردازش تصویر و هوش مصنوعی
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  تحلیل‌گر داده
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  کارشناس پردازش تصویر
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  کارشناس ارشد پردازش تصویر
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  دانشمند داده
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  کارشناس تحول دیجیتال - تخصص هوش مصنوعی
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  مدرس دوره‌های AI
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  کاربرد هوش مصنوعی
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={photo7}
                      alt="کاربرد هوش مصنوعی - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      کاربرد هوش مصنوعی
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  محبوبیت هوش مصنوعی روز‌به‌روز در حال افزایش است. AI در سال‌های
                  اخیر به‌طور قابل توجهی پیشرفت داشته‌ است و امروزه تقریباً
                  استفاده از این تکنولوژی در تمام بخش‌های صنایع و کسب و کارهای
                  مختلف به طور وسیعی فراگیر شده است. در ادامه به شرح کاربردهای
                  AI در حوزه ها و صنایع مختلف می پردازیم.
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  تجارت الکترونیک
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  پزشکی
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  بانکداری و بازارهای{" "}
                  <Typography
                    component={"a"}
                    target="_blank"
                    href="https://mycryptocommerce.com"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    مالی{" "}
                  </Typography>
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  سبک زندگی
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  شبکه اجتماعی
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  رباتیک
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  منابع انسانی
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  حمل و نقل
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  بازی های کامپیوتری
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  <NavLink
                    to="/Academy/What-Is-Digital-Marketing"
                    target="_blank"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    بازاریابی{" "}
                  </NavLink>
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  آموزش
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  کشاورزی
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  سطوح مختلف هوش مصنوعی
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={photo8}
                      alt="سطوح مختلف هوش مصنوعی - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      سطوح هوش مصنوعی
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  هوش مصنوعی با توجه به درکی که از دنیای بیرون دارد و می تواند
                  به آن پاسخ دهد دارای سطوح مختلفی می باشد. این سطوح شامل سه سطح
                  محدود، عمومی و سوپر هوش مصنوعی است. در ادامه به توضیح مختصری
                  در مورد هر یک از این سطوح می پردازیم.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  هم چنین می توانید برای خرید ارزهای دیجیتال حوزه هوش مصنوعی به
                  قسمت{" "}
                  <Typography
                    component={"a"}
                    target="_blank"
                    href="https://mycryptocommerce.com/Market"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    بازار{" "}
                  </Typography>
                  صرافی{" "}
                  <Typography
                    component={"a"}
                    target="_blank"
                    href="https://mycryptocommerce.com"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    کریپتوکامرس{" "}
                  </Typography>
                  مراجعه بفرمایید.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  هوش مصنوعی محدود (artificial narrow intelligence)
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  هوش مصنوعی محدود زودتر از انواع دیگر هوش مصنوعی پدیدار شد و
                  نمونه های بسیاری از این نوع هوش مصنوعی در دنیای امروز وجود
                  دارد. برای مثال، رایانه‌هایی که بازی های پیچیده‌ای مانند شطرنج
                  را انجام می دهند یا تصمیم گیری هوشمندانه در زمینه تجارت، تمامی
                  این ها نمونه هایی از هوش مصنوعی محدود می باشند.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  هر زمان حرف از AI محدود به میان می آید منظور سیستم‌های هوشمندی
                  است که در انجام دادن یک وظیفه (task) بهتر از انسان عمل
                  می‌کنند. برای نمونه سیستم هوشمندی که قادر است به صورت خودکار
                  گفتار را به نوشتار تبدیل کند یا سیستم‌های تشخیص چهره که قادر
                  هستند هویت یک فرد را در شلوغی و سیل عظیمی از جمعیت شناسایی
                  کنند از این نوع می باشند. در ادامه به برخی از کارایی های هوش
                  مصنوعی محدود اشاره می کنیم.
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  اتومبیل های خودکار که با کمک این فناوری آموزش می بینند که
                  چگونه باید رانندگی کنند.
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  سیستم‌های پردازش تصویر و تشخیص چهره که قادرند هویت افراد را
                  تشخیص دهند.
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  سیستم‌های AI که در انجام فرآیندهای مالی در بانک‌ها و سایر کسب
                  و کارهای مالی نقش مهمی ایفا می کنند.
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  دستیارهای هوشمند که بر اساس نیازها افراد یاری می رسانند مثلا
                  قادر هستند که بلیط پرواز و هتل را از قبل رزرو نمایند.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  هوش مصنوعی عمومی (Artificial General Intelligence)
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  در تعریف هوش مصنوعی عمومی می توان گفت که ماشینی است که دنیای
                  اطراف خود را شبیه یک انسان درک می کند و دارای ظرفیت و گنجایش
                  مشابه برای انجام فعالیت‌ها و وظایفی است که یک انسان معمولا
                  آن‌ها را انجام می‌دهد. در دنیای امروزی هوش مصنوعی عمومی وجود
                  ندارد اما به عنوان نشانی از آن می توان به داستان های
                  عملی-تخیلی اشاره کرد. از نظر تئوری هوش مصنوعی عمومی قادر است
                  که هم سطح انسان به فعالیت بپردازد و حتی بهتر از انسان نیز وارد
                  عمل شود.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  بدین ترتیب یک ماشین با این سطح از آگاهی و دانش قادر خواهد بود
                  که تمام فعالیت هایی که بر انسان محول می‌شود را بدون نیاز به
                  وجود انسان به انجام رساند. بنابراین در طی زمان اکثر ماشین‌های
                  دارای هوش مصنوعی عمومی توانایی این امر را دارند که در بسیاری
                  از امور به جای انسان وارد عمل شوند. با بکارگیری هوش مصنوعی
                  عمومی که دارای توانایی‌ها و ظرفیت‌های زیادی برای کمک به انسان
                  است بسیاری از مشکلات امروزی حل می شود.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  سیستم‌های هوش مصنوعی عمومی می‌توانند کارهای عادی تا کارهای
                  بسیار مهم را به بهترین نحو ممکن انجام دهند. به عنوان مثال
                  قادرند کارهایی مثل رانندگی، دستیار شخصی هوشمند با توانایی درک
                  همه‌ی نیازهای کاربر، دستیار پزشک و یا سیستم تشخیص بیماری را
                  انجام دهند. در سطوح بالاتر این سیستم‌ها می‌توانند کارهای
                  امنیتی را به خوبی ساماندهی کنند.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  سوپر هوش مصنوعی (Artificial Super Intelligence)
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  سوپر هوش مصنوعی در واقع سطح هوش و درک انسانی را پشت سر نهاده و
                  دارای هوش فرا بشری می باشد. تا کنون هیچ جامعه‌ای نتوانسته است
                  که به این سطح از هوش مصنوعی دست پیدا کند. حتی زمان رسیدن به آن
                  نیز در هاله ای ابهام باقی مانده است. هم چنین این مسئله که
                  کارایی این تکنولوژی چیست و آیا تهدیدی برای بشر است یا فرصتی
                  برای او، همچنان مبهم است. برای رسیدن به این سطح از AI، یک
                  سیستم هوشمند باید تست تورینگ را پشت سر بگذارد و هیچ ماشینی تا
                  به امروز به این سطح از درک و شعور دانش یک انسان بالغ نرسیده
                  است.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  دسته بندی سیستم‌های هوش مصنوعی
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={photo9}
                      alt="دسته بندی هوش مصنوعی - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      دسته بندی هوش مصنوعی
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  آرنت هینتز، استادیار دانشگاه ایالتی میشیگان، برای هوش مصنوعی
                  چهار دسته بندی کلی در نظر گرفته است. این دسته بندی ها شامل
                  سیستم هایی که امروزه وجود دارند تا سیستم های احساسی که هنوز
                  وجود ندارند می باشد.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  نوع اول: ماشین های انفعالی
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  به عنوان نمونه ای از این دسته بندی می توان deep blue را نام
                  برد که یک برنامه شطرنج بود که در دهه 1990 در مسابقه شطرنج، گری
                  کاسپاروف، قهرمان شطرنج جهان را شکست داد. deep blue مهره های
                  روی هر خانه شطرنج را شناسایی می کرد و حرکت های پیش رو را پیش
                  بینی می نمود. اما مشکلی که این برنامه داشت قادر نبود که تجربه
                  های قبلی خود را به خاطر بسپارد تا از آن برای حرکت های آینده
                  استفاده نماید. این برنامه هربار تمام حرکت های استراتژیک خود و
                  رقیب را بررسی و آنالیز می کرد و بهترین آن ها را برمی گزید. این
                  نوع از هوش مصنوعی تنها برای هدف های محدودی قابل استفاده اند و
                  در همه موقعیت های کاربرد ندارند.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  نوع دوم: حافظه محدود
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  این سیستم هوش مصنوعی بر خلاف سیستم قبل قادر است که از تجربیات
                  گذشته خود برای تصمیمات آینده بهره گیرد. به عنوان مثال برخی از
                  کارکرد های تصمیم گیری ماشین های خودکار در این دسته قرار می
                  گیرند. این نوع ماشین ها از مشاهدات خود برای تصمیمات آینده
                  استفاده می کنند. البته این نوع از مشاهدات و تجربیات دائمی
                  نیستند.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  نوع سوم: تئوری ذهن
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  این نوع از هوش مصنوعی به اعتقادات و دانش ها، آرزوها و آمال و
                  نیت هر فرد مرتبط می شود و تاثیری که هر کدام از آن ها بر تصمیم
                  گیری شخص می گذارد. اما این نوع از AI هنوز وجود ندارد.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  نوع چهارم: خود آگاهی
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  در این دسته از هوش مصنوعی، سیستم AI آگاهی از خود و هوشیاری
                  وجود دارد. ماشین های دارای خود آگاهی قادرند بفهمند که در چه
                  سطح و حالتی قرار دارند. همچنین می توانند از اطلاعاتی که حاصل
                  می شود احساسات دیگران را پیش بینی کنند. البته لازم به توضیح
                  است که این نوع از هوش مصنوعی نیز مانند دسته سوم هنوز وجود
                  ندارد.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  کلام آخر
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  با اینکه مدت زیادی از پیدایش هوش مصنوعی نمی گذرد اما بحث در
                  رابطه با این علم بسیار زیاد است که گنجاندن تمامی این مطالب در
                  این مقاله از حوصله خارج می باشد. بنابراین در مقالات بعدی سعی
                  می کنیم تا اطلاعات جامع تری از این علم نوظهور در اختیارتان
                  قرار دهیم.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  برای سفارش طراحی سایت هوش مصنوعی در وب سایت{" "}
                  <NavLink
                    to="/"
                    target="_blank"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    سورین{" "}
                  </NavLink>
                  ثبت نام و درخواست خود را ثبت کنید. کارشناسان به زودی با شما
                  تماس خواهند گرفت.
                </Typography>
                {/* do not change */}

                {author(articleData.author, t)}
                {tags(articleData.tags, t)}
                {linkArticle(t, url)}
                {sourceLink(articleData.source, t)}
                {view(dataview, t)}
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={7.5}
              md={8}
              lg={8.5}
              xl={9}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: { xs: "80%", sm: "60%", md: "70%" },
              }}
            >
              <Loader />
            </Grid>
          )}
        </Grid>
      </Grid>
      <PortfolioButton />
      <Footer />
    </>
  );
};
export default Article15;
