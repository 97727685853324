import { SvgIcon } from '@mui/material';
import React from 'react';
const ContactIcon = ({ color }) => {
  return (
    <SvgIcon component="object" sx={{ height: "30px" }}>
      <svg height="20" width="20" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 19.7795C21 20.1935 20.9199 20.619 20.7496 21.033C20.5794 21.447 20.359 21.838 20.0686 22.206C19.5779 22.827 19.0371 23.2755 18.4261 23.563C17.8252 23.8505 17.1743 24 16.4732 24C15.4517 24 14.36 23.724 13.2083 23.1605C12.0566 22.597 10.9049 21.838 9.76314 20.8835C8.61142 19.9175 7.51978 18.848 6.47822 17.6635C5.44667 16.4675 4.51527 15.214 3.68403 13.903C2.86279 12.592 2.2018 11.281 1.72108 9.9815C1.24036 8.6705 1 7.417 1 6.221C1 5.439 1.12018 4.6915 1.36054 4.0015C1.6009 3.3 1.98147 2.656 2.51227 2.081C3.15323 1.3565 3.85428 1 4.59539 1C4.87581 1 5.15623 1.069 5.40661 1.207C5.667 1.345 5.89735 1.552 6.07762 1.851L8.4011 5.6115C8.58137 5.899 8.71157 6.1635 8.8017 6.4165C8.89184 6.658 8.94191 6.8995 8.94191 7.118C8.94191 7.394 8.87181 7.67 8.7316 7.9345C8.6014 8.199 8.41112 8.475 8.17076 8.751L7.40961 9.6595C7.29945 9.786 7.24937 9.9355 7.24937 10.1195C7.24937 10.2115 7.25939 10.292 7.27942 10.384C7.30946 10.476 7.33951 10.545 7.35954 10.614C7.53981 10.9935 7.85028 11.488 8.29094 12.086C8.74161 12.684 9.22233 13.2935 9.74311 13.903C10.2839 14.5125 10.8047 15.076 11.3355 15.5935C11.8563 16.0995 12.2869 16.4445 12.6274 16.6515C12.6775 16.6745 12.7376 16.709 12.8077 16.7435C12.8878 16.778 12.968 16.7895 13.0581 16.7895C13.2283 16.7895 13.3585 16.7205 13.4687 16.594L14.2298 15.7315C14.4802 15.444 14.7206 15.2255 14.9509 15.0875C15.1813 14.9265 15.4116 14.846 15.662 14.846C15.8523 14.846 16.0526 14.892 16.2729 14.9955C16.4932 15.099 16.7236 15.2485 16.974 15.444L20.2889 18.1465C20.5493 18.3535 20.7296 18.595 20.8398 18.8825C20.9399 19.17 21 19.4575 21 19.7795Z" stroke={color}/>
      </svg>
    </SvgIcon>
  );
};
export default ContactIcon;