import { SvgIcon } from '@mui/material';
import React from 'react';
const OrderProgrammerIcon = ({ color }) => {
  return (
    <SvgIcon component="object">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="transparent" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.1275 14.25L13.185 16.1925L14.25 17.25L17.25 14.25L14.25 11.25L13.185 12.3075L15.1275 14.25ZM8.8725 14.25L10.815 12.3075L9.75 11.25L6.75 14.25L9.75 17.25L10.815 16.1925L8.8725 14.25Z" fill={color} />
        <path d="M6.75 6.75C7.16421 6.75 7.5 6.41421 7.5 6C7.5 5.58579 7.16421 5.25 6.75 5.25C6.33579 5.25 6 5.58579 6 6C6 6.41421 6.33579 6.75 6.75 6.75Z" fill={color} />
        <path d="M4.5 6.75C4.91421 6.75 5.25 6.41421 5.25 6C5.25 5.58579 4.91421 5.25 4.5 5.25C4.08579 5.25 3.75 5.58579 3.75 6C3.75 6.41421 4.08579 6.75 4.5 6.75Z" fill={color} />
        <path d="M21 3H3C2.17275 3 1.5 3.67275 1.5 4.5V19.5C1.5 20.3273 2.17275 21 3 21H21C21.8273 21 22.5 20.3273 22.5 19.5V4.5C22.5 3.67275 21.8273 3 21 3ZM21 4.5V7.5H3V4.5H21ZM3 19.5V9H21V19.5H3Z" fill={color} />
      </svg>
    </SvgIcon>
  );
};
export default OrderProgrammerIcon;