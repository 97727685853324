import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import { author, date, h1, headPhoto, largePhoto, linkArticle, rootArticle, tags, paragraph, timeRead, view, listItemCircle, h2, h4, smallPhoto, sourceLink } from "../articleFunction/ArticleFunction";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { Articles } from "./Articles";
import ArticleSide from "../articleFunction/ArticleSide";

//import photo
import photo1 from '../../image/articles/01/photo1.jpg';
import photo2 from '../../image/articles/01/photo2.jpg';
import photo3 from '../../image/articles/01/photo3.jpg';
import photo4 from '../../image/articles/01/photo4.jpg';
import photo5 from '../../image/articles/01/photo5.jpg';
import Loader from "../Loader";
import PortfolioButton from "../PortfolioButton";


let articleData = null;
const Article01 = () => {

  //NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${articleData !== null && articleData.title}`
    document.getElementById("main-heading").innerHTML = articleData !== null && articleData.h1;
    document.getElementsByTagName('meta')["description"].content = articleData !== null && articleData.desc;
    document.getElementsByTagName('meta')["keywords"].content = articleData !== null && articleData.keywords;
    document.getElementsByTagName('meta')["author"].content = articleData !== null && articleData.author;
    setCanonicalTag()
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item
    })
  }, [])

  useEffect(() => {
    articleData !== null && addView(); getView();
  }, [])

  const addView = () => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v1/article/addView",
      method: "POST",
      data: { id: articleData.id }
    }))
      .then((res) => { })
  }
  const getView = () => {
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/article/getView`,
      method: "GET",
      params: { id: articleData.id }
    }))
      .then((res) => {
        setDataView(res.data.data)
      })
  }

  //change This part

  //read More
  const readMore = [
    {
      id: 1,
      title: "سئو و تمام نکاتی که باید در آن رعایت کرد",
      link: "/what-is-seo"
    }
  ]
  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: "rtl", alignItems: "center" }}>
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid container sx={{ display: "flex", alignItems: { xs: "unset", sm: 'flex-start' }, justifyContent: "space-between", flexDirection: "row", flexWrap: "wrap", width: { xs: "100%", sm: "85%" } }}>
          <ArticleSide tags={articleData !== null && articleData.tags} readMore={readMore} />
          {
            articleData !== null ?
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: 'center', sm: "flex-end" }, flexDirection: "column", width: { xs: "100%", sm: "60%", md: "70%" } }} >
                {/* do not change */}

                {headPhoto(articleData.image)}
                {h1(articleData.h1, t)}
                {timeRead(articleData.timeRead, t)}
                {date(articleData.date, i18n)}

                <Grid sx={{ display: 'flex', alignItems: { xs: "center", sm: 'flex-start' }, justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "column" }}>
                  {/* The text of the article */}

                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}
                  >با توجه به اینکه بسیاری از کسب و کارها امروزه آنلاین شدند و اکثر افراد کارها و خریدهای خود را به صورت اینترنتی انجام می دهند، طراحی سایت اهمیت بالایی پیدا کرده است. چنانچه قصد دارید بازار اینترنتی راه اندازی کنید، طراحی سایت اولین قدمی است که باید به آن توجه کنید.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بنابراین اگر شما یک وب سایت تخصصی برای کسب و کار خود طراحی کنید، می توانید محصولات و خدمات خود را به تمام نقاط کشور به صورت آنلاین ارائه کنید. شما با داشتن سایت می توانید کسب و کار خود را به تمام کاربران معرفی کنید و برای خود مشتریان دائمی پیدا کنید. هر چقدر سایت شما کاربرپسند باشد، مخاطبین وقت بیشتری را در آن صرف می کنند و احتمال بیشتری دارد که از سایت شما خرید کنند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    مقدمه:
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" }, background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo1} alt="طراحی سایت - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>طراحی سایت</Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    با پیشرفت تکنولوژی و تغییر نحوه بازاریابی، فضای مجازی بیش از قبل مورد توجه قرار گرفت. بازاریابی نوین، تجار و فروشندگان را به ارائه کالا و خدمات در فضای مجازی و اینترنت تشویق می کند. شما قادر خواهید بود با یک طراحی سایت مناسب  خدمات و محصولات خود را به مشتریان عرضه کنید. با سایت مناسب شما می توانید به موتورهای جستجو معرفی شوید و بهترین تبلیغ را داشته باشید. قبل از طراحی وب سایت نیاز است یکسری کارها را  انجام دهید تا موجب کارآمدتر شدن وب سایت شما شود. در این مقاله از <Typography component={"a"} target="_blank" href="https://sorinapp.com/" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >سورین</Typography> به بررسی این موارد می پردازیم.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    1- تحقیق برای سفارش سایت
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای شروع کار باید هدف از ایجاد سایت و کاربرد آن را مشخص کرد، سپس تصمیم به طراحی وب سایت گرفته شود.  خیلی از افراد تصور می کنند که همه چیز در دست طراح سایت است. یعنی مشتری اطلاعات سایت را به برنامه نویس می دهد و طراح در کمترین زمان سایت را تحویل می هد! اگر این اتفاق بیفتد در اکثر مواقع سایت نهایی آن چیزی نیست که شما توقع داشتید. برای جلوگیری از این احتمال بهتر است برای تمام آنچه که در ذهنتان است یک نقشه اماده کنید، سپس با طراحان مشورت کنید و اطلاعات مورد نظرتان را بطور کامل در اختیارشان قرار دهید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    2- نوع وب سایت
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای سفارش طراحی سایت شما در ابتدا باید مشخص کنید که به چه وب سایتی برای کسب و کار خود نیاز دارید. همانطور که می دانید سایت ها انواع مختلفی دارند. سایت شرکتی، سایت فروشگاهی، سایت خبری و ... که هر کدام فضای هاست مخصوص به خود را دارند که با توجه به فضای هاستی که اشغال می کنند قیمت آن نیز محاسبه می شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    3- انتخاب دامنه و هاست
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای انتخاب نام دامنه باید توجه کافی داشت زیرا نام دامنه هم برای موتورهای جستجو اهمیت دارد و هم صدای هویت برند شماست. سعی کنید از دامنه رایگان استفاده نکنید. باید به این نکته توجه داشت که برای اعتمادسازی کاربران سایت باید از دامنه ای استفاده کنید که متعلق به خود شماست. این موضوع به بازدیدکنندگان این باور را می دهد که سایت شما دارای برنامه و پشتوانه می باشد.  همچنین سب می شود که آدرس اینترنتی شما به راحتی در ذهن کاربران بماند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    جدا از دامنه انتخاب هاست هم از نکات مهم می باشد. کسب و کارهای آنلاینی که سرعت لود صفحات بالاتری دارند مشتریان بیشتری را جذب می کنند. برای انتخاب هاست، بررسی ماهیت وب سایت و کسب و کار بسیار کلیدی می باشد. آیا وب سایت دارای تعداد زیادی رسانه یا عکس و ویدئو است؟ میزان ترافیک سایت چه میزان است؟ با توجه به این نکات باید تحقیق کرد که شرکت میزبان توانایی این حجم از اطلاعات را دارد. نکته مهم بعدی درمورد خرید هاست امنیت آن می باشد که باید حتما لحاظ شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    4- مدیریت محتوا
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    با توجه به اهدافی که برای سایت خود در نظر دارید می توانید یک سیستم مدیریت محتوای آماده یا کدنویسی را انتخاب کنید. این موضوع به شما و سطح نیازهایتان از یک سایت، بستگی دارد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    5- انتخاب گرافیک و رنگ مناسب
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" }, background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo2} alt="طراحی سایت و انتخاب رنگ - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>طراحی سایت و انتخاب رنگ</Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    یکی از امتیازات مهم در طراحی سایت انتخاب گرافیک و رنگ مناسب می باشد. با توجه به اینکه برنامه نویسی و کد نویسی سایت از نکات اساسی طراحی سایت می باشد ولی گرافیک و رنگ سایت نیز میزان خوب یا بد بودن سایت شما را مشخص می کند. گرافیک سایت، نحوه چیدمان رنگ ها، عناصر، نمایش تصاویر، نوع و سایز قلم را شامل می شود. رنگ ها سبب برانگیختن واکنش های متفاوت کاربران مانند آرامش، شادی و یا ناامیدی می شوند. این موضوع می تواند در انتخاب گزینه خرید محصول توسط کاربر موثر باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    6- برند سازی و لوگو
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" }, background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo3} alt="طراحی سایت و برندسازی - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>طراحی سایت و برندسازی</Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برندسازی برای تمامی کسب و کارهای آنلاین حائز اهمیت است.  طراحی لوگوی مناسب برند شما باعث جلب توجه مشتری می شود. طراحی لوگو تصویر واضحی از صدای منحصر به فرد برند را ارائه می دهد. برای قرار دادن لوگو مکان مناسبی را در سایت در نظر بگیرید که برای بازدیدکنندگان قابل مشاهده باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    7- شناسایی نیازهای مشتری
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" }, background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo4} alt="طراحی سایت و نیاز مشتری - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>طراحی سایت و نیاز مشتری</Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    تحقیق در مورد اینکه کاربران شما چه کسانی هستند و یا از کدام کشور هستند و محصولات شما مناسب کدام قشر از افراد جامعه می باشد، از نکات اساسی سایت می باشد. بنابراین در ابتدا برای سفارش طراحی سایت، مخاطبین هدف خود را شناسایی کنید. پس از مشخص شدن مخاطبین سایت باید امکانات سایت را متناسب با نیازهای مشتری در سایت قرار دهید و ظاهر سایت را متناسب با سلیقه کاربران طراحی کنید. رعایت  این موارد سبب تاثیرگذاری بیشتر و افزایش درامد سایت خواهد شد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    8- بررسی امکانات سایت
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    قبل از سفارش سایت باید بررسی کنید که با توجه به اهداف و نیازهای شما و کاربران چه امکاناتی برای سایت مورد نیاز است. پس از بررسی بهتر است این امکانات را با طراح سایت در میان بگذارید. گاهی امکان دارد یک ویژگی بدون استفاده در سایت هزینه زیادی را برای شما به ارمغان اورد. همچنین گاهی نیز عدم توجه به یک قابلیت ویژه سبب از دست دادن مشتریان زیادی شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    9- بودجه
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بهتر است یک بودجه اولیه برای سفارش طراحی سایت خود در نظر بگیرید. سپس، با یک طراح سایت مشورت کنید. اگر بودجه کمی دارید، نمی توانید یک سایت حرفه ای با امکانات تخصصی داشته باشید. در این صورت برای شروع کار با توجه به بودجه محدود، شما می توانید از  قالب های آماده استفاده کنید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    10- انتخاب طراح و قرارداد
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای انتخاب طراح بهتر است در ابتدا رزومه کاری چند نفر را مورد بررسی قرار دهید. بعد از انتخاب فرد مناسب، قرارداد سفارش طراحی سایت را آماده نمایید. این قسمت را می توان مهمترین قسمت سفارش طراحی سایت دانست. بهتر است تمام جزئیات مربوط به سایت را وارد کنید. البته گاهی اوقات برخی از نکات در طول پروژه تغییر می کنند؛ بنابراین در عقد قرارداد خیلی حساسیت به خرج ندهید. حساسیت زیاد در مفاد قرارداد طولانی شدن بیش از حد پروسه را بهمراه دارد و  در کیفیت پروژه نهایی اثربخش نمی باشد.
                  </Typography>
                  <Typography component={"h4"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "18px", md: "24px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    از دیگر مواردی که در طراحی سایت می توان آن ها را مدنظر قرار داد موارد زیر می باشد:
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    عکس خود و تیم کاریتان را در سایت قرار دهید:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    کاربران زمانی که وارد یک سایت جدید کسب و کار می شوند قبل از هر چیز بررسی می کنند که آیا واقعا صاحب سایت وجود خارجی دارد؟ بنابراین بهتر است که تصویری از خود و تیم کاریتان را در سایت قرار دهید و مختصری در مورد تخصص هر کدام از آنها بنویسید تا بدین ترتیب اعتماد بازدیدکنندگان و مشتریان سایت را جلب نمایید.
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    نام برند خود را به عنوان نام دامنه قرار دهید:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    با این کار می توانید موضوع برند سازی را بهتر انجام دهید. کافی است نگاهی به سایت معروف <Typography component={"a"} target="_blank" href="https://www.digikala.com/" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >
                      دیجی کالا </Typography>  بیندازید.
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    فرمی ساده برای صفحه تماس با ما داشته باشید:
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" }, background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo5} alt="" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>طراحی سایت و صفحه تماس با ما</Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بهتر است فرمی ساده برای بخش تماس با ما در نظر داشته باشید تا کاربران بتوانند مشکلات و ابهاماتی که در سایت با آن روبرو می شوند را با شما در میان بگذارند. بهتر است راه های ارتباطی متفاوتی نیز داشته باشید تا بازدیدکنندگان بتوانند براحتی با شما در تماس باشند.
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    طراحی خبرنامه را به خاطر داشته باشید:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای اینکه کاربران و مخاطبان شما از آخرین محصولات، سرویس ها، اخبار و مقالات اگاهی داشته باشند بهتر است صفحه ای را برای خبرنامه در نظر داشته باشید.
                  </Typography>
                  <Typography component={"h4"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "18px", md: "24px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    نکات دیگری در طراحی سایت حائز اهمیت است که در ادامه به  آن می پردازیم:
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    فونت خوانا استفاده کنید:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای اینکه کاربر بتواند براحتی مطالب داخل سایت را بخواند باید فونت مورد استفاده در سایت دارای سایز مناسب  باشد و همچنین خوانا باشد.
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    از تصاویر با کیفیت استفاده کنید:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زمانی که هدف شما از طراحی سایت فروش محصولات و ارائه خدمات می باشد بهتر است از تصاویر واضح و با کیفیت استفاده کنید تا مخاطبین بتوانند براحتی جزئیات خدمات سایت را مورد بررسی قرار دهند.
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    بهینه سازی را در الویت قرار دهید:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای اینکه ترافیک سایت شما بالا برود و کاربران زیادی وارد سایت شما شوند بهتر است اصول بهینه سازی را در سایت خود پیاده سازی کنید. یکی از مهمترین مسائل در سئو سایت، رسپانسیو بودن سایت شما برای گوشی همراه می باشد. از آنجا که امروزه اکثر مخاطبین با استفاده از موبایل های خود وب گردی می کنند پس به این موضوع باید توجه ویژه و خاصی داشت
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    نتیجه گیری
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    مطالبی که در این مقاله بیان شد از مهمتری نکات طراحی سایت بشمار می آیند. بکارگیری این موارد با توجه به هدف شما از طراحی سایت بررسی می شود. چنانچه تنها قصد شما معرفی محصولات و خدمات می باشد بهتر است از  طراحی بصری استفاده کنید ولی اگر برای شما کسب رتبه در نتایج برتر گوگل در الویت است، تولید محتوا، کدها و دیگر موارد را نیز مورد توجه قرار دهید.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    شما در وب گستران سورین با بهره گیری از مشاوره ی بهترین متخصصان و طراحان سایت می توانید بهترین گزینه را انتخاب کنید.
                  </Typography>

                  {/* do not change */}

                  {author(articleData.author, t)}
                  {tags(articleData.tags, t)}
                  {linkArticle(t, url)}
                  {sourceLink(articleData.source, t)}
                  {view(dataview, t)}
                </Grid>
              </Grid>
              :
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "80%", sm: "60%", md: "70%" } }}>
                <Loader />
              </Grid>
          }
        </Grid>
      </Grid>
      <PortfolioButton />
      <Footer />
    </>
  );
}
export default Article01;