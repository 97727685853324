import { Button, Grid, Typography, CardMedia } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import arrow from '../image/sample/arrow.png';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { NavLink, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { dataDesign, dataAmozeshi, dataFood, dataPezeshki, dataTravel } from "./DataSample";
import Footer from "./Footer";
import { setCanonicalTag } from "./modules/general";

const SamplePage = () => {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(null);
  const [pauseVideo, setPauseVideo] = useState(false);
  const [openTempeleteOne, setOpenTempeleteOne] = useState(false)
  const [openTempeleteTow, setOpenTempeleteTwo] = useState(false)
  const [openTempeleteTree, setOpenTempeleteTree] = useState(false)
  const params = useParams();

  useEffect(() => {
    let type = params.value;
    let title = "";
    let description = ""
    let keywords = ""
    switch (type) {
      case ("Designing"):
        title = "وب سایت معماری و ساختمان شرکت سورین | طراحی سایت معماری و طراحی داخلی وب گستران سورین"
        description = "قالب Designing  شرکت وب گستران سورین مناسب برای وب سایت های صنعت ساختمان از جمله طراحی داخلی ساختمان، طراحی نما، بازسازی خانه، مشارکت در ساخت و ... بسیار مناسب است"
        keywords = "نمونه پروژه های سورین | بهترین قالب سایت | نمونه وب سایت معماری | نمونه وب سایت فروشگاهی | نمونه وب سایت شرکتی | نمونه وب سایت پزشکی | نمونه وب سایت رستوران | نمونه وب سایت بازی | نمونه وب سایت خبری"
        break;
      case ("Food"):
        title = "وب سایت کافه و غذا شرکت سورین | طراحی وب سایت رستوران |"
        description = "قالب Food شرکت وب گستران سورین مناسب برای مارکت پلیس های رستورانی مانند اسنپ فود می باشد. قابلیت شخصی سازی قالب برای رستوران ها، کافی شاپ ها و ابمیوه فروشی ها وجود دارد"
        keywords = "وب سایت آنلاین سفارش غذا | سایت خرید غذا از رستوران ها | سایت سفارش کافه از کافی شاپ |"
        break;
      case ("Travel"):
        title = "وب سایت مسافرتی | وب سایت گردشگری | وب سایت معرفی مکان های دیدنی | وب سایت رزور بلیط و هتل "
        description = "قالب Travel  وب گستران سورین برای آژانس های مسافرتی و شرکت های ارایه کننده مشاوره و راهنمایی مسافران داخل و خارج از کشورمناسب است. همچنین پلتفرم مقایسه نرخ بلیط و هتل هم میتواند از قالب فوق استفاده کند."
        keywords = "وب سایت آژانس های مسافرتی | پلتفرم اجاره ویلا | سایت خرید بلیط هواپیما | سایت گردش و سفر | وب سایت تور مسافرتی | سایت مشاوره به مسافران"
        break;
      case ("Educational"):
        title = "وب سایت آموزش آنلاین | وب سایت آموزشگاهی | سایت آموزش مجازی"
        description = "قالب Educational  شرکت وب گستران سورین برای آموزشگاه ها و موسسات آموزشی نطیر فرادرس بسیار مناسب است. هم برای دانشگاه ها و هم مدارس آموزش مجازی قابل استفاده می باشد"
        keywords = "وب سایت آموزش از راه دور | وب سایت دانشگاهی | سایت آموزشی |وب سایت دوره های آنلاین | وب سایت یادگیری | سایت آموزش آنلاین درسی"
        break;
      case ("Medical"):
        title = "وب سایت پزشکی | وب سایت نوبت دهی اینترنتی | وب سایت تندرستی و سلامت | وب سایت تخصصی سلامت | وب سایت زیبایی | وب سایت دندانپزشکی |"
        description = "قالب Medical شرکت وب گستران سورین مناسب فعالیت های مربوط به سلامت می باشد. سایت نوبت دهی، سایت معرفی پزشکان، سایت بیمارستانی و هرگونه خدمات آنلاین پزشکی که قابل ارایه در محیط اینترنت را داراست"
        keywords = "وب سایت زیبایی | سایت تخخصصی پزشکی |سایت خدمات آنلاین پزشکی | سایت آنلاین خدمات سلامتی | سایت بیمارستان و مرکز درمانی | سایت داروخانه | سایت آزمایشکاه | وب سایت ژورنال پزشکی |سایت تجهیزات پزشکی"
        break;
      case ("Technology"):
        title = "وب سایت تکنولوژی | سایت فناوری نوین | وب سایت آی تی |وب سایت فناوری اطلاعات | سایت واقعیت مجازی | سایت بر بستر بلاکچین | سایت تکنولوژی نرم افزار | سایت تکنولوژی سخت افزار  "
        description = "قالب Technology شرکت وب گستران سورین مناسب کسب و کار های فناوری اطلاعات و تکنولوژی می باشد. وب سایت هایی که خدماتی نظیر اخبار حوزه تکنولوژی، معرفی سخت افزار و دستگاههای دیجیتال، هوش مصنوعی، فناوری بلاکچین و هر خدمت و محصولی که در حوزه تکنولوژی جا گیرد، میتوانند از این قالب استفاده نمایند."
        keywords = "وب سایت خدمات تکنولوژی | بهترین سایت حوزه تکنولوژی | سایت هوش مصنوعی | سایت خدمات "
        break;
      case ("Financial"):
        title = "سایت مالی و حسابداری | نرم افزار حسابداری | سایت تخصصی مالی | وب گستران سورین "
        description = "قالب Financial  شرکت وب گستران سورین مناسب برای انواع سایتهای حوزه مالی و حسابداری می باشد. استارآپ های فین تک و وب سایتهای که تحلیل و یا خدمات مالی ارایه می دهند، میتوانند از این قالب استفاده کنند."
        keywords = "وب سایت تحلیل تکنیکال | سایت های بورسی | سایت ارز دیجیتال | صرافی رمزارز | سایت کیف پول آنلاین | سایت مشابه تردینگ ویو | سایت بازار سرمایه"
        break;
      case ("PicVideoMusic"):
        title = "سایت تماشا و دانلود فیلم | سایت اشتراک گذاری فیلم و عکس | سایت دانلود و پخش موزیک | وب گستران سورین"
        description = "قالب picVideoMusic  شرکت وب گستران سورین برای انواع وب سایت فعال در زمینه دانلود و پخش موزیک، فیلم، سریال، انیمیشن و مناسب است . هم چنین سایت های فعال در زمینه طراحی و ادیت عکس و هم چنین اشتراک گذاری فایل صوتی و تصویری می توانند از این قالب استفاده کنند. "
        keywords = "سایت دانلود عکس | سایت دانلود و تماشای آنلاین فیلم و سریال |  سایت | سایت ادیت عکس و فیلم | سایت موزیک ساز | سایت دانلود و خرید موسیقی | سایت دانلود آهنگ |"
        break;
      case ("BeautyAndHealth"):
        title = "سایت سلامت و تندرستی | سایت زیبایی و پوست | سایت بهداشت عمومی | وب گستران سورین"
        description = "قالب BeautyAndHealth  شرکت وب گستران سورین برای وب سایت های فروشگاهی که وسایل بهداشتی بفروش می رسانند مناسب است. هم چنین خدمات زیبایی نظیر کلینیک و آرایشگاه ها هم میتوانند از این قالب استفاده کنند"
        keywords = "وب سایت آرایشی و بهداشتی | وب سایت سلامت پوست | وب سایت سالن زیبایی | سایت آرایشگاه زنانه"
        break;
      case ("Business"):
        title = "سایت کسب و کار | سایت استارت آپ | وب سایت مشاغل خانگی | وب گستران سورین"
        description = "قالب Business شرکت وب گستران سورین مناسب برای کسب و کار های تازه تاسیس و کسب و کارهای کوچک یا خانگی مناسب است. هم چنین برای معرفی خدمات یا شرکت شما این قالب قابل به بهینه سازی برای انواع شرکت می باشد."
        keywords = "وب سایت کسب و کارهای کوچک | وب سایت شرکتی | سایت معرفی شرکت | سایت"
        break;
      case ("Marketing"):
        title = "وب سایت فروشگاهی | فروشگاه اینترنتی | وب سایت آنلاین فروشگاهی | سایت فروش اینترنتی آنلاین"
        description = "قالب فروشگاهی Marketing  وب گستران سورین برای انواع فروشگاههای اینترنتی مناسب است. کسب و کارهای که در زمینه فروش هر نوع کالا فعالیت دارند، میتوانند از قالب esor  استفاده کنند. هم چنین این قالب برای انواع مارکت پلیس و فروشگاههای آنلاین با هر حجم محصولاتی قابل استفاده می باشد."
        keywords = "سایت خرید اینترنتی | سایت درگاه پرداخت | سایت فروش آنلاین | پلتفرم خرید و فروش اینترنتی | سایت مارکت پلیس فروشگاهی | "
        break;
      case ("ECommerce"):
        title = "سایت بازاریابی اینترنتی | سایت تبلیغات آنلاین |  پلتفرم تبلیغات اینترنتی | وب گستران سورین "
        description = "قالب ECommerce شرکت وب گستران سورین برای وب سایت هایی که به عنوان نمایش دهنده تبلیغات محسوب می شوند و کسب و کار های بازاریابی که درخواست پلتفرم با قابلیت های تبلیغات کلیکی، تبلیغات بنری، تبلیغات ریتارگتینگ، رپرتاژ آگهی و ...دارند، می توئانند استفاده کنند."
        keywords = "سامانه تبلیغات اینترنتی | سایت تبلیغات کلیکی | وب سایت بازاریابی آنلاین | سایت رپرتاژ آگهی | سایت دیجیتال مارکتینگ | سایت درج تبلیغات اینترنتی | سایت تبلیغات هوشمند"
        break;
      case ("Entertainmentgame"):
        title = "سایت تخصصی بازی | سایت بازی آنلاین | وب سایت سرگرمی اینترنتی | وب گستران سورین"
        description = "قالب Entertainmentgame شرکت وب گستران سورین برای کسب و کارهایی که در حوزه بازی و سرگرمی فعالیت می کنند بسیار کاراست. هم چنین این قالب برای پیش بینی و سایت های کازینو قابل پیاده سازیست."
        keywords = "سایت بازی کوکان | سایت بازی بزرگسالان | سایت سرگرمی و شادی | سایت طنز و خنده | اپلیکیشن بازی موبایل | سایت بازی رایانه ای | سایت داستان و سرگرمی"
        break;
      case ("Blog"):
        title = "وب سایت شخصی | سایت مجله اینترنتی | وب سایت مجله آنلاین"
        description = "قالب Blog  شرکت وب گستران سورین برای کسب و کارهایی که در زمینه اخبار، مقالات، آموزش فعال هستند مناسب است. هم چنین از این قالب می توان به عنوان سایت یا یلاگ شخصی هم استفاده کرد. پلتفرم های انتشار محتوا می توانند با شخصی سازی قالب فوق به راحتی فعالیت داشته باشند."
        keywords = "سایت آنلاین اطلاعات | وب لاگ اینترنتی | سایت انتشار مقالات و اخبار و آموزش | سایت دانلود مقالات"
        break;
    }

    document.title = title
    document.getElementById("main-heading").innerHTML = title
    document.getElementsByTagName('meta')["description"].content = description;
    document.getElementsByTagName('meta')["keywords"].content = keywords
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, [params.value]);


  const play = () => {
    setPauseVideo(false);
  }

  const pause = () => {
    setPauseVideo(true);
  }
  

  useEffect(() => {
    setValue(params.value)
  }, [params.value])

  const openCaption = (id) => {
    id === 1 ? setOpenTempeleteOne(true) : id === 2 ? setOpenTempeleteTwo(true) : id === 3 && setOpenTempeleteTree(true)
  }

  const closeCaption = (id) => {
    id === 1 ? setOpenTempeleteOne(false) : id === 2 ? setOpenTempeleteTwo(false) : id === 3 && setOpenTempeleteTree(false)
  }
  const dataGrid = () => {
    let list = []
    let data = null;
    if (value !== null) {
      data = value === "Designing" ? dataDesign : value === "Food" ? dataFood : value === "Travel" ? dataTravel : value === "Educational" ? dataAmozeshi : value === "Medical" ? dataPezeshki : "";
    }
    data === "" ?
      list.push(
        <Typography key={"notFound"} sx={{ fontSize: { xs: "20px", sm: "25px", md: "30px" }, height: "50vh", padding: { xs: "50px", md: "100px" } }}>{t("notSample")}</Typography>
      )
      :
      value !== null && data !== null &&
      data.forEach((item) => {
        list.push(
          <Grid key={item.id} item xs={12} sm={12} md={5}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", margin: "20px 0", width: { xs: "90%", sm: "80%", md: "380px", lg: "500px" }, maxWidth: { xs: "90%", sm: "80%", md: "unset" } }}>
            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "flex-start", width: "100%", flexDirection: "row", margin: "10px 0" }}>
              <Typography sx={{ fontSize: { xs: "14px", sm: "20px" }, color: "#070707", fontWeight: "700" }}>{t(item.title)}</Typography>
            </Grid>
            <Grid
              onMouseEnter={() => openCaption(item.id)} onMouseLeave={() => closeCaption(item.id)}
              sx={{
                display: "flex", alignItems: "center", justifyContent: 'center', width: "100%", height: { xs: "238px", sm: "467px" }, position: "relative"
              }}>
              <CardMedia component='video' preload="metadata" src={item.video}
                sx={{ width: "100%", height: { xs: "238px", sm: "445px" } }}
                controls
                onPlay={play}
                onPause={pause}>
              </CardMedia>
              <Grid sx={{
                display: item.id === 1 ? openTempeleteOne ? "flex" : "none" : item.id === 2 ? openTempeleteTow ? "flex" : "none" : item.id === 3 && openTempeleteTree ? "flex" : "none",
                width: "100%", background: ' linear-gradient(180deg, rgba(40, 40, 40, 0.74) 34.38%, rgba(74, 69, 69, 0.76) 65.1%, rgba(182, 182, 182, 0.85) 100%)',
                margin: "10px 0", padding: "25px 10px", zIndex: "100", position: "absolute", bottom: "74px",justifyContent:"center",alignItems:"center",
              }}>
                <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "16px" }, color: "white", zIndex: "400",textAlign:"justify" }}>{item.hoverText}</Typography>
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', margin: "15px 0" }}>
              <NavLink to={`/Portfolio/${item.type}/${item.title}`} style={{ textDecoration: "none", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <Button sx={{
                  width: "50%", height: { xs: "35px", sm: "41px" }, background: "#FF3951", boxShadow: "0px 0px 8px #FF3951",
                  borderRadius: "8px", color: "#FFFFFF", fontSize: { xs: "10px", sm: "16px" }, "&:hover": { background: "#FF3951" }
                }}>
                  {t("Preview")}
                </Button>
              </NavLink>
            </Grid>
          </Grid>
        )
      })


    return list;
  }
  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: i18n.language === "fa" ? "rtl" : "ltr", alignItems: "center" }}>
        <Grid sx={{ display: "flex", width: "100%", backgroundColor: "#EDEDED", padding: "15px 0", justifyContent: "center" }}>
          <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: { xs: "95%", md: "86%" } }}>
            <NavLink className="navlink" to="/">
              <Typography component={"p"} sx={{ fontSize: "12px", color: "#757575", padding: "0 5px" }} >{t("mainPage")}</Typography>
            </NavLink>
            <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
            <NavLink className="navlink" to="/Portfolio">
              <Typography component={"p"} sx={{ fontSize: "12px", color: "#757575", padding: "0 5px" }}>{t("sampleWork")}</Typography>
            </NavLink>
            <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
            <Typography component={"p"} sx={{ fontSize: "12px", color: "#070707", padding: "0 5px" }}>{t("sites")} {t(value)}</Typography>
          </Grid>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "flex-end", justifyContent: 'flex-start', flexDirection: 'row', width: { xs: "95%", md: "86%" }, margin: "30px 0" }}>
          <Typography component={"img"} alt="arrow" src={arrow}
            sx={{
              width: { xs: "19px", sm: "29px" }, height: { xs: "24px", sm: "37px" }, filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))", padding: "0 3px"
              , transform: i18n.language === "en" && "rotate(180deg)"
            }} />
          <Typography sx={{ fontSize: { xs: "16px", sm: "20px", md: "24px" }, color: "#070707", fontWeight: "700" }}>{t("sites")} {t(value)}</Typography>
        </Grid>
        <Grid container sx={{ display: 'flex', alignItems: "center", justifyContent: "space-evenly", width: "100%" }}>
          {
            value !== null && dataGrid()
          }
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}

export default SamplePage;