import { Grid, Link, Typography } from "@mui/material"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { NavLink } from "react-router-dom";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import * as shamsi from 'shamsi-date-converter';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';


export const rootArticle = (value, t) => {
  let list = [];
  list.push(
    <Grid key={"root"} sx={{ display: "flex", width: "100%", backgroundColor: "#EDEDED", padding: "15px 0", justifyContent: "center" }}>
      <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: { xs: "95%", md: "86%" } }}>
        <NavLink className="navlink" to="/">
          <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#757575", padding: "0 5px" }} >{t("mainPage")}</Typography>
        </NavLink>
        <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
        <NavLink className="navlink" to="/Academy">
          <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#757575", padding: "0 5px" }}>{t("articles")}</Typography>
        </NavLink>
        <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
        <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#070707", padding: "0 5px" }}>{t(value)}</Typography>
      </Grid>
    </Grid>
  )
  return list;
}

export const headPhoto = (value) => {
  let list = [];
  list.push(
    <Grid key={"head"} sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', width:  {xs:"90%",sm:"100%",lg:"85%"}, zIndex: "-1" }}>
      <Grid sx={{
        backgroundSize: "100% 100%", backgroundImage: `url(${value})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", borderRadius: "16px",
        filter: "drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.3))", width: "100%", minHeight: { xs: "150px", sm: "190px", md: "300px", lg: "429px" }, height: "100%"
      }}></Grid>
    </Grid>
  )
  return list;
}

export const h1 = (value, t) => {
  let list = [];
  list.push(
    <Grid key={"h1"} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: "center", md: 'flex-start' }, width: { xs: "90%", sm: "100%" }, }}>
      <Typography sx={{ color: "#000000", fontSize: { xs: "17px", sm: "24px", md: "36px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: { xs: "center", md: "justify" } }}>{t(value)}</Typography>
    </Grid>

  )
  return list;
}

export const timeRead = (value, t) => {
  let list = [];
  list.push(
    <Grid key={"read"} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "row", margin: { xs: "5px 0", md: "10px 0" } }}>
      <Typography sx={{ color: "#070707", fontSize: { xs: "10px", sm: "12px", md: "18px" }, fontWeight: '700', }}>{t("timeRead")}</Typography>
      <Typography sx={{ color: "#070707", fontSize: { xs: "10px", sm: "12px", md: "18px" }, padding: "0 5px" }}>{value} {t("minutes")}</Typography>
    </Grid>
  )
  return list;
}

export const date = (value, i18n) => {
  let date = value.split(',');
  let dateFa = `${date[0]}/${date[1]}/${date[2]}`;
  let list = [];
  list.push(
    <Grid key={"date"} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "row", margin: { xs: "5px 0", md: "10px 0" } }}>
      <CalendarMonthOutlinedIcon sx={{ fontSize: { xs: '20px', sm: "24px" } }} />
      <Typography sx={{ color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify" }}>
        {i18n.language === "fa" ? dateFa : shamsi.jalaliToGregorian(parseInt(date[0]), parseInt(date[1]), parseInt(date[2])).join('/')}
      </Typography>
    </Grid>
  )
  return list;
}

export const author = (value, t) => {
  let list = [];
  list.push(
    <Grid key={"author"} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "row", margin: { xs: "5px 0", md: "10px 0" } }}>
      <Typography sx={{ color: "#070707", fontSize: { xs: "10px", sm: "12px", md: "18px" }, fontWeight: '700', }}>{t("author")}</Typography>
      <Typography sx={{ color: "#070707", fontSize: { xs: "10px", sm: "12px", md: "18px" }, padding: "0 5px" }}>{value}</Typography>
    </Grid>
  )
  return list;
}


export const tags = (value, t) => {
  let list = [];
  list.push(
    <Grid key={"tag"} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "row", margin: { xs: "5px 0", md: "10px 0" } }}>
      <Typography sx={{ color: "#070707", fontSize: { xs: "10px", sm: "12px", md: "18px" }, fontWeight: '700', width: { xs: "50px", sm: "60px", md: "80px" } }}>{t("tags")}:</Typography>
      <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "90%", margin: "10px 0", flexDirection: "row", flexWrap: "wrap" }}>
        {
          value.map((item, index) => (
            <Grid key={index} sx={{
              display: "flex", alignItems: "center", justifyContent: "center", background: "#ffffff", border: "1px solid #FFFFFF", boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)"
              , borderRadius: "19px", margin: { xs: "5px 3px", sm: '5px' }, minWidth: { xs: "47px", sm: "56px", md: "70px" }, minHeight: { xs: "19px", sm: "21px", md: "26px" }
            }}>
              <NavLink to={`/Academy/${item}`} style={{ textDecoration: "none" }} target="_blank">
                <Typography sx={{ color: "#555555", textAlign: "center", fontSize: { xs: "8px", sm: "10px", md: "12px" }, fontWeight: "700" }}>{t(item)}</Typography>
              </NavLink>
            </Grid>
          ))
        }
      </Grid>
    </Grid>
  )
  return list;
}


export const linkArticle = (t, value) => {
  let list = [];
  list.push(
    <Grid key={"link"} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "row", margin: { xs: "0px 0 5px 0", md: "10px 0" } }}>
      <ContentCopyIcon sx={{ fontSize: { xs: "20px", md: "24px" } }} />
      <Grid onClick={() => navigator.clipboard.writeText(value)} sx={{
        display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", border: "1px solid #FFFFFF", margin: "0 10px", cursor: "pointer"
        , boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)", borderRadius: "19px", width: { xs: "93px", sm: "120px", md: "195px" }, height: { xs: "22px", md: "32px" }
      }}>
        <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#555555", "&:hover": { color: "#2F79E9" } }}>{t("linkArticle")}</Typography>
      </Grid>
    </Grid>
  )
  return list;
}

export const sourceLink = (value, t) => {
  let list = [];
  list.push(
    <Grid key={"source"} sx={{ display: value === "" ? "none" : 'flex', alignItems: 'center', justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "row", margin: { xs: "0px 0 5px 0", md: "10px 0" } }}>
      <Typography sx={{ color: "#070707", fontSize: { xs: "10px", sm: "12px", md: "18px" }, fontWeight: '700', }}>{t("sourceLink")}:</Typography>
      <Grid sx={{
        display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", border: "1px solid #FFFFFF", margin: "0 10px"
        , boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)", borderRadius: "19px", width: { xs: "93px", sm: "120px", md: "195px" }, height: { xs: "22px", md: "32px" }
      }}>
        <Link href={value} sx={{ textDecoration: 'none' }}>
          <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#555555", "&:hover": { color: "#2F79E9" } }}>{t("sourceLink")}</Typography>
        </Link>
      </Grid>
    </Grid>
  )
  return list;
}

export const view = (value, t) => {
  let list = [];
  list.push(
    <Grid key={"view"} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "row", margin: { xs: "5px 0 10px 0", md: "10px 0 20px 0" } }}>
      <VisibilityIcon sx={{ fontSize: { xs: "20px", md: "24px" } }} />
      <Grid sx={{
        display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", border: "1px solid #FFFFFF", margin: "0 10px"
        , boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)", borderRadius: "19px", width: { xs: "47px", sm: "56px", md: "76px" }, height: { xs: "19px", sm: "21px", md: "24px" }
      }}>
        <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#555555" }}>{value} {t("views")}</Typography>
      </Grid>
    </Grid>
  )
  return list;
}



export const qoute = (value, t) => {
  let list = [];
  list.push(
    <Grid key={"qoute"} sx={{
      display: 'flex', alignItems: 'center', justifyContent: 'center', width: { xs: "90%", sm: "100%" }, border: "1px solid #F47DB6", boxShadow: '0px 4px 4px 2px rgba(0, 0, 0, 0.28)'
      , borderRadius: "36px 0px 36px 36px", padding: { xs: "10px", md: "20px" }, margin: { xs: "0px 0 5px 0", md: "10px 0" }
    }}>
      <Typography sx={{ color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify" }}><FormatQuoteRoundedIcon sx={{ fontSize: { xs: "15px", sm: "20px" } }} />{t(value)}<FormatQuoteRoundedIcon sx={{ transform: "rotate(180deg)", fontSize: { xs: "15px", sm: "20px" } }} />
      </Typography>
    </Grid>
  )
  return list;
}