import React, { useEffect } from "react";
import Anchor from "./Anchor";
import ArticlesLanding from "./ArticlesLanding";
import Features from "./Features";
import Header from "./Header";
import Services from "./Services";
import WhySite from "./WhySite";
import WorkSample from "./WorkSamples";
import Footer from "./Footer";
import { setCanonicalTag } from "./modules/general";
import PortfolioButton from "./PortfolioButton";

const Home = ({ auth }) => {
  useEffect(() => {
    document.title = "بهترین شرکت طراحی سایت در تهران|طراحی اپلیکیشن|سئو سایت|وب گستران سورین"
    document.getElementById("main-heading").innerHTML = "بهترین شرکت طراحی سایت در تهران | طراحی اپلیکیشن | سئو سایت | وب گستران سورین";
    document.getElementsByTagName('meta')["description"].content = "شرکت وب گستران سورین یکی از برترین شرکت های طراحی وب سایت، طراحی اپلیکیشن، سئو پیشرفته، طراحی و توسعه انواع پلتفرم، برنامه نویسی بلاکچین، طراحی گرافیکی در ایران می باشد.";
    document.getElementsByTagName('meta')["keywords"].content = "بهترین شرکت طراحی وب سایت در ایران | طراحی سایت فروشگاهی | طراحی وب سایت حرفه ای |طرافی صرافی ارز دیجیتال | طراحی سایت شرکتی | بهترین شرکت نرم افزاری ایران | بهترین شرکت طراح اپلیکیشن موبایل | ";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag();
  }, [])

  return (
    <>
      <PortfolioButton />
      <Header />
      <WorkSample />
      <Services auth={auth} />
      <Features />
      <WhySite />
      <ArticlesLanding />
      <Anchor />
      <Footer />
    </>
  );
}
export default Home;