import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setCanonicalTag } from "./modules/general";
import { NavLink, useParams } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useState } from "react";
import Footer from "./Footer";
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { dataJob } from "./DataJob";
import Loader from "./Loader";

const CareerOpportunitiesDetail = () => {
  const { t, i18n } = useTranslation();
  const [dataDetail, setDataDetail] = useState(null);
  const params = useParams();

  useEffect(() => {
    document.title = "فرصت های شغلی نرم افزاری در بهترین شرکت طراحی سایت در ایران | وب گستران سورین";
    document.getElementById("main-heading").innerHTML ="فرصت های شغلی نرم افزاری در بهترین شرکت طراحی سایت در ایران | وب گستران سورین";
    document.getElementsByTagName('meta')["description"].content = "شما میتوانید در بهترین شرکت طراحی نرم فزار در ایران مشغول به کار شوید.فرصت های شغلی نظیر برنامه نویس frontend، برنامه نویس backend، طراح رابط کاربری و تجربه کاربری، پشتیبانی نرم افزار، برنامه نویس fullstack،  کاراموز برنامه نویسی ";
    document.getElementsByTagName('meta')["keywords"].content = "صفحه مر بوط به استخدام کاراموزان و برنامه نویسان نرم افزاری | ";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    let find = dataJob.find(item => item.title === params.value)
    setDataDetail(find)
  }, []);

  return (
    <>
      <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', flexDirection: "column", width: "100%", direction: i18n.language === "fa" ? "rtl" : "ltr", marginBottom: "200px" }}>
        <Grid sx={{ display: "flex", width: "100%", backgroundColor: "#EDEDED", padding: "15px 0", justifyContent: "center" }}>
          <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: { xs: "95%", md: "86%" } }}>
            <NavLink className="navlink" to="/">
              <Typography component={"p"} sx={{ fontSize: "12px", color: "#757575", padding: "0 5px" }} >{t("mainPage")}</Typography>
            </NavLink>
            <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
            <Typography component={"p"} sx={{ fontSize: "12px", color: "#070707", padding: "0 5px" }}>{t("JobOffer")}</Typography>
          </Grid>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: { xs: "center", sm: "flex-start" }, justifyContent: "flex-start", flexDirection: "column", width: { xs: "95%", md: "90%", lg: "75%" } }}>
          <Typography sx={{ fontSize: { xs: "18px", sm: "22px", md: "32px" }, color: "#000000", margin: "20px 0" }}>{t("cooperation")}</Typography>
          {
            dataDetail === null ?
              <Loader />
              :
              <Grid sx={{
                display: 'flex', alignItems: "center", width: "100%", height: "170px", flexDirection: "column", margin: { xs: '10px', md: "50px 0" }
              }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: { xs: "center", sm: "space-between" }, width: "100%" }}>
                  <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: { xs: "flex-start", sm: "space-between" }, flexDirection: { xs: "column", sm: "row" }, width: { xs: "unset", sm: "100%" } }}>
                    <Grid sx={{ display: "flex", alignItems: "center", flexDirection: "row", margin: { xs: "5px 0", sm: "unset" } }}>
                      <HandshakeOutlinedIcon sx={{ fontSize: { xs: "20px", sm: "25px", md: "30px" }, padding: "0 2px" }} />
                      <Typography sx={{ fontSize: { xs: "14px", sm: "16px", md: "24px" }, fontWeight: "700", padding: "0 2px" }}>{t("jobTitle")}:</Typography>
                      <Typography sx={{ fontSize: { xs: "14px", sm: "16px", md: "24px" }, padding: "0 2px" }}>{t(dataDetail.title)}</Typography>
                    </Grid>
                    <Grid sx={{ display: "flex", alignItems: "center", flexDirection: "row", margin: { xs: "5px 0", sm: "unset" } }}>
                      <AccessTimeOutlinedIcon sx={{ fontSize: { xs: "20px", sm: "25px", md: "30px" }, padding: "0 2px" }} />
                      <Typography sx={{ fontSize: { xs: "14px", sm: "16px", md: "24px" }, fontWeight: "700", padding: "0 2px" }}>{t("workingHour")}:</Typography>
                      <Typography sx={{ fontSize: { xs: "14px", sm: "16px", md: "24px" }, padding: "0 2px" }}>{t(dataDetail.time)}</Typography>
                    </Grid>
                    <Grid sx={{ display: "flex", alignItems: "center", flexDirection: "row", margin: { xs: "5px 0", sm: "unset" } }}>
                      <LocationOnOutlinedIcon sx={{ fontSize: { xs: "20px", sm: "25px", md: "30px" }, padding: "0 2px" }} />
                      <Typography sx={{ fontSize: { xs: "14px", sm: "16px", md: "24px" }, fontWeight: "700", padding: "0 2px" }}>{t("addressLabel")}</Typography>
                      <Typography sx={{ fontSize: { xs: "14px", sm: "16px", md: "24px" }, padding: "0 2px" }}>{t(dataDetail.address)}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid sx={{ display: "flex", width: "100%", margin: { xs: "20px auto", md: "50px 0" }, borderBottom: "1px solid #757575" }}></Grid>
                <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", flexDirection: "row", width: "98%" }}>
                  <Grid sx={{ display: "flex", alignItems: 'felx-start', justifyContent: "flex-start", flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "24px" }, fontWeight: "700", padding: "0 2px 20px 0" }}>{t("requirements")}:</Typography>
                    {
                      dataDetail.Requirements.map((item, index) => (
                        <Typography key={index} sx={{ padding: "0 20px 10px 0", fontSize: { xs: "10px", sm: "14px", md: "22px" } }}>- {item}</Typography>
                      ))
                    }
                  </Grid>
                  <Grid sx={{ display: "flex", alignItems: 'felx-start', justifyContent: "flex-start", flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "24px" }, fontWeight: "700", padding: "0 2px 20px 0" }}>{t("technicalKnowledge")}:</Typography>
                    {
                      dataDetail.TechnicalKnowledge.map((item, index) => (
                        <Typography key={index} sx={{ padding: "0 20px 10px 0", fontSize: { xs: "10px", sm: "14px", md: "22px" } }}>- {item}</Typography>
                      ))
                    }
                  </Grid>
                </Grid>
              </Grid>
          }
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}
export default CareerOpportunitiesDetail;