import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Modal, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import { baseUrl } from "./modules/general";
import AlertMessage from "./modules/AlertMessage";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { setCanonicalTag } from "./modules/general";
import Loader from "./Loader";

let dataSelect = [];
const MessageContactUs = () => {

  const { t, i18n } = useTranslation();
  const [TabSize, setTabSize] = useState(window.innerWidth <= 600 ? "vertical" : "horizontal");
  const [openModal, setOpenModal] = useState(false);
  const [contact, setContact] = useState(null);
  const [btnView, setBtnView] = useState(false)
  const [loadingView, setLoadingView] = useState(false);
  const [btnDelete, setBtnDelete] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [alert, setAlert] = useState({ alertCheck: false, message: "", severity: "" });
  const headerPersonal = ["number", "name&fullName", "mobile", "subject"];

  useEffect(() => {
    document.title = "صفحه پاسخ به پیام کاربران دربهترین سایت طراحی سایت در تهران | وب گستران سورین";
    document.getElementById("main-heading").innerHTML ="صفحه پاسخ به پیام کاربران دربهترین سایت طراحی سایت در تهران | وب گستران سورین";
    document.getElementsByTagName('meta')["description"].content = "صفحه مربوط به پاسخ کاربران برای ثبت درخواست هایی نظیره درخواست طراحی سایت، طراحی وب سایت، برنامه نویسی پروژه دانشگاهی، طراحی رابط کاربری UI، طراحی تجربه کاربری UX، سئو سایت، طراحی گرافیکی و درخواست ساخت نرم افزار اختصاصی است.";
    document.getElementsByTagName('meta')["keywords"].content = "پاسخ به درخواست طراحی سایت | پاسخ به درخواست طراحی اپلیکیشن | پاسخ به درخواست طراحی رابط کاربری UI | پاسخ به درخواست طراحی تجربه کاربری UX | پاسخ به درخواست برنامه نویسی |پاسخ به درخواست انجام پروژه برنامه نویسی | پاسخ به درخواست سئو سایت";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => resizeTab());
    return (() => window.removeEventListener('resize', () => resizeTab()));
  }, [window.innerWidth]);

  useEffect(() => {
    getContactMessage();
  }, [])

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const modalHandeler = (item) => {
    dataSelect = item;
    handleOpenModal();
  }

  const clickOtherLocation = (e) => {
    if (e.target.id === 'modal-status') {
      handleCloseModal();
    }
  }

  const viewHandeler = (id) => {
    setBtnView(true)
    setLoadingView(true)
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    let data = { contact_id: id }
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/contact/view',
      method: "POST",
      data: data,
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setBtnView(false)
        setLoadingView(false)
        setAlert({ alertCheck: true, message: t("AM_ViewSuccess"), severity: "success" })
      })
  }

  const deleteHandeler = (id) => {
    setBtnDelete(true)
    setLoadingDelete(true)
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    let data = { contact_id: id }
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/contact/delete',
      method: "POST",
      data: data,
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setBtnDelete(false)
        setLoadingDelete(false)
        setAlert({ alertCheck: true, message: t("AM_deleteMessageSuccess"), severity: "success" })
        getContactMessage();
      })
  }

  const getContactMessage = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/contact/get`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setContact(res.data.data)
      })
  }

  const resizeTab = () => {
    if (window.innerWidth < 600 && TabSize === 'horizontal') {
      setTabSize('vertical')
    }
    else if (window.innerWidth > 600 && TabSize === 'vertical') {
      setTabSize('horizontal')
    }
  }

  const head = () => {
    let list = [];
    headerPersonal.forEach((item, index) => {
      list.push(
        <Grid key={index} sx={{ display: { xs: item === "number" ? "none" : "flex", sm: "flex" }, height: { xs: "60px", sm: "15%" }, alignItems: "center", justifyContent: "center", width: { xs: "50%", sm: "20%" } }}>
          <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px" }, color: "#070707", fontWeight: "700", textAlign: 'center', }}>{t(item)}</Typography>
        </Grid>
      )
    })
    return list;
  }

  const dataGrid = () => {
    let list = [];
    if (contact === null) {
      list.push(
        <Loader key={"data1"} />
      )
    }
    else if (contact.length === 0) {
      list.push(
        <Typography key={"data2"} sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707" }}>{t("notContact")}</Typography>
      )
    }
    else if (contact !== null && contact.length > 0) {
      contact.forEach((item,index) => {
        list.push(
          <Grid key={item.id} onClick={() => modalHandeler(item)} sx={{
            display: 'flex', alignItems: "center", justifyContent: 'center', width: "100%", backgroundColor: parseInt(index+1) % 2 === 0 ? "#FFFFFF" : "#EDEDED",
            border: "1px solid #757575", borderRadius: "8px", margin: { xs: "15px 5px 0 0", sm: "15px 0 0 0" }, cursor: "pointer", "&:hover": { boxShadow: " 0px 8px 3px 2px rgba(0, 0, 0, 0.25)" }
          }}>
            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-evenly", width: "100%", height: "40px", flexDirection: "row", }}>
              {headerPersonal.map((value, index) => (
                value === "number" ?
                  <Grid key={index} sx={{ display: { xs: value === "number" ? "none" : "flex", sm: "flex" }, alignItems: "center", justifyContent: "center", width: { xs: "50%", sm: "20%" }, height: { xs: "60px", sm: "unset" } }}>
                    <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{index + 1}</Typography>
                  </Grid>
                  :
                  value === "name&fullName" ?
                    <Grid key={index} sx={{ display: { xs: value === "number" ? "none" : "flex", sm: "flex" }, alignItems: "center", justifyContent: "center", width: { xs: "50%", sm: "20%" }, height: { xs: "60px", sm: "unset" } }}>
                      <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{item.name_lastname !== null ? item.name_lastname : t("NotCompleted")}</Typography>
                    </Grid>
                    :
                    <Grid key={index} sx={{ display: { xs: value === "number" ? "none" : "flex", sm: "flex" }, alignItems: "center", justifyContent: "center", width: { xs: "50%", sm: "20%" }, height: { xs: "60px", sm: "unset" } }}>
                      <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{t(item[value] !== null ? item[value] : "NotCompleted")}</Typography>
                    </Grid>
              ))}
            </Grid>
          </Grid>
        )
      })
    }
    list.length === 0 && list.push(<Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707" }}>{t("notContact")}</Typography>)
    return list;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  }

  return (
    <>
      <Grid sx={{
        display: 'flex', width: "100%", height: "100%", flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
        direction: i18n.language === "fa" ? "rtl" : 'ltr'
      }}>
        <Grid sx={{
          display: 'flex', alignItems: "center", justifyContent: 'center', width: "95%", height:  { xs: "43px", sm: "69px" }, backgroundColor: "#FFFFFF",
          border: "1px solid #CBCBCB", borderRadius: "8px", marginTop: { xs: "10px", sm: "20px", md: "90px" }
        }}>
          <Grid sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: "space-evenly", width: "100%", flexDirection: "row", overflow: { xs: "scroll", sm: "unset" } }}>
            {head()}
          </Grid>
        </Grid>
        <Grid sx={{
          display: 'flex', alignItems: "center", justifyContent: 'flex-start', width: "95%", height: "100%", margin: { xs: "5px 0 0 5px", sm: "15px 0 0 0" }
          , flexDirection: "column"
        }}>
          {dataGrid()}
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid id="modal-status" onClick={(e) => clickOtherLocation(e)} sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh", backgroundColor: 'rgba(105,99,99,0.6)', direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
          <Grid sx={{
            display: "flex", alignItems: "center", flexDirection: 'column', background: "#FFFFFF", width: { xs: "95%", sm: "674px" },
            borderRadius: "12px",
          }}>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "95%", margin: "10px 0 20px 0" }}>
              <CloseRoundedIcon onClick={handleCloseModal} sx={{ fontSize: "30px", cursor: "pointer" }} />
            </Grid>
            <Grid sx={{ display: "flex", alignItems: "center", backgroundColor: "#EDEDED", width: { xs: "90%", sm: "616px" }, borderRadius: "16px", flexDirection: "column" }}>
              <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-around", flexDirection: "row", width: "95%", padding: "15px 0" }}>
                <Typography sx={{ fontSize: { xs: "14px", sm: "24px" }, margin: "0 10px", fontWeight: "700" }}>{dataSelect.name_lastname !== null ? dataSelect.name_lastname : t("NotCompleted")}</Typography>
                <Typography sx={{ fontSize: { xs: "12px", sm: "18px" }, color: "#000000" }}>{dataSelect.mobile}</Typography>
              </Grid>
              <Grid sx={{ width: "90%", display: "flex", height: '1px', backgroundColor: "#CBCBCB" }}></Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", minHeight: "100px" }}>
                <Typography sx={{ fontSize: { xs: "12px", sm: "18px" }, color: "#000000" }}>{dataSelect.message}</Typography>
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: 'row', width: "100%", margin: "30px 0" }}>
              <Button onClick={() => viewHandeler(dataSelect.id)} disabled={btnView ? true : false} sx={{
                width: { xs: "100px", sm: "110px" }, height: { xs: "33px", sm: "40px" }, boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", fontSize: { xs: "14px", sm: "16px" },
                background: "#FF3951", color: "#FFFFFF", "&:hover": { background: "#FF3951" }, margin: "0 10px"
              }}>
                {btnView === true && loadingView === true ? <CircularProgress size={20} color="inherit" sx={{ color: "#ffffff" }} /> : t("displayed")}
              </Button>
              <Button onClick={() => deleteHandeler(dataSelect.id)} disabled={btnDelete ? true : false} sx={{
                width: { xs: "100px", sm: "110px" }, height: { xs: "33px", sm: "40px" }, boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", fontSize: { xs: "14px", sm: "16px" }, border: "2px solid #FF3951",
                background: "#FFFFFF", color: "#FF3951", "&:hover": { background: "#FFFFFFF" }, margin: "0 10px"
              }}>
                {btnDelete === true && loadingDelete === true ? <CircularProgress size={20} color="inherit" sx={{ color: "#FF3951" }} /> : t("delete")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal >
      <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
    </>
  )
}

export default MessageContactUs;