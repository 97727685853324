import { Grid, TextField, Typography, Button, CircularProgress } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import map from '../image/contactUs/map.jpg';
import contact from '../image/contactUs/contact.png';
import { NavLink } from "react-router-dom";
import twitter from '../image/icon/twitter.png';
import telegram from '../image/icon/telegram.png';
import instagram from '../image/icon/instagram.png';
import whatsApp from '../image/icon/whatsApp.png';
import Footer from "./Footer";
import { useState } from "react";
import axios from "axios";
import { baseUrl } from "./modules/general";
import AlertMessage from "./modules/AlertMessage";
import { useEffect } from "react";
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { setCanonicalTag } from "./modules/general";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = ({ info }) => {
  const { t, i18n } = useTranslation();
  const [textFieldSize, setTextFiledSize] = useState(window.innerWidth <= 900 ? "small" : "medium");
  const [fullName, setFullName] = useState(info !== null && info.name !== null && info.lastname !== null ? info.name + " " + info.lastname : "");
  const [phoneNumber, setPhoneNumber] = useState(info !== null && info.mobile !== null ? info.mobile : "");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [sendBtn, setSendBtn] = useState(false);
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({ alertCheck: false, message: "", severity: "" });
  const [captcha, setCaptcha] = useState(false);

  useEffect(() => {
    document.title = "تماس با شرکت وب گستران سورین | برترین شرکت طراحی وب سایت اینترنتی"
    document.getElementById("main-heading").innerHTML = "تماس با شرکت وب گستران سورین | برترین شرکت طراحی وب سایت اینترنتی";
    document.getElementsByTagName('meta')["description"].content = "برای ارتباط با شرکت وب گستران سورین از طریق فرم دریافت اطلاعات و یا تماس با شماره 02191030773 داخلی 1 ";
    document.getElementsByTagName('meta')["keywords"].content = "ارتباط با شرکت وب گستران سورین | ارائه دهنده خدمات نرم افزاری | برای سفارش طراحی سایت تماس بگیرید ";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => resizeTextFiled());
    return (() => window.removeEventListener('resize', () => resizeTextFiled()));
  }, [window.innerWidth]);

  const resizeTextFiled = () => {
    if (window.innerWidth < 900 && textFieldSize === 'medium') {
      setTextFiledSize('small')
    }
    else if (window.innerWidth > 900 && textFieldSize === 'small') {
      setTextFiledSize('medium')
    }
  }
  const setInput = (setter) => (event) => {
    setter(event.target.value);
  }

  const submitHandeler = () => {
    if ((fullName === "") && (phoneNumber === "") && (subject === "") && (message === "")) {
      setAlert({ alertCheck: true, message: t("AM_notField"), severity: "error" })
    }
    else if (fullName === "") {
      setAlert({ alertCheck: true, message: t("AM_fullName"), severity: "error" })
    }
    else if (phoneNumber === "") {
      setAlert({ alertCheck: true, message: t("AM_phoneNumber_FiledNull"), severity: "error" })
    }
    else if (subject === "") {
      setAlert({ alertCheck: true, message: t("AM_subject"), severity: "error" })
    }
    else if (message === "") {
      setAlert({ alertCheck: true, message: t("AM_message"), severity: "error" })
    }
    else if (captcha === false) {
      setAlert({ alertCheck: true, message: t("AM_captcha"), severity: "error" })
    }
    else {
      sendMessage();
    }
  }

  const sendMessage = () => {
    let data = { nln: fullName, mobile: phoneNumber, subject: subject, message: message }
    setSendBtn(true);
    setLoading(true);
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/contact/create',
      method: "POST",
      data: data
    }))
      .then((res) => {
        setSendBtn(false);
        setLoading(false);
        setPhoneNumber("");
        setFullName("");
        setMessage("");
        setSubject("");
        setAlert({ alertCheck: true, message: t("AM_register_successContact"), severity: "success" })
      })
      .catch(
        (error) => {
          setSendBtn(false);
          setLoading(false);
        }
      )
  }

  const onChangeRechapta = (value) => {
    value != null ? setCaptcha(true) : setCaptcha(false)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };

  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: i18n.language === "fa" ? "rtl" : "ltr", alignItems: "center" }}>
        <Grid sx={{ display: "flex", width: "100%", backgroundColor: "#EDEDED", padding: "15px 0", justifyContent: "center" }}>
          <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: { xs: "95%", md: "86%" } }}>
            <NavLink className="navlink" to="/">
              <Typography component={"p"} sx={{ fontSize: "12px", color: "#757575", padding: "0 5px" }} >{t("mainPage")}</Typography>
            </NavLink>
            <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
            <Typography component={"p"} sx={{ fontSize: "12px", color: "#070707", padding: "0 5px" }}>{t("contact")}</Typography>
          </Grid>
        </Grid>
        <Grid sx={{ backgroundSize: "cover", backgroundImage: `url(${map})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", width: "100%", height: { xs: "20vh", md: "25vh", lg: "30vh" }, marginTop: "10px" }}>
        </Grid>
        <Grid sx={{ display: "flex", flexDirection: { xs: "column-reverse", md: "row" }, width: "95%", margin: "15px 0", marginBottom: "50px", alignItems: "center" }}>
          <Grid sx={{ display: 'flex', flexDirection: "column", width: { xs: "100%", sm: "80%", md: "50%" }, justifyContent: { xs: "center", md: "flex-start" }, marginRight: { md: "70px" } }}>
            <Typography component={"p"} sx={{ fontSize: { sm: "24px" }, fontWeight: "700", lineHeight: "34px", display: { xs: "none", sm: "flex" } }}>{t("contact")}</Typography>
            <Grid sx={{ display: "flex", width: "100%", borderTop: { xs: "none", sm: "1px solid #757575" }, padding: "5px 0" }}>
              <Typography component={"p"} sx={{ color: "#757575", fontSize: { xs: "14px", sm: "20px" } }}>{t("communicationUs")}</Typography>
            </Grid>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', width: "100%", flexDirection: "column" }}>
              <TextField className="textField" size={textFieldSize} InputLabelProps={{ sx: { fontSize: { xs: "10px", sm: "16" } } }} InputProps={{ disableUnderline: true }}
                value={fullName} onChange={setInput(setFullName)} label={t("fullName")} variant="filled" sx={{ width: "100%", margin: "10px 0" }} />
              <TextField className="textField" size={textFieldSize} InputLabelProps={{ sx: { fontSize: { xs: "10px", sm: "16" } } }} InputProps={{ disableUnderline: true }}
                value={phoneNumber} onChange={setInput(setPhoneNumber)} label={t("phoneNumber")} variant="filled" sx={{ width: "100%", margin: "10px 0" }} />
              <TextField className="textField" size={textFieldSize} InputLabelProps={{ sx: { fontSize: { xs: "10px", sm: "16" } } }} InputProps={{ disableUnderline: true }}
                value={subject} onChange={setInput(setSubject)} label={t("subject")} variant="filled" sx={{ width: "100%", margin: "10px 0" }} />
              <TextField className="textField" size={textFieldSize} InputLabelProps={{ sx: { fontSize: { xs: "10px", sm: "16" } } }} InputProps={{ disableUnderline: true }}
                value={message} onChange={setInput(setMessage)} label={t("message")} variant="filled" multiline rows={6} sx={{ width: "100%", margin: "10px 0" }} />
              <ReCAPTCHA
                sitekey="6LfKh64mAAAAAPBcT_cK-ExJsLxXAdzBKWAJa1q1"
                onChange={(value) => onChangeRechapta(value)}
                className="g-recaptcha-login"
                hl="fa"
              />
            </Grid>
            <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%" }}>
              <Button onClick={submitHandeler} disabled={sendBtn ? true : false} sx={{ display: "flex", bgcolor: "#FF3951", width: { xs: "50%", sm: "45%" }, padding: "12px 0", boxShadow: " #FF3951 0px 5px 15px", margin: "20px auto", "&:hover": { backgroundColor: "#FF3951" } }}>
                <Typography component={"p"} sx={{ color: "white", fontSize: "12px" }}>
                  {sendBtn === true && loading === true ? <CircularProgress size={20} color="inherit" /> : t("send")}

                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid sx={{
            display: "flex", justifyContent: "center", alignItems: "center", backgroundSize: "100% 100%", backgroundImage: `url(${contact})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
            width: { xs: "303px", sm: "490px", md: "700px", lg: "50%" }, margin: { xs: "0 auto", md: "0 30px" }, height: { xs: "312px", sm: "500px", md: "650px" }, flexDirection: 'column'
          }}>
            <Grid sx={{ display: 'flex', alignItems: "flex-start", justifyContent: "center", flexDirection: "column", margin: { xs: "60px 0 5px 0", sm: "80px 0 10px 0" }, width: "80%" }}>
              <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', flexDirection: 'row', margin: { xs: "5px 0", md: "10px 0" } }}>
                <PlaceIcon sx={{ color: "#0D47A1", fontSize: { xs: "20px", md: "26px" }, padding: "0 3px" }} />
                <Typography sx={{ fontSize: { xs: '10px', sm: "16px", md: "20px" }, fontWeight: "700", color: "#070707" }}>{t("fieldAddress")}:</Typography>
                <Typography sx={{ fontSize: { xs: '10px', sm: "16px" }, color: "#070707", padding: "0 3px" }}>{t("addressContactUs")}</Typography>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', flexDirection: 'row', margin: { xs: "5px 0", md: "10px 0" } }}>
                <PhoneIcon sx={{ color: "#0D47A1", fontSize: { xs: "20px", md: "26px" }, padding: "0 3px", transform: i18n.language === "fa" && "rotate(250deg)" }} />
                <Typography sx={{ fontSize: { xs: '10px', sm: "16px", md: "20px" }, fontWeight: "700", color: "#070707" }}>{t("phoneLabel")}</Typography>
                <Typography sx={{ fontSize: { xs: '10px', sm: "16px" }, color: "#070707", padding: "0 3px" }}>021-77442770</Typography>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', flexDirection: 'row', margin: { xs: "5px 0", md: "10px 0" } }}>
                <EmailIcon sx={{ color: "#0D47A1", fontSize: { xs: "20px", md: "26px" }, padding: "0 3px" }} />
                <Typography sx={{ fontSize: { xs: '10px', sm: "16px", md: "20px" }, fontWeight: "700", color: "#070707" }}>{t("email")}:</Typography>
                <Typography component={"a"} href="http://info@sorinapp.com" sx={{ textDecoration: "none", fontSize: { xs: '10px', sm: "16px" }, color: "#070707", padding: "0 3px" }}>info@Sorinapp.com</Typography>
              </Grid>
            </Grid>
            <Grid sx={{
              display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: "row", margin: "0 auto", bottom: { xs: "25%", md: "27%" }

            }}>
              <NavLink className="navlink">
                <Typography component={"img"} alt="twitter" src={twitter} sx={{ marginRight: { xs: "3px", md: "5px" }, width: { xs: "50%", md: "70%" } }} />
              </NavLink>
              <NavLink className="navlink">
                <Typography component={"img"} alt="telegram" src={telegram} sx={{ marginRight: { xs: "3px", md: "5px" }, width: { xs: "50%", md: "70%" } }} />
              </NavLink>
              <NavLink className="navlink">
                <Typography component={"img"} alt="whatsApp" src={whatsApp} sx={{ marginRight: { xs: "3px", md: "5px" }, width: { xs: "50%", md: "70%" } }} />
              </NavLink>
              <NavLink className="navlink">
                <Typography component={"img"} alt="instagram" src={instagram} sx={{ marginRight: { xs: "3px", md: "5px" }, width: { xs: "50%", md: "70%" } }} />
              </NavLink>
            </Grid>
          </Grid>
          <Grid sx={{ display: { xs: "flex", sm: "none" }, justifyContent: "center", alignItems: "center", borderBottom: "1px solid #757575", padding: "0px 100px" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "20px" }}>{t("contact")}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
      <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
    </>
  );
}
export default ContactUs;