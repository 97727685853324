import { SvgIcon } from '@mui/material';
import React from 'react';
const NewOrderIcon = ({ color }) => {
  return (
    <SvgIcon component="object" sx={{ height: "30px" }}>
      <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 6V7.5H18V6H13.5ZM18 10.5V9H13.5V10.5H18ZM18 13.5V12H15V13.5H18ZM19.5 6V7.5H21V6H19.5ZM19.5 9V10.5H21V9H19.5ZM21 13.5V12H19.5V13.5H21ZM22.5 1.5V22.5H15V24H24V0H10.9336L4.5 6.43359V9H6V7.5H12V1.5H22.5ZM10.5 2.56641V6H7.06641L10.5 2.56641ZM3 12H0V24H13.5V12H10.5V10.5H9V12H4.5V10.5H3V12ZM1.5 22.5V16.5H12V22.5H1.5ZM1.5 15V13.5H12V15H1.5Z" fill={color} />
      </svg>
    </SvgIcon>
  );
};
export default NewOrderIcon;