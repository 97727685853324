import { SvgIcon } from '@mui/material';
import React from 'react';
const AreasActivityIcon = ({ color }) => {
  return (
    <SvgIcon component="object" sx={{ height: "30px" }}>
      <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.89218 12.605C5.62851 13.1898 7.80316 14.0847 10.1197 14.0847C12.3283 14.0847 13.6762 13.2844 14.3757 12.7954" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.5171 5.4267H6.48291C6.25172 5.4267 6.0643 5.61411 6.0643 5.8453V10.1645C6.0643 10.3957 6.25172 10.5831 6.48291 10.5831H13.5171C13.7483 10.5831 13.9357 10.3957 13.9357 10.1645V5.8453C13.9357 5.61411 13.7483 5.4267 13.5171 5.4267Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.3927 5.42671V4.62843C11.3927 4.42859 11.3133 4.23694 11.172 4.09563C11.0307 3.95432 10.8391 3.87494 10.6392 3.87494H9.36082C9.16098 3.87494 8.96933 3.95432 8.82802 4.09563C8.68672 4.23694 8.60733 4.42859 8.60733 4.62843V5.42671" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  );
};
export default AreasActivityIcon;