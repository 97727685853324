import React from "react";
import '../styles/loader.css'
import { Grid, Typography } from "@mui/material";
const Loader = () => {
  return (
    <Grid sx={{ width: "100%", margin: "50px auto",display:"flex",alignItems:"center",justifyContent:"center"  }}>
      <Grid className="loader-wrapper">
        <Grid className="loader">
          <Grid className="loader loaderInner"> </Grid>
        </Grid>
      </Grid>
    </Grid >
  )
}
export default Loader;