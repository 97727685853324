import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import back from '../image/dashboard/back.png';
import logo from '../image/logo/logo.png';
import { useState } from "react";
import PersonalInformation from "./PersonalInformation";
import OrderUi from "./OrderUi";
import OrderProgramming from "./OrderProgramming";
import OrderSeo from "./OrderSeo";
import OrderGraphicDesign from "./OrderGraphicDesign";
import StatusOrder from "./StatusOrder";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import NewUser from "./NewUser.js";
import NewOrder from "./NewOrder";
import ModalLogout from "./ModalLogout";
import OrderSite from "./OrderSite";
import avatar from '../image/dashboard/avatar.png'
import OrderApplication from "./OrderApplication";
import Ticket from "./Ticket";
import axios from "axios";
import { baseUrl } from "./modules/general";
import OldOrder from "./OldOrder";
import MessageContactUs from "./MessageContactUs";
import Activity from "./Activity";
import TypeSite from "./TypeSite";
import TypeGraphic from "./TypeGraphic";
import Condition from "./Condition";
import SearchUser from "./SearchUser";

const DashboardMain = ({ panel, data, infoUser }) => {

  const navigate = useNavigate();
  const Params = useParams();
  const { t, i18n } = useTranslation();
  const [select, setSelect] = useState(null);
  const [page, setPage] = useState(null);
  const [openModalExit, setOpenModalExit] = useState(false);
  const [info, setInfo] = useState(null);
  const [unReadComment, setUnREadComment] = useState(0);

  useEffect(() => {
    setSelect(Params.value)
  }, [Params.value])

  useEffect(() => {
    if (infoUser !== null) setInfo(infoUser)
  }, [infoUser])

  useEffect(() => {
    pageSelect();
  }, [select]);

  useEffect(() => {
    getUnReadComment();
  }, [])
  const selectHandeler = (value) => {
    setSelect(value);
    navigate("/SorinBoard/" + value)
  }

  const getUnReadComment = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/comment/unReadCommend`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setUnREadComment(res.data.data)
      })
  }

  const handleOpenModalExit = () => setOpenModalExit(true);
  const handleCloseModalExit = () => setOpenModalExit(false);

  const panelFun = () => {
    let list = [];
    if (data) {
      data.forEach((item) => {
        list.push(
          item.text === "logout" ?
            <Grid key={item.id} sx={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", height: '100%' }}>
              <Grid className="logoutHover" onClick={handleOpenModalExit} sx={{ display: 'flex', flexDirection: "row", alignItems: "flex-end", width: "100%", margin: "5% 0 2% 0", padding: "5% 15px", cursor: 'pointer' }}>
                <Typography sx={{ fontSize: "18px", color: "#070707", fontWeight: "700", lineHeight: "28px", padding: "0 5px" }} >{t(item.text)}</Typography>
                <item.icon color={"#000000"} />
              </Grid>
            </Grid>
            :
            item.text === "Tickets" ?
              <Grid key={item.id} onClick={() => selectHandeler(item.text)} sx={{
                display: 'flex', flexDirection: "row", alignItems: "center", width: "100%", margin: "10% 0 2% 0", padding: "3% 10px",
                background: select === item.text ? "#FFEDEF" : "unset", borderLeft: i18n.language === "fa" ? select === item.text ? "4px solid #FF3951" : "unset" : "unset", cursor: 'pointer'
                , borderRadius: select === item.text ? i18n.language === "fa" ? "0px 16px 16px 0px" : "16px 0px 0px 16px" : "unset", borderRight: i18n.language === "en" ? select === item.text ? "4px solid #FF3951" : "unset" : "unset"
              }}>
                <item.icon color={select === item.text ? "#FF3951" : "#000000"} />
                <Typography sx={{ fontSize: "18px", color: select === item.text ? "#FF3951" : "#070707", fontWeight: "700", lineHeight: "28px", padding: "0 5px" }} >{t(item.text)} </Typography>
                <Grid sx={{ display: unReadComment > 0 ? "flex" : "none", alignItems: "center", justifyContent: 'center' }} >
                  <Typography sx={{ fontSize: "14px", color: "#FFFFFF", fontWeight: '700', borderRadius: "50%", padding: "0px 6px", background: "#16C72E" }}>{unReadComment}</Typography>
                </Grid>
              </Grid>
              :
              <Grid key={item.id} onClick={() => selectHandeler(item.text)} sx={{
                display: 'flex', flexDirection: "row", alignItems: "center", width: "100%", margin: "10% 0 2% 0", padding: "3% 10px",
                background: select === item.text ? "#FFEDEF" : "unset", borderLeft: i18n.language === "fa" ? select === item.text ? "4px solid #FF3951" : "unset" : "unset", cursor: 'pointer'
                , borderRadius: select === item.text ? i18n.language === "fa" ? "0px 16px 16px 0px" : "16px 0px 0px 16px" : "unset", borderRight: i18n.language === "en" ? select === item.text ? "4px solid #FF3951" : "unset" : "unset"
              }}>
                <item.icon color={select === item.text ? "#FF3951" : "#000000"} />
                <Typography sx={{ fontSize: "18px", color: select === item.text ? "#FF3951" : "#070707", fontWeight: "700", lineHeight: "28px", padding: "0 5px" }} >{t(item.text)} </Typography>
              </Grid>
        )
      })
      return list;
    }
  }

  const pageSelect = () => {
    switch (select) {
      case ("Dashboard"):
        setPage(<Dashboard infoUser={infoUser} />)
        break;
      case ("UserInfo"):
        setPage(<PersonalInformation />)
        break;
      case ("WebsiteOrder"):
        setPage(<OrderSite />)
        break;
      case ("ProgrammingOrder"):
        setPage(<OrderProgramming />)
        break;
      case ("SeoOrder"):
        setPage(<OrderSeo />)
        break;
      case ("UIUXorder"):
        setPage(<OrderUi />)
        break;
      case ("GraphicOrder"):
        setPage(<OrderGraphicDesign />)
        break;
      case ("AppOrder"):
        setPage(<OrderApplication />)
        break;
      case ("OrderStatus"):
        setPage(<StatusOrder infoUser={infoUser} />)
        break;
      case ("NewUser"):
        setPage(<NewUser />)
        break;
      case ("NewOrder"):
        setPage(<NewOrder />)
        break;
      case ("OrderHistory"):
        setPage(<OldOrder />)
        break;
      case ("Tickets"):
        setPage(<Ticket infoUser={infoUser} unRead={getUnReadComment} />)
        break;
      case ("ContactSorin"):
        setPage(<MessageContactUs />)
        break;
      case ("AreasActivity"):
        setPage(<Activity />)
        break;
      case ("SiteType"):
        setPage(<TypeSite />)
        break;
      case ("GraphicType"):
        setPage(<TypeGraphic />)
        break;
      case ("ProjectStatus"):
        setPage(<Condition />)
        break;
      case ("SearchUser"):
        setPage(<SearchUser />)
        break;

    }
  }
  return (
    <>
      <Grid sx={{
        display: "flex", alignItems: "flex-start", justifyContent: 'center', width: "100%",
        height: "100%", minHeight: "100vh", direction: i18n.language === "fa" ? "rtl" : "ltr", overflow: "scroll",
        background: `url(${back})`, backgroundPosition: "center", backgroundSize: "100% 100%", backgroundRepeat: "repeat", padding: "40px 0",
      }}>
        <Grid sx={{
          display: "flex", flexDirection: "row", background: "rgba(245, 245, 245, 0.49)", alignItems: "flex-start", borderRadius: "32px", width: "90%",
          padding: "30px 3%", border: "5px solid #FFFFFF", height: "100%",
        }}>
          <Grid sx={{
            display: { xs: "none", md: 'flex' }, flexDirection: "column", width: "100%", maxWidth: { md: "220px", lg: "268px" }, position: "sticky", top: "0",
            minHeight: "100% !important", background: "#FFFFFF", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)", borderRadius: "26px", minHeight: { md: "80vh", lg: "90vh" }
          }}>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', height: "170px", borderBottom: "1px solid #CBCBCB" }}>
              <Typography component={"img"} alt="logo" src={logo} sx={{ width: "110px", height: "85px" }} />
            </Grid>
            {panelFun()}
          </Grid>
          <Grid sx={{ display: "flex", alignItems: 'center', width: "100%", flexDirection: "column", justifyContent: "center", height: { xs: "100%", md: "unset" } }}>
            <Grid sx={{
              display: panel === "S" ? "none" : select === "Dashboard" || select === "UserInfo" || select === "Tickets" ? "none" : 'flex', alignItems: "center", justifyContent: "slex-start", width: "95%", height: "100%", minHeight: { xs: "81px", md: "115px" }, background: " rgba(252, 252, 252, 0.59)",
              borderRadius: "26px", flexDirection: "row", padding: "0 2%"
            }}>
              {
                infoUser !== null ?
                  <>
                    <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                      <Grid
                        sx={{
                          width: { xs: "69px", sm: "90px" }, height: { xs: "69px", sm: "90px" }, margin: "0 10px", borderRadius: "50px"
                          , background: `url(${infoUser.picture_path !== null ? infoUser.picture_path : avatar})`, backgroundPosition: "center", backgroundSize: infoUser.picture_path !== null ? "100% 100%" : "120% 110%",
                        }} ></Grid>
                    </Grid>
                    <Grid sx={{ display: 'flex', alignItems: "flex-start", justifyContent: "space-evenly", flexDirection: 'column', padding: "0 2%", height: "100%", minHeight: { xs: "81px", md: "115px" } }}>
                      <Typography sx={{ fontSize: { xs: "16px", md: "24px" }, fontWeight: "700", color: "#070707", lineHeight: "34px" }}>
                        {infoUser.user_type_id === 1 ? infoUser.name === null ? "" : infoUser.name + " " + infoUser.lastname :
                          infoUser.user_type_id === 2 ? infoUser.organ_manager_name === null ? "" : infoUser.organ_manager_name : ""
                        }
                      </Typography>
                      <Typography sx={{ fontSize: { xs: "12px", md: "16px" }, fontWeight: "400", color: "#070707", lineHeight: "23px" }}>{infoUser.user_type_id === 2 ? t("organizationPanel") : t("personalPanel")}</Typography>
                      <Typography sx={{ fontSize: { xs: "12px", md: "16px" }, fontWeight: "400", color: "#070707", lineHeight: "23px" }}>
                        {infoUser.user_type_id === 2 ? infoUser.organ_name === null ? "" : infoUser.organ_name : ""}
                      </Typography>
                    </Grid>
                  </>
                  :
                  ""
              }
            </Grid>
            {page}
          </Grid>
        </Grid>
      </Grid>
      <ModalLogout open={openModalExit} close={handleCloseModalExit} />
    </>
  )
}
export default DashboardMain;