
import { Grid, Tab, Tabs, MenuItem, Select, FormControl, Typography, TextField, CircularProgress, Button, Modal, Pagination, Stack } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import tikcetSend from '../image/dashboard/ticketSend.png'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import avatar from '../image/dashboard/avatar.png';
import logo from '../image/dashboard/logoSupporter.png'
import AlertMessage from "./modules/AlertMessage";
import axios from "axios";
import { baseUrl } from "./modules/general";
import * as shamsi from 'shamsi-date-converter';
import { setCanonicalTag } from "./modules/general";
import Loader from "./Loader";

const Ticket = ({ infoUser, unRead }) => {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState("Tickets");
  const [TabSize, setTabSize] = useState(window.innerWidth <= 600 ? "vertical" : "horizontal");
  const [textFieldSize, setTextFiledSize] = useState(window.innerWidth <= 900 ? "small" : "medium");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState("");
  const [loadingSend, setLoadingSend] = useState(false);
  const [btnSend, setBtnSend] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalTicket, setOpenModalTicket] = useState(false);
  const [ticket, setTicket] = useState(null)
  const [page, setPage] = useState(1);
  const [numberShow, setNumberShow] = useState(25);
  const [alert, setAlert] = useState({ alertCheck: false, message: "", severity: "" });
  const [dataCategori, setDataCategori] = useState(null);
  const [dataTicket, setDataTicket] = useState(null);
  const [btnComment, setBtnComment] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [comment, setComment] = useState("")
  const [loader, setLoader] = useState(false);
  const header = ["title", "category", "status", "date", "time"];

  useEffect(() => {
    document.title = "ثبت تیکت دربهترین شرکت طراحی سایت در ایران | وب گستران سورین";
    document.getElementById("main-heading").innerHTML = "ثبت تیکت دربهترین شرکت طراحی سایت در ایران | وب گستران سورین";
    document.getElementsByTagName('meta')["description"].content = "صفحه مربوط به پاسخگویی تیکت توسط پشتیبان همیشه آنلاین بهترین شرکت طراحی سایت در ایران. پاسخگویی 24 ساعته و 7 روز هفته برای پشتیبانی محصولات نرم افزاری برترین سایت طراحی نرم افزار در تهران";
    document.getElementsByTagName('meta')["keywords"].content = "تیکت مربوط به طراحی سایت | تیکت مربوط به طراحی اپلیکیشن | تیکت مربوط به برنامه نویسی | تیکت مربوط به طراحی رابط کاربری UI | تیکت طراحی تجربه کاربری UX | تیکت مشکلات سئو سایت";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    getCategori();
  }, [])

  useEffect(() => {
    if (value === "Tickets") {
      getTicket();
    };
  }, [value])

  useEffect(() => {
    window.addEventListener('resize', () => resizeTab());
    return (() => window.removeEventListener('resize', () => resizeTab()));
  }, [window.innerWidth]);

  useEffect(() => {
    window.addEventListener('resize', () => resizeTextFiled());
    return (() => window.removeEventListener('resize', () => resizeTextFiled()));
  }, [window.innerWidth]);

  const getCategori = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/category/get`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataCategori(res.data.data)
      })
  }

  const getTicket = () => {
    let url = infoUser.user_type_id === 3 ? "/api/v1/ticket/new" : "/api/v1/ticket/userTikets"
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}${url}`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setTicket(res.data.data)
      })
  }

  const resizeTab = () => {
    if (window.innerWidth < 600 && TabSize === 'horizontal') {
      setTabSize('vertical')
    }
    else if (window.innerWidth > 600 && TabSize === 'vertical') {
      setTabSize('horizontal')
    }
  }

  const resizeTextFiled = () => {
    if (window.innerWidth < 900 && textFieldSize === 'medium') {
      setTextFiledSize('small')
    }
    else if (window.innerWidth > 900 && textFieldSize === 'small') {
      setTextFiledSize('medium')
    }
  }

  const sendHandeler = () => {
    if ((title === "") && (category === "") && (message === "")) {
      setAlert({ alertCheck: true, message: t("AM_notField"), severity: "error" })
    }
    else if ((title === "")) {
      setAlert({ alertCheck: true, message: t("AM_notTitle"), severity: "error" })
    }
    else if ((category === "")) {
      setAlert({ alertCheck: true, message: t("AM_notGrouping"), severity: "error" })
    }
    else if ((message === "")) {
      setAlert({ alertCheck: true, message: t("AM_notText"), severity: "error" })
    }
    else {
      createTicket();
    }

  }

  const createTicket = () => {
    let data = {
      title: title,
      category_id: category,
      message: message
    }
    setLoadingSend(true)
    setBtnSend(true)
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/ticket/create',
      method: "POST",
      data: data,
      headers: { 'Authorization': AuthStr, }
    }))
      .then(() => {
        handleOpenModal();
        setLoader(false);
        setBtnSend(false);
        setTitle(""); setCategory(""); setMessage("");
      })
      .catch(
        (error) => {
          const { status } = error.response;
          switch (status) {
            case (500):
              setAlert({ alertCheck: true, message: t("AM_intervalServer"), severity: "error" })
              break;
            default:
              setAlert({ alertCheck: true, message: t("alert_defualt"), severity: "error" })
          }
          setBtnSend(false)
          setLoadingSend(false)
        }
      )
  }

  const descCategory = (data) => {
    let findElement;
    dataCategori !== null ?
      findElement = dataCategori.find(element => element.id === data)
      :
      findElement = null;
    return findElement !== null ? findElement.name_fa : null;
  }

  const head = () => {
    let list = [];
    header.forEach((item, index) => {
      list.push(
        <Grid key={index} sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: "20%" }}>
          <Typography sx={{ fontSize: { xs: "10px", sm: "18px", md: "20px" }, color: "#070707", fontWeight: '700' }}>{t(item)}</Typography>
        </Grid>
      )
    })
    return list;
  }

  const dataGrid = () => {
    let list = [];
    if (ticket === null) {
      list.push(
        <Loader key={"data1"} />
      )
    }
    else if (ticket.length === 0) {
      list.push(
        <Typography key={"data2"} sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707" }}>{t("notTicket")}</Typography>
      )
    }
    else if (ticket !== null) {
      let ticketData = ticket.slice((page - 1) * numberShow, page * numberShow);
      ticketData.forEach((item) => {
        let date = item.created_at.substring(0, 9).split('-');
        let dateEn = `${date[0]}/${date[1]}/${date[2]}`;
        list.push(
          <Grid onClick={() => replayTicket(item.id)} key={item.id} sx={{ display: "flex", alignItems: "center", justifyContent: 'space-evenly', flexDirection: 'row', width: "95%", minHeight: { xs: "55px", sm: "60px" }, borderBottom: "1px solid #CBCBCB", marginBottom: "10px", cursor: "pointer" }}>
            <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "16px" }, color: "#3C3A3A", textAlign: 'center', width: "20%" }}>{item.title}</Typography>
            <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "16px" }, color: "#3C3A3A", width: "20%", textAlign: 'center' }}>
              {infoUser.user_type_id === 3 ? descCategory(item.category_id) : item.category.name_fa}
            </Typography>
            <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "18px" }, color: item.status === "NEW" ? "#FC1010" : "#0D6B28", width: "20%", textAlign: 'center' }}>{t(item.status)}</Typography>
            <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "16px" }, color: "#3C3A3A", width: "20%", textAlign: 'center' }}>
              {i18n.language === "fa" ? shamsi.gregorianToJalali(parseInt(date[0]), parseInt(date[1]), parseInt(date[2])).join('/') : dateEn}
            </Typography>
            <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "16px" }, color: "#3C3A3A", width: "20%", textAlign: 'center' }}>{item.created_at.substring(11, 16)}</Typography>
          </Grid>
        )
      })
    }
    return list;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const replayTicket = (id) => {
    setLoader(true);
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + "/api/v1/ticket/getTicket/" + id,
      method: "GET",
      headers: { 'Authorization': AuthStr }
    }))
      .then((res) => {
        setDataTicket(res.data.data)
        handleOpenModalTicket();
        setLoader(false);
        commentView(dataTicket);
      })
  }

  const clickOtherLocation = (e) => {
    if (e.target.id === 'modal-ticket') {
      handleCloseModal();
      handleCloseModalTicket();
    }
  }

  const sendComment = () => {
    let data = {
      ticket_id: dataTicket[0].ticket_id,
      comment: comment
    }
    setCommentLoading(true)
    setBtnComment(true)
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/comment/create',
      method: "POST",
      data: data,
      headers: { 'Authorization': AuthStr, }
    }))
      .then(() => {
        setBtnComment(false);
        setCommentLoading(false);
        replayTicket(dataTicket[0].id)
        commentsGrid();
        setComment("");
        setAlert({ alertCheck: true, message: t("AM_commentSend"), severity: "success" })
      })
      .catch(
        (error) => {
          const { status } = error.response;
          switch (status) {
            case (500):
              setAlert({ alertCheck: true, message: t("AM_intervalServer"), severity: "error" })
              break;
            default:
              setAlert({ alertCheck: true, message: t("alert_defualt"), severity: "error" })
          }
          setBtnComment(false)
          setCommentLoading(false)
        }
      )
  }

  const commentView = (dataTicket) => {
    let data = {
      ticket_id: dataTicket[0].ticket_id,
    }
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/comment/commentView',
      method: "POST",
      data: data,
      headers: { 'Authorization': AuthStr, }
    }))
      .then(() => { unRead() })
  }

  const onChangePagination = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };

  const commentsGrid = () => {
    let list = [];
    if ((dataTicket !== null) && (dataTicket[0].comments.length > 0)) {
      dataTicket[0].comments.forEach((element, index) => {
        list.push(
          <Grid key={index} sx={{ display: "flex", alignItems: 'center', justifyContent: "space-evenly", background: "#EDEDED", borderRadius: "19px", width: "90%", flexDirection: "column", marginBottom: "20px" }}>
            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "flex-start", width: '95%', flexDirection: dataTicket[0].comments[index].user.user_type_id === 3 ? "row-reverse" : "row" }}>
              <Grid sx={{ width: { xs: "40px", sm: "70px" }, height: { xs: "40px", sm: "70px" }, margin: "0 10px", borderRadius: "50px", backgroundColor: "#FFFFFF", display: 'flex', alignItems: "center", justifyContent: 'center' }} >
                <Grid sx={{
                  width: { xs: "40px", sm: "65px" }, height: { xs: "30px", sm: "55px" }, margin: "0 10px", borderRadius: "50px",
                  background: `url(${dataTicket[0].comments[index].user.user_type_id === 1 || dataTicket[0].comments[index].user.user_type_id === 2 ?
                    dataTicket[0].comments[index].user.picture_path !== null ? dataTicket[0].comments[index].user.picture_path : avatar :
                    dataTicket[0].comments[index].user.user_type_id === 3 ? dataTicket[0].comments[index].user.picture_path !== null ? dataTicket[0].comments[index].user.picture_path : logo : ""})`, backgroundPosition: "center", backgroundSize: "100% 100%",
                }} ></Grid>
              </Grid>
              <Grid sx={{ display: 'flex', alignItems: "flex-start", justifyContent: "space-evenly", flexDirection: 'column', padding: "0 5px", minHeight: { xs: "81px", md: "115px" } }}>
                <Typography sx={{ fontSize: { xs: '12px', sm: "16px" }, color: "#070707", fontWeight: '700' }}>
                  {
                    dataTicket[0].comments[index].user.user_type_id === 1 ? dataTicket[0].comments[index].user.name === null ? "" : dataTicket[0].comments[index].user.name + " " + dataTicket[0].comments[index].user.lastname :
                      dataTicket[0].comments[index].user.user_type_id === 2 ? dataTicket[0].comments[index].user.organ_manager_name === null ? "" : dataTicket[0].comments[index].user.organ_manager_name :
                        dataTicket[0].comments[index].user.user_type_id === 3 ? t("support") : ""
                  }
                </Typography>
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "flex-start", width: '95%', margin: "10px 0  20px 0" }}>
              <Typography sx={{ fontSize: { xs: "10px", sm: '12px', md: '16px' }, color: "#070707", textAlign: "justify" }}>
                {dataTicket[0].comments[index].comment}
              </Typography>
            </Grid>
          </Grid>
        )
      })
    }
    return list;
  }

  const handleOpenModal = () => {
    setOpenModal(true)
    const timeout = setTimeout(() => {
      handleCloseModal();
    }, 3000)
    return () => {
      clearTimeout(timeout)
    };
  };

  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModalTicket = () => setOpenModalTicket(true);
  const handleCloseModalTicket = () => setOpenModalTicket(false);
  return (
    <>
      <Grid sx={{ display: 'flex', width: "100%", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: 'flex-start' }}>
        <Grid sx={{ display: 'flex', alignItems: "flex-start", marginTop: { xs: "0", md: "20px" }, flexDirection: "column", width: { xs: "100%", md: "90%" } }}>
          <Tabs
            value={value}
            onChange={handleChange}
            orientation={TabSize}
            sx={{
              width: "100%", minHeight: "45px !important",
              '& .Mui-selected': {
                color: "#000000 !important", border: "none", background: "#FFFFFF !important"
              }
              , '& .MuiTabs-indicator': { backgroundColor: "transparent" }
              , "& .MuiTab-root": {
                fontSize: { xs: "11px", sm: "16px", md: "18px" }, fontWeight: "700", width: { xs: "50%", sm: "160px" }, color: "#000000 !important",
                background: "transparent", borderRadius: "6px 6px 0 0", height: { xs: "40px", sm: "70px" }
              }, "& .MuiTabs-flexContainer": { flexDirection: "row" },
            }}
            aria-label="visible arrows tabs example"
          >
            <Tab value="Tickets" label={t("Tickets")} />
            <Tab value="sendTicket" label={t("sendTicket")} sx={{ display: infoUser.user_type_id === 3 ? "none" : "flex" }} />
          </Tabs>
          <Grid sx={{ display: "flex", alignItems: "center", flexDirection: "column", width: "100%", height: "100%", background: "#FFFFFF", borderRadius: "0 0 6px" }}>
            {
              value === "sendTicket" ?
                <Grid sx={{ display: "flex", width: { xs: "95%", sm: "100%" }, flexDirection: "column", alignItems: "center", justifyContent: 'flex-start' }}>
                  <Grid sx={{ display: 'flex', alignItems: "center", marginTop: { xs: "0", md: "40px" }, justifyContent: "space-between", flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
                    <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                      <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "10px 5px 5px", md: "5px 20px" } }}>{t("title")}</Typography>
                      <TextField label={t("title")}
                        className="textField"
                        value={title}
                        onChange={(e) => { setTitle(e.target.value) }}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                        size={textFieldSize}
                        sx={{
                          width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                        }}
                      />
                    </Grid>
                    <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                      <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "10px 5px 5px", md: "5px 20px" } }}>{t("category")}</Typography>
                      <FormControl sx={{ m: 1, width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" } }}
                        size={textFieldSize}>
                        <Select
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                          sx={{ "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important" } }}
                        >
                          {
                            dataCategori !== null ?
                              dataCategori.map(element => (
                                <MenuItem key={element.id} value={element.id} className="menuItem">{element.name_fa}</MenuItem>
                              ))
                              :
                              null
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid sx={{ display: "flex", flexDirection: "column", width: { xs: "95%", md: "90%" }, alignItems: 'flex-start', marginTop: { xs: "0", md: "3%" }, justifyContent: 'center', marginBottom: { xs: "5%", sm: "0" } }}>
                    <Grid sx={{ display: "flex", alignItems: 'center', padding: { xs: "10px 5px 5px", md: "5px 20px" } }}>
                      <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707" }}>{t("text")}</Typography>
                    </Grid>
                    <TextField label={t("text")}
                      className="textField"
                      variant='outlined'
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                      fullWidth
                      multiline
                      rows={15}
                      sx={{
                        width: "100%", background: "#FFFFFF",
                        boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                      }}
                    />
                  </Grid>
                  <Grid sx={{ display: "flex", flexDirection: "column", margin: { xs: "20px 0", sm: "40px 0" }, width: { xs: "95%", md: "90%" }, alignItems: 'center', justifyContent: 'center' }}>
                    <Button onClick={sendHandeler} disabled={btnSend ? true : false} sx={{
                      background: "#FF3951", boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", color: "#FFFFFF", fontSize: { xs: "12px", sm: "16px", md: "18px" },
                      width: { xs: "100px", sm: "159px", md: "193px" }, height: { xs: '33px', sm: '50px', md: "60px" }, "&:hover": { backgroundColor: "#FF3951" }, textTransform: "lowercase"
                    }}>
                      {btnSend === true && loadingSend === true ? <CircularProgress size={20} color="inherit" sx={{ color: "white" }} /> : t("sendMessage")}
                    </Button>
                  </Grid>
                </Grid>
                :
                <Grid sx={{ display: "flex", width: { xs: "95%", sm: "100%" }, flexDirection: "column", alignItems: "center", justifyContent: 'flex-start', height: { xs: "444px", sm: "760px", lg: "850px" } }}>
                  <Grid sx={{
                    display: "flex", alignItems: "center", flexDirection: 'row', background: "#EAEAEA", borderRadius: "21px",
                    width: "95%", minHeight: { xs: "60px", md: "67px" }, margin: { xs: "20px 0 0 0", md: "40px 0 20px 0" }, justifyContent: "space-between"
                  }}>
                    {head()}
                  </Grid>
                  <Grid sx={{
                    display: "flex", alignItems: "center", flexDirection: "column", overflow: "scroll", width: "100%", minHeight: { xs: "150px", md: "530px", lg: "625px" }
                  }}>
                    {
                      dataGrid()
                    }
                  </Grid>
                  {ticket !== null && ticket.length > numberShow ?
                    <Grid sx={{ margin: "10px 0 20px 0", display: "flex", alignItems: "flex-end", justifyContent: "space-between", height: "50%", width: { xs: "98%", sm: "90%" } }}>
                      <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "flex-start", width: "50%" }}>
                        <Stack sx={{ direction: "ltr" }}>
                          <Pagination
                            sx={{
                              "& .MuiPaginationItem-root": { color: "#000000", border: "none", borderRadius: "50px", backgroundColor: "#EAEAEA", width: "10px !important" }, "& .MuiPaginationItem-icon": { color: "#000000", fontSize: { xs: "15px", md: "24px" } },
                              "& .MuiPaginationItem-previousNext": { background: "#EAEAEA", borderRadius: "50px", width: "10px !important" },
                            }}
                            count={Math.ceil(ticket.length / numberShow)}
                            variant="outlined"
                            shape="rounded"
                            size={textFieldSize}
                            onChange={onChangePagination}
                          />
                        </Stack>
                      </Grid>
                      <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: 'flex-end', flexDirection: "row", width: "50%" }}>
                        <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, color: "#000000", padding: i18n.language === "fa" ? "0 0 0 3px" : "0 0px 0 0" }}>{t("numberShow")}</Typography>
                        <FormControl sx={{ width: { xs: "56px", sm: "70px" }, background: "#EAEAEA !important", borderRadius: "10px", margin: { xs: "0", md: "0px 10px" } }}>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={numberShow}
                            onChange={(e) => setNumberShow(e.target.value)}

                            sx={{
                              "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important", }
                              , "& .MuiMenuItem-root": { justifyContent: "flex-end" }, "& .MuiOutlinedInput-input": { padding: { xs: "0px 5px !important", sm: "5px 10px !important" } }, '.MuiOutlinedInput-notchedOutline': { border: "0 !important" }
                            }}
                          >
                            <MenuItem value={25} className="menuItemTicket">25</MenuItem>
                            <MenuItem value={50} className="menuItemTicket">50</MenuItem>
                            <MenuItem value={100} className="menuItemTicket">100</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    :
                    <Grid sx={{ margin: "10px 0 20px 0", display: "flex", alignItems: "flex-end", justifyContent: "flex-end", height: "50%", width: { xs: "98%", sm: "90%" } }}>
                      <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: 'flex-end', flexDirection: "row", width: "50%" }}>
                        <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, color: "#000000", padding: i18n.language === "fa" ? "0 0 0 3px" : "0 0px 0 0" }}>{t("numberShow")}</Typography>
                        <FormControl sx={{ width: { xs: "56px", sm: "70px" }, background: "#EAEAEA !important", borderRadius: "10px", margin: { xs: "0", md: "0px 10px" } }}>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={numberShow}
                            onChange={(e) => setNumberShow(e.target.value)}

                            sx={{
                              "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important", }
                              , "& .MuiMenuItem-root": { justifyContent: "flex-end" }, "& .MuiOutlinedInput-input": { padding: { xs: "0px 5px !important", sm: "5px 10px !important" } }, '.MuiOutlinedInput-notchedOutline': { border: "0 !important" }
                            }}
                          >
                            <MenuItem value={25} className="menuItemTicket">25</MenuItem>
                            <MenuItem value={50} className="menuItemTicket">50</MenuItem>
                            <MenuItem value={100} className="menuItemTicket">100</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  }
                </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid id="modal-ticket" onClick={(e) => clickOtherLocation(e)} sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh", backgroundColor: 'rgba(105,99,99,0.6)', direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
          <Grid sx={{
            display: "flex", alignItems: "center", justifyContent: "space-evenly", flexDirection: 'column', background: "#FFFFFF", width: { xs: "95%", sm: "556px" },
            borderRadius: "12px", height: "284px"
          }}>
            <Typography sx={{ fontSize: { xs: "14px", sm: "22px" }, color: "#000000" }}>{t("textMessage1")}</Typography>
            <Typography sx={{ fontSize: { xs: "14px", sm: "22px" }, color: "#000000" }}>{t("textMessage2")}</Typography>
            <Typography component={"img"} src={tikcetSend} alt="send" />
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openModalTicket}
        onClose={handleCloseModalTicket}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid id="modal-ticket" onClick={(e) => clickOtherLocation(e)} sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh", backgroundColor: 'rgba(105,99,99,0.6)', direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
          <Grid sx={{
            display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: 'column', background: "#FFFFFF", width: { xs: "95%", md: "70%", lg: "50%" },
            borderRadius: "24px", maxHeight: "90%", overflow: "scroll"
          }}>
            <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: 'flex-start', width: "90%", margin: { xs: "10px 0", md: "30px 0" } }}>
              <CloseRoundedIcon onClick={handleCloseModalTicket} sx={{ fontSize: "30px", cursor: "pointer" }} />
            </Grid>
            <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "space-evenly", background: "#EDEDED", borderRadius: "19px", width: "90%", flexDirection: "column", marginBottom: "20px" }}>
              <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "flex-start", width: '95%' }}>
                <Grid
                  sx={{
                    width: { xs: "40px", sm: "70px" }, height: { xs: "40px", sm: "70px" }, margin: "0 10px", borderRadius: "50px", backgroundColor: "#FFFFFF", display: 'flex',
                    alignItems: "center", justifyContent: 'center'
                  }} >
                  <Grid
                    sx={{
                      width: { xs: "40px", sm: "70px" }, height: { xs: "30px", sm: "60px" }, margin: "0 10px", borderRadius: "50px"
                      , background: `url(${dataTicket !== null && dataTicket[0].user.picture_path !== null ? dataTicket[0].user.picture_path : avatar})`, backgroundPosition: "center", backgroundSize: "100% 100%",
                    }} ></Grid>
                </Grid>
                <Grid sx={{ display: 'flex', alignItems: "flex-start", justifyContent: "space-evenly", flexDirection: 'column', padding: "0 5px", minHeight: { xs: "81px", md: "115px" } }}>
                  <Typography sx={{ fontSize: { xs: '12px', sm: "16px" }, color: "#070707", fontWeight: '700' }}>
                    {dataTicket !== null && dataTicket[0].user.user_type_id === 1 ? dataTicket[0].user.name === null ? "" : dataTicket[0].user.name + " " + dataTicket[0].user.lastname :
                      dataTicket !== null && dataTicket[0].user.user_type_id === 2 ? dataTicket[0].user.organ_manager_name === null ? "" : dataTicket[0].user.organ_manager_name : ""
                    }
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "flex-start", width: '95%', marginBottom: "20px" }}>
                <Typography sx={{ fontSize: { xs: "10px", sm: '12px', md: '16px' }, color: "#070707", textAlign: "justify" }}>
                  {dataTicket !== null && dataTicket[0].message}
                </Typography>
              </Grid>
            </Grid>
            {
              commentsGrid()
            }
            <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "space-evenly", background: "#EDEDED", borderRadius: "19px", width: "90%", flexDirection: "column" }}>
              <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: infoUser.user_type_id === 3 ? "flex-end" : "flex-start", width: '95%' }}>
                <Grid
                  sx={{
                    width: { xs: "40px", sm: "70px" }, height: { xs: "40px", sm: "70px" }, margin: "0 10px", borderRadius: "50px", backgroundColor: "#FFFFFF", display: 'flex',
                    alignItems: "center", justifyContent: 'center'
                  }} >
                  <Grid
                    sx={{
                      width: { xs: "40px", sm: "70px" }, height: { xs: "30px", sm: "60px" }, margin: "0 10px", borderRadius: "50px"
                      , background: `url(${infoUser.picture_path !== null ? infoUser.picture_path : infoUser.user_type_id === 3 ? logo : avatar})`, backgroundPosition: "center", backgroundSize: "100% 100%",
                    }} ></Grid>
                </Grid>
                <Grid sx={{ display: 'flex', alignItems: "flex-start", justifyContent: "space-evenly", flexDirection: 'column', padding: "0 5px", minHeight: { xs: "81px", md: "115px" } }}>
                  <Typography sx={{ fontSize: { xs: '12px', sm: "16px" }, color: "#070707", fontWeight: '700' }}>
                    {infoUser.user_type_id === 1 ? infoUser.name === null ? "" : infoUser.name + " " + infoUser.lastname :
                      infoUser.user_type_id === 2 ? infoUser.organ_manager_name === null ? "" : infoUser.organ_manager_name : ""
                    }
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: "flex-start", width: '90%', flexDirection: 'column', marginBottom: "30px" }}>
                <TextField label={t("text")}
                  className="textField"
                  variant='outlined'
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  fullWidth
                  multiline
                  rows={5}
                  sx={{
                    width: "100%", background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column", margin: { xs: "20px 0 20px 0", sm: "20px 0 20px 0" }, width: { xs: "95%", md: "90%" }, alignItems: 'center', justifyContent: 'center' }}>
              <Button onClick={() => sendComment()} disabled={btnComment ? true : false} sx={{
                background: "#FF3951", boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", color: "#FFFFFF", fontSize: { xs: "12px", sm: "16px", md: "18px" },
                width: { xs: "100px", sm: "159px", md: "193px" }, height: { xs: '33px', sm: '50px', md: "60px" }, "&:hover": { backgroundColor: "#FF3951" }, textTransform: "lowercase"
              }}>
                {btnComment === true && commentLoading === true ? <CircularProgress size={20} color="inherit" sx={{ color: "white" }} /> : t("sendMessage")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
    </>
  )
}

export default Ticket;