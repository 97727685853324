import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import arrowRightPurple from '../image/icon/arrowRightPurple.png';
import arrowLeftPurple from '../image/icon/arrowLeftPurple.png';
import { useTranslation } from "react-i18next";
import homeinja from '../image/workSample/homeinja.png';
import cryptoFa from '../image/workSample/cryptoFa.png';
import cryptoEn from '../image/workSample/cryptoEn.png';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import "swiper/css";
import 'swiper/css/pagination';
import '../styles/WorkSample.css';
import { NavLink } from "react-router-dom";

SwiperCore.use([Pagination, Autoplay]);
const WorkSample = () => {
  const { t, i18n } = useTranslation();

  const sample = [
    {
      id: "01",
      title: "RealEstateEebsiteDesign",
      desc1: "sampleLandingHomeinja1",
      desc2: "sampleLandingHomeinja2",
      site: "Homeinja.com",
      href: "https://homeinja.com/",
      image: homeinja,
      color: "#14C9E8"
    },
    {
      id: "02",
      title: "digitalCurrencyExchangeDesign",
      desc1: "digitalCurrencyExchangeDesignDesc1",
      site: "Mycryptocommerce.com",
      href: "https://mycryptocommerce.com/",
      image: i18n.language === "fa" ? cryptoFa : cryptoEn,
      color: "#7CC2C7"
    }
  ]

  return (
    <>
      <Grid sx={{ display: "flex", width: "100%", justifyContent: "center", flexDirection: 'column', alignItems: "center", marginTop: { xs: "30px", sm: "60px", md: "100px" }, direction: "rtl" }}>
        <Grid sx={{ display: "flex", width: { xs: "95%", md: "100%", lg: "80%" }, flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative" }}>
          <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: { xs: "20px", sm: "35px", md: "50px" }, width: "100%" }}>
            <Typography component={"img"} alt="icon" src={arrowRightPurple} sx={{ width: { xs: "14px", sm: "39px", md: "40px", lg: "54px" }, height: { xs: "17px", sm: "53px", md: "55px", lg: "69px" }, margin: "0 3px" }} />
            <Typography component={"p"} sx={{ fontWeight: "bold", fontSize: { xs: "12px", sm: "24px", md: "32px" } }}>{t("WorkSampleTitle")}</Typography>
            <Typography component={"img"} alt="icon" src={arrowLeftPurple} sx={{ width: { xs: "14px", sm: "39px", md: "40px", lg: "54px" }, height: { xs: "17px", sm: "53px", md: "55px", lg: "69px" }, margin: "0 3px" }} />
          </Grid>
          <Swiper
            style={{
              "--swiper-pagination-color": '#FF3951',
            }}
            modules={[Pagination]}
            autoplay={{ delay: 5000, disableOnInteraction: false, reverseDirection: true, waitForTransition: true }}
            className="mySwiper"
            pagination={{
              clickable: true,
              slidesPerView: 1,
              spaceBetween: 10,
            }}
            speed={1200}
            loop={true}
            centeredSlides={true}
            roundLengths={true}
            mousewheel={true}
            grabCursor={true}
          >
            {sample.map((item) => (
              <SwiperSlide key={item.id}>
                <Grid sx={{ display: "flex", justifyContent: { xs: "space-between", md: "center" }, alignItems: "center", flexDirection: "column", width: "100%", }}>
                  <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <Typography component={"img"} alt="sample Image" src={item.image} sx={{ width: "80%" }} />
                  </Grid>
                  <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "flex-start", flexDirection: "column", width: "100%", marginTop: "20px" }}>
                    <Typography component={"p"} sx={{ color: "#FF3951", textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", fontSize: { xs: "12px", sm: "20px", md: "24px" }, fontWeight: "700", width: "80%", marginBottom: "10px" }}>{t(item.title)}</Typography>
                    <Typography component={"p"} sx={{ fontSize: { xs: "10px", sm: "14px", md: "18px" }, color: "#070707", textAlign: "center", width: "80%", marginBottom: "5px" }}>{t(item.desc1)}</Typography>
                    <Typography component={"p"} sx={{ fontSize: { xs: "10px", sm: "14px", md: "18px" }, color: "#070707", textAlign: "center", width: "80%", marginBottom: "5px" }}>{t(item.desc2)}</Typography>
                    <Typography component={"a"} href={item.href} target="_blank" sx={{ fontSize: { xs: "10px", sm: "14px", md: "18px" }, textDecoration: "none", color: item.color, textAlign: "center", width: "80%", marginBottom: "50px" }}>{item.site}</Typography>
                  </Grid>
                </Grid>
              </SwiperSlide>
            ))}
          </Swiper>
          <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", marginTop: "10px" }}>
            <Button sx={{ display: "flex", bgcolor: "#FF3951", width: { xs: "158px", sm: "300px" }, height: { xs: "40px", sm: "50px" }, boxShadow: "#FF3951 0px 5px 15px", margin: "20px auto", "&:hover": { backgroundColor: "#FF3951" } }}>
              <NavLink to={"/Portfolio"} style={{ textDecoration: "none" }} target="_blank">
                <Typography component={"span"} sx={{ color: "white", fontSize: { xs: "12px", sm: "14px", md: "14px" } }}>{t("viewPortfolio")}</Typography>
              </NavLink>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default WorkSample