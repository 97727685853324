import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Typography, FormControl, Select, MenuItem, Button, CircularProgress, FormGroup, FormControlLabel, Checkbox, Tooltip, IconButton } from "@mui/material";
import axios from "axios";
import { baseUrl } from "./modules/general";
import AlertMessage from "./modules/AlertMessage";
import { separator, onlyFloatnaumber } from "./modules/general";
import { setCanonicalTag } from "./modules/general";
import Loader from "./Loader";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const OrderApplication = () => {

  const { t, i18n } = useTranslation();
  const [textFieldSize, setTextFiledSize] = useState(window.innerWidth <= 900 ? "small" : "medium");
  const [alert, setAlert] = useState({ alertCheck: false, message: "", severity: "" });
  const [appName, setAppName] = useState("")
  const [siteName, setSiteName] = useState("");
  const [siteAddress, setSiteAddresss] = useState("");
  const [activity, setActivity] = useState("");
  const [similarApp, setSimilarApp] = useState("");
  const [specialFeatures, setSpecialFeatures] = useState("");
  const [goalCustomer, setGoalCustomer] = useState("");
  const [minimumBudget, setMinimumBudget] = useState("");
  const [maximumBudget, setMaximumBudget] = useState("");
  const [description, setDescription] = useState("");
  const [btnSubmit, setBtnSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataActivity, setDataActivity] = useState(null);
  const [android, setAndroid] = useState(0);
  const [pwa, setPwa] = useState(0);
  const [ios, setIos] = useState(0);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    document.title = "سفارش طراحی اپلیکیشن موبایل | ساخت اپلیکیشن تلفن همراه | وب گستران سورین";
    document.getElementById("main-heading").innerHTML = "سفارش طراحی اپلیکیشن موبایل | ساخت اپلیکیشن تلفن همراه | وب گستران سورین";
    document.getElementsByTagName('meta')["description"].content = "شما درحال ثبت سفارش طراحی اپلیکیشن موبایل در بهترین سایت طراحی اپلیکیشن در ایران هستید. شما در صفحه مربوط به ثبت درخواست طراحی و ساخت اپلیکیشن تلفن همراه هستید و میتوانید درخواست هایی نظیر طراحی اپلیکیشن IOS، طراحی اپلیکیشن android، طراحی وب اپلیکیشن پیش رونده PWA، طراحی اپلیکیشن ترکیبی hybrid، طراحی اپلیکیشن بومی native را  داشته باشید.";
    document.getElementsByTagName('meta')["keywords"].content = "درخواست طراحی اپلیکیشن گوشی همراه | سفارش طراحی وب اپلیکیشن | سفارش طراحی اپلیکیشن اندروید | سفارش طراحی اپلیکیشن آی او اس اپل | طراحی اپلیکیشن حرفه ای | بهترین شرکت طراحی اپلیکیشن موبایل در ایران";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    setInfo(JSON.parse(localStorage.getItem('sorinInfoUser')));
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => resizeTextFiled());
    return (() => window.removeEventListener('resize', () => resizeTextFiled()));
  }, [window.innerWidth]);

  useEffect(() => {
    getActivity();
  }, [])

  const getActivity = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/activity/get`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataActivity(res.data.data)
      })
  }

  const setInput = (setter) => (event) => {
    setter(event.target.value);
  }

  const setCheckbox = (setter) => (event) => {
    setter(event.target.value);
  }

  const submitHandeler = () => {
    let data;
    if (appName === "") {
      setAlert({ alertCheck: true, message: t("AM_notNameApp"), severity: "error" })
    }
    else if ((pwa === 0) && (ios === 0) && (android === 0)) {
      setAlert({ alertCheck: true, message: t("AM_notOperationg"), severity: "error" })
    }
    else if ((minimumBudget !== "") && (+minimumBudget < 500000)) {
      setAlert({ alertCheck: true, message: t("alert_minimumBudget"), severity: "error" })
    }
    else if ((maximumBudget !== "") && (+maximumBudget > 400000000000)) {
      setAlert({ alertCheck: true, message: t("alert_maximumBudget"), severity: "error" })
    }
    else if ((maximumBudget !== "") && (minimumBudget !== "") && (+minimumBudget > +maximumBudget)) {
      setAlert({ alertCheck: true, message: t("alert_minimumBigerMaximum"), severity: "error" })
    }
    else {
      data = {
        app_name: appName,
        site_name: siteName,
        site_address: siteAddress,
        activity_type_id: activity,
        goal_customer: goalCustomer,
        minimum_budget: minimumBudget,
        maximum_budget: maximumBudget,
        special_features: specialFeatures,
        similar_apps: similarApp,
        android: android,
        ios: ios,
        pwa: pwa,
        description: description
      }
      submitData(data);
    }
  }

  const submitData = (data) => {
    setLoading(true)
    setBtnSubmit(true)
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/app/create',
      method: "POST",
      data: data,
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setAlert({ alertCheck: true, message: t("alert_dataSuccess"), severity: "success" })
        setBtnSubmit(false)
        setLoading(false)
        setAppName(""); setSiteName(""); setSiteAddresss(""); setActivity(""); setSimilarApp(""); setSpecialFeatures(""); setGoalCustomer(""); setMinimumBudget(""); setMaximumBudget("");
        setDescription(""); setAndroid(0); setPwa(0); setIos(0);
      })
      .catch(
        (error) => {
          const { status } = error.response;
          switch (status) {
            case (500):
              setAlert({ alertCheck: true, message: t("AM_intervalServer"), severity: "error" })
              break;
            case (400):
              setAlert({ alertCheck: true, message: t("AM_BadRequest"), severity: "error" })
              break;
            default:
              setAlert({ alertCheck: true, message: t("alert_defualt"), severity: "error" })
          }
          setBtnSubmit(false)
          setLoading(false)
        }
      )
  }
  const resizeTextFiled = () => {
    if (window.innerWidth < 900 && textFieldSize === 'medium') {
      setTextFiledSize('small')
    }
    else if (window.innerWidth > 900 && textFieldSize === 'small') {
      setTextFiledSize('medium')
    }
  }

  const maximumHandeler = (event) => {
    let value = onlyFloatnaumber(event.target.value);
    setMaximumBudget(value)
  }

  const minimumHandeler = (event) => {
    let value = onlyFloatnaumber(event.target.value);
    setMinimumBudget(value)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };

  return (
    info === null ?
      <Loader />
      :
      info.active === 0 ?
        <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "18px" }, margin: "20px" }}>{t("compeleteInfo")}</Typography>
        :
        <>
          <Grid sx={{ display: 'flex', width: "100%", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: 'center' }}>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", marginTop: { xs: "0", md: "2%" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("app_name")}</Typography>
                  <Tooltip title={t("tooltipAppName")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  value={appName}
                  onChange={setInput(setAppName)}
                  fullWidth
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("site_name")}</Typography>
                  <Tooltip title={t("tooltipSiteName")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  value={siteName}
                  onChange={setInput(setSiteName)}
                  fullWidth
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", marginTop: { xs: "0", md: "2%" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("site_address")}</Typography>
                  <Tooltip title={t("tooltipSiteAddress")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  value={siteAddress}
                  onChange={setInput(setSiteAddresss)}
                  fullWidth
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("activity")}</Typography>
                  <Tooltip title={t("tooltipActiviyApp")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <FormControl sx={{ m: 1, width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }, }}
                  size={textFieldSize}>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={activity}
                    onChange={setInput(setActivity)}
                    sx={{ "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important" } }}
                  >
                    {
                      dataActivity !== null ?
                        dataActivity.map(element => (
                          <MenuItem key={element.id} value={element.id} className="menuItem">{element.description}</MenuItem>
                        ))
                        :
                        null
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", marginTop: { xs: "0", md: "2%" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("special_features")}</Typography>
                  <Tooltip title={t("tooltipSpecialFeaturesApp")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  fullWidth
                  value={specialFeatures}
                  onChange={setInput(setSpecialFeatures)}
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("goal_customer")}</Typography>
                  <Tooltip title={t("tooltipSiteGoalCustomer")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  value={goalCustomer}
                  onChange={setInput(setGoalCustomer)}
                  fullWidth
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", marginTop: { xs: "0", md: "2%" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("minimum_budget")}</Typography>
                  <Tooltip title={t("tooltipSiteMinimumBudget")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  value={separator(minimumBudget)}
                  onChange={(event) => minimumHandeler(event)}
                  fullWidth
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("maximum_budget")}</Typography>
                  <Tooltip title={t("tooltipSiteMaximumBudget")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  fullWidth
                  value={separator(maximumBudget)}
                  onChange={(event) => maximumHandeler(event)}
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", marginTop: { xs: "0", md: "2%" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("similar_apps")}</Typography>
                  <Tooltip title={t("tooltipSimilarApp")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  value={similarApp}
                  onChange={setInput(setSimilarApp)}
                  fullWidth
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{
              display: "flex", flexDirection: "column", width: { xs: "95%", md: "90%" }, alignItems: 'flex-start', marginTop: { xs: "10px", md: "2%" }, justifyContent: 'center',

            }}>
              <Grid sx={{
                display: "flex", alignItems: 'center', flexDirection: { xs: "column", sm: "row" }, justifyContent: { xs: "center", sm: "unset" }, width: { xs: "95%", md: "100%" }, border: "1px solid #777373", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
                height: { xs: "71px", sm: '58px' }, borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
              }}>
                <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: { xs: "center", sm: "flex-start" }, padding: { xs: "0", sm: "0 5px", md: "0 3%" }, width: { xs: "100%", sm: "35%" } }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707" }}>{t("applicationOperatingSystem")}</Typography>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: 'center', flexDirection: 'row', justifyContent: { xs: "center", sm: "flex-start" }, width: { xs: "95%", sm: "65%" } }}>
                  <FormGroup sx={{ display: "flex", alignItems: 'center', flexDirection: 'row', justifyContent: "space-evenly", width: '100%' }}>
                    <FormControlLabel sx={{ "& .MuiTypography-root": { fontWeight: { xs: "400", sm: "600" }, fontSize: { xs: "12px", sm: "16px" } }, margin: "0 !important" }}
                      control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, '&.Mui-checked': { color: "#FF3951", } }} />} label="Android" value={1} onChange={setCheckbox(setAndroid)} />
                    <FormControlLabel sx={{ "& .MuiTypography-root": { fontWeight: { xs: "400", sm: "600" }, fontSize: { xs: "12px", sm: "16px" } }, margin: "0 !important" }}
                      control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, '&.Mui-checked': { color: "#FF3951", } }} />} label="PWA" value={1} onChange={setCheckbox(setPwa)} />
                    <FormControlLabel sx={{ "& .MuiTypography-root": { fontWeight: { xs: "400", sm: "600" }, fontSize: { xs: "12px", sm: "16px" } }, margin: "0 !important" }}
                      control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, '&.Mui-checked': { color: "#FF3951", } }} />} label="IOS" value={1} onChange={setCheckbox(setIos)} />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column", width: { xs: "95%", md: "90%" }, alignItems: 'flex-start', marginTop: { xs: "0", md: "2%" }, justifyContent: 'center', marginBottom: { xs: "5%", sm: "0" } }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("description")}</Typography>
                  <Tooltip title={t("tooltipActivityProgram")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              <TextField
                className="textField"
                variant='outlined'
                value={description}
                onChange={setInput(setDescription)}
                InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                fullWidth
                multiline
                rows={4}
                sx={{
                  width: "100%", background: "#FFFFFF",
                  boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}
              />
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column", marginTop: "40px", width: { xs: "95%", md: "90%" }, alignItems: 'center', justifyContent: 'center' }}>
              <Button onClick={submitHandeler} disabled={btnSubmit ? true : false} sx={{
                background: "#FF3951", boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", color: "#FFFFFF", fontSize: { xs: "12px", sm: "16px", md: "18px" },
                width: { xs: i18n.language === "fa" ? "100px" : "125px", sm: "159px", md: "193px" }, height: { xs: i18n.language === "fa" ? '33px' : "40px", sm: '50px', md: "60px" }, "&:hover": { backgroundColor: "#FF3951" }, textTransform: "lowercase"
              }}>
                {btnSubmit === true && loading === true ? <CircularProgress size={20} color="inherit" sx={{ color: "white" }} /> : t("submitInfo")}
              </Button>
            </Grid>
          </Grid >
          <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
        </>
  )
}
export default OrderApplication;