import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import {
  author,
  date,
  h1,
  headPhoto,
  largePhoto,
  linkArticle,
  rootArticle,
  tags,
  paragraph,
  timeRead,
  view,
  listItemCircle,
  h2,
  h4,
  smallPhoto,
  sourceLink,
} from "../articleFunction/ArticleFunction";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { Articles } from "./Articles";
import ArticleSide from "../articleFunction/ArticleSide";
import { NavLink } from "react-router-dom";
//import photo
import photo1 from "../../image/articles/14/photo1.jpeg";
import photo2 from "../../image/articles/14/photo2.jpg";
import photo3 from "../../image/articles/14/photo3.png";
import photo4 from "../../image/articles/14/photo4.png";
import photo5 from "../../image/articles/14/photo5.jpg";
import photo6 from "../../image/articles/14/photo6.png";
import photo7 from "../../image/articles/14/photo7.jpg";
import photo8 from "../../image/articles/14/photo8.jpg";

import Loader from "../Loader";
import PortfolioButton from "../PortfolioButton";

let articleData = null;
const Article14 = () => {
  //NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${
      articleData !== null && articleData.title
    }`;
    document.getElementById("main-heading").innerHTML =
      articleData !== null && articleData.h1;
    document.getElementsByTagName("meta")["description"].content =
      articleData !== null && articleData.desc;
    document.getElementsByTagName("meta")["keywords"].content =
      articleData !== null && articleData.keywords;
    document.getElementsByTagName("meta")["author"].content =
      articleData !== null && articleData.author;
    setCanonicalTag();
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item;
    });
  }, []);

  useEffect(() => {
    articleData !== null && addView();
    getView();
  }, []);

  const addView = () => {
    Promise.resolve(
      axios({
        url: baseUrl + "/api/v1/article/addView",
        method: "POST",
        data: { id: articleData.id },
      })
    ).then((res) => {});
  };
  const getView = () => {
    Promise.resolve(
      axios({
        url: `${baseUrl}/api/v1/article/getView`,
        method: "GET",
        params: { id: articleData.id },
      })
    ).then((res) => {
      setDataView(res.data.data);
    });
  };

  //change This part

  //read More
  const readMore = [
    {
      id: 1,
      title: "سئو و تمام نکاتی که باید در آن رعایت کرد",
      link: "/what-is-seo",
    },
    {
      id: 2,
      title: "ده زبان برنامه نویسی محبوب 2023",
      link: "/popular-programming-language-2023",
    },
    {
      id: 3,
      title: "نکاتی که باید برای سفارش طراحی وب سایت در نظر گرفت",
      link: "/Points_to_consider_when_ordering_a_website_design",
    },
    {
      id: 4,
      title: "سئو سایت چیست و چرا باید به سئو اهمیت دهیم",
      link: "/SEO-Importance",
    },
    {
      id: 5,
      title: "معرفی صفر تا صد زبان برنامه پایتون برای مبتدی ها",
      link: "/What-Is-Python-Programming-Language",
    },
    {
      id: 6,
      title: "معرفی صفر تا صد زبان جاوا اسکریپت برای مبتدی ها",
      link: "/What-Is-Javascript-Programming-Language",
    },
  ];
  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          direction: "rtl",
          alignItems: "center",
        }}
      >
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: { xs: "unset", sm: "flex-start" },
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            width: { xs: "100%", sm: "85%" },
          }}
        >
          <ArticleSide
            tags={articleData !== null && articleData.tags}
            readMore={readMore}
          />
          {articleData !== null ? (
            <Grid
              item
              xs={12}
              sm={7.5}
              md={8}
              lg={8.5}
              xl={9}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", sm: "flex-end" },
                flexDirection: "column",
                width: { xs: "100%", sm: "60%", md: "70%" },
              }}
            >
              {/* do not change */}
              {headPhoto(articleData.image)}
              {h1(articleData.h1, t)}
              {timeRead(articleData.timeRead, t)}
              {date(articleData.date, i18n)}
              <Grid
                sx={{
                  display: "flex",
                  alignItems: { xs: "center", sm: "flex-start" },
                  justifyContent: "flex-start",
                  width: { xs: "90%", sm: "100%" },
                  flexDirection: "column",
                }}
              >
                {/* The text of the article */}

                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  جیمز گاسلینگ زبان برنامه نویسی جاوا (
                  <Typography
                    component={"a"}
                    target="_blank"
                    href="https://www.java.com/"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    JAVA
                  </Typography>
                  ) را در 23 مه 1995 (برابر با 2 خرداد 1374) طراحی کرد. سایت
                  معتبر 
                  <Typography
                    component={"a"}
                    target="_blank"
                    href="https://www.tiobe.com/tiobe-index/java"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    Tiobe
                  </Typography>
                   جاوا را از سال 2001 همواره به عنوان اولین یا دومین زبان
                  برنامه‌نویسی دنیا مطرح کرده است.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  یکی از دلایل محبوبیت این زبان استفاده در{" "}
                  <NavLink
                    to="/Academy/Programming"
                    target="_blank"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    برنامه نویسی{" "}
                  </NavLink>
                  <NavLink
                    to="/Academy/Application"
                    target="_blank"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    اپلیکیشن{" "}
                  </NavLink>
                  های اندروید می باشد. بسیاری از افراد برای نوشتن برنامه‌های
                  اندرویدی به سراغ جاوا می‌روند. در ادامه بیشتر با تاریخچه و
                  نحوه‌ی شکل‌گیری این زبان آشنا می شویم.{" "}
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  تاریخچه زبان جاوا
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={photo1}
                      alt="جیمز گاسلینگ - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      جیمز گاسلینگ
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  در اوایل دهه‌ی 1990 میلادی آقای{" "}
                  <Typography
                    component={"a"}
                    target="_blank"
                    href="https://fa.wikipedia.org/wiki/%D8%AC%DB%8C%D9%85%D8%B2_%DA%AF%D8%A7%D8%B3%D9%84%DB%8C%D9%86%DA%AF"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    جیمز گاسلینگ{" "}
                  </Typography>
                  و همکارانش ب دلیلی پیچیدگی زبان ++C تصمیم گرفتند زبانی را
                  ابداع کنند که علاوه‌بر ساده بودن از قدرت زبان ++C نیز برخوردار
                  باشد. آن‌ها در ابتدا نام Oak به معنای “بلوط” را روی این زبان
                  گذاشتند. دلیل این امر هم وجود یک درخت بلوط در مجاورت اتاق کار
                  آقای گاسلینگ بود. پس از مدتی مدیران شرکت سان‌مایکروسیستمز
                  (شرکت گاسلینگ و همکارانش) تصمیم گرفتند نام مناسب‌تری را برای
                  این زبان انتخاب کنند.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  بدین منظور به یک کافی‌شاپ مراجعه کردند و حدود نصف روز را صرف
                  بحث و تبادل نظر در این خصوص کردند. در نهایت تصمیم گرفتند که از
                  مخفف نام‌های خودشان یعنی James Gosling و Arthur Van Hoff و
                  همچنین Andy Bechtolsheim برای نامگذاری استفاده کنند و در نهایت
                  نام Java را بر روی این زبان برگزیدند.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  جاوا چیست؟
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  جاوا زبانی چندمنظوره و شی‌گرا می باشد که شباهت زیادی به C و
                  ++C دارد. استفاده از جاوا از C و ++C آسان‌تر است و با آن می
                  توان برنامه‌هایی قدرتمندی ساخت. البته شرکت سان مایکروسیستمز در
                  سال 2000 تعریفی از جاوا ارائه داد که از تعریف فوق واضح تر می
                  باشد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                ساختار زبان JAVA به گونه‌ای است که برنامه نویسان می‌توانند بدون نگرانی از معماری کامپیوتر کدهای این زبان را در هر جایی بنویسند و آن را به راحتی در هر مکانی اجرا نمایند.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  جاوا زبان رایانه‌ای ساده، شی‌گرا، مناسب شبکه، تفسیرشدنی،
                  مستحکم، امن، فارغ از معماری، پرتابل است که عملکرد بالا، چندنخی
                  و دینامیک دارد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  در ادامه هر یک از این خصوصیات را به صورت جداگانه شرح می دهیم.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  سادگی زبان جاوا
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={photo2}
                      alt="سادگی جاوا - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      سادگی جاوا
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  در ابتدا جاوا با حذف بخشی از ویژگی های C و ++C مدلسازی شد. از
                  جمله این ویژگی‌ها می‌توان اشاره‌گرها، پیاده‌سازی چندباره وراثت
                  و بارگذاری بیش از حد عملگرها را نام برد. اما ویژگی هایی نیز در
                  جاوا قرار دارد که این ویژگی‌ها در C++/C وجود ندارند؛ مانند
                  امکان بازیافت حافظه (garbage-collection) که به طور خودکار اشیا
                  و آرایه‌های بی استفاده را حذف می‌کند و از ویژگی های اساسی جاوا
                  می باشد.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  شی گرا بودن زبان جاوا
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  تمرکز جاوا بر شی‌گرایی باعث شد که توسعه‌دهندگانی که این زبان
                  را به کار می گیرند، از آن ب جای رفع محدودیت های مسئله برای حل
                  یک مسئله استفاده کنند. یکی از دلایل تمایز بین جاوا و C همین
                  مسئله می باشد. برای مثال، چنانچه قصد نوشتن برنامه‌ای برای یک
                  حساب بانکی را دارید، در جاوا باید به ذخیره‌سازی شی حساب بانکی
                  بپردازید؛ اما در C باید برای وضعیت حساب و رفتارهایی مانند
                  واریز یا برداشت کد نویسی کنید.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  جاوا زبان مبتنی بر شبکه
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  کتابخانه وسیع شبکه در جاوا باعث سادگی کار با پروتکل کنترل
                  انتقال/پروتکل اینترنت (TCP/IP) و پروتکل‌های شبکه مانند HTTP
                  (پروتکل انتقال ابرمتن) و FTP (پروتکل انتقال فایل) شده است.
                  همچنین سبب آسان شدن ایجاد اتصال‌های شبکه شده است. به علاوه
                  برنامه‌های جاوا می‌توانند از طریق شبکه TCP/IP، از طریق URLها،
                  به اشیا دسترسی پیدا کنند و این دسترسی به سادگی دسترسی روی
                  سیستم فایل محلی می باشد.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  جاوا یک زبان تفسیر شدنی
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={photo3}
                      alt="جاوا زبان تفسیر شدنی - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      جاوا زبان تفسیر شدنی
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  برنامه جاوا به طور غیر مستقیم از طریق یک ماشین مجازی اجرا
                  می‌شود. این ماشین مجازی بایت‌کدها (دستورالعمل‌ها و داده‌های
                  مرتبط) را از طریق تفسیر به دستورالعمل‌های خاص پلتفرم ترجمه می‌
                  نماید. منظور از تفسیر، عمل شناسایی دستورالعمل‌ها و سپس انتخاب
                  دستورالعمل‌های خاص پلتفرم برای اجرا می باشد. سپس ماشین مجازی
                  این دستورالعمل‌های خاص پلتفرم را اجرا می‌کند. این ویژگی تفسیری
                  بودن جاوا باعث سهولت در خطایابی برنامه‌های جاوا می شود، چون
                  اغلب اطلاعات در زمان کامپایل در واقع در محیط اجرا هستند.
                  تفسیری بودن جاوا همچنین امکان به تأخیر انداختن پیوند بین قطعات
                  مختلف برنامه جاوا تا زمان اجرا را فراهم می سازد و این مسئله
                  افزایش سرعت توسعه برنامه را در پی خواهد داشت.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  استحکام زبان جاوا
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  برنامه‌های جاوا هم در اپلیکیشن‌های مصرفی و هم در مأموریت‌های
                  حیاتی استفاده می‌شوند. این برنامه ها از پخش‌کننده‌های بلوری تا
                  ناوبری خودرو یا سیستم‌های کنترل هوایی را شامل می‌شوند.
                  ویژگی‌های زبان جاوا که باعث مستحکم بودن آن می‌شوند، شامل
                  اعلان‌ها، بررسی دوباره نوع داده، یک بار در زمان کامپایل و بار
                  دیگر در زمان اجرا، آرایه‌های واقعی و کنار گذاشتن اشاره‌گرها می
                  باشند.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  امن بودن زبان جاوا
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  برنامه‌های جاوا در محیط‌هایی که شبکه‌بندی شده، مورد استفاده
                  قرار می‌گیرند. از آنجا که برنامه‌های جاوا قابلیت اجرا روی
                  پلتفرم‌های مختلف شبکه را دارند، بنابراین ایمن سازی این
                  پلتفرم‌ها در برابر کدهای مخرب که موجب گسترش ویروس‌ها، سرقت
                  اطلاعات کارت‌های بانکی یا اجرای اعمال خرابکارانه می‌شوند، امری
                  مهم می باشد. از ویژگی‌هایی که موجب استحکام زبان جاوا می‌شوند
                  می توان کنار گذاشتن اشاره‌گرها را نامید که به همراه ویژگی‌های
                  امنیتی مانند مدل امن sandbox جاوا و رمزنگاری کلید عمومی فعالیت
                  می‌کنند. این دو نوع ویژگی‌ با هم از تأثیر ویروس‌ها و دیگر
                  کدهای خطرناکی که روی پلتفرم‌های مشکوک است، جلوگیری می‌کنند.
                  زبان جاوا از لحاظ تئوریک امن می باشد؛ اما در عمل
                  آسیب‌پذیری‌های امنیتی مختلفی شناسایی شده است. بنابراین در
                  گذشته شرکت سان مایکروسیستمز و اکنون شرکت اوراکل، همواره اقدام
                  به انتشار آپدیت های امنیتی برای جاوا می‌کنند.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  جاوا زبان فارغ از معماری
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  شبکه‌ها، اتصال پلتفرم‌هایی با معماری مختلف ریزپردازنده‌ها و
                  سیستم‌های عامل را موجب می‌شوند. جاوا قادر نخواهد بود که
                  دستورالعمل‌های خاص پلتفرم را ایجاد کند و همچنین نباید انتظار
                  داشت که این دستورالعمل‌ها از سوی انواع پلتفرم‌هایی که بخشی از
                  شبکه هستند درک شود. در عوض جاوا دستورالعمل‌های بایت‌کد مستقل
                  از پلتفرم را ایجاد می‌کند که تفسیر آن برای هر پلتفرمی آسان می
                  باشد.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  جاوا یک زبان پرتابل
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={photo4}
                      alt="پرتابل بودن جاوا - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      پرتابل بودن جاوا
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  به دلیل عدم وابستگی جاوا به معماری، جاوا یک زبان پرتابل می
                  باشد. با این حال پرتابل بودن جاوا چیزی فراتر از مستقل بودن
                  دستورالعمل‌های بایت‌کدها از پلتفرم می باشد. همچنین
                  کتابخانه‌های جاوا به پرتابل بودن آن کمک می‌کنند. این
                  کتابخانه‌ها در موارد ضروری، انواع داده‌ ها را به روشی خاص
                  ارائه می دهند که تا حد امکان پرتابل، کد جاوا را به قابلیت‌های
                  خاص پلتفرم متصل سازد.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  جاوا زبانی با عملکرد بالا
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  تفسیری بودن جاوا سبب شده است که جاوا عملکرد بالایی داشته باشد.
                  این زبان برای اپلیکیشن‌های با عملکرد بالا از کامپایل درجا
                  (just-in-time) استفاده می‌کند. یعنی دستورالعمل‌های بایت‌کدی که
                  تفسیر شده اند را تحلیل می‌کند. در مرحله بعدی تفسیر این
                  دستورالعمل‌های بایت‌کد سبب اجرای همان دستورالعمل‌های خاص
                  پلتفرم می‌شود. به این ترتیب عملکرد نرم‌افزار ارتقا می یابد.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  جاوا یک زبان چند نخی (multithread)
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  جاوا برای بهبود عملکرد برنامه‌هایی که چندین کار را به یک باره
                  انجام می دهند، از مفهوم اجرای چند نخی استفاده می نماید. به
                  عنوان مثال برنامه‌ای که رابط گرافیکی کاربر (GUI) را مدیریت
                  می‌کند و در همین حال منتظر ورودی از یک اتصال شبکه می باشد، از
                  نخ (thread) دیگری به جای نخ GUI برای این انتظار استفاده
                  می‌کند. ابتکارهای همگام‌سازی جاوا به نخ‌ها این اجازه را می‌دهد
                  که داده‌ها را بدون هیچ تأثیر مخربی بین خود مبادله نمایند.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  جاوا یک زبان پویا (دینامیک) است
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  به دلیل ارتباط‌های متقابلی که بین کد برنامه و کتابخانه‌ها در
                  زمان اجرا به صورت دینامیک صورت می‌گیرد، نیازی به ایجاد لینک
                  صریح بین آن‌ها نیست. در نتیجه زمانی که یک برنامه یا یکی از
                  کتابخانه‌های آن تکامل پیدا می کند توسعه‌دهنده تنها می بایست
                  برنامه یا کتابخانه آپدیت شده را منتشر کند. با این که رفتار
                  دینامیک جاوا سبب شده است هنگام تغییر به کد کمتری نیاز باشد؛
                  اما این روش انتشار می‌تواند تداخل‌هایی ر ا ایجاد کند. برای
                  مثال توسعه‌دهنده امکان دارد یک نوع کلاس را از یک کتابخانه حذف
                  کند یا نام آن را تغییر بدهد. وقتی شرکتی، کتابخانه به روزرسانی
                  شده را منتشر کند، برنامه‌های موجود که به آن نوع کلاس وابسته
                  هستند دیگر کار نمی کنند. برای حل این مشکل جاوا از رابط
                  (interface type) پشتیبانی می‌نماید.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  با توجه به ویژگی‌های فوق متوجه می‌شویم که جاوا علاوه بر اینکه
                  نوعی زبان برنامه نویسی است، یک پلتفرم برنامه‌نویسی نیز به شمار
                  می‌آید. این پلتفرم از دو بخش مهم تشکیل می شود که شامل ماشین
                  مجازی جاوا و محیط اجرایی جاوا می باشد.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  نسخه های جاوا
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={photo5}
                      alt="ورژن های جاوا - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      ورژن های جاوا
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  جاوا دارای سه نسخه است. برنامه نویسان می توانند بر اساس نوع
                  برنامه ای که توسعه می دهند هر یک از این نسخه ها را یاد بگیرند.
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  Java Standard Edition
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  شامل کتابخانه های اصلی مانند java.lang ، java.util .
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  Java Enterprise Edition
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  شامل APIهای جاوا مانند JMS، EJB، JSPs/servlets .
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  Java Micro Edition
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  استفاده در تلفن های همراه، ست تاپ باکس ها (مبدل یا گیرنده
                  دیجیتال)، گوشی های دستی .
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  پرکاربردترین نسخه جاوا Java SE (Java Standard Edition) می باشد
                  که اکثر برنامه ها از این نسخه استفاده می کنند.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  تفاوت جاوا با جاوا اسکریپت
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  از لحاظ فنی هیچ رابطه ای بین جاوا و جاوا اسکریپت وجود ندارد و
                  این دو زبان کاملا مستقل و جدا از هم هستند. جاوا اسکریپت از سوی
                  نت اسکیپ (Netscape) در دهه 90 میلادی توسعه یافت. زمانی که نت
                  اسکیپ محبوبیت جاوا را دید و متوجه شد از LiveScript استفاده
                  نمی‌ شود نام این زبان را به جاوا اسکریپت تغییر داد. به این
                  ترتیب بود که جاوا اسکریپت از موج محبوبیت جاوا بهره‌مند شد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  تنها شباهتی که می توان برای این دو زبان بیان کرد این است که هر
                  دوی آن ها دستور زبانشان را از زبان برنامه‌نویسی C می گیرند. به
                  همین علت اگر آشنایی با زبان جاوا داشته باشید، در این صورت
                  یادگیری جاوا اسکریپت نیز آسان خواهد بود و بالعکس.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  فریمورک‌های جاوا
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={photo6}
                      alt="فریمورک های جاوا - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      فریمورک های جاوا
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  جاوا یک زبان برنامه‌نویسی چندمنظوره محسوب می‌شود و به همین
                  دلیل در محیط‌های متفاوتی استفاده می شود. زبان جاوا به منظور
                  استفاده روی پلتفرم‌های مختلف طراحی شده است و روی ماشین‌های
                  لینوکس، یونیکس باکس، مک، ویندوز یا حتی گوشی تلفن همراه اجرا می
                  شود.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  برای جاوا فریمورک‌های زیادی وجود دارند که با کمک ان ها می‌توان
                  اپلیکیشن‌های وب، موبایل، میکروسرویس و REST API هایی را پیاده
                  سازی کرد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  فریمورک‌های جاوا امکان تمرکز روی منطق تجاری اپلیکیشن ها را به
                  جای نوشتن کارکردهای ابتدایی مانند ایجاد اتصال به پایگاه داده
                  یا مدیریت خطاها فراهم می‌ کند. تمامی فریمورک‌ها از دستور زبان
                  یکسانی استفاده می‌نمایند. در ادامه به بررسی برخی از محبوب‌ترین
                  فریمورک‌های جاوا می پردازیم :
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  BLADE
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  BLADE فریمورک ساده اپلیکیشن با کمترین اثرات جانبی می باشد. این
                  فریمورک، سبک و با عملکرد بالا برای جاوا می باشد که امکان ساخت
                  اپلیکیشن‌های وب را به سرعت فراهم می‌آورد. فریمورک blade روی
                  سادگی و ظرافت تمرکز دارد.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  Dropwizard
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  یک فریمورک با عملکرد بالا می باشد که برای توسعه سریع سرویس‌های
                  وب RESTful استفاده می شود. این فریمورک به طور خاص برای ایجاد
                  میکرو سرویس‌های جاوا مناسب می باشد.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  فریمورک Dropwizard چند کتابخانه تثبیت شده جاوا را برای ارائه
                  پلتفرمی سریع و بدون مزاحمت گردآوری کرده است. 
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  Grails
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  Grails فریمورک وب اپلیکیشن می باشد که مبتنی بر زبان برنامه
                  نویسی Groovy است. فریمورک Grails یک زبان شی‌گرا برای پلتفرم
                  جاوا می باشد که برای بهبود بهره‌وری توسعه‌دهندگان طراحی شد.
                  دستور زبان آن با جاوا منطبق است و با بایت‌کد JVM (ماشین مجازی
                  جاوا) کامپایل می‌شود.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  GWT
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  GWT یک فریمورک وب عالی است که از سوی گوگل ایجاد می شود. در
                  واقع GWT رویای هر توسعه‌دهنده‌ای که می‌خواهد اپلیکیشن‌های جاوا
                  را برای وب بسازد برآورده می کند. در واقع امکان نوشتن کدهای سمت
                  کلاینت جاوا و توزیع آن به صورت جاوا اسکریپت روی مرورگر را
                  فراهم می‌کند.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  Hibernate
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  Hibernate یک فریمورک نگاشت شی -رابطه‌ای می باشد که به منظور
                  ارتباط بهتر بین زبان برنامه‌نویسی جاوا و سیستم‌های مدیریت
                  پایگاه داده رابطه‌ای (RDBMS) ارائه شده است.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  مزیت‌های جاوا
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={photo7}
                      alt="مزایای جاوا - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      مزایای جاوا
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  با توجه به موضوع که جاوا تنها زبانی نیست که برای توسعه
                  اپلیکیشن‌های اندرویدی به کار می رود؛ اما همچنان در عرصه برنامه
                  نویسی حرف اول را می زند. البته دلیل این محبوبیت را می توان
                  مزیت های جاوا دانست. در ادامه برخی از این مزیت ها را مورد
                  بررسی قرار می دهیم.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  برنامه‌نویسی شی‌گرا
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  جاوا امکان برنامه‌نویسی شی‌گرا (Object-oriented) را فراهم می
                  سازد. منظور از برنامه‌نویسی شی‌گرا نوعی از کدنویسی می باشد که
                  در آن نه تنها انواع داده و ساختمان داده قابل تعریف هستند؛ بلکه
                  مجموعه توابع مورد استفاده این داده‌ها نیز تعریف می‌شوند. بدین
                  ترتیب ساختمان داده تبدیل به یک شی می‌شود که می‌توان آن را برای
                  ایجاد روابطی بین شی‌های مختلف استفاده کرد.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  زبان سطح بالا
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  جاوا یک زبان سطح بالا است و شباهت بسیاری با زبان انسان دارد.
                  زبان‌های سطح بالا از کامپایلرها و مفسرها استفاده می کنند و
                  نوشتن و خواندن و همچنین نگه داری در زبان های سطح بالا راحت تر
                  است.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  استاندارد برای محاسبات سازمانی
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  بزرگ‌ترین مزیت جاوا، اپلیکیشن‌های سازمانی (Enterprise) هستند.
                  شروع این روند به دهه 90 میلادی باز می‌گردد که سازمان‌ها نیاز
                  به ابزارهای برنامه‌نویسی مستحکمی داشتند که از جنس C نباشند.
                  جاوا کتابخانه‌های زیادی را پشتیبانی می‌کند که بلوک‌های سازنده
                  هر سیستم سازمانی می باشند. این موضوع باعث شد تا توسعه‌دهندگان
                  هر کارکردی که یک شرکت لازم داشت را با استفاده از جاوا بنویسند.
                  جاوا زبانی است که در اغلب مدارس و دانشگاه‌ها به عنوان یک مقدمه
                  برای برنامه‌نویسی رایانه‌ای تدریس می شود. جدا از این،
                  قابلیت‌های یکپارچه شدن جاوا نیز بسیار وسیع است. همچنین نگهداری
                  از جاوا نسبتاً ارزان می باشد، زیرا وابسته به سخت‌افزار خاصی
                  نیست و روی سرورهایی از هر نوع قابلیت اجرا دارد.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  عدم وابستگی به پلتفرم
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  جاوا از تمامی سیستم‌های عامل رایج نظیر ویندوز، مک‌اواس، و
                  لینوکس از JVM پشتیبانی می‌کند. به عنوان مثال می توان برنامه
                  جاوا را روی ویندوز نوشت، سپس آن را به بایت‌کد تبدیل نمود و این
                  اپلیکیشن را روی هر پلتفرم دیگری که از ماشین مجازی جاوا (JVM)
                  پشتیبانی می‌کند، اجرا نمود.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  معایب برنامه‌نویسی جاوا
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  درست است ک جاوا قابلیت های زیادی در توسعه وب و برنامه نویسی
                  دار اما دارای معایبی نیز ممی باشد که در ذیل به آن می پردازیم.
                </Typography>
                <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    لایسنس پولی
                  </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  به تازگی شرکت اوراکل اعلام کرده است که از ابتدای سال 2019،
                  بابت استفاده از نسخه JAVE SE 8 در محیط‌های تجاری، کسب و کار یا
                  production باید هزینه پرداخت کرد. بنابراین باید برای دریافت
                  همه اصلاحیه‌های باگ و به‌روزرسانی‌ها بر اساس تعداد کاربران یا
                  تعداد پردازنده‌ها هزینه‌ پرداخت کنند.
                </Typography>
                <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                   عملکرد پایین
                  </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  تمامی زبان های سطح بالا به دلیل مسئله کامپایل و سطح تجرید به
                  یک ماشین مجازی با مشکل عملکرد پایین مواجه هستند. با این حال،
                  این نکته را تنها دلیل انتقاد عمومی از سرعت پایین جاوا نمی توان
                  دانست.
                </Typography>
                <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    کد طولانی و پیچیده
                  </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  منظور از طولانی بودن کد یعنی از کلمات زیادی در کد نویسی
                  استفاده می‌ شود. این موضوع خوانایی برنامه را کاهش می دهد و به
                  سادگی نمی توان کدها را اسکن کرد. زبان‌های سطح بالا به دلیل
                  تقلید از زبان انگلیسی شلوغی زیادی را در کدها به همراه دارند.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  کاربردهای جاوا
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "1100px",
                    margin: { xs: "10px auto", sm: "20px auto" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      borderRadius: {
                        xs:
                          i18n.language === "fa"
                            ? "100px 0px 8px 26px"
                            : "0px 100px 8px 26px",
                        md:
                          i18n.language === "fa"
                            ? "100px 0px 8px 48px"
                            : "0px 100px 8px 48px",
                      },
                      background:
                        "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)",
                      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",
                      width: { xs: "90%", sm: "100%", md: "90%" },
                      height: {
                        xs: "170px",
                        sm: "200px",
                        md: "280px",
                        lg: "400px",
                        xl: "550px",
                      },
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={photo8}
                      alt="اپلیکیشن اندروید - سورین"
                      sx={{
                        backgroundSize: "100% 100%",
                        borderRadius: {
                          xs:
                            i18n.language === "fa"
                              ? "50px 8px 0px 0px"
                              : "8px 50px 0px 0px",
                          md:
                            i18n.language === "fa"
                              ? "100px 8px 0px 0px"
                              : "8px 100px 0px 0px",
                        },
                        width: "100%",
                        minHeight: { xs: "80%", lg: "88%" },
                      }}
                    ></Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", sm: "10px", md: "14px" },
                        color: "#070707",
                        margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" },
                      }}
                    >
                      اپلیکیشن اندروید
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  زبان جاوا در کنسول های وب، رابط کاربری گرافیکی، برنامه های
                  کاربردی وب و موبایل، توسعه بازی، سیستم های جاسازی شده و برنامه
                  های دسکتاپ استفاده می شود. علاوه بر این جاوا برای توسعه نرم
                  افزار هم کارایی دارد. این زبان در رایانه ها و دستگاه های تلفن
                  همراه و حتی دستگاه های الکترونیکی مانند تلویزیون، تهویه مطبوع،
                  ماشین لباسشویی و غیره هم اجرا می شود. جاوا در توسعه
                  اپلیکیشن‌های اندرویدی، نرم‌افزارهای وب و دسکتاپ، بازی‌سازی و
                  دیگر موارد کارایی دارد. استفاده از جاوا گستره وسیعی دارد که در
                  ادامه برخی از کاربرد جاوا را لیست کرده‌ایم.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  اپلیکیشن های اندرویدی
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  با وجود رشد گسترده کاتلین (Kotlin)؛ جاوا همچنان زبان پیش‌فرض
                  برای اپلیکیشن‌های اندرویدی به حساب می آید که جمع زیادی از
                  توسعه‌دهندگان جاوا، به برنامه‌نویسان اندروید پیوستند. با این
                  که اندروید از Android SDK به جای JDK استفاده می‌کند؛ اما
                  همچنان با جاوا کدنویسی می شود.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  برنامه های مالی
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  جاوا یکی از پر تقاضاترین زبان های برنامه نویسی در بخش مالی
                  محسوب می‌شود. این زبان هم در سمت سرور و هم کلاینت برای ساخت
                  وب‌سایت‌های مطمئن، سریع و ساده استفاده می شود. از جاوا به
                  عنوان زبان برنامه‌نویسی مناسب برای شبیه‌سازی و مدلسازی داده‌ها
                  نیز استفاده می شود.
                </Typography>
                <Typography
                  component={"h3"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "19px", md: "27px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  اپلیکیشن های تجاری
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  از اپلیکیشن های محبوبی که با جاوا نوشته شده است می توان به
                  Murex اشاره داشت. Murex یک برنامه مدیریت بانکی برای اتصال
                  فرانت و بک‌اند می باشد.
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  ویژگی های جاوا
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  جاوا دارای ویژگی های بی شماری است که در زیر به برخی از این
                  ویژگی ها می پردازیم.
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  نحو ساده که درک و یادگیری آن ساده می باشد
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  قوی بودن زبان جاوا
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  حذف خطاهایی که در زبان های C و C++ رخ می دهند
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  اجرا شدن روی هر پلتفرمی به عنوان یک زبان قابل حمل
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  امن بودن زبان جاوا
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  انجام چندین وظیفه
                </Typography>
                <Typography
                  component={"li"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "start",
                    width: "100%",
                    fontWeight: "700",
                    margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" },
                    listStyleType: "disc",
                  }}
                >
                  جاوا به سرعت C و ++C نیست، اما عملکرد بالاتری نسبت به هر زبان
                  دیگری دارد
                </Typography>
                <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                  کلام آخر
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  تمامی زبان های برنامه نویسی دارای مقوله ای طولانی برای بحث و
                  بررسی هستند که جاوا نیز از این قاعده مستثنی نیست. اما تمامی
                  این مطالب در این مقاله نمی گنجد. بنابراین در مقالات بعدی بیشتر
                  به زبان جاوا می پردازیم.
                </Typography>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  درباره بهترین{" "}
                  <NavLink
                    to="/AboutSorin"
                    target="_blank"
                    sx={{
                      color: "#6A35FF",
                      fontSize: { xs: "10px", sm: "14px", md: "16px" },
                      textDecoration: "none",
                      borderBottom: "2px solid #6A35FF",
                    }}
                  >
                    شرکت{" "}
                  </NavLink>
                  برنامه نویسی درتهران بیشتر بدانی.
                </Typography>

                {/* do not change */}

                {author(articleData.author, t)}
                {tags(articleData.tags, t)}
                {linkArticle(t, url)}
                {sourceLink(articleData.source, t)}
                {view(dataview, t)}
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={7.5}
              md={8}
              lg={8.5}
              xl={9}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: { xs: "80%", sm: "60%", md: "70%" },
              }}
            >
              <Loader />
            </Grid>
          )}
        </Grid>
      </Grid>
      <PortfolioButton />
      <Footer />
    </>
  );
};
export default Article14;
