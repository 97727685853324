import { SvgIcon } from '@mui/material';
import React from 'react';
const SeoIcon = ({ color }) => {
  return (
    <SvgIcon component="object"  sx={{height:"30px"}}>
      <svg width="24" height="24" viewBox="0 0 22 18" fill="transparent" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.1835 1H1.8165C1.36556 1 1 1.36556 1 1.8165V16.1835C1 16.6344 1.36556 17 1.8165 17H20.1835C20.6344 17 21 16.6344 21 16.1835V1.8165C21 1.36556 20.6344 1 20.1835 1Z" stroke={color}  />
        <path d="M7 6.47403C6 5.00003 4.249 5.96904 4.3745 7.48404C4.5 9.00004 6.5 9.00003 6.6245 10.516C6.75 12.031 5 13 4 11.5255M12 6.00003H10V12.5H12M10 9.50004H12" stroke={color} />
        <path d="M18 7.5C18 6.67157 17.3284 6 16.5 6C15.6716 6 15 6.67157 15 7.5V11C15 11.8284 15.6716 12.5 16.5 12.5C17.3284 12.5 18 11.8284 18 11V7.5Z" stroke={color} />
      </svg>
    </SvgIcon>
  );
};
export default SeoIcon;