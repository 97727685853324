import { SvgIcon } from '@mui/material';
import React from 'react';
import "../../styles/Nav.css"
const SiteIcon = ({ color }) => {

  return (
    <SvgIcon className='iconSize'  component="object" sx={{ height: "30px" }}>
      <svg height="17" width="17" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0ZM1.11 9.68H3.62C3.66 10.59 3.787 11.494 4 12.38H1.84C1.43721 11.5309 1.18996 10.6164 1.11 9.68ZM9.68 4.28V1.19C10.6437 1.55622 11.4356 2.26962 11.9 3.19C12.105 3.537 12.286 3.898 12.44 4.27L9.68 4.28ZM12.9 5.63C13.132 6.513 13.27 7.418 13.31 8.33H9.68V5.63H12.9ZM8.32 1.19V4.28H5.56C5.71426 3.9077 5.89471 3.54679 6.1 3.2C6.56247 2.27585 7.35459 1.55866 8.32 1.19ZM8.32 5.63V8.33H4.7C4.74 7.418 4.878 6.513 5.11 5.63H8.32ZM3.62 8.32H1.11C1.18996 7.38359 1.43721 6.46912 1.84 5.62H4C3.78659 6.50565 3.65933 7.40985 3.62 8.32ZM4.7 9.68H8.32V12.38H5.11C4.87804 11.4971 4.7406 10.592 4.7 9.68ZM8.33 13.68V16.77C7.36632 16.4038 6.57445 15.6904 6.11 14.77C5.90471 14.4232 5.72426 14.0623 5.57 13.69L8.33 13.68ZM9.68 16.77V13.73H12.44C12.2857 14.1023 12.1053 14.4632 11.9 14.81C11.4356 15.7304 10.6437 16.4438 9.68 16.81V16.77ZM9.68 12.33V9.63H13.3C13.2594 10.542 13.122 11.4471 12.89 12.33H9.68ZM14.39 9.63H16.9C16.82 10.5664 16.5728 11.4809 16.17 12.33H14C14.21 11.46 14.337 10.573 14.38 9.68L14.39 9.63ZM14.39 8.28C14.3442 7.38631 14.2136 6.49899 14 5.63H16.16C16.563 6.48 16.81 7.394 16.89 8.33L14.39 8.28ZM15.39 4.28H13.6C13.2761 3.3703 12.8067 2.51923 12.21 1.76C13.4544 2.31858 14.5353 3.18605 15.35 4.28H15.39ZM5.79 1.76C5.19332 2.51923 4.72388 3.3703 4.4 4.28H2.65C3.46472 3.18605 4.54562 2.31858 5.79 1.76ZM2.64 13.76H4.4C4.72388 14.6697 5.19332 15.5208 5.79 16.28C4.54218 15.713 3.46095 14.8349 2.65 13.73L2.64 13.76ZM12.2 16.28C12.7967 15.5208 13.2661 14.6697 13.59 13.76H15.35C14.5303 14.8393 13.4498 15.6927 12.21 16.24L12.2 16.28Z" fill={color} />
      </svg>
    </SvgIcon>
  );
};
export default SiteIcon;