import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import arowwUP from '../image/scroll/arrowUP.png'
import arrowDown from '../image/scroll/arrowDown.png'
import "../styles/navLink.css"

const Scroll = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);

  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: scrollPosition - 850, behavior: "smooth" });
  }
  const scrollDown = () => {
    window.scrollTo({ top: scrollPosition + 850, behavior: "smooth" });
  }

  return (
    <>
      <Grid className="navlink" sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100px", cursor: "pointer", position: 'fixed', zIndex: "500", right: { xs: "0", sm: "20px" }, top: { xs: "80vh", md: "85vh" } }}>
        <Typography component={"img"} alt="arrowup" src={arowwUP} onClick={scrollTop} sx={{ width: { xs: "35px", sm: "50px" }, cursor: "pointer", filter: " drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.25))", display: scrollPosition > 500 ? "flex" : "none" }}></Typography>
        <Typography component={"img"} alt="arrowDown" src={arrowDown} onClick={scrollDown} sx={{ width: { xs: "35px", sm: "50px" }, cursor: "pointer", filter: " drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.25))"}}></Typography>
      </Grid>
    </>
  )
}
export default Scroll;