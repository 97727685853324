import { Grid, TextField, Typography, FormControl, Radio, Select, MenuItem, RadioGroup, FormControlLabel, Button, CircularProgress, Tooltip, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { separator, onlyFloatnaumber } from "./modules/general";
import axios from "axios";
import { baseUrl } from "./modules/general";
import AlertMessage from "./modules/AlertMessage";
import { setCanonicalTag } from "./modules/general";
import Loader from "./Loader";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const OrderSite = () => {
  const { t, i18n } = useTranslation();
  const [textFieldSize, setTextFiledSize] = useState(window.innerWidth <= 900 ? "small" : "medium");
  const [radioSeo, setRadioSeo] = useState(1);
  const [radioDomain, setRadioDomain] = useState(1);
  const [radioHost, setRadioHost] = useState(1);
  const [maximum_budget, setMaximum_budget] = useState("");
  const [goal_customer, setgoal_customer] = useState("");
  const [special_features, setspecial_features] = useState("");
  const [similar_sites, setsimilar_sites] = useState("");
  const [description, setDescription] = useState("");
  const [minimum_budget, setMinimum_budget] = useState("");
  const [siteType, setSiteType] = React.useState("");
  const [dataActivity, setDataActivity] = useState(null);
  const [dataSiteType, setDataSiteType] = useState(null);
  const [activity, setActivity] = useState("");
  const [alert, setAlert] = useState({ alertCheck: false, message: "", severity: "" });
  const [btnSubmit, setBtnSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    getActivity();
    getSiteType();
  }, [])

  useEffect(() => {
    document.title = "سفارش طراحی سایت | درخواست طراحی وب سایت | وب گستران سورین | ";
    document.getElementById("main-heading").innerHTML = "سفارش طراحی سایت | درخواست طراحی وب سایت | وب گستران سورین | ";
    document.getElementsByTagName('meta')["description"].content = "صفحه مربوط به سفارش طراحی سایت در وب سایت شرکت وب گستران سورین، بهترین سایت طراحی سایت در ایران. شما در حال ثبت درخواست طراحی وب سایت در وب سایت رسمی شرکت طراحی سایت وب گستران سورین هستید";
    document.getElementsByTagName('meta')["keywords"].content = "سفارش سایت در بهترین شرکت طراحی سایت | طراحی سایت در بهترین شرکت طراحی سایت در ایران | خرید سایت از بهترین شرکت طراحی سایت در تهران ";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    setInfo(JSON.parse(localStorage.getItem('sorinInfoUser')));
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => resizeTextFiled());
    return (() => window.removeEventListener('resize', () => resizeTextFiled()));
  }, [window.innerWidth]);

  const handleChangeSiteType = (event) => {
    setSiteType(event.target.value)
  };

  const getActivity = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/activity/get`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataActivity(res.data.data)
      })
  }

  const getSiteType = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/st/get`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataSiteType(res.data.data)
      })
  }

  const submitHandeler = () => {
    let data;
    if ((siteType === "") && (activity === "")) {
      setAlert({ alertCheck: true, message: t("alert_WebsiteOrderNull"), severity: "error" })
    }
    else if (siteType === "") {
      setAlert({ alertCheck: true, message: t("alert_typeSite"), severity: "error" })
    }
    else if (activity === "") {
      setAlert({ alertCheck: true, message: t("alert_activity"), severity: "error" })
    }
    else if ((minimum_budget !== "") && (+minimum_budget < 500000)) {
      setAlert({ alertCheck: true, message: t("alert_minimumBudget"), severity: "error" })
    }
    else if ((maximum_budget !== "") && (+maximum_budget > 400000000000)) {
      setAlert({ alertCheck: true, message: t("alert_maximumBudget"), severity: "error" })
    }
    else if ((maximum_budget !== "") && (minimum_budget !== "") && (+minimum_budget > +maximum_budget)) {
      setAlert({ alertCheck: true, message: t("alert_minimumBigerMaximum"), severity: "error" })
    }
    else {
      if (goal_customer !== "") {
        data = { ...data, goal_customer: goal_customer }
      }
      else if (special_features !== "") {
        data = { ...data, special_features: special_features }
      }
      else if (minimum_budget !== "") {
        data = { ...data, minimum_budget: minimum_budget }
      }
      else if (maximum_budget !== "") {
        data = { ...data, maximum_budget: maximum_budget }
      }
      else if (similar_sites !== "") {
        data = { ...data, similar_sites: similar_sites }
      }
      else if (description !== "") {
        data = { ...data, description: description }
      }
      data = {
        ...data,
        type_site_id: siteType,
        activity_type_id: activity,
        seo: radioSeo,
        domain: radioDomain,
        host: radioHost,
      }
      submitData(data);
    }
  }

  const submitData = (data) => {
    setLoading(true)
    setBtnSubmit(true)
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/site/create',
      method: "POST",
      data: data,
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setAlert({ alertCheck: true, message: t("alert_dataSuccess"), severity: "success" })
        setBtnSubmit(false)
        setLoading(false)
        setSiteType(""); setActivity(""); setgoal_customer(""); setspecial_features(""); setMaximum_budget(""); setMinimum_budget(""); setsimilar_sites(""); setDescription(""); setRadioSeo(1); setRadioDomain(1); setRadioHost(1);
      })
      .catch(
        (error) => {
          const { status } = error.response;
          switch (status) {
            case (500):
              setAlert({ alertCheck: true, message: t("AM_intervalServer"), severity: "error" })
              break;
            case (400):
              setAlert({ alertCheck: true, message: t("AM_BadRequest"), severity: "error" })
              break;
            default:
              setAlert({ alertCheck: true, message: t("alert_defualt"), severity: "error" })
          }
          setBtnSubmit(false)
          setLoading(false)
          setAlert({ alertCheck: true, message: t("alert_dataFaild"), severity: "error" })
        }
      )
  }

  const resizeTextFiled = () => {
    if (window.innerWidth < 900 && textFieldSize === 'medium') {
      setTextFiledSize('small')
    }
    else if (window.innerWidth > 900 && textFieldSize === 'small') {
      setTextFiledSize('medium')
    }
  }

  const handleChangeSeo = (event) => {
    setRadioSeo(event.target.value);
  };

  const handleChangeHost = (event) => {
    setRadioHost(event.target.value);
  };

  const handleChangeDomain = (event) => {
    setRadioDomain(event.target.value);
  };

  const minimum_budgetHandeler = (e) => {
    let value = onlyFloatnaumber(e.target.value);
    setMinimum_budget(value)
  }

  const maximum_budgetHandeler = (e) => {
    let value = onlyFloatnaumber(e.target.value);
    setMaximum_budget(value)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };

  return (
    info === null ?
      <Loader />
      :
      info.active === 0 ?
        <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "18px" }, margin: "20px" }}>{t("compeleteInfo")}</Typography>
        :
        <>
          <Grid sx={{ display: 'flex', width: "100%", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: 'flex-start' }}>
            <Grid sx={{ display: 'flex', alignItems: "center", marginTop: { xs: "0", md: "10px" }, justifyContent: "space-between", flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("SiteType")}</Typography>
                  <Tooltip title={t("tooltipSiteType")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <FormControl sx={{ m: 1, width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }, }}
                  size={textFieldSize}>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={siteType}
                    onChange={handleChangeSiteType}
                    sx={{
                      "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important" }
                      , "& .MuiMenuItem-root": { justifyContent: "flex-end" }
                    }}
                  >
                    {
                      dataSiteType !== null ?
                        dataSiteType.map(element => (
                          <MenuItem key={element.id} value={element.id} className="menuItem">{element.description}</MenuItem>
                        ))
                        :
                        null
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("activity")}</Typography>
                  <Tooltip title={t("tooltipSiteActivity")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <FormControl sx={{ m: 1, width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" } }}
                  size={textFieldSize}>
                  <Select
                    value={activity}
                    onChange={(event) => setActivity(event.target.value)}
                    sx={{ "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important" } }}
                  >
                    {
                      dataActivity !== null ?
                        dataActivity.map(element => (
                          <MenuItem key={element.id} value={element.id} className="menuItem">{element.description}</MenuItem>
                        ))
                        :
                        null
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", marginTop: { xs: "0", md: "10px" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" }, justifyContent: "space-between" }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("goal_customer")}</Typography>
                  <Tooltip title={t("tooltipSiteGoalCustomer")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  value={goal_customer}
                  onChange={(e) => { setgoal_customer(e.target.value) }}
                  variant='outlined'
                  fullWidth

                  size={textFieldSize}
                  sx={{
                    width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("special_features")}</Typography>
                  <Tooltip title={t("tooltipSiteSpecialFeatures")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  value={special_features}
                  onChange={(e) => { setspecial_features(e.target.value) }}
                  fullWidth

                  size={textFieldSize}
                  sx={{
                    width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", marginTop: { xs: "0", md: "10px" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" }, justifyContent: "space-between" }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("minimum_budgetLabel")}</Typography>
                  <Tooltip title={t("tooltipSiteMinimumBudget")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  value={separator(minimum_budget)}
                  onChange={(e) => minimum_budgetHandeler(e)}
                  className="textField"
                  variant='outlined'
                  fullWidth

                  size={textFieldSize}
                  sx={{
                    width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("maximum_budgetLabel")}</Typography>
                  <Tooltip title={t("tooltipSiteMaximumBudget")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  value={separator(maximum_budget)}
                  onChange={(e) => maximum_budgetHandeler(e)}
                  variant='outlined'
                  fullWidth

                  size={textFieldSize}
                  sx={{
                    width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column", width: { xs: "95%", md: "90%" }, alignItems: 'flex-start', marginTop: { xs: "0", md: "10px" }, justifyContent: 'center', marginBottom: { xs: "5%", sm: "0" } }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("similar_sites")}</Typography>
                  <Tooltip title={t("tooltipSimilarSite")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              <TextField
                className="textField"
                variant='outlined'
                value={similar_sites}
                onChange={(e) => { setsimilar_sites(e.target.value) }}
                fullWidth
                size={textFieldSize}
                sx={{
                  display: { xs: "none", md: "flex" },
                  width: "100%", background: "#FFFFFF",
                  boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}
              />
              <TextField
                className="textField"
                variant='outlined'
                value={similar_sites}
                onChange={(e) => { setsimilar_sites(e.target.value) }}
                fullWidth
                multiline
                rows={2}
                sx={{
                  display: { xs: "flex", md: "none" },
                  width: "100%", background: "#FFFFFF",
                  boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}
              />
            </Grid>
            <Grid sx={{ display: "flex", width: { xs: "95%", md: "90%" }, alignItems: 'flex-start', marginTop: { xs: "0", md: "20px" }, justifyContent: 'center', marginBottom: { xs: "5%", sm: "0" } }}>
              <Grid sx={{ display: 'flex', alignItems: "center", flexDirection: { xs: "column", md: "row" }, width: "100%", justifyContent: "space-between", margin: { xs: "0 0 2% 0", md: i18n.language === "fa" ? "0px 20px 0 0" : "0 0 0 20px" }, }}>
                <Grid sx={{
                  display: "flex", alignItems: "center", flexDirection: { xs: "row", md: 'column' }, width: { xs: "95%", md: "32%" }, border: "1px solid #777373",
                  filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.25))", borderRadius: "8px", height: { xs: "58px", md: "70px" }, justifyContent: "space-between", padding: { xs: "0 8%", md: "0" }
                }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", fontWeight: { xs: "400", sm: "700" } }}>{t("needSEO")}</Typography>
                  <FormControl sx={{ display: "flex", flexDirection: 'row', alignItems: "center" }} >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={radioSeo}
                      onChange={handleChangeSeo}
                      sx={{ display: "flex", flexDirection: 'row', alignItems: "center", "& .MuiRadio-root": { padding: "3px !important" }, "& .MuiFormControlLabel-root": { margin: "5px !important" } }}
                    >
                      <FormControlLabel value={1} sx={{ "& .MuiTypography-root": { fontWeight: { xs: "400", sm: "700" }, fontSize: { xs: "12px", sm: "16px" } } }} control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 15 }, '&.Mui-checked': { color: "#FF3951", } }} />} label={t("yes")} />
                      <FormControlLabel value={0} sx={{ "& .MuiTypography-root": { fontWeight: { xs: "400", sm: "700" }, fontSize: { xs: "12px", sm: "16px" } } }} control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 15 }, '&.Mui-checked': { color: "#FF3951", } }} />} label={t("no")} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid sx={{
                  display: "flex", alignItems: "center", flexDirection: { xs: "row", md: 'column' }, width: { xs: "95%", md: "32%" }, border: "1px solid #777373", marginTop: { xs: "4%", md: "0" },
                  filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.25))", borderRadius: "8px", height: { xs: "58px", md: "70px" }, justifyContent: "space-between", padding: { xs: "0 8%", md: "0" }
                }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", fontWeight: { xs: "400", sm: "700" } }}>{t("haveDomain")}</Typography>
                  <FormControl sx={{ display: "flex", flexDirection: 'row', alignItems: "center" }} >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={radioDomain}
                      onChange={handleChangeDomain}
                      sx={{ display: "flex", flexDirection: 'row', alignItems: "center", "& .MuiRadio-root": { padding: "3px !important" }, "& .MuiFormControlLabel-root": { margin: "5px !important" } }}
                    >
                      <FormControlLabel value={1} sx={{ "& .MuiTypography-root": { fontWeight: { xs: "400", sm: "700" }, fontSize: { xs: "12px", sm: "16px" } } }} control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 15 }, '&.Mui-checked': { color: "#FF3951", } }} />} label={t("yes")} />
                      <FormControlLabel value={0} sx={{ "& .MuiTypography-root": { fontWeight: { xs: "400", sm: "700" }, fontSize: { xs: "12px", sm: "16px" } } }} control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 15 }, '&.Mui-checked': { color: "#FF3951", } }} />} label={t("no")} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid sx={{
                  display: "flex", alignItems: "center", flexDirection: { xs: "row", md: 'column' }, width: { xs: "95%", md: "32%" }, border: "1px solid #777373", marginTop: { xs: "4%", md: "0" },
                  filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.25))", borderRadius: "8px", height: { xs: "58px", md: "70px" }, justifyContent: "space-between", padding: { xs: "0 8%", md: "0" }
                }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", fontWeight: { xs: "400", sm: "700" } }}>{t("haveHost")}</Typography>
                  <FormControl sx={{ display: "flex", flexDirection: 'row', alignItems: "center" }} >
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={radioHost}
                      onChange={handleChangeHost}
                      sx={{ display: "flex", flexDirection: 'row', alignItems: "center", "& .MuiRadio-root": { padding: "3px !important" }, "& .MuiFormControlLabel-root": { margin: "5px !important" } }}
                    >
                      <FormControlLabel value={1} sx={{ "& .MuiTypography-root": { fontWeight: { xs: "400", sm: "700" }, fontSize: { xs: "12px", sm: "16px" } } }} control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 15 }, '&.Mui-checked': { color: "#FF3951", } }} />} label={t("yes")} />
                      <FormControlLabel value={0} sx={{ "& .MuiTypography-root": { fontWeight: { xs: "400", sm: "700" }, fontSize: { xs: "12px", sm: "16px" } } }} control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 15 }, '&.Mui-checked': { color: "#FF3951", } }} />} label={t("no")} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column", width: { xs: "95%", md: "90%" }, alignItems: 'flex-start', marginTop: { xs: "0", md: "10px" }, justifyContent: 'center', marginBottom: { xs: "5%", sm: "0" } }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("description")}</Typography>
                  <Tooltip title={t("tooltipDescriptionSite")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              <TextField
                className="textField"
                variant='outlined'
                value={description}
                onChange={(e) => { setDescription(e.target.value) }}
                fullWidth
                multiline
                rows={3}
                sx={{
                  width: "100%", background: "#FFFFFF",
                  boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" },
                }}
              />
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column", marginTop: "40px", width: { xs: "95%", md: "90%" }, alignItems: 'center', justifyContent: 'center' }}>
              <Button onClick={submitHandeler} disabled={btnSubmit ? true : false} sx={{
                background: "#FF3951", boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", color: "#FFFFFF", fontSize: { xs: "12px", sm: "16px", md: "18px" },
                width: { xs: i18n.language === "fa" ? "100px" : "125px", sm: "159px", md: "193px" }, height: { xs: i18n.language === "fa" ? '33px' : "40px", sm: '50px', md: "60px" }, "&:hover": { backgroundColor: "#FF3951" }, textTransform: "setMinimum_budgetcase"
              }}>
                {btnSubmit === true && loading === true ? <CircularProgress size={20} color="inherit" sx={{ color: "white" }} /> : t("submitInfo")}
              </Button>
            </Grid>
          </Grid>
          <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
        </>
  )
}
export default OrderSite;