import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import {
  author,
  date,
  h1,
  headPhoto,
  largePhoto,
  linkArticle,
  rootArticle,
  tags,
  paragraph,
  timeRead,
  view,
  listItemCircle,
  h2,
  h4,
  smallPhoto,
  sourceLink,
} from "../articleFunction/ArticleFunction";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { Articles } from "./Articles";
import ArticleSide from "../articleFunction/ArticleSide";
import { NavLink } from "react-router-dom";
//import photo
import photo1 from "../../image/articles/18/photo1.png";
import photo2 from "../../image/articles/18/photo2.png";
import photo3 from "../../image/articles/18/photo3.webp";
import photo4 from "../../image/articles/18/photo4.webp";
import photo5 from "../../image/articles/18/photo5.png";
import Loader from "../Loader";
import PortfolioButton from "../PortfolioButton";

let articleData = null;
const Article18 = () => {
  //NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${
      articleData !== null && articleData.title
    }`;
    document.getElementById("main-heading").innerHTML =
      articleData !== null && articleData.h1;
    document.getElementsByTagName("meta")["description"].content =
      articleData !== null && articleData.desc;
    document.getElementsByTagName("meta")["keywords"].content =
      articleData !== null && articleData.keywords;
    document.getElementsByTagName("meta")["author"].content =
      articleData !== null && articleData.author;
    setCanonicalTag();
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item;
    });
  }, []);

  useEffect(() => {
    articleData !== null && addView();
    getView();
  }, []);

  const addView = () => {
    Promise.resolve(
      axios({
        url: baseUrl + "/api/v1/article/addView",
        method: "POST",
        data: { id: articleData.id },
      })
    ).then((res) => {});
  };
  const getView = () => {
    Promise.resolve(
      axios({
        url: `${baseUrl}/api/v1/article/getView`,
        method: "GET",
        params: { id: articleData.id },
      })
    ).then((res) => {
      setDataView(res.data.data);
    });
  };

  //change This part
//read More
const readMore = [
    {
      id: 1,
      title: "معرفی صفر تا صد زبان برنامه پایتون برای مبتدی ها",
      link: "/What-Is-Python-Programming-Language",
    },
    {
      id: 2,
      title: "معرفی صفر تا صد زبان برنامه نویسی سی شارپ برای مبتدی ها",
      link: "/What-Is-CSharp-Programming-Language",
    },
    {
      id: 3,
      title: "معرفی صفر تا صد زبان جاوا برای مبتدی ها",
      link: "/What-Is-JAVA-Programming-Language",
    },
    {
      id: 4,
      title: "معرفی صفر تا صد زبان جاوا اسکریپت برای مبتدی ها",
      link: "/What-Is-Javascript-Programming-Language",
    },
  ];
  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          direction: "rtl",
          alignItems: "center",
        }}
      >
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: { xs: "unset", sm: "flex-start" },
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            width: { xs: "100%", sm: "85%" },
          }}
        >
          <ArticleSide
            tags={articleData !== null && articleData.tags}
            readMore={readMore}
          />
          {articleData !== null ? (
            <Grid
              item
              xs={12}
              sm={7.5}
              md={8}
              lg={8.5}
              xl={9}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", sm: "flex-end" },
                flexDirection: "column",
                width: { xs: "100%", sm: "60%", md: "70%" },
              }}
            >
              {/* do not change */}
              {headPhoto(articleData.image)}
              {h1(articleData.h1, t)}
              {timeRead(articleData.timeRead, t)}
              {date(articleData.date, i18n)}
              <Grid
                sx={{
                  display: "flex",
                  alignItems: { xs: "center", sm: "flex-start" },
                  justifyContent: "flex-start",
                  width: { xs: "90%", sm: "100%" },
                  flexDirection: "column",
                }}
              >
                {/* The text of the article */}
                <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    کاتلین یک زبان برنامه نویسی مدرن و مستقل از سکو است که بر روی ماشین مجازی جاوا اجرا می‌شود. این زبان به سورس کد جاوا اسکریپت یا کد ماشین کامپایل می شود.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زبان کاتلین کاملا با جاوا سازگار است، به این معنا که می‌توان از آن برای نوشتن کد جدید برای برنامه‌های جاوا یا برای تبدیل کد جاوا به کاتلین استفاده کرد. این زبان برنامه نویسی به دلیل ویژگی‌های برجسته‌ای مانند خوانایی بالا، انعطاف‌پذیری، امنیت، و قابلیت همکاری با کد جاوا، به سرعت جایگزین برنامه‌نویسی با جاوا شده است.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تاریخچه زبان کاتلین
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    توسعهٔ زبان برنامه نویسی کاتلین سال 2011 در سن پترزبورگ روسیه توسط تیم برنامه‌نویسان جت برینز به دلیل ساخت ابزارهای برنامه‌نویسی قدرتمند مانند IntelliJ IDEA انجام شد. نام این زبان برنامه نویسی از روی جزیره کاتلین در نزدیکی سن پترزبورگ گرفته شده است. هدف از ارائه Kotlin، ارائه زبانی مدرن، قابل خواندن، توسعه‌پذیر و ساده‌تر نسبت به زبان‌های دیگر مانند Java بود. Kotlin از همان ابتدا مورد توجه برنامه‌نویسان قرار گرفت و به جایگاه ویژه ای بین زبان‌های محبوب برنامه‌نویسی دست یافت. در سال ۲۰۱۷ کاتلین به عنوان زبان رسمی برنامه‌نویسی اندروید معرفی شد و این امر به محبوبیت هر چه بیشتر آن افزود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    صفر تا صد زبان کاتلین
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زبان برنامه نویسی کاتلین زبانی کاربردی و open-source می باشد. کاتلین در ابتدا برای ماشین مجازی جاوا ( JVM ) و اندروید طراحی شد و ویژگی های برنامه نویسی شی گرا و کاربردی را با هم ترکیب می کند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    مقایسه زبان برنامه نویسی کاتلین با جاوا
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo1} alt="مقایسه زبان کاتلین و جاوا" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        مقایسه کاتلین با جاوا
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    کاتلین زبانی مدرن و قدرتمند است که بسیاری از ویژگی های زبان جاوا را داراست. اما می توان گفت کاتلین رساتر و خلاصه تر از زبان جاوا می باشد. با اینکه جاوا شهرت بسیاری در فناوری کسب کرده و بسیار قابل اعتماد می باشد اما یکسری از ویژگی ها را ندارد و امکان دارد به تدریج منسوخ شود. بنابراین کاتلین را می توان به عنوان یک جایگزین عالی برای جاوا معرفی کرد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    از دیگر تفاوت های این دو زبان می توان به تعداد خطوط کد نوشته شده اشاره کرد. برای پیاده سازی برنامه ای با قابلیت یکسان، کدهای نوشته شده در زبان کاتلین به مراتب بسیار کمتر از جاوا می باشد. در نتیجه قابلیت نگهداری و خوانایی کد‌های کاتلین راحت تر می باشد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    از دیگر تفاوت های ای دو زبان می توان به سرعت اجرای برنامه های کاتلین اشاره کرد. زیرا کاتلین از توابع درون خطی و توابع بدون نام پشتیبانی می کند و به توسعه‌دهندگان اجازه می‌دهد تا برنامه‌هایی سریع‌تر از جاوا ایجاد کنند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    این دو زبان در زمینه کامپایل نیز تفاوت‌هایی با یکدیگر دارند. جاوا در مقایسه با کاتلین 10 تا 15 درصد سریع‌تر کامپایل می‌شود. با این حال، در زمان کامپایل‌های افزایشی که فقط کد به همراه تغییرات کامپایل می‌شود و نیازی به ساخت کل برنامه نیست، کاتلین نسبت به جاوا بهتر عمل می‌کند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    همچنین می توان گفت زبان جاوا زبان برنامه نویسی سمت سرور برای ساخت <NavLink to="/Academy/what-is-application" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >اپلیکیشن </NavLink>های اندرویدی است. ظهور زبان جدید کاتلین در عرصه برنامه نویسی، باعث شکست برتری مطلق جاوا در این زمینه می باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    مزایای زبان کاتلین
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo2} alt="مزایای کاتلین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        مزایای کاتلین
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "400", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    توسعه با زبان کاتلین ایمنی بیشتری دارد.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "400", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    نگهداری از برنامه آسان و ارزان است.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "400", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    قابلیت تعامل کاتلین با جاوا
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "400", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    امکان توسعه‌ و گسترش آسان
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "400", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    کد‌های کمتر و خوانایی بالاتر
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "400", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    خطا‌ها و باگ‌های کمتر
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "400", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    قابلیت توسعه چند پلتفرمی
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "400", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    یادگیری آسان
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "400", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    امنیت بالا
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    معایب زبان برنامه نویسی کاتلین
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "400", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    کمبود نیروی متخصص کاتلین
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "400", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    جامعه برنامه‌نویسی کوچک
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "400", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    زمان کامپایل طولانی
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "400", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    منابع محدود یادگیری
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    ویژگی های زبان برنامه نویسی کاتلین
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زبان برنامه نویسی کاتلین به دلیل خصوصیات و قابلیت های منحصر بفردی ک دارد توانسته جایگاه ویژه ای را در بین برنامه نویسان و توسعه دهندگان پیدا کند. در ادامه به بیان این ویژگی ها می پردازیم.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    ویژگی Lazy-Loading
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    قابلیت Lazy-Loading یا بارگذاری تنبل سبب کاهش زمان راه‌اندازی برنامه می‌شود. توسعه‌دهندگان برای کاهش زمان راه‌اندازی برنامه خود از این ویژگی استفاده می کنند. این ویژگی خاص از طریق بارگیری منابع مورد نیاز در حافظه اصلی، باعث کاهش زمان راه‌اندازی برنامه می‌شود.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    Collection Filter
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برنامه‌ها برای اجرای بخش‌های مختلف خود باید با رابط برنامه نویسی اپلیکیشن» (Application Programming Interface | API) در ارتباط باشند. در پیاده‌سازی ارتباط برنامه با API امکان دارد برنامه‌نویس به دفعات با مجموعه‌های مختلفی روبرو شود. زبان کاتلین دارای ویژگی فیلتر مجموعه (Collection Filter) می باشد که به برنامه‌نویس اجازه می‌دهد تا با تعیین الگویی مشخص، خروجی کار را برحسب نیازمندی برنامه دریافت نماید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تعامل کاتلین با جاوا
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    به بیان بسیاری از برنامه نویسان یکی از بهترین ویژگی های زبان کاتلین قابلیت همکاری با جاوا می باشد. زبان برنامه نویسی کاتلین بر روی ماشین مجازی جاوا (JVM) اجرا می‌شود و تمامی ابزارها و کتابخانه‌های جاوا را نیز پشتیبانی می‌کند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    کاربردهای زبان برنامه نویسی کاتلین
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo3} alt="کاربردهای زبان کاتلین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        کاربردهای زبان کاتلین
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زبان کاتلین به عنوان یک زبان چندمنظوره، در زمینه‌های مختلف <NavLink to="/Portfolio" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >برنامه نویسی </NavLink> کاربردهای فراوانی دارد. در ذیل به بررسی این کاربردها می پردازیم. پس با ما همراه باشید.
                  </Typography>
                  <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      توسعه اندروید:
                      </Typography>
                    <Typography component={"p"}
                      sx={{
                        color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", marginBottom: { xs: "10px", md: "15px" }
                      }}>
                      کاتلین زبان رسمی توسعه <NavLink to="/Academy/all-about-instagram" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >اندروید </NavLink> می باشد که توسط گوگل برای توسعه <Typography component={"a"} target="_blank" href="https://android.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >Android Studio</Typography>، IDE رسمی اندروید مورد استفاده قرار می گیرد.
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      توسعه وب: 
                    </Typography>
                    <Typography component={"p"}
                      sx={{
                        color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", marginBottom: { xs: "10px", md: "15px" }
                      }}>
                      کاتلین با استفاده از چارچوب های مختلف مانند Spring Boot و Kotlin.js برای نوشتن برنامه‌های وب سمت سرور و سمت کلاینت کارایی دارد.
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      توسعه چند پلتفرمی: 
                    </Typography>
                    <Typography component={"p"}
                      sx={{
                        color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", marginBottom: { xs: "10px", md: "15px" }
                      }}>
                      برای نوشتن برنامه‌هایی که می‌توانند بر روی پلتفرم‌های مختلف مانند JVM، JavaScript و Native اجرا شوند از زبان کاتلین استفاده می شود.
                    </Typography>
                    <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    دلیل یادگیری زبان برنامه نویسی کاتلین چیست؟
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    محبوبیت کاتلین زمانی بر سر زبان ها افتاد که این زبان توسط گوگل به عنوان زبان رسمی برنامه نویسی اندروید معرفی شد. می توان گفت کاتلین جایگزین زبان جاوا شد و این موضوع مهر تاییدی بر قابلیت‌ها و قدرت این زبان برنامه نویسی بود.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    با استفاده از زبان کاتلین می‌توان اپلیکیشن‌های کاربردی برای تلفن همراه و برنامه‌های کاربردی تحت‌وب (وب اپلیکیشن) را پیاده‌سازی کرد. یادگیری این زبان حتی اگر به صورت حرفه ای نباشد به کسب مهارت در زبان‌هایی همچون Go ،Rust ،Scala و Swift منجر می شود، زیرا این زبان‌ها در برخی مفاهیم اساسی با زبان کاتلین وجه اشتراک دارند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    شرکت هایی که از زبان کاتلین استفاده می کنند؟
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo4} alt="شرکت هایی که از کاتلین استفاده می کنند" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                      شرکت هایی که از کاتلین استفاده می کنند
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بعد از به رسمیت شناختن کاتلین توسط <Typography component={"a"} target="_blank" href="https://www.google.ru/?hl=fa" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >گوگل </Typography> محبوبیت این زبان دوچندان شد.  طبق آمار‌های رسمی، 66 درصد از برنامه‌نویسان برای برنامه نویسی اندروید از زبان کاتلین استفاده می‌کنند. همچنین شرکت های مطرحی در دنیا وجود دارند که این زبان را برای توسعه برنامه هایشان بکار می برند. از جمله این شرکت ها می توان به گوگل، <Typography component={"a"} target="_blank" href="https://www.netflix.com/de-en/" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >نتفلیکس </Typography>، اوبر، Atlassian ،Pinterest ،Kickstarter اشاره داشت.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    آینده زبان کاتلین
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    پیش بینی اینده به راحتی امکانپذیر نیست. در خصوص زبان برنامه نویسی کاتلین نیز با توجه موارد ذکر شده همچون ویژگی‌ها و ساختار قدرتمند کاتلین و حمایت شرکت بزرگی چون گوگل، می‌توان به آینده این زبان امیدوار بود. بسیار محتمل است که زبان کاتلین به همین صورت در مسیر پیشرفت و توسعه باقی بماند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    دلایلی که نشان می دهد کاتلین آینده ای روشن دارد:
                  </Typography>
                  <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      پشتیبانی گوگل:
                    </Typography>
                    <Typography component={"p"}
                      sx={{
                        color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", marginBottom: { xs: "10px", md: "15px" }
                      }}>
                     از انجا که گوگل از کاتلین به عنوان زبان رسمی توسعه اندروید پشتیبانی می کند، این امر باعث شده که کاتلین به طور چشمگیری توسط توسعه دهندگان اندروید مورد استفاده قرار گیرد.
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      جامعه فعال:
                    </Typography>
                    <Typography component={"p"}
                      sx={{
                        color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", marginBottom: { xs: "10px", md: "15px" }
                      }}>
                      کاتلین دارای یک جامعه رو به رشد از توسعه دهندگان است که به طور مداوم در حال توسعه کتابخانه ها و ابزارهای جدید برای کاتلین می باشند.
                    </Typography><Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      قدرتمند و انعطاف پذیر:
                    </Typography>
                    <Typography component={"p"}
                      sx={{
                        color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", marginBottom: { xs: "10px", md: "15px" }
                      }}>
                      کاتلین زبانی قدرتمند و انعطاف پذیر است و می تواند برای توسعه برنامه های مختلف در پلتفرم های گوناگون استفاده شود.
                    </Typography><Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      ایمنی و پویایی:
                    </Typography>
                    <Typography component={"p"}
                      sx={{
                        color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", marginBottom: { xs: "10px", md: "15px" }
                      }}>
                      کاتلین زبان برنامه‌نویسی ایمن و پویا می باشد و قادر است خطاهای رایج در زمان اجرا را از بین ببرد. همین امر سبب می‌شود که کاتلین برای توسعه برنامه‌های بزرگ و پیچیده مناسب باشد.
                    </Typography>
                    <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    بازار کار کاتلین
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    نکته مهم در بازار کار کاتلین بحث عرضه و تقاضا‌ی نیروی متخصص در این زبان برنامه نویسی است. از انجا که کاتلین زبان نوظهوری است و مدت زیادی از معرفی آن توسط گوگل نمی‌گذرد، در نتیجه، می‌توان با اطمینان گفت که برنامه‌نویسان فعال در این زمینه هنوز اشباع نشده‌ است و این نکته‌ مثبتی برای بازار کار و یادگیری زبان کاتلین می باشد. همچنین با توجه به قدرت و قابلیت‌های بالای کاتلین و حمایت گوگل از آن، می‌توان انتظار داشت که بسیاری از شرکت‌ها و کسب و کار‌ها به دنبال جذب و استخدام برنامه‌نویس کاتلین هستند و این نکته مثبت دیگری در مورد زبان کاتلین می باشد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    مورد مهم دیگری که در این زمینه می توان خاطر نشان کرد، کاربرد اصلی کاتلین در برنامه نویسی اندروید است. از انجا که سیستم‌عامل اندروید یک سیستم‌عامل فراگیر در موبایل بشمار می آید در نتیجه، با یادگیری زبان کاتلین می‌توان از فرصت‌های موجود در این زمینه از برنامه نویسی استفاده کرد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    نرم‌افزارهایی که می توان در آنها کد کاتلین نوشت
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo5} alt="محیط های برنامه نویسی کاتلین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        محیط های برنامه نویسی کاتلین
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    محیط های نرم افزاری بسیاری هستند که زبان کاتلین را پشتیبانی می کنند. ما در این قسمت به معرفی نمونه ای از انها می پردازیم.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    IntelliJ IDEA
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                   اینتل‌جی‌آی‌دی‌ای یک محیط توسعه یکپارچه  (IDE) است که توسط JetBrains ارائه شد. این نرم‌افزار از زبان‌های برنامه‌نویسی زیادی از جمله کاتلین، پشتیبانی می‌کند. IntelliJ IDEA برای توسعه برنامه‌های کاتلین از ابزارهای متنوعی بهره می گیرد که در ادامه به آن اشاره می کنیم.
                  </Typography>
                  <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      بررسی کد: 
                    </Typography>
                    <Typography component={"p"}
                      sx={{
                        color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", marginBottom: { xs: "10px", md: "15px" }
                      }}>
                      IntelliJ IDEA کد کاتلین را به صورت real-time بررسی می‌کند و خطاها و هشدارها را نشان می‌دهد.
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      پیشنهادات کد:
                    </Typography>
                    <Typography component={"p"}
                      sx={{
                        color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", marginBottom: { xs: "10px", md: "15px" }
                      }}>
                      IntelliJ IDEA پیشنهادات کدی را برای نوشتن کد سریع‌تر و کارآمدتر ارائه می‌کند.
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      ابزارهای دیباگ:
                    </Typography>
                    <Typography component={"p"}
                      sx={{
                        color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", marginBottom: { xs: "10px", md: "15px" }
                      }}>
                     IntelliJ IDEA ابزارهای دیباگ قدرتمندی را برای پیدا کردن و رفع خطاها ارائه می‌دهد.
                    </Typography>
                    <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    Android Studio
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    گوگل اندروید استودیو را که یک محیط توسعه رسمی است برای برنامه های اندروید ارائه داده است. این نرم‌افزار زبان کاتلین را به طور کامل پشتیبانی می‌کند و این امکان را فراهم می اورد تا برنامه‌های اندروید را با استفاده از این زبان قدرتمند بسازید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    NetBeans
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    از دیگر محیط های برنامه نویسی که از زبان برنامه نویسی کاتلین پشتیبانی می کند می توان به نت‌بینز اشاره کرد. نت بینز یک محیط توسعه یکپارچه  (IDE) رایگان و متن باز می باشد که توسط <Typography component={"a"} target="_blank" href="https://www.apache207.de/" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >Apache </Typography> Software Foundation معرفی شد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                   ما در این مقاله از سورین سعی بر این داشتیم تا شما را با زبان برنامه نویسی کاتلین اشنا کنیم اما ارائه تمام مطالب در این مقاله نمی گنجد. در مقالات بعدی مطالب جامع تری از این زبان را ارائه می کنیم. بنابراین با ما همراه باشید.
                  </Typography>


                {/* do not change */}

                {author(articleData.author, t)}
                {tags(articleData.tags, t)}
                {linkArticle(t, url)}
                {sourceLink(articleData.source, t)}
                {view(dataview, t)}
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={7.5}
              md={8}
              lg={8.5}
              xl={9}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: { xs: "80%", sm: "60%", md: "70%" },
              }}
            >
              <Loader />
            </Grid>
          )}
        </Grid>
      </Grid>
      <PortfolioButton />
      <Footer />
    </>
  );
};
export default Article18;
