import React from "react";
import { Typography, Grid } from "@mui/material";
import header from '../image/header/header.png';
import backHeader from '../image/header/backHeader.png';
import '../styles/Header.css'
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t, i18n } = useTranslation()

  return (
    <>
      <Grid sx={{ display: "flex", alignItems: "center", width: "100%", paddingTop: { xs: "15px", md: "40px" } }}>
        <Grid sx={{ width: "100%", marginRight: "30px", display: "flex", justifyContent: "flex-start", flexDirection: "row-reverse", alignItems: 'flex-start' }}>
          <Grid sx={{ display: 'flex', alignItems: "flex-Start", justifyContent: "flex-end", width: "100%", position: "relative" }}>
            <Typography className="backImg" component={'img'} alt="backgroundHeader" src={backHeader}
              sx={{ zIndex: "-200", width: "40%", borderRadius: "50%", marginRight: "50px" }} />
            <Typography component={'img'} alt="header" src={header}
              sx={{
                width: "70%", transition: "transform 1s linear", position: "absolute",
                "&:hover": {
                  transform: "translate3d(-5px, -10px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  willChange: "trasform", transformStyle: "preserve-3d"
                }
              }} />
          </Grid>
          <Grid className="slogan" sx={{ display: "flex", alignItems: "center", justifyContent: "center", zIndex: "400", height: { xs: "15%", sm: "20vh", md: "25vh", lg: "40%", xl: "60%" }, position: "absolute", right: "50%" }}>
            <Typography component={"h2"} sx={{ fontSize: { xs: "15px", sm: "30px", md: "45px", lg: "55px", xl: "70px" }, lineHeight: { xs: "28px", sm: "57px", md: "100px", lg: "136px" }, fontWeight: "700", textAlign: "center" }}>{t("slogan")}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default Header;