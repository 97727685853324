import React, { useEffect, useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArticleSide from "../articleFunction/ArticleSide";
import Footer from "../Footer";
import { author, date, h1, linkArticle, rootArticle, tags, timeRead, view, sourceLink, headPhoto } from "../articleFunction/ArticleFunction";
import { Articles } from "./Articles";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { NavLink } from "react-router-dom";
import logo from '../../image/logo/logo.png'
import '../../styles/Article.css';
//import Photo
import photo1 from '../../image/articles/02/photo1.jpg'
import photo2 from '../../image/articles/02/photo2.jpg'
import photo3 from '../../image/articles/02/photo3.jpg'
import photo4 from '../../image/articles/02/photo4.jpg'
import photo5 from '../../image/articles/02/photo5.jpg'
import photo6 from '../../image/articles/02/photo6.jpg'
import Loader from "../Loader";

let articleData = null;
const Article02 = () => {
  ////////NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${articleData !== null && articleData.title}`
    document.getElementById("main-heading").innerHTML =articleData !== null && articleData.h1;
    document.getElementsByTagName('meta')["description"].content = articleData !== null && articleData.desc;
    document.getElementsByTagName('meta')["keywords"].content = articleData !== null && articleData.keywords;
    document.getElementsByTagName('meta')["author"].content = articleData !== null && articleData.author;
    setCanonicalTag()
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item
    })
  }, [])

  useEffect(() => {
    articleData !== null && addView(); getView();
  }, [])

  const addView = () => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v1/article/addView",
      method: "POST",
      data: { id: articleData.id }
    }))
      .then((res) => { })
  }

  const getView = () => {
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/article/getView`,
      method: "GET",
      params: { id: articleData.id }
    }))
      .then((res) => {
        setDataView(res.data.data)
      })
  }
  //change This part

  //read More
  const readMore = [
    {
      id: 1,
      title: "",
      link: ""
    },
  ]

  //sourceLink
  const sourceAddress = ""

  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: "rtl", alignItems: "center" }}>
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid container sx={{ display: "flex", alignItems: { xs: "unset", sm: 'flex-start' }, justifyContent: "space-between", flexDirection: "row", flexWrap: "wrap", width: { xs: "100%", sm: "85%" } }}>
          <ArticleSide tags={articleData !== null && articleData.tags} readMore={readMore} />
          {
            articleData !== null ?
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: 'center', sm: "flex-end" }, flexDirection: "column", width: { xs: "100%", sm: "60%", md: "70%" } }} >
                {/* do not change */}

                {headPhoto(articleData.image)}
                {h1(articleData.h1, t)}
                {timeRead(articleData.timeRead, t)}
                {date(articleData.date, i18n)}
                <Grid sx={{ display: 'flex', alignItems: { xs: "center", sm: 'flex-start' }, justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "column" }}>
                  {/* The text of the article */}
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    سئو چیست؟چرا بهینه سازی سایت مهم است؟  آیا بهینه سازی برای یک سایت لازم است؟ سئو چگونه در رتبه بندی سایت کار می کند؟ این ها سوالاتی هستند که ذهن خیلی از افراد را درگیر کرده است. ما در این مقاله از سورین سعی داریم شما را با مفاهیم سئو و اهمیت آن آشنا کنیم. پس با ما همراه باشید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    مقدمه:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    سئو یکی از زیرمجموعه‌های دیجیتال مارکتینگ است. SEO مخفف سه کلمه Search Engine Optimization است که به معنی بهینه سازی سایت برای موتورهای جستجوگر می باشد. بهینه سازی اهمیت خاصی برای رضایت کاربر از نتیجه یافت شده در  <Typography component={"a"} target="_blank" href="https://www.google.com/" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >گوگل</Typography> دارد. وقتی کاربری محصولات یا خدمات مربوط به کسب و کار شما را در گوگل جستجو می کند، چنانچه سایت شما در نتایج جستجو رتبه بهتری داشته باشد، احتمال اینکه مشتری بیشتری جذب کنید نیز بیشتر خواهد بود. بنابراین برای تمام کسانیکه در فضای وب فعالیت دارند دانستن مفهوم سئو امری ضروری می باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: { xs: "90%", sm: "100%" } }}>
                    سئو چیست؟
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo1} alt="بهینه سازی سایت - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        بهینه سازی سایت
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    سئو یا بهینه سازی برای موتورهای جستجو را می توان مجموعه اقداماتی که برای بهبود رتبه سایت در موتورهای جستجو نظیر گوگل و بینگ ماکروسافت انجام می‌شود، نامید. اما امروزه با توجه به بروز رسانی گوگل مفهوم <Typography component={"strong"} sx={{ fontWeight: "700" }}>سئو سایت </Typography>
                    از بهینه سازی موتورهای جستجو به بهینه سازی برای کاربر و محتوای کاربردی تغییر کرده است. سئو را نباید با تبلیغ کردن و صرف هزینه یا فريب و دستکاري موتور‌هاي جستجو با روش‌هاي غير اخلاقی اشتباه گرفت بلکه منظور از بهینه سازی دریافت بهترین رتبه برای سایت از طریق نتایج رایگان گوگل می باشد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    سئو و بهینه سازی سایت، فرآیندی زمانبر و مستمر می باشد. برای بهینه سازی باید از تحلیل کلمات کلیدی و آنالیز رقبا شروع کنید. فرآیند سئو به علم و دانش زیاد و  صبر نیاز دارد. از آنجا که موتور‌هاي جستجو بدلیل تغيير مداوم الگوريتم‌هاي رتبه‌بندي خود قابل پيش‌بيني نیستند؛ بنابراين کارشناسان سئو باید دانش خود را در این زمینه بروز رسانی  کنند. همچنین متخصصین این علم با استفاده از تکنیک های برنامه نویسی، محتوای سئو محور، ایجاد بک لینک در سایت های معتبر می توانند ترافیک و بازدید سایت را افزایش دهند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تاریخچه شروع سئو
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    اوايل سال 1990 اولين موتور‌هاي جستجو آغاز به کار کردند. از محبوب‌ترين آن‌ها Infoseek ،Alta Vista و <Typography component={"a"} target="_blank" href="https://yahoo.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >Yahoo</Typography>
                    را می توان نام برد. در آن زمان مردم موتورهاي جستجو را يک ليست فهرست شده می دانستند که سئو اوليه بر اساس حروف الفبا انجام مي‌شد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    با ارتقای اینترنت در گذر زمان موتورهاي جستجوي جديد‌تري پیدا شدند که از الگوريتم‌هاي رتبه‌بندي پيچيده‌تري بهره می بردند. اين الگوريتم‌ها با معيار تراکم کلمه کليدي ( تعداد دفعاتي که يک کلمه کليدي يا يک عبارت در يک صفحه استفاده شده تقسيم بر تعداد کل کلمات صفحه) و برچسب‌هاي شبيه key-words براي فهم محتويات وب سايت‌ها کار می کردند. اینجا بود که سئو برای بهتر دیده شدن سایت ها کار خود را آغاز کرد. با توسعه رتبه‌بندي صفحات توسط موتور‌هاي جستجو، مهندسين سئو به دنبال راهکارهایی هر چند غیر اخلاقی جهت تاثيرپذيري در رتبه بندي اين موتورها برآمدند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    اما امروزه الگوريتم‌هاي موتورهاي جستجوگر پيچيده‌تر شدند و این امر دشوار است که مهندسين بهينه‌سازي بتوانند موتورهاي جستجو و الگوریتم های پیچیده آنها را دور بزنند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    سئو چگونه کار می کند؟
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo2} alt="سئو و موتورهای جستجو - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        سئو و موتورهای جستجو
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    موتورهای جستجو مانند گوگل و بینگ دارای ربات هایی هستند که این ربات ها برای خزیدن در صفحات وب، جمع آوری اطلاعات مربوط به آن صفحات و قرار دادن آنها در فهرست صفحات ایندکس شده، رفتن از سایتی به سایت دیگر،بکار می روند. صفحات ایندکس شده همان چیزی را که به دنبالش هستید به صورت فهرستی از نتایج به شما نشان می دهد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    الگوریتم‌ها با در نظر گرفتن صدها فاکتور رتبه‌بندی، صفحات ایندکس شده را تحلیل می‌کنند تا مشخص شود که صفحات با چه ترتیبی در نتایج جستجوی یک کوئری نمایش داده شوند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    فاکتورهای رتبه بندی با درک و تجربه کاربری از صفحات در ارتباط هستند. بدین معنا که ربات‌های جستجو بررسی می کنند که یک وب‌سایت تا چه اندازه می‌تواند اطلاعاتی که مدنظر کاربر است را ارائه کند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    الگوریتم های جستجو تنها صفحات مرتبط و معتبر را نمایش می دهند. بهینه سازی سایت و محتوای آن با در نظر گرفتن این عوامل می تواند در رتبه بندی صفحات در نتایج جستجو موثر باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    موتور جستجو چیست؟
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    موتور جستجو ابزاری آنلاین برای پیدا کردن اطلاعات مورد نظر افراد در اینترنت می باشد. از معروفترین موتورهای جستجو می توان موتور جستجوی گوگل شرکت آلفابت و موتور جستجوی بینگ شرکت مایکروسافت را نام برد.  زمانیکه کلید واژه‌های مختلف توسط کاربران جستجو می شود هر موتور جستجوگر عملکرد متفاوتی را در رتبه بندی سایت ها ایفا می کنند. دلیل این متفاوت بودن رتبه دهی، هوش مصنوعی بکار رفته در موتورهای جستجو است. موتورهای جستجو گوگل در مقایسه با سایر موتورهای جستجو نتایج بهتری را برای کاربران به نمایش می گذارند. به همین دلیل گوگل یکی از  پرطرفدارترین موتورهای جستجو در اینترنت را دارد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    باید به این نکته توجه داشت که هر موتور جستجو الگوریتم رتبه‌دهی متفاوتی را اجرا می کند. بررسی تمام این موارد در این مقاله از سورین مقدور نمی باشد. از آنجا که موتور های گوگل سهم بیشتری را در این فضا دارند بنابراین منظور ما از موتور جستجو موتورهای گوگل خواهد بود. البته باید بدانید که بسیاری از نکاتی که در اینجا ذکر می شوددر مورد  تمامی موتورهای جستجوگر یکسان می باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    عملکرد موتورهای جستجو
                  </Typography>
                  <Typography component={"ol"}>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      خیزش (Crawling):
                      <Typography component={"span"}
                        sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", paddingRight: "3px" }}>
                        مشاهده و بررسی صفحات سایت
                      </Typography>
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      فهرست کردن (Indexing):
                      <Typography component={"span"}
                        sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", paddingRight: "3px" }}>
                        فهرست کردن صفحات مشاهده شده در پایگاه داده
                      </Typography>
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      رتبه بندی صفحات (Ranking):
                      <Typography component={"span"}
                        sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", paddingRight: "3px" }}>
                        رتبه‌بندی (رنکینگ) نتایج بر اساس کلمه کلیدی وارد شده توسط کاربر و فاکتورهای رتبه‌دهی
                      </Typography>
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      نمایش نتایج به کاربر در صفحه نتایج جستجو:
                      <Typography component={"span"}
                        sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", paddingRight: "3px" }}>
                        نمایش صفحه نتایج جستجو لینک‌های مختلف به ترتیب با وارد کردن کلمه کلیدی در موتور جستجو
                      </Typography>
                    </Typography>
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: { xs: "90%", sm: "100%" } }}>
                    چرا باید سایت را سئو کنیم؟
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo3} alt="سئو سایت - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        اهمیت سئوسایت
                      </Typography>
                    </Grid>
                  </Grid>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    سئو سایت باعث میشود روزانه تعدادی زیادی از مخاطبین علاقه مند از سایت شما بازدید کنند.
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    هزینه سئو کردن از هزینه تبلیغات محیطی و اینترنتی کمتر است.
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    وقتی بازدیدکنندگان سایت شما زیاد می شود افراد از برند شما شناخت بیشتری پیدا می کنند.
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    مخاطبین ایرانی رتبه الکسا را به عنوان یک روش اعتمادسازی می دانند، بنابراین سئو باعث کاهش رتبه الکسا و در نتیجه افزایش اعتماد کاربران خواهد شد. لازم به ذکر است سایت الکسا به کار خود پایان داده است و میتوانید به جای آن از <Typography component={"a"} target="_blank" href="https://www.similarweb.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >similarweb </Typography>
                    استفاده کنید.
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    همچنین سئو باعث افزایش کاربران و مشتریان وفادار می شود.
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    سبب پیشی گرفتن از رقبای کاری می شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    مراحل سئو سایت
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    سئو یک فرآیند کاملا تخصصی می باشد. برای بهینه سازی معمولا باید یکسری مراحل طی شود که در زیر به آن می پردازیم:
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    آنالیز کلی سایت:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    اولین قدم برای سئوی یک پروژه، شناخت درست سایت می باشد. ابتدا باید سایت یک بررسی کاملی از سایت انجام داد. سپس صفحات نیازمند بهینه‌سازی و خطاها شناسایی شوند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تحلیل رقبا:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    مرحله بعدی برای سئو سایت شناسایی سایت رقبا می باشد. باید نقاط قوت و ضعف آن‌ها  را بررسی کنیم. تحلیل سایت رقبا سبب می شود برای اقدامات خود اولویت‌بندی درستی انجام دهیم.
                  </Typography>
                    <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    شناخت پرسنا و مخاطب سایت:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    هدف مهم سئو جذب مخاطب برای سایت می باشد. برای این امر، ابتدا باید نوع کاربر و رفتارش را به‌درستی شناسایی کنیم. با تحلیل رفتار کاربران سایت و کلمات کلیدی با جستجوی زیاد در حوزه رقابتی، می‌توان براحتی پرسونای سایت (کاربران هدف سایت) را پیدا کرد. سپس وارد فرآیند طراحی استراتژی برای جذب و نگهداری آنها شد.
                  </Typography>
                    <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    طراحی استراتژی سئو:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    مهم‌ترین مراحله سئو پروژه، مرحله طراحی استراتژی می باشد. انجام درست این مرحله نقش بسیار کلیدی برای فعالیت های بعدی است.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تحقیق کلمات کلیدی:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای جذب مخاطب هدفمند، نیاز است بدنبال کلمات کلیدی که کاربران مورد نظر ما جستجو می کنند، باشیم. یافتن این  کلمات کلیدی به ما کمک می‌کند که نقاط تماس مناسبی با مخاطبین خود پیدا کنیم.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    بهینه سازی فنی:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    موتورهای جستجو با پیاده سازی سئو تکنیکال و سئو داخلی می توانند صفحات سایت را به طور موثر پیدا کنند، بخزند و فهرست کنند. با توجه به اینکه بخش فنی در سئو نقش بسزایی دارد بنابراین باید با دقت بالا و دید جزئی قسمت فنی سایت بهینه کنیم.
                  </Typography>
                    <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    بهینه سازی و ساخت صفحات هدف:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای کلمات کلیدی انتخاب شده باید صفحه لندینگ وجود داشته باشد. طراحی این صفحه باید براساس استانداردهای سئو باشد. ممکن است صفحه لندینگ در سایت باشد ولی بهینه نباشد. در این صورت باید بهینه سازی صفحه را  طبق اصول و استانداردهای سئو انجام داد.
                  </Typography>
                    <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    تولید محتوا:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    با توجه به  کلمات کلیدی باید  تولید محتوا انجام دهید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    سئوی محتوا:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    با اصول سئو محتوایی برای محتواهای داخل سایت به موتورهای جستجو کمک می کنید تا از کوئری های محتوا به درک درستی برسند. رعایت اصول سئوی محتوایی مانند عنوان جذاب، استفاده درست از تگ‌های H1 تا H5، استفاده از URL کوتاه و مناسب و تصاویر با کیفیت در محتوای سایت، نقش موثری در سئوی سایت دارد و جایگاه صفر گوگل را از آن سایت می کند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    لینک سازی داخلی و خارجی:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    لینک‌سازی یکی از بخش‌های موثر سئو می باشد. لینک‌ بیلدینگ از خود سایت یا سایت‌های معتبر دیگر، باعث انتقال اعتبار به صفحات ضعیف تر می شود. در این مرحله باید برای صفحاتی که اعتبار کمتری دارند با منابع درست و مرتبط لینک‌سازی انجام داد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    گزارش گیری منظم و بهبود استراتژی:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    گزارش‌گیری از روند کار یکی از مهم‌ترین اقدامات  بهینه‌سازی سایت می باشد. گاهی در حین اجرای پروژه،  با بررسی آمار متوجه می شویم که یکی از اقدامات درست نیست و اثر مطلوبی روی سایت ندارد بنابراین باید با یک عمل بهتر جایگزین کنیم تا روند سئو بهبود یابد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: { xs: "90%", sm: "100%" } }}>
                    گام‌های لازم برای ارتقا رتبه سایت در گوگل
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo4} alt="ارتقا رتبه سایت - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        ارتقا رتبه سایت
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بهبود سئوی سایت دارای جنبه‌های متفاوتی می باشد و گوگل بیش از ۲۰۰ فاکتور را برای تعیین رتبه سایت بکار می گیرد. در این بخش به بررسی موارد اساسی‌ در نیازمندی‌های سئو می پردازیم.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    ۱- قابلیت خزیدن (Crawlability):
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    ربات گوگل در فضای وب می چرخد و رفتار کاربران را شبیه‌سازی می‌کند. این ربات، خزنده وب (Web Crawler) نام دارد. خزنده وب، لینک‌های موجود در صفحات متعدد در اینترنت را وارد می شود و هرچه را که در آن صفحات موجود است به سرورهای گوگل گزارش می‌کند. این روش به عنوان خزیدن یا Crawling معروف است.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    به همین دلیل باید اطمینان حاصل کنید که صفحات سایت شما قابل خزیدن و دیده شدن توسط ربات گوگل را دارد. همچنین معماری سایت با استفاده از لینک های داخلی موثر باید به گونه ای باشد که پیمایش در همه صفحات سایت برای ربات خزنده به آسانی انجام شود. لینک داخلی، همان لینکی است که در یک صفحه از سایت می گذارید  و از آن به یک صفحه دیگر در وبسایت می روید.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    ابزارهای مختلفی برای بررسی قابلیت خزش وبسایت وجود دارند از جمله ابزار <Typography component={"a"} target="_blank" href="https://www.screamingfrog.co.uk/seo-spider/" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >ScreamingFrog.</Typography> با وارد کردن عبارت “site:yourdomain.com” در جستجوی گوگل شما می توانید متوجه شوید که  سایت شما توسط گوگل کرال شده یا نه و کدام صفحات در فهرست گوگل قرار دارند. البته این روش را نمی توان بعنوان یک روش قابل اعتماد در نظر گرفت.
                  </Typography>
                  <Typography component={"h4"}
                    sx={{ color: "#000000", fontSize: { xs: "12px", sm: "16px", md: "24px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    دلایل ایندکس نشدن سایت
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در صورتیکه سایت شما یا برخی از صفحات توسط گوگل ایندکس نشده است می تواند بدلایل زیر باشد:
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    سایت شما یک سایت جدید باشد و هنوز ربات گوگل آن را ندیده است.
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    لینک‌های ورودی به تعداد مناسب در سایت شما وجود ندارد.
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    ساختار لینک‌های داخلی در سایت شما مناسب نیست. گوگل برای پیدا کردن صفحات شما نیاز به لینک‌های داخلی دارد و صفحات بدون لینک داخلی معمولا در فهرست قرار نمی گیرند.
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    در تنظیمات فنی سایت از تگ No-index استفاده کرده اید. بدین ترتیب شما مانع فهرست شدن سایت یا صفحه‌ مورد نظر توسط ربات‌‌های موتورهای جستجو شده اید.
                  </Typography>
                    <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    ۲- سازگاری با موبایل (ریسپانسیو) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    منظور از سازگاری یا ریسپانسیو بودن موبایل این است که چیدمان سایت و اندازه المان‌ها برای نمایش در موبایل بهینه سازی شود. با افزایش استفاده از گوشی‌های هوشمند برای انجام امور روزمره، تعداد افراد بیشتری با تلفن همراشان به جستجو می پردازند. با استفاده از ابزار «تست سازگاری با موبایل سایت» با عنوان <Typography component={"a"} target="_blank" href="https://search.google.com/test/mobile-friendly" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >Mobile Friendly Testing Tool</Typography> که توسط گوگل ارائه شده است می توانید  سازگاری وب سایت خود را با موبایل بررسی کنید.
                  </Typography>
                    <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    ۳- سرعت سایت
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    هدف گوگل رضایت کاربران است بنابراین زمانی که یک سایت کند عمل می کند کاربر تمایلی به بازدید از آن سایت را نخواهد داشت. بنابراین سرعت بارگذاری سایت یکی از عوامل مهم در رنکینگ سایت می باشد. گوگل برای سایت‌هایی که نسبت به درخواست‌های کاربر واکنش سریعتری داشته باشند، رتبه بالاتری را قائل می شود.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای ارزیابی سرعت سایت خود می توانید از ابزارهایی که موجود است بهره ببرید. با وارد کردن ادرس سایت خود در سایت های زیر می توانید سرعت سایت خود را ارزیابی کنید.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    <Typography component={"a"} target="_blank" href="https://gtmetrix.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >جی‌ تی‌ متریکس (GTmetrix) </Typography>
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    <Typography component={"a"} target="_blank" href="https://www.pingdom.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >پینگ دام (Pingdom) </Typography>
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    <Typography component={"a"} target="_blank" href="https://pagespeed.web.dev" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >گوگل پیج اسپید اینسایت (Google Page Speed Insights)</Typography>
                  </Typography>
                    <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    ۴- بهینه سازی تصاویر در سئو
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    استفاده از محتوای تصویری مانند اسکرین‌شات‌ها و نمودارها و یا تصاویر گرافیکی باعث می شود نوشته ها جذاب تر شوند و کاربران از مطالب سایت درک بهتری داشته باشند. همچنین تصاویر می‌توانند در قسمت جستجوی تصاویر گوگل نیز رتبه بگیرند و ترافیک سای را بالا ببرند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بهینه سازی تصاویر علاوه بر اینکه سرعت سایت را افزایش می دهد، سئو صفحه‌ای که تصاویر در آن قرار دارد را نیز بهبود می بخشد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    ۵- لینک های داخلی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    لینک داخلی تاثیر شگفت انگیزی در رتبه بندی سایت دارد. توجه به این نکته که ساختار سایت چگونه باشد و این لینک دهی داخلی به چه صورت انجام شود بسیار مهم می باشد. سایت را باید یک هرم در نظر بگیرید که در راس این هرم صفحه اصلی سایت قرار دارد. بقیه صفحات سایت بصورت سلسله مراتبی با توجه به اهمیتی که دارند، در زیر آن قرار دارند. در مقالات لینک های مربوط بهم ایجاد کنید تا با کلیک بر روی لینک‌ از درون متن یک مقاله به مقاله‌ای دیگر بروید. با این کار گوگل و کاربران از ارتباط بین صفحات و مقالات شما مطلع می شوند. لینک‌های داخلی می‌توانند داخل متن یا در انتهای مقالات باشند. همچنین انتخاب متن لینک یا انکر تکست ها نیز از اهمیت بالایی برخوردار است. سعی کنید که انکرتکست هایی که انتخاب می کنید بیش از حد طولانی و تکراری نباشند.
                  </Typography>
                    <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    ۶- تحقیق کلمات کلیدی اصلی و فرعی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    یکی از موارد مهم در سئوی سایت تحقیق در مورد کلمات کلیدی می باشد. شما باید بررسی کنید که مخاطبان شما معمولا چه کلماتی را جستجو می کنند. با توجه به کلمات کلیدی که استخراج می کنید تولید محتوا را انجام دهید و مطالب سایت را بهینه سازی کنید. تحقیق کلمات کلیدی در سئو با وجود اینکه فرآیند ساده‌ای می باشد، اما باید به درستی صورت پذیرد. برای این امر رعایت دو نکته ضروری می باشد:
                  </Typography>
                  <Typography component={"ol"}>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", marginBottom: { xs: "10px", md: "15px" } }}>
                      به حد کافی از کسب و کار خود شناخت داشته باشید.
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", marginBottom: { xs: "10px", md: "15px" } }}>
                      برای یافتن کلمات کلیدی یکسری ابزار موجود است که بهتر است با نحوه کار این ابزارهای تحقیق کلمات کلیدی آشنا باشید. از جمله این ابزارها می توان به <Typography component={"a"} target="_blank" href="https://trends.google.com/trends" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >گوگل ترندز </Typography>
                      ، گوگل <Typography component={"a"} target="_blank" href="https://trends.google.com/trends" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >سرچ کنسول </Typography>
                      اشاره داشت.
                    </Typography>
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بعد از بررسی این کلمات بهتر است واژه های مشابه با کلمات کلیدی اصلی را نیز استخراج کنید. بعد از شناسایی کلمات کلیدی بهتر است با کمک یک ابزار مشخص نمایید که میزان جستجو کدام کلمه در گوگل بیشتر می باشد. بهترین ابزار برای انجام اینکار سایت Google trends را می توان معرفی کرد.
                  </Typography>
                    <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    ۷- بک لینک
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    به لینک سایت که در سایت های دیگر قرار دارد، بک لینک گفته می شود. بک لینک بعنوان یک رای اعتماد در سایت شما می باشد. هر چه میزان این بک لینک ها بیشتر باشد رتبه سایت در گوگل بالاتر می رود. ابتدای پیدایش موتورهای جستجو بک لینک ها نقش مهمی در رنکینگ سایت ایفا می کردند و امروزه نیز یکی از عوامل تاثیرگذار در بهینه سازی سایت می باشند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    ۸- استفاده از گواهینامه SSL
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    گواهینامه SSL یک لایه امنیتی به اطلاعات رد و بدل شده بین شما و سرور سایت اضاف می کند. این عمل باعث افزایش امنیت اطلاعات رد و بدل شده می شود. اگر به نوار آدرس مرورگر دقت کنید آدرس برخی از سایت‌ها با https آغاز می‌شود و یک علامت قفل بسته در کنار آدرس قرار دارد که نشان می دهد این سایت‌ها از اس اس ال استفاده می‌کنند. در سال ۲۰۱۴ گوگل اعلام کرد که سایت‌هایی که دارای گواهینامه SSL هستند رتبه بهتری می گیرند. بنابراین در ابتدای راه اندازی سایت گواهینامه SSL را نصب کنید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    ۹- استفاده از نقشه سایت یا سایت مپ
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    نقشه سایت یک فایل با فرمت XML می باشد که آدرس تمام صفحاتی از سایت را شامل می شود که شما تمایل دارید توسط موتورهای جستجو فهرست شوند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    ۱۰- بهینه سازی محتوای سایت:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    گوگل همواره در تلاش است تا بهترین و مفیدترین نتایج را برای کاربران نمایش داده شود. بنابراین با تولید محتوای مناسب شانس شما برای کسب رتبه اول در صفحات گوگل بالا خواهد رفت. سعی کنید برای تولید محتوا از کپی کردن مطالب از سایت های دیگر بپرهیزید. جملات کوتاه برای تولید محتوا بکار ببرید. همچنین پاراگراف‌های طولانی استفاده نکنید و بین پاراگراف‌ها تصاویر مناسب قرار دهید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: { xs: "90%", sm: "100%" } }}>
                    انواع سئو
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    سئو سایت بطور کلی به دو دسته تقسیم می شود که یک نوع سئو بر اساس فعالیت داریم و یک نوع سئو هم بر اساس استراژدی که در زیر به طور مفصل به هر کدام می پردازیم.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: { xs: "90%", sm: "100%" } }}>
                    انواع سئو سایت بر اساس نوع فعالیت
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    فعالیت‌های سئو دارای چندین دسته است. اولین دسته‌بندی، سئوی داخلی و سئوی خارجی می باشد. سئوی داخلی به دو بخش سئو تکنیکال و سئو محتوا تقسیم می شود. هر کدام از این بخش ها فعالیت خاص خود را انجام می دهند. نمی توان تنها یک بخش از سئو را برای پروژه در نظر گرفت.  سئو یک فعالیت همه‌جانبه می باشد که باید تمام بخش‌ها به‌صورت منظم اجرا شوند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    سئو داخلی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    تمام فعالیت‌هایی که در سایت کسب‌ و کار انجام می شود را سئو داخلی می نامند. بهینه‌سازی بخش فنی سایت و محتواهای سایت شامل این بخش می شوند. سئو داخلی زمینه ساز فعالیت‌های سئو خارجی می باشد. یعنی قبل از سئو خارجی ابتدا باید با سئو داخلی سایت را بهینه سازی کرد.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    سئو محتوا
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    سئو محتوا یکی از بخش‌های سئو داخلی می باشد. هر عملی در جهت بهینه‌سازی محتواهای سایت سئو محتوا محسوب می شود. بهینه‌سازی متاتگ‌ها، بهبود متن محتواها، بهبود انکرتکست‌ لینک‌های داخلی، بهینه‌سازی لینک‌های داخلی بخشی از سئو محتوا می باشند. با اینکه بهینه سازی محتوا معمولا ساده به‌نظر می‌آید اما این عمل نیازمند افراد متخصص برای انجام می باشد.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    سئو تکنیکال
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    سئو تکنیکال یا سئو فنی، بهینه سازی فنی صفحات سایت جهت کراول کردن و ایندکس شدن سایت برای موتورهای جستجو می باشد. سئو تکنیکال مجموعه عملیات مربوط به موتورهای جستجو، ربات ها و کدهای برنامه نویسی است. در سئو تکنیکال هدف ارتقا جنبه های فنی سایت و افزایش رتبه سایت در موتورهای جستجو می باشد. اگر سئو تکنیکال به درستی صورت پذیرد سرعت سایت افزایش می یابد و محتوای سایت توسط ربات های موتور جستجو قابل فهم تر می شود.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    سئو خارجی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    هر اقدام بهینه سازی که خارج از سایت کارفرما انجام پذیرد سئو خارجی یا Off Page SEO می باشد. این بخش سئو در روند رشد سایت می تواند بسیار موثر باشد اما به همان میزان نیز می تواند باعث جریمه و پنالتی شدن سایت شود. از این رو سئو خارجی در بین اکثر متخصصین سئو به لبه تیز شمشیر معروف است. ساخت لینک خارجی، رپورتاژ آگهی، سیگنال شبکه‌های شبکه‌های اجتماعی بخشی از فعالیت‌های سئو خارجی می باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    انواع سئو سایت بر اساس استراتژی
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo5} alt="انواع سئو - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        انواع سئوسایت
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بهینه سازی سایت از نظر استراتژیک به سه دسته تقسیم می‌ شود. این سئو تعیین کننده چارچوب فعالیت‌های سایت می باشد.  این سه دسته عبارتند از: سئو کلاه سفید، سئو کلاه سیاه، سئو کلاه خاکستری.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    سئو کلاه سفید
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در این بخش تیم سئو، تنها اقداماتی که از نظر گوگل مورد تایید هستند را انجام می دهند. در سئوی کلاه سفید ریسک پنالتی شدن سایت وجود ندارد و به همین دلیل مطمئن ترین روش سئو می باشد. بهینه‌سازی محتوا، بهینه‌سازی متاتگ‌ها از جمله فرآیند سئوی کلاه سفید می باشند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    سئو کلاه سیاه
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    سئو کلاه سیاه برخلاف سئو کلاه سفید اقداماتی را انجام می دهد که برخلاف قوانین گوگل می باشد. این نوع از سئو   با اینکه ممکن است سبب رشد سایت شود اما احتمال پنالتی شدن در آن بسیار بالاست. متخصص سئو با این روش فرآیندی را انجام می دهند که باعث می شود رتبه سایت بیش از حد بالا برود اما در صورت متوجه شدن گوگل سایت جریمه می‌شود. لینک سازی دستی خارجی، روش‌های گول‌زدن ربات گوگل از جمله اقدامات سئو کلاه سیاه می باشند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    سئو کلاه خاکستری
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    سئو کلاه خاکستری ترکیبی از سئوی کلاه سفید و کلاه سیاه می باشد. در این مدل سئو متخصص سئو با اینکه بیشتر اقداماتش در حوزه کلاه سفید است اما تا حدودی هم ازسئوی کلاه سیاه استفاده می کند. در این استراتژی، تنها در صورت نیاز وارد حوزه سئو کلاه سیاه می‌شویم.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    آمارهای جالب در مورد سئو
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo6} alt="امارهای  سئو - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        امارهای  سئو
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    با توجه بر اهمیت SEO در اینجا لازم دیدیم تا برخی آمارهای منتشر شده درباره موتورهای جستجو را ذکر نماییم.
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    مردم جهان در روز بطور تقریبی ۶ تا ۷ میلیارد بار در فضای اینترنت جستجو می کنند.
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    هفتاد تا هشتاد درصد کاربران، به لینک‌های تبلیغی (با پرداخت پول)  که توسط موتورهای جستجو از جمله گوگل، نمایش داده می‌شوند، توجهی ندارند.
                  </Typography>
                 <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    کسی که از طریق کلیک کردن روی لینک در موتور جستجو وارد سایت شما می‌شود برای تبدیل شدن به مشتری در مقایسه با کسی که با بازاریابی وارد فضای آنلاین شما می شود هزینه کمتری دارد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    نکته طلایی برای بهینه سازی سایت
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زمانی که کلمات کلیدی با املای متفاوت دارید صفحات جداگانه برای هر املا تولید نکنید. مثلا برای دو کلمه کلیدی تهران و طهران دو صفحه جدا نسازید. برای رتبه گرفتن برای هر دو کلمه باید یک صفحه داشته باشید و هر دو واژه را به صفحه لینک دهید. این نکته درباره واژه هایی مانند "طراحی سایت تهران" و "طراحی سایت در تهران " نیز به همین ترتیب می باشد.
                  </Typography>

                  {/* do not change */}
                </Grid>
                {author(articleData.author, t)}
                {tags(articleData.tags, t)}
                {linkArticle(t, url)}
                {sourceLink(sourceAddress, t)}
                {view(dataview, t)}

              </Grid>
              :
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "80%", sm: "60%", md: "70%" } }}>
                <Loader />
              </Grid>
          }
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
export default Article02;