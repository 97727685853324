import indexPhoto1 from '../../image/articles/01/indexPhoto1.jpg'
import indexPhoto2 from  '../Magzin/../../image/articles/02/indexPhoto2.jpg';
import indexPhoto3 from  '../Magzin/../../image/articles/03/indexPhoto3.jpg';
import indexPhoto4 from  '../Magzin/../../image/articles/04/indexPhoto4.jpg';
import indexPhoto5 from  '../Magzin/../../image/articles/05/indexPhoto5.jpg';
import indexPhoto6 from  '../Magzin/../../image/articles/06/indexPhoto6.jpg';
import indexPhoto7 from  '../Magzin/../../image/articles/07/indexPhoto7.jpg';
import indexPhoto8 from  '../Magzin/../../image/articles/08/indexPhoto8.jpg';
import indexPhoto9 from  '../Magzin/../../image/articles/09/indexPhoto9.jpg';
import indexPhoto10 from  '../Magzin/../../image/articles/10/indexPhoto10.jpg';
import indexPhoto11 from  '../Magzin/../../image/articles/11/indexPhoto11.jpg';
import indexPhoto12 from  '../Magzin/../../image/articles/12/indexPhoto12.jpg';
import indexPhoto13 from  '../Magzin/../../image/articles/13/indexPhoto13.jpg';
import indexPhoto14 from  '../Magzin/../../image/articles/14/indexPhoto14.jpg';
import indexPhoto15 from  '../Magzin/../../image/articles/15/indexPhoto15.jpg';
import indexPhoto16 from  '../Magzin/../../image/articles/16/indexPhoto16.jpg';
import indexPhoto17 from  '../Magzin/../../image/articles/17/indexPhoto17.png';
import indexPhoto18 from  '../Magzin/../../image/articles/18/indexPhoto18.webp';

const tag = ["Programming", "UIUX", "Graphic", "WebDesign", "Seo", "Application", "Beginner", "Advanced", "Intermediate","Technology","Knowable"]

export const Articles = [
  {
    id: 1,
    title:"مواردی که پیش از طراحی وب سایت باید بدانید | بهترین شرکت سفارش ساخت سایت در تهران ",
    desc: "اکثر کسب و کارهای امروزی آنلاین هستند و بیشتر افراد کارهای خود را اینترنتی انجام می دهند، بنابراین طراحی سایت اهمیت بالایی پیدا کرده است.",
    keywords: " نکات مهم پیش از سفارش طراحی سایت | راهنمای سفارش طراحی سایت | نکات طلایی برای سفارش سایت | اهمیت طراحی سایت | شرکت طراحی سایت | طراحی و ساخت انواع وب سایت",
    h1:"نکاتی که باید برای سفارش طراحی وب سایت در نظر گرفت",
    image: indexPhoto1,
    author: "نیلوفر پورقلی",
    date: "1402,03,07",
    link: "/Points_to_consider_when_ordering_a_website_design",
    suggested: "no",
    tags: ["WebDesign", "Application"],
    source: "",
    timeRead: "7"
  },
  {
    id: 2,
    title: "مفاهیم سئو به زبان ساده | همه چیز در مورد بهینه سازی سایت در موتورهای جستجوگر | بهترین شرکت سئو کار در تهران ",
    desc: "سئو یا بهینه سازی موتورهای جستجو را می توان مجموعه اقداماتی که برای بهبود رتبه سایت در موتورهای جستجو نظیر گوگل انجام می‌شود، نامید.",
    keywords: "سئو سایت چیست، بهینه سازی سایت برای موتورهای جستجو، اهداف سئو، انواع سئو سایت، نقش سئو در کیفیت محتوا، عوامل تاثیرگذار در سئو، مواردی که در سئو باید توجه کنیم",
    h1: "سئو و تمام نکاتی که باید در آن رعایت کرد",
    image: indexPhoto2,
    author: "نیلوفر پورقلی",
    date: "1402,03,30",
    link: "/what-is-seo",
    suggested: "yes",
    tags: ["Seo", "Beginner"],
    source: "",
    timeRead: "15"
  },
  {
    id: 3,
    title: "آشنایی با لوگوهای معروف دنیا | تاریخچه و داستان برندهای جهان | بهترین شرکت طراحی لوگو در تهران ",
    desc: "هر کدام از لوگوهای معروف جهان داستانی را در خود دارند. ما در این مقاله از سورین به بررسی داستان های مربوط به بهترین لوگوهای جهان می پردازیم. ",
    keywords:"زیباترین لوگوهای جهان | لوگوهای خاص دنیا | لوگوهای خلاقانه جهان | بررسی خاص ترین لوگوهای جهان | معرفی لوگوهای معروف دنیا | برترین لوگو برندهای جهان |مشهورترین لوگو برندهای دنیا ",
    h1: "معرفی بهترین لوگوهای جهان در سال 2023",
    image: indexPhoto3,
    author: "نیلوفر پورقلی",
    date: "1402,04,04",
    link: "/the-best-logos-in-the-world",
    suggested: "no",
    tags: ["Graphic", "Beginner"],
    source: "",
    timeRead: "10"
  },
  {
    id: 4,
    title: "محبوب ترین روند طراحی UI/UX در 2023 | زمینه های مورد توجه UI/UX در سال 2023 | ترند هایی که مخاطب را تحت تاثیر قرار می دهد | بهترین شرکت طراحی UI/UX در تهران | وب گستران سورین",
    desc: "رابط کاربری و تجربه کاربری، پارامترهایی مهم در طراحی سایت می باشند؛ بنابراین در سورین به بررسی ترند های طراحی UI و UX سال 2023 می پردازیم. ",
    keywords:"ترندهای 2023 طراحی گرافیک | خلاقانه ترین ترندهای طراحی سایت | محبوب ترین روند طراحی گرافیک | برترین ترند های طراحی UI و UX | ترندهای تاثیر گذار UI و UX در 2023 | زمینه‌های مورد توجه طراحی UI-UX | ترندهای طراحی رابط کاربری و طراحی تجربه کاربری سال 2023 ",
    h1: "ترند طراحی UI و UX سال 2023",
    image: indexPhoto4,
    author: "نیلوفر پورقلی",
    date: "1402,04,07",
    link: "/2023-UI-UX-design-trend",
    suggested: "yes",
    tags: ["UIUX", "Graphic", "Intermediate"],
    source:"",
    timeRead:"10"
  },
  {
    id: 5,
    title: "مراحل شروع کسب و کار در اینستاگرام | روش های درامدزایی از اینستاگرام | ایجاد اکانت کسب و کار در اینستاگرام | بهترین شرکت طراحی پیج کاری در تهران ",
    desc: "با توجه به اینکه اینستاگرام رونق زیادی به کسب و کارها داده است ما در اینجا به بررسی نکات مهم در مورد داشتن پیج کاری موفق اینستاگرام میپردازیم. ",
    keywords:" ساخت پیج فروش در اینستاگرام | ایجاد پیج کاری حرفه ای در اینستاگرام | صفحه اینستاگرام حرفه ای برای کسب و کارها | آموزش راه اندازی پیج اینستاگرام | راه اندازی اصولی پیج کاری اینستاگرام | ایجاد اکانت حرفه ای کسب و کار در اینستاگرام | صفر تا صد راه اندازی پیج تجاری در اینستاگرام | پیج حرفه ای کسب و کار در اینستاگرام ",
    h1: "صفر تا صد راه اندازی پیج حرفه ای کاری اینستاگرام",
    image: indexPhoto5,
    author: "نیلوفر پورقلی",
    date: "1402,04,12",
    link: "/instragram-business-page",
    suggested: "yes",
    tags: ["Seo", "Application", "Intermediate"],
    source:"",
    timeRead:"10"
  },
  {
    id: 6,
    title: "همه چیز در مورد اپلیکیشن و انواع آن | توضیح کامل نرم افزار گوشی همراه | بهترین شرکت طراحی اپلیکیشن در تهران ",
    desc: "اپلیکیشن که روی گوشی های هوشمند، تبلت و ساعت های هوشمند اجرا می شود در فروشگاه های نرم افزاری مخصوص بسته به سیستم عامل دستگاه عرضه می شود. ",
    keywords:"اپلیکیشن موبایل چیست | اپ تلفن همراه | نصب اپلیکیشن بر روی گوشی | طراحی اپ موبایل | نرم افزار کاربری گوشی تلفن همراه | نرم افزار اختصاصی موبایل | طراحی اپلیکیشن IOS | طراحی اپلیکیشن android | نرم افزار کاربردی | طراحی اپ PWA",
    h1: "اپلیکیشن چیست؟ هر آنچه در مورد نرم افزار کاربردی باید بدانید",
    image: indexPhoto6,
    author: "نیلوفر پورقلی",
    date: "1402,04,24",
    link: "/what-is-application",
    suggested: "no",
    tags: ["Application", "Intermediate"],
    source:"",
    timeRead:"10"
  },
  {
    id: 7,
    title: " آشنایی با اصطلاحات مهم طراحی سایت | واژه های پر کاربرد و رایج در طراحی سایت | بهترین شرکت طراحی سایت  ",
    desc: "ما در این مقاله از سورین شما را با برخی از اصطلاحات پرکاربرد برنامه نویسی و طراحی سایت آشنا می کنیم. پس با ما همراه باشید.",
    keywords:"اصطلاحات برنامه نویسی | آشنایی با مفاهیم پایه طراحی | دایره لغات برنامه نویسی | کلمات پر کاربرد طراحی سایت | دیکشنری طراحی سایت",
    h1: "اصطلاحات پرکاربرد طراحی سایت",
    image: indexPhoto7,
    author: "نیلوفر پورقلی",
    date: "1402,05,04",
    link: "/common-words-in-web-design",
    suggested: "yes",
    tags: ["WebDesign", "Beginner"],
    source:"",
    timeRead:"10"
  },
  {
    id: 8,
    title: "آموزش جامع اینستاگرام | همه چیز در مورد اینستاگرام به زبان ساده | بهترین شرکت راهنمای پیج اینستاگرام در تهران ",
    desc: "اینستاگرام یک شبکه اجتماعی رایگان اشتراک گذاری عکس و ویدئو می باشد که بسیار محبوب و کاربردی می باشد و این محبوبیت روز به روز افزایش می یابد.",
    keywords:"صفر تا صد اینستاگرام | هر آنچه باید در مورد اینستاگرام بدانید | آموزش اینستاگرام برای افراد مبتدی | روش نصب و کار با اپ اینستا | زیر و بم اپلیکیشن موبایلی اینستاگرام | اینستاگرام چیست و چگونه کار می کند",
    h1: "اینستاگرام از صفر تا صد ؛ بررسی اپلیکیشن محبوب فضای مجازی",
    image: indexPhoto8,
    author: "نیلوفر پورقلی",
    date: "1402,05,18",
    link: "/all-about-instagram",
    suggested: "yes",
    tags: ["Seo", "Application", "Beginner"],
    source:"",
    timeRead:"8"
  },
  {
    id: 9,
    title: "بررسی سئو به زبان ساده | زیر و بم سئوی مبتدی | بهترین شرکت سئوکار در تهران ",
    desc: "سئو سایت تاثیر بسزایی در افزایش بازدید و بالا رفتن درآمد برای کسانی که کسب و کار جدیدی در محیط اینترنت راه اندازی کرده اند دارد.",
    h1:  "سئو سایت چیست و چرا باید به سئو سایت اهمیت دهیم؟",
    keywords:"بهینه سازی سایت | نکات مهم سئو کردن سایت | اهمیت سئو در دیجیتال مارکتینگ | مدت زمان لازم برای سئو | بهینه سازی وب سایت در موتورهای جستجو | بالا بردن رتبه گوگل | بهترین شرکت سئو کار | سئو به زبان ساده | افزایش رتبه در موتورهای جستجوگر",
    image: indexPhoto9,
    author: "نیلوفر پورقلی",
    date: "1402,05,21",
    link: "/SEO-Importance",
    suggested: "no",
    tags: ["Seo", "Beginner"],
    source:"",
    timeRead:"10"
  },
  {
    id: 10,
    title: "دیجیتال مارکتینگ چیست و بازاریابی اینترنتی یعنی چی؟",
    desc: "در دنیای امروزی یکی از راه های موفقیت در کسب و کار، آشنایی با دیجیتال مارکتینگ و بازاریابی آنلاین می باشد.",
    keywords:"بازاریابی دیجیتال | بازاریابی آنلاین | کاربرد دیجیتال مارکتینگ | اهمیت دیجیتال مارکتینگ | روش های بازاریابی دیجیتال | استراتژی بازاریابی دیجیتال | خدمات دیجیتال مارکتینگ | بازاریابی اینترنتی | آنلاین مارکتینگ | تبلیغات اینترنتی | تبلیغات آنلاین",
    h1: "همه نکاتی که در مورد دیجیتال مارکتینگ یا بازاریابی اینترنتی باید بدانید",
    image: indexPhoto10,
    author: "نیلوفر پورقلی",
    date: "1402,05,23",
    link: "/What-Is-Digital-Marketing",
    suggested: "yes",
    tags: ["Seo", "Intermediate"],
    source:"",
    timeRead:"10"
  },
  {
    id: 11,
    title: "بهترین و کاراترین زبان برنامه نویسی در سال 2023 | همراه با توضیح کامل و مثال کاربردی | بهترین شرکت برنامه نویسی در تهران ",
    desc: "در این مقاله از سورین به بررسی ده زبان برنامه نویسی محبوب سال 2023 و 1402 از لحاظ کارایی و بازار کار می پردازیم.",
    keywords:"رتبه بندی زبان های برنامه نویسی | مقایسه زبان های برنامه نویسی | بهترین زبان برنامه نویسی برای شروع | پردرآمد ترین زبان برنامه نویسی | بهترین زبان برنامه نویسی | پرکاربردترین زبان برنامه نویسی | محبوب ترین زبان برنامه سازی",
    h1: "ده زبان برنامه نویسی محبوب 2023",
    image: indexPhoto11,
    author: "نیلوفر پورقلی",
    date: "1402,05,25",
    link: "/popular-programming-language-2023",
    suggested: "yes",
    tags: ["Programming", "Intermediate"],
    source:"",
    timeRead:"8"
  },
  {
    id: 12,
    title: "زبان برنامه نویسی پایتون چیست و چه کاربردی دارد | مزایا و معایب زبان پایتون | بهترین شرکت طراح سایت با پایتون ",
    desc: "زبان پایتون یک زبان برنامه‌نویسی شیءگرا، مفسری ، سطح بالا، و همه منظوره است توسط خیدو فان پا به دنیای برنامه نویسی گذاشت.",
    keywords:"پایتون به زبان ساده | آموزش مبتدی زبان پایتون | آشنایی با زبان برنامه نویسی پایتون | معرفی کامل زبان برنامه سازی پایتون | پایتون چیست و چگونه کار میکند | تاریخچه پایتون | امنیت پایتون | آموزش از صفر پایتون | آموزش پایتون جادی میرمیرانی",
    h1: "معرفی صفر تا صد زبان برنامه پایتون برای مبتدی ها",
    image: indexPhoto12,
    author: "نیلوفر پورقلی",
    date: "1402,06,01",
    link: "/What-Is-Python-Programming-Language",
    suggested: "no",
    tags: ["Programming", "Beginner"],
    source:"",
    timeRead:"8"
  },
  {
    id: 13,
    title: "زبان برنامه نویسی جاوا اسکریپت چیست و چه کاربردی دارد | مزایا و معایب زبان جاوا اسکریپت | بهترین شرکت طراح سایت با جاوا اسکریپت ",
    desc: "جاوا اسکریپت یک زبان برنامه نویسی همه منظوره می باشد که هم در بخش front-end و هم در بخش back-end توسعه وب مورد استفاده قرار می گیرد.",
    keywords:"جاوا اسکریپت به زبان ساده | آموزش مبتدی زبان جاوا اسکریپت | آشنایی با زبان برنامه نویسی جاوا اسکریپت | معرفی کامل زبان برنامه سازی جاوا اسکریپت | جاوا اسکریپت چیست و چگونه کار میکند | تاریخچه جاوا اسکریپت | آموزش از صفر تا صد جاوا اسکریپت ",
    h1: "معرفی صفر تا صد زبان جاوا اسکریپت برای مبتدی ها",
    image: indexPhoto13,
    author: "نیلوفر پورقلی",
    date: "1402,06,06",
    link: "/What-Is-Javascript-Programming-Language",
    suggested: "no",
    tags: ["Programming", "Beginner"],
    source:"",
    timeRead:"10"
  },
  {
    id: 14,
    title: "زبان برنامه نویسی جاوا چیست و چه کاربردی دارد | مزایا و معایب زبان Java  ",
    desc: "جاوا یک زبان برنامه نویسی همه منظوره و شئ گرا می باشد که در مقایسه با سایر زبان های برنامه نویسی وابستگی کمتری به پیاده سازی دارد. ",
    keywords:" جاوا به زبان ساده | آموزش مبتدی زبان جاوا | آشنایی با زبان برنامه نویسی جاوا | معرفی کامل زبان برنامه سازی جاوا | جاوا چیست و چگونه کار میکند | تاریخچه جاوا | امنیت جاوا | آموزش از صفر جاوا",
    h1: "معرفی صفر تا صد زبان جاوا برای مبتدی ها",
    image: indexPhoto14,
    author: "نیلوفر پورقلی",
    date: "1402,06,11",
    link: "/What-Is-JAVA-Programming-Language",
    suggested: "no",
    tags: ["Programming", "Beginner"],
    source:"",
    timeRead:"9"
  },
  {
    id: 15,
    title: "هوش مصنوعی به زبان ساده ؛ آشنایی با مزایا و کاربردهای این تکنولوژی ",
    desc: "هوش مصنوعی شاخه‌ای وسیع از علوم کامپیوتر می باشد که نحوه عملکرد آن مشابه مغز انسان در حل مسائل مختلف است. ",
    keywords:"هوش مصنوعی یعنی چی | فناوری هوش مصنوعی چیست | تاریخچه هوش مصنوعی | برنامه هوش مصنوعی | مخترع هوش مصنوعی | مقاله کوتاه در مورد هوش مصنوعی | خطرات هوش مصنوعی | آینده هوش مصنوعی | عکس هوش مصنوعی | AI چیست ",
    h1: "هوش مصنوعی چیست (AI) و چه کاربردی دارد؟",
    image: indexPhoto15,
    author: "نیلوفر پورقلی",
    date: "1402,06,18",
    link: "/What-Is-Artificial-Intelligence",
    suggested: "no",
    tags: ["Programming", "Intermediate"],
    source:"yes",
    timeRead:"10"
  },  
  {
    id: 16,
    title: "کاتالوگ چیست و چه کاربردی دارد",
    desc: "کاتالوگ راهنمایی جامع از محصولات و خدمات می باشد که تمامی گزینه‌های موجود را به شکل سازمان‌دهی شده برای مشتری به نشان می‌گذارد.  ",
    keywords:"طراحی کاتالوگ در فتوشاپ | تفاوت کاتالوگ و بروشور | هزینه طراحی کاتالوگ | نمونه طراحی کاتالوگ | بهترین شرکت طراحی کاتالوگ | انواع کاتالوگ | اصول طراحی کاتالوگ | نقش کاتالوک در بازاریابی | ویژگی های کاتالوگ | طراحی کاتالوگ با نرم افزار | طراحی کاتالوگ حرفه ای",
    h1: "همه چیز در مورد طراحی کاتالوگ و تاثیر آن بر برندینگ",
    image: indexPhoto16,
    author: "نیلوفر پورقلی",
    date: "1402,07,03",
    link: "/All-About-Catalog-Design",
    suggested: "yes",
    tags: ["Graphic", "Beginner"],
    source:"yes",
    timeRead:"13"
  },  
  {
    id: 17,
    title: "زبان برنامه نویسی سی شارپ چیست و چه کاربردی دارد | مزایا و معایب زبان C# ",
    desc: "سی شارپ یک زبان برنامه نویسی همه منظوره و شئ گرا می باشد که یکی از محبوب ترین زبان های برنامه نویسی می باشد.",
    keywords:"زبان برنامه نویسی سی شارپ | کاربرد زبان برنامه نویسی سی شارپ | مزایا و معایب زبان سی شارپ | بهترین شرکت طراح سایت با سی شارپ | وب گستران سورین | سی شارپ به زبان ساده | آموزش مبتدی زبان سی شارپ | آشنایی با زبان برنامه نویسی سی شارپ | معرفی کامل زبان برنامه سازی سی شارپ | نحوه کار با سی شارپ | C Sharp | تاریخچه سی شارپ | امنیت سی شارپ | آموزش از صفر سی شارپ | #C ",
    h1: "معرفی صفر تا صد زبان برنامه نویسی سی شارپ برای مبتدی ها",
    image: indexPhoto17,
    author: "نیلوفر پورقلی",
    date: "1402,11,16",
    link: "/What-Is-CSharp-Programming-Language",
    suggested: "yes",
    tags: ["Programming", "Beginner"],
    source:"yes",
    timeRead:"14"
  },  
  {
    id: 18,
    title: "زبان برنامه نویسی کاتلین چیست و چه کاربردی دارد | مزایا و معایب زبان Kotlin ",
    desc: "کاتلین زبان برنامه‌نویسی مدرن و پرکاربردی است که امکانات فراوانی را برای برنامه‌نویسان فراهم می‌کند و باعث کاهش هزینه‌ها و زمان می شود.",
    keywords:"زبان برنامه نویسی کاتلین | کاربرد زبان برنامه نویسی کاتلین | مزایا و معایب زبان کاتلین | بهترین شرکت طراح سایت با kotlin | وب گستران سورین | کاتلین به زبان ساده | آموزش مبتدی زبان کاتلین | آشنایی با زبان برنامه نویسی کاتلین | معرفی کامل زبان برنامه سازی کاتلین | نحوه کار با کاتلین | KOTLIN | تاریخچه کاتلین | امنیت کاتلین | آموزش از صفر کاتلین | kotlin ",
    h1: "معرفی صفر تا صد زبان برنامه نویسی کاتلین برای مبتدی ها",
    image: indexPhoto18,
    author: "نیلوفر پورقلی",
    date: "1403,02,17",
    link: "/What-Is-Kotlin-Programming-Language",
    suggested: "yes",
    tags: ["Programming", "Beginner"],
    source:"yes",
    timeRead:"15"
  },  
]