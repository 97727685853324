import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import { author, date, h1, headPhoto, largePhoto, linkArticle, rootArticle, tags, paragraph, timeRead, view, listItemCircle, h2, h4, smallPhoto, sourceLink } from "../articleFunction/ArticleFunction";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { Articles } from "./Articles";
import ArticleSide from "../articleFunction/ArticleSide";
import { NavLink } from "react-router-dom";
//import photo
import photo1 from '../../image/articles/12/photo1.jpg';
import photo2 from '../../image/articles/12/photo2.jpg';
import photo3 from '../../image/articles/12/photo3.jpg';
import photo4 from '../../image/articles/12/photo4.jpg';
import photo5 from '../../image/articles/12/photo5.jpg';
import photo6 from '../../image/articles/12/photo6.jpg';
import photo7 from '../../image/articles/12/photo7.jpg';
import photo8 from '../../image/articles/12/photo8.jpg';



import Loader from "../Loader";
import PortfolioButton from "../PortfolioButton";


let articleData = null;
const Article12 = () => {

  //NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${articleData !== null && articleData.title}`
    document.getElementById("main-heading").innerHTML = articleData !== null && articleData.h1;
    document.getElementsByTagName('meta')["description"].content = articleData !== null && articleData.desc;
    document.getElementsByTagName('meta')["keywords"].content = articleData !== null && articleData.keywords;
    document.getElementsByTagName('meta')["author"].content = articleData !== null && articleData.author;
    setCanonicalTag()
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item
    })
  }, [])

  useEffect(() => {
    articleData !== null && addView(); getView();
  }, [])

  const addView = () => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v1/article/addView",
      method: "POST",
      data: { id: articleData.id }
    }))
      .then((res) => { })
  }
  const getView = () => {
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/article/getView`,
      method: "GET",
      params: { id: articleData.id }
    }))
      .then((res) => {
        setDataView(res.data.data)
      })
  }

  //change This part

  //read More
  const readMore = [
    {
      id: 1,
      title: "محبوب ترین زبان های برنامه نویسی در سال 2023",
      link: "/popular-programming-language-2023"
    },
    {
      id: 2,
      title: "اصطلاحات پرکاربرد طراحی سایت",
      link: "/common-words-in-web-design"
    },
    {
        id: 3,
        title: "نکاتی که باید برای سفارش طراحی وب سایت در نظر گرفت",
        link: "/Points_to_consider_when_ordering_a_website_design"
      },
      {
        id: 4,
        title: "اپلیکیشن چیست و هر آنچه در مورد نرم افزار کاربردی تلف همراه باید بدانید",
        link: "/what-is-application"
      },
      {
        id: 5,
        title: "سئو سایت چیست و چرا باید به سئو اهمیت دهیم",
        link: "/SEO-Importance"
      },


  ]
  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: "rtl", alignItems: "center" }}>
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid container sx={{ display: "flex", alignItems: { xs: "unset", sm: 'flex-start' }, justifyContent: "space-between", flexDirection: "row", flexWrap: "wrap", width: { xs: "100%", sm: "85%" } }}>
          <ArticleSide tags={articleData !== null && articleData.tags} readMore={readMore} />
          {
            articleData !== null ?
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: 'center', sm: "flex-end" }, flexDirection: "column", width: { xs: "100%", sm: "60%", md: "70%" } }} >
                {/* do not change */}
                {headPhoto(articleData.image)}
                {h1(articleData.h1, t)}
                {timeRead(articleData.timeRead, t)}
                {date(articleData.date, i18n)}
                <Grid sx={{ display: 'flex', alignItems: { xs: 'center', sm: 'flex-start' }, justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "column" }}>
                  {/* The text of the article */}
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    وب گستران <NavLink to="/" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >سورین </NavLink>
                    به عنوان شرکت پیشرو در صنعت نرم افزار و فناوری اطلاعات کشور برخود واجب می داند به افزایش دانش و آگاهی جوانان ایران در زمینه تکنولوژی های روز کمک کند. در این مقاله تیم محتوای سورین مطالب مفیدی را درباره زبان پایتون در اختیار شما قرار می دهد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در انتهای این مقاله شما پاسخ پرسش هایی نظیر زبان پایتون چیست و چه کسی آن را طراحی کرده است؟ و کاربرد این زبان و معایب و ویژگی های آن را دریافت خواهید کرد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای اموزش بیشتر مقالات <NavLink to="/Academy" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >آکادمی </NavLink>
                    را مطالعه بفرمایید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    مقدمه :
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo1} alt="زبان پایتون - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        زبان پایتون
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                   یکی از زبان های <NavLink to="/Academy/Programming" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >برنامه نویسی </NavLink>
                   که افراد زیادی داوطلب یادگیری آن هستند، <NavLink to="/Academy/popular-programming-language-2023" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >زبان </NavLink>
                   برنامه نویسی پایتون (<Typography component={"a"} target="_blank" href="https://www.python.org" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >text</Typography>
                 ) می باشد. پایتون زبانی همه‌منظوره و توسعه داده شده، می باشد و به توسعه نوع خاصی از نرم‌افزارها  محدود نمی شود. پایتون از «تحلیل داده» (Data Analysis) تا ساخت بازی‌های کامپیوتری کارایی دارد. همچنین پایتون در علم داده، هوش مصنوعی، برنامه نویسی وب، خودکارسازی فرآیندها و بسیاری از حوزه‌های دیگر قابل استفاده می باشد. از آنجا که پایتون زبانی سطح بالا می باشد بنابراین یادگیری آن نسبت به دیگر زبان‌های برنامه نویسی آسان‌تر می باشد. همچنین نسبت به سایر زبان‌های برنامه نویسی، سینتکس و قواعد نحوی ساده تری دارد.  
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    پایتون چیست؟
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    پایتون از محبوب‌ترین زبان‌های برنامه‌نویسی دهه کنونی است که طی سال‌های اخیر جز پنج زبان برنامه‌نویسی محبوب دنیا شده است. زبان پایتون، در رده‌بندی‌های گوناگونی که برای زبان‌های برنامه‌نویسی، تنوع و تعدد فرصت‌های شغلی آن‌ها ارائه می‌شود در رده ی اول و دوم قرار دارد. پایتون یک زبان برنامه نویسی همه‌کاره می باشد که برای ساخت برنامه های کامپیوتری، طراحی سایت و حتی ساختن برنامه های محبوبی مثل اینستاگرام یا نت‌فلیکس و اسپاتیفای کارایی دارد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بدون اغراق می توان گفت این زبان برای تمامی افراد اعم از مبتدی تا برنامه نویس حرفه‌ای قابل استفاده می باشد. 
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تاریخچه زبان پایتون
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo2} alt=" خیدوفان مخترع پایتون - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        خیدوفان
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                      sx={{
                        color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", marginBottom: { xs: "10px", md: "15px" }
                      }}>
                      آقای Guido <Typography component={"a"} target="_blank" href="https://en.wikipedia.org/wiki/Guido_van_Rossum" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >van </Typography>
                      Rossum طی سال‌های 1985 تا 1990 پایتون را به دنیای برنامه نویسی معرفی کرد.
                    </Typography>
                    <Typography component={"p"}
                      sx={{
                        color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", marginBottom: { xs: "10px", md: "15px" }
                      }}>
                      خیدو فان روسوم در زمستان 1985، ایده ساخت یک زبان برنامه نویسی کارا و راحت به ذهنش خطور کرد. حدود 4 سال زمان برد تا خیدوجان در دانشگاه هلند توانست با پایتون برنامه نویسی کند
                    </Typography>
                    <Typography component={"p"}
                      sx={{
                        color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", marginBottom: { xs: "10px", md: "15px" }
                      }}>
                      کار هوشمندانه ای که خیدوفان در ادامه انجام داد، سینتکس زبان برنامه نویسی  ABC و کمی از ویژگی های مفیدش را برداشته بود . هر چند این مسئله با شکایات زیادی همراه بود ولی خیدو تمام مشکلات زبان ABC رو رفع کرده بود و یک زبان اسکریپتی جدیدی ساخت که تمامی معایب را از سر راه برداشته بود.
                    </Typography>
                    <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    علت نام‌گذاری زبان برنامه نویسی پایتون
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                        اکثریت تصور می کنند که نام این زبان از مار پیتون گرفته شده است در حالیکه وقتی که خیدوفان در حال ساخت پایتون بود، فیلمنامه‌های سیرک پرنده مونتی پایتون <Typography component={"a"} target="_blank" href="https://www.bbc.com/persian" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >بی بی سی </Typography>
                        را مطالعه می کرد. او با این فکر که نام پایتون کوتاه و کمی مرموز است، این نام را برگزید.  پایتون در حال حاضر سومین نسخه اصلی خود را دارد و به طور منظم آپدیت  می‌شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    کاربرد پایتون
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                        محبوبیت زبان برنامه‌نویسی پایتون در میان توسعه‌دهندگان نرم‌افزار، شرکت‌ها، پژوهشگران آکادمیک و افراد تازه‌واردی که در صدد یادگیری برنامه‌نویسی هستند، دلایل گوناگونی دارد. همراهی پایتون با تکنولوژی باعث محبوبیت این زبان است. همچنین برای پایتون می توان کاربردهای بسیاری را برشمرد که در زیر به آن ها می پردازیم.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    طراحی وب سایت
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo3} alt="طراحی وب سایت با پایتون - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                       طراحی وب سایت با پایتون
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                        برنامه نویسی <NavLink to="/Academy/WebDesign" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >وبسایت </NavLink>
                        ، یکی از داغ ترین حوزه های برنامه نویسی می باشد. طی تحقیقات به عمل امده، میزان استخدام طراحان وب در سال های ۲۰۱۲ تا۲۰۲۲ ، ۲۰ درصد رشد داشته است. در حالی که متوسط رشد استخدام بقیه مشاغل۱۱ درصد است.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                        زبان پایتون اغلب برای توسعه بک‌اند وب سایت استفاده می شود. نقش پایتون در توسعه وب می‌تواند شامل ارسال داده‌ها به سرور و بالعکس، پردازش داده‌ها و برقراری ارتباط با پایگاه‌های داده، مسیریابی URL و تضمین امنیت باشد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تجزیه و تحلیل داده ها و یادگیری ماشین
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    شغل تحلیل گر داده برای سومین سال متوالی به عنوان درآمدزاترین شغل رتبه بندی شد. تحلیل داده ها که از مهم ترین بخش های یک شرکت می باشد با کتابخانه های پایتون به راحتی انجام پذیر است.
                    </Typography>
                    <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    پایتون سالهاست که به یک عنصر اصلی در علم داده تبدیل شده است و به تحلیلگران داده و دیگر متخصصان این امکان را می دهد که از این زبان برای انجام محاسبات آماری پیچیده، ایجاد تجسم داده ها، ساخت الگوریتم های یادگیری ماشین، دستکاری و تجزیه و تحلیل داده ها و تکمیل سایر وظایف مرتبط با داده استفاده کنند.
                    </Typography>
                    <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                   زبان پایتون این توانایی را دارد که طیف وسیعی از تجسم‌های داده‌های مختلف، مانند نمودارهای خطی و میله‌ای، نمودارهای دایره‌ای، هیستوگرام‌ها و نمودارهای سه‌بعدی را ایجاد کند. پایتون همچنین دارای تعداد انبوهی کتابخانه می باشد که به کدنویس ها این امکان رو می دهد که برنامه هایی برای تجزیه و تحلیل داده ها و یادگیری ماشینی سریع تر و کارآمدتر مانند TensorFlow و Keras بنویسند.
                    </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    ساخت بازی های کامپیوتری
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo4} alt="ساخت بازی با پایتون - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        ساخت بازی کامپیوتری با پایتون
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                   ایجاد بازی های کامپیوتری یکی از کاربردهای قابل توجه پایتون می باشد. برای ساخت یک بازی، باید از بسیاری از مهارت های اصلی برنامه نویسی استفاده کرد.
                    </Typography>
                    <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در توسعه بازی، متغیرها، حلقه ها، دستورات شرطی، توابع، برنامه نویسی شی گرا و مجموعه کاملی از تکنیک ها و الگوریتم های برنامه نویسی کارایی دارد. به عنوان مثالی از بازی هایی که با پایتون ساخته شده است می توان به بازی محبوب بتلفیلد2 اشاره کرد.در توسعه بازی، متغیرها، حلقه ها، دستورات شرطی، توابع، برنامه نویسی شی گرا و مجموعه کاملی از تکنیک ها و الگوریتم های برنامه نویسی کارایی دارد. به عنوان مثالی از بازی هایی که با پایتون ساخته شده است می توان به بازی محبوب بتلفیلد2 اشاره کرد.
                    </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    هک و امنیت
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    هرجا سخن از هک می شود، نام پایتون نیز با آن همراه است. پایتون دارای کتابخانه های داخلی بسیاری است که با آن می‌توان  به سیستم بقیه نفوذ کرد. بنابراین پایتون متقاضیان بسیاری برای هک دارد.    
                    </Typography>
                    <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    از کتابخانه های پایتون برای که برای هک می توان استفاده کرد می توان به کتابخونه های os, pyngrok و pynput اشاره داشت.
                    </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    پردازش تصویر
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo5} alt="پردزش تصویر با پایتون - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        پردازش تصویر
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                     دنیای امروز پر از داده است و تصاویر، بخش مهمی از این داده ها هستند. ولی این تصاویر دیجیتال قبل از استفاده باید پردازش و تجزیه و تحلیل شوند. وظیفه پردازش تصویر مانند برش، چرخش، چرخش و غیره؛ تقسیم بندی تصویر، طبقه بندی، و استخراج  بر عهده نمایشگرها می باشد.
                     </Typography>
                    <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    پایتون به دلیل در دسترس بودن رایگان بسیاری از ابزارهای پیشرفته پردازش تصویر در اکوسیستم خود محبوبیت زیادی برای پردازش تصاویر دارد.
                    </Typography>
                    <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    هوش مصنوعی یا AI
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                     پایتون را می توان قوی ترین زبان برای هوش مصنوعی دانست. هوش مصنوعی این امکان را فراهم می کند که کامپیوترها مانند انسان ها فکر می کنند. اگر قرار باشد که برنامه ای نوشته شود که از هوش مصنوعی برای حل سودوکو استفاده شود این برنامه با زبان پایتون به راحتی قابل نوشتن است. بنابراین پایتون یک برنامه کاربردی از هوش مصنوعی است زیرا کامپیوتر برای حل یک مسئله برنامه ریزی می کند.   
                    </Typography>
                    <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    اینترنت اشیا
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    پایتون برای توسعه <Typography component={"a"} target="_blank" href="https://homeinja.com/IOT" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >اینترنت اشیا </Typography>
                    یک انتخاب عالی است. جدا از این پایتون برای اجرا روی سیستم عامل لینوکس در دسترس می باشد و می‌توانید از MicroPython برای میکروکنترلرها استفاده کنید.
                    </Typography>
                    <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    از مزایای کار با پایتون برای دستگاه‌های اینترنت اشیا، سرعت توسعه کد و تعداد زیادی کتابخانه برای انواع <Typography component={"a"} target="_blank" href="https://mycryptocommerce.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >پلتفرم </Typography>
                    را می توان نام برد.    
                    </Typography>
                    <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    شرکت هایی که در طراحی وبسایت از پایتون استفاده می کنند
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo6} alt="شرکت آمازون - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                       شرکت آمازون
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                        شرکت های معروفی در دنیا هستند که از زبان پایتون برای طراحی و پیاده سازی سایتشان استفاده می کنند که در ادامه به معرفی برخی از این شرکت ها می پردازیم.
                    </Typography>
                    <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    آمازون
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    پایتون به تجزیه و تحلیل الگوی خرید مشتریان سایت آمازون کمک کرده است تا به بزرگترین فروشگاه اینترنتی کل جهان تبدیل شود. بدین ترتیب تیم <Typography component={"a"} target="_blank" href="https://www.amazon.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >آمازون </Typography>
                    قادر خواهد بود که داده های عظیم را به راحتی تحلیل کند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    پینترست
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    پینترست یکی از ابزارهایی می باشد که شما به هرعکسی با هر تمی در کوتاهترین زمان می توانید دسترسی داشته باشید. این شرکت هم برای وبسایت و هم برای <NavLink to="/Academy/Application" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >اپلیکیشن </NavLink>
                    خود از پایتون استفاده می کند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    Quora
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    Quora یک شرکت پرسش و پاسخ می باشد، کافی است که شما یک سوال از شخصی ترین تا علمی ترین موضوع داشته باشید. در این شرکت هزاران نفر قرار دارند تا به شما پاسخ دهند. این شرکت نیز از  <Typography component={"a"} target="_blank" href="https://www.google.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >گوگل </Typography>
                    تقلید کرد و از پایتون استفاده کرد تا بقیه زبان های برنامه نویسی باعث کند پیش رفتنشان نشود.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                   Mozilla
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    موزیلا که بیشتر برای فایرفاکس شناخته می‌شود، بیش از 230 هزار خط کد دارد که با زبان پایتون نوشته شده است.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    Uber
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    اوبر برای اشتراک گذاری داده ها از IPython استفاده می‌کند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                   ویژگی های پایتون
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo7} alt="ویژگی های پایتون - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        ویژگی های پایتون
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بسیاری از اساتید برای شروع برنامه نویسی، پایتون را پیشنهاد می دهند ب این دلیل که این زبان دارای قابلیت های بسیاری است. در ادامه به برخی از این ویژگی ها می پردازیم.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                   سینتکس ساده پایتون
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زبان پایتون زبان ساده ای است و سینتکس قابل فهم و راحتی هم دارد. نحو یا syntax در واقع نحوه‌ی نگارش و قرارگیری کلمات و عبارات به صورت درست، در یک زبان برنامه نویسی می باشد. در علم کامپیوتر، سینتکس یک زبان، مجموعه‌ای از قوانین می باشد که نحوه قرارگیری و ترکیب کلمات، نشانه ها و علائم یک زبان را به صورتی که معنای درستی بدهند را مشخص می کند. بنابراین یکی از راحت ترین زبان های برنامه نویسی پایتون می باشد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    همه منظوره
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    پایتون در اکثر زمینه های برنامه نویسی کارایی دارد. از برنامه نویسی وب اپلیکیشن تا هوش مصنوعی و هک و امنیت پایتون نقش پر رنگی را ایفا می کند. بنابراین زبان پایتون برای همه مخصوصا کسانی که اول راه هستند بدلیل ساده بودن و کارایی همه منظوره، انتخاب مناسبی می باشد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تعداد بالای کتابخانه 
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    وجود کتابخانه ها در یک زبان برنامه نویسی از اهمیت بالایی برخوردار است. با وجود کتابخانه ها دیگر نیازی به نوشتن کد زیاد نیست و میتوانید کدهایی را که از قبل در کتابخانه ها هستند، وارد نمایید. بنابراین پایتون با وجود تعداد بالای کتابخانه ها الویت بالایی برای برنامه نویسی دارد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    سازگاری با انواع سیستم عامل ها
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در بیشتر معیارهایی که برای سنجش میزان محبوبیت نظیر Tiobe Index مورد بررسی قرار می گیرد  پایتون به عنوان یکی از محبوب‌ترین زبان‌های برنامه‌نویسی در جهان معرفی می شود. همچنین، تعداد بسیار زیاد پروژ‌ه‌های برنامه‌نویسی موجود در سایت Github، دلیل محکمی بر این ادعا می باشد که پایتون در دسته ی محبوب‌ترین و پرکاربردترین زبان‌های برنامه‌نویسی قرار دارد. پایتون در بسیاری از پلتفرم‌ها و سیستم‌های عامل اصلی (و حتی پلتفرم‌ها و سیستم‌های عامل جزئی) قابلیت اجرا دارد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    معایب زبان پایتون
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo8} alt="معایب پایتون - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        معایب پایتون
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                   با اینکه زبان برنامه‌نویسی پایتون زبانی «همه منظوره» (General Purpose) می باشد، اما برخی از ویژگی‌های منحصر به فرد آن باعث می‌شود تا برای کاربردهای خاصی از زبان‌های برنامه‌نویسی مناسب نباشد. 
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    پایتون یک زبان برنامه‌نویسی سطح بالا است و برای برنامه‌نویسی در سطح سیستمی گزینه خوبی نمی باشد. در نتیجه، زبان پایتون را نمی‌توان برای کد نویسی «برنامه راه‌اندازی دستگاه‌ها» (Device Drivers) و یا «هسته سیستم‌های عامل» (OS Kernels) بکار برد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    همچنین در شرایطی که نیاز توسعه «باینری‌های مستقل چندسکویی» (Cross-Platform Standalone Binaries) از برنامه‌های کاربردی پیاده‌سازی شود، پایتون گزینه مناسبی نیست. البته، توسعه یک نرم‌افزار، برای سیستم‌های عامل ویندوز، مک و لینوکس، با زبان برنامه‌نویسی پایتون امکان‌پذیر هست ولی کار راحتی نمی باشد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                     ولی چنانچه اولویت و نیاز اول و آخر سازمانی در هنگام توسعه برنامه کاربردی، سرعت بهینه اجرا در تمامی جنبه‌های برنامه مورد نظر باشد، بهتر است بجای پایتون از زبان‌های دیگر نظیر C یا C++‎ یا جاوا استفاده کرد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    بازار کار پایتون
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    یکی از انگیزه‌های افراد برای یادگیری زبان برنامه نویسی، پیدا کردن شغل مناسب با درآمد خوب می باشد. برنامه‌نویسی به عنوان یکی از مشاغل حوزه فناوری اطلاعات، طی سال‌های اخیر در فهرست ده حوزه مهارتی پردرآمد بازار کاری قرار گرفت. در میان زبان‌های برنامه‌نویسی، پایتون در فهرست ۱۰ زبان برنامه‌نویسی دارای فرصت‌های شغلی متعدد با درآمد مناسب قرار دارد. بنابراین، یکی از دلایل یادگیری پایتون وجود فرصت‌های شغلی متعدد و متنوع برای این زبان برنامه نویسی است.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                     همچنین در حوزه های دیگر فناوری اطلاعات مانند هوش مصنوعی نیز فرصت‌های شغلی خوبی فراهم است. به طور کلی می توان گفت که یادگیری پایتون، مسیری به سوی بازار کار و درآمد خوب ایجاد می کند.
                     </Typography>   
                     <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    کلام آخر
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در این مقاله سعی بر این داشتیم که شما را تا حدودی با زبان پر کاربرد پایتون آشنا کنیم اما آموزش پایتون بسیار فراتر از مطالب این مقاله می باشد. ما در مقالات بعدی سورین نیز آموزش جامع تری از پایتون قرار می دهیم. امید است که مطالعه این مطالب برای شما مفید واقع شود.
                    </Typography>
                    <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                   آموزش پایتون با جادی میرمیرانی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در زیر لینک تعدادی از آموزش های زبان برنامه نویسی پایتون توسط مهندس جادی میرمیرانی را مشاهده میفرمایید.
                    </Typography>
                    <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                     1 . آموزش مقدماتی پایتون با جادی <Typography component={"a"} target="_blank" href="https://www.youtube.com/watch?v=XTsQxezu-KU" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >مشاهده </Typography>
                     </Typography> 
                    <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                     2. کار با توابع پایتون با جادی <Typography component={"a"} target="_blank" href="https://www.youtube.com/watch?v=rdQCYJdXU8E" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >تماشا </Typography>
                    </Typography>
                   {/* do not change */}

                   {author(articleData.author, t)}
                  {tags(articleData.tags, t)}
                  {linkArticle(t, url)}
                  {sourceLink(articleData.source, t)}
                  {view(dataview, t)}
                </Grid>
              </Grid>
              :
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "80%", sm: "60%", md: "70%" } }}>
                <Loader />
              </Grid>
          }
        </Grid>
      </Grid>
      <PortfolioButton />
      <Footer />
    </>
  );
}
export default Article12;




