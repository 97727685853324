import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Modal, Button, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import { baseUrl } from "./modules/general";
import AlertMessage from "./modules/AlertMessage";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { setCanonicalTag } from "./modules/general";
import Loader from "./Loader";

const Condition = () => {

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [btnAdd, setBtnAdd] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [condition, setCondition] = useState("");
  const [description, setDescription] = useState("");
  const [alert, setAlert] = useState({ alertCheck: false, message: "", severity: "" });
  const [dataTypeGraphic, setDataTypeGraphic] = useState(null);
  const [textFieldSize, setTextFiledSize] = useState(window.innerWidth <= 900 ? "small" : "medium");
  const header = ["number", "condition", "titleDescription"];

  useEffect(() => {
    document.title = "وضعیت پروژه طراحی سایت | وضعیت سفارش برنامه نویسی پیشرفته | بهترین سایت طراحی سایت";
    document.getElementById("main-heading").innerHTML ="وضعیت پروژه طراحی سایت | وضعیت سفارش برنامه نویسی پیشرفته | بهترین سایت طراحی سایت";
    document.getElementsByTagName('meta')["description"].content = "صفحه مربوط به نمایش وضعیت پروژه های نرم افزاری شرکت وب گستران سورین بهترین شرکت طراحی نرم افزارهای اختصاصی و پیچیده در ایران. در این صفجه وضعیت سفارشاتی نظیر طراحی سایت، طراحی وب سایت، طراحی رابط کاربری UI، طراحی تجربه کاربری UX، سفارش برنامه نویسی دانشگاهی، سئو سایت، انواع طراحی گرافیکی را بررسی می کنید.";
    document.getElementsByTagName('meta')["keywords"].content = "وضعیت سفارشات نرم افزاری کاربران | وضعیت سفارش طراحی وب سایت | مراحل پروژه برنامه نویسی دانشگاهی | وضعیت طراحی رابط کاربری UI | وضعیت سفارش طراحی تجربه کاربری UX | وضعیت سفارش سئو سایت | وضعیت سفارش طراحی اپلیکیشن موبایل | وضعیت سفارش طراحی گرافیکی";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => resizeTextFiled());
    return (() => window.removeEventListener('resize', () => resizeTextFiled()));
  }, [window.innerWidth]);

  useEffect(() => {
    getTypeCondition();
  }, [])

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const modalHandeler = () => {
    handleOpenModal();
  }

  const clickOtherLocation = (e) => {
    if (e.target.id === 'modal-status') {
      handleCloseModal();
    }
  }
  const resizeTextFiled = () => {
    if (window.innerWidth < 900 && textFieldSize === 'medium') {
      setTextFiledSize('small')
    }
    else if (window.innerWidth > 900 && textFieldSize === 'small') {
      setTextFiledSize('medium')
    }
  }

  const addHandeler = () => {
    if ((condition === "") && (description === "")) {
      setAlert({ alertCheck: true, message: t("AM_notField"), severity: "error" })
    }
    else if (condition === "") {
      setAlert({ alertCheck: true, message: t("AM_notCondition"), severity: "error" })
    }
    else if (description === "") {
      setAlert({ alertCheck: true, message: t("AM_notDescriptionCondition"), severity: "error" })
    }
    else {
      submitData();
    }
  }

  const submitData = () => {
    let data = {
      des: description,
      con: condition
    }
    setLoading(true)
    setBtnAdd(true)
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/condition/create',
      method: "POST",
      data: data,
      headers: { 'Authorization': AuthStr },
    }))
      .then(() => {
        setAlert({ alertCheck: true, message: t("alert_dataSuccess"), severity: "success" })
        setBtnAdd(false)
        setLoading(false)
        getTypeCondition();
        handleCloseModal();
      })
      .catch(
        (error) => {
          const { status } = error.response;
          status === 500 ?
            setAlert({ alertCheck: true, message: t("AM_intervalServer"), severity: "error" }) :
            setAlert({ alertCheck: true, message: t("alert_dataFaild"), severity: "error" })
          setBtnAdd(false)
          setLoading(false)
        }
      )
  }

  const getTypeCondition = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/condition/get`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataTypeGraphic(res.data.data)
      })
  }

  const head = () => {
    let list = [];
    header.forEach((item, index) => {
      list.push(
        <Grid key={index} sx={{ display: { xs: item === "number" ? "none" : "flex", sm: "flex" }, height: { xs: "60px", sm: "15%" }, alignItems: "center", justifyContent: "center", width: { xs: "50%", sm: "20%" } }}>
          <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px" }, color: "#070707", fontWeight: "700", textAlign: 'center', }}>{t(item)}</Typography>
        </Grid>
      )
    })
    return list;
  }

  const dataGrid = () => {
    let list = [];
    if (dataTypeGraphic === null) {
      list.push(
        <Loader key={"data1"} />
      )
    }
    else if (dataTypeGraphic.length === 0) {
      list.push(
        <Typography key={"data2"} sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707" }}>{t("notData")}</Typography>
      )
    }
    else if (dataTypeGraphic !== null && dataTypeGraphic.length > 0) {
      dataTypeGraphic.forEach((item, index) => {
        list.push(
          <Grid key={item.id} sx={{
            display: 'flex', alignItems: "center", justifyContent: 'center', width: "100%", backgroundColor: parseInt(index + 1) % 2 === 0 ? "#FFFFFF" : "#EDEDED",
            border: "1px solid #757575", borderRadius: "8px", margin: { xs: "15px 5px 0 0", sm: "15px 0 0 0" }
          }}>
            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-evenly", width: "100%", height: "40px", flexDirection: "row", }}>
              {header.map((value, index) => (
                <Grid key={index} sx={{ display: { xs: value === "number" ? "none" : "flex", sm: "flex" }, alignItems: "center", justifyContent: "center", width: { xs: "50%", sm: "20%" }, height: { xs: "60px", sm: "unset" } }}>
                  <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>
                    {value === "number" ? index + 1 : value === "titleDescription" ? item.description : item[value] !== null ? item[value] : "NotCompleted"}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )
      })
    }
    list.length === 0 && list.push(<Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707" }}>{t("notData")}</Typography>)
    return list;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  }

  return (
    <>
      <Grid sx={{
        display: 'flex', width: "100%", height: "100%", flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
        direction: i18n.language === "fa" ? "rtl" : 'ltr'
      }}>
        <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between", flexDirection: { xs: "column", sm: "row" }, width: "94%", marginTop: { xs: "10px", sm: "20px", md: "90px" } }}>
          <Typography sx={{ fontSize: { xs: "14px", sm: "18px", md: "20px" }, fontWeight: "700", color: "#070707" }}>{t("ProjectStatus")}</Typography>
          <Button onClick={modalHandeler} sx={{
            fontSize: { xs: "12px", sm: "18px" }, color: "#FFFFFF", background: "#FF3951", "&:hover": { background: "#FF3951" }, boxShadow: "0px 0px 8px #FF3951",
            borderRadius: "8px", width: { xs: "135px", sm: "140px", md: "150px" }, height: { xs: "35px", sm: "45px" }, marginTop: { xs: "20px", sm: "unset" }
          }}>
            {t("add")}</Button>
        </Grid>
        <Grid sx={{
          display: 'flex', alignItems: "center", justifyContent: 'center', width: "95%", height: { xs: "43px", sm: "69px" }, backgroundColor: "#FFFFFF",
          border: "1px solid #CBCBCB", borderRadius: "8px", marginTop: "20px"
        }}>
          <Grid sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: "space-evenly", width: "100%", flexDirection: "row", overflow: { xs: "scroll", sm: "unset" } }}>
            {head()}
          </Grid>
        </Grid>
        <Grid sx={{
          display: 'flex', alignItems: "center", justifyContent: 'flex-start', width: "95%", height: "100%", margin: { xs: "5px 0 0 5px", sm: "15px 0 0 0" }
          , flexDirection: "column"
        }}>
          {dataGrid()}
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid id="modal-status" onClick={(e) => clickOtherLocation(e)} sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh", backgroundColor: 'rgba(105,99,99,0.6)', direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
          <Grid sx={{
            display: "flex", alignItems: "center", flexDirection: 'column', background: "#FFFFFF", width: { xs: "95%", sm: "674px" },
            borderRadius: "12px", padding: "10px 0 20px 0"
          }}>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "93%", marginBottom: "20px" }}>
              <CloseRoundedIcon onClick={handleCloseModal} sx={{ fontSize: "30px", cursor: "pointer" }} />
            </Grid>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", backgroundColor: "#EDEDED", width: { xs: "90%", sm: "600px" }, borderRadius: "16px", flexDirection: "column", minHeight: { xs: "200px", md: "250px" } }}>
              <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", flexDirection: "column", width: "100%" }}>
                <Grid sx={{ display: "flex", alignItems: 'center', flexDirection: "row", width: "95%", justifyContent: "space-between" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", width: "10%" }}>{t("condition")}</Typography>
                  <TextField placeholder={t("condition")}
                    className="textField"
                    variant='outlined'
                    fullWidth
                    value={condition}
                    onChange={(e) => setCondition(e.target.value)}
                    size={textFieldSize}
                    sx={{
                      width: "90%", background: "#FFFFFF",
                      boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                    }}
                  />
                </Grid>
                <Grid sx={{ display: "flex", alignItems: 'center', flexDirection: "row", width: "95%", justifyContent: "space-between", marginTop: { xs: "30px", md: "40px" } }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", width: "10%" }}>{t("titleDescription")}</Typography>
                  <TextField placeholder={t("titleDescription")}
                    className="textField"
                    variant='outlined'
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    size={textFieldSize}
                    sx={{
                      width: "90%", background: "#FFFFFF",
                      boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: 'row', width: "100%", marginTop: "20px" }}>
              <Button onClick={addHandeler} disabled={btnAdd ? true : false} sx={{
                width: { xs: "135px", sm: "176px" }, height: { xs: "33px", sm: "50px" }, boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", fontSize: { xs: "14px", sm: "16px" },
                background: "#FF3951", color: "#FFFFFF", "&:hover": { background: "#FF3951" }, margin: "0 10px"
              }}>
                {btnAdd === true && loading === true ? <CircularProgress size={20} color="inherit" sx={{ color: "#ffffff" }} /> : t("add")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal >
      <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
    </>
  )
}

export default Condition;