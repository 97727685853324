import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import { author, date, h1, headPhoto, largePhoto, linkArticle, rootArticle, tags, paragraph, timeRead, view, listItemCircle, h2, h4, smallPhoto, sourceLink } from "../articleFunction/ArticleFunction";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { Articles } from "./Articles";
import ArticleSide from "../articleFunction/ArticleSide";
import { NavLink } from "react-router-dom";
//import photo
import photo1 from '../../image/articles/10/photo1.jpg';
import photo2 from '../../image/articles/10/photo2.jpg';
import photo3 from '../../image/articles/10/photo3.jpg';
import photo4 from '../../image/articles/10/photo4.jpg';
import photo5 from '../../image/articles/10/photo5.jpg';
import photo6 from '../../image/articles/10/photo6.jpg';
import photo7 from '../../image/articles/10/photo7.jpg';

import Loader from "../Loader";
import PortfolioButton from "../PortfolioButton";


let articleData = null;
const Article10 = () => {

  //NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${articleData !== null && articleData.title}`
    document.getElementById("main-heading").innerHTML = articleData !== null && articleData.h1;
    document.getElementsByTagName('meta')["description"].content = articleData !== null && articleData.desc;
    document.getElementsByTagName('meta')["keywords"].content = articleData !== null && articleData.keywords;
    document.getElementsByTagName('meta')["author"].content = articleData !== null && articleData.author;
    setCanonicalTag()
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item
    })
  }, [])

  useEffect(() => {
    articleData !== null && addView(); getView();
  }, [])

  const addView = () => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v1/article/addView",
      method: "POST",
      data: { id: articleData.id }
    }))
      .then((res) => { })
  }
  const getView = () => {
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/article/getView`,
      method: "GET",
      params: { id: articleData.id }
    }))
      .then((res) => {
        setDataView(res.data.data)
      })
  }

  //change This part

  //read More
  const readMore = [
    {
      id: 1,
      title: "سئو و تمام نکاتی که باید در آن رعایت کرد",
      link: "/what-is-seo"
    },
    {
      id: 2,
      title: "سئو سایت چیست و چرا باید به سئو سایت اهمیت دهیم؟",
      link: "/SEO-Importance"
    },
    {
        id: 3,
        title: "نکاتی که باید برای سفارش طراحی وب سایت در نظر گرفت",
        link: "/Points_to_consider_when_ordering_a_website_design"
      },
      {
        id: 4,
        title: "اصطلاحات پرکاربرد طراحی سایت",
        link: "/common-words-in-web-design"
      },

  ]
  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: "rtl", alignItems: "center" }}>
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid container sx={{ display: "flex", alignItems: { xs: "unset", sm: 'flex-start' }, justifyContent: "space-between", flexDirection: "row", flexWrap: "wrap", width: { xs: "100%", sm: "85%" } }}>
          <ArticleSide tags={articleData !== null && articleData.tags} readMore={readMore} />
          {
            articleData !== null ?
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: 'center', sm: "flex-end" }, flexDirection: "column", width: { xs: "100%", sm: "60%", md: "70%" } }} >
                {/* do not change */}

                {headPhoto(articleData.image)}
                {h1(articleData.h1, t)}
                {timeRead(articleData.timeRead, t)}
                {date(articleData.date, i18n)}
                <Grid sx={{ display: 'flex', alignItems: { xs: 'center', sm: 'flex-start' }, justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "column" }}>
                  {/* The text of the article */}
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                   در دنیای امروزه اکثر افراد از اینترنت استفاده می کنند. تمامی شرکت ها نیز این موضوع را دریافته اند که برای موفق شدن در کسب و کار باید در اینترنت فعالیت داشته باشند و از فضای آن برای جلب مشتری جدید، معرفی کالا و خدمات خود بهره ببرند. بنابراین باید با تکنیک های <Typography component={"strong"} sx={{ fontWeight: "700" }}>دیجیتال مارکتینگ </Typography> یا <Typography component={"strong"} sx={{ fontWeight: "700" }}>بازاریابی دیجیتال </Typography> آشنا باشند و از آن استفاده کنند. ما در این مقاله از <NavLink to="/Academy" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >آکادمی </NavLink>
                   سورین شما را با این مفهوم پرکاربرد امروزی آشنا می کنیم و همه نکاتی که در مورد دیجیتال مارکتینگ یا بازرایابی اینترنتی باید بدانید را مورد بررسی قرار می دهیم. پس با ما همراه باشید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    دیجیتال مارکتینگ چیست؟
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo1} alt="دیجیتال مارکتینگ - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        دیجیتال مارکتینگ
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    <NavLink to="/Academy/What-Is-Digital-Marketing" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >دیجیتال مارکتینگ </NavLink>
                    به نوعی بازاریابی می گویند که در بستر اینترنت و دستگاه‌های الکترونیکی به صورت آنلاین برای تبلیغات، معرفی و فروش محصولات و خدمات پیاده سازی می شود. کسب‌وکارها در روش‌های بازاریابی دیجیتال 
                   با بهره گیری از ابزارهای دیجیتال مانند موتورهای جستجو، شبکه‌های اجتماعی، ایمیل، پیامک تبلیغاتی، تبلیغات هدفمند و سایر روش‌ها با مشتریان خود ارتباط برقرار می کنند. بازرایابی اینترنتی 
                   در مقایسه با بازاریابی سنتی می‌تواند با صرف زمان و هزینه‌ی کمتر، بخش بسیار بزرگتری از جامعه‌ی هدف محصول و خدمات را مورد هدف قرار دهد. از نمونه های دیجیتال مارکتینگ می توان به بازاریابی از طریق ایمیل، تبلیغات پرداخت به ازای هر کلیک، بازاریابی در شبکه‌های اجتماعی، <NavLink to="/Academy/Seo" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >سئو </NavLink>
                  و حتی <Typography component={"a"} target="_blank" href="https://homeinja.com/HomeMag" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >وبلاگ </Typography>
                  نویسی اشاره داشت.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تاریخچه دیجیتال مارکتینگ
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo2} alt="تاریخچه دیجیتال مارکتینگ - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        تاریخچه دیجیتال مارکتینگ
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    قبل از دهه 1990، کسب و کارها برای بازاریابی روش های سنتی مانند تبلیغات چاپی، آگهی های تلویزیونی و بیلبوردها را بکار می بردند. اما با پیدایش شبکه های اجتماعی و گوشی های هوشمند بازاریابی دستخوش تغییر و تحول چشمگیری شد. در سال 1990 تعداد محدودی از افراد به اینترنت دسترسی پیدا کردند و اولین استفاده از اصطلاح بازاریابی دیجیتال در این دوره بود. به مرور این تعداد افزایش یافت. حدود سال 1993 اولین بنر قابل کلیک تبلیغاتی منتشر شد. از تعدادی که آگهی را مشاهده کردند 44 درصد آگهی را کلیک کردند. به این ترتیب کسب‌وکارها استفاده از نرم‌افزارهای ارتباط با مشتریان یا CRM را شروع کردند. 
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                   در سال 1994 سایت <Typography component={"a"} target="_blank" href="https://www.yahoo.com/?guccounter=1" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >یاهو </Typography>
                  راه اندازی شد. در همان سال اول با حدود 1 میلیون بازدید به محبوبیت زیادی دست یافت. همین موضوع سبب شد تا تغییرات گسترده ای در فضای دیجیتال مارکتینگ انجام گیرد. شرکت‌ها تمرکز و توان خود را بر روی سئو (SEO) و افزایش رتبه بندی سایت در موتورهای جستجو قرار دادند. در سال 1996 چند موتور جستجو و ابزار دیگر مانند Alexa نیز پیدایش یافت.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    اتفاقات مهم در تاریخچه دیجیتال مارکتینگ
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    سال 2001 اولین کمپین بازاریابی اینترنتی Universal Music
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    سال 2003 راه اندازی لینکدین و وردپرس
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    سال 2004 شروع کار <Typography component={"a"} target="_blank" href="https://mail.google.com/mail" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >جیمیل </Typography>
                    و فیس بوک
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    سال 2005 پیدایش <Typography component={"a"} target="_blank" href="https://www.youtube.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >یوتیوب </Typography>

                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    سال 2006 راه اندازی <Typography component={"a"} target="_blank" href="https://twitter.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >توئیتر </Typography>

                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    سال 2007 آغاز به کار <Typography component={"a"} target="_blank" href="https://www.apple.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >آیفون </Typography>

                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    سال 2009 ظهور واتس‌اپ
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تاریخچه دیجیتال مارکتینگ در ایران
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در دهه 80 ظهور دیجیتال مارکتینگ در ایران  با سایت <Typography component={"a"} target="_blank" href="https://persian-blog.ir" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >پرشین بلاگ </Typography>
                    بود. پس از مدتی بنرهای تبلیغاتی در وبلاگ‌ها قرار گرفت و افراد صفحه‌ای برای گرفتن تبلیغ ایجاد کردند. به این ترتیب بود که سایت های تخصصی تبلیغ راه اندازی شد. کار این سایت‌ها تبلیغ و اطلاع رسانی درباره شرکت و خدمات آنها بود. افزایش کاربران موبایل و سیستم‌های اندرویدی باعث شد در سال ۸۹ دانشجویان ایرانی برنامه بازار را وارد دنیای وب کنند. بعد از آن برنامه‌ عدد به بازار آمد که هدف از ایجاد آن تبلیغ در فضای مجازی بود. امروزه این برنامه ها رونق زیادی گرفتند و  به ارتباطات و تبلیغات سر و سامان بخشیدند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    دیجیتال مارکتینگ در ایران
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo3} alt="پرشین بلاگ - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        پرشین بلاگ
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                   با توجه به افزایش روزانه افراد از اینترنت تمامی شرکت‌ها و سازمان‌ها سعی بر این دارند که جهت پیشرفت و تحقق اهداف خود به این دانش مهم دست یابند و اطلاعات خود را در این زمینه کامل کنند. بر اساس تحقیقات بعمل آمده هیچ رشته دانشگاهی برای این علم در ایران وجود ندارد. اما برخی رشته ها بصورت غیر مستقیم آموزش هایی را در این راستا در نظر گرفته اند. در ادامه به معرفی این رشته ها می پردازیم.
                  </Typography>
                  <Typography component={"h4"}
                    sx={{ color: "#000000", fontSize: { xs: "12px", sm: "16px", md: "24px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    رشته مدیریت و بازاریابی: 
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    علومی که در رشته مدیریت و بازاریابی آموزش داده می شود از دروسی است که در دیجیتال مارکتینگ کارایی دارد.  بنابراین کسانی که این دروس را گذرانده اند می‌توانند در بازار کار دیجیتال مارکتینگ، تکنیک‌های بازاریابی و دانش دیجیتالی را بکار ببرند.
                  </Typography>
                  <Typography component={"h4"}
                    sx={{ color: "#000000", fontSize: { xs: "12px", sm: "16px", md: "24px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    رشته روابط عمومی: 
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای خیلی از افراد ارتباط بین رشته روابط عمومی با دیجیتال مارکتینگ قابل درک نیست. اما دانش آموختگان این رشته می‌توانند با یادگیری دانش‌های دیجیتالی بعنوان متخصص دیجیتال مارکتر در این رشته فعالیت کنند.
                  </Typography>
                  <Typography component={"h4"}
                    sx={{ color: "#000000", fontSize: { xs: "12px", sm: "16px", md: "24px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    رشته مهندسی کامپیوتر گرایش نرم افزار:
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    توسعه گران نرم افزار بخش زیادی از دیجیتال مارکتینگ را تشکیل می دهند. دروسی که در مهندسی نرم‌افزار می گذرانند یکی از راه‌های ورود افراد به رشته آنلاین مارکتینگ می باشد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    جدا از این رشته‌ها، رشته‌های کارشناسی علوم کامپیوتر، مهندسی فن آوری اطلاعات و مهندسی صنایع نیز آموزش هایی را برای کسانی که می خواهند وارد حرفه بازاریابی دیجیتال شوند در نظر گرفته است.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    اهمیت دیجیتال مارکتینگ
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo4} alt="اهمیت دیجیتال مارکتینگ - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        اهمیت دیجیتال مارکتینگ
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    بازاریابی دیجیتال این امکان را برای شما فراهم می کند که مخاطبان خود را بهتر شناسایی کنید ومشتریان بالقوه‌ی خود  را مورد هدف قرار دهید. در حقیقت برخلاف تبلیغات فیزیکی و سنتی محدودیتی در شناخت، دسته‌بندی، ارسال پیام و دریافت فیدبک از مخاطبان ندارید.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    در تبلیغات اینترنتی نسبت به روش‌های سنتی بازاریابی هزینه کمتری صرف تبلیغات می شود و  بازگشت سرمایه سریع‌تری هم بدنبال دارد. با دیجیتال مارکتینگ می‌توانید بیست‌ و چهارساعته در هفت روز هفته، کمپین‌های بازاریابی خود را تحلیل و بررسی کنید.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                   تبلیغات اینترنتی محدود به یک روش خاص نیستند بلکه روش ها و ابزارهای متنوعی برای دیجیتال مارکتینگ وجود دارند.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    از مزیت های موتورهای جستجوگر این است که کاری به بزرگی برند ندارند. بنابراین در این نوع بازاریابی شما می توانید با تمام رقبا حتی شرکت های بزرگ و مطرح در حوزه کاری خود به رقابت بپردازید. با تولید محتوای صحیح و تکنیک‌های سئو، امکان دارد موتورهای جستجوگر اولویت بیشتری به وب‌سایت کسب‌وکار شما برای نمایش بدهند.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                   بازاریابی دیجیتال قابل اندازه‌گیری می باشد. این بدان معناست که با بازاریابی دیجیتال می‌توانید به تمامی اطلاعات ازجمله آمار تعداد بازدید، اشتراک، کلیک‌ها و زمان‌های صرف شده در صفحات دسترسی پیدا کنید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                   ابزارهای دیجیتال مارکتینگ
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    یک دیجیتال مارکتر برای بازاریابی آنلاین نیاز دارد که به ابزارهای تبلیغات اینترنتی مسلط باشد. در زیر به تفصیل  ابزارهای بازاریابی دیجیتال را نام می بریم.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                     بازاریابی توسط موتورهای جستجو (SEM)
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                     بهینه سازی برای موتورهای جستجو (سئو)
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    بازاریابی محتوا (Content Marketing)
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    بازاریابی درونگرا (Inbound Marketing)
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    بازاریابی شبکه های اجتماعی (Social Media Marketing)
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                      استراتژی محتوا (Content Strategy)
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    تبلیغات بر اساس کلیک (PPC)
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                     اینفلوئنسر مارکتینگ (Influencer Marketing)
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                      تبلیغات بنری (Banner Advertising) 
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    تبلیغات همسان (Native Advertising)
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                     ایمیل مارکتینگ
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                     بازاریابی موبایلی
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                      بازاریابی از طریق همکاری در فروش (Affiliate Marketing)
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    تولید محتوا
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    بازاریابی ویروسی دیجیتال (Digital Viral Marketing)
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    استراتژی‌های دیجیتال مارکتینگ
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo5} alt="استراژدی دیجیتال مارکتینگ - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        استراژدی دیجیتال مارکتینگ
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    استراتژی مارکتینگ،  برنامه‌ی مشخصی است که برای رسیدن به اهداف بازاریابی باید در نظر بگیرید تا بتوانید موفقیت کسب‌وکار خود را تضمین کنید. استراتژی شامل سه بخش اصلی می شود که در زیر به توضیح آن می پردازیم.
                  </Typography>
                  <Typography component={"ol"}>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      شناسایی چالش‌ها
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      تعیین خط مشی‌ها برای روبرو شدن با چالش‌ها
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      مجموعه‌ اقدامات مشخص برای اجرای صحیح خط مشی‌ها 
                    </Typography>
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    همچنین برای تحقق این استراتژی نیاز است که چند گام اصلی را سر لوحه کارتان قرار دهید.
                  </Typography>
                  <Typography component={"ol"}>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      شناسایی دقیق اهداف و خواسته هایتان
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      شناخت کامل مشتریان و پیدا کردن راهکارهای عملی برای مشکلات آنان
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      شناسایی کامل رقبا
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      تقسیم وظایف بین افراد تیم
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      ارزیابی دارایی‌ها و بسترهای دیجیتالی در دسترس
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      بررسی کمپین‌های رسانه‌ای موجود
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                      راه اندازی کمپین بازاریابی دیجیتال
                    </Typography>
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                   کانال های اصلی دیجیتال مارکتینگ
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo6} alt="کانال دیجیتال مارکتینگ - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        کانال های دیجیتال مارکتینگ
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                   برای دیجیتال مارکتینگ می توان سه دسته اصلی قرار داد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    1. وب سایت فروشگاهی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    مانند بازریابی سنتی نمی توانید با استخدام یک فروشنده حرفه ای مشتری را جذب کنید. بنابراین باید روشی را پیاده کنید که مشتری وارد سایت شما شود. استراتژی دیجیتال مارکتینگ درست، راه اندازی یک وبسایت جذاب و کاربردی را به شما پیشنهاد می دهد. اما توجه به سه نکته در روند بازاریابی دیجیتال سایت شما حائز اهمیت است. رابط کاربری، تجربه کاربری و ریسپانسیو بودن سه موضوع اساسی است که در طراحی سایت <NavLink to="/Portfolio/ECommerce" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >فروشگاهی </NavLink>
                    باید رعایت شود.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    2. شبکه‌های اجتماعی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                   شبکه های اجتماعی برای توسعه کسب و کارتان بسیار کارامد می باشند و به بهتر دیده شدن شما کمک می کنند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    3. سایر پلتفرم ها
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    پلتفرم‌های دیگری مانند یوتیوب، <Typography component={"a"} target="_blank" href="https://www.aparat.com/MyCryptoCommerce/MyCryptoCommerce" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >آپارات </Typography>
                    و ایمیل هستند که در دیجیتال مارکتینگ به شما کمک می کنند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                   تعریف بازاریابی دیجیتال در سایت های معتبر
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo7} alt="هاب اسپات - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        هاب اسپات
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در این قسمت به معرفی تعدادی ا زسایت هایی که در زمینه دیجیتال مارکتینگ فعالیت دارند می پردازیم.
                  </Typography>
                  <Typography component={"h4"}
                    sx={{ color: "#000000", fontSize: { xs: "12px", sm: "16px", md: "24px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تعریف هاب اسپات(<Typography component={"a"} target="_blank" href="https://www.hubspot.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >HUBSPOT </Typography>
                    ) از دیجیتال مارکتینگ :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                     دیجیتال مارکتینگ تمام تلاش‌های مربوط به بازاریابی را شامل می شود که با استفاده از دستگاه‌های الکترونیکی و اینترنت انجام پذیر است. کسب و کارها از کانال‌های دیجیتال مانند موتور‌های جستجو، شبکه‌های اجتماعی، ایمیل و وب‌ <Typography component={"a"} target="_blank" href="https://mycryptocommerce.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >سایت </Typography>
                     های دیگر برای برقراری ارتباط با مشتریان بالقوه و بالفعل استفاده می‌کنند.
                  </Typography>
                  <Typography component={"h4"}
                    sx={{ color: "#000000", fontSize: { xs: "12px", sm: "16px", md: "24px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تعریف سایت مارتک ادوایزر از بازاریابی دیجیتال : 
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    آنلاین مارکتینگ به تمام کارهای بازاریابی که شامل کانال‌های دیجیتال مانند بازاریابی محتوایی، سئو، بازاریابی ایمیلی، بازاریابی شبکه‌های اجتماعی، بازاریابی موبایلی می‌شود تا به کمک آنها بتوانیم استراتژی‌های مختلفی را برای رسیدن به مشتریان بالقوه و بالفعل و برقراری ارتباط موثر با آنها تدوین کنیم.
                  </Typography>
                  <Typography component={"h4"}
                    sx={{ color: "#000000", fontSize: { xs: "12px", sm: "16px", md: "24px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                   تعریف نیل پتل  از بازاریابی اینترنتی : 
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    آنلاین مارکتینگ به هر نوعی از بازاریابی محصولات و خدمات می گویند که روی دستگاه‌های الکترونیکی پیاده‌سازی می‌شود. براین اساس بازاریابی دیجیتال هم به صورت آنلاین و هم به شکل آفلاین قابل اجراست.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    معرفی کتاب آموزشی دیجیتال مارکتینگ
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    مبحث دیجیتال مارکتینگ بسیار گسترده می باشد. هر چقدر که شما در این زمینه مطالعه داشته باشید باز هم به تمامی مفاهیم بازاریابی دیجیتال بطور کامل مسلط نخواهید شد و جا برای آموزش بیشتر خواهید داشت. ما در این قسمت به معرفی چند کتاب در زمینه آنلاین مارکتینگ می پردازیم.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                   کتاب <Typography component={"a"} target="_blank" href="https://www.amazon.com/New-Rules-Marketing-Applications-Newsjacking/dp/1119362415" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >The New Rules of Marketing and PR </Typography>
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    کتاب دیوید اسکات با عنوان قوانین جدید بازاریابی و روابط عمومی را می‌توان به عنوان یک کتاب مرجع کلاسیک در زمینه‌ی بازاریابی دیجیتال معرفی کرد. این کتاب بارها چاپ شده است. در هر سری انتشار جدید نویسنده با توجه به نیازهای هر مقطع زمانی مطالب کتاب را بروز کرده است. این کتاب بیشتر به ابعاد مدیریتی دیجیتال مارکتینگ توجه دارد و می تواند برای تمام افرادی که بدنبال بازاریابی اینترنتی هستند مفید باشد.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    کتاب <Typography component={"a"} target="_blank" href="https://www.amazon.com/Ctrl-Alt-Delete-Business-Depends/dp/1455523321" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >Ctrl Alt Delete </Typography>
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    نویسنده این کتاب میچ جول (Mitch Joel) که سابقه ای درخشان در بازاریابی اینترنتی دارد، این کتاب را بر اساس بررسی نقش ابزارهای دیجیتال در تحول کسب و کارها نوشته است.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    کتاب <Typography component={"a"} target="_blank" href="https://www.amazon.com/Epic-Content-Marketing-Different-Customers/dp/0071819894" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >Epic Content Marketing </Typography>
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    جو پولیتزی نویسنده این کتاب، کتاب‌های بسیاری در زمینه استراتژی محتوا و بازاریابی محتوا دارد. اما کتاب Epic Content Marketing فراتر از بازاریابی محتوا می باشد. پولیتزی در این کتاب به حوزه‌های بازاریابی دیجیتال پرداخته است و نقش محتوا در هر یک از حوزه‌ها را مورد توجه قرار داده است. هدف گذاری برای بازاریابی محتوا، مدل کسب و کار بر اساس محتوا، تقویم محتوا و استفاده از پلتفرم‌ها و ابزارهای مختلف برای بازاریابی دیجیتال، از جمله مبحث های مهم در این کتاب هستند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    سوالات متداول
                  </Typography>
                  <Typography component={"h4"}
                    sx={{ color: "#000000", fontSize: { xs: "12px", sm: "16px", md: "24px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    1. تفاوت بازاریابی اینترنتی و دیجیتال مارکتینگ در چیست؟
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بازاریابی اینترنتی و دیجیتال مارکتینگ هر دو یک مفهوم را می رسانند. اصطلاح بازار یابی اینترنتی بیشتر در دهه‌ی نود میلادی بکار می رفت اما با گذر زمان و گسترش ابزارهای بازاریابی دیجیتال، دیجیتال مارکتینگ جایگزین رواج پیدا کرد.
                  </Typography>
                  <Typography component={"h4"}
                    sx={{ color: "#000000", fontSize: { xs: "12px", sm: "16px", md: "24px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    2.  آیا اصطلاح بازاریابی دیجیتال و تبلیغات دیجیتال به یک معنا هستند؟
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    این دو کلمه در معنا با هم تفاوت دارند. وقتی بازاریابی دیجیتال را به کار می بریم، منظور شناخت بازار با استفاده از ابزارهای دیجیتال برای حل مشکلات و نیازهای بازار است. در حالی که وقتی صحبت از تبلیغات دیجیتال باشد، یعنی محصول و خدمات را با استفاده از ابزارهای دیجیتالی به مخاطب معرفی کنیم.
                  </Typography>
                  <Typography component={"h4"}
                    sx={{ color: "#000000", fontSize: { xs: "12px", sm: "16px", md: "24px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                   3. آیا ضمانتی برای موفقیت در کسب و کار با دیجیتال مارکتینگ وجود دارد؟
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    تداوم و پیوستگی در اجرای دیجیتال مارکتینگ یکی از مهمترین عوامل موفقیت در کسب و کار محسوب می شود. نمایش مداوم و طولانی‌مدت آگهی‌ها نام کسب‌وکار شما را در خاطر مخاطب ماندگار می کند. همچنین عملکرد بسیاری از جنبه‌های استراتژی بازاریابی اینترنتی مانند لوگو، برند، کاربران شبکه‌های اجتماعی و بازدیدکنندگان سایت در بلندمدت نمایان می شود. در ضمن باید این نکته را در نظر داشت که با توجه به توسعه‌ی روزافزون ابزارها و امکانات جدید دیجیتال مارکتینگ، نباید یک روز از روندهای روز قبل عقب افتاد. زیرا امکان دارد شرکت‌های رقیب بخش زیادی از سهم بازار را از آن خود کنند.
                  </Typography>
                  <Typography component={"h4"}
                    sx={{ color: "#000000", fontSize: { xs: "12px", sm: "16px", md: "24px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    4. چه کسانی به دیجیتال مارکتینگ نیاز دارند؟
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    تمام کسب و کارهایی که حضور آنلاین دارند برای جذب مشتری بیشتر به بازریابی اینترنتی نیاز دارند. زیرا افراد زیادی هستند که برای یافتن محصولات و خدمات مورد نظر خود به اینترنت مراجعه می کنند.
                  </Typography>

                   {/* do not change */}

                   {author(articleData.author, t)}
                  {tags(articleData.tags, t)}
                  {linkArticle(t, url)}
                  {sourceLink(articleData.source, t)}
                  {view(dataview, t)}
                </Grid>
              </Grid>
              :
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "80%", sm: "60%", md: "70%" } }}>
                <Loader />
              </Grid>
          }
        </Grid>
      </Grid>
      <PortfolioButton />
      <Footer />
    </>
  );
}
export default Article10;
