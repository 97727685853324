import { SvgIcon } from '@mui/material';
import React from 'react';
const MagIcon = ({ color }) => {
  return (
    <SvgIcon component="object" sx={{ height: "30px" }}>
      <svg height="17" width="17" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 15H20M9 10.3333H13M9 5.66667H13M3.5 22C4.16304 22 4.79893 21.6927 5.26777 21.1457C5.73661 20.5987 6 19.8569 6 19.0833V1H23V19.0833C23 19.8569 22.7366 20.5987 22.2678 21.1457C21.7989 21.6927 21.163 22 20.5 22H3.5ZM3.5 22C2.83696 22 2.20107 21.6927 1.73223 21.1457C1.26339 20.5987 1 19.8569 1 19.0833V5.66667H6.025M16 5.66667H19V10.3333H16V5.66667Z" stroke={color} />
      </svg>
    </SvgIcon>
  );
};
export default MagIcon;