import React, { useEffect } from "react";
import '../styles/navLink.css';
import '../styles/Nav.css';
import { useState } from "react";
import DashboardMain from "./DashboardMain";
import { dataUser, dataSupporter } from "./DataPanel";
import { useNavigate, useParams } from "react-router-dom";

let info = null
const Panel = ({ auth }) => {
  const [panel, setPanel] = useState(null);
  const [dataPanel, setDataPanel] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    info = JSON.parse(localStorage.getItem('sorinInfoUser'));
    if (info) {
      if ((info.user_type_id === 1) || (info.user_type_id === 2)) {
        if ((params.value === "Dashboard") || (params.value === "UserInfo") || (params.value === "WebsiteOrder") || (params.value === "AppOrder") || (params.value === "UIUXorder") ||
          (params.value === "ProgrammingOrder") || (params.value === "SeoOrder") || (params.value === "GraphicOrder") || (params.value === "OrderStatus") || (params.value === "Tickets")) {
          setPanel("U")
          setDataPanel(dataUser)
        }
        else {
          navigate("/NotFound")
        }
      }
      else if ((info.user_type_id === 3)) {
        if ((params.value === "NewUser") || (params.value === "NewOrder") || (params.value === "OrderHistory") || (params.value === "ContactSorin") || (params.value === "AreasActivity") ||
          (params.value === "SiteType") || (params.value === "GraphicType") || (params.value === "ProjectStatus") || (params.value === "Tickets") || (params.value === "SearchUser")) {
          setPanel("S")
          setDataPanel(dataSupporter)
        }
        else {
          navigate("/NotFound")
        }
      }
    }

  }, [auth, params.value])


  return (
    <>
      <DashboardMain panel={panel} data={dataPanel} infoUser={info} />
    </>
  )
}

export default Panel;