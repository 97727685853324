import { Typography,Grid } from "@mui/material";
import React from "react";
import iconArrowTop from "../image/icon/iconArrowTop.png";

const Anchor = () =>{
  const scrollTop =() =>{
    window.scrollTo({top:0, behavior:"smooth"});
  }
  return(
    <>
      <Grid sx={{display:"flex",justifyContent:"center",alignItems:"center",cursor:"pointer"}}>
          <Typography  onClick={scrollTop} component={"img"} alt="anchor" src={iconArrowTop} sx={{filter:"drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3))"}}/>
      </Grid>
    </>
  );
}
export default Anchor;