import { SvgIcon } from '@mui/material';
import React from 'react';
const AllGraphicIcon = ({ color }) => {
  return (
    <SvgIcon component="object" sx={{ height: "30px" }}>
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.9335 10.5C12.1418 10.5 9.86682 12.3958 9.86682 14.7222C9.86682 17.0487 12.1418 18.9444 14.9335 18.9444C15.2694 18.9444 15.5916 18.8332 15.8292 18.6353C16.0667 18.4373 16.2002 18.1688 16.2002 17.8889C16.2002 17.6313 16.0836 17.3822 15.8759 17.1838C15.8353 17.1451 15.812 17.0958 15.81 17.0444C15.81 16.9262 15.9215 16.8333 16.0634 16.8333H16.9602C18.6372 16.8333 20.0002 15.6976 20.0002 14.3C20.0002 12.2058 17.7252 10.5 14.9335 10.5ZM17.7202 15.1444C17.2996 15.1444 16.9602 14.8616 16.9602 14.5111C16.9602 14.1607 17.2996 13.8778 17.7202 13.8778C18.1407 13.8778 18.4802 14.1607 18.4802 14.5111C18.4802 14.8616 18.1407 15.1444 17.7202 15.1444ZM16.2002 13.4556C15.7796 13.4556 15.4402 13.1727 15.4402 12.8222C15.4402 12.4718 15.7796 12.1889 16.2002 12.1889C16.6207 12.1889 16.9602 12.4718 16.9602 12.8222C16.9602 13.1727 16.6207 13.4556 16.2002 13.4556ZM11.3868 14.5111C11.3868 14.1607 11.7263 13.8778 12.1468 13.8778C12.5674 13.8778 12.9068 14.1607 12.9068 14.5111C12.9068 14.8616 12.5674 15.1444 12.1468 15.1444C11.7263 15.1444 11.3868 14.8616 11.3868 14.5111ZM14.4268 12.8222C14.4268 13.1727 14.0874 13.4556 13.6668 13.4556C13.2463 13.4556 12.9068 13.1727 12.9068 12.8222C12.9068 12.4718 13.2463 12.1889 13.6668 12.1889C14.0874 12.1889 14.4268 12.4718 14.4268 12.8222Z" fill={color} />
        <path d="M11.1333 3.63889C11.1333 4.33877 11.467 5.00998 12.0608 5.50487C12.6547 5.99975 13.4601 6.27778 14.3 6.27778C15.1399 6.27778 15.9453 5.99975 16.5392 5.50487C17.133 5.00998 17.4667 4.33877 17.4667 3.63889C17.4667 2.93901 17.133 2.2678 16.5392 1.77291C15.9453 1.27802 15.1399 1 14.3 1C13.4601 1 12.6547 1.27802 12.0608 1.77291C11.467 2.2678 11.1333 2.93901 11.1333 3.63889ZM1 1H7.33333V6.27778H1V1ZM7.33333 9.44444V20C5.67885 19.9982 4.09089 19.457 2.90912 18.4921C1.72734 17.5272 1.04558 16.2152 1.00967 14.8368C0.97376 13.4584 1.58655 12.123 2.71695 11.1162C3.84736 10.1095 5.40564 9.51134 7.05847 9.44972L7.33333 9.44444Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  );
};
export default AllGraphicIcon;