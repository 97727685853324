import React, { useEffect, useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import featuresXSen from '../image/features/featuresXSen.png';
import photo1 from '../image/features/1.png';
import photo2 from '../image/features/2.png';
import photo3 from '../image/features/3.png';
import photo4 from '../image/features/4.png';
import photo5 from '../image/features/5.png';
import photo6 from '../image/features/6.png';
import featuresXS from '../image/features/featuresXS.png';
import arrowRightPurple from '../image/icon/arrowRightPurple.png';
import arrowLeftPurple from '../image/icon/arrowLeftPurple.png';
import "../styles/features.css"
import { useNavigate } from "react-router-dom";
import ModalLogin from "./ModalLogin";

let info = null;
const Features = () => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const Navigate = useNavigate();

  useEffect(() => {
    info = (JSON.parse(localStorage.getItem('sorinInfoUser')));
  }, [])

  const loginHandler = () => {
    setType("L");
    setOpen(true);
  }

  const handleCloseModal = () => {
    setOpen(false);
  }
  const btnHandeler = (value) => {
    !localStorage.getItem('TACC') ? loginHandler() : info.user_type_id === 1 || info.user_type_id === 2 ? Navigate(`/SorinBoard/${value}`) : Navigate("/SorinBoard/NewOrder");
  }
  return (
    <>
      <Grid sx={{ display: "flex", width: "100%", justifyContent: "center", flexDirection: 'column', alignItems: "center", margin: { xs: "30px 0", sm: "40px 0", md: "50px 0" }, direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
        <Grid sx={{ display: "flex", width: { xs: "90%", md: "85%" }, flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative" }}>
          <Grid sx={{ display: "flex", flexDirection: i18n.language === "fa" ? "row" : "row-reverse", justifyContent: "center", alignItems: "center", marginBottom: { xs: "20px", sm: "35px", md: "50px" }, width: "100%" }}>
            <Typography component={"img"} alt="icon" src={arrowRightPurple} sx={{ width: { xs: "14px", sm: "39px", md: "40px", lg: "54px" }, height: { xs: "17px", sm: "53px", md: "55px", lg: "69px" }, margin: "0 3px" }} />
            <Typography component={"p"} sx={{ fontWeight: "700", fontSize: { xs: "12px", sm: "24px", md: "32px" }, textAlign: "center", lineHeight: { xs: "17px", md: "45px" } }}>{t("feauturesTitle")}</Typography>
            <Typography component={"img"} alt="icon" src={arrowLeftPurple} sx={{ width: { xs: "14px", sm: "39px", md: "40px", lg: "54px" }, height: { xs: "17px", sm: "53px", md: "55px", lg: "69px" }, margin: "0 3px" }} />
          </Grid>
          <Grid sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center", justifyContent: "center", flexDirection: "row", flexWrap: "wrap", width: "100%" }}>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "33%", margin: "30px 0" }}>
              <Grid title={t("features1")} sx={{
                display: "flex", alignItems: "center", justifyContent: "center", background: `url(${photo1})`, backgroundPosition: "center", backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat", width: { sm: "173px", md: "208px", lg: "293px", xl: "311px" }, height: { sm: "173px", md: "208px", lg: "293px", xl: "311px" }
              }}></Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", width: { sm: "173px", md: "208px", lg: "293px", xl: "311px" } }}>
                <Grid sx={{
                  display: "flex", alignItems: "center", justifyContent: "center", minWidth: { sm: "39px", md: "41px", lg: "43px", xl: "45px" }, minHeight: { sm: "39px", md: "41px", lg: "43px", xl: "45px" },
                  border: "2px solid #8439FF", filter: "drop-shadow(0px 0px 5px #8439FF)", borderRadius: "50%", background: "#FCFAFF"
                }}>
                  <Typography sx={{ color: "#8439FF", fontSize: { sm: "14px", md: "16px", lg: "18px", xl: "20px" } }}>1</Typography>
                </Grid>
                <Typography sx={{ fontSize: { sm: "12px", md: "16px", xl: "18px" }, color: "#062B33", margin: "0 5px" }}>{t("features1")}</Typography>
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "33%", margin: "30px 0" }}>
              <Grid title={t("features21")} sx={{
                display: "flex", alignItems: "center", justifyContent: "center", background: `url(${photo2})`, backgroundPosition: "center", backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat", width: { sm: "173px", md: "208px", lg: "293px", xl: "311px" }, height: { sm: "173px", md: "208px", lg: "293px", xl: "311px" }
              }}></Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", width: { sm: "173px", md: "208px", lg: "293px", xl: "311px" } }}>
                <Grid sx={{
                  display: "flex", alignItems: "center", justifyContent: "center", minWidth: { sm: "39px", md: "41px", lg: "43px", xl: "45px" }, minHeight: { sm: "39px", md: "41px", lg: "43px", xl: "45px" },
                  border: "2px solid #8439FF", filter: "drop-shadow(0px 0px 5px #8439FF)", borderRadius: "50%", background: "#FCFAFF"
                }}>
                  <Typography sx={{ color: "#8439FF", fontSize: { sm: "14px", md: "16px", lg: "18px", xl: "20px" } }}>2</Typography>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "column", margin: "0 5px" }}>
                  <Typography sx={{ fontSize: { sm: "12px", md: "16px", xl: "18px" }, color: "#062B33", }}>{t("features21")}</Typography>
                  <Typography sx={{ fontSize: { sm: "12px", md: "16px", xl: "18px" }, color: "#062B33", }}>{t("features22")}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "33%", margin: "30px 0" }}>
              <Grid title={t("features31")} sx={{
                display: "flex", alignItems: "center", justifyContent: "center", background: `url(${photo3})`, backgroundPosition: "center", backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat", width: { sm: "173px", md: "208px", lg: "293px", xl: "311px" }, height: { sm: "173px", md: "208px", lg: "293px", xl: "311px" }
              }}></Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", width: { sm: "173px", md: "208px", lg: "293px", xl: "311px" } }}>
                <Grid sx={{
                  display: "flex", alignItems: "center", justifyContent: "center", minWidth: { sm: "39px", md: "41px", lg: "43px", xl: "45px" }, minHeight: { sm: "39px", md: "41px", lg: "43px", xl: "45px" },
                  border: "2px solid #8439FF", filter: "drop-shadow(0px 0px 5px #8439FF)", borderRadius: "50%", background: "#FCFAFF"
                }}>
                  <Typography sx={{ color: "#8439FF", fontSize: { sm: "14px", md: "16px", lg: "18px", xl: "20px" } }}>3</Typography>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "column", margin: "0 5px" }}>
                  <Typography sx={{ fontSize: { sm: "12px", md: "16px", xl: "18px" }, color: "#062B33", }}>{t("features31")}</Typography>
                  <Typography sx={{ fontSize: { sm: "12px", md: "16px", xl: "18px" }, color: "#062B33", }}>{t("features32")}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "33%", margin: "30px 0" }}>
              <Grid title={t("features4")} sx={{
                display: "flex", alignItems: "center", justifyContent: "center", background: `url(${photo4})`, backgroundPosition: "center", backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat", width: { sm: "173px", md: "208px", lg: "293px", xl: "311px" }, height: { sm: "173px", md: "208px", lg: "293px", xl: "311px" }
              }}></Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", width: { sm: "173px", md: "208px", lg: "293px", xl: "311px" } }}>
                <Grid sx={{
                  display: "flex", alignItems: "center", justifyContent: "center", minWidth: { sm: "39px", md: "41px", lg: "43px", xl: "45px" }, minHeight: { sm: "39px", md: "41px", lg: "43px", xl: "45px" },
                  border: "2px solid #8439FF", filter: "drop-shadow(0px 0px 5px #8439FF)", borderRadius: "50%", background: "#FCFAFF"
                }}>
                  <Typography sx={{ color: "#8439FF", fontSize: { sm: "14px", md: "16px", lg: "18px", xl: "20px" } }}>4</Typography>
                </Grid>
                <Typography sx={{ fontSize: { sm: "12px", md: "16px", xl: "18px" }, color: "#062B33", margin: "0 5px" }}>{t("features4")}</Typography>
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "33%", margin: "30px 0" }}>
              <Grid title={t("features5")} sx={{
                display: "flex", alignItems: "center", justifyContent: "center", background: `url(${photo5})`, backgroundPosition: "center", backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat", width: { sm: "173px", md: "208px", lg: "293px", xl: "311px" }, height: { sm: "173px", md: "208px", lg: "293px", xl: "311px" }
              }}></Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", width: { sm: "173px", md: "208px", lg: "293px", xl: "311px" } }}>
                <Grid sx={{
                  display: "flex", alignItems: "center", justifyContent: "center", minWidth: { sm: "39px", md: "41px", lg: "43px", xl: "45px" }, minHeight: { sm: "39px", md: "41px", lg: "43px", xl: "45px" },
                  border: "2px solid #8439FF", filter: "drop-shadow(0px 0px 5px #8439FF)", borderRadius: "50%", background: "#FCFAFF"
                }}>
                  <Typography sx={{ color: "#8439FF", fontSize: { sm: "14px", md: "16px", lg: "18px", xl: "20px" } }}>5</Typography>
                </Grid>
                <Typography sx={{ fontSize: { sm: "12px", md: "16px", xl: "18px" }, color: "#062B33", margin: "0 5px" }}>{t("features5")}</Typography>
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "33%", margin: "30px 0" }}>
              <Grid title={t("features6")} sx={{
                display: "flex", alignItems: "center", justifyContent: "center", background: `url(${photo6})`, backgroundPosition: "center", backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat", width: { sm: "173px", md: "208px", lg: "293px", xl: "311px" }, height: { sm: "173px", md: "208px", lg: "293px", xl: "311px" }
              }}></Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", width: { sm: "173px", md: "208px", lg: "293px", xl: "311px" } }}>
                <Grid sx={{
                  display: "flex", alignItems: "center", justifyContent: "center", minWidth: { sm: "39px", md: "41px", lg: "43px", xl: "45px" }, minHeight: { sm: "39px", md: "41px", lg: "43px", xl: "45px" },
                  border: "2px solid #8439FF", filter: "drop-shadow(0px 0px 5px #8439FF)", borderRadius: "50%", background: "#FCFAFF"
                }}>
                  <Typography sx={{ color: "#8439FF", fontSize: { sm: "14px", md: "16px", lg: "18px", xl: "20px" } }}>6</Typography>
                </Grid>
                <Typography sx={{ fontSize: { sm: "12px", md: "16px", xl: "18px" }, color: "#062B33", margin: "0 5px" }}>{t("features6")}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ display: { xs: "flex", sm: "none" }, alignItems: "center", justifyContent: "center", width: "100%" }}>
            <Grid title="features" sx={{
              display: "flex", alignItems: "center", justifyContent: "center", background: `url(${i18n.language === "fa" ? featuresXS : featuresXSen})`, backgroundPosition: "center", backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat", width: "350px", height: "900px"
            }}></Grid>
          </Grid>
          <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", marginTop: "80px" }}>
            <Button onClick={() => btnHandeler("dashboard")} className="btnFeatures" sx={{
              bgcolor: "#FF3951", width: { xs: "150px", sm: "214px", md: "260px", lg: "290px" }, height: { xs: "40px", sm: "45px", md: "50px", lg: "65px" },
              boxShadow: " #FF3951 0px 0px 15px", "&:hover": { backgroundColor: "#FF3951" }, color: "white", fontSize: { xs: "12px", sm: "14px", md: "16px" },
              borderRadius: "8px"
            }}>
              {t("orderNow")}
            </Button>
          </Grid>
        </Grid >
      </Grid >
      <ModalLogin open={open} close={handleCloseModal} data={type} />
    </>
  );
}
export default Features;