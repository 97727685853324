import { SvgIcon } from '@mui/material';
import React from 'react';
import '../../styles/dashboard.css';
const LogoutIcon = ({ color }) => {
  return (
    <SvgIcon className='logoutHover' component="object">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="transparent" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.8999 7.55999C9.2099 3.95999 11.0599 2.48999 15.1099 2.48999H15.2399C19.7099 2.48999 21.4999 4.27999 21.4999 8.74999V15.27C21.4999 19.74 19.7099 21.53 15.2399 21.53H15.1099C11.0899 21.53 9.2399 20.08 8.9099 16.54" stroke={color} />
        <path d="M15.0001 12H3.62012" stroke={color}  />
        <path d="M5.85 8.65002L2.5 12L5.85 15.35" stroke={color}  />
      </svg>
    </SvgIcon>
  );
};
export default LogoutIcon;