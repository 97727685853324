import React, { useState } from "react";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import PlaceIcon from '@mui/icons-material/Place';
import siteDesign from '../image/footer/enemad_pic.png';
import inamad from '../image/footer/inamad.png';
import footer from '../image/footer/footer.png';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { NavLink, useLocation } from "react-router-dom";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  const clickHandler = () => {
    if (open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }
  const location = useLocation();


  return (
    <>
      <Grid sx={{ display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: "column", bgcolor: '#062B33', direction: i18n.language === "fa" ? "rtl" : "ltr", width: "100%" }}>
        <Grid sx={{ display: 'flex', flexDirection: { xs: "column", md: "row" }, width: "95%", margin: "20px 0" }}>
          <Grid sx={{ display: "flex", flexDirection: "column", width: { xs: "100%", md: "80%" }, marginLeft: { md: "0px", lg: "50px" }, justifyContent: "flex-start" }}>
            <Grid sx={{ display: "flex", flexDirection: { xs: "column", md: 'row' }, justifyContent: "space-between" }}>
              <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: { xs: "center", md: "flex-start" } }}>
                <Grid sx={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: "center" }}>
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("social")}</Typography>
                  <WhatsAppIcon sx={{ fontSize: "20px", color: "white", margin: "0 5px" }} />
                  <LinkedInIcon sx={{ fontSize: "22px", color: "white", margin: "0 5px" }} />
                  <InstagramIcon sx={{ fontSize: "20px", color: "white", margin: "0 5px" }} />
                </Grid>
                <Grid sx={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: "flex-start", marginTop: "10px" }}>
                  <PlaceIcon sx={{ fontSize: "20px", color: "white" }} />
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("addressContactUs")}</Typography>
                </Grid>
                <Grid sx={{ display: { xs: "flex", md: "none" }, padding: "2px", width: "100%", backgroundColor: "#FF3951", margin: "15px 0" }}></Grid>
              </Grid>
              <Grid sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: { xs: "center", md: "flex-end" }, marginBottom: "20px", width: { xs: "100%", md: "unset" } }}>
                <Grid sx={{ margin: "0 10px", width: { xs: "44px", sm: "75px" }, height: { xs: "45px", sm: "75px" }, background: "#FFFBFB", borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img referrerpolicy='origin' id='rgvjwlaosizpnbqejzpergvj' style={{ cursor: "pointer", width: '80%', }} onclick='window.open("https://logo.samandehi.ir/Verify.aspx?id=349273&p=xlaoaodspfvluiwkjyoexlao", "Popup","toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")' alt='logo-samandehi' src='https://logo.samandehi.ir/logo.aspx?id=349273&p=qftishwlbsiyodrfyndtqfti' />
                </Grid>
                <Grid sx={{ margin: "0 10px", width: { xs: "44px", sm: "75px" }, height: { xs: "45px", sm: "75px" }, background: "#FFFBFB", borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <a referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=355381&amp;Code=ehM4j9MBqtKyn1xas20n" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img referrerpolicy="origin" src="https://Trustseal.eNamad.ir/logo.aspx?id=355381&amp;Code=ehM4j9MBqtKyn1xas20n" style={{ width: '80%', cursor: "pointer" }} alt="اینماد سورین" id="ehM4j9MBqtKyn1xas20n" />
                  </a>
                </Grid>
                <Grid sx={{ margin: "0 10px", width: { xs: "44px", sm: "75px" }, height: { xs: "45px", sm: "75px" }, background: "#FFFBFB", borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography component={"img"} alt="sample" src={inamad} sx={{ width: '80%' }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ display: { xs: "none", md: "flex" }, padding: "2px", width: "100%", backgroundColor: "#FF3951" }}></Grid>
            <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: { xs: "center", md: 'space-between' }, flexDirection: 'row', margin: "20px 0", flexWrap: "wrap" }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', width: { xs: "50%", md: "20%" } }}>
                <NavLink to={`/Portfolio/Designing`} style={{ textDecoration: "none" }}>
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("Designing")}</Typography>
                </NavLink>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', width: { xs: "50%", md: "20%" } }}>
                <NavLink to={`/Portfolio/Food`} style={{ textDecoration: "none", width: { xs: "50%", md: "20%" } }}>
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("Food")}</Typography>
                </NavLink>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', width: { xs: "50%", md: "20%" } }}>
                <NavLink to={`/Portfolio/Educational`} style={{ textDecoration: "none", width: { xs: "50%", md: "20%" } }}>
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("Educational")}</Typography>
                </NavLink>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', width: { xs: "50%", md: "20%" } }}>
                <NavLink to={`/Portfolio/Entertainmentgame`} style={{ textDecoration: "none", width: { xs: "50%", md: "20%" } }}>
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("Entertainmentgame")}</Typography>
                </NavLink>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', width: { xs: "50%", md: "20%" } }}>
                <NavLink to={`/Portfolio/Travel`} style={{ textDecoration: "none", width: { xs: "50%", md: "20%" } }}>
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("Travel")}</Typography>
                </NavLink>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', width: { xs: "50%", md: "20%" } }}>
                <NavLink to={`/Portfolio/Medical`} style={{ textDecoration: "none", width: { xs: "50%", md: "20%" } }}>
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("Medical")}</Typography>
                </NavLink>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', width: { xs: "50%", md: "20%" } }}>
                <NavLink to={`/Portfolio/Technology`} style={{ textDecoration: "none", width: { xs: "50%", md: "20%" } }}>
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("Technology")}</Typography>
                </NavLink>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', width: { xs: "50%", md: "20%" } }}>
                <NavLink to={`/Portfolio/Blog`} style={{ textDecoration: "none", width: { xs: "50%", md: "20%" } }}>
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("Blog")}</Typography>
                </NavLink>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', width: { xs: "50%", md: "20%" } }}>
                <NavLink to={`/Portfolio/Financial`} style={{ textDecoration: "none", width: { xs: "50%", md: "20%" } }}>
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("Financial")}</Typography>
                </NavLink>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', width: { xs: "50%", md: "20%" } }}>
                <NavLink to={`/Portfolio/PicVideoMusic`} style={{ textDecoration: "none", width: { xs: "50%", md: "20%" } }}>
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("PicVideoMusic")}</Typography>
                </NavLink>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', width: { xs: "50%", md: "20%" } }}>
                <NavLink to={`/Portfolio/BeautyAndHealth`} style={{ textDecoration: "none", width: { xs: "50%", md: "20%" } }}>
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("BeautyAndHealth")}</Typography>
                </NavLink>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', width: { xs: "50%", md: "20%" } }}>
                <NavLink to={`/Portfolio/Business`} style={{ textDecoration: "none", width: { xs: "50%", md: "20%" } }}>
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("Business")}</Typography>
                </NavLink>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', width: { xs: "50%", md: "20%" } }}>
                <NavLink to={`/Portfolio/ECommerce`} style={{ textDecoration: "none", width: { xs: "50%", md: "20%" } }}>
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("ECommerce")}</Typography>
                </NavLink>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', width: { xs: "50%", md: "20%" } }}>
                <NavLink to={`/Portfolio/Marketing`} style={{ textDecoration: "none", width: { xs: "50%", md: "20%" } }}>
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("Marketing")}</Typography>
                </NavLink>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: { xs: "flex-start", md: 'center' }, justifyContent: { xs: "flex-start", md: 'center' }, width: { xs: "55%", md: "20%" } }}>
                <NavLink to={`/Portfolio/Other`} style={{ textDecoration: "none", width: { xs: "50%", md: "20%" } }}>
                  <Typography component={"p"} sx={{ fontSize: "16px", color: "white" }}>{t("Other")}</Typography>
                </NavLink>
              </Grid>
            </Grid>
            <Grid sx={{ padding: "2px", width: "100%", backgroundColor: "#FF3951" }}></Grid>
          </Grid>
          <Grid sx={{ display: "flex", width: { sm: "100%", md: "20%" }, alignItems: 'center', justifyContent: "center" }}>
            <Typography component={"img"} alt="image" src={footer} sx={{ width: { xs: "220px", sm: "390px", md: "100%" } }} />
          </Grid>
        </Grid>
        <Grid sx={{ display: "flex", flexDirection: "column", alignItems: 'center', marginTop: "10px", width: "100%", justifyContent: 'center' }}>
          <Typography component={"span"} sx={{ fontSize: { xs: "8px", sm: "14px" }, color: 'white', textAlign: "center" }}>{t("footerContent")}</Typography>
          {
            location.pathname === "/" &&
            <Grid sx={{ display: "flex", flexDirection: "column", alignItems: 'center', width: "100%", justifyContent: 'center' }}>
              <Typography sx={{ cursor: "pointer", backgroundColor: "#062B33", margin: "10px 0" }} onClick={clickHandler}>
                {open ? <KeyboardArrowUpIcon sx={{ color: '#FF3951', fontSize: '20px', border: "1px solid #FF3951", borderRadius: "50%" }} /> :
                  <KeyboardArrowDownIcon sx={{ color: '#FF3951', fontSize: '20px', border: "1px solid #FF3951", borderRadius: "50%" }} />}
              </Typography>
              <Grid sx={{ display: open ? "flex" : 'none', alignItems: "center", justifyContent: "center", width: { xs: "90%", lg: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "column", width: "unset" }}>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle1")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc1")}</Typography>
                  <Grid>
                    <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle2")}</Typography>
                    <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc2")}</Typography>
                    <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc3")}</Typography>
                    <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc7")}</Typography>
                  </Grid>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle4")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc4")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle8")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc8")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc11")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle9")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc10")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc9")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle12")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc12")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle13")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc13")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle14")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc14")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle15")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc16")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc15")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle17")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc17")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc18")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle19")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc19")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle20")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc20")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc21")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle22")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc22")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc23")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle24")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc24")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc25")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle26")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc26")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc27")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle28")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc28")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc29")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle30")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc30")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc31")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle32")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc32")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc33")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle34")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc34")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc35")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle36")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc36")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle37")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc37")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle39")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc40")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc39")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle41")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc43")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc42")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc41")}</Typography>
                  <Typography component={"h2"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, fontWeight: "700", margin: "3px" }}>{t("footerTitle44")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc47")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc45")}</Typography>
                  <Typography component={"p"} sx={{ color: "white", textAlign: "justify", textJustify: "inter-word", fontSize: { xs: "8px", sm: "16px" }, margin: "3px" }}>{t("fotterDesc44")}</Typography>
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "20px 0" }}>
          <Typography component={"span"} sx={{ fontSize: { xs: "8px", sm: "14px" }, color: "#757575", fontWeight: "bold" }}>{t("roulsFooter")}</Typography>
        </Grid>
      </Grid>
    </>
  );
}
export default Footer;