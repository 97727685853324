import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, TextField, Button } from "@mui/material"
import searchIcon from '../../image/icon/search.png';
import iconArrowRight from '../../image/icon/iconArrowRight.png';
import iconArrowLeft from '../../image/icon/iconArrowLeft.png';
import iconArrowTop from '../../image/icon/iconArrowTop.png';
import { NavLink, useNavigate } from "react-router-dom";
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import backSideMenu from '../../image/articles/backSideMenu.png';
import { Articles } from "../Magzin/Articles";

const ArticleSide = ({ tags, readMore }) => {
  const { t, i18n } = useTranslation();
  const [serachOpen, setSearchOpen] = useState(false);
  const [openTag, setOpenTag] = useState(false);
  const [openArticle, setOpenArticle] = useState(false)
  const [openRead, setOpenRead] = useState(false);
  const [sideMenuOpen, setSideMenuOpen] = useState(false)
  const [search, setSearch] = useState("");
  const [reletedArticle, setReletedArticle] = useState(null);
  const [readMoreData, setReadMoreData] = useState(null);

  const Navigate = useNavigate();
  const tag = ["Programming", "UIUX", "Graphic", "WebDesign", "Seo", "Application", "Beginner", "Advanced", "Intermediate","Technology","Knowable"]

  useEffect(() => {
    window.addEventListener("resize", resizeSilde);
    return () => {
      window.removeEventListener("resize", resizeSilde);
    };
  }, []);

  const resizeSilde = () => {
    setSideMenuOpen(
      window.innerWidth <= 600 && false
    );
  }
  useEffect(() => {
    relatedArticle(tags);
  }, [tags, readMore])

  const relatedArticle = (tags) => {
    let article = [];
    let result = [];
    let randomList = [];

    if (tags) {
      tags.forEach((i) => {
        if ((i !== "Advanced") && (i !== "Beginner") && (i !== "Intermediate")) {
          Articles.forEach((j) => {
            j.tags.forEach((p) => {
              if (p === i) {
                article.push(j)
              }
            })
          })
        }
      })
    }
    if (article !== null) {
      for (let i = 1; randomList.length < 6; i++) {
        let randomNum = Math.floor(Math.random() * article.length);
        if (!randomList.find((item) => item === randomNum || item === 0)) {
          randomList.push(randomNum);
        }
      }
      for (let i = 0; i < randomList.length; i++) {
        let randomNum = randomList[i];
        result.push(article[randomNum]);
      }
    }
    setReletedArticle(result.length > 0 && result);
  }

  const searchHandeler = () => {
    if (search !== "") {
      Navigate("/Academy/" + search)
    }
  }
  const serachHandler = () => {
    setSearchOpen(true);
  }
  const TagHandeler = () => {
    setOpenTag(!openTag);
    if (openTag === false) {
      setOpenArticle(false)
      setOpenRead(false)
    }
  }
  const ArticleHandeler = () => {
    setOpenArticle(!openArticle);
    if (openArticle === false) {
      setOpenTag(false)
      setOpenRead(false)
    }
  }
  const ReadMoreHandeler = () => {
    setOpenRead(!openRead);
    if (openRead === false) {
      setOpenArticle(false)
      setOpenTag(false)
    }
  }
  const sideMenuHandeler = () => {
    setSideMenuOpen(true);
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchHandeler();
    }
  }
  return (
    <>
      <Grid item xs={10} sm={4.5} md={4} lg={3} sx={{ display: "flex", alignItems: 'flex-start', justifyContent: { xs: 'space-between', sm: 'flex-start' }, maxWidth: { xs: "95%", sm: "90%", lg: "307px" }, minWidth: { xs: "100%", sm: "90%", lg: "100%" }, margin: { xs: "20px 5px", sm: "20px 0" } }}>
        <Grid onClick={serachHandler} sx={{
          display: serachOpen === false ? 'flex' : "none", justifyContent: "center", alignItems: 'center', boxShadow: "0px 0px 6px rgba(255, 57, 81, 0.82), inset 3px 0px 4px rgba(103, 103, 103, 0.25), inset 0px -4px 4px rgba(103, 103, 103, 0.25)",
          borderRadius: "8px", width: { xs: "42px", sm: "50px" }, height: { xs: "37px", sm: "45px" }, cursor: "pointer", margin: "0 10px"
        }}>
          <Typography component={"img"} alt="search" src={searchIcon} />
        </Grid>
        <TextField label={t("search")}
          className="textField"
          variant='outlined'
          fullWidth
          value={search}
          onKeyDown={handleKeyDown}
          onChange={(e) => setSearch(e.target.value)}
          size="small"
          sx={{
            width: { xs: "85%", sm: "40%", lg: "307px" }, display: serachOpen ? 'flex' : "none", alignItems: "center", border: "1px solid rgba(255, 57, 81, 0.64)",
            boxShadow: "0px 0px 6px rgba(255, 57, 81, 0.82)", borderRadius: "8px", margin: i18n.language === "fa" ? "0 10px 0 0" : "0 0 0 10px"
            , "& .MuiOutlinedInput-input": { padding: { xs: "2px 0", sm: "10px 0" }, alignItems: "center" }
          }}
          InputLabelProps={{ sx: { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "14px" : "12px" } } }}
          InputProps={{
            endAdornment: <Button onClick={searchHandeler}> <Typography component={"img"} alt="search" src={searchIcon} sx={{ padding: "0 5px" }} /></Button>,
          }}
        />
        <Grid onClick={sideMenuHandeler} sx={{
          display: { xs: "flex", sm: "none" }, alignItems: "center", justifyContent: "center", borderRadius: "8px", width: { xs: "42px", sm: "50px" }, height: { xs: "37px", sm: "45px" }, cursor: "pointer"
          , boxShadow: "0px 0px 6px rgba(255, 57, 81, 0.82), inset 3px 0px 4px rgba(103, 103, 103, 0.25), inset 0px -4px 4px rgba(103, 103, 103, 0.25)", margin: "0 20px"
        }}>
          <WidgetsRoundedIcon sx={{ textShadow: "2px 2px 2px rgba(0, 0, 0, 0.29)" }} />
        </Grid>

      </Grid>
      <Grid item sm={4.5} md={4} lg={3} sx={{ display: "flex", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: "column", position: 'sticky', top: "0px" }}>
        <Grid sx={{
          display: { xs: 'none', sm: 'flex' }, alignItems: 'flex-start', justifyContent: "flex-start", maxWidth: { sm: "90%", lg: "280px !important", xl: "307px !important" }, margin: "10px 0"
        , borderRadius: "0 50px", boxShadow: " 0px 0px 20px -3px rgba(255, 57, 81, 0.6)", background: "#F8F8F8", flexDirection: 'column', padding: {xs: "10px 0", md: "20px 0" }
        }}>
        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", width: "100%" }}>
          <Typography component={"img"} alt="icon" src={iconArrowRight} sx={{ width: { sm: "18px", md: "23px" }, height: { sm: "22px", md: "30px" }, margin: "0 3px" }} />
          <Typography sx={{ fontWeight: "bold", fontSize: { sm: "14px", md: "18px" }, color: "#070707" }}>{t("tags")}</Typography>
          <Typography component={"img"} alt="icon" src={iconArrowLeft} sx={{ width: { sm: "18px", md: "23px" }, height: { sm: "22px", md: "30px" }, margin: "0 3px" }} />
        </Grid>
        <Grid key={"data1"} sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", flexWrap: "wrap", width: "100%", margin: "10px 0", flexDirection: "row" }}>
          {
            tag.map((item, index) => (
              <Grid key={index} sx={{
                display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px", background: "#ffffff", border: "1px solid #FFFFFF", boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)"
                , borderRadius: "19px", minWidth: "70px", margin: { sm: "5px", md: "5px 10px" }, cursor: "pointer"
              }}>
                <NavLink to={`/Academy/${item}`} style={{ textDecoration: "none" }} target="_blank">
                  <Typography sx={{ color: "#555555", textAlign: "center", fontSize: { sm: "10px", md: "12px" }, fontWeight: "700" }}>{t(item)}</Typography>
                </NavLink>
              </Grid>
            ))
          }
        </Grid>
      </Grid>
      <Grid sx={{
        display: { xs: 'none', sm: 'flex' }, alignItems: 'flex-start', justifyContent: "flex-start", minWidth: { sm: "90%", lg: "280px !important", xl: "307px !important" },
        margin: "10px 0", maxWidth: { sm: "90%", lg: "280px !important", xl: "307px !important" }
        , borderRadius: "0 50px", boxShadow: " 0px 0px 20px -3px rgba(255, 57, 81, 0.6)", background: "#F8F8F8", flexDirection: 'column', padding: { xs: "10px 0", md: "20px 0" }
      }}>
        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", width: "100%" }}>
          <Typography component={"img"} alt="icon" src={iconArrowRight} sx={{ width: { sm: "18px", md: "23px" }, height: { sm: "22px", md: "30px" }, margin: "0 3px" }} />
          <Typography sx={{ fontWeight: "bold", fontSize: { sm: "14px", md: "18px" }, color: "#070707" }}>{t("relatedArticle")}</Typography>
          <Typography component={"img"} alt="icon" src={iconArrowLeft} sx={{ width: { sm: "18px", md: "23px" }, height: { sm: "22px", md: "30px" }, margin: "0 3px" }} />
        </Grid>
        <Grid key={"data2"} sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", flexWrap: "wrap", width: "100%", margin: "10px 0", flexDirection: "row" }}>
          {
            reletedArticle !== null ?
              reletedArticle.map((item, index) => (
                <Grid key={index} sx={{
                  display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", borderBottom: reletedArticle.length === index + 1 ? "unset" : "1px solid #CBCBCB"
                  , margin: "5px", cursor: "pointer", width: "80%"
                }}>
                  <NavLink to={item !== undefined && `/Academy${item.link}`} style={{ textDecoration: "none" }} target="_blank">
                    <Typography variant="body/" noWrap sx={{ color: "#555555", textAlign: "center", fontSize: { sm: "10px", md: "12px" }, fontWeight: "700" }}>
                      {t(item !== undefined ? item.title.length <= 40 ? item.title : (item.title.substr(0, 40) + "...") : "")}
                    </Typography>
                  </NavLink>
                </Grid>
              ))
              :
              <Typography></Typography>
          }
        </Grid>
      </Grid>
      <Grid sx={{
        display: { xs: 'none', sm: 'flex' }, alignItems: 'flex-start', justifyContent: "flex-start", minWidth: { sm: "90%", lg: "280px !important", xl: "307px !important" },
        margin: "10px 0", maxWidth: { sm: "90%", lg: "280px !important", xl: "307px !important" }
        , borderRadius: "0 50px", boxShadow: " 0px 0px 20px -3px rgba(255, 57, 81, 0.6)", background: "#F8F8F8", flexDirection: 'column', padding: { xs: "10px 0", md: "20px 0" }
      }}>
        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", width: "100%" }}>
          <Typography component={"img"} alt="icon" src={iconArrowRight} sx={{ width: { sm: "18px", md: "23px" }, height: { sm: "22px", md: "30px" }, margin: "0 3px" }} />
          <Typography sx={{ fontWeight: "bold", fontSize: { sm: "14px", md: "18px" }, color: "#070707" }}>{t("readMoreTitle")}</Typography>
          <Typography component={"img"} alt="icon" src={iconArrowLeft} sx={{ width: { sm: "18px", md: "23px" }, height: { sm: "22px", md: "30px" }, margin: "0 3px" }} />
        </Grid>
        <Grid key={"data3"} sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", flexWrap: "wrap", width: "100%", margin: "10px 0", flexDirection: "row" }}>
          {
            readMore.map((item, index) => (
              <Grid key={index} sx={{
                display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", borderBottom: readMore.length === index + 1 ? "unset" : "1px solid #CBCBCB"
                , width: "80%", margin: "5px", cursor: 'pointer'
              }}>
                <NavLink to={item !== undefined && `/Academy${item.link}`} target="_blank" style={{ textDecoration: "none" }}>
                  <Typography sx={{ color: "#555555", textAlign: "center", fontSize: { sm: "10px", md: "12px" }, fontWeight: "700" }}>
                    {t(item !== undefined && item.title.length <= 40 ? item.title : (item.title.substr(0, 40) + "..."))}
                  </Typography>
                </NavLink>
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    </Grid >
      <Grid sx={{
        display: sideMenuOpen ? 'flex' : "none", alignItems: "center", justifyContent: 'flex-start', flexDirection: "column", width: "100vw", height: "100vh", position: "fixed", top: "0", zIndex: "800", backgroundSize: "100% 100%",
        backgroundImage: `url(${backSideMenu})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
      }}>
        <Grid sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: "85%", marginTop: "30px" }}>
          <CloseRoundedIcon onClick={() => setSideMenuOpen(false)} sx={{ fontSize: "35px", fontWeight: '700', cursor: "pointer" }} />
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', flexDirection: 'column' }}>
          <Grid onClick={TagHandeler} sx={{ display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: '700', color: "#070707" }}>{t("tags")}</Typography>
            <Typography alt="icon" component={"img"} src={iconArrowTop} sx={{ width: '18px', height: "15px", transform: openTag && "rotate(180deg)", margin: "5px 0" }} />
          </Grid>
          <Grid key={"data4"} sx={{ display: openTag ? "flex" : "none", alignItems: "center", justifyContent: 'center', flexWrap: "wrap", width: "100%" }}>
            {
              tag.map((item, index) => (
                <Grid key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "30%" }}>
                  <Grid key={"in"} sx={{
                    display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px", background: "#ffffff", border: "1px solid #FFFFFF", boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)"
                    , borderRadius: "19px", width: "80px !important", margin: { xs: "5px", md: "5px 10px" }, height: "23px"
                  }}>
                    <NavLink to={`/Academy/${item}`} target="_blank" style={{ textDecoration: "none" }}>

                      <Typography sx={{ color: "#555555", textAlign: "center", fontSize: "10px" }}>{t(item)}</Typography>
                    </NavLink>
                  </Grid>
                </Grid>
              ))
            }
          </Grid>
          <Grid onClick={ArticleHandeler} sx={{ display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column', margin: "10px 0 0 0" }}>
            <Typography sx={{ fontSize: '14px', fontWeight: '700', color: "#070707" }}>{t("relatedArticle")}</Typography>
            <Typography alt="icon" component={"img"} src={iconArrowTop} sx={{ width: '18px', height: "15px", transform: openArticle && "rotate(180deg)", margin: "5px 0" }} />
          </Grid>
          <Grid key={"data5"} sx={{ display: openArticle ? "flex" : "none", alignItems: "center", justifyContent: 'center', flexWrap: "wrap", width: "100%" }}>
            {
              reletedArticle !== null ?
                reletedArticle.map((item, index) => (
                  <Grid key={index} sx={{
                    display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", borderBottom: reletedArticle.length === index + 1 ? "unset" : "1px solid #CBCBCB"
                    , width: "50%", margin: "5px"
                  }}>
                    <NavLink to={item !== undefined && `/Academy${item.link}`} target="_blank" style={{ textDecoration: "none" }}>
                      <Typography sx={{ color: "#555555", textAlign: "center", fontSize: "12px" }}>{t(item !== undefined ? item.title : "")}</Typography>
                    </NavLink>
                  </Grid>
                ))
                :
                <Typography></Typography>
            }
          </Grid>
          <Grid onClick={ReadMoreHandeler} sx={{ display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column', margin: "10px 0 0 0" }}>
            <Typography sx={{ fontSize: '14px', fontWeight: '700', color: "#070707" }}>{t("readMoreTitle")}</Typography>
            <Typography alt="icon" component={"img"} src={iconArrowTop} sx={{ width: '18px', height: "15px", transform: openRead && "rotate(180deg)", margin: "5px 0" }} />
          </Grid>
          <Grid key={"data6"} sx={{ display: openRead ? "flex" : "none", alignItems: "center", justifyContent: 'center', flexWrap: "wrap", width: "100%" }}>
            {
              readMore.map((item, index) => (
                <Grid key={index} sx={{
                  display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", borderBottom: readMore.length === index + 1 ? "unset" : "1px solid #CBCBCB"
                  , width: "50%", margin: "5px"
                }}>
                  <NavLink to={item !== undefined && `/Academy${item.link}`} target="_blank" style={{ textDecoration: "none" }}>
                    <Typography sx={{ color: "#555555", textAlign: "center", fontSize: "12px" }}>{t(item !== undefined && item.title)}</Typography>
                  </NavLink>
                </Grid>
              ))

            }
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ArticleSide;