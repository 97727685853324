import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import { author, date, h1, headPhoto, largePhoto, linkArticle, rootArticle, tags, paragraph, timeRead, view, listItemCircle, h2, h4, smallPhoto, sourceLink } from "../articleFunction/ArticleFunction";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { Articles } from "./Articles";
import ArticleSide from "../articleFunction/ArticleSide";

//import photo
import photo1 from '../../image/articles/04/photo1.jpg';
import photo2 from '../../image/articles/04/photo2.jpg';
import photo3 from '../../image/articles/04/photo3.jpg';
import photo4 from '../../image/articles/04/photo4.jpg';
import Loader from "../Loader";
import PortfolioButton from "../PortfolioButton";


let articleData = null;
const Article04 = () => {

  //NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${articleData !== null && articleData.title}`
    document.getElementById("main-heading").innerHTML = articleData !== null && articleData.h1;
    document.getElementsByTagName('meta')["description"].content = articleData !== null && articleData.desc;
    document.getElementsByTagName('meta')["keywords"].content = articleData !== null && articleData.keywords;
    document.getElementsByTagName('meta')["author"].content = articleData !== null && articleData.author;
    setCanonicalTag()
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item
    })
  }, [])

  useEffect(() => {
    articleData !== null && addView(); getView();
  }, [])

  const addView = () => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v1/article/addView",
      method: "POST",
      data: { id: articleData.id }
    }))
      .then((res) => { })
  }
  const getView = () => {
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/article/getView`,
      method: "GET",
      params: { id: articleData.id }
    }))
      .then((res) => {
        setDataView(res.data.data)
      })
  }

  //change This part

  //read More
  const readMore = [
    {
      id: 1,
      title: "",
      link: ""
    }
  ]
  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: "rtl", alignItems: "center" }}>
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid container sx={{ display: "flex", alignItems: { xs: "unset", sm: 'flex-start' }, justifyContent: "space-between", flexDirection: "row", flexWrap: "wrap", width: { xs: "100%", sm: "85%" } }}>
          <ArticleSide tags={articleData !== null && articleData.tags} readMore={readMore} />
          {
            articleData !== null ?
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: 'center', sm: "flex-end" }, flexDirection: "column", width: { xs: "100%", sm: "60%", md: "70%" } }} >
                {/* do not change */}

                {headPhoto(articleData.image)}
                {h1(articleData.h1, t)}
                {timeRead(articleData.timeRead, t)}
                {date(articleData.date, i18n)}
                <Grid sx={{ display: 'flex', alignItems:{ xs:"center",sm:'flex-start'}, justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "column" }}>
                {/* The text of the article */}
                <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    از مهمترین پارامترهای ایجاد وب سایت کاربر پسند، مفاهیم رابط کاربری  (UI) و تجربه کاربری (UX) می باشد. ما در این مقاله از سورین قصد داریم تا نگاهی به برخی از ترند های طراحی UI و UX سال 2023 بیندازیم. البته برخی از ترند ها که در گذشته رواج داشتند یا دستخوش تغییر شدند و تکامل یافتند یا به طور کامل از بین رفتند و جای خود را به روند های جدید تر دادند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    ترندهای UI سال 2023
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo1} alt="ترند UI سال 2023 - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                      ترند UI سال 2023
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    از آنجا که تجربه کاربری و رابط کاربری در طراحی سایت از اهمیت ویژه ای برخوردار است بنابراین ما در اینجا به بررسی ترند های طراحی UI و UX می پردازیم. در زیر برخی از ترند های UI را مورد توجه قرار دادیم.    
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    1. طراحی موشن (Motion Design) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                   یکی از ترندهای جذاب UI در سال 2023 طراحی حرکت می باشد که با نام موشن گرافیک شناخته می شود. امروزه موشن ها بخش جدایی ناپذیر از طراحی رابط کاربری شده اند. برای اینکه کاربر را جذب صفحات سایت کنیم بهتر است از تصاویر متحرک استفاده کنیم. اگر به سایت های پر بازدید توجه داشته باشید متوجه خواهید شد که اکثر آنها شامل موشن گرافیک و جلوه های انتقال صفحه می باشند. طراح UI این موشن ها را به صور مختلف داخل سایت قرار می دهد. برخی از آن‌ها به صورت ویدیوهای کوتاه و برخی از آن‌ها به صورت گیف(Gif) طراحی می شوند.
                   </Typography> 
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    2. سه بعدی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    طراحی سه بعدی در سال های 2020 و 2021 بعنوان ترندی نوظهور مورد استقبال قرار گرفت . کارایی این روند برای ایجاد بعد و عمق به طرح، استفاده از سایه، انیمیشن یا لایه می باشد.  این ترند برای شرکت هایی که ارائه محصولات دارند بسیار پر کاربرد می باشد. طراحی سه بعدی در زمینه تجارت الکترونیک، صنعت مد، آموزش، فرهنگ بسیار مورد توجه قرار گرفته است. شرکت اپل که یکی از شرکت های بزرگ در زمینه فناوری می باشد و  در ساخت لوازم الکترونیکی مصرفی فعالیت دارد، برای ارائه محصولات خود از طراحی سه بعدی بهره می برد.    
                   </Typography>
                   <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                     البته باید در نظر داشت که استفاده بیش از حد و غیر ضروری از گرافیک ها و انیمیشن های سه بعدی، وب سایت را برای کاربر کسل کننده می کند. 
                     </Typography>   
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    3. پیمایش اسکرولی ( Scrollytelling )
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    تصویر نویسی یا اسکرول غوطه ور را می توان زیر دسته موشن دانست که در تعامل کاربر با محصولات بسیار موثر است. Scrollytelling روشی است که از امکان پیمایش در سایت برای نشان دادن و روایت محتوای چندرسانه‌ای مانند متن، تصاویر، صدا و فیلم استفاده می‌کند. این قابلیت این امکان را برای محتوا کاران فراهم می اورد که اطلاعات و محتواهای پیچیده و سنگین را در قالب بخش‌ها و بلاک‌های قابل هضم و منظم به کاربران نمایش دهند. پیمایش اسکرولی روشی جدید در بخش طراحی انیمیشن می باشد که اگر به درستی اجرا شود نقش بسزایی در جذابیت سایت دارد.    
                   </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    4. رنگ های شاد
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                     از نظر روانشناسی استفاده از رنگ های شاد و پررنگ در جذب کاربر بسیار موثر است. مسدودسازی رنگ(Color Blocking)، بافت‌های ملایم و چشم‌نواز باعث جذابیت سایت می شود. اثر این روند بقدری بالاست که تمامی شرکت های بزرگ این روش را برای طراحی لوگوها و بنرهای کمپین‌های مختلف خود بکار می برند.
                   </Typography> 
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                   5. گرادیانت
                  </Typography> 
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo2} alt="گرادیانت - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                      گرادیانت
                      </Typography>
                    </Grid>
                  </Grid> 
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    استفاده از گرادیانت در طراحی وب سایت در سال 2023 با استقبال جالبی از کاربران روبرو شده است. پیاده سازی از این طرح در طراحی و پذیرش این رنگ ها ازسوی کاربران جهت برآورده کردن نیازهای روانی کاربران بعد از دوران کرونا می باشد.     
                   </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    6. طراحی تمیز
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                        این روند سالیان سال است که مورد توجه طراحان قرار دارد. این روند در سال 2023 نیز یکی از ترندهای طراحی رابط کاربری محسوب می شود.  طراحی هر چه ساده‌تر، مینیمال‌تر و تمیزتر باشد تعامل بیشتری با کاربر برقرار می کند.
                   </Typography>
                   <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    اما ذکر این نکته لازم است که این سادگی نباید سبب شود که تجربه کاربری نادیده گرفته شود. طرح در عین اینکه ساده طراحی می شود اما باید به گونه ای باشد که کاربر به راحتی و با یک نگاه گزینه مورد نظر را در سایت پیدا کند.
                    </Typography>    
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    7. شخصی سازی طراحی وب سایت
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                     قابلیت سفارشی سازی این امکان را  به کاربران می دهد که کامپوننت های طراحی را با سلیقه خودشان در سایت قرار دهند. بعنوان مثالی از سفارشی سازی می توان به اپلیکیشن تلگرام اشاره کرد. در تلگرام کاربر این امکان را دارد که  رنگ، والپیپر، زبان و دیگر موارد را تغییر دهد.   
                   </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    8. Micro-interactions
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    ایجاد واسطه‌ برای تعامل‌های کوچک در وب‌سایت، از دیگر ترند‌های طراحی UI در سال 2023 می باشد. از این قابلیت برای گرفتن بازخورد بیشتر و برقراری ارتباط بهتر با کاربر استفاده می‌شود. طراحی انیمیشن‌های کوچک یا اضافه کردن المنت‌های بصری در وب‌سایت و یا حتی عوض شدن رنگ منوها زمانی که کاربر با ماوس روی آن می ایستد، تعامل بیشتری را هنگام پیمایش ایجاد می کند.    
                   </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                   9. عدم تقارن
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                     این ترند روشی است که سالیان سال در طراحی رابط کاربری مورد توجه است و همچنین در سال 2023 نیز به عنوان روندی تاثیر گذار استفاده می شود.    
                   </Typography>
                   <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, width:"100%"}}>
                    ترندهای UX سال 2023
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo3} alt="ترند UX  سال 2023 - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                      ترند UX  سال 2023
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                     در بالا ما به بررسی تعدادی از ترند های طراحی UI پرداختیم. حال در این قسمت سعی بر این داریم تا ترند های طراحی UX سال 2023 را مورد بررسی قرار دهیم. پس با ما همراه باشید.   
                   </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                   1. حالت تاریک و ضد نور
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                        انتخاب حالت نور در طراحی از ترندهای طراحی تجربه کاربری 2023 می باشد. طراحی وب سایت باید به گونه ای باشد که کاربرانی که از لحاظ بینایی دچار ضعف هستند بتوانند نور صفحه را با زدن دکمه ای تنظیم کنند و به حالت تاریک در آورند. بنابراین ایجاد استفاده از این قابلیت که کاربر بتواند نور روشن را تاریک را برای وب سایت انتخاب کند از روندهای طراحی 2023 می باشد که طراح باید آن را مورد توجه قرار دهد.
                   </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                   2.  AR و VR
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    فناوری AR/VR  بیشتر برای طراحانی کارایی دارد که به دنبال ایجاد یک تجربه وب سایت همه جانبه هستند. AR یا واقعیت افزوده به صحنه هایی از دنیای واقعی می گویند که با صدا، گرافیک و سایر ورودی های تولید شده توسط کامپیوتر تقویت میشود. VR یا واقعیت مجازی یک شبیه سازی کامپیوتری از یک محیط می باشد. این فناوری در حوزه های آموزش، خرده فروشی و مراقبتهای بهداشتی بسیار پر کاربرد می باشد.    
                   </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                   3.  سوپر اپلیکیشن
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                     به برنامه ای که چندین خدمات مانند پیام رسانی، پرداخت الکترونیک و تحویل را باهم ارائه دهد سوپر اپلیکیشن می گویند. این سوپر اپلیکیشن ها در سایت بسیار پر کاربرد است. بنابراین یکی از ترند های طراحی رابط کاربری 2023 طراحی سوپر اپلیکیشن ها می باشد.   
                   </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                   4. طراحی کتابخانه‌ها و سیستم‌ها
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    ابزار های طراحی مانند Figma، Adobe XD و Sketch به طراحان این امکان را می دهد تا کامپوننت هایی را بسازند که بتوانند در تمام صفحات توسعه دهند. همچنین این کامپوننت ها را بصورت کتابخانه ای در دسترس سایر افراد و اعضای تیم نیز قرار می دهند. این قابلیت یکی از بهترین روش ها برای طراحانی است که روی روی یک پروژه مشترک فعالیت می کنند.    
                   </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                   5. اولویت‌بندی سرعت صفحه
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    سرعت یکی از موارد مهم در بازدید صفحه می باشد. یکی از دلایل اصلی برای ترک صفحه سرعت پایین لود صفحات می باشد. بنابراین یکی از مسائلی که طراح باید در نظر داشته باشد این است که صفحاتی در زمان طراحی بهینه سازی کند تا سرعت بارگذاری افزایش یابد.  بنابراین اولویت سرعت بارگذازی صفحات سایت را یکی دیگر از ترندهای 2023 می توان در نظر گرفت.     
                   </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                   6. رویکرد سازگار با موبایل
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                     از آنجا که استفاده از تلفن همراه بسیار زیاد شده است بنابراین سازگاری وب سایت ها با موبایل روندی است که طراحان باید به آن توجه ویژه داشته باشند.   
                   </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                   7. طراحی وب سایت برای معلولان
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)",width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo4} alt="طراحی سایت مخصوص معلولان- سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                      طراحی سایت مخصوص معلولان
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                        یکی از مواردی که در طراحی UX بسیار مهم تلقی می شود طراحی سایت برای معلولان است. طراحی باید به گونه ای انجام گیرد که استفاده و پیمایش برای افراد کم توان و معلول به راحتی انجام پذیرد. این موضوع در تمام کشورهای اروپایی به یکی از ملزومات قانونی و حقوقی طراحی وب سایت تبدیل شده است.  
                   </Typography>
                   <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                   کلام آخر
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                     برای ایجاد وب سایت کاربر پسند، باید به تجربه کاربری (UX) و رابط کاربری (UI) توجه کافی داشته باشیم. در این مقاله از سورین سعی کردیم تا ترند های طراحی UI و UX سال 2023 را بیان کنیم. با بکارگیری این نکات در طراحی UI و UX می توانید طراحی و تجربه کاربری را بهبود ببخشید.    
                   </Typography>
                {/* do not change */}

                {author(articleData.author, t)}
                  {tags(articleData.tags, t)}
                  {linkArticle(t, url)}
                  {sourceLink(articleData.source, t)}
                  {view(dataview, t)}
                </Grid>
              </Grid>
              :
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "80%", sm: "60%", md: "70%" } }}>
                <Loader />
              </Grid>
          }
        </Grid>
      </Grid>
      <PortfolioButton />
      <Footer />
    </>
  );
}
export default Article04;

