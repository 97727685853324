import { Grid, Typography, TextField, Pagination, Stack, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { headPhoto } from './articleFunction/ArticleFunction';
import { useEffect } from "react";
import { useState } from "react";
import { Articles } from "./Magzin/Articles";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import frame from '../image/articles/sorinMag/frame.png';
import searchIcon from '../image/icon/search.png';
import iconArrowRight from '../image/icon/iconArrowRight.png';
import iconArrowLeft from '../image/icon/iconArrowLeft.png';
import iconArrowTop from '../image/icon/iconArrowTop.png';
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import backSideMenu from '../image/articles/backSideMenu.png';
import Footer from "./Footer";
import { setCanonicalTag } from "./modules/general";
import Loader from "./Loader";
import PortfolioButton from "./PortfolioButton";
import ModalLogin from "./ModalLogin";

// let articlePage = [];
const ArticlesPage = ({ auth }) => {
  const { t, i18n } = useTranslation();
  const [itemCatgroy, setItemCategory] = useState(null);
  const [serachOpen, setSearchOpen] = useState(false);
  const [openQuickAccess, setOpenQuickAccess] = useState(false)
  const [openCategory, setOpenCategory] = useState(false);
  const [sideMenuOpen, setSideMenuOpen] = useState(false)
  const [articleData, setArticleData] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const params = useParams();
  const [type, setType] = useState("");
  const [open, setOpen] = useState(false);
  const [articlePage, setArticlePage] = useState(null)
  const Navigate = useNavigate();

  const quickAccess = [
    {
      id: 1,
      text: "WebDesign",
      link: "/SorinBoard/WebsiteOrder"
    },
    {
      id: 2,
      text: "Application",
      link: "/SorinBoard/AppOrder"
    },
    {
      id: 3,
      text: "UIUX",
      link: "/SorinBoard/UIUXorder"
    },
    {
      id: 4,
      text: "Programming",
      link: "/SorinBoard/ProgrammingOrder",

    },
    {
      id: 5,
      text: "Seo",
      link: "/SorinBoard/SeoOrder"

    },
    {
      id: 6,
      text: "Graphic",
      link: "/SorinBoard/GraphicOrder"
    },
  ]

  const category = [
    {
      id: 1,
      text: "LastestArticles"
    },
    {
      id: 2,
      text: "ArticlesRelatedServices"
    },
    {
      id: 3,
      text: "Beginner"
    },
    {
      id: 4,
      text: "Intermediate",
    },
    {
      id: 5,
      text: "Advanced"
    },
    {
      id: 6,
      text: "SuggestedEditor",
    },
  ]

  useEffect(() => {
    document.title = "آموزش طراحی سایت | اموزش سئو سایت | اموزش طراحی UI/UX | آموزش برنامه نویسی"
    document.getElementById("main-heading").innerHTML = "جدیدترین مقالات آموزش برنامه نویسی | بهترین مقالات طراحی سایت | بهترین مطالب سئو سایت |به روزترین مقالات طراحی UI/UX"
    document.getElementsByTagName('meta')["description"].content = "آموزش از مبتدی تا پیشرفته مباحث نرم افزاری و ارائه مقالات آموزشی مفید در حوزه طراحی سایت توسط شرکت وب گستران سورین ؛ بهترین شرکت طراحی سایت تهران "
    document.getElementsByTagName('meta')["keywords"].content = "آموزش طراحی رابط کاربری | آموزش طراحی تجربه کاربری | اموزش ارتقا رتبه سایت در گوگل | آموزش طراحی گرافیکی | آموزش HTML  , CSS | مباحث آموزشی نرم افزار | مقالات آموزشی طراحی سایت | مطالب آموزش طراحی وب سایت | آموزش طراحی اپلیکیشن موبایل "
    setCanonicalTag()
  }, [params.value]);

  useEffect(() => {
    window.addEventListener("resize", resizeSilde);
    return () => {
      window.removeEventListener("resize", resizeSilde);
    };
  }, []);

  useEffect(() => {
    setItemCategory(params.value)
  }, [])

  useEffect(() => {
    getData(params.value);
  }, [params.value])

  useEffect(() => {
    if (articleData !== null) {
      setArticlePage(articleData.slice((page - 1) * 10, page * 10))
    }
  }, [articleData, page])

  const getData = (value) => {
    if (value === "LastestArticles") {
      lastestArticlesHandeler()
    }
    else if (value === "SuggestedEditor") {
      suggested();
    }
    else if (value === "ArticlesRelatedServices") {
      relatedServices();
    }
    else if ((value === "Programming") || (value === "UIUX") || (value === "Graphic") || (value === "Seo") || (value === "Application") ||
      (value === "WebDesign") || (value === "Beginner") || (value === "Advanced") || (value === "Intermediate") || (value === "Technology") || (value === "Knowable")) {
      let list = [];
      Articles.forEach((item) => {
        item.tags.forEach((Element) => {
          Element === value && list.push(item)
        })
      })
      setArticleData(list.map(item => item).reverse());
    }
    else {
      let list = [];
      Articles.forEach((item) => {
        if ((item.h1.includes(value)) || (item.desc.includes(value))) list.push(item)
      })
      setArticleData(list.map(item => item).reverse());
    }
  }

  const suggested = () => {
    let list = [];
    Articles.forEach((item) => {
      item.suggested === "yes" && list.push(item)
    })
    setArticleData(list)
  }

  const relatedServices = () => {
    let list = [];
    let find = false;
    Articles.forEach((item) => {
      item.tags.forEach((Element) => {
        if ((Element === "Programming") || (Element === "UIUX") || (Element === "Graphic") || (Element === "Seo") || (Element === "Application") || (Element === "WebDesign") || (Element === "Technology") || (Element === "Knowable")) {
          list.forEach((value) => {
            find = value.id === item.id ? true : false
          })
          find === false && list.push(item)
        }
      })
    })
    setArticleData(list)
  }

  const lastestArticlesHandeler = () => {
    let list = [];
    let len = Articles.length - 1;
    for (let i = len; i >= 0; i--) {
      list.push(Articles[i])
    }
    setArticleData(list);
  }

  const resizeSilde = () => {
    setSideMenuOpen(
      window.innerWidth <= 600 && false
    );
  }

  const serachHandler = () => {
    setSearchOpen(true);
  }

  const quickAccessHandeler = () => {
    setOpenQuickAccess(!openQuickAccess);
    if (openQuickAccess === false) {
      setOpenCategory(false)
    }
  }

  const categoryHandeler = () => {
    setOpenCategory(!openCategory);
    setOpenQuickAccess(false)
  }

  const sideMenuHandeler = () => {
    setSideMenuOpen(true);
  }

  const onChangePagination = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const searchHandeler = () => {
    if (search !== "") {
      Navigate("/Academy/" + search)
    }
  }

  const closeModal = () => {
    setOpenQuickAccess(false)
    setOpenCategory(false)
    setSideMenuOpen(false)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchHandeler();
    }
  }

  const handleCloseModal = () => {
    setOpen(false);
  }

  const loginHandler = () => {
    setType("L");
    setOpen(true);
  }

  const clickQuickAccess = (value) => {
    let info = JSON.parse(localStorage.getItem('sorinInfoUser'));
    auth === false ? loginHandler() : info.user_type_id !== null && info.user_type_id === 1 || info.user_type_id !== null && info.user_type_id === 2 ? Navigate(value) : Navigate("/SorinBoard/NewOrder");
  }
  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: i18n.language === "fa" ? "rtl" : "ltr", alignItems: "center" }}>
        <Grid sx={{ display: "flex", width: "100%", backgroundColor: "#EDEDED", padding: "15px 0", justifyContent: "center" }}>
          <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: { xs: "95%", md: "86%" } }}>
            <NavLink className="navlink" to="/" target="_blank">
              <Typography sx={{ fontSize: "12px", color: "#757575", padding: "0 5px" }} >{t("mainPage")}</Typography>
            </NavLink>
            <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
            <Typography sx={{ fontSize: "12px", color: "#070707", padding: "0 5px" }}>{t("Academy")}</Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ display: "flex", alignItems: { sm: 'flex-start' }, justifyContent: "space-between", flexDirection: "row", flexWrap: "wrap", width: { xs: "100%", sm: "90%" } }}>
          <Grid item xs={10} sm={4.5} md={4} lg={3} sx={{ display: "flex", alignItems: 'flex-start', justifyContent: { xs: 'space-between', sm: 'flex-start' }, maxWidth: { xs: "95%", sm: "90%", lg: "307px" }, minWidth: { xs: "100%", sm: "90%", lg: "100%" }, margin: { xs: "20px 5px", sm: "20px 0" } }}>
            <Grid onClick={serachHandler} sx={{
              display: serachOpen === false ? 'flex' : "none", justifyContent: "center", alignItems: 'center', boxShadow: "0px 0px 6px rgba(255, 57, 81, 0.82), inset 3px 0px 4px rgba(103, 103, 103, 0.25), inset 0px -4px 4px rgba(103, 103, 103, 0.25)",
              borderRadius: "8px", width: { xs: "42px", sm: "50px" }, height: { xs: "37px", sm: "45px" }, cursor: "pointer", margin: "0 10px"
            }}>
              <Typography component={"img"} alt="search" src={searchIcon} />
            </Grid>
            <TextField label={t("search")}
              className="textField"
              variant='outlined'
              fullWidth
              value={search}
              onKeyDown={handleKeyDown}
              onChange={(e) => setSearch(e.target.value)}
              size="small"
              sx={{
                width: { xs: "85%", sm: "40%", lg: "307px" }, display: serachOpen ? 'flex' : "none", alignItems: "center", border: "1px solid rgba(255, 57, 81, 0.64)",
                boxShadow: "0px 0px 6px rgba(255, 57, 81, 0.82)", borderRadius: "8px", margin: i18n.language === "fa" ? "0 10px 0 0" : "0 0 0 10px"
                , "& .MuiOutlinedInput-input": { padding: { xs: "2px 0", sm: "10px 0" }, alignItems: "center" }
              }}
              InputLabelProps={{ sx: { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "14px" : "12px" } } }}
              InputProps={{
                endAdornment: <Button onClick={searchHandeler}><Typography component={"img"} alt="search" src={searchIcon} sx={{ padding: "0 5px" }} /></Button>,
              }}
            />
            <Grid onClick={sideMenuHandeler} sx={{
              display: { xs: "flex", sm: "none" }, alignItems: "center", justifyContent: "center", borderRadius: "8px", width: { xs: "42px", sm: "50px" }, height: { xs: "37px", sm: "45px" }, cursor: "pointer"
              , boxShadow: "0px 0px 6px rgba(255, 57, 81, 0.82), inset 3px 0px 4px rgba(103, 103, 103, 0.25), inset 0px -4px 4px rgba(103, 103, 103, 0.25)", margin: "0 20px"
            }}>
              <WidgetsRoundedIcon sx={{ textShadow: "2px 2px 2px rgba(0, 0, 0, 0.29)" }} />
            </Grid>

          </Grid>
          <Grid item sm={4.5} md={4} lg={3} sx={{ display: "flex", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: "column", position: 'sticky', top: "0px" }}>
            <Grid sx={{
              display: { xs: 'none', sm: 'flex' }, alignItems: 'flex-start', justifyContent: "flex-start", maxWidth: { sm: "90%", lg: "307px !important" }, margin: "20px 0"
              , borderRadius: "0 50px", boxShadow: " 0px 0px 20px -3px rgba(255, 57, 81, 0.6)", background: "#F8F8F8", flexDirection: 'column', padding: { xs: "10px 0", md: "20px 0" }
            }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", width: "100%" }}>
                <Typography component={"img"} alt="icon" src={iconArrowRight} sx={{ width: { sm: "18px", md: "23px" }, height: { sm: "22px", md: "30px" }, margin: "0 3px" }} />
                <Typography sx={{ fontWeight: "bold", fontSize: { sm: "14px", md: "18px" }, color: "#070707" }}>{t("quickAccess")}</Typography>
                <Typography component={"img"} alt="icon" src={iconArrowLeft} sx={{ width: { sm: "18px", md: "23px" }, height: { sm: "22px", md: "30px" }, margin: "0 3px" }} />
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", flexWrap: "wrap", width: "100%", margin: "10px 0", flexDirection: "row" }}>
                {
                  quickAccess.map((item, index) => (
                    <Grid key={index} onClick={() => clickQuickAccess(item.link)} sx={{
                      display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", borderBottom: quickAccess.length === index + 1 ? "unset" : "1px solid #CBCBCB"
                      , width: "80%", margin: "5px", cursor: "pointer"
                    }}>
                      <Typography sx={{ color: "#555555", textAlign: "center", fontSize: { sm: "10px", md: "12px" }, fontWeight: "700" }}>{t(item.text)}</Typography>
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
            <Grid sx={{
              display: { xs: 'none', sm: 'flex' }, alignItems: 'flex-start', justifyContent: "flex-start", maxWidth: { sm: "90%", lg: "307px !important" }, margin: "20px 0"
              , borderRadius: "0 50px", boxShadow: " 0px 0px 20px -3px rgba(255, 57, 81, 0.6)", background: "#F8F8F8", flexDirection: 'column', padding: { xs: "10px 0", md: "20px 0" }
            }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", width: "100%" }}>
                <Typography component={"img"} alt="icon" src={iconArrowRight} sx={{ width: { sm: "18px", md: "23px" }, height: { sm: "22px", md: "30px" }, margin: "0 3px" }} />
                <Typography sx={{ fontWeight: "bold", fontSize: { sm: "14px", md: "18px" }, color: "#070707" }}>{t("category")}</Typography>
                <Typography component={"img"} alt="icon" src={iconArrowLeft} sx={{ width: { sm: "18px", md: "23px" }, height: { sm: "22px", md: "30px" }, margin: "0 3px" }} />
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", flexWrap: "wrap", width: "100%", margin: "10px 0", flexDirection: "row" }}>
                {
                  category.map((item, index) => (
                    <Grid key={index} sx={{
                      display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", borderBottom: category.length === index + 1 ? "unset" : "1px solid #CBCBCB"
                      , width: "80%", margin: "5px", cursor: 'pointer'
                    }}>
                      <NavLink to={"/Academy/" + item.text} target="_blank" style={{ textDecoration: "none", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <Typography sx={{ color: "#555555", textAlign: "center", fontSize: { sm: "10px", md: "12px" }, fontWeight: "700" }}>{t(item.text)}</Typography>
                      </NavLink>
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{
            display: sideMenuOpen ? 'flex' : "none", alignItems: "center", justifyContent: 'flex-start', flexDirection: "column", width: "100vw", height: "100vh", position: "fixed", top: "0", zIndex: "800", backgroundSize: "100% 100%",
            backgroundImage: `url(${backSideMenu})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
          }}>
            <Grid sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: "85%", marginTop: "30px" }}>
              <CloseRoundedIcon onClick={closeModal} sx={{ fontSize: "35px", fontWeight: '700', cursor: "pointer" }} />
            </Grid>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: 'center', flexDirection: 'column' }}>
              <Grid onClick={quickAccessHandeler} sx={{ display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column', margin: "10px 0 0 0" }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '700', color: "#070707" }}>{t("quickAccess")}</Typography>
                <Typography alt="icon" component={"img"} src={iconArrowTop} sx={{ width: '18px', height: "15px", transform: openQuickAccess && "rotate(180deg)", margin: "5px 0" }} />
              </Grid>
              <Grid key={"li"} sx={{ display: openQuickAccess ? "flex" : "none", alignItems: "center", justifyContent: 'center', flexWrap: "wrap", width: "100%" }}>
                {
                  quickAccess.map((item, index) => (
                    <Grid key={index} sx={{
                      display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", borderBottom: quickAccess.length === index + 1 ? "unset" : "1px solid #CBCBCB"
                      , width: "100%", margin: "5px"
                    }}>
                      <NavLink to={item.link} target="_blank" style={{ textDecoration: "none" }}>
                        <Typography sx={{ color: "#555555", textAlign: "center", fontSize: "12px" }}>{t(item.text)}</Typography>
                      </NavLink>
                    </Grid>
                  ))
                }
              </Grid>
              <Grid onClick={categoryHandeler} sx={{ display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column', margin: "10px 0 0 0" }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '700', color: "#070707" }}>{t("category")}</Typography>
                <Typography alt="icon" component={"img"} src={iconArrowTop} sx={{ width: '18px', height: "15px", transform: openCategory && "rotate(180deg)", margin: "5px 0" }} />
              </Grid>
              <Grid sx={{ display: openCategory ? "flex" : "none", alignItems: "center", justifyContent: 'center', flexWrap: "wrap", width: "100%" }}>
                {
                  category.map((item, index) => (
                    <Grid key={index} onClick={() => setSideMenuOpen(false)} sx={{
                      display: "flex", alignItems: "center", justifyContent: "center", padding: "5px", borderBottom: category.length === index + 1 ? "unset" : "1px solid #CBCBCB"
                      , width: "100%", margin: "5px"
                    }}>
                      <NavLink to={"/Academy/" + item.text} target="_blank" style={{ textDecoration: "none", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <Typography sx={{ color: "#555555", textAlign: "center", fontSize: "12px" }}>{t(item.text)}</Typography>
                      </NavLink>
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid key={"lim"} item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: 'center', sm: "flex-end" }, flexDirection: "column", width: { xs: "100%", sm: "60%", md: "70%" } }}>
            {
              articleData === null ?
                <Loader />
                :
                articleData.length === 0 ?
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707" }}>{t("notAricle")}</Typography>
                  :
                  articleData !== null && articleData.length > 0 &&
                  <Grid key={"data"} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: "center", sm: "space-between", md: "center" }, width: "100%", flexDirection: "row", flexWrap: "wrap", margin: "20px 0" }}>
                    {
                      articlePage !== null ?
                        articlePage.map((item, index) => (
                          index === 0 ?
                            <Grid key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: "100%" }} >
                              {headPhoto(item.image)}
                              <Grid sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', width: { xs: "85%", sm: "98%" }, margin: "10px 0" }}>
                                <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "24px" }, fontWeight: "700", margin: "5px 0" }}>{t(item.h1)}</Typography>
                                <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px" }, color: "#070707", margin: "5px 0" }}> {t(item.desc)}</Typography>
                                <Grid sx={{ display: "flex", justifyContent: "center", margin: "5px 0", alignItems: "center", flexDirection: "row" }} >
                                  <NavLink className="navlink" target="_blank" to={`/Academy${item.link}`} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                                    <Typography component={"p"} sx={{
                                      display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row", color: "#FF3951",
                                      fontSize: { xs: "8px", sm: "12px", md: "14px" }, filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.31))"
                                    }}>
                                      {t("readMore")} <ArrowBackIcon sx={{ fontSize: { xs: "10px", sm: "18px" }, transform: i18n.language === "en" ? "rotateZ(180deg)" : "null", filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.31))" }} />
                                    </Typography>
                                  </NavLink>
                                </Grid>
                              </Grid>
                            </Grid>
                            :
                            <Grid key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: { xs: "90%", sm: "50%", md: "30%", lg: "32%" } }}>
                              <Grid sx={{
                                backgroundSize: "100% 100%", backgroundImage: `url(${frame})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", flexDirection: "column", padding: "10px", margin: "10px 0",
                                width: { xs: "290px", sm: "100%", md: "100%", lg: "362px" }, height: { xs: "237px", sm: "288px", md: "360px", lg: "456px" }, display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'
                              }}>
                                <Typography alt="img" component={"img"} src={item.image && item.image} sx={{ width: { xs: "191px", sm: "90%", md: "93%", lg: "83%" }, height: { xs: "125px", sm: "130px", md: "150px", lg: "200px" }, borderRadius: "8px 100px 8px 8px" }} />
                                <Typography sx={{ fontSize: { xs: '15px', sm: "12px", md: "18px", lg: "24px" }, fontWeight: "700", color: "#000000", textAlign: "center", width: "90%" }}>{t(item.h1)}</Typography>
                                <Typography sx={{ fontSize: { xs: '11px', sm: "10px", md: "14px" }, color: "#000000", textAlign: "center" }}>{t(item.desc)}</Typography>
                                <NavLink className="navlink" target="_blank" to={`/Academy${item.link}`} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                                  <Typography component={"p"} sx={{
                                    display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row", color: "#FF3951",
                                    fontSize: { xs: "8px", sm: "12px", md: "14px" }, filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.31))"
                                  }}>
                                    {t("readMore")} <ArrowBackIcon sx={{ fontSize: { xs: "10px", sm: "18px" }, transform: i18n.language === "en" ? "rotateZ(180deg)" : "null", filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.31))" }} />
                                  </Typography>
                                </NavLink>
                              </Grid>
                            </Grid>
                        ))
                        :
                        <Loader />
                    }
                  </Grid>
            }
            <Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "center", width: "100%", margin: "20px 0" }}>
          {
            articleData !== null && articleData.length > 10 &&
            <Stack sx={{ direction: "ltr" }}>
              <Pagination
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "#000000", border: "none", borderRadius: "10px", backgroundColor: "#ffffff", width: "10px !important",
                    boxShadow: " 0px 0px 4px 3px rgba(255, 57, 81, 0.23)"
                  }, "& .Mui-selected": { background: "#ffffff !important", color: "#FF3951 !important" },
                  "& .MuiPaginationItem-icon": { color: "#FF3951", fontSize: { xs: "20px", md: "30px" }, boxShadow: "none" },
                  "& .MuiPaginationItem-previousNext": { background: "transparent", width: "10px !important", color: "#FF3951", boxShadow: "none" },
                }}
                count={Math.ceil(articleData.length / 10)}
                variant="outlined"
                shape="rounded"
                onChange={onChangePagination}
              />
            </Stack>
          }
        </Grid>
      </Grid>
      <PortfolioButton />
      <Footer />
      <ModalLogin open={open} close={handleCloseModal} data={type} />
    </>
  )
}

export default ArticlesPage;