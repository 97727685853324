import React, { useState, useEffect } from "react";
import { Grid, Typography, Menu, Button } from "@mui/material";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import '../styles/navLink.css';
import '../styles/Nav.css';
import login from '../image/Nav/login.png';
import profile from '../image/Nav/profile.png';
import logo from '../image/logo/logo.png';
import profileDark from '../image/Nav/profileDark.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import logout from '../image/Nav/logout.png';
import user from '../image/Nav/user.png';
import ModalLogin from "./ModalLogin";
import LanguageIcon from '@mui/icons-material/Language';
import navBack from '../image/Nav/navBack.png'
import PersonIcon from "../image/Nav/PersonIcon";
import ContactIcon from "../image/Nav/ContactIcon";
import MagIcon from '../image/Nav/MagIcon';
import SiteIcon from "../image/Nav/SiteIcon";
import ModalLogout from "./ModalLogout";
import { dataUser, dataSupporter } from "./DataPanel";
import HandshakeIcon from '@mui/icons-material/Handshake';
import profileSorin from '../image/Nav/profileSorin.png'
// import { create } from "./modules/createXML";
// import { route } from "./route";

let info = null;
const Nav = ({ auth }) => {
  const [authCheck, setAuthCheck] = useState(auth)
  const [mobileMenu, setMobileMenu] = useState(false);
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [showMenu, setShowMenu] = React.useState('none');
  const [type, setType] = useState("");
  const [select, setSelect] = useState(null);
  const [showDashboard, setShowDashboard] = useState('none');
  const [dashboardMobile, setDashboardMobile] = useState(false);
  const [mobileSelect, setMobileSelect] = useState("");
  const [openLang, setOpenLang] = useState(0);
  const [openModalExit, setOpenModalExit] = useState(false);
  const [selectNav, setSelectNav] = useState("Landing");
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);

  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    // console.log(create(route))
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    scrollPosition > 60 && setShowDashboard("none")
  }, [scrollPosition])


  const Navigate = useNavigate();
  const location = useLocation();

  let pathNav = location.pathname.split('/');

  useEffect(() => {
    setAuthCheck(auth)
  }, [auth]);

  useEffect(() => {
    let path = location.pathname.split('/');
    if (path[1] === "SorinBoard") {
      setSelect(path[2])
    }
  }, [location.pathname])

  useEffect(() => {
    if ((pathNav[1] === "") || (pathNav[1] === "JobOffer") || (pathNav[1] === "Academy") || (pathNav[1] === "ContactSorin") || (pathNav[1] === "AboutSorin")) {
      setMobileSelect(pathNav[1])
      setSelectNav(pathNav[1] === "" ? "Landing" : pathNav[1])
    }
    else {
      setMobileSelect("")
      setSelectNav("")
    }
  }, [pathNav])

  const handleOpenModalExit = () => setOpenModalExit(true);
  const handleCloseModalExit = () => setOpenModalExit(false);

  const selectHandeler = (value) => {
    Navigate("/SorinBoard/" + value)
    setSelect(value);
  }

  const openMenu = Boolean(anchorEl);

  const langHanlder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    window.addEventListener('resize', () => resizeMenu());
    return (() => window.removeEventListener('resize', () => resizeMenu()));
  }, [window.innerWidth]);

  function resizeMenu() {
    if (window.innerWidth > 900 && showMenu === 'flex') {
      setShowMenu('none')
      setMobileMenu(false)
    }
  }

  const clickMobileMenu = () => {
    setMobileMenu(!mobileMenu);
    setShowMenu(!mobileMenu ? 'flex' : 'none')
    setOpenLang(0)
  };
  const onChangeLanguage = (lang) => {
    if (lang === "en") {
      i18n.changeLanguage("en");
      document.documentElement.setAttribute('lang', 'en');
    } else {
      i18n.changeLanguage("fa");
      document.documentElement.setAttribute('lang', 'fa');
    }
    handleClose();
  };

  const loginHandler = () => {
    setType("L");
    setOpen(true);
  }

  const handleCloseModal = () => {
    setOpen(false);
  }

  const loginMobileHandler = () => {
    clickMobileMenu();
    setType("L");
    setOpen(true);
  }

  const registerHandler = () => {
    setType("R");
    setOpen(true);
  }

  const dashboardHandeler = () => {
    setDashboardMobile(!dashboardMobile)
    setShowDashboard(!dashboardMobile ? "flex" : "none")
  }

  const mobileSelectHandeler = (value) => {
    setMobileSelect(value)
    clickMobileMenu();
  }

  const openLangHandeler = () => {
    if (openLang === 0) {
      setOpenLang(1)
    }
    else {
      setOpenLang(0)
    }
  }

  const clickOtherLocation = (e) => {
    if (e.target.id === 'mobile_menu') {
      setMobileMenu(!mobileMenu);
      setShowMenu(!mobileMenu ? 'flex' : 'none')
      setOpenLang(0)
    }
  }

  const panelFun = () => {
    let list = [];
    let dataPanel = [];
    info = JSON.parse(localStorage.getItem('sorinInfoUser'));
    if (info) {
      if ((info.user_type_id === 1) || (info.user_type_id === 2)) {
        dataPanel = dataUser
      }
      else if ((info.user_type_id === 3)) {
        dataPanel = dataSupporter
      }
    }

    if (dataPanel.length > 0) {
      dataPanel.forEach((item) => {
        list.push(
          item.text === "logout" ?
            <Grid key={item.id} className="logoutHover" onClick={handleOpenModalExit} sx={{ display: { xs: 'flex', md: "none" }, flexDirection: "row", alignItems: "flex-start", width: "100%", margin: "5% 0 2% 0", padding: "3% 15px", cursor: 'pointer' }}>
              <item.icon color={"#000000"} />
              <Typography sx={{ fontSize: "14px", color: "#070707", fontWeight: "700", lineHeight: "28px", padding: "0 5px" }} >{t(item.text)}</Typography>
            </Grid>
            :
            <Grid key={item.id} onClick={() => selectHandeler(item.text)} sx={{
              display: 'flex', flexDirection: "row", alignItems: "flex-start", width: "100%", margin: "5% 0 2% 0", padding: "3% 10px",
              background: select === item.text ? "#FFEDEF" : "unset", borderLeft: i18n.language === "fa" ? select === item.text ? "4px solid #FF3951" : "unset" : "unset", cursor: 'pointer'
              , borderRadius: select === item.text ? i18n.language === "fa" ? "0px 16px 16px 0px" : "16px 0px 0px 16px" : "unset", borderRight: i18n.language === "en" ? select === item.text ? "4px solid #FF3951" : "unset" : "unset"
            }}>
              <item.icon color={select === item.text ? "#FF3951" : "#000000"} />
              <Typography sx={{ fontSize: "14px", color: select === item.text ? "#FF3951" : "#070707", fontWeight: { xs: "400", sm: "700" }, lineHeight: "28px", padding: "0 5px" }} >{t(item.text)} </Typography>
            </Grid>
        )
      })
      return list;
    }
  }
  return (
    <>
      <Grid sx={{
        display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center", direction: i18n.language === "fa" ? "rtl" : "ltr"
        , backgroundColor: "#FFF8F8", width: "100%", zIndex: "200", height: { xs: "50px", sm: "95px", lg: "107px" }
      }}>
        <Grid sx={{ display: { xs: "flex", md: "none" }, flexDirection: "row", justifyContent: "space-between", alignItems: 'center', width: { xs: "95%", sm: "90%" } }}>
          <Grid className="hamburgerMenu" sx={{ display: 'flex', justifyContent: "flex-start", alignItems: 'center', width: "20%", zIndex: "700" }}>
            <Grid className={mobileMenu ? "hamburger_Button active" : "hamburger_Button not-active"}
              sx={{ width: mobileMenu ? { sm: "30px", xs: "20px" } : "30px", position: mobileMenu ? "fixed" : "null", top: mobileMenu === true ? "15px" : "null" }} onClick={clickMobileMenu}>
              <Grid className="hamburger_span" sx={{ background: mobileMenu ? "#070707 !important" : "" }}></Grid>
              <Grid className="hamburger_span" sx={{ background: mobileMenu ? "#070707 !important" : "" }}></Grid>
              <Grid className="hamburger_span" sx={{ background: mobileMenu ? "#070707 !important" : "" }}></Grid>
            </Grid>
          </Grid>
          <Grid sx={{ display: mobileMenu === false ? "flex" : "none", alignItems: 'center', justifyContent: 'center', margin: '0 auto', width: "60%" }}>
            <NavLink className="navlink" to="/" style={{ display: mobileMenu === false ? "flex" : "none", alignItems: 'center', justifyContent: 'center', margin: '0 auto', width: "100%" }}>
              <Typography component={"img"} alt="وب گستران سورین" src={logo} sx={{ width: { xs: "47px", sm: "75px", md: "70px", lg: "110px" }, height: { xs: "38px", sm: "52px", md: "49px", lg: "70px" } }} />
            </NavLink>
          </Grid>
          <Grid sx={{ display: mobileMenu === false ? "flex" : "none", justifyContent: 'flex-end', width: "20%" }}>
            <Button sx={{ display: "flex", justifyContent: 'flex-end' }} onClick={authCheck ? dashboardHandeler : loginHandler}>
              <Typography component={'img'} alt="login" src={profileSorin} sx={{ width: { xs: "18px", sm: "30px" }, height: { xs: "18px", sm: "30px" } }} />
            </Button>
          </Grid>
        </Grid>
        <Grid sx={{ display: { xs: "none", md: "flex" }, flexDirection: 'row', justifyContent: "space-between", alignItems: "center", width: { md: "95%", lg: "85%" } }}>
          <Grid sx={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center", width: { md: "70%", lg: "70%" } }}>
            <NavLink className="navlink" to="" >
              <Typography component={"img"} alt="شرکت سورین" src={logo} sx={{ width: { md: "70px", lg: "110px" }, height: { md: "49px", lg: "70px" } }} />
            </NavLink>
            <NavLink className="navlink" to="/">
              <Typography onClick={() => setSelectNav("Landing")} component={"p"} sx={{ color: selectNav === "Landing" ? "#FF3951" : "#000000", fontSize: i18n.language === "fa" ? { md: "16px", lg: "18px", xl: "19px" } : { md: "16px", lg: "18px" }, fontWeight: "700" }}>{t("mainPage")}</Typography>
            </NavLink>
            <NavLink className="navlink" to="/Academy">
              <Typography onClick={() => setSelectNav("Academy")} component={"p"} sx={{ color: selectNav === "Academy" ? "#FF3951" : "#000000", fontSize: i18n.language === "fa" ? { md: "16px", lg: "18px", xl: "19px" } : { md: "16px", lg: "18px" }, fontWeight: "700" }}>{t("Academy")}</Typography>
            </NavLink>
            <NavLink className="navlink" to='AboutSorin'>
              <Typography onClick={() => setSelectNav("AboutSorin")} component={"p"} sx={{ color: selectNav === "AboutSorin" ? "#FF3951" : "#000000", fontSize: i18n.language === "fa" ? { md: "16px", lg: "18px", xl: "19px" } : { md: "16px", lg: "18px" }, fontWeight: "700" }}>{t("aboutUs")}</Typography>
            </NavLink>
            <NavLink className="navlink" to="/ContactSorin">
              <Typography onClick={() => setSelectNav("ContactSorin")} component={"p"} sx={{ color: selectNav === "ContactSorin" ? "#FF3951" : "#000000", fontSize: i18n.language === "fa" ? { md: "16px", lg: "18px", xl: "19px" } : { md: "16px", lg: "18px" }, fontWeight: "700" }}>{t("contact")}</Typography>
            </NavLink>
            <NavLink className="navlink" to="/JobOffer">
              <Typography onClick={() => setSelectNav("JobOffer")} component={"p"} sx={{ color: selectNav === "JobOffer" ? "#FF3951" : "#000000", fontSize: i18n.language === "fa" ? { md: "16px", lg: "18px", xl: "19px" } : { md: "16px", lg: "18px" }, fontWeight: "700" }}>{t("JobOffer")}</Typography>
            </NavLink>
            <Grid sx={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', cursor: "pointer" }} onClick={langHanlder}>
              <LanguageIcon sx={{ fontSize: "30px" }} />
            </Grid>
          </Grid>
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            sx={{ ".MuiMenu-paper": { bgcolor: "white", borderRadius: "5px" }, position: "absolute", left: "-40px" }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Typography onClick={() => onChangeLanguage('fa')}
              sx={{
                padding: "5px 25px", margin: "5px", textAlign: "center", fontWeight: 'bold', backgroundColor: i18n.language === "fa" ? "#FF7283" : "white",
                color: i18n.language === "fa" ? "white" : "black", borderRadius: "15px", cursor: "pointer", fontFamily: "yekan !important", fontSize: "18px"
              }}>
              فارسی
            </Typography>
            <Typography onClick={() => onChangeLanguage('en')}
              sx={{
                padding: "5px 25px", margin: "5px", textAlign: "center", fontWeight: 'bold', backgroundColor: i18n.language === "en" ? "#FF7283" : "white",
                color: i18n.language === "en" ? "white" : "black", borderRadius: "15px", cursor: "pointer", fontFamily: "crimson !important", fontSize: "18px"
              }}>
              English
            </Typography>
          </Menu>
          <Grid sx={{ display: auth === false ? "flex" : "none", flexDirection: 'row', justifyContent: "flex-end", width: { md: "30%", lg: "30%" } }}>
            <Grid onClick={loginHandler} sx={{ border: "2.5px solid #FF3951", borderRadius: "30px", width: "110px", display: 'flex', height: "50px", position: "relative", 
            left: i18n.language === "fa" ? "-48px" : "38px", justifyContent: 'flex-start', alignItems: "center" ,cursor:"pointer"}}>
              <Button sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "0px 2px", "&:hover": { backgroundColor: "transparent" } }} >
                <Typography component={"img"} alt="login" src={login} />
                <Typography component={"p"} sx={{ color: "#FF3951", fontSize: "14px" }}>{t("login")}</Typography>
              </Button>
            </Grid>
            <Grid sx={{
              backgroundColor: "#FF3951", borderRadius: "30px", width: "140px", zIndex: "100"
              , display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', height: "50px", boxShadow: i18n.language === "fa" ? "2px 2px 2px grey" : "0px 2px 2px 2px grey"
            }}>
              <Button sx={{ display: "flex", flexDirection: 'row', justifyContent: "center", alignItems: 'center', "&:hover": { backgroundColor: "transparent" } }} onClick={registerHandler} >
                <Typography component={"img"} alt="profile" src={profile} />
                <Typography component={"p"} sx={{ color: "white", fontSize: "14px" }} >{t("register")}</Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid sx={{ display: auth ? "flex" : "none", flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
            <Grid onClick={dashboardHandeler} sx={{ display: "flex", alignItems: "center", justifyContent: 'center', margin: i18n.language === "fa" ? "0 0 0 30px" : "0 30px 0 0"  ,cursor:"pointer"}}>
              <Typography component={"img"} alt="user" src={user} sx={{ width: "55px", height: "55px" }} />
            </Grid>
            <Grid onClick={handleOpenModalExit} sx={{ display: "flex", alignItems: "center", justifyContent: 'center' ,cursor:"pointer" }}>
              <Typography component={"img"} alt="logout" src={logout} sx={{ width: "43px", height: "43px" }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid lang={i18n.language} onClick={(e) => clickOtherLocation(e)} id="mobile_menu" sx={{
        width: "100vw", height: "100vh", transition: "0.4s all ease", overflowX: "hidden", backgroundColor: 'rgba(105,99,99,0.6)',
        direction: i18n.language === "fa" ? "rtl" : "ltr", display: showMenu, flexDirection: "row"
      }}>
        <Grid sx={{
          width: { xs: '80%', sm: '50%' }, height: "100vh", display: 'flex', flexDirection: "column", overflow: "scroll",
          borderRadius: i18n.language === "fa" ? "16px 0px 0px 16px" : "0px 16px 16px 0", background: `url(${navBack})`, backgroundPosition: "center", backgroundSize: "110% 110%",
          backgroundRepeat: "no-repeat",
          boxShadow: "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;"
        }}>
          <Grid sx={{ paddingTop: "20px", display: "flex", justifyContent: 'center', alignItems: 'center' }}>
            <NavLink className="navlink" to="/" style={{ display: "flex", justifyContent: authCheck ? "center" : 'flex-end', alignItems: 'center', width: "100%", padding: authCheck ? "10px 20px" : "0 20px" }}>
              <Typography component={"img"} alt="سورین" src={logo} sx={{ width: { xs: authCheck ? "60px" : "45px", sm: authCheck ? "79px" : '69px' }, height: { xs: authCheck ? "43px" : "35px", sm: authCheck ? "59px" : "54px" } }} />
            </NavLink>
          </Grid>
          <Grid sx={{ display: auth ? "none" : 'flex', alignItems: "center", justifyContent: 'center', margin: '10px 0' }}>
            <Button onClick={loginMobileHandler} sx={{ backgroundColor: "#FF3951", borderRadius: "26px", minWidth: { xs: "80px !important", sm: "139px" }, minHeight: { xs: "35px !important", sm: '47px' }, boxShadow: "2px 3px 2px silver", "&:hover": { backgroundColor: "#FF3951" } }}>
              <Typography sx={{ color: "white", fontSize: { xs: "10px", sm: "14px" } }}>{t("login")}/{t("register")}</Typography>
            </Button>
          </Grid>
          <Grid sx={{ background: "#CBCBCB", height: "1px", width: "80%", margin: "10px auto" }}></Grid>
          <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center", marginTop: { sm: "20px" }, width: "100%" }}>
            <Grid sx={{ display: "flex", justifyContent: "center", width: "80%", height: "40px", borderRadius: "10px", marginBottom: { xs: "10px", sm: "10%" }, background: mobileSelect === "Landing" ? "linear-gradient(270deg, #F9F5FF 47.7%, #FFBCC4 103.55%)" : "none" }}>
              <Grid onClick={() => mobileSelectHandeler("Landing")}
                sx={{
                  display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", minWidth: { xs: i18n.language === "fa" ? "170px" : "200px", sm: i18n.language === "fa" ? "200px" : "250px" },
                  maxWidth: { xs: i18n.language === "fa" ? "170px" : "200px", sm: i18n.language === "fa" ? "200px" : "250px" }
                }}>
                <NavLink className="navlink" to="/"
                  style={{
                    display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", background: "transparent",
                    padding: "0 20%", width: "100%"
                  }}>
                  <SiteIcon color={mobileSelect === "Landing" ? "#FF3951" : "#070707"} />
                  <Typography sx={{ color: mobileSelect === "Landing" ? "#FF3951" : "#070707", fontSize: { xs: "12px", sm: "18px" }, fontWeight: mobileSelect === "Landing" ? "700" : "400", lineHeight: "150%", padding: "0 5px" }}>{t("mainPage")}</Typography>
                </NavLink>
              </Grid>
            </Grid>
            <Grid sx={{
              display: "flex", justifyContent: "center", width: "80%", height: "40px", borderRadius: "10px", marginBottom: { xs: "10px", sm: "10%" },
              background: mobileSelect === "Academy" ? "linear-gradient(270deg, #F9F5FF 47.7%, #FFBCC4 103.55%)" : "none"
            }}>
              <Grid onClick={() => mobileSelectHandeler("Academy")}
                sx={{
                  display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row",
                  minWidth: { xs: i18n.language === "fa" ? "170px" : "200px", sm: i18n.language === "fa" ? "200px" : "250px" }, maxWidth: { xs: i18n.language === "fa" ? "170px" : "200px", sm: i18n.language === "fa" ? "200px" : "250px" }
                }}>
                <NavLink className="navlink" to="/Academy"
                  style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", padding: "0 20%", width: "100%" }}>
                  <MagIcon color={mobileSelect === "Academy" ? "#FF3951" : "#070707"} />
                  <Typography sx={{ color: mobileSelect === "Academy" ? "#FF3951" : "#070707", fontSize: { xs: "12px", sm: "16px" }, fontWeight: mobileSelect === "Academy" ? "700" : "400", lineHeight: "150%", padding: "0 5px" }}>{t("sorinMag")}</Typography>
                </NavLink>
              </Grid>
            </Grid>
            <Grid sx={{
              display: "flex", justifyContent: "center", width: "80%", height: "40px", borderRadius: "10px", marginBottom: { xs: "10px", sm: "10%" },
              background: mobileSelect === "ContactSorin" ? "linear-gradient(270deg, #F9F5FF 47.7%, #FFBCC4 103.55%)" : "none"
            }}>
              <Grid onClick={() => mobileSelectHandeler("ContactSorin")}
                sx={{
                  display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row",
                  minWidth: { xs: i18n.language === "fa" ? "170px" : "200px", sm: i18n.language === "fa" ? "200px" : "250px" }, maxWidth: { xs: i18n.language === "fa" ? "170px" : "200px", sm: i18n.language === "fa" ? "200px" : "250px" }
                }}>
                <NavLink className="navlink" to="/ContactSorin"
                  style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", padding: "0 20%", width: "100%" }}>
                  <ContactIcon color={mobileSelect === "ContactSorin" ? "#FF3951" : "#070707"} />
                  <Typography sx={{ color: mobileSelect === "ContactSorin" ? "#FF3951" : "#070707", fontSize: { xs: "12px", sm: "18px" }, fontWeight: mobileSelect === "ContactSorin" ? "700" : "400", lineHeight: "150%", padding: "0 5px" }}>{t("contact")}</Typography>
                </NavLink >
              </Grid>
            </Grid>
            <Grid sx={{
              display: "flex", justifyContent: "center", width: "80%", height: "40px", borderRadius: "10px", marginBottom: { xs: "10px", sm: "10%" },
              background: mobileSelect === "AboutSorin" ? "linear-gradient(270deg, #F9F5FF 47.7%, #FFBCC4 103.55%)" : "none"
            }}>
              <Grid onClick={() => mobileSelectHandeler("AboutSorin")}
                sx={{
                  display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row",
                  minWidth: { xs: i18n.language === "fa" ? "170px" : "200px", sm: i18n.language === "fa" ? "200px" : "250px" }, maxWidth: { xs: i18n.language === "fa" ? "170px" : "200px", sm: i18n.language === "fa" ? "200px" : "250px" }
                }}>
                <NavLink className="navlink" to="/AboutSorin"
                  style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", padding: "0px 20%", width: "100%" }}>
                  <PersonIcon color={mobileSelect === "AboutSorin" ? "#FF3951" : "#070707"} />
                  <Typography sx={{ color: mobileSelect === "AboutSorin" ? "#FF3951" : "#070707", fontSize: { xs: "12px", sm: "18px" }, fontWeight: mobileSelect === "AboutSorin" ? "700" : "400", lineHeight: "150%", padding: "0 5px" }}>{t("aboutUs")}</Typography>
                </NavLink>
              </Grid>
            </Grid>
            <Grid sx={{
              display: "flex", justifyContent: "center", width: "80%", height: "40px", borderRadius: "10px", marginBottom: { xs: "10px", sm: "10%" },
              background: mobileSelect === "JobOffer" ? "linear-gradient(270deg, #F9F5FF 47.7%, #FFBCC4 103.55%)" : "none"
            }}>
              <Grid onClick={() => mobileSelectHandeler("JobOffer")}
                sx={{
                  display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row",
                  minWidth: { xs: i18n.language === "fa" ? "170px" : "200px", sm: i18n.language === "fa" ? "200px" : "250px" }, maxWidth: { xs: i18n.language === "fa" ? "170px" : "200px", sm: i18n.language === "fa" ? "200px" : "250px" }
                }}>
                <NavLink className="navlink" to="/JobOffer"
                  style={{
                    display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row",
                    padding: i18n.language === "fa" ? "0 20% 0 0" : "0 0 0 20%", width: "100%"
                  }}>
                  <HandshakeIcon sx={{ color: mobileSelect === "JobOffer" ? "#FF3951" : "#070707" }} />
                  <Typography sx={{ color: mobileSelect === "JobOffer" ? "#FF3951" : "#070707", fontSize: { xs: "12px", sm: "18px" }, fontWeight: mobileSelect === "SEOConsulting" ? "700" : "400", lineHeight: "150%", padding: i18n.language === "fa" ? "0 5px" : "0", width: "90%" }}>{t("JobOffer")}</Typography>
                </NavLink>
              </Grid>
            </Grid>
            <Grid onClick={openLangHandeler} sx={{
              display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", height: "40px",
              minWidth: { xs: i18n.language === "fa" ? "170px" : "200px", sm: i18n.language === "fa" ? "200px" : "250px" }, maxWidth: { xs: i18n.language === "fa" ? "170px" : "200px", sm: i18n.language === "fa" ? "200px" : "250px" }
            }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", padding: "0px 20%", width: "100%" }}>
                <LanguageIcon sx={{ fontSize: { xs: "18px", sm: "18px" } }} />
                <Typography sx={{ fontSize: { xs: "12px", sm: "18px" }, lineHeight: "150%", padding: "0 5px" }}>{t("language")}</Typography>
              </Grid>
            </Grid>
            <Grid sx={{ display: openLang === 1 ? "flex" : "none", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", width: "80px" }}>
              <Typography onClick={() => onChangeLanguage('fa')} sx={{ padding: "10px", color: i18n.language === "fa" ? "#FF3951" : "black", fontSize: { xs: "12px", sm: "16px" }, fontFamily: "yekan !important" }}>فارسی</Typography>
              <Typography onClick={() => onChangeLanguage('en')} sx={{ padding: "0px 10px", color: i18n.language === "en" ? "#FF3951" : "black", fontSize: { xs: "12px", sm: "16px" }, fontFamily: "crimson!important" }}>English</Typography>
            </Grid>
          </Grid>
          <Grid sx={{
            display: "flex", alignItems: "center", justifyContent: "center", height: "100%", flexDirection: "column"
          }}>
            <Grid sx={{
              display: "flex", alignItems: "center", justifyContent: "flex-end", flexDirection: "column", padding: "0 15px"
            }}>
              <Typography sx={{ textAlign: 'center', fontSize: { xs: "14px", sm: i18n.language === "fa" ? "20px" : "24px" }, fontWeight: "700", padding: "5px 0", lineHeight: "28px" }}>{t("slogan")}</Typography>
              <Typography sx={{ fontSize: { xs: i18n.language === "fa" ? "10px" : "12px", sm: i18n.language === "fa" ? "10px" : "14px" }, fontWeight: "600" }}>{t("connectUs")}</Typography>
              <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-evenly', alignItems: "center", width: "100%", padding: "10px 0" }}>
                <NavLink className="navlink" style={{ display: "flex", padding: "5px" }}>
                  <InstagramIcon sx={{ color: "#070707", fontSize: "20px" }} />
                </NavLink>
                <NavLink className="navlink" style={{ display: "flex", padding: "5px" }}>
                  <TwitterIcon sx={{ color: "#070707", fontSize: "20px" }} />
                </NavLink>
                <NavLink className="navlink" style={{ display: "flex", padding: "5px" }}>
                  <WhatsAppIcon sx={{ color: "#070707", fontSize: "20px" }} />
                </NavLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid onClick={dashboardHandeler} lang={i18n.language} id="mobile_menu" sx={{
        width: "100vw", height: "100vh", transition: "0.4s all ease", overflowX: "hidden", backgroundColor: 'rgba(105,99,99,0.6)',
        direction: i18n.language === "en" ? "rtl" : "ltr", display: { xs: showDashboard, sm: "none" }, flexDirection: "row", zIndex: "800 !important"
      }}>
        <Grid sx={{
          display: 'flex', flexDirection: "column", width: "60%", minWidth: { md: "220px", lg: "268px" }, overflow: "scroll",
          height: "100%", background: "#FFFFFF", boxShadow: "0px 0px 6px rgba(255, 57, 81, 0.45)", borderRadius: i18n.language === "fa" ? "0px 16px 16px 0px" : "16px 0px 0px 16px"
        }}>
          {panelFun()}
        </Grid>
      </Grid>
      <Grid onClick={dashboardHandeler} lang={i18n.language} sx={{
        width: "100vw", height: "100vh", transition: "0.4s all ease", overflowX: "hidden", backgroundColor: 'rgba(0,0,0,0.0)', position: "fixed", top: "0",
        direction: i18n.language === "en" ? "rtl" : "ltr", display: { xs: "none", sm: showDashboard }, flexDirection: "row", zIndex: "800 !important"
      }}>
        <Grid sx={{
          position: "fixed", top: { xs: "80px", md: "90px" }, left: i18n.language === "fa" ? { sm: "10px", md: "10px" } : "unset", right: i18n.language === "en" ? "10px" : "unset",
          display: "flex", flexDirection: "column", width: { sm: "30%", md: "25%" }, maxWidth: { md: "220px" }, zIndex: "900 !important",
          background: "#FFFFFF", boxShadow: "0px 0px 6px rgba(255, 57, 81, 0.45)", borderRadius: "16px", direction: i18n.language === "fa" ? "rtl" : "ltr"
        }}>
          {panelFun()}
        </Grid>
      </Grid>
      <ModalLogin open={open} close={handleCloseModal} data={type} />
      <ModalLogout open={openModalExit} close={handleCloseModalExit} />
    </>
  );
}

export default Nav;