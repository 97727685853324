export const dataJob = [
  // {
  //   id: 1,
  //   title: "programming",
  //   desc: "لورم اپیسوم متن ساختگی",
  //   time: "تمام وقت",
  //   address: "تهران",
  //   Requirements: ["لورم اپیسوم متن ساختگی", "لورم اپیسوم متن ساختگی", "لورم اپیسوم متن ساختگی"],
  //   TechnicalKnowledge: ["لورم اپیسوم متن ساختگی", "لورم اپیسوم متن ساختگی", "لورم اپیسوم متن ساختگی"]
  // },
  
]