import { Grid, TextField, Typography, FormControl, Select, MenuItem, Button, IconButton, Input, CircularProgress, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useTranslation } from "react-i18next";
import { separator, onlyFloatnaumber } from "./modules/general";
import { baseUrl } from "./modules/general";
import axios from "axios";
import AlertMessage from "./modules/AlertMessage";
import { setCanonicalTag } from "./modules/general";
import Loader from "./Loader";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const OrderProgramming = () => {
  const [t, i18n] = useTranslation();
  const [textFieldSize, setTextFiledSize] = useState(window.innerWidth <= 900 ? "small" : "medium");
  const [minimum_budget, setMinimum_budget] = useState("");
  const [maximum_budget, setMaximum_budget] = useState("")
  const [dataActivity, setDataActivity] = useState(null);
  const [activity, setActivity] = useState("")
  const [description, setDescription] = useState("");
  const [alert, setAlert] = useState({ alertCheck: false, message: "", severity: "" });
  const [btnSubmit, setBtnSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileDocumation, setFileDocumation] = useState(null);
  const [nameFile, setNameFile] = useState(null);
  const [fileSize, setFileSize] = useState();
  const [info, setInfo] = useState(null);

  useEffect(() => {
    document.title = "سفارش برنامه نویسی | سفارش کدنویسی | سفارش پروژه برنامه نویسی دانشگاهی | وب گستران سورین";
    document.getElementById("main-heading").innerHTML = "سفارش برنامه نویسی | سفارش کدنویسی | سفارش پروژه برنامه نویسی دانشگاهی | وب گستران سورین";
    document.getElementsByTagName('meta')["description"].content = "شما در حال ثبت سفارش انجام پروژه برنامه نویسی دانشگاهی هستید. صفحه ثبت درخواست پروژه های نرم افزاری و کامپیوتری دانشگاه در بهترین سایت سفارش و انجام پروژه های برنامه نویسی در ایران";
    document.getElementsByTagName('meta')["keywords"].content = "سفارش برنامه نویسی پیشرفته | صفحه درخواست پروژه برنامه نویسی دانشگاه | سفارش انجام انواع پروژه برنامه نویسی کامپیوتر | بهترین شرکت انجام پروژه های برنامه نویسی از مبتدی تا پیشرفته";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    setInfo(JSON.parse(localStorage.getItem('sorinInfoUser')));
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => resizeTextFiled());
    return (() => window.removeEventListener('resize', () => resizeTextFiled()));
  }, [window.innerWidth]);

  useEffect(() => {
    getActivity();
  }, [])

  const getActivity = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/activity/get`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataActivity(res.data.data)
      })
  }

  const submitHandeler = () => {
    const formData = new FormData();
    if (activity === "") {
      setAlert({ alertCheck: true, message: t("alert_activity"), severity: "error" })
    }
    else if ((fileDocumation !== "") && (fileSize > 490000)) {
      setAlert({ alertCheck: true, message: t("Alert_fileLarge"), severity: "error" })
    }
    else if ((minimum_budget !== "") && (minimum_budget < 500000)) {
      setAlert({ alertCheck: true, message: t("alert_minimumBudget"), severity: "error" })
    }
    else if ((maximum_budget !== "") && (maximum_budget > 400000000000)) {
      setAlert({ alertCheck: true, message: t("alert_maximumBudget"), severity: "error" })
    }
    else if ((maximum_budget !== "") && (minimum_budget !== "") && (+minimum_budget > +maximum_budget)) {
      setAlert({ alertCheck: true, message: t("alert_minimumBigerMaximum"), severity: "error" })
    }
    else {
      if (fileDocumation !== null) { formData.append("documentation", fileDocumation) }
      if (activity !== "") { formData.append("activity_type_id", activity) }
      if (minimum_budget !== "") { formData.append("minimum_budget", minimum_budget) }
      if (maximum_budget !== "") { formData.append("maximum_budget", maximum_budget) }
      if (description !== "") { formData.append("description", description) }
      submitData(formData);
    }
  }

  const submitData = (data) => {
    setLoading(true)
    setBtnSubmit(true)
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/program/create',
      method: "POST",
      data: data,
      headers: { 'Authorization': AuthStr },
    }))
      .then(() => {
        setAlert({ alertCheck: true, message: t("alert_dataSuccess"), severity: "success" })
        setBtnSubmit(false);
        setLoading(false);
        setActivity(""); setFileDocumation(null); setMinimum_budget(""); setMaximum_budget(""); setDescription(""); setNameFile(null);
      })
      .catch(
        (error) => {
          const { status } = error.response;
          status === 500 ?
            setAlert({ alertCheck: true, message: t("AM_intervalServer"), severity: "error" }) :
            setAlert({ alertCheck: true, message: t("alert_dataFaild"), severity: "error" })
          setBtnSubmit(false)
          setLoading(false)
        }
      )
  }
  const onChangeLicence = (e) => {
    if (e.target.files[0]) {
      if ((e.target.files[0].type === "application/pdf") || (e.target.files[0].type === "application/msword") || (e.target.files[0].type === "application/zip")
        || (e.target.files[0].type === "application/x-zip-compressed")) {
        setFileDocumation(e.target.files[0]);
        setNameFile(e.target.files[0].name);
        setFileSize(e.target.files[0].size)
      }
      else {
        setAlert({ alertCheck: true, message: t("Alert_notFile"), severity: "error" })
      }
    }
  }

  const resizeTextFiled = () => {
    if (window.innerWidth < 900 && textFieldSize === 'medium') {
      setTextFiledSize('small')
    }
    else if (window.innerWidth > 900 && textFieldSize === 'small') {
      setTextFiledSize('medium')
    }
  }

  const minimum_budgetHandeler = (e) => {
    let value = onlyFloatnaumber(e.target.value);
    setMinimum_budget(value)
  }

  const maximum_budgetHandeler = (e) => {
    let value = onlyFloatnaumber(e.target.value);
    setMaximum_budget(value)
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };
  return (
    info === null ?
      <Loader />
      :
      info.active === 0 ?
        <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "18px" }, margin: "20px" }}>{t("compeleteInfo")}</Typography>
        :
        <>
          <Grid sx={{ display: 'flex', width: "100%", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: 'center', padding: { xs: "0", md: "100px 0" } }}>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", marginTop: { xs: "0", md: "4%" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("activity")}</Typography>
                  <Tooltip title={t("tooltipActivityProgram")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <FormControl sx={{ m: 1, width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }, }}
                  size={textFieldSize}>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={activity}
                    onChange={(event) => setActivity(event.target.value)}
                    sx={{ "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important" } }}
                  >
                    {
                      dataActivity !== null ?
                        dataActivity.map(element => (
                          <MenuItem key={element.id} value={element.id} className="menuItem">{element.description}</MenuItem>
                        ))
                        :
                        null
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("documentation")}</Typography>
                  <Tooltip title={t("tooltipDocumentation")} >
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid sx={{
                  display: "flex", width: "100%", background: "#FFFFFF", height: { xs: "40px", md: "56px" }, boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}>
                  <Input id="upload-documentation" type='file' sx={{ display: "none" }} accept="Application/msword, Application/pdf" onChange={(event) => onChangeLicence(event)} />
                  <Typography component={"label"} htmlFor="upload-documentation" sx={{ display: "flex", alignItems: "center", width: "100%", padding: "0 10px" }} >
                    <IconButton component="span" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", "&:hover": { backgroundColor: "transparent" } }} >
                      <Typography sx={{ color: "#7C7C7C", fontSize: { xs: "12px", sm: "14px", } }}>{nameFile === null ? t("uploadDocumentation") : nameFile}</Typography>
                      <CloudUploadOutlinedIcon sx={{ color: "#000000" }} />
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" }, marginTop: { xs: "0", md: "3%" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("minimum_budgetLabel")}</Typography>
                  <Tooltip title={t("tooltipSiteMinimumBudget")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  value={separator(minimum_budget)}
                  onChange={(e) => minimum_budgetHandeler(e)}
                  fullWidth
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("maximum_budgetLabel")}</Typography>
                  <Tooltip title={t("tooltipSiteMaximumBudget")} >
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  value={separator(maximum_budget)}
                  onChange={(e) => maximum_budgetHandeler(e)}
                  fullWidth
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column", width: { xs: "95%", md: "90%" }, alignItems: 'flex-start', marginTop: { xs: "0", md: "3%" }, justifyContent: 'center', marginBottom: { xs: "5%", sm: "0" } }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("description")}</Typography>
                  <Tooltip title={t("tooltipDescriptionProgramming")} >
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              <TextField
                className="textField"
                variant='outlined'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                fullWidth
                multiline
                rows={4}
                sx={{
                  width: "100%", background: "#FFFFFF",
                  boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}
              />
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column", marginTop: "40px", width: { xs: "95%", md: "90%" }, alignItems: 'center', justifyContent: 'center' }}>
              <Button onClick={submitHandeler} disabled={btnSubmit ? true : false} sx={{
                background: "#FF3951", boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", color: "#FFFFFF", fontSize: { xs: "12px", sm: "16px", md: "18px" },
                width: { xs: i18n.language === "fa" ? "100px" : "125px", sm: "159px", md: "193px" }, height: { xs: i18n.language === "fa" ? '33px' : "40px", sm: '50px', md: "60px" }, "&:hover": { backgroundColor: "#FF3951" }, textTransform: "lowercase"
              }}>
                {btnSubmit === true && loading === true ? <CircularProgress size={20} color="inherit" sx={{ color: "white" }} /> : t("submitInfo")}
              </Button>
            </Grid>
          </Grid >
          <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
        </>
  )
}

export default OrderProgramming;