import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import { author, date, h1, headPhoto, largePhoto, linkArticle, rootArticle, tags, paragraph, timeRead, view, listItemCircle, h2, h4, smallPhoto, sourceLink } from "../articleFunction/ArticleFunction";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { Articles } from "./Articles";
import ArticleSide from "../articleFunction/ArticleSide";
import { NavLink } from "react-router-dom";
//import photo
import photo1 from '../../image/articles/13/photo1.png';
import photo2 from '../../image/articles/13/photo2.jpeg';
import photo3 from '../../image/articles/13/photo3.png';
import photo4 from '../../image/articles/13/photo4.jpg';
import photo5 from '../../image/articles/13/photo5.jpg';
import photo6 from '../../image/articles/13/photo6.jpg';


import Loader from "../Loader";
import PortfolioButton from "../PortfolioButton";


let articleData = null;
const Article13 = () => {

  //NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${articleData !== null && articleData.title}`
    document.getElementById("main-heading").innerHTML = articleData !== null && articleData.h1;
    document.getElementsByTagName('meta')["description"].content = articleData !== null && articleData.desc;
    document.getElementsByTagName('meta')["keywords"].content = articleData !== null && articleData.keywords;
    document.getElementsByTagName('meta')["author"].content = articleData !== null && articleData.author;
    setCanonicalTag()
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item
    })
  }, [])

  useEffect(() => {
    articleData !== null && addView(); getView();
  }, [])

  const addView = () => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v1/article/addView",
      method: "POST",
      data: { id: articleData.id }
    }))
      .then((res) => { })
  }
  const getView = () => {
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/article/getView`,
      method: "GET",
      params: { id: articleData.id }
    }))
      .then((res) => {
        setDataView(res.data.data)
      })
  }

  //change This part

  //read More
  const readMore = [
    {
      id: 1,
      title: "سئو و تمام نکاتی که باید در آن رعایت کرد",
      link: "/what-is-seo"
    },
    {
      id: 2,
      title: "اصطلاحات پرکاربرد طراحی سایت",
      link: "/common-words-in-web-design"
    },
    {
        id: 3,
        title: "نکاتی که باید برای سفارش طراحی وب سایت در نظر گرفت",
        link: "/Points_to_consider_when_ordering_a_website_design"
      },
      {
        id: 4,
        title: "سئو سایت چیست و چرا باید به سئو اهمیت دهیم",
        link: "/SEO-Importance"
      },


  ]
  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: "rtl", alignItems: "center" }}>
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid container sx={{ display: "flex", alignItems: { xs: "unset", sm: 'flex-start' }, justifyContent: "space-between", flexDirection: "row", flexWrap: "wrap", width: { xs: "100%", sm: "85%" } }}>
          <ArticleSide tags={articleData !== null && articleData.tags} readMore={readMore} />
          {
            articleData !== null ?
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: 'center', sm: "flex-end" }, flexDirection: "column", width: { xs: "100%", sm: "60%", md: "70%" } }} >
                {/* do not change */}
                {headPhoto(articleData.image)}
                {h1(articleData.h1, t)}
                {timeRead(articleData.timeRead, t)}
                {date(articleData.date, i18n)}
                <Grid sx={{ display: 'flex', alignItems: { xs: 'center', sm: 'flex-start' }, justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "column" }}>
                  {/* The text of the article */}
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    تمام کسانی که وارد دنیای برنامه نویسی می شوند نام جاوا اسکریپت برایشان آشناست. ما در این مقاله از <NavLink to="/Academy" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >آکادمی </NavLink>
                    سورین به تعریفی از زبان جاوا اسکریپت و هدف از توسعه این زبان <NavLink to="/Academy" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >برنامه نویسی </NavLink>
                    می پردازیم. همچنین در مورد کاربردهای جاوا اسکریپت و دیگر جنبه‌های مختلف آن توضیحاتی را ارائه می دهیم که دید روشنی از این زبان برنامه نویسی در اختیار کاربر قرار می‌دهد. کاربر با مطالعه این مقاله درک بهتری از جاوا اسکریپت به دست می آورد و تصمیم می گیرد که وارد شدن به حوزه برنامه نویسی جاوا اسکریپت برای او مناسب خواهد بود یا خیر.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    جاوا اسکریپت چیست؟
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo1} alt="جاوا اسکریپت - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                       جاوا اسکریپت
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                     «جاوا اسکریپت» (
                  <Typography component={"a"} target="_blank" href="https://www.javascript.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >JavaScript</Typography>
                  ) یکی از زبان‌های برنامه نویسی پرکاربرد و رایج می باشد که معمولاً توسط برنامه‌نویسان وب برای ایجاد <NavLink to="/Academy/WebDesign" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >وب سایت </NavLink>
                  های تعاملی، صفحات وب پویا، وب <NavLink to="/Academy/Application" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >اپلیکیشن </NavLink>
                 و حتی توسعه بازی به کار می رود. JavaScript زبانی قدرتمند است که در سال 1995 برای <Typography component={"a"} target="_blank" href="https://netscape-browser.en.softonic.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >Netscape Navigator </Typography>
                 ابداع شد. از آن زمان تاکنون، تمامی مرورگرهای وب مدرن آن را برای افزودن قابلیت به وب سایت‌ها و برنامه‌های کاربردی وب به کار می برند. توسعه‌دهندگان و برنامه‌نویسان معمولاً  جاوا اسکریپت را در کنار HTML و CSS استفاده می‌کنند. این زبان محبوب در کنار HTML و CSS شگفتی می آفریند  و تعاملی ایجاد می کند که HTML و CSS  به تنهایی قادر به انجام آن نیستد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تعریف معنی جاوا اسکریپت به زبان خودمانی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در این قسمت جاوا اسکریپت را به زبان خودمانی تعریف می کنیم تا به طور کامل با مفهوم آن آشنا شوید. اگر ساختار یک صفحه وب را به آناتومی بدن انسان‌ تشبیه کنیم در واقع بدن انسان در ابتدا یک اسکلت است که روی این اسکلت با گوشت و پوست پوشیده شده است.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                   در وب هم اسکلت اولیه با زبان اشاره HTML ایجاد می‌شود. یعنی المان های اصلی صفحه مثل متن، تصویر، لینک در صفحه قرار داده می شود، اما این خروجی‌ها جذابیتی در اجرا ندارد. بنابراین به سراغ زبان دوم یعنی CSS باید رفت. به کمک CSS این اسکلت را پوست و گوشت می‌ دهیم. یعنی المان‌هایی که داخ صفحه هستند دارای رنگ، اندازه و ویژگی‌های <NavLink to="/Academy/UIUX" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >طراحی </NavLink>
                   می‌شوند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در گام بعدی باید به سراغ عضلات را برای حرکت ایجاد کنیم. اینجاست که جاوا اسکریپت وارد عمل می شود. برای ایجاد هرگونه حرکت در صفحه وب باید از جاوا اسکریپت استفاده کنیم. برای نمونه منوهای کشویی، اسلایدر و گالری تصاویر  با این زبان ایجاد می‌شوند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تاریخچه جاوا اسکریپت
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo2} alt="تاریخچه جاوااسکریپت - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        تاریخچه جاوا اسکریپت
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    جاوا اسکریپت اولین بار در می‌1995 توسط برندان ایک، یکی از کارکنان شرکت Netscape پا به دنیای برنامه نویسی گذاشت. این شرکت تصمیم داشت صفحات وب پویا و جذاب‌تری را در برنامه نویسی به کار گیرد. این اولین گام برای ساخت زبانی ساده در کد نویسی بود. آقای براندان ایچ یا برندن ایچ از طرف شرکت ماموریت داشت که زبانی اسکریپتی برای صفحات وب بسازد که طراحان بتوانند به راحتی از آن استفاده کنند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    این شرکت در ابتدا به فکر ارتقا و ساده سازی زبان <Typography component={"a"} target="_blank" href="https://www.scheme.org" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >Schema </Typography>
                    بود اما در نهایت تصمیم گرفت که زبانی شبیه جاوا اما با سینتکس ساده‌تر بسازد. در ابتدا این زبان برنامه نویسی Mocha نامگذاری شد که بعد به Mona تغییر نام داد. در سپتامبر همان سال اسم این زبان به LiveScript تغییر یافت و در آخر با نام JavaScript نهایی شد. انتخاب این نام به دلیل پرطرفدار بودن زبان برنامه نویسی Java در آن زمان بود. در آن زمان جاوا اسکریپت توانست سهم زیادی از بازار جاوا را از آن خود کند. جاوا اسکریپت در سال 1996 برای استاندارد شدن به سازمان <Typography component={"a"} target="_blank" href="https://www.ecma-international.org" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >ECMA </Typography>
                    واگذار شد. در نهایت اولین استاندارد جاوا اسکریپت با نام ECMAScript در سال 1997 انتشار یافت.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    کاربردهای جاوا اسکریپت
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    این <NavLink to="/Academy/popular-programming-language-2023" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >زبان محبوب </NavLink>
                   ، در بسیاری از حوزه‌های فناوری و اینترنت اعم از توسعه و برنامه نویسی وب، توسعه بازی، ساخت وب اپلیکیشن، برنامه نویسی سمت سرور و غیره استفاده می شود. 
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زبان جاوا اسکریپت بخاطر انعطافی که دارد بسیار مورد توجه برنامه‌نویسان قرار گرفته است. این زبان برای برنامه نویسی سمت کاربر و سمت سرور کاربرد دارد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    جاوا اسکریپت علاوه بر طراحی سایت، در برنامه‌نویسی اپلیکیشن موبایل و دسکتاپ نیز کارایی دارد.
                    از کاربردهای دیگر این زبان توسعه‌ی بازی‌های تحت وب، ایجاد <NavLink to="/Academy/Graphic" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >گرافیک </NavLink>
                    های دو و سه بعدی می باشد. 
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    به دلیل سادگی این زبان نسبت به سایر زبان های برنامه نویسی، به راحتی با یادگیری آن قادر خواهید بود که روح تازه ای به وب‌سایت خود دهید و با ایجاد انواع انیمیشن و تحرکات مختلف به عناصر صفحه، دارای <NavLink to="/Academy/2023-UI-UX-design-trend" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >رابط کاربری </NavLink>
                    بهتری نیز باشید.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    جاوا اسکریپت باعث می‌شود که تعامل بین کاربر و صفحات وب به بهترین شکل ممکن انجام پذیرد. امروزه بسیاری از مرورگرهای پیشرفته با استفاده از جاوا اسکریپت به‌عنوان زبان برنامه نویسی اصلی خود تجربه کاربری بی‌نظیری را برای کاربران وب به ارمغان می آورند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    مزایای زبان جاوا اسکریپت
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo3} alt="مزایای جاوا اسکریپت - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        مزایای جاوا اسکریپت
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                   این زبان هم مانند دیگر زبان های برنامه نویسی دارای نقاط قوت بسیاری می باشد که باعث محبوبیت آن در میان برنامه نویسان شده است. در ادامه این مزایا را بررسی می کنیم.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    با توجه به آمار منشر شده از  سایت <Typography component={"a"} target="_blank" href="https://stackoverflow.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >stackoverflow </Typography>
                   ، جاوا اسکریپت محبوب‌ترین زبان برنامه نویسی سال 2018 شد.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                   برای پردازش و اجرا نیازی به کامپایلر نیست.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    یادگیری جاوا اسکریپت نسبت به دیگر زبان‌های برنامه نویسی راحت‌تر می باشد.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    به صورت کراس پلتفرم روی مرورگر‌ها و پلتفرم‌های مختلف قابلیت اجرا دارد.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    از زبان‌های برنامه نویسی دیگر سبک‌تر و سریع‌تر است.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                   فریم ورک ها و کتابخانه‌های زیادی را در اختیارتان می گذارد.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    زبان بومی مرورگر وب می باشد و پردازش در مرورگر کاربران به راحتی انجام می‌شود.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    امکان ایجاد صفحات وب تعاملی و پویا را برای برنامه نویسان فراهم می آورد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    معایب زبان برنامه نویسی جاوا اسکریپت
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo4} alt="معایب جاوا اسکریپت - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        معایب جاوا اسکریپت
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                   این زبان با وجود نقاط قوت بسیاری که دارد دارای معایبی نیز می باشد که در ادامه به بررسی آن ها می پردازیم.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    مشکل دیباگ کردن و دشواری در تشخیص خطا
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    بر روی مرورگرهای قدیمی اجرا نمی شود.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    نسبت به عوامل مخرب نفوذ پذیری دارد.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    برای اجرای کدهای مخرب در کامپیوتر کاربران استفاده می شود.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    با رندر شدن متفاوت بر ابزارهای مختلف سبب ایجاد تناقض و نداشتن یکپارچگی می شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    بهترین فریم ورک‌های زبان جاوا اسکریپت
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo5} alt="فریم ورک های جاوا اسکریپت - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        فریم ورک های جاوا اسکریپت
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    اگر شما تصمیم ندارید که از این زبان در وب استفاده کنید، بعد از یادگیری آن می‌توانید از فریم ورک‌های این زبان استفاده نمایید. استفاده از فریم ورک ها باعث افزایش سرعت کدنویسی شما می شود و دقت و نظم بیشتری به آن می‌ دهد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    معروف‌ترین فریمورک‌های java script:
                  </Typography>
                  <Typography component={"a"} target="_blank" href="" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} ><Typography component={"a"} target="_blank" href="https://angularjs.org" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >Angular JS</Typography>
                  </Typography>
                  <Typography component={"a"} target="_blank" href="" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} ><Typography component={"a"} target="_blank" href="https://react.dev" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >React</Typography>
                  </Typography>
                  <Typography component={"a"} target="_blank" href="" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} ><Typography component={"a"} target="_blank" href="https://vuejs.org" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >Vue.Js</Typography>
                  </Typography>
                  <Typography component={"a"} target="_blank" href="" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} ><Typography component={"a"} target="_blank" href="https://emberjs.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >Emberjs</Typography>
                   </Typography>
                   <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    کتابخانه‌های معروف جاوا اسکریپت
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    از مزیت های جاوا اسکریپت می توان به کتابخانه‌هایی اشاره کرد که بر پایه این زبان شکل گرفته‌اند. کتابخانه هر زبان برنامه نویسی، مجموعه‌ای از دستورات آن زبان می باشد که به صورت خلاصه و آماده برای استفاده قرار می‌گیرد. یکی از مشهورترین کتابخانه‌های جاوا اسکریپت، <Typography component={"a"} target="_blank" href="https://jquery.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >jQuery </Typography>
                    نام دارد. جی‌کوئری مجموعه‌ای از دستورات پیچیده‌ی جاوا اسکریپت می باشد که به‌صورت بسیار ساده در اختیار کاربران قرار می گیرد. جی‌کوئری این قابلیت را ایجاد می کند که به جای کدنویسی طولانی، کدهای خود را در یک یا چند سطر، خلاصه نمایید. به طور مثال با جی‌کوئری می‌توانید کدنویسی طولانی را جایگزین کدنویسی ساده نمایید. 
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    جاوا اسکریپت چگونه کار می‌کند؟
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo6} alt="عملکرد جاوا اسکریپت - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        عملکرد جاوا اسکریپت
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بعد از بررسی مزایای استفاده از جاوا اسکریپت در بالا ، در مورد نحوه عملکرد جاوا اسکریپت بحث می کنیم.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    جاوا اسکریپت یک زبان برنامه نویسی سمت کلاینت ( کاربر ) می باشد، این بدین معناست که در مرورگر کاربر کار می‌کند و در دستگاه خارجی عمل نمی‌کند. یک مثال از زبانی که سمت کلاینت نیست، <Typography component={"a"} target="_blank" href="https://www.mysql.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >MySQL </Typography>
                    می باشد، یعنی mysql زبانی سمت سرور است که درخواست‌های پایگاه داده را مدیریت می‌کند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    جاوا اسکریپت نیازی به بارگیری نرم افزار خاصی در دستگاه‌های کاربر ندارد، زیرا مرورگرهای مدرن، نرم افزار مورد نیاز را در خود ادغام می کنند. این کار باعث می‌شود جاوا اسکریپت بسیار کاربر پسندتر از دیگر زبان‌های برنامه نویسی شود. همچنین طراحی با جاوا اسکریپت سبب کاهش قیمت طراحی سایت می شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    چرا جاوا اسکریپت محبوب است؟
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                     از دلایل محبوبیت جاوا اسکریپت می توان به ریسپانسیو بودن آن اشاره داشت. به این معنا که توسعه دهندگان می توانند طراحی خود را در چندین مرورگر و دستگاه تطبیق دهند. با ترکیب HTML5، CSS3 و جاوا اسکریپت، آنها قادر خواهند بود که این کار را در یک پایگاه کم واحد انجام دهند. همچنین یادگیری JS برای توسعه دهندگان آسان می باشد و برای آنها این امکان را فراهم می آورد تا با سرعت بتوانند سایت خود را توسعه دهند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    همیشه طرفداران سرسخت پایتون هستند که استدلال خوبی برای یادگیری <NavLink to="/Academy/What-Is-Python-Programming-Language" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >پایتون </NavLink>
                    دارند ولی اگر تصمیم دارید که به دنیای فناوری ورود کنید، جاوا اسکریپت بهترین انتخاب است.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    می توان بدون اغراق گفت که جاوا اسکریپت یا فریمورک های جاوا اسکریپت نظیر angular جزء ضروری توسعه وب‌سایت فرانت‌اند می باشند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                   جست‌وجوی سایت‌های فهرست مشاغل در <NavLink to="/JobOffer" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >مشاغل </NavLink>
                   برنامه‌نویسی نشان دهنده ی اولویت قابل توجه برای برنامه‌نویسان جاوا اسکریپت است. امکان دارد برخی از کسب و کارها ندانند کدام زبان برنامه نویسی برای پروژه آنها بهتر است، اما یک پروژه وب سایت فرانت اند حتما نیاز به توسعه دهنده جاوا اسکریپت دارد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                   بهترین شرکت طراحی سایت با جاوا اسکریپت :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    همان طور که در این مقاله از اکادمی سورین بررسی و گفته شد، جاوا اسکریپت یکی از محبوب ترین و کاراترین زبان های سمت کاربر می باشد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                   شرکت <NavLink to="/AboutSorin" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >وب گستران سورین </NavLink>
                  با مجوز از سازمان نصر تهران به عنوان بهترین شرکت طراحی سایت با جاوا اسکریپت در تهران و کل ایران آماده ارایه خدمات طراحی وب سایت، اپلیکیشن، برنامه نویسی و سئو به شکل کاملا حرفه ای و تخصصی به همویهنان عزیز می باشد .
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    کلام آخر
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در این مقاله سعی بر این داشتیم که شما را تا حدودی با زبان پر کاربرد جاوا اسکریپت آشنا نماییم. اما زبان جاوا اسکریپت بسیار فراتر از مطالب این مقاله می باشد. ما در مقالات بعدی سورین نیز آموزش جامع تری از جاوا اسکریپت قرار می دهیم. امید است که مطالعه این مطالب برای شما مفید واقع شود
                  </Typography>


                  {/* do not change */}

                  {author(articleData.author, t)}
                  {tags(articleData.tags, t)}
                  {linkArticle(t, url)}
                  {sourceLink(articleData.source, t)}
                  {view(dataview, t)}
                </Grid>
              </Grid>
              :
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "80%", sm: "60%", md: "70%" } }}>
                <Loader />
              </Grid>
          }
        </Grid>
      </Grid>
      <PortfolioButton />
      <Footer />
    </>
  );
}
export default Article13;