import { Button, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const PortfolioButton = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Grid sx={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", transform: "rotate(90deg)", position: "fixed", left: "-60px", top: {xs:"25vh",md:"30vh"}, zIndex: "800" }}>
        <NavLink to="/Portfolio" target="_blank">
          <Button sx={{
            width: {xs:"100px",sm:"125px",md:"150px"}, height: {xs:"60px",sm:"50px",md:"40px"}, borderRadius: {xs:"30px 30px 0 0",sm:"35px 35px 0 0",md:"80px 80px 0 0"}, background: "linear-gradient(270deg, #FFF8EC 47.7%, #FFBCC4 103.55%)", color: "#FF3951",
            fontSize: { xs: "10px", sm: "14px", md: "17px" }
          }}>
            <Grid sx={{ border: "2px solid #ffffff", width:{xs:"105px",sm:"120px",md:"140px"}, height:{xs:"55px",sm:"45px",md:"30px"}, borderBottom: "unset", borderRadius: {xs:"23px 23px 0 0",sm:"28px 28px 0 0",md:"80px 80px 0 0"} }}>
              {t("sampleWork")}
            </Grid>
          </Button>
        </NavLink>
      </Grid>
    </>
  )
}

export default PortfolioButton;