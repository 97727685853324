import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { setCanonicalTag } from "./modules/general";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "./modules/general";
import { useState } from "react";
import avatar from '../image/dashboard/avatar.png'
import Loader from "./Loader";


const Dashboard = ({ infoUser }) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState(null);
  const [program, setProgram] = useState(null);
  const [graphic, setGraphic] = useState(null);
  const [site, setSite] = useState(null);
  const [seo, setSeo] = useState(null);

  const titleSite = ["condition", "type", "activity", "maximum_budget", "minimum_budget", "domain", "seo", "host", "goal_customer", "description", "similar_sites", "special_features"]
  const titleProgrammer = ["condition", "activity", "minimum_budget", "maximum_budget", "description"];
  const titleGraphic = ["condition", "graphic_type_id", "color", "description"];
  const titleSeo = ["condition", "site_name", "site_address", "activity", "keyword", "minimum_budget", "maximum_budget", "textual_cotent_number", "visual_cotent_number", "video_cotent_number", "description"];

  useEffect(() => {
    document.title = "پنل سفارش سایت | پنل سفارش اپلیکیشن | درخواست سفارش سئو | ثبت سفارش برنامه نویسی | وب گستران سورین";
    document.getElementById("main-heading").innerHTML = "پنل سفارش سایت | پنل سفارش اپلیکیشن | درخواست سفارش سئو | ثبت سفارش برنامه نویسی | وب گستران سورین";
    document.getElementsByTagName('meta')["description"].content = "صفحه داشبورد شرکت وب گستران سورین مخصوص کاربرانی است که درخواست هایی نظیر برنامه نویسی، طراحی UI/UX، طراحی انواع وب سایت، سئو سایت و طراحی گرافیکی را دارند.";
    document.getElementsByTagName('meta')["keywords"].content = "داشبور شرکت وب گستران سورین | پنل درخواست طراحی سایت | پنل درخواست طراحی رابط کاربری | پنل درخواست سئو سایت | پنل درخواست برنامه نویسی | ثبت سفارش پروژه برنامه نویسی دانشگاهی ";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    getNewOrder();
  }, [])

  useEffect(() => {
    data !== null && sliceData(data);
  }, [data])


  const getNewOrder = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/general/last`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setData(res.data.data)
      })
  }
  const sliceData = (data) => {
    setProgram(data.program);
    setGraphic(data.graphic);
    setSeo(data.seo);
    setSite(data.site);
  }

  const gridSeo = () => {
    let list = [];
    list.push(
      <Grid key={"data1"} sx={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: "column", borderRadius: "8px", margin: { xs: "20px 0", md: "30px 0px" },
        boxShadow: '3px 12px 10px rgba(0, 0, 0, 0.25)', background: "#FFFFFF", width: { xs: "95%", sm: "90%", md: "70%", lg: "617px" }
      }}>
        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", padding: "20px 0" }}>
          <Typography sx={{ color: "#070707", fontSize: { xs: "18px", sm: "20px" }, fontWeight: "700" }}>{t("lastOrderSeo")}</Typography>
        </Grid>
        {titleSeo.map((value, index) => (
          <Grid key={index} sx={{ display: 'flex', alignItems: 'center', backgroundColor: (index + 1) % 2 === 0 ? "#ffffff" : "#EDEDED", width: "100%", }}>
            <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "center", width: "50%", borderRight: i18n.language === "en" ? "1px solid #848484" : 'none', height: "34px !important" }}>
              <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, color: "#070707", textAlign: "center" }}>{t(value)}</Typography>
            </Grid>
            <Grid sx={{ display: "flex", alignItems: 'center', width: "50%", justifyContent: "center", borderRight: i18n.language === "fa" ? "1px solid #848484" : 'none', height: "34px !important" }}>
              <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, color: "#FF3951", textAlign: "center" }}>
                {seo === null ? t("NotCompleted") :
                  value === "condition" ? seo.condition.description !== null ? seo.condition.description : t("NotCompleted") :
                    value === "activity" ? seo.activity.description !== null ? seo.activity.description : t("NotCompleted") :
                      seo[value] !== null ? seo[value] : t("NotCompleted")
                }
              </Typography>

            </Grid>
          </Grid>
        ))}
      </Grid>
    )
    return list;
  }

  const gridSite = () => {
    let list = [];
    list.push(
      <Grid key={"data2"} sx={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: "column", borderRadius: "8px", margin: { xs: "20px 0", md: "30px 0px" },
        boxShadow: '3px 12px 10px rgba(0, 0, 0, 0.25)', background: "#FFFFFF", width: { xs: "95%", sm: "90%", md: "70%", lg: "617px" }
      }}>
        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", padding: "20px 0" }}>
          <Typography sx={{ color: "#070707", fontSize: { xs: "18px", sm: "20px" }, fontWeight: "700" }}>{t("lastOrderSite")}</Typography>
        </Grid>
        {titleSite.map((value, index) => (
          <Grid key={index} sx={{ display: 'flex', alignItems: 'center', backgroundColor: (index + 1) % 2 === 0 ? "#ffffff" : "#EDEDED", width: "100%", }}>
            <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "center", width: "50%", borderRight: i18n.language === "en" ? "1px solid #848484" : 'none', height: "34px !important" }}>
              <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, color: "#070707", textAlign: "center" }}>{t(value)}</Typography>
            </Grid>
            <Grid sx={{ display: "flex", alignItems: 'center', width: "50%", justifyContent: "center", borderRight: i18n.language === "fa" ? "1px solid #848484" : 'none', height: "34px !important" }}>
              <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, color: "#FF3951", textAlign: "center" }}>
                {site === null ? t("NotCompleted") :
                  value === "condition" ? site.condition.description !== null ? site.condition.description : t("NotCompleted") :
                    value === "activity" ? site.activity.description !== null ? site.activity.description : t("NotCompleted") :
                      value === "type" ? site.type.description !== null ? site.type.description : t("NotCompleted") :
                        site[value] !== null ? site[value] : t("NotCompleted")
                }
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    )

    return list;
  }

  const gridGraphic = () => {
    let list = [];
    let color = null;

    color = graphic !== null && graphic.color !== null && JSON.parse(graphic.color);
    list.push(
      <Grid key={"data3"} sx={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: "column", borderRadius: "8px", margin: { xs: "20px 0", md: "30px 0px" },
        boxShadow: '3px 12px 10px rgba(0, 0, 0, 0.25)', background: "#FFFFFF", width: { xs: "95%", sm: "90%", md: "70%", lg: "617px" }
      }}>
        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", padding: "20px 0" }}>
          <Typography sx={{ color: "#070707", fontSize: { xs: "18px", sm: "20px" }, fontWeight: "700" }}>{t("lastOrderGraphic")}</Typography>
        </Grid>
        {titleGraphic.map((value, index) => (
          <Grid key={index} sx={{ display: 'flex', alignItems: 'center', backgroundColor: (index + 1) % 2 === 0 ? "#ffffff" : "#EDEDED", width: "100%", }}>
            <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "center", width: "50%", borderRight: i18n.language === "en" ? "1px solid #848484" : 'none', height: "34px !important" }}>
              <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, color: "#070707", textAlign: "center" }}>{t(value)}</Typography>
            </Grid>
            {value === "color" ?
              <Grid sx={{ display: "flex", alignItems: 'center', width: "50%", justifyContent: "center", borderRight: i18n.language === "fa" ? "1px solid #848484" : 'none', height: "34px !important" }}>
                {graphic === null ?
                  <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, color: "#FF3951", textAlign: "center" }}>{t("NotCompleted")}</Typography>
                  :
                  graphic.color !== null ?
                    <Grid sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                      <Grid sx={{ backgroundColor: color[0], display: color[0] !== "#ffffff" ? "flex" : "none", padding: "10px" }}></Grid>
                      <Grid sx={{ backgroundColor: color[1], display: color[1] !== "#ffffff" ? "flex" : "none", padding: "10px" }}></Grid>
                      <Grid sx={{ backgroundColor: color[2], display: color[2] !== "#ffffff" ? "flex" : "none", padding: "10px" }}></Grid>
                      <Grid sx={{ backgroundColor: color[3], display: color[3] !== "#ffffff" ? "flex" : "none", padding: "10px" }}></Grid>
                    </Grid>
                    :
                    <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, color: "#FF3951", textAlign: "center" }}>{t("NotCompleted")}</Typography>}

              </Grid>
              : <Grid sx={{ display: "flex", alignItems: 'center', width: "50%", justifyContent: "center", borderRight: i18n.language === "fa" ? "1px solid #848484" : 'none', height: "34px !important" }}>
                <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, color: "#FF3951", textAlign: "center" }}>
                  {graphic === null ? t("NotCompleted") :
                    value === "condition" ? graphic.condition.description !== null ? graphic.condition.description : t("NotCompleted") :
                      value === "graphic_type_id" ? graphic.type.description !== null ? graphic.type.description : t("NotCompleted") :
                        graphic[value] !== null ? graphic[value] : t("NotCompleted")
                  }
                </Typography>
              </Grid>
            }
          </Grid>
        ))
        }
      </Grid >
    )

    return list;
  }

  const gridProgramming = () => {
    let list = [];

    list.push(
      <Grid key={"data4"} sx={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: "column", borderRadius: "8px", margin: { xs: "20px 0", md: "30px 0px" },
        boxShadow: '3px 12px 10px rgba(0, 0, 0, 0.25)', background: "#FFFFFF", width: { xs: "95%", sm: "90%", md: "70%", lg: "617px" }
      }}>
        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", padding: "20px 0" }}>
          <Typography sx={{ color: "#070707", fontSize: { xs: "18px", sm: "20px" }, fontWeight: "700" }}>{t("lastOrderProgram")}</Typography>
        </Grid>
        {titleProgrammer.map((value, index) => (
          <Grid key={index} sx={{ display: 'flex', alignItems: 'center', backgroundColor: (index + 1) % 2 === 0 ? "#ffffff" : "#EDEDED", width: "100%", }}>
            <Grid sx={{ display: "flex", alignItems: 'center', justifyContent: "center", width: "50%", borderRight: i18n.language === "en" ? "1px solid #848484" : 'none', height: "34px !important" }}>
              <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, color: "#070707", textAlign: "center" }}>{t(value)}</Typography>
            </Grid>
            <Grid sx={{ display: "flex", alignItems: 'center', width: "50%", justifyContent: "center", borderRight: i18n.language === "fa" ? "1px solid #848484" : 'none', height: "34px !important" }}>
              <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, color: "#FF3951", textAlign: "center" }}>
                {program === null ? t("NotCompleted") :
                  value === "condition" ? program.condition.description !== null ? program.condition.description : t("NotCompleted") :
                    value === "activity" ? program.activity.description !== null ? program.activity.description : t("NotCompleted") :
                      program[value] !== null ? program[value] : t("NotCompleted")
                }
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    )
    return list;
  }

  return (
    <>
      <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: "column", width: "100%", direction: i18n.language === "fa" ? "rtl" : 'ltr' }}>
        <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', flexDirection: { xs: "column", sm: 'row' }, width: '100%' }}>
          <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: "50%" }}>
            <Typography sx={{ color: "#070707", fontSize: { xs: "18px", sm: '24px' }, fontWeight: '700' }}>
              {infoUser.user_type_id === 1 ? infoUser.name === null ? "" : infoUser.name + " " + infoUser.lastname :
                infoUser.user_type_id === 2 ? infoUser.organ_manager_name === null ? "" : infoUser.organ_manager_name : ""
              }
            </Typography>
            <Typography sx={{ color: "#070707", fontSize: { xs: "12px", sm: '20px' } }}>
              {infoUser.user_type_id === 2 ? t("organizationPanel") : t("personalPanel")}
            </Typography>
            <Typography sx={{ color: "#070707", fontSize: { xs: "12px", sm: '20px' } }}>
              {infoUser.user_type_id === 2 ? infoUser.organ_name === null ? "" : infoUser.organ_name : ""}
            </Typography>
          </Grid>
          <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "50%" }}>
            <Grid sx={{ width: { xs: "188px", md: "213px" }, height: { xs: "162px", md: "184px" }, background: "#ffffff", boxShadow: " 0px 0px 13px 2px rgba(0, 0, 0, 0.25)", borderRadius: "16px" }}>
              <Grid
                sx={{ width: { xs: "188px", md: "213px" }, height: { xs: "162px", md: "184px" }, background: `url(${infoUser.picture_path !== null ? infoUser.picture_path : avatar})`, backgroundPosition: "center", backgroundSize: "100% 100%", }} ></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', width: "100%", flexDirection: "column", margin: { xs: "0", md: "20px 0" } }}>

          {data === null && <Loader />}
          {gridSite()}
          {gridSeo()}
          {gridGraphic()}
          {gridProgramming()}
        </Grid>
      </Grid>
    </>
  )
}

export default Dashboard;