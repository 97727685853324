import { SvgIcon } from '@mui/material';
import React from 'react';
const NewUserIcon = ({ color }) => {
  return (
    <SvgIcon component="object">
      <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0001 7.20001C9.6241 7.20001 7.7041 9.12001 7.7041 11.496C7.7041 13.872 9.6241 15.804 12.0001 15.804C14.3761 15.804 16.2961 13.872 16.2961 11.496C16.2961 9.12001 14.3761 7.20001 12.0001 7.20001Z" fill={color} />
        <path d="M17.028 0H6.972C2.604 0 0 2.604 0 6.972V17.028C0 20.4 1.548 22.716 4.272 23.592C5.064 23.868 5.976 24 6.972 24H17.028C18.024 24 18.936 23.868 19.728 23.592C22.452 22.716 24 20.4 24 17.028V6.972C24 2.604 21.396 0 17.028 0ZM22.2 17.028C22.2 19.596 21.192 21.216 19.164 21.888C18 19.596 15.24 17.964 12 17.964C8.76 17.964 6.012 19.584 4.836 21.888H4.824C2.82 21.24 1.8 19.608 1.8 17.04V6.972C1.8 3.588 3.588 1.8 6.972 1.8H17.028C20.412 1.8 22.2 3.588 22.2 6.972V17.028Z" fill={color} />
      </svg>
    </SvgIcon>
  );
};
export default NewUserIcon;