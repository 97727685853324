import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import notAuthentication from '../image/notAuthentication/notAuthentication.png';
import { Button, Grid, Typography } from "@mui/material";
import ModalLogin from "./ModalLogin";

const AuthenticationNull = () => {

  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");

  const handleCloseModal = () => {
    setOpen(false);
  }
  const loginHandler = () => {
    setType("L");
    setOpen(true);
  }
  const registerHandler = () => {
    setType("R");
    setOpen(true);
  }
  return (
    <>
      <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", flexDirection: "column", width: "100%", direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
        <Grid sx={{
          backgroundSize: "100% 100%", backgroundImage: `url(${notAuthentication})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
          width: { xs: "300px", sm: "500px", md: "700px" }, height: { xs: "200px", sm: "400px", md: "600px" }, margin: "50px 0"
        }}></Grid>
        <Typography sx={{ fontSize: { xs: "", sm: "", md: "30px" }, color: "#000000", width: { xs: "250px", sm: "400px", md: "521px" }, textAlign: "center" }}>{t("descAuthen")}</Typography>
        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", width: "100%", margin: "40px 0" }}>
          <Button onClick={loginHandler} sx={{
            fontSize: { xs: "12px", sm: "16px", md: "18px" }, fontWeight: "700", width: { xs: "90px", sm: "145px" }, height: { xs: "40px", sm: "50px" },
            color: "#FF3951", border: "1px solid #FF3951", boxShadow: "0px 0px 8px #FF3951", margin: "0 30px"
          }}>
            {t("login")}
          </Button>
          <Button onClick={registerHandler} sx={{
            fontSize: { xs: "12px", sm: "16px", md: "18px" }, fontWeight: "700", width: { xs: "90px", sm: "145px" }, height: { xs: "40px", sm: "50px" },
            background: "#FF3951", color: "#ffffff", boxShadow: "0px 0px 8px #FF3951", margin: "0 30px"
          }}>
            {t("register")}
          </Button>
        </Grid>
      </Grid>
      <ModalLogin open={open} close={handleCloseModal} data={type} />
    </>
  )
}

export default AuthenticationNull;