import React, { useEffect, useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArticleSide from "../articleFunction/ArticleSide";
import Footer from "../Footer";
import { author, date, h1, linkArticle, rootArticle, tags, timeRead, view, sourceLink, headPhoto } from "../articleFunction/ArticleFunction";
import { Articles } from "./Articles";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { NavLink } from "react-router-dom";
import logo from '../../image/logo/logo.png'
import '../../styles/Article.css';
//import Photo
import photo1 from '../../image/articles/03/photo1.jpg'
import photo2 from '../../image/articles/03/photo2.jpg'
import photo3 from '../../image/articles/03/photo3.webp'
import photo4 from '../../image/articles/03/photo4.jpg'
import photo5 from '../../image/articles/03/photo5.jpg'
import photo6 from '../../image/articles/03/photo6.jpg'
import photo7 from '../../image/articles/03/photo7.jpg'
import photo8 from '../../image/articles/03/photo8.jpg'
import photo9 from '../../image/articles/03/photo9.jpg'
import photo10 from '../../image/articles/03/photo10.png'
import photo11 from '../../image/articles/03/photo11.jpg'
import photo12 from '../../image/articles/03/photo12.jpg'
import photo13 from '../../image/articles/03/photo13.jpg'
import photo14 from '../../image/articles/03/photo14.jpg'
import photo15 from '../../image/articles/03/photo15.jpg'
import Loader from "../Loader";

let articleData = null;
const Article03 = () => {
  ////////NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${articleData !== null && articleData.title}`
    document.getElementById("main-heading").innerHTML = articleData !== null && articleData.h1;
    document.getElementsByTagName('meta')["description"].content = articleData !== null && articleData.desc;
    document.getElementsByTagName('meta')["keywords"].content = articleData !== null && articleData.keywords;
    document.getElementsByTagName('meta')["author"].content = articleData !== null && articleData.author;
    setCanonicalTag()
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item
    })
  }, [])

  useEffect(() => {
    articleData !== null && addView(); getView();
  }, [])

  const addView = () => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v1/article/addView",
      method: "POST",
      data: { id: articleData.id }
    }))
      .then((res) => { })
  }

  const getView = () => {
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/article/getView`,
      method: "GET",
      params: { id: articleData.id }
    }))
      .then((res) => {
        setDataView(res.data.data)
      })
  }
  //change This part

  //read More
  const readMore = [
    {
      id: 1,
      title: "ترند طراحی UI و UX سال 2023",
      link: "/2023 UI-UX design trend "
    },
  ]

  //sourceLink
  const sourceAddress = ""

  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: i18n.language === "fa" ? "rtl" : "ltr", alignItems: "center" }}>
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid container sx={{ display: "flex", alignItems: { xs: "unset", sm: 'flex-start' }, justifyContent: "space-between", flexDirection: "row", flexWrap: "wrap", width: { xs: "100%", sm: "85%" } }}>
          <ArticleSide tags={articleData !== null && articleData.tags} readMore={readMore} />
          {
            articleData !== null ?
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: 'center', sm: "flex-end" }, flexDirection: "column", width: { xs: "100%", sm: "60%", md: "70%" } }} >
                {/* do not change */}

                {headPhoto(articleData.image)}
                {h1(articleData.h1, t)}
                {timeRead(articleData.timeRead, t)}
                {date(articleData.date, i18n)}
                <Grid sx={{ display: 'flex', alignItems: { xs: "center", sm: 'flex-start' }, justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "column" }}>
                  {/* The text of the article */}
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    لوگو را می توان هویت بصری یک کسب و کار داست که به صورت نماد طراحی می شود. لوگو برای معرفی محصول یا خدمات یک حوزه کاری به مشتری در قالب یک طرح گرافیکی معرفی می شود. ما در این مقاله قصد داریم تا شما را با بهترین لوگوهای جهان آشنا کنیم. هر کدام از این نماد ها داستانی را در دل خود دارند.  پس با سورین همراه باشید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    لوگوهای معروف جهان
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    لوگوهای زیادی در جهان وجود دارند که از لحاظ محبوبیت بسیار معروف هستند. بسیاری از این لوگوها در طول تاریخ دچار تغییر شدند. اما بسیاری نیز از این لوگو ها همان نماد اولیه خود را حفظ کرده اند. در زیر به بررسی تعدادی انگشت شمار از این لوگو ها می پردازیم.
                  </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    لوگوی کوکاکولا
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo1} alt="لوگوی کوکاکولا - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        لوگوی کوکاکولا
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    جان پمبرتون داروساز آمریکایی در سال 1886 یک نوشیدنی اختراع کرد. این نوشیدنی ترکیبی از کوکائین که از برگ کوکا گرفته می شود و عصاره کافئین دانه کولا بود. فرانک رابینسون که حسابدار دکتر پمبرتون بود از ترکیب این دو کلمه نام این نوشیدنی را <Typography component={"a"} target="_blank" href="https://www.coca-colacompany.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >کوکاکولا </Typography>
                    پیشنهاد داد. همچنین برای لوگوی این نوشیدنی نیز نام آن را بصورت دست نویس خطاطی کرد که مورد تایید دکتر واقع شد. با اینکه حق امتیاز این برند در سال 1889 توسط آساکندلر خریداری شد اما همچنان  این لوگو تا به امروز به عنوان لوگوی کوکاکولا استفاده می شود.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: { xs: "90%", sm: "100%" } }}>
                    لوگوی اپل
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo2} alt="لوگوی اپل - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        لوگوی اپل
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    این لوگو در سال 1976 توسط راب جانوف که از بهترین طراحان لوگو به شمار می آید طراحی شد. طرح اولیه لوگو اپل تصویری از نیوتن بود که زیر درخت سیب نشسته است. طرح بعدی این لوگو به شکل یک سیب گاز خورده رنگین کمانی  بود. این طرح به مرور جای خود را به یک طرح مونوکروم سیاه داد.  در مورد گاز روی سیب، برخی آن را نماد "بایت" می‌دانند و بعضی از افراد آن را استعاره‌ای از آگاهی مشتریان از محصولات اپل می‌دانند. البته برخی معتقدند گاز روی لوگو برای تمایز آن از آلبالو می باشد. اما طراحان لوگو این گاز را راهی برای ورود به لوگوهای مینیمالیستی و خارج کردن طرح از سادگی به شمار می آورند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    یکی از نکات مورد توجه در مورد این لوگو عدم ارتباط معنایی این نماد با حوزه فعالیت شرکت اپل است. بسیاری از طراحان این لوگو را به عنوان بهترین لوگو می دانند.
                  </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    لوگوی آمازون
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo3} alt="لوگوی آمازون - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        لوگوی آمازون
                      </Typography>
                    </Grid>
                  </Grid>
                 
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    شرکت <Typography component={"a"} target="_blank" href="https://www.amazon.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >آمازون </Typography>
                    در سال 1995 با فروش کتاب به صورت آنلاین کار خود را شروع کرد. سپس کار خود را به فروش تمامی محصولات توسعه داد. لوگوی این شرکت به صورت amazon  می باشد و فلشی زرد رنگ زیر از a تا  z کشیده شده است. این لوگو بدین معناست که در شرکت آمازون هر آنچه را که مورد نیاز مشتری است و با هر حرفی که از a تا z شروع می شود، می توانید پیدا کنید. همچنین فلش زرد رنگ که شبیه لبخند می باشد می تواند حس رضایت افراد را منتقل کند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: { xs: "90%", sm: "100%" } }}>
                    لوگوی والت دیزنی
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo4} alt="لوگوی والت دیزنی - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        لوگوی والت دیزنی
                      </Typography>
                    </Grid>
                  </Grid>
                 
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    این لوگو یکی از لوگوهای محبوب در بین کودکان است. لوگوی این شرکت که ترکیبی از قلعه سیندرلا و امضای والت دیزنی بنیانگذار این شرکت می باشد، تداعی گر کارتن سیندرلا می باشد. همچنین نماد قلعه می تواند نشان دهنده سحر و جادو باشد که به ببینده حس هیجان و تخیل را القا می کند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: { xs: "90%", sm: "100%" } }}>
                    لوگوی شرکت نایک
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo5} alt="لوگوی نایک - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        لوگوی نایک
                      </Typography>
                    </Grid>
                  </Grid>
                 
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    یکی از بهترین و معروفترین لوگوهای جهان لوگوی <Typography component={"a"} target="_blank" href="https://www.nike.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} > شرکت نایک </Typography>
                    می باشد. این لوگو در دسته لوگو های نمادین یا مونوگرام می باشد. لوگوی نایک در دید اول شبیه به یک چک مارک یا تیک می باشد اما مفهوم عمیقی را در خود جای داده است. اسم این شرکت از الهه بالدار پیروزی یونان که توانایی پرواز داشت برگرفته است. بدین معنا که کفش های نایک بخاطر سبکی و راحتی حس پرواز را می دهد. کروین دیویدسون از بال این الهه برای طراحی لوگوی نایک ایده گرفت. همچنین دو خط قوس‌دار این لوگو نشان از سرعت، حرکت و پیروزی دارند.
                  </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    لوگوی گوگل
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo6} alt="لوگوی گوگل - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        لوگوی گوگل
                      </Typography>
                    </Grid>
                  </Grid>
                 
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    از معروف ترین و بهترین لوگوهای جهان در حوزه فناوری می توان به لوگوی <Typography component={"a"} target="_blank" href="https://www.google.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >گوگل </Typography>
                    اشاره داشت. این لوگو در دسته لوگوهای تایپوگرام قرار دارد. لوگوی گوگل در عین سادگی بسیار جذاب می باشد. این لوگو توسط آرتور کدار از طراحان مطرح جهان در سال 1998 طراحی شد. لوگوی اولیه گوگل فونت ساده ای از نام شرکت بود. در سال 2014 تغییراتی جزئی روی این لوگو اعمال شد و فاصله بین حروف را کمی بیشتر کردند. سرانجام در سال 2014 این لوگو با فونت سفارشی طراحی شد و تاکنون تغییری نداشته است. تمام حروف این لوگو به غیر از حرف l از سه رنگ اصلی تشکیل شده است. بنابراین با توجه به رنگ بندی لوگو می توان نتیجه گرفت که این لوگو از قاعده و قانون خاصی نتیجه گیری نمی کند.
                  </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    لوگوی کوکو شنل
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo7} alt="لوگوی کوکو شنل - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        لوگوی کوکو شنل
                      </Typography>
                    </Grid>
                  </Grid>
                 
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    لوگوی <Typography component={"a"} target="_blank" href="https://www.chanel.com/us" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >شنل </Typography>
                    یکی از لوگوهای معروف و محبوب در دنیای مد و لباس می باشد. این برند توسط گابریل چنل و با همکاری آرتور کیپل افتتاح شد. نماد این لوگو که از دو حرف در هم تنیده c می باشد بر‌گرفته از حروف اول نام بنیانگذار آن یا Château de Crémat است.
                  </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    لوگوی چوپا چابز
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo8} alt="لوگوی چوپا چابز - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        لوگوی چوپا چابز
                      </Typography>
                    </Grid>
                  </Grid>
                 
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    لوگوی چوپا چابز که مربوط به شرکت آبنبات سازی اسپانیایی می باشد توسط سالوادور دالی از نقاشان مطرح اسپانیایی طراحی شده است. طرح این لوگو از گل آفتاب گردان برگرفته است. همچنین نام این کمپانی نیز تداعی گر آبنبات در ذهن مشتری می باشد. زیرا نام اسپانیایی این برند chupar در زبان انگلیسی معادل کلمه to lick به معنای لیسیدن است.
                  </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    لوگوی المپیک
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo9} alt="لوگوی المپیک - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        لوگوی المپیک
                      </Typography>
                    </Grid>
                  </Grid>
                 
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    مسابقات المپیک از سال 1912 در جهان مطرح شد. لوگوی این مسابقات به صورت پنج دایره متصل به هم می باشد که نماد پنج قاره جهان می باشد. رنگ های این حلقه ها هم از پرچم های کشور های شرکت کننده در مسابقات المپیک می باشد.
                  </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    لوگوی اینستاگرام
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo10} alt="لوگوی اینستاگرام - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        لوگوی اینستاگرام
                      </Typography>
                    </Grid>
                  </Grid>
                 
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    <Typography component={"a"} target="_blank" href="https://www.instagram.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >اینستاگرام </Typography>
                    با اینکه اپلیکیشنی نوپاست و مدت زیادی از ظهور آن نمی گذرد اما بسیار مورد توجه است. لوگوی ابتدایی این برند اولین بار در سال 2010 طراحی شد. نماد این لوگو به منظور جلب نظر عکاسان دارای رنگ قهوه ای بود. اما از آنجا که مخاطبین اینستاگرام تمام طبقات افراد دنیا شد بنابراین لوگوی این شبکه اجتماعی در سال 2016 دستخوش یک سری تغییرات شد. رنگ این لوگو به رنگ های روشن و درخشان تغییر پیدا کرد. البته به تازگی نیز تغییرات کوچکی نیز در این لوگو بوجود آمده است.
                  </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    لوگوی پپسی
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo11} alt="لوگوی پپسی - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        لوگوی پپسی
                      </Typography>
                    </Grid>
                  </Grid>
                 
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    <Typography component={"a"} target="_blank" href="https://www.pepsi.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >پپسی </Typography>
                    از برند های پیشتاز در حوزه نوشیدنی های گازدار و غیر الکلی می باشد. این نوشیدنی اولین بار در سال 1983 توسط کیلب برادهم پا به عرصه گذاشت. برادهم که تحصیلات خود را در رشته دارو سازی گذرانده بود در ابتدا یک نوشیدنی برای درمان درد معده ساخت. این نوشیدنی ترکیبی از کارامل، شکر، دانه کولا، آب، روغن لیمو و جوز هندی بود و به شدت مورد استقبال قرار گرفت. نام این نوشیدنی در ابتدا برد بود. اما به دلیل استقبال زیاد افراد نام این نوشیدنی به پپسی کولا تغییر یافت. این نام با توجه به آنزیم پپسین که در هضم پروتئین های معده کمک می کند انتخاب شد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    لوگوی پپسی از زمان پیدایش تا به الان یازده بار تغییر کرده است. لوگوی اولیه پپسی در سال 1906 رونمایی شد. بعد یکسال این لوگو دچار تغییر شد و توسط شرکت MEXICO به ثبت رسید. چهار طرح اولیه لوگوی پپسی از نام قدیمی آن یعنی پپسی کولا گرفته شده بود. آخرین بار در سال 2008 نماد این لوگو تغییر کرد. اما از آن سال تا کنون دیگر این لوگو دستخوش تغییرات نشد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: { xs: "90%", sm: "100%" } }}>
                    لوگوی مرسدس بنز
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo12} alt="لوگوی مرسدس بنز - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        لوگوی مرسدس بنز
                      </Typography>
                    </Grid>
                  </Grid>
                 
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    لوگوی <Typography component={"a"} target="_blank" href="https://www.mercedes-benz.com/en" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >بنز </Typography>
                    ترکیبی از دو شرکت موتور سازی بنز و دایلمر است. ستاره سه ضلعی داخل لوگو  نشان دهنده آب، زمین و هوا می باشد. دایره دور این ستاره نیز از نماد اولیه بنز می آید. رنگ نقره ای لوگو هم نشانه قابل اطمینان بودن، داشتن امنیت و حرفه‌ای بودن لوگو می باشد.
                  </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    لوگوی سونی
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo13} alt="لوگوی سونی - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        لوگوی سونی
                      </Typography>
                    </Grid>
                  </Grid>
                 
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    شرکت <Typography component={"a"} target="_blank" href="https://www.sony.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >سونی </Typography>
                    که در عرصه تولید لوازم خانگی الکترونیکی، سرگرمی‌ها، سخت افزارهای اینترنتی فعالیت دارد در سال 1946 تاسیس شد. این شرکت وایو می باشد. لوگوی شرکت توسط  یوسو کوروکیلوگوی استاد دیزاین ژاپنی طراحی شد.  این لوگو از دو واژه va به معنای آنالوگ و io که شبیه 1 و 0 امواج دیجیتال می باشد، تشکیل شده است.
                  </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    لوگوی آدیداس
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo14} alt="لوگوی آدیداس - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        لوگوی آدیداس
                      </Typography>
                    </Grid>
                  </Grid>
                 
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    <Typography component={"a"} target="_blank" href="https://www.adidas.com/us" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >آدیداس </Typography>
                    که از برندهای معروف ورزشی می باشد در سال 1924 در سال آلمان تاسیس شد. لوگوی آدیداس که متشکل از کلمه آدیداس و سه خط مورب می باشد. این خطوط مورب نشانگر کوه می باشد. بنابراین می توان گفت که لوگوی آدیداس به این معناست که برای گذراندن زندگی باید چالش هایی را پشت سر گذاشت.
                  </Typography>
                   <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:  "100%"  }}>
                    لوگوی بی ام دبلیو
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo15} alt="لوگوی بی ام دبلیو - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        لوگوی بی ام دبلیو
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    یکی از بهترین لوگوهای جهان لوگوی بی ام دبلیو می باشد. این لوگو در سال 1929 طراحی شد. طراحی این لوگو تاکنون تغییری نکرده است. در طراحی این لوگو از یک دایره با مربع های سفید و آبی استفاده شده است.  ترکیب  رنگهای آبی و سفید لوگو نمادی از پرچم ایالت باواریا می باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%" }}>
                    کلام آخر
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px",width:"100%",
                    }}>
                    با توجه به مطالب گفته شد می توان گفت که طراحی یک لوگوی خوب می تواند نشان دهنده حوزه کاری و جامعه هدف کسب و کار باشد. همچنین لوگوی مناسب به کسب و کار قدرت می بخشد. بنابراین ما در این مقاله از سورین به معرفی برترین لوگوهای جهان پرداختیم.  با الهام گرفتن از آن ها می توانید لوگویی طراحی کنید که در دسته لوگوهای معروف دنیا قرار گیرد.
                  </Typography>


                  {/* do not change */}
                </Grid>
                {author(articleData.author, t)}
                {tags(articleData.tags, t)}
                {linkArticle(t, url)}
                {sourceLink(sourceAddress, t)}
                {view(dataview, t)}

              </Grid>
              :
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "80%", sm: "60%", md: "70%" } }}>
                <Loader />
              </Grid>
          }
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
export default Article03;