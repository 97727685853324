import { Grid, TextField, Typography, FormControl, Select, MenuItem, Input, CircularProgress, Button, Tooltip, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import pallet from '../image/dashboard/pallet.png';
import CloseIcon from '@mui/icons-material/Close';
import InputColor from 'react-input-color';
import { baseUrl } from "./modules/general";
import axios from "axios";
import AlertMessage from "./modules/AlertMessage";
import { setCanonicalTag } from "./modules/general";
import Loader from "./Loader";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const OrderGraphicDesign = () => {
  const { t, i18n } = useTranslation();
  const [color1, setColor1] = useState("#ffffff");
  const [color2, setColor2] = useState("#ffffff");
  const [color3, setColor3] = useState("#ffffff");
  const [color4, setColor4] = useState("#ffffff");
  const [description, setDescription] = useState("");
  const [textFieldSize, setTextFiledSize] = useState(window.innerWidth <= 900 ? "small" : "medium");
  const [graphicType, setGraphicType] = useState("");
  const [dataTypeGraphic, setDataTypeGraphic] = useState(null);
  const [btnSubmit, setBtnSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ alertCheck: false, message: "", severity: "" });
  const [info, setInfo] = useState(null);

  useEffect(() => {
    document.title = "سفارش انواع طرح گرافیکی | ثبت درخواست طراحی گرافیکی | وب گستران سورین";
    document.getElementById("main-heading").innerHTML = "سفارش انواع طرح گرافیکی | ثبت درخواست طراحی گرافیکی | وب گستران سورین";
    document.getElementsByTagName('meta')["description"].content = "شما درصفحه سفارش طراحی گرافیکی هستید. در این صفحه می توانید درخواست های زیر را ثبت کنید مانند : طراحی کارت ویزیت، طراحی کاتالوگ، طراحی بروشور، طراحی پوستر، طراحی بنر، طراحی اسلایدر، طراحی لوگو، طراحی صفحات سایت، طراحی گیف،طراحی موشن گرافیک و طراحی انواع طرح گرافیکی و هر نوع گرافیکی که با نرم افزار قابلیت طراحی داشته باشد.";
    document.getElementsByTagName('meta')["keywords"].content = "سفارش انواع طراحی گرافیک | سفارش طراحی لوگو | سفارش طراحی گرافیک تبلیغاتی | طراحی کاتالوگ و کارت ویزیت | طراحی پوستر، بنر، بیلبورد | طراحی موشن گرافیک";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    setInfo(JSON.parse(localStorage.getItem('sorinInfoUser')));
  }, [])

  useEffect(() => {
    getTypeGraphic();
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => resizeTextFiled());
    return (() => window.removeEventListener('resize', () => resizeTextFiled()));
  }, [window.innerWidth]);

  const getTypeGraphic = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/tg/get`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataTypeGraphic(res.data.data)
      })
  }

  const submitInfo = () => {
    let colorExist = false;
    let color = [color1, color2, color3, color4]
    color.forEach((item) => {
      if (item !== "#ffffff") {
        return colorExist = true;
      }
    })
    color.forEach((item, index) => {
      if (item === "#ffffff") {
        color.splice(index, 1);
      }
    })
    const formData = new FormData();
    if (graphicType === "") {
      setAlert({ alertCheck: true, message: t("alert_typeGraphic"), severity: "error" })
    } else {
      formData.append("graphic_type_id", graphicType);
      if (colorExist === true) { formData.append("color", JSON.stringify(color)) };
      if (description !== "") { formData.append("description", description) };
      submitData(formData);
    }
  }

  const submitData = (data) => {
    setLoading(true)
    setBtnSubmit(true)
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/graphic/create',
      method: "POST",
      data: data,
      headers: { 'Authorization': AuthStr },
    }))
      .then(() => {
        setAlert({ alertCheck: true, message: t("alert_dataSuccess"), severity: "success" })
        setBtnSubmit(false)
        setLoading(false)
      })
      .catch(
        (error) => {
          const { status } = error.response;
          status === 500 ?
            setAlert({ alertCheck: true, message: t("AM_intervalServer"), severity: "error" }) :
            setAlert({ alertCheck: true, message: t("alert_dataFaild"), severity: "error" })
          setBtnSubmit(false)
          setLoading(false)
        }
      )
  }

  const handleGraphicType = (event) => {
    setGraphicType(event.target.value)
  };

  const resizeTextFiled = () => {
    if (window.innerWidth < 900 && textFieldSize === 'medium') {
      setTextFiledSize('small')
    }
    else if (window.innerWidth > 900 && textFieldSize === 'small') {
      setTextFiledSize('medium')
    }
  }
  const color1Handeler = (event) => {
    setColor1(event.hex)
  }
  const color2Handeler = (event) => {
    setColor2(event.hex)
  }
  const color3Handeler = (event) => {
    setColor3(event.hex)
  }
  const color4Handeler = (event) => {
    setColor4(event.hex)
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };
  return (
    info === null ?
      <Loader />
      :
      info.active === 0 ?
        <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "18px" }, margin: "20px" }}>{t("compeleteInfo")}</Typography>
        :
        <>
          <Grid sx={{ display: 'flex', width: "100%", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: 'center' }}>
            <Grid sx={{ display: 'flex', alignItems: "center", marginTop: { xs: "0", md: "120px" }, justifyContent: "space-between", flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("graphic_type_id")}</Typography>
                <Tooltip title={t("tooltipGraphicType")}>
                  <IconButton>
                    <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
                <FormControl sx={{ m: 1, width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }, }}
                  size={textFieldSize}>
                  <Select
                    value={graphicType}
                    onChange={handleGraphicType}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    sx={{ "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important" } }}
                  >
                    {
                      dataTypeGraphic !== null ?
                        dataTypeGraphic.map(element => (
                          <MenuItem key={element.id} value={element.id} className="menuItem">{element.description}</MenuItem>
                        ))
                        :
                        null
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("suggestedColors")}</Typography>
                  <Tooltip title={t("tooltipColor")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid sx={{
                  display: "flex", width: "100%", background: "#FFFFFF", height: { xs: "40px", md: "56px" }, boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}>

                  <Typography component={"label"} htmlFor="upload-color" sx={{ display: "flex", alignItems: "center", width: "100%", padding: "0 10px" }} >
                    <Grid
                      sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", "&:hover": { backgroundColor: "transparent" } }} >
                      <Grid className="inputColor1" sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                        <CloseIcon onClick={() => setColor1("#FFFFFF")} sx={{ fontSize: { xs: "15px", sm: "20px", md: "15px", lg: "20px" }, cursor: "pointer" }} />
                        <InputColor
                          initialValue={color1}
                          onChange={color1Handeler}
                          placement="right"
                          className="colorStyle"
                        />
                      </Grid>
                      <Grid className="inputColor2" sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                        <CloseIcon onClick={() => setColor2("#FFFFFF")} sx={{ fontSize: { xs: "15px", sm: "20px", md: "15px", lg: "20px" }, cursor: "pointer" }} />
                        <InputColor
                          initialValue={color2}
                          onChange={color2Handeler}
                          placement="right"
                          className="colorStyle"
                        />
                      </Grid>
                      <Grid className="inputColor3" sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                        <CloseIcon onClick={() => setColor3("#FFFFFF")} sx={{ fontSize: { xs: "15px", sm: "20px", md: "15px", lg: "20px" }, cursor: "pointer" }} />
                        <InputColor
                          initialValue={color3}
                          onChange={color3Handeler}
                          placement="right"
                          className="colorStyle"
                        />
                      </Grid>
                      <Grid className="inputColor4" sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                        <CloseIcon onClick={() => setColor4("#FFFFFF")} sx={{ fontSize: { xs: "15px", sm: "20px", md: "15px", lg: "20px" }, cursor: "pointer" }} />
                        <InputColor
                          initialValue={color4}
                          onChange={color4Handeler}
                          placement="right"
                          className="colorStyle"
                        />
                      </Grid>
                      <Typography component={"img"} alt="pallet" src={pallet} />
                    </Grid>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column", width: { xs: "95%", md: "90%" }, alignItems: 'flex-start', marginTop: { xs: "0", md: "2%" }, justifyContent: 'center', marginBottom: { xs: "5%", sm: "0" } }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("description")}</Typography>
                <Tooltip title={t("tooltipDescription")}>
                  <IconButton>
                    <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <TextField
                className="textField"
                variant='outlined'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                fullWidth
                multiline
                rows={4}
                sx={{
                  width: "100%", background: "#FFFFFF",
                  boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}
              />
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column", marginTop: "40px", width: { xs: "95%", md: "90%" }, alignItems: 'center', justifyContent: 'center' }}>
              <Button onClick={submitInfo} disabled={btnSubmit ? true : false} sx={{
                background: "#FF3951", boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", color: "#FFFFFF", fontSize: { xs: "12px", sm: "16px", md: "18px" },
                width: { xs: i18n.language === "fa" ? "100px" : "125px", sm: "159px", md: "193px" }, height: { xs: i18n.language === "fa" ? '33px' : "40px", sm: '50px', md: "60px" }, "&:hover": { backgroundColor: "#FF3951" }, textTransform: "lowercase"
              }}>
                {btnSubmit === true && loading === true ? <CircularProgress size={20} color="inherit" sx={{ color: "white" }} /> : t("submitInfo")}
              </Button>
            </Grid>
          </Grid>
          <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
        </>
  )
}

export default OrderGraphicDesign;