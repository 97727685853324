import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Tab, Tabs, Typography, Modal, Button, CircularProgress, FormControl, Select, MenuItem, InputLabel, TextField } from "@mui/material";
import axios from "axios";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { baseUrl } from "./modules/general";
import AlertMessage from "./modules/AlertMessage";
import avatar from '../image/dashboard/avatar.png';
import logo from '../image/dashboard/logo.png';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import searchIcon from '../image/icon/search.png';
import { setCanonicalTag } from "./modules/general";
import Loader from "./Loader";

let dataHead = null;
let dataSelect = [];
const OldOrder = () => {

  const { t, i18n } = useTranslation();
  const [TabSize, setTabSize] = useState(window.innerWidth <= 600 ? "vertical" : "horizontal");
  const [value, setValue] = useState("site");
  const [openModal, setOpenModal] = useState(false);
  const [btnSwitch, setBtnSwitch] = useState(false)
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ alertCheck: false, message: "", severity: "" });
  const [conditionData, setConditionData] = useState(null);
  const [condition, setCondition] = useState("");
  const [search, setSearch] = useState("");
  const [dataSearch, setDataSearch] = useState(null);
  const [loadingGrid, setLoadingGrid] = useState(false)

  const headerSite = ["number", "type", "activity", "maximum_budget", "minimum_budget"];
  const headerUI = ["number", "color", "goal", "use"];
  const headerGraphic = ["number", "color", "graphic_type_id"];
  const headerProgramming = ["number", "activity", "minimum_budget", "maximum_budget"];
  const headerApplication = ["number", "app_name", "activity"];
  const headerSeo = ["number", "site_name", "site_address", "activity"]
  const titleSite = ["maximum_budget", "minimum_budget", "domain", "seo", "host", "goal_customer", "description", "similar_sites", "special_features"]
  const titleUi = ["goal", "use", "color", "description"];
  const titleProgrammer = ["minimum_budget", "maximum_budget", "description"];
  const titleSeo = ["site_address", "keyword", "minimum_budget", "maximum_budget", "textual_cotent_number", "visual_cotent_number", "video_cotent_number", "description"];
  const titleGraphic = ["color", "description"];
  const titleApplication = ["site_name", "site_address", "maximum_budget", "minimum_budget", "goal_customer", "similar_apps", "special_features", "description", "applicationOperatingSystem"]

  useEffect(() => {
    document.title = "تاریخچه سفارشات طراحی سایت دربهترین شرکت طراحی سایت در ایران | وب گستران سورین";
    document.getElementById("main-heading").innerHTML = "تاریخچه سفارشات طراحی سایت دربهترین شرکت طراحی سایت در ایران | وب گستران سورین";
    document.getElementsByTagName('meta')["description"].content = " صفحه مربوط به تاریخچه سفارشات ثبت شده در پنل پشتیبان عالی نرم افزارهای شرکت وب گستران سورین. در این قسمت کاربرانی که سفارشات قدیمی ثبت شده نظیر : سفارش طراحی سایت، سفارش طراحی وب سایت، سفارش طراحی رابط کاربری UI، سفارش طراحی تجربه کاربری UX، را دارا هستند.";
    document.getElementsByTagName('meta')["keywords"].content = "تاریخچه سفارشات طراحی وب سایت | تاریخچه سفارشات طراحی رابط کاربری| تاریخچه سفارشات طراحی تجربه کاربری | تاریخچه سفارشات بهینه سازی سایت | تاریخچه سفارشات طراحی گرافیکی | تاریخچه سفارشات طراحی اپلیکیشن";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => resizeTab());
    return (() => window.removeEventListener('resize', () => resizeTab()));
  }, [window.innerWidth]);

  useEffect(() => {
    getCondition();
  }, [])

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const modalHandeler = (item) => {
    dataSelect = item;
    handleOpenModal();
  }
  const clickOtherLocation = (e) => {
    if (e.target.id === 'modal-status') {
      handleCloseModal();
    }
  }

  const getCondition = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/condition/get`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setConditionData(res.data.data)
      })
  }

  const resizeTab = () => {
    if (window.innerWidth < 600 && TabSize === 'horizontal') {
      setTabSize('vertical')
    }
    else if (window.innerWidth > 600 && TabSize === 'vertical') {
      setTabSize('horizontal')
    }
  }

  const searchHandeler = (value) => {
    setLoadingGrid(true)
    let url;
    url =
      value === "site" ? "/api/v1/site/search" :
        value === "UIUX" ? "/api/v1/front/search" :
          value === "Programming" ? "/api/v1/program/search" :
            value === "seo" ? "/api/v1/seo/search" :
              value === "graphic" ? "/api/v1/graphic/search" :
                value === "Application" && "/api/v1/app/search";
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}${url}`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
      params: { search: search }
    }))
      .then((res) => {
        setDataSearch(res.data.data)
        setLoadingGrid(false)
      })
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchHandeler(value);
    }
  }

  const head = () => {
    let list = [];
    dataHead = value === "site" ? headerSite : value === "UIUX" ? headerUI : value === "Programming" ? headerProgramming : value === "seo" ? headerSeo : value === "graphic" ? headerGraphic : value === "Application" && headerApplication;
    dataHead.forEach((item, index) => {
      list.push(
        <Grid key={index} sx={{ display: { xs: item === "number" ? "none" : "flex", sm: "flex" }, height: { xs: "60px", sm: "15%" }, alignItems: "center", justifyContent: "center", width: "20%" }}>
          <Typography sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px" }, color: "#070707", fontWeight: "700", textAlign: 'center', }}>{t(item)}</Typography>
        </Grid>
      )
    })

    return list;
  }

  const dataGrid = () => {
    let list = [];
    let color = null;
    if (loadingGrid === true) {
      list.push(
        <Loader key={"data1"} />
      )
    }
    else if (dataSearch === null || dataSearch.length === 0) {
      list.push(
        <Typography key={"data2"} sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707" }}>{t("notOrder")}</Typography>
      )
    }
    else if ((dataSearch !== null) && (dataHead !== null)) {
      dataSearch.forEach((item, index) => {
        color = item.hasOwnProperty("color") && item.color !== null && JSON.parse(item.color);
        list.push(
          <Grid key={item.id} onClick={() => modalHandeler(item)} sx={{
            display: 'flex', alignItems: "center", justifyContent: 'center', width: "100%", backgroundColor: parseInt(index + 1) % 2 === 0 ? "#FFFFFF" : "#EDEDED",
            border: "1px solid #757575", borderRadius: "8px", margin: { xs: "15px 5px 0 0", sm: "15px 0 0 0" }, cursor: "pointer", "&:hover": { boxShadow: " 0px 8px 3px 2px rgba(0, 0, 0, 0.25)" }
          }}>
            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-evenly", width: "100%", height: "40px", flexDirection: "row", }}>
              {dataHead.map((value, index) => (
                value === "number" ?
                  <Grid key={index} sx={{ display: { xs: value === "number" ? "none" : "flex", sm: "flex" }, alignItems: "center", justifyContent: "center", width: "20%", height: { xs: "60px", sm: "unset" } }}>
                    <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{index + 1}</Typography>
                  </Grid>
                  :
                  value === "type" ?
                    <Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "20%", height: { xs: "60px", sm: "unset" } }}>
                      <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{item.hasOwnProperty("type") && item.type !== null ? item.type.description : t("NotCompleted")}</Typography>
                    </Grid>
                    :
                    value === "siteType" ?
                      <Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "20%", height: { xs: "60px", sm: "unset" } }}>
                        <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{item.hasOwnProperty("type") && item.type !== null ? item.type.description : t("NotCompleted")}</Typography>
                      </Grid>
                      :
                      value === "activity" ?
                        <Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "20%", height: { xs: "60px", sm: "unset" } }}>
                          <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{item.hasOwnProperty("activity") && item.activity !== null ? item.activity.description : t("NotCompleted")}</Typography>
                        </Grid>
                        :
                        value === "graphic_type_id" ?
                          <Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "20%", height: { xs: "60px", sm: "unset" } }}>
                            <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{item.hasOwnProperty("type") && item.type !== null ? item.type.description : t("NotCompleted")}</Typography>
                          </Grid>
                          :
                          value === "color" ?
                            <Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "20%", height: { xs: "60px", sm: "unset" } }}>
                              {item.hasOwnProperty("color") && item.color !== null ?
                                <Grid sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                                  <Grid sx={{ backgroundColor: color[0], display: color[0] !== "#ffffff" ? "flex" : "none", padding: "10px" }}></Grid>
                                  <Grid sx={{ backgroundColor: color[1], display: color[1] !== "#ffffff" ? "flex" : "none", padding: "10px" }}></Grid>
                                  <Grid sx={{ backgroundColor: color[2], display: color[2] !== "#ffffff" ? "flex" : "none", padding: "10px" }}></Grid>
                                  <Grid sx={{ backgroundColor: color[3], display: color[3] !== "#ffffff" ? "flex" : "none", padding: "10px" }}></Grid>
                                </Grid>
                                :
                                <Typography sx={{ fontSize: { xs: "10px", sm: "16px" }, color: "#FF3951", textAlign: "center" }}>{t("NotCompleted")}</Typography>}
                            </Grid>
                            :
                            <Grid key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "20%", height: { xs: "60px", sm: "unset" } }}>
                              <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, color: "#FF3951", fontWeight: "700", textAlign: 'center', padding: { xs: "0 5px", sm: "unset" }, width: { xs: "70px", sm: "unset" } }}>{item.hasOwnProperty(value) && item[value] !== null ? item[value] : t("NotCompleted")}</Typography>
                            </Grid>
              ))}
            </Grid>
          </Grid>
        )
      })
    }
    return list;
  }

  const descCondition = (data) => {
    let findElement;
    conditionData !== null ?
      findElement = conditionData.find(element => element.id === data)
      :
      findElement = null
    return findElement !== null ? findElement.description : null;
  }

  const handleChange = (event, newValue) => {
    setDataSearch(null);
    setValue(newValue);
    search !== "" && searchHandeler(newValue)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };

  const modalFirstData = (value) => {
    let color = [];
    color = dataSelect.hasOwnProperty("color") && dataSelect.color !== null && JSON.parse(dataSelect.color);
    let list = [];
    value === "site" ?
      list.push(
        <Grid key={"data3"} sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "85%", flexDirection: "row", margin: "10px 0" }}>
          <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "18px" }, fontWeight: "700", color: "#00000" }}>{t("phoneNumber")}</Typography>
            <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "16px" }, color: "#00000", marginTop: "10px" }}>
              {dataSelect.hasOwnProperty("user") && dataSelect.user.mobile !== null ? dataSelect.user.mobile : t("NotCompleted")}
            </Typography>
          </Grid>
          <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "18px" }, fontWeight: "700", color: "#00000" }}>{t("type")}</Typography>
            <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "16px" }, color: "#00000", marginTop: "10px" }}>
              {dataSelect.hasOwnProperty("type") && dataSelect.type !== null ? dataSelect.type.description : t("NotCompleted")}
            </Typography>
          </Grid>
          <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "18px" }, fontWeight: "700", color: "#00000" }}>{t("activity")}</Typography>
            <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "16px" }, color: "#00000", marginTop: "10px" }}>
              {dataSelect.hasOwnProperty("activity") && dataSelect.activity !== null ? dataSelect.activity.description : t("NotCompleted")}
            </Typography>
          </Grid>
        </Grid>
      )
      :
      value === "UIUX" ?
        list.push(
          <Grid key={"data4"} sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "85%", flexDirection: "row", margin: "20px 0" }}>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
              <Typography sx={{ fontSize: { xs: "12px", sm: "18px", md: "20px" }, fontWeight: "700", color: "#00000" }}>{t("phoneNumber")}</Typography>
              <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "18px" }, color: "#00000" }}>
                {dataSelect.hasOwnProperty("user") && dataSelect.user.mobile !== null ? dataSelect.user.mobile : t("NotCompleted")}
              </Typography>
            </Grid>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
              <Typography sx={{ fontSize: { xs: "12px", sm: "18px", md: "20px" }, fontWeight: "700", color: "#00000" }}>{t("logo")}</Typography>
              <CloudUploadOutlinedIcon sx={{ color: "#FF3951", textShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)", margin: { xs: "0 5px", sm: "0 10px" }, fontSize: { xs: "20px", md: "24px" } }} />
              <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: "1px solid #000000", borderRadius: "4px", background: "#ffffff", width: { xs: "57px", sm: "93px" }, height: { xs: "53px", sm: "86px" }, margin: { xs: "0 5px", sm: "0 10px" } }}>
                <Grid sx={{
                  display: "flex", justifyContent: "center", alignItems: 'center', backgroundSize: "90% 90%", width: { xs: "57px", sm: "93px" }, height: { xs: "53px", sm: "86px" },
                  backgroundImage: `url(${dataSelect.hasOwnProperty("logo_path") && dataSelect.logo_path !== null ? dataSelect.logo_path : logo})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
                }}></Grid>
              </Grid>
            </Grid>
          </Grid>
        )
        :
        value === "Programming" ?
          list.push(
            <Grid key={"data5"} container sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "85%", flexDirection: "row", margin: "10px 0" }}>
              <Grid item xs={5} sm={3.5} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "18px" }, fontWeight: "700", color: "#00000" }}>{t("phoneNumber")}</Typography>
                <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "16px" }, color: "#00000", marginTop: "10px" }}>
                  {dataSelect.hasOwnProperty("user") && dataSelect.user.mobile !== null ? dataSelect.user.mobile : t("NotCompleted")}
                </Typography>
              </Grid>
              <Grid item xs={5} sm={3.5} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "18px" }, fontWeight: "700", color: "#00000" }}>{t("activity")}</Typography>
                <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "16px" }, color: "#00000", marginTop: "10px" }}>
                  {dataSelect.hasOwnProperty("activity") && dataSelect.activity !== null ? dataSelect.activity.description : t("NotCompleted")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={5} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", marginTop: { xs: "10px", sm: "unset" } }}>
                <Typography sx={{ fontSize: { xs: "12px", sm: "18px", md: "20px" }, fontWeight: "700", color: "#00000" }}>{t("documentation")}</Typography>
                <CloudUploadOutlinedIcon sx={{ color: "#FF3951", textShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)", margin: "0 10px", fontSize: { xs: "20px", md: "24px" } }} />
                <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: "1px solid #000000", borderRadius: "4px", background: "#EDEDED", width: { xs: "45px", sm: "77px", md: "93px" }, height: { xs: "41px", sm: "71px", md: "86px" }, margin: "0 10px" }}>
                  <FilePresentOutlinedIcon sx={{ fontSize: { xs: "20px", sm: "25px", md: "35px" } }} />
                </Grid>
              </Grid>
            </Grid>
          )
          :
          value === "seo" ?
            list.push(
              <Grid key={"data6"} container sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "85%", flexDirection: "row", margin: "10px 0" }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "18px" }, fontWeight: "700", color: "#00000" }}>{t("phoneNumber")}</Typography>
                  <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "16px" }, color: "#00000", marginTop: "10px" }}>
                    {dataSelect.hasOwnProperty("user") && dataSelect.user.mobile !== null ? dataSelect.user.mobile : t("NotCompleted")}
                  </Typography>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "18px" }, fontWeight: "700", color: "#00000" }}>{t("site_name")}</Typography>
                  <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "16px" }, color: "#00000", marginTop: "10px" }}>
                    {dataSelect.hasOwnProperty("site_name") && dataSelect.site_name !== null ? dataSelect.site_name : t("NotCompleted")}
                  </Typography>
                </Grid>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "18px" }, fontWeight: "700", color: "#00000" }}>{t("activity")}</Typography>
                  <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "16px" }, color: "#00000", marginTop: "10px" }}>
                    {dataSelect.hasOwnProperty("activity") && dataSelect.activity !== null ? dataSelect.activity.description : t("NotCompleted")}
                  </Typography>
                </Grid>
              </Grid>
            )
            :
            value === "graphic" ?
              list.push(
                <Grid key={"data7"} container sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "85%", flexDirection: "row", margin: "10px 0" }}>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "18px" }, fontWeight: "700", color: "#00000" }}>{t("phoneNumber")}</Typography>
                    <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "16px" }, color: "#00000", marginTop: "10px" }}>
                      {dataSelect.hasOwnProperty("user") && dataSelect.user.mobile !== null ? dataSelect.user.mobile : t("NotCompleted")}
                    </Typography>
                  </Grid>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "18px" }, fontWeight: "700", color: "#00000" }}>{t("graphic_type_id")}</Typography>
                    <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "16px" }, color: "#00000", marginTop: "10px" }}>
                      {dataSelect.hasOwnProperty("type") && dataSelect.type !== null ? dataSelect.type.description : t("NotCompleted")}
                    </Typography>
                  </Grid>
                </Grid>
              )
              :
              value === "Application" &&
              list.push(
                <Grid key={"data8"} container sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "85%", flexDirection: "row", margin: "10px 0" }}>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "18px" }, fontWeight: "700", color: "#00000" }}>{t("phoneNumber")}</Typography>
                    <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "16px" }, color: "#00000", marginTop: "10px" }}>
                      {dataSelect.hasOwnProperty("user") && dataSelect.user.mobile !== null ? dataSelect.user.mobile : t("NotCompleted")}
                    </Typography>
                  </Grid>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "18px" }, fontWeight: "700", color: "#00000" }}>{t("app_name")}</Typography>
                    <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "16px" }, color: "#00000", marginTop: "10px" }}>
                      {dataSelect.hasOwnProperty("app_name") && dataSelect.app_name !== null ? dataSelect.app_name : t("NotCompleted")}
                    </Typography>
                  </Grid>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: { xs: "12px", sm: "16px", md: "18px" }, fontWeight: "700", color: "#00000" }}>{t("activity")}</Typography>
                    <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "16px" }, color: "#00000", marginTop: "10px" }}>
                      {dataSelect.hasOwnProperty("activity") && dataSelect.activity !== null ? dataSelect.activity.description : t("NotCompleted")}
                    </Typography>
                  </Grid>
                </Grid>
              )
    return list;
  }

  const modalSecoundData = (value) => {
    let list = [];
    let color = [];
    let data = [];
    color = dataSelect.hasOwnProperty("color") && dataSelect.color !== null && JSON.parse(dataSelect.color);
    let android = dataSelect.hasOwnProperty("android") && dataSelect.android === 1 ? " Android " : "";
    let pwa = dataSelect.hasOwnProperty("pwa") && dataSelect.pwa === 1 ? " PWA " : "";
    let ios = dataSelect.hasOwnProperty("ios") && dataSelect.ios === 1 ? " ios " : "";
    let operatingSystem = android + pwa + ios

    data = value === "site" ? titleSite : value === "UIUX" ? titleUi : value === "Programming" ? titleProgrammer : value === "seo" ? titleSeo : value === "graphic" ? titleGraphic : value === "Application" && titleApplication
    list.push(
      data.map((item, index) => (
        <Grid key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: "98%", flexDirection: "row", padding: { xs: "8px 15px", sm: "10px 20px" } }}>
          <Typography sx={{ fontSize: { xs: "10px", sm: "16px", md: "18px" }, fontWeight: "700", color: "#00000" }}>{t(item)}:</Typography>
          {item === "color" ?
            dataSelect.hasOwnProperty("color") && dataSelect.color !== null ?
              <Grid sx={{ display: "flex", alignItems: "center", flexDirection: "row", padding: "0 10px" }}>
                <Grid sx={{ backgroundColor: color[0], display: color[0] !== "#ffffff" ? "flex" : "none", padding: "10px" }}></Grid>
                <Grid sx={{ backgroundColor: color[1], display: color[1] !== "#ffffff" ? "flex" : "none", padding: "10px" }}></Grid>
                <Grid sx={{ backgroundColor: color[2], display: color[2] !== "#ffffff" ? "flex" : "none", padding: "10px" }}></Grid>
                <Grid sx={{ backgroundColor: color[3], display: color[3] !== "#ffffff" ? "flex" : "none", padding: "10px" }}></Grid>
              </Grid>
              :
              <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "16px" }, color: "#00000", padding: "0 10px" }}>{t("NotCompleted")}</Typography>
            :
            <Typography sx={{ fontSize: { xs: "10px", sm: "14px", md: "16px" }, color: "#00000", padding: "0 10px" }}>
              {dataSelect.hasOwnProperty(item) && dataSelect[item] !== null ? item === "host" || item === "domain" || item === "seo" ? dataSelect[item] === 1 ? t("yes") : t("no") :
                dataSelect[item] : item === "ApplicationOperatingSystem" ? operatingSystem : t("NotCompleted")
              }
            </Typography>
          }
        </Grid>
      ))
    )
    return list;
  }

  const conditionChange = (id) => {
    let data = {
      id: id,
      side: value === "site" ? "SI" : value === "UIUX" ? "FR" : value === "Programming" ? "PR" : value === "seo" ? "SE" : value === "graphic" ? "GR" : value === "Application" && "AP",
      condition_id: condition
    }
    setBtnSwitch(true)
    setLoading(true)
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/general/changeCondition',
      method: "POST",
      data: data,
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setBtnSwitch(false)
        setLoading(false)
        setAlert({ alertCheck: true, message: t("AM_conditionChangeSuccess"), severity: "success" })
      })
      .catch((error) => {
        setAlert({ alertCheck: true, message: t("AM_conditionChangeFaild"), severity: "error" })
      })
  }

  return (
    <>
      <Grid sx={{
        display: 'flex', width: "100%", height: "100%", flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
        direction: i18n.language === "fa" ? "rtl" : 'ltr'
      }}>
        <Grid sx={{ display: 'flex', width: "100%", alignItems: 'center', justifyContent: 'center', marginTop: { xs: "0", sm: "20px", md: "30px" }, flexDirection: "column-reverse" }}>
          <TextField placeholder={t("search")}
            className="textField"
            variant='outlined'
            fullWidth
            size="small"
            value={search}
            onKeyDown={handleKeyDown}
            onChange={(e) => setSearch(e.target.value)}
            sx={{
              width: { xs: "95%", sm: "300px", md: "350px" }, display: 'flex', alignItems: "center",
              borderRadius: "8px", margin: { xs: "20px 0px 0 0px", sm: "0px 10px" }
              , "& .MuiOutlinedInput-input": { padding: { xs: "2px 0", sm: "10px 0" }, alignItems: "center" }, background: "#ffffff",
              "& .MuiOutlinedInput-root":{border: "1px solid rgba(255, 57, 81, 0.64)",boxShadow:"0px 0px 6px rgba(255, 57, 81, 0.82)"}
            }}
            InputLabelProps={{ sx: { fontSize: { xs: i18n.language === "fa" ? "12px" : "10px", md: i18n.language === "fa" ? "14px" : "12px" } } }}
            InputProps={{
              endAdornment: <Button onClick={() => searchHandeler(value)}><Typography component={"img"} alt="search" src={searchIcon} sx={{ padding: "5px" }} /></Button>,
            }}
          />
          <Grid sx={{ display: { xs: "flex", sm: "none" }, alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
            <FormControl sx={{
              width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: "20px 0 0 0",
            }}
              size="small">
              <Select
                value={value}
                onChange={(e) => setValue(e.target.value)}
                labelId="demo-select-small-label"
                id="demo-select-small"
                sx={{
                  boxShadow: " 0px 0px 3px #FF3951 !important", borderRadius: "8px", border: "none !important",
                  "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important" }
                  , "& .MuiPaper-root": { top: "297px !important", boxShadow: " 0px 0px 3px #FF3951 !important" }, "& input": { fontSize: "12px !importat" }
                }}
              >
                <MenuItem value="site" className="menuItem">{t("site")}</MenuItem>
                <Grid sx={{ background: "#CBCBCB", width: "90%", height: "1px", display: "flex", alignItems: 'center', justifyContent: 'center', margin: "0 auto" }}></Grid>
                <MenuItem value="UIUX" className="menuItem">{t("uiField")}</MenuItem>
                <Grid sx={{ background: "#CBCBCB", width: "90%", height: "1px", display: "flex", alignItems: 'center', justifyContent: 'center', margin: "0 auto" }}></Grid>
                <MenuItem value="Programming" className="menuItem">{t("OrderProgrammer")}</MenuItem>
                <Grid sx={{ background: "#CBCBCB", width: "90%", height: "1px", display: "flex", alignItems: 'center', justifyContent: 'center', margin: "0 auto" }}></Grid>
                <MenuItem value="seo" className="menuItem">{t("seoLabel")}</MenuItem>
                <Grid sx={{ background: "#CBCBCB", width: "90%", height: "1px", display: "flex", alignItems: 'center', justifyContent: 'center', margin: "0 auto" }}></Grid>
                <MenuItem value="graphic" className="menuItem">{t("Graphic")}</MenuItem>
                <Grid sx={{ background: "#CBCBCB", width: "90%", height: "1px", display: "flex", alignItems: 'center', justifyContent: 'center', margin: "0 auto" }}></Grid>
                <MenuItem value="Application" className="menuItem">{t("Application")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid sx={{ display: { xs: "none", sm: 'flex' }, width: { xs: "30%", sm: "95%" }, alignItems: 'center', justifyContent: 'center', marginTop: { xs: "0", sm: "20px", md: "50px" }, }}>
          <Tabs
            value={value}
            onChange={handleChange}
            orientation={TabSize}
            sx={{
              width: "100%", minHeight: "45px !important",
              '& .Mui-selected': { color: "#FFFFFF !important", background: "linear-gradient(0deg, #FF3951 0%, #FF3951 22%, #FF4057 22.01%, #FF6F80 53%, #FFA7B2 69%, #FFB9C2 76%, #FEB9C2 86%, #FFA7B2 100%) !important", zIndex: "400" }
              , '& .MuiTabs-indicator': { backgroundColor: "transparent" }
              , "& .MuiTab-root": {
                fontSize: { xs: "11px", sm: "12px", md: "12px", lg: "14px", xl: "18px" }, fontWeight: "700", width: { xs: "40px", sm: "18%" }, color: "#757575",
                background: " linear-gradient(90deg, #D1D1D1 0%, #A8A8A8 100%)", borderRadius: { sm: "35px 35px 0 0", lg: "100px 100px 0 0" }, border: "1px solid #CCCCCC"
              }
            }}
            aria-label="visible arrows tabs example"
          >
            <Tab value="site" label={t("site")} sx={{ position: "absolute", right: "0px", zIndex: "300" }} />
            <Tab value="UIUX" label={t("uiField")} sx={{ position: "absolute", right: "16%", zIndex: "250" }} />
            <Tab value="Programming" label={t("Programming")} sx={{ position: "absolute", right: "33%", zIndex: "200" }} />
            <Tab value="seo" label={t("seoLabel")} sx={{ position: "absolute", right: "50%", zIndex: "150" }} />
            <Tab value="graphic" label={t("Graphic")} sx={{ position: "absolute", right: "66%", zIndex: "100" }} />
            ‍<Tab value="Application" label={t("Application")} sx={{ position: "absolute", right: "82%", zIndex: "50" }} />
          </Tabs>
        </Grid>
        <Grid sx={{
          display: 'flex', alignItems: "center", justifyContent: 'center', width: "95%", height: { xs: "43px", sm: "69px" }, backgroundColor: "#FFFFFF",
          border: "1px solid #CBCBCB", borderRadius: { xs: "8px", sm: "0 0 8px 8px" }, margin: { xs: "15px 0 0 5px", sm: "0" }
        }}>
          <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-evenly", width: "100%", flexDirection: "row", overflow: { xs: "scroll", sm: "unset" } }}>
            {head()}
          </Grid>
        </Grid>
        <Grid sx={{
          display: 'flex', alignItems: "center", justifyContent: "space-evenly", width: "95%", height: "100%", margin: { xs: "5px 0 0 5px", sm: "15px 0 0 0" }
          , flexDirection: "column"
        }}>
          {dataGrid()}
        </Grid>
      </Grid >
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid id="modal-status" onClick={(e) => clickOtherLocation(e)} sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh", backgroundColor: 'rgba(105,99,99,0.6)', direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
          <Grid sx={{
            display: "flex", alignItems: "center", justifyContent: "space-evenly", flexDirection: 'column', background: "#FFFFFF", width: { xs: "95%", md: "876px" },
            borderRadius: "16px", padding: { xs: "25px 0", md: "30px 0" }, maxHeight: "90%", overflow: "scroll"
          }}>
            <Grid sx={{ display: "flex", alignItems: "center", backgroundColor: "#EDEDED", width: { xs: "95%", md: "785px" }, borderRadius: "16px", flexDirection: "column" }}>
              <Grid container sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "90%", padding: "15px 0" }}>
                <Grid item xs={6} sm={3} sx={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "flex-start", minWidth: { xs: "50%", sm: "33%" } }}>
                  <Grid
                    sx={{
                      width: { xs: "47px", sm: "75px", md: "90px" }, height: { xs: "47px", sm: "75px", md: "90px" }, margin: { xs: "0 3px", sm: "0 10px" }, borderRadius: "50px"
                      , backgroundPosition: "center", backgroundSize: "115% 115%", backgroundRepeat: "repeat", backgroundImage: `url(${dataSelect.hasOwnProperty("user") && dataSelect.user.picture_path !== null ? dataSelect.user.picture_path : avatar})`
                    }} ></Grid>
                  <Grid sx={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
                    <Typography sx={{ fontSize: { xs: "10px", sm: "16px", md: "18px" }, margin: { xs: "0 3px", sm: "0 10px" }, fontWeight: "700", color: "#070707" }}>{dataSelect.hasOwnProperty("user") && dataSelect.user.name !== null && dataSelect.user.lastname !== null ? `${dataSelect.user.name} ${dataSelect.user.lastname}` : t("NotCompleted")}</Typography>
                    <Typography sx={{ fontSize: { xs: "8px", sm: "12px", md: "14px" }, color: "#070707", margin: { xs: "0 3px", sm: "0 10px" } }}>{dataSelect.user_type_id === "1" ? t("organizationPanel") : t("personalPanel")}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: "center", miWidth: { xs: "50%", sm: "33%" } }}>
                  <Typography sx={{ fontSize: { xs: "10px", sm: "16px", md: "18px" }, fontWeight: '700' }}>
                    {condition !== "" ? descCondition(condition) : dataSelect.hasOwnProperty("condition") && dataSelect.condition !== null ? dataSelect.condition.description : t("NotCompleted")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: "center", sm: "flex-end" }, minWidth: { xs: "100%", sm: "33%" } }}>
                  <FormControl sx={{
                    width: { xs: "146px", sm: "130px", md: "160px" }, background: "#FFFFFF", borderRadius: "8px", boxShadow: "0px 0px 3px #FF3951", border: "none !important", minHeight: { xs: "28px", sm: "40px !important" },
                    "& .MuiOutlinedInput-input": { padding: "0px !important", border: "none !important", color: "#FF3951 !important", textAlign: "center", fontSize: { xs: "10px !important", sm: "12px !important", md: "14px !important" } },
                    "& input": { borderColor: "none !important" },
                  }}
                    size="small">
                    <InputLabel sx={{
                      "& .Mui-focused": { color: "#FF3951 !important" }, textAlign: "right", fontSize: { xs: "10px !important", sm: "12px !important", md: "14px !important" }, color: "#FF3951", right: i18n.language === "fa" ? "25px !important" : "unset !important",
                      left: i18n.language === "fa" ? "unset !important" : "7px !important",
                    }}>{t("OrderStatus")}</InputLabel>
                    <Select
                      value={condition}
                      onChange={(event) => setCondition(event.target.value)}
                      sx={{
                        height: { xs: "28px", sm: "40px !important" },
                        "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important", color: "#FF3951" }
                        , boxShadow: 'boxShadow: "0px 0px 3px #FF3951"', '.MuiOutlinedInput-notchedOutline': { border: "0 !important" },
                      }}
                    >
                      {
                        conditionData !== null ?
                          conditionData.map(element => (
                            <MenuItem key={element.id} value={element.id} className="menuItem" sx={{ fontSize: { xs: "10px !important", sm: "12px !important", md: "14px !important" }, alignItems: "center !important", }}>
                              {element.description}
                            </MenuItem>

                          ))
                          :
                          null
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid sx={{ width: "90%", display: "flex", height: '1px', backgroundColor: "#CBCBCB" }}></Grid>
              {modalFirstData(value)}
              {modalSecoundData(value)}
            </Grid>


            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: 'row', width: "100%", marginTop: "20px" }}>
              <Button onClick={() => conditionChange(dataSelect.id)} disabled={btnSwitch ? true : false} sx={{
                width: { xs: "100px", sm: "110px", md: "200px" }, height: { xs: "33px", sm: "40px", md: "60px" }, boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", fontSize: { xs: "14px", sm: "16px" },
                background: "#FF3951", color: "#FFFFFF", "&:hover": { background: "#FF3951" }, margin: "0 10px"
              }}>
                {btnSwitch === true && loading === true ? <CircularProgress size={20} color="inherit" sx={{ color: "#ffffff" }} /> : t("switching")}
              </Button>
              <Button onClick={handleCloseModal} sx={{
                width: { xs: "100px", sm: "110px", md: "200px" }, height: { xs: "33px", sm: "40px", md: "60px" }, boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", fontSize: { xs: "14px", sm: "16px" }, border: "2px solid #FF3951",
                background: "#FFFFFF", color: "#FF3951", "&:hover": { background: "#FFFFFFF" }, margin: "0 10px"
              }}>
                {t("cancel")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal >
      <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
    </>
  )
}

export default OldOrder;