import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import { author, date, h1, headPhoto, largePhoto, linkArticle, rootArticle, tags, paragraph, timeRead, view, listItemCircle, h2, h4, smallPhoto, sourceLink } from "../articleFunction/ArticleFunction";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { Articles } from "./Articles";
import ArticleSide from "../articleFunction/ArticleSide";
import { NavLink } from "react-router-dom";
//import photo
import photo1 from '../../image/articles/09/photo1.jpg';
import photo2 from '../../image/articles/09/photo2.jpg';
import photo3 from '../../image/articles/09/photo3.jpg';
import photo4 from '../../image/articles/09/photo4.jpg';
import photo5 from '../../image/articles/09/photo5.jpg';
import photo6 from '../../image/articles/09/photo6.jpg';
import photo7 from '../../image/articles/09/photo7.jpg';

import Loader from "../Loader";
import PortfolioButton from "../PortfolioButton";


let articleData = null;
const Article09 = () => {

  //NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${articleData !== null && articleData.title}`
    document.getElementById("main-heading").innerHTML = articleData !== null && articleData.h1;
    document.getElementsByTagName('meta')["description"].content = articleData !== null && articleData.desc;
    document.getElementsByTagName('meta')["keywords"].content = articleData !== null && articleData.keywords;
    document.getElementsByTagName('meta')["author"].content = articleData !== null && articleData.author;
    setCanonicalTag()
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item
    })
  }, [])

  useEffect(() => {
    articleData !== null && addView(); getView();
  }, [])

  const addView = () => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v1/article/addView",
      method: "POST",
      data: { id: articleData.id }
    }))
      .then((res) => { })
  }
  const getView = () => {
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/article/getView`,
      method: "GET",
      params: { id: articleData.id }
    }))
      .then((res) => {
        setDataView(res.data.data)
      })
  }

  //change This part

  //read More
  const readMore = [
    {
      id: 1,
      title: "سئو و تمام نکاتی که باید در آن رعایت کرد ",
      link: "/what-is-seo"
    },
    {
      id: 2,
      title: "اصطلاحات پرکاربرد در وب و سایت ",
      link: "/common-words-in-web-design"
    }, 
    {
        id: 3,
        title: "صفر تا صد راه اندازی پیج حرفه ای کاری اینستاگرام",
        link: "/instragram-business-page"
      },

  ]
  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: "rtl", alignItems: "center" }}>
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid container sx={{ display: "flex", alignItems: { xs: "unset", sm: 'flex-start' }, justifyContent: "space-between", flexDirection: "row", flexWrap: "wrap", width: { xs: "100%", sm: "85%" } }}>
          <ArticleSide tags={articleData !== null && articleData.tags} readMore={readMore} />
          {
            articleData !== null ?
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: 'center', sm: "flex-end" }, flexDirection: "column", width: { xs: "100%", sm: "60%", md: "70%" } }} >
                {/* do not change */}
                {headPhoto(articleData.image)}
                {h1(articleData.h1, t)}
                {timeRead(articleData.timeRead, t)}
                {date(articleData.date, i18n)}
                <Grid sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "column" }}>
                {/* The text of the article */}
                <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    خیلی از کسانی که کسب و کار جدیدی در محیط اینترنت راه اندازی می کنند مدام درگیر این موضوع هستند که چگونه می توانیم رشد کنیم؟ یا به چه روشی می توانیم سایتی که داریم را در صفحه اول گوگل مشاهده کنیم؟ اصلا سئو چیست و چرا باید سایت را سئو کنیم؟ آیا یک بار سئو را انجام می دهیم و دیگر به ادامه این کار نیازی نیست؟ آیا هزینه کردن در سئو باعث افزایش فروش می شود یا خیر؟ چرا باید برای سئو هزینه کنیم؟ بدون هزینه در سئو هم در رقابت گوگل موفق می شویم یا خیر؟ این ها و ده ها سوال دیگر از جمله سوالاتی هستند که ذهن خیلی از افراد را به خود مشغول کرده است.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                     ما در این مقاله از آکادمی  <NavLink to="/Academy" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >سورین </NavLink> سعی داریم تا به تمامی این سوالات پاسخ دهیم و شما را با مفهوم و روش های  <NavLink to="/Academy/Seo" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >سئو </NavLink> آشنا کنیم. 
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    مقدمه
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: "100%", height: { xs: "170px", sm: "245px", md: "258px", lg: "460px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo1} alt="سئو سایت - سورین " sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        سئو سایت
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                   اگر به تازگی کلمه سئو را شنیدید و هیچ آشنایی با این مفهوم ندارید، این مطلب از <NavLink to="/" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >سورین </NavLink>
                   به شما کمک می کند تا به درک کاملی از مفهوم سئوی سایت و اهمیت سئو برسید.  مطالعه کامل این راهنما به شما کمک می کند تا با مهمترین نکات برای بهینه سازی سایت و افزایش رتبه در گوگل، آشنا شوید. پس با ما همراه شوید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                   سئو سایت به زبان ساده
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    SEO یا سئو مخفف کلمه <Typography component={"strong"} sx={{ fontWeight: "700" }}>Search Engine Optimization </Typography> می باشد. <Typography component={"strong"} sx={{ fontWeight: "700" }}>سئو سایت </Typography>، به تمامی کارهایی می گویند که سبب می شود سایت شما در <Typography component={"a"} target="_blank" href="https://www.google.com" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >گوگل </Typography>
                    شناخته شود. این موضوع تاثیر بسزایی در افزایش بازدید و بالا رفتن درآمد شما از سایت دارد. با استفاده از <Typography component={"strong"} sx={{ fontWeight: "700" }}>تکنیک های سئو </Typography> شما می توانید سایت خود را به افرادی که به دنبال خرید محصولات سایت شما هستند معرفی کنید و خیلی از این اشخاص را به مشتری تبدیل کنید. در واقع هیچ روش بازاریابی اینترنتی دیگری تا این اندازه موثر نخواهد بود. نکته قابل توجه در بهینه سازی سایت اینجا می باشد که شما بدون پرداخت هزینه های بالای تبلیغات، قادرید که این کار را انجام دهید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    سئو چگونه کار می کند؟
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    از آنجا که هدف اصلی تمامی موتورهای جستجوگر نمایش مناسب ترین نتایج برای کاربران است، بنابراین با رعایت اصول سئو باید به موتورهای جستجو فهماند که محتوای شما بهترین نتیجه برای موضوع جستجو شده توسط کاربر است. چناچه هدف شما این است که ترافیک زیادی روی سایت خود ایجاد کنید باید با الگوریتم‌های رتبه‌بندی در موتور جستجوی گوگل آشنا باشید. 
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    اهمیت و سرمایه گذاری روی سئوی سایت
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: "100%", height: { xs: "170px", sm: "245px", md: "258px", lg: "460px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo2} alt=" اهمیت سئو - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        اهمیت سئو
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    سئو را می توان روشی نوین و موثر در دنیای <NavLink to="/What-is-Digital-Marketing" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >دیجیتال مارکتینگ </NavLink>
                    دانست. با توجه به استفاده هر روز مردم از وب برای یافتن پاسخ سوالات یا اطلاعات محصولات و خدمات، بیش از میلیاردها کلیدواژه (کلمات مربتط با کسب و کار شما) در موتورهای جستجوگر وارد می‌شود. بنابراین بهتر دیده‌شدن در فضای دیجیتال و افزایش رتبه در گوگل، نقش موثری در جذب مشتریان بالقوه و افزایش گردش مالی کسب‌وکار دارد. زیرا اکثریت تنها در صفحه اول گوگل بدنبال نتیجه جستجوی مورد نظر خود هستند. یکی از بهترین و پربازخورد ترین نوع تبلیغات می باشد که همزمان با نمایش محصول و خدمات شما، اعتبار و رتبه شما نیز در سایت گوگل ارتقا می یابد که این گنجینه ای نامحدود برای درآمدزایی برای شما می باشد.
                    </Typography>    
                    <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    سئو بهتر است یا تبلیغات در گوگل؟
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                   برای تبلیغ در گوگل شما باید هزینه ای را به گوگل پرداخت نمایید تا لینک سایت شما را در صفحات جستجوی گوگل نمایش دهد. به ازای هر بار کلیک مبلغ مشخصی از اعتبار شما کسر می شود. این روش تبلیغاتی را، گوگل ادز می‌نامند. با بالا رفتن تعداد کلیک‌ها اعتبار حساب شما نیز به اتمام می رسد در نتیجه این فرایند متوقف می گردد. هم چنین به علت تحریم های مالی امکان پرداخت مستقیم وجود ندارد و باید از طریق شرکتهای واسط اقدام نمایید.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                   نکته ای که در اینجا مهم است هر چه تعداد افرادی که قصد نمایش لینک سایتشان برای یک کلمه یکسان افزایش یابد قیمت هر کلیک نیز افزایش (PPC) پیدا خواهد کرد. بنابراین مبلغ پرداختی شما به گوگل نیز افزایش می یابد. با توجه به مطالبی که بیان شد می توان گفت هزینه به ازای هر کلیک در گوگل ادز، بصورت مزایده‌ای تعیین می‌شود. ولی با صرف هزینه کمتر برای سئو می توانید سایت مورد نظر خود را همیشه در صفحه اول گوگل قرار دهید. در نظر داشته باشید برای بهترین بازخورد باید انواع مختلف تبلیغات را استفاده و تست کرد اما سئو یکی از بهترین، موثرترین و ماندگارترین نوع تبلیغات محسوب می شود.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    با توجه به مقایسه کوتاهی که بین سئو و تبلیغات گوگل داشتیم می توان مزایای زیر را برای سئو وب سایت در نظر گرفت :
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    ترافیک رایگان در سئو
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: "100%", height: { xs: "170px", sm: "245px", md: "258px", lg: "460px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo4} alt="ترافیک در سئو - سورین " sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                       ترافیک در سئو
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    بر خلاف گوگل ادز شما با سرمایه گذاری روی سئو بابت هر کلیک نیازی نیست که به گوگل هزینه ای پرداخت کنید. می توان گفت که جایگاه شما در گوگل به تعداد کلیک و تعداد افرادی که روی لینک شما کلیک می‌کنند وابسته نیست (البته که محتوای مناسب باعث ماندگاری کابر می شود که این از دید گوگل پنهان نمی ماند). تنها سئو اصولی باعث افزایش رتبه شما می شود که طبعا کلیک، ورودی و کاربران سایت شما هم افزایش می یابد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                   ترافیک مستمر ناشی از سئو
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    ترافیک ناشی از سئو مستمر و یکنواخت می باشد در صورتیکه تبلیغات گوگل با اتمام شارژ حساب شما نمایش سایت در صفحه نتایج جستجو متوقف می‌ گردد. اما با توقف سرمایه گذاری در سئو رتبه سایت دچار تغییرات ناگهانی نمی شود. سئو یعنی <Typography component={"a"} target="_blank" href="https://homeinja.com/HomeMag" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >تولید محتوا </Typography>
                    اصولی و صحیح به صورت مستمر و پیوسته که باعث افزایش رتبه در موتورهای جستجوگر شوند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                   عدم تمایل کاربران برای کلیک روی لینک های تبلیغاتی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    علامت (<Typography component={"a"} target="_blank" href="https://ads.google.com/home" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >Ad</Typography>
                    ) در کنار لینک ها به معنای تبلیغاتی بودن لینک مورد نظر می باشد. آمار نشان می دهد که بسیاری از کاربران روی لینک‌های تبلیغاتی کلیک نمی کنند چون به نتایج ناشی از تبلیغات اعتماد نمی کنند. 
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    معایب سئو
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    با تمام مزایایی که برای سئو در زمینه یک سایت برشمردیم اما دارای معایبی نیز می باشد که در زیر به آن ها می پردازیم.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    زمان زیاد برای رسیدن به نتیجه
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    کسب رتبه ی بالا در گوگل در مدت زمان کوتاه امکانپذیر نیست. اگر شما با ترفندهایی در زمان کوتاه سایت را به رتبه های بالاتر ارتقا دهید با همان سرعت هم در صفحات آخر خواهید افتاد چون الگوهای گوگل تمام ترفندها را به خوبی می دانند و جریمه های سنگین برای سایت شما در نظر می گیرند.<Typography component={"a"} target="_blank" href="https://support.google.com/programmable-search/answer/4513925?hl=en" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >ایندکس </Typography>
                    کردن صفحات توسط گوگل فرآیندی طولانی می باشد و برای رسیدن به رتبه ی  بالا هفته‌ها یا ماه‌ها زمان نیاز دارید. با وجود اینکه سرمایه‌گذاری در سئو با ارزش است اما اگر افزایش سریع میزان بازدید از سایت هدف شما می باشد بهتر است دیگر روش‌های بازاریابی دیجیتال مانند گوگل ادز را نیز بکار ببرید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    تغییرات الگوریتم های گوگل
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: "100%", height: { xs: "170px", sm: "245px", md: "258px", lg: "460px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo5} alt="الگوریتم های گوگل - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        الگوریتم های گوگل
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                     الگوریتم یا الگوهای رتبه بندی گوگل به منظور نمایش بهتر نتایج به کاربران دائما در حال تغییر هستند. بروز رسانی اطلاعات درباره آخرین تغییرات الگوریتم‌های گوگل امری ضروری در حفظ رتبه‌های برتر برای سایت‌ می باشد. این آپدیت اطلاعات ممکن است برای بسیاری از صاحبان کسب و کار مقدور نباشد. بنابر این نیاز هست تا کار سئو سایت را به افراد متخصص در این حوزه بسپارید.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    توجه داشته باشید تمام اقدامات لازم یک کسب و کار را قادر نخواهید بود که تنهایی به انجام رسانید. شما مسئول مدیریت خدمات و محصولات خود هستید و باید قسمت تبلیغات و یا سئو را به شرکت متخصص و با سابقه واگذار نمایید تا با کمترین هزینه، بهترین نتیجه را حاصل شود.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                   عدم تضمین برای رسیدن به رتبه برتر گوگل
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    با توجه به استفاده گوگل از بیش از ۲۰۰ فاکتور برای رتبه بندی سایت‌ها و تغییر مدام الگوریتم‌های گوگل می توان به صراحت گفت که تضمینی برای کسب رتبه برتر در گوگل وجود ندارد. یعنی اگر شرکت یا شخصی به شما ضمانت قرارگیری در رتبه اول گوگل را داد همکاری خود را فورا قطع کنید. 
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    کسانیکه به صورت حرفه ای در کار سئو هستند می دانند ضمانتی برای قرارگیری وجود ندارد. بر روی هر کلمه رقابتی جداگانه وجود دارد و شما باید برای تمام کلمات کلیدی کسب و کار خود همیشه در رقابت باقی بمانید و برای افزایش رتبه تولید محتوای مناسب داشته باشید. تنها ضمانت، سئو پیوسته و اصولی در طی زمان است. همچنین خود گوگل در مورد تضمین ارائه خدمات سئوی شرکت‌ها هشدار می‌دهد. بنابراین نباید به شرکت هایی که به شما تضمین می‌دهند که در تعدادی از کلمات کلیدی به صفحه اول گوگل یا حتی رتبه اول گوگل می رسید و در صورتی که این اتفاق رخ ندهد، هزینه شما را  پس می دهند، اعتماد کرد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    اصطلاحات کلیدی در سئو
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    سئو به عنوان حوزه کاری دارای یکسری اصطلاحات مهم می باشد که یادگیری آن ها خالی از لطف نیست.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    سئو داخلی یا  On-page SEO
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: "100%", height: { xs: "170px", sm: "245px", md: "258px", lg: "460px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo6} alt="سئو داخلی - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        سئو داخلی
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    تمام فرآیندی که روی خود سایت انجام می شود سئو داخلی نام دارد. تولید محتوای مفید، افزایش سرعت سایت، طراحی ساختار اطلاعاتی مناسب، ایجاد لینک‌های داخلی و بهبود تجربه کاربری سایت در دسته سئو داخلی قرار دارند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    سئو خارجی یا Off-page SEO
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                   سئو خارجی دریافت لینک از سایت های دیگر به منظور نمایش اعتبار و ارزش سایت به موتورهای جستجو می باشد. زمانیکه لینک سایت شما در سایت‌های دیگر باشد برای گوگل نشان دهنده اعتبار بیشتر است. از جمله این اقدامات می توان به بک لینک و رپرتاژ اشاره کرد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    سئو فنی یا Technical SEO
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    این سئو زیر مجموعه ای از سئو داخلی می باشد. سئو تکنیکال به مجموع فرایندی می گویند که عملیات خیزش و ایندکس کردن صفحات سایت را توسط گوگل آسان می کند. ساخت نقشه سایت، افزایش سرعت لود صفحات سایت، کم کردن صفحات تکراری و سازگاری سایت با تمامی دستگاهها و امنیت سایت از الزامات اولیه در سئو تکنیکال می باشند. این بخش از سئو ربطی به محتوا ندارد و به بهینه سازی و بهبود زیر ساخت وب‌سایت مرتبط می شود. با سپردن سئو تکنیکال سایت خود به شرکت سورین خیالتون از سئو راحت خواهد شد و به بهترین شکل سایت خود را سئو شده دریافت کنید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    سئوی کلاه سفید یا White Hat SEO و سئوی کلاه سیاه یا Black Hat SEO
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: "100%", height: { xs: "170px", sm: "245px", md: "258px", lg: "460px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo7} alt="سئو کلاه سفید و سیاه - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        سئو کلاه سفید و کلاه سیاه
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    این دو اصطلاح از نقش آدم‌های خوب و بد در فیلم‌های وسترن سرچشمه می گیرد. سئو کلاه سفید اشاره به تکنیک‌های قانونی سئو و سئو کلاه سیاه اشاره به روش‌های غیر قانونی در سئو دارد. در سئو کلاه سیاه این امکان وجود دارد که در مدت زمان کوتاهی رتبه سایت در گوگل افزایش یابد. این امر ممکن است در کوتاه مدت باعث افزایش رتبه سایت در گوگل شود. اما در بلند مدت کاهش رتبه ناگهانی سایت و جریمه و یا حتی حذف تمام صفحات سایت را از نتایج گوگل در پی داشته باشد. 
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    مثلا خرید لینک انبوه از سایت‌های دیگر (بک لینک) با هدف بهبود سئوی خارجی و دستکاری رتبه در گوگل از تکنیک های سئوی کلاه سیاه می باشد که منجر به پنالتی ( جریمه) سایت می شود. دقیقا اقداماتی که شرکت های سئو کار تضمینی انجام می دهند که در مدت کوتاه شما را به رتبه بالا ارتقا می دهند اما از سئو کلاه سیاه استفاده کرده اند. خاطر جمع باشید که الگوریتم گوگل این اقدامات را شناسایی می کند و سایت شما با جرایم سنگین رو به رو می شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    جمع بندی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    در این مقاله سعی کردیم تا شما را با مفاهیم اولیه و کلی سئو وب سایت و فاکتورهای مهم برای دریافت بهترین نتیجه در گوگل آشنا کنیم. پس برای اینکه سرمایه گذاری در سئو با موفقیت انجام شود باید محتوای ارزشمندی در اختیار مخاطبان قرار دهید که با موتورهای جستجو سازگار باشند و بر اساس الگوریتم های گوگل پیاده سازی شده باشد. عدم توجه و رعایت هر کدام از مطالبی که بیان شد منجر به نتایج ناامید کننده سئو سایت شما می شود.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    درباره بهترین <NavLink to="/AboutSorin" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >شرکت </NavLink>
                    سئوکار در تهران بیشتر بدانید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                   پرسش‌های متداول درباره سئو
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    در این قسمت به بعضی از سوالات کاربران در مورد سئو پاسخ می دهیم.  
                    </Typography>
                    <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    هزینه خدمات سئو چگونه تعیین می شود؟
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                    سئو سایت دارای یک قیمت مشخص و از پیش تعیین شده نیست. عوامل متعدد روی سئو تاثیر گذار هستند. بررسی وضعیت سایت از نظر فنی و طراحی، میزان رقابت در حوزه مربوطه و کلمات کلیدی در ارزیابی قیمت و زمان لازم برای سئوی یک سایت موثر می باشند. کارشناسان سئو پس از بررسی این بخش ها زمان و هزینه لازم برای سئوی سایت را تخمین می زنند. متخصصین این حوزه با توجه به تجربه و کیفیت خدماتشان، تعرفه‌های مختلفی را برای خدمات سئو به کارفرمایان ارائه می دهند. شرکت های حرفه ای مثل وب گستران سورین بهترین برنامه را بر اساس بودجه شما ارائه می دهد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    برای رسیدن به رتبه یک گوگل چقدر زمان لازم است؟
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                   عوامل متعددی در کسب رتبه یک گوگل دخیل می باشد. زمان رسیدن به این نتیجه، بستگی به وضعیت سایت و رقابت در آن شاخه از کسب و کار و بودجه ماهیانه شما دارد. ممکن است در یک حوزه که رقابت کم است زمان مورد نیاز ۳ ماه توسط کارشناسان برآورد شود در صورتی که در یک حوزه با رقابت بیشتر یکسال وقت و انرژی نیاز باشد. 
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    سئو برای سایت موثرتر است یا تبلیغات گوگل؟
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                      در تبلیغات گوگل با اتمام شارژ حساب، سایت شما از صفحه نتایج جستجوی گوگل پاک می شود. از طرفی طبق گزارشات بسیاری از افراد روی تبلیغات کلیک نمی‌کنند. در صورتی که در سئو چنانچه سرمایه گذاری روی سئو را متوقف کنید، نمایش سایت شما در گوگل همچنان برقرار می باشد و ترافیک ورودی سایت پایدار می ماند. 
                      </Typography>
                      <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "justify" }}>
                    چگونه می‌توانم رتبه سایتم را درگوگل ارتقا دهم؟
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" },lineHeight:"35px"
                    }}>
                   بررسی یک سایت از لحاظ فنی، سرعت بارگذاری، ساختار اطلاعاتی مناسب و نحوه دسته بندی صفحات از عوامل تاثیر گذار در افزایش رتبه سایت می باشد. با رعایت این موارد و استخراج کلمات کلیدی مهم می توان محتوای موثر و سئو محور تولید کرد. از دیگر عوامل موثر در ارتقای رتبه سایت در گوگل ایجاد بک لینک‌های مناسب برای سایت می باشد. 
                  </Typography>


                 {/* do not change */}

               {author(articleData.author, t)}
                  {tags(articleData.tags, t)}
                  {linkArticle(t, url)}
                  {sourceLink(articleData.source, t)}
                  {view(dataview, t)}
                </Grid>
              </Grid>
              :
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "80%", sm: "60%", md: "70%" } }}>
                <Loader />
              </Grid>
          }
        </Grid>
      </Grid>
      <PortfolioButton />
      <Footer />
    </>
  );
}
export default Article09;