import React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertMessage({open , message, duration, close , severity}) {
  return (
    <Stack spacing={2} sx={{ width: '100%' , ".MuiSnackbar-root":{direction:"ltr" , textAlign:"center"}	}}>
      <Snackbar open={open} autoHideDuration={duration} onClose={close} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
        <Alert onClose={close} severity={severity} sx={{ width: '100%' }}>
         {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}