import { Grid, Typography,Button } from "@mui/material";
import React,{useEffect} from "react";
import { useTranslation } from "react-i18next";
import fa404 from '../image/notFound/fa404.png';
import en404 from '../image/notFound/en404.png';
import { NavLink } from "react-router-dom";
import { setCanonicalTag } from "./modules/general";

const NotFound = () => {
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    document.title = "صفحه مورد نظر در بهترین سایت طراحی سایت در ایران یافت نشد | وب گستران سورین";
    document.getElementById("main-heading").innerHTML ="صفحه مورد نظر در بهترین سایت طراحی سایت در ایران یافت نشد | وب گستران سورین";
    document.getElementsByTagName('meta')["description"].content = "صفحه درخواستی شما در سایت وب گستران سورین وجود ندارد لطفا به صفحه اصلی بازگردید. شما در حال جستجو در یهترین سایت طراحی نرم افزار در ایران هستید.";
    document.getElementsByTagName('meta')["keywords"].content = "صفحه درخواستی وجود ندارد | صفحه درخواستی از سایت حذف شده است | دسترسی به صفحه مورد نظر وجود ندارد.";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  return (
    <>
      <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center",flexDirection:"column", width: "100%", padding: "0px 0 0 0" }}>
        <Grid sx={{
          display: "flex", alignItems: "center", justifyContent: "center", width: "100%"
        }}>
          <Typography component={"img"} alt="notFound" src={i18n.language === "fa" ? fa404 : en404} sx={{ width: { xs: "100%", sm: "85%", md: "70%" } }} />
        </Grid>
        <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", marginTop: {xs:"50px",md:"50px"} }}>
            <Button sx={{ display: "flex", bgcolor: "#FF3951", width: { xs: "158px", sm: "300px" }, height: { xs: "40px", sm: "50px" }, boxShadow: "#FF3951 0px 5px 15px", margin: "20px auto", "&:hover": { backgroundColor: "#FF3951" } }}>
              <NavLink to={"/"} style={{ textDecoration: "none" }}>
                <Typography component={"p"} sx={{ color: "white", fontSize: { xs: "12px", sm: "14px", md: "14px" } }}>{t("mainPage")}</Typography>
              </NavLink>
            </Button>
          </Grid>
      </Grid>
    </>
  )
}
export default NotFound;