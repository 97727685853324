import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import {
  author,
  date,
  h1,
  headPhoto,
  largePhoto,
  linkArticle,
  rootArticle,
  tags,
  paragraph,
  timeRead,
  view,
  listItemCircle,
  h2,
  h4,
  smallPhoto,
  sourceLink,
} from "../articleFunction/ArticleFunction";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { Articles } from "./Articles";
import ArticleSide from "../articleFunction/ArticleSide";
import { NavLink } from "react-router-dom";
//import photo
import photo1 from "../../image/articles/17/photo1.png";
import photo2 from "../../image/articles/17/photo2.jpg";
import photo3 from "../../image/articles/17/photo3.jpg";
import photo4 from "../../image/articles/17/photo4.png";
import photo5 from "../../image/articles/17/photo5.jpg";
import photo6 from "../../image/articles/17/photo6.jpg";
import photo7 from "../../image/articles/17/photo7.jpg";
import photo8 from "../../image/articles/17/photo8.jpg";
import photo9 from "../../image/articles/17/photo9.jpg";
import photo10 from "../../image/articles/17/photo10.jpg";
import photo11 from "../../image/articles/17/photo11.jpg";

import Loader from "../Loader";
import PortfolioButton from "../PortfolioButton";

let articleData = null;
const Article17 = () => {
  //NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${
      articleData !== null && articleData.title
    }`;
    document.getElementById("main-heading").innerHTML =
      articleData !== null && articleData.h1;
    document.getElementsByTagName("meta")["description"].content =
      articleData !== null && articleData.desc;
    document.getElementsByTagName("meta")["keywords"].content =
      articleData !== null && articleData.keywords;
    document.getElementsByTagName("meta")["author"].content =
      articleData !== null && articleData.author;
    setCanonicalTag();
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item;
    });
  }, []);

  useEffect(() => {
    articleData !== null && addView();
    getView();
  }, []);

  const addView = () => {
    Promise.resolve(
      axios({
        url: baseUrl + "/api/v1/article/addView",
        method: "POST",
        data: { id: articleData.id },
      })
    ).then((res) => {});
  };
  const getView = () => {
    Promise.resolve(
      axios({
        url: `${baseUrl}/api/v1/article/getView`,
        method: "GET",
        params: { id: articleData.id },
      })
    ).then((res) => {
      setDataView(res.data.data);
    });
  };

  //change This part

  //read More
  const readMore = [
    {
      id: 1,
      title: "معرفی صفر تا صد زبان برنامه پایتون برای مبتدی ها",
      link: "/What-Is-Python-Programming-Language",
    },
    {
      id: 2,
      title: "ده زبان برنامه نویسی محبوب 2023",
      link: "/popular-programming-language-2023",
    },
    {
      id: 3,
      title: "اصطلاحات پرکاربرد طراحی سایت",
      link: "/common-words-in-web-design",
    },
    {
      id: 4,
      title: "اپلیکیشن چیست؟ هر آنچه در مورد نرم افزار کاربردی باید بدانید",
      link: "/what-is-application",
    },
  ];
  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          direction: "rtl",
          alignItems: "center",
        }}
      >
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: { xs: "unset", sm: "flex-start" },
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            width: { xs: "100%", sm: "85%" },
          }}
        >
          <ArticleSide
            tags={articleData !== null && articleData.tags}
            readMore={readMore}
          />
          {articleData !== null ? (
            <Grid
              item
              xs={12}
              sm={7.5}
              md={8}
              lg={8.5}
              xl={9}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", sm: "flex-end" },
                flexDirection: "column",
                width: { xs: "100%", sm: "60%", md: "70%" },
              }}
            >
              {/* do not change */}
              {headPhoto(articleData.image)}
              {h1(articleData.h1, t)}
              {timeRead(articleData.timeRead, t)}
              {date(articleData.date, i18n)}
              <Grid
                sx={{
                  display: "flex",
                  alignItems: { xs: "center", sm: "flex-start" },
                  justifyContent: "flex-start",
                  width: { xs: "90%", sm: "100%" },
                  flexDirection: "column",
                }}
              >
                {/* The text of the article */}
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  از محبوب ترین و پرکاربرد ترین زبان های برنامه نویسی دنیا می توان به<strong> سی شارپ </strong> اشاره کرد.  با توجه به آخرین تحقیقاتی که انجام شد این زبان جزو 5 زبان برنامه نویسی برتر در دنیا شناخته شد. همچنین می توان گفت که #C در ایران نیز بازار کار خوبی دارد. زبان سی شارپ برای ساخت برنامه های تحت ویندوز (دسکتاپ)، برنامه های تحت وب،Web service  ها، برنامه های موبایل و <Typography component={"a"} target="_blank" href="https://mycryptocommerce.com/Blog/What-Is-SLP" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >بازی ها </Typography> کاربرد دارد. 
                  </Typography>
                  <Typography
                  component={"h2"}
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "22px", md: "32px" },
                    fontWeight: "700",
                    margin: { xs: "5px 0", md: "20px 0" },
                    textAlign: "start",
                    width: "100%",
                  }}
                >
                 زبان سی شارپ چیست 
                </Typography>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo1} alt="زبان سی شارپ" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        زبان سی شارپ
                      </Typography>
                    </Grid>
                  </Grid>
                <Typography
                  component={"p"}
                  sx={{
                    color: "#070707",
                    fontSize: { xs: "10px", sm: "14px", md: "16px" },
                    textAlign: "justify",
                    margin: { xs: "5px 0", md: "10px 0" },
                    lineHeight: "35px",
                    width: "100%",
                  }}
                >
                  سی شارپ یک زبان برنامه نویسی شی گرا و همه منظوره و مدرن می باشد که بسیار شبیه به ++C است.  اجرای این زبان روی Net Framework. <Typography component={"a"} target="_blank" href="https://www.microsoft.com/de-de/" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >مایکروسافت </Typography> می باشد و روی انواع پلتفرم های کامپیوتری نیز کامپایل می شود. زبان سی شارپ برای توسعه برنامه های ویندوز از پلتفرم های WinForms،WPF  و UWP استفاده می کند. همچنین با زبان سی شارپ و پلتفرم هایی مانند Xamarin و UWP می ‌توان برای اندروید،  iOS برنامه نوشت. فریمورک های ASP.NET MVC  و ASP.NET Core دو تکنولوژی مهم برای طراحی و ایجاد برنامه های تحت وب مدرن می باشند که در دنیای برنامه نویسی بسیار پرکاربرد هستند. بنابراین با تسلط بر زبان برنامه نویسی سی شارپ قادر خواهید بود فریمورک قدرتمند ASP.NET Core را به راحتی فرا گیرید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    تاریخچه زبان سی شارپ
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زبان سی شارپ یک زبان برنامه نویسی است که توسط مایکروسافت در سال 2000 با سرپرستی <Typography component={"a"} target="_blank" href="https://en.wikipedia.org/wiki/Anders_Hejlsberg" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >آندرس هجلزبرگ </Typography> به دنیای برنامه نویسی معرفی شد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    در سال ۱۹۹۹، شرکت Sun (سان) به شرکت مایکروسافت اجازه استفاده از زبان برنامه‌نویسی جاوا را در سیستم عاملشان داد. با اینکه جاوا به هیچ پلتفرم یا سیستم‌عاملی وابسته نبود، ولی مایکروسافت برخی از مفاد قرارداد را رعایت نکرد و قابلیت مستقل از سیستم‌عامل بودن <NavLink to="/Academy/What-Is-JAVA-Programming-Language" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >جاوا </NavLink> را حذف کرد. بنابراین شرکت سان نیز علیه مایکروسافت شکایت کرد و مایکروسافت را مجبور کرد تا زبان شی گرای جدیدی با کامپایل جدیدی که به ++C شباهت داشت را ایجاد کند. در سال ۱۹۹۹ گروهی با مدیریت آندرس هلزبرگ که  طراحی Framework و زبان‌های برنامه سازیBorland، Delphi، Pascal، C++ را در کارنامه خود داشت، زبانی را با نام Cool ایجاد کردند که شبیه زبان C بود اما خاصیت شی گرایی نیز داشت. در ارائه و معرفی رسمی چارچوب دات‌ نت در سال ۲۰۰۰ این زبان با الهام از نت موسیقایی «♯» که مخفف یک نت است، با نام #C معرفی شد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    دلایل طراحی زبان سی شارپ
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo2} alt="طراحی زبان سی شارپ" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        طراحی زبان سی شارپ
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    سی شارپ زبانی ساده، مدرن و شیءگرا می باشد.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    از سی شارپ برای استفاده در اجزای توسعه نرم‌افزار جهت دستیابی به مزایای سامانه‌های توزیعی استفاده می شود.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    پشتیبانی از این زبان برای ساده می باشد.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    زبان سی شارپ برای نوشتن برنامه‌های با سیستم عامل‌های پیچیده بسیار بزرگ تا توابع اختصاصی بسیار کوچک مناسب می باشد.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    برنامه‌های نوشته شده با سی شارپ از لحاظ حافظه و پردازنده مورد نیاز مقرون به صرفه هستند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    دلایل محبوبیت سی شارپ
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زبان برنامه نویسی سی شارپ دارای قابلیت های زیادی است که در مقایسه با دیگر <NavLink to="/Academy/Programming" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >زبان های برنامه نویسی </NavLink> آن را منحصر به فردتر می کند. ما در ادامه به بررسی تعدادی از این ویژگی های ممتاز می پردازیم.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    یادگیری آسان زبان برنامه نویسی سی شارپ
                    <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                     سی شارپ در دسته زبان های سطح بالا قرار دارد و به زبان های برنامه نویسی چون  ++C, C و جاوا شبیه است، بنابراین یادگیری این زبان ساده می باشد و با داشتن یک منبع مناسب قادر خواهید بود به درستی این زبان برنامه نویسی را فرا بگیرید. 
                    </Typography>
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    بکارگیری زبان سی شارپ برای توسعه برنامه های دسکتاپ (ویندوز) و تحت وب
                    <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زبان C# به صورت گسترده برای تولید برنامه های دسکتاپ ، تحت وب و Web service ها مورد استفاده قرار می گیرد. مایکروسافت با خرید شرکت  Xamarin قدم بزرگی در مسیر رشد زبان #C برداشت. <Typography component={"a"} target="_blank" href="https://visualstudio.microsoft.com/xamarin/" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >Xamarin </Typography>  امکانی را فراهم کرد تا با استفاده از زبان #C برای دستگاه‌های اندرویدی و iOS اپلیکیشن موبایل تولید شود.
                  </Typography>
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    استفاده از زبان سی شارپ برای تولید بازی
                    <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo3} alt="سی شارپ و تولید بازی" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                      سی شارپ و تولید بازی
                      </Typography>
                    </Grid>
                  </Grid>
                    <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زبان سی شارپ کارایی بالایی در تولید بازی های مختلف دارد.
                  </Typography>
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    زبان #C دارای برنامه های تحت وب یکپارچه ای است
                    <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زبان برنامه نویسی سی شارپ دارای کتابخانه بزرگی است که باعث می شود عملکرد سطح بالاتری نسبت به دیگر زبان ها مانند جاوا و C++ دشاته باشد.
                  </Typography>
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    بازار کار عالی در ایران و خارج از ایران
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    پشتیبانی و بروزرسانی مداوم توسط شرکت مایکروسافت
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    شی گرا بودن زبان سی شارپ
                    <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برنامه نویسی شی گرا یا OOP، در حقیقت منطقی است که از زندگی روزمره‌ی ما انسان ها گرفته شده است. در زبان سی شارپ نیز که یک زبان برنامه نویسی شی گرا می باشد، مفاهیم برنامه نویسی همانند اشیای دنیای واقعی مدل می شود زیرا ذهن انسان قادر است که اشیا را مدل سازی کند.
                  </Typography>
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    موارد استفاده از سی شارپ
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    کتابخانه و اجرای ویندوز
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برنامه های کاربردی وب
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    کنترل و سیستم های بازی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    بازی های ویدئویی و واقعیت مجازی
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    محیط سی شارپ
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo4} alt="محیط سی شارپ" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                      محیط سی شارپ
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای استفاده از زبان سی شارپ به <Typography component={"a"} target="_blank" href="https://dotnet.microsoft.com/en-us/download/dotnet-framework" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >NET Framework. </Typography> و یک IDE (محیط توسعه یکپارچه) نیاز هست.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                     NET Framework
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    پلت فرم NET Framework. سیستم عامل ویندوز برای نوشتن برنامه های مبتنی بر وب و دسکتاپ از جمله سی شارپ مورد نیاز است، زیرا این پلتفرم قابلیت همکاری زبانی را فراهم می سازد. همچنین NET Framework. به #C اجازه می دهد با دیگر زبان های رایج برنامه نویسی مانند C++، <NavLink to="/Academy/What-Is-Javascript-Programming-Language" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >Jscript </NavLink>،<Typography component={"a"} target="_blank" href="https://en.wikipedia.org/wiki/COBOL#:~:text=COBOL%20(%2F%CB%88ko%CA%8Ab,2002%2C%20object%2Doriented%20language." sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >COBOL </Typography> در ارتباط باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    IDE ها
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    شرکت مایکروسافت IDE های مختلفی را برای برنامه نویسی با زبان #C ارائه می دهد که در ذیل به آن می پردازیم.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    Visual Studio 2010 (VS)
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    Visual Studio Express
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    Visual Web Developer
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    Visual Studio Code (VSC)
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    فایل‌های کد منبع سی شارپ را با استفاده از یک ویرایشگر متن اصلی، مانند Notepad می توان نوشت و با استفاده از کامپایلر  NET Framework. کامپایل کرد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    از آنجا که سی شارپ بخشی از پلتفرم Net Framework.  است، به کتابخانه ای وسیع از کدها و مؤلفه‌های آن مانند Common Language Runtime (CLR)، .Net Framework Class Library، Common Language Specification، Common Type System، Metadata and Assemblies، Windows Forms، ASP.Net و ASP.Net AJAX، Windows Workflow Foundation (WF)، Windows Communication Foundation (WCF) و LINQ دسترسی دارد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    مقایسه سی شارپ با زبان های دیگر
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    اگربخواهیم سی شارپ را با دیگر زبان های برنامه نویسی مقایسه کنیم بسیار زمان بر است. بنابراین چون در این مقاله نمی گنجد ما در این مقاله به مقایسه این زبان با دو زبان دیگر برنامه نویسی از جمله جاوا و ++Cمی پردازیم. 
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    مقایسه #C و جاوا
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo5} alt="مقایسه جاوا و سی شارپ" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        جاوا و سی شارپ
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    سی شارپ و جاوا زبان های برنامه نویسی سطح بالایی و شی گرایی هستند که در کنار شباهت های زیادی که با هم دارند تفاوت هایی نیز با هم دارند. هر دوی این زبان ها تحت تاثیر زبان سی متولد شدند اما سی شارپ برای توسعه برنامه در اکوسیستم مایکروسافت کارایی دارد و جاوا برای برنامه های وب سمت کلاینت گزینه مناسبی می باشد. همچنین سی شارپ برای پروژه های واقعیت مجازی مانند بازی ها، موبایل و برنامه های کاربردی وب مورد استفاده قرار می گیرد. زبان سی شارپ برای پلتفرم‌ های مایکروسافت و چندین سیستم عامل غیر مبتنی بر مایکروسافت، مانند Mono Project که با Linux و OS X کار می ‌کند ساخته شده است. اما زبان برنامه نویسی جاوا برای ایجاد برنامه‌ های پیام‌ رسانی و توسعه برنامه‌ های مبتنی بر وب و سازمانی در اکوسیستم‌ های منبع باز کاربرد دارد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    مقایسه #C و ++C
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                     #C و ++C هر دو زبان هایی مبتنی بر C با کدهای مشابه و کامپایل شده هستند که تفاوت های زیادی نیز بین آنها وجود دارد. ++C روی هر پلتفرمی قابلیت اجرا دارد اما #C تنها برای سیستم عامل ویندوز طراحی شده است. سی شارپ یک زبان برنامه نویسی کامپوننت گرا می باشد در صورتی که ++C یک زبان شی گرا جزئی می باشد. همچنین سی شارپ توسط NET. به CLR کامپایل می شود ولی ++C به کد ماشین کامپایل می شود. همچنین اندازه باینری ها در سی شارپ بسیار بزرگتر از ++C می باشد. #C خطاها و هشدارهای کامپایلر را ارائه می کند، اما ++C از هشدارها پشتیبانی نمی کند و ممکن است سبب آسیب به سیستم عامل شود. همچنین ++C سریعتر از #C اجرا می شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    قواعد سی شارپ
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo6} alt="قواعد سی شارپ" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        قواعد سی شارپ
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    سی شارپ زبانی Case-Sensitive می باشد یعنی به حروف کوچک و بزرگ حساس است.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    سی شارپ 79 کلمه کلیدی دارد که این کلمات را نمی‌توان برای نام‌گذاری شناسه‌ها (مانند نام متغیر، نام کنترل، نام شیء، نام کلاس و…) بکار کرد.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    بعد از نوشتن هر خط دستور باید از سمی‌ کالن استفاده کرد.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    در ابتدای کدها باید کلمه کلیدی using را برای تعریف namespace ها نوشت. namespace ها برای دسته بندی کلاس‌ها و کوتاه تر کردن کدنویسی استفاده می شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    کاربردهای سی شارپ
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زبان سی شارپ در حوزه های مختلفی کاربرد دارد که در زیر به برخی از آن ها می پردازیم.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                     برنامه نویسی موبایل
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زامارین (Xamarin) فریمورکی از زبان #C است که برای برنامه نویسی دستگاه های اندروید و ios استفاده می شود.  این فریمورک مانند فلاتر (Flutter) و React Native برای توسعه برنامه‌های چندسکویی یا Cross-Platform کارایی دارد.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    توسعه صفحات وب
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    جهت کدنویسی سرور وبسایت‌ها با سی شارپ، می‌توان از  <Typography component={"a"} target="_blank" href="https://dotnet.microsoft.com/en-us/apps/aspnet" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} > ASP.Net </Typography> بهره گرفت. برای کار با این فریمورک، باید به خوبی زبان سی شارپ فرا گرفت چون بسیاری از کدهای استفاده شده در ASP.Net با زبان سی شارپ می باشند.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                     هوش مصنوعی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    یکی از شاخه‌های پرکاربرد <NavLink to="/Academy/What-Is-Artificial-Intelligence" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >هوش مصنوعی </NavLink>، یادگیری ماشین می باشد. یادگیری ماشین یعنی کامپیوترها قادر باشند چیزهای جدید را با استفاده از نمونه‌ها و تجربیات خود یاد بگیرند. یادگیری ماشین یا ML در زمینه‌های گوناگونی مانند موتورهای جستجو، فیلتر کردن ایمیل‌های دریافتی، تشخیص چهره، تشخیص پزشکی و زبان شناسی کارایی دارد. با استفاده از سی شارپ و بسته‌ی نرم‌افزاری <Typography component={"a"} target="_blank" href="https://www.squadhelp.com/name/ML.net" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >ML.Net </Typography> می‌توانید در زمینه یادگیری ماشین، برنامه‌نویسی انجام دهید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    اینترنت اشیاء
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo9} alt="سی شارپ و اینترنت اشیا" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        اینترنت اشیا
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای توسعه پروژه های مبتنی بر <NavLink to="https://mycryptocommerce.com/Details/IOTA" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >اینترنت اشیاء</NavLink> که از سنسورهای متعددی استفاده می‌کنند، #C زبانی ایده ال می باشد. با استفاده از سی شارپ و کتابخانه‌های مورد نیاز IoT می‌توانید برنامه های بر پایه IoT را توسعه بخشید. دو بسته‌ای که برای توسعه اینترنت اشیاء با سی شارپ استفاده می شود عبارت است از:
                  </Typography>
                  <Typography component={"ol"}>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                    
                      <Typography component={"span"}
                        sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", paddingRight: "3px" }}>
                        System.Device.Gpio
                      </Typography>
                    </Typography>
                    <Typography component={"li"}
                      sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", marginBottom: { xs: "10px", md: "15px" } }}>
                    
                      <Typography component={"span"}
                        sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" },textAlign: "start", width:"100%", paddingRight: "3px" }}>
                        Iot.Device.Bindings
                      </Typography>
                    </Typography> 
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    توسعه برنامه های ویندوز
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    برای توسعه برنامه های دسکتاپ، سی شارپ گزینه مناسبی می باشد. برای توسعه برنامه های دارای GUI یا رابط کاربری گرافیکی دسکتاپ می توانید از Visual Studio استفاده کنید.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    الکترونیک
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    سی شارپ با داشتن کتابخانه‌ها و کلاس‌هایی برای کار با انواع پورت‌های سخت‌افزاری و میکروکنترلرها کاربرد زیادی در حوزه الکترونیک دارد. حتی در حوزه رباتیک و برنامه‌نویسی نیز می توان از سی شارپ استفاده کرد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    مزایا و معایب سی شارپ
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    این زبان نیز مانند هر زبان برنامه نویسی دیگری دارای یکسری مزایا و معایبی می باشد که در ادامه به بررسی آن ها می پردازیم.
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    مزایای زبان سی شارپ
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo10} alt="مزایای سی شارپ" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        مزایای سی شارپ
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    زبان #C دارای مزایای مختلفی است که در ذیل آن ها بازگو می کنیم.
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                   ساختار نوشتاری مشابه جاوا، C++ و C
                  </Typography><Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    زبانی قدرتمند که از Net Framework. استفاده می‌کند.
                  </Typography><Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    امنیت بالا
                  </Typography><Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    قابلیت استفاده مجدد از کدها
                  </Typography><Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    چندسکویی بودن (Cross-Platform)
                  </Typography><Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    کتابخانه های غنی برای اجرای روان توابع
                  </Typography><Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    برنامه های مدولار و قابل نگهداری
                  </Typography><Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    کم بودن هزینه نگهداری
                  </Typography>
                  <Typography component={"h3"}
                    sx={{ color: "#000000", fontSize: { xs: "14px", sm: "19px", md: "27px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    معایب زبان برنامه نویسی سی شارپ
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    عدم امکان استفاده مستقیم از زبان اسمبلی
                  </Typography>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    ارتباط ضعیف با API
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    وابستگی زیاد به چارچوب Net.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    موارد استفاده از سی شارپ
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo11} alt="کاربردهای سی شارپ" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        کاربردهای C#
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    کتابخانه ها و اجزای ویندوز
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    خدمات ویندوز
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    برنامه های کاربردی وب
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    فناوری بلاک چین و دفتر کل توزیع شده
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    برنامه ها و خدمات ابری Azure
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    دستگاه های اینترنت اشیا
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    بازی های ویدئویی و واقعیت مجازی
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    نرم افزارهای همکاری مانند شیرپوینت
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    برنامه های موبایلی بومی iOS و اندروید
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    خدمات Backend و برنامه های پایگاه داده
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    برنامه های کاربردی هوش مصنوعی و ML(یادگیری ماشین)
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    برنامه های کاربردی توزیع شده
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    برنامه نویسی در سطح سخت افزار
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    نرم افزار ویروس و بدافزار
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    برنامه های کاربردی مبتنی بر رابط کاربری گرافیکی
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    کنسول ها و برنامه های بازی
                  </Typography>
                   <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    نرم افزار سازمانی
                  </Typography> 
                  <Typography component={"li"}
                    sx={{ color: "#000000", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "start", width:"100%", fontWeight: "700", margin: { xs: "0 10px 10px 0", md: "0 15px 15px 0" }, listStyleType: "disc" }}>
                    فناوری بلاک چین و دفتر کل توزیع شده
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width: "100%" }}>
                    جمع بندی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px", width: "100%",
                    }}>
                    ما در این مقاله به معرفی یک زبان سطح بالا و شی گرا پرداختیم و معایب و مزایای این زبان را بررسی نمودیم. همچنین لازم به ذکر است که این زبان یکی از پرکاربردترین زبان های دنیاست و بازار کار خوبی در ایران و جهان دارد. امید است که مطالب این <NavLink to="/Academy" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >مقاله </NavLink> مفید واقع شود.
                  </Typography>
                {/* do not change */}

                {author(articleData.author, t)}
                {tags(articleData.tags, t)}
                {linkArticle(t, url)}
                {sourceLink(articleData.source, t)}
                {view(dataview, t)}
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={7.5}
              md={8}
              lg={8.5}
              xl={9}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: { xs: "80%", sm: "60%", md: "70%" },
              }}
            >
              <Loader />
            </Grid>
          )}
        </Grid>
      </Grid>
      <PortfolioButton />
      <Footer />
    </>
  );
};
export default Article17;
