import { SvgIcon } from '@mui/material';
import React from 'react';
const ContactUsMessageIcon = ({ color }) => {
  return (
    <SvgIcon component="object" sx={{ height: "30px" }}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 11H18V13H9V11ZM18 7H6V9H18V7ZM22 4V22L18 18H4C3.46957 18 2.96086 17.7893 2.58579 17.4142C2.21071 17.0391 2 16.5304 2 16V4C2 2.9 2.9 2 4 2H20C20.5304 2 21.0391 2.21071 21.4142 2.58579C21.7893 2.96086 22 3.46957 22 4ZM20 4H4V16H18.83L20 17.17V4Z" fill={color} />
      </svg>
    </SvgIcon>
  );
};
export default ContactUsMessageIcon;