import { Button, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import arrowRightPurple from '../image/icon/arrowRightPurple.png';
import arrowLeftPurple from '../image/icon/arrowLeftPurple.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { NavLink } from "react-router-dom";
import { Articles } from './Magzin/Articles.js';
import * as shamsi from 'shamsi-date-converter';

const ArticlesLanding = () => {
  const { t, i18n } = useTranslation();
  const [articleInfo, setArticleInfo] = useState([]);

  useEffect(() => {
    let result = [];
    let randomList = [];
    for (let i = 1; randomList.length < 3; i++) {
      let randomNum = Math.floor(Math.random() * Articles.length);
      if ((randomList.find((item) => item === randomNum)) === undefined) {
        randomList.push(randomNum);
      }
    }
    for (let i = 0; i < randomList.length; i++) {
      let randomNum = randomList[i];
      result.push(Articles[randomNum]);
    }
    setArticleInfo(result);
  }, [])

  const convertDate = (value) => {
    let date = value.split(',');
    let dateFa = `${date[0]}/${date[1]}/${date[2]}`;
    return i18n.language === "fa" ? dateFa : shamsi.jalaliToGregorian(parseInt(date[0]), parseInt(date[1]), parseInt(date[2])).join('/')
  }

  const Card = () => {
    let list = [];
    articleInfo.forEach((item, index) => {
      list.push(
        <Grid title={item.title} key={index} sx={{
          display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "90%", sm: "30%" }, background: `url(${item.image})`, backgroundPosition: "center", backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat", height: { xs: "125px", sm: "160px", md: "200px", lg: "220px", xl: "250x" }, margin: "20px 0", borderRadius: "16px", border: "1.5px solid #8439FF",
          boxShadow: "0px 0px 16px 0px #8439FF", "&:hover": { backgroundSize: "105% 105%" }, transition: "all 0.8s"
        }}>
          <Grid sx={{
            display: "flex", alignItems: "flex-start", justifyContent: "space-evenly", flexDirection: "column", opacity: "0", width: "100%", height: "100%",
            background: "rgba(217, 217, 217, 0.70)", borderRadius: "16px", "&:hover": { opacity: "1" }, transition: "opacity 0.3s", padding: "0 5%"
          }}>
            <Typography sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px" }, color: "#062B33" }}>{`${t("timeRead")} ${item.timeRead} ${t("minutes")}`}</Typography>
            <Typography sx={{ fontSize: { xs: "10px", sm: "16px", lg: "20px" }, fontWeight: "700", color: "#062B33" }}>{item.h1}</Typography>
            <Typography sx={{ fontSize: { xs: "8px", sm: "10px", lg: "16px" }, color: "#062B33" }}>{item.desc}</Typography>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <Typography sx={{ fontSize: { xs: "8px", sm: "10px", lg: "18px" }, color: "#062B33", padding: { xs: "unset", sm: "10px 0" } }}>{convertDate(item.date)}</Typography>
              <NavLink className="navlink" to={`/Academy${item.link}`} target="_blank" style={{ display: "flex", alignItems: "flex-end", justifyContent: "center", flexDirection: "row" }} >
                <Typography component={"p"} sx={{
                  display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row", color: "#FF3951",
                  fontSize: { xs: "8px", sm: "12px", md: "14px" }, filter: "drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.31))"
                }}>{t("readMore")}
                </Typography>
                <ArrowBackIcon sx={{ fontSize: { xs: "10px", sm: "14px", md: "18px" }, transform: i18n.language === "en" ? "rotateZ(180deg)" : "null", color: "#FF3951" }} />
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
      )
    })
    return list;
  }
  return (
    <>
      <Grid sx={{ display: "flex", width: "100%", justifyContent: "center", flexDirection: 'column', alignItems: "center", marginTop: { xs: "30px", sm: "60px", md: "100px" }, direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
        <Grid sx={{ display: "flex", width: { xs: "95%", md: "95%", lg: "85%" }, flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative" }}>
          <Grid sx={{ display: "flex", flexDirection: i18n.language === "fa" ? "row" : "row-reverse", justifyContent: "center", alignItems: "center", marginBottom: { xs: "20px", sm: "35px", md: "50px" }, width: "100%" }}>
            <Typography component={"img"} alt="icon" src={arrowRightPurple} sx={{ width: { xs: "14px", sm: "39px", md: "40px", lg: "54px" }, height: { xs: "17px", sm: "53px", md: "55px", lg: "69px" }, margin: "0 3px" }} />
            <Typography component={"p"} sx={{ fontWeight: "700", fontSize: { xs: "12px", sm: "24px", md: "32px" }, textAlign: "center", lineHeight: { xs: "17px", md: "45px" } }}>{t("articles")}</Typography>
            <Typography component={"img"} alt="icon" src={arrowLeftPurple} sx={{ width: { xs: "14px", sm: "39px", md: "40px", lg: "54px" }, height: { xs: "17px", sm: "53px", md: "55px", lg: "69px" }, margin: "0 3px" }} />
          </Grid>
          <Grid sx={{
            display: "flex", alignItems: "center", justifyContent: { xs: "center", sm: "space-between" }, width: "100%", marginTop: { xs: "0", md: "30px" },
            flexDirection: "row", flexWrap: "wrap"
          }}>
            {Card()}
          </Grid>
          <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", marginTop: "25px" }}>
            <Button sx={{ display: "flex", bgcolor: "#FF3951", width: { xs: "158px", sm: "300px" }, height: { xs: "40px", sm: "50px" }, boxShadow: " #FF3951 0px 5px 15px", margin: "20px auto", "&:hover": { backgroundColor: "#FF3951" } }}>
              <NavLink className="navlink" to="/Academy" target="_blank">
                <Typography component={"span"} sx={{ color: "white", fontSize: { xs: "12px", sm: "14px", md: i18n.language === "fa" ? "16px" : "14px" } }}>{t("viewArticle")}</Typography>
              </NavLink>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default ArticlesLanding;