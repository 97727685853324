import { SvgIcon } from '@mui/material';
import React from 'react';
const OldOrderIcon = ({ color }) => {
  return (
    <SvgIcon component="object" sx={{ height: "30px" }}>
      <svg width="24" height="24" viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.8 0C19.1205 0 21 1.79 21 4C21 6.21 19.1205 8 16.8 8C14.4795 8 12.6 6.21 12.6 4C12.6 1.79 14.4795 0 16.8 0ZM16.8 6C17.955 6 18.9 5.1 18.9 4C18.9 2.9 17.955 2 16.8 2C15.645 2 14.7 2.9 14.7 4C14.7 5.1 15.645 6 16.8 6ZM16.8 10C19.1205 10 21 11.79 21 14C21 16.21 19.1205 18 16.8 18C14.4795 18 12.6 16.21 12.6 14C12.6 11.79 14.4795 10 16.8 10ZM10.5 2H9.53674e-07V4H10.5V2ZM10.5 16V14H9.53674e-07V16H10.5ZM10.5 8H9.53674e-07V10H10.5V8Z" fill={color} />
      </svg>
    </SvgIcon>
  );
};
export default OldOrderIcon;