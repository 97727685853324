import React, { useState,useEffect } from "react";
import { Modal, Grid, Typography, Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { baseUrl } from "./modules/general";
import { useNavigate } from "react-router-dom";

const ModalLogout = ({ open, close }) => {
  const [btnLogout, setBtnLogout] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const Navigate = useNavigate();

  const logoutHandler = () => {
    setLoading(true)
    setBtnLogout(true)
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/user/logout`,
      method: "POST",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setBtnLogout(false)
        setLoading(false)
        localStorage.setItem("TACC", "")
        Navigate("/");
        window.location.reload();
      })
  }
  const clickOtherLocation = (e) => {
    if (e.target.id === 'modal-logout') {
      close();
    }
  }
  return (
    <>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid onClick={(e) => clickOtherLocation(e)} id="modal-logout" sx={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: "100vw", height: "100vh", backgroundColor: 'rgba(105,99,99,0.6)' }}>
          <Grid sx={{
            display: "flex", alignItems: "center", justifyContent: "space-evenly", flexDirection: 'column', background: "#FFFFFF", width: { xs: "300px", sm: "443px", md: "521px" },
            height: { xs: "159px", sm: "206px", md: "230px" }, borderRadius: "11px", boxShadow: "0px 0px 15px rgba(255, 57, 81, 0.54)"
          }}>
            <Typography component={"p"} sx={{ fontSize: { xs: "18px", sm: "20px", md: "24px" }, color: "#1A1A1A" }}>{t("logOutModal")}</Typography>
            <Grid sx={{ display: 'flex', alignItems: "center", flexDirection: "row", margin: "15px 0 0" }}>
              <Button onClick={close} sx={{
                width: { xs: "108px", sm: "130px" }, height: { xs: "32px", sm: "40px" }, background: "#FF3951",
                boxShadow: "0px 0px 8px #FF3951;", borderRadius: "8px", fontSize: { xs: "12px", sm: "16px", md: "18px" }, color: "#FFFFFF",
                "&:hover": { background: "#FF3951" }, marginRight: "15px"
              }}>
                {t("cancel")}
              </Button>
              <Button onClick={logoutHandler} disabled={btnLogout ? true : false} sx={{
                width: { xs: "108px", md: "130px" }, height: { xs: "32px", sm: "40px" }, backgroundColor: "#FFFFFF", border: "2px solid #FF3951", boxShadow: "0px 0px 8px #FF3951;",
                fontSize: { xs: "12px", sm: "16px", md: "18px" }, color: "#FF3951", borderRadius: "8px", "&:hover": { backgroundColor: "#FFFFFF" }
              }}>
                {btnLogout === true && loading === true ? <CircularProgress size={20} color="inherit" sx={{ color: "#FF3951" }} /> : t("exit")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default ModalLogout
