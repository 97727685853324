import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import aboutUs from '../image/aboutUs/aboutUs.png';
import { NavLink } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Footer from "./Footer";
import { setCanonicalTag } from "./modules/general";
const AboutUs = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = "درباره شرکت وب گستران سورین بیشتر بدانید"
    document.getElementById("main-heading").innerHTML = "درباره شرکت وب گستران سورین بیشتر بدانید";
    document.getElementsByTagName('meta')["description"].content = "شرکت سورین از معتبرترین شرکت های ارائه کننده خدمات نرم افزاری نظیر طراحی انواع وب سایت و پلتفرم های پیچیده مالی، طراحی فروشگاه اینترنتی حرفه ای، برنامه نویسی پیشرفته از سال 96 کار خود را آغاز کرده است.";
    document.getElementsByTagName('meta')["keywords"].content = "اطلاعات شرکت کامپیوتری سورین | درباره شرکت قدرتمند طراحی انواع نرم افزار سورین | درباره بهترین شرکت طراحی سایت در تهران بیشتر بدانید";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: i18n.language === "fa" ? "rtl" : "ltr", alignItems: "center" }}>
        <Grid sx={{ display: "flex", width: "100%", backgroundColor: "#EDEDED", padding: "15px 0", justifyContent: "center" }}>
          <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: { xs: "95%", md: "86%" } }}>
            <NavLink className="navlink" to="/">
              <Typography sx={{ fontSize: "12px", color: "#757575", padding: "0 5px" }} >{t("mainPage")}</Typography>
            </NavLink>
            <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
            <Typography sx={{ fontSize: "12px", color: "#070707", padding: "0 5px" }}>{t("aboutUs")}</Typography>
          </Grid>
        </Grid>
        <Grid sx={{ display: "flex", flexDirection: { xs: "column-reverse", md: "row" }, justifyContent: "center", alignItems: "flex-start", width: { xs: "95%", md: "100%" } }}>
          <Grid sx={{ display: "flex",justifyContent:"center",alignItems:"center", width: { xs: "100%", md: "50%" }, }}>
            <Grid sx={{display:"flex",flexDirection:"column",alignItems:"flex-start",justifyContent:{xs:"center",md:"flex-start"}, padding: { xs: "20px 0", md: "100px 0" },width:{xs:"95%",md:"75%"}}}>
              <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("text1Aboutus")}</Typography>
              <Typography component={"h2"} sx={{ fontSize: { xs: "16px", sm: "20px", md: "24px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("h2About1")}</Typography>
              <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("text2Aboutus")}</Typography>
              <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("text3Aboutus")}</Typography>
              <Typography component={"h2"} sx={{ fontSize: { xs: "16px", sm: "20px", md: "24px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("h2About2")}</Typography>
              <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("text4Aboutus")}</Typography>
              <Typography component={"h3"} sx={{ fontSize: { xs: "14px", sm: "18px", md: "22px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("h3About1")}</Typography>
              <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("text5Aboutus")}</Typography>
              <NavLink to="/Academy/WebDesign" style={{ textDecoration: "none",borderBottom:"1px solid #757575" }}>
                <Typography component={"h3"} sx={{ fontSize: { xs: "14px", sm: "18px", md: "22px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("h3About2")}</Typography>
              </NavLink>
              <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("text6Aboutus")}</Typography>
              <NavLink to="/Academy/Application" style={{ textDecoration: "none",borderBottom:"1px solid #757575" }}>
                <Typography component={"h3"} sx={{ fontSize: { xs: "14px", sm: "18px", md: "22px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("h3About3")}</Typography>
              </NavLink>
              <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("text7Aboutus")}</Typography>
              <NavLink to="/Academy/Seo" style={{ textDecoration: "none",borderBottom:"1px solid #757575" }}>
                <Typography component={"h3"} sx={{ fontSize: { xs: "14px", sm: "18px", md: "22px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("h3About4")}</Typography>
              </NavLink>
              <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("text8Aboutus")}</Typography>
              <NavLink to="/Academy/UIUX" style={{ textDecoration: "none",borderBottom:"1px solid #757575" }}>
                <Typography component={"h3"} sx={{ fontSize: { xs: "14px", sm: "18px", md: "22px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("h3About5")}</Typography>
              </NavLink>
              <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("text9Aboutus")}</Typography>
              <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("text10Aboutus")}</Typography>
              <NavLink to="/Academy/Programming" style={{ textDecoration: "none",borderBottom:"1px solid #757575" }}>
                <Typography component={"h3"} sx={{ fontSize: { xs: "14px", sm: "18px", md: "22px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("h3About6")}</Typography>
              </NavLink>
              <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("text11Aboutus")}</Typography>
              <Typography component={"h3"} sx={{ fontSize: { xs: "14px", sm: "18px", md: "22px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("h3About7")}</Typography>
              <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("text12Aboutus")}</Typography>
              <NavLink to="/Academy/Graphic" style={{ textDecoration: "none",borderBottom:"1px solid #757575" }}>
                <Typography component={"h3"} sx={{ fontSize: { xs: "14px", sm: "18px", md: "22px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("h3About8")}</Typography>
              </NavLink>
              <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("text13Aboutus")}</Typography>
              <Typography component={"p"} sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" }, lineHeight: "150%", color: "#757575", textAlign: "justify", padding: { xs: "5px 0", md: "10px 0" } }}>{t("text14Aboutus")}</Typography>
            </Grid>


          </Grid>
          <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "100%", md: "50%" }, position: { sm: "unset", md: "sticky" }, top: "60px", padding: { xs: "20px 0", md: "50px 0" } }} >
            <Typography component={"img"} alt="aboutUs" src={aboutUs} sx={{ width: { xs: "80%", md: "90%" } }} />
          </Grid>
        </Grid>
        <Grid sx={{display:"none"}}>
          <Typography>
         ما در این صفحه شما را با درباره شرکت وب گستران سورین بیشتر بدانید آشنا می کنیم.
          </Typography>
          <Typography>
            شرکت وب گستران سورین به عنوان عضو رسمی انجمن بلاکچین ایران ( زنجیره بلوکی ایران )شناخته شده است. عضویت در نظام صنفی رایانه ای تهران و هم چنین نماد اعتماد الکترونیک و ساماندهی پایگاه های اینترنتی از جمله عناوین بهترین شرکت نرم افزاری ایران است. ارائه راهکارهای مالی مبتنی بر بلاک چین، طراحی کیف پول اختصاصی ارز دیجیتال، پلتفرم وام دهی و استیکینگ از جمله تخصص های سورین می باشد. 
          </Typography>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
export default AboutUs;