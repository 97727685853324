import { Grid, TextField, Typography, FormControl, Select, MenuItem, Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { separator, onlyFloatnaumber } from "./modules/general";
import { baseUrl } from "./modules/general";
import axios from "axios";
import AlertMessage from "./modules/AlertMessage";
import { setCanonicalTag } from "./modules/general";
import Loader from "./Loader";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const OrderSeo = () => {
  const [t, i18n] = useTranslation();
  const [textFieldSize, setTextFiledSize] = useState(window.innerWidth <= 900 ? "small" : "medium");
  const [numberTextualContent, setNumberTextualContent] = useState("");
  const [numberPhotoContent, setNumberPhotoContent] = useState("");
  const [numberVideoContent, setNumberVideoContent] = useState("");
  const [minimumMonthlyBudget, setMinimumMonthlyBudget] = useState("");
  const [maximumMonthlyBudget, setMaximumMonthlyBudget] = useState("");
  const [siteName, setSiteName] = useState("");
  const [addressSite, setAddressSite] = useState("");
  const [description, setDescription] = useState("");
  const [dataActivity, setDataActivity] = useState(null);
  const [activity, setActivity] = useState("")
  const [alert, setAlert] = useState({ alertCheck: false, message: "", severity: "" });
  const [btnSubmit, setBtnSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [info, setInfo] = useState(null);

  useEffect(() => {
    document.title = "سفارش سئو سایت | فرم درخواست بهینه سازی سایت | وب گستران سورین بهترین شرکت سئوکار در ایران ";
    document.getElementById("main-heading").innerHTML = "سفارش سئو سایت | فرم درخواست بهینه سازی سایت | وب گستران سورین بهترین شرکت سئوکار در ایران ";
    document.getElementsByTagName('meta')["description"].content = "شما درصفحه ثبت سفارش سئو وب سایت خود هستید. ثبت درخواست بهینه سازی وب سایت شما برای موتورهای جستجوگر مثل گوگل در بهترین شرکت سئو کار در تهران و ایران. ";
    document.getElementsByTagName('meta')["keywords"].content = "درخواست بهینه سازی سایت برای موتورهای جستجوگر | درخواست ارتقای رتبه سایت در موتورهای جستجوگر | درخواست بهینه سازی صفحات وب سایت برای جستجو در موتورهای جستجوگر | درخواست seo سایت";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    setInfo(JSON.parse(localStorage.getItem('sorinInfoUser')));
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => resizeTextFiled());
    return (() => window.removeEventListener('resize', () => resizeTextFiled()));
  }, [window.innerWidth]);

  useEffect(() => {
    getActivity();
  }, [])

  const getActivity = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/activity/get`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataActivity(res.data.data)
      })
  }

  const submitHandeler = () => {
    let data;
    if (activity === "" && siteName === "" && addressSite === "") {
      setAlert({ alertCheck: true, message: t("alert_seoNull"), severity: "error" })
    }
    else if (siteName === "") {
      setAlert({ alertCheck: true, message: t("alert_siteName"), severity: "error" })
    }
    else if (addressSite === "") {
      setAlert({ alertCheck: true, message: t("alert_addressSite"), severity: "error" })
    }
    else if (activity === "") {
      setAlert({ alertCheck: true, message: t("alert_activity"), severity: "error" })
    }
    else if ((minimumMonthlyBudget !== "") && (+minimumMonthlyBudget < 500000)) {
      setAlert({ alertCheck: true, message: t("alert_minimumBudget"), severity: "error" })
    }
    else if ((maximumMonthlyBudget !== "") && (+maximumMonthlyBudget > 400000000000)) {
      setAlert({ alertCheck: true, message: t("alert_maximumBudget"), severity: "error" })
    }
    else if ((maximumMonthlyBudget !== "") && (minimumMonthlyBudget !== "") && (+minimumMonthlyBudget > +maximumMonthlyBudget)) {
      setAlert({ alertCheck: true, message: t("alert_minimumBigerMaximum"), severity: "error" })
    }
    else {
      data = {
        site_address: addressSite,
        activity_type_id: activity,
        site_name: siteName,
        keyword: keyword,
        minimum_budget: minimumMonthlyBudget,
        maximum_budget: maximumMonthlyBudget,
        textual_cotent_number: numberTextualContent,
        visual_cotent_number: numberPhotoContent,
        video_cotent_number: numberVideoContent,
        description: description,
      }
      submitData(data);
    }
  }

  const submitData = (data) => {
    setLoading(true)
    setBtnSubmit(true)
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/seo/create',
      method: "POST",
      data: data,
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setAlert({ alertCheck: true, message: t("alert_dataSuccess"), severity: "success" })
        setBtnSubmit(false)
        setLoading(false);
        setSiteName(""); setAddressSite(""); setKeyword(""); setActivity(""); setNumberTextualContent(""); setNumberPhotoContent(""); setNumberVideoContent(""); setMinimumMonthlyBudget(""); setMaximumMonthlyBudget(""); setDescription("");
      })
      .catch(
        (error) => {
          const { status } = error.response;
          switch (status) {
            case (500):
              setAlert({ alertCheck: true, message: t("AM_intervalServer"), severity: "error" })
              break;
            case (400):
              setAlert({ alertCheck: true, message: t("AM_BadRequest"), severity: "error" })
              break;
            default:
              setAlert({ alertCheck: true, message: t("alert_defualt"), severity: "error" })
          }
          setBtnSubmit(false)
          setLoading(false)
        }
      )
  }

  const resizeTextFiled = () => {
    if (window.innerWidth < 900 && textFieldSize === 'medium') {
      setTextFiledSize('small')
    }
    else if (window.innerWidth > 900 && textFieldSize === 'small') {
      setTextFiledSize('medium')
    }
  }

  const numberTextualContentHandeler = (e) => {
    let value = onlyFloatnaumber(e.target.value);
    setNumberTextualContent(value)
  }

  const numberPhotoContentHandeler = (e) => {
    let value = onlyFloatnaumber(e.target.value);
    setNumberPhotoContent(value)
  }

  const numberVideoContentHandeler = (e) => {
    let value = onlyFloatnaumber(e.target.value);
    setNumberVideoContent(value)
  }

  const minimumMonthlyBudgetHandeler = (e) => {
    let value = onlyFloatnaumber(e.target.value);
    setMinimumMonthlyBudget(value)
  }

  const maximumMonthlyBudgetHandeler = (e) => {
    let value = onlyFloatnaumber(e.target.value);
    setMaximumMonthlyBudget(value)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };

  return (
    info === null ?
      <Loader />
      :
      info.active === 0 ?
        <Typography sx={{ fontSize: { xs: "12px", sm: "14px", md: "18px" }, margin: "20px" }}>{t("compeleteInfo")}</Typography>
        :
        <>
          <Grid sx={{ display: 'flex', width: "100%", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: 'center' }}>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", marginTop: { xs: "0", md: "2%" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("site_name")}</Typography>
                  <Tooltip title={t("tooltipSiteName")} >
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  value={siteName}
                  onChange={(e) => setSiteName(e.target.value)}
                  fullWidth
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("site_address")}</Typography>
                  <Tooltip title={t("tooltipSiteAddress")} >
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  value={addressSite}
                  onChange={(e) => setAddressSite(e.target.value)}
                  fullWidth
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", marginTop: { xs: "0", md: "2%" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("mainKeyword")}</Typography>
                  <Tooltip title={t("tooltipMainKeyword")} >
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  fullWidth
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("numberTextualContent")}</Typography>
                  <Tooltip title={t("tooltipNumberTextualContent")} >
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  value={separator(numberTextualContent)}
                  onChange={(e) => numberTextualContentHandeler(e)}
                  fullWidth
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", marginTop: { xs: "0", md: "2%" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("numberPhotoContent")}</Typography>
                  <Tooltip title={t("tooltipNumberPhotoContent")} >
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  fullWidth
                  value={separator(numberPhotoContent)}
                  onChange={(e) => numberPhotoContentHandeler(e)}
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("numberVideoContent")}</Typography>
                  <Tooltip title={t("tooltipNumberVideoContent")} >
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  value={separator(numberVideoContent)}
                  onChange={(e) => numberVideoContentHandeler(e)}
                  fullWidth
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", marginTop: { xs: "0", md: "2%" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("minimumMonthlyBudgetLabel")}</Typography>
                  <Tooltip title={t("tooltipSiteMinimumBudget")} >
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  value={separator(minimumMonthlyBudget)}
                  onChange={(e) => minimumMonthlyBudgetHandeler(e)}
                  fullWidth
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("maximumMonthlyBudgetLabel")}</Typography>
                  <Tooltip title={t("tooltipSiteMaximumBudget")} >
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <TextField
                  className="textField"
                  variant='outlined'
                  fullWidth
                  value={separator(maximumMonthlyBudget)}
                  onChange={(e) => maximumMonthlyBudgetHandeler(e)}
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", marginTop: { xs: "0", md: "2%" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("activity")}</Typography>
                  <Tooltip title={t("tooltipActivitySeo")} >
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <FormControl sx={{ m: 1, width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }, }}
                  size={textFieldSize}>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={activity}
                    onChange={(event) => setActivity(event.target.value)}
                    sx={{ "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important" } }}
                  >
                    {
                      dataActivity !== null ?
                        dataActivity.map(element => (
                          <MenuItem key={element.id} value={element.id} className="menuItem">{element.description}</MenuItem>
                        ))
                        :
                        null
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column", width: { xs: "95%", md: "90%" }, alignItems: 'flex-start', marginTop: { xs: "0", md: "2%" }, justifyContent: 'center', marginBottom: { xs: "5%", sm: "0" } }}>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "15px 5px 5px", md: "5px 20px 5px 0px" } }}>{t("description")}</Typography>
                  <Tooltip title={t("tooltipDescriptionSeo")} >
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: "20px", color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              <TextField
                className="textField"
                variant='outlined'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                fullWidth
                multiline
                rows={4}
                sx={{
                  width: "100%", background: "#FFFFFF",
                  boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}
              />
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column", marginTop: "40px", width: { xs: "95%", md: "90%" }, alignItems: 'center', justifyContent: 'center' }}>
              <Button onClick={submitHandeler} disabled={btnSubmit ? true : false} sx={{
                background: "#FF3951", boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", color: "#FFFFFF", fontSize: { xs: "12px", sm: "16px", md: "18px" },
                width: { xs: i18n.language === "fa" ? "100px" : "125px", sm: "159px", md: "193px" }, height: { xs: i18n.language === "fa" ? '33px' : "40px", sm: '50px', md: "60px" }, "&:hover": { backgroundColor: "#FF3951" }, textTransform: "lowercase"
              }}>
                {btnSubmit === true && loading === true ? <CircularProgress size={20} color="inherit" sx={{ color: "white" }} /> : t("submitInfo")}
              </Button>
            </Grid>
          </Grid >
          <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
        </>
  )
}

export default OrderSeo;