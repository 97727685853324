import React from 'react';
import { SvgIcon } from '@mui/material';
const StageIcon = ({ color }) => {
  return (
    <SvgIcon component="object" sx={{ height: "30px" }}>
      <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.75868 13.8221H5.75868V12.8221V11.1337H7.44682H8.44682V10.1337V8.4435H10.135H11.135V7.4435V5.75451H12.8231H13.8231V4.75451V3.06552H15V15H3.06992V13.8221H4.75868Z" stroke={color} strokeWidth="2" />
        <path d="M11.3535 1.35358L1.35352 11.3536" stroke={color} strokeLinecap="round" />
      </svg>
    </SvgIcon>
  );
};
export default StageIcon;