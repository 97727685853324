import DashboardIcon from "../image/dashboard/DashboardIcon";
import InfoIcon from '../image/dashboard/InfoIcon';
import orderUiIcon from '../image/dashboard/OrdeUiIcon';
import OrderProgrammerIcon from '../image/dashboard/OrderProgrammerIcon';
import SeoIcon from '../image/dashboard/SeoIcon';
import StatusIcon from '../image/dashboard/StatusIcon';
import TicketIcon from "../image/Nav/TicketIcon";
import LogoutIcon from "../image/dashboard/LogoutIcon";
import ApplicationIcon from "../image/dashboard/ApplicationIcon";
import NewUserIcon from "../image/dashboard/NewUserIcon";
import NewOrderIcon from "../image/dashboard/NewOrderIcon";
import OldOrderIcon from "../image/dashboard/OldOrderIcon";
import ContactUsMessageIcon from "../image/dashboard/ContactUsMessageIcon";
import AreasActivityIcon from "../image/dashboard/AreasActivityIcon";
import TypeSiteIcon from "../image/dashboard/TypeSiteIcon";
import AllGraphicIcon from "../image/dashboard/AllGraphicIcon";
import StageIcon from "../image/dashboard/StageIcon";
import PalletIcon from "../image/dashboard/PalletIcon";
import SiteIcon from "../image/Nav/SiteIcon";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import searchUserIcon from '../image/dashboard/searchUserIcon.js'
export const dataUser = [
  {
    id: 1,
    text: "Dashboard",
    icon: DashboardIcon
  },
  {
    id: 2,
    text: "UserInfo",
    icon: InfoIcon
  },
  {
    id: 3,
    text: "WebsiteOrder",
    icon: SiteIcon
  },
  {
    id: 4,
    text: "AppOrder",
    icon: ApplicationIcon
  },
  {
    id: 5,
    text: "UIUXorder",
    icon: orderUiIcon
  },
  {
    id: 6,
    text: "ProgrammingOrder",
    icon: OrderProgrammerIcon
  },
  {
    id: 7,
    text: "SeoOrder",
    icon: SeoIcon
  },
  {
    id: 8,
    text: "GraphicOrder",
    icon: PalletIcon
  },
  {
    id: 9,
    text: "OrderStatus",
    icon: StatusIcon
  },
  {
    id: 10,
    text: "Tickets",
    icon: TicketIcon
  },
  {
    id: 11,
    text: "logout",
    icon: LogoutIcon
  },

]

export const dataSupporter = [
  {
    id: 1,
    text: "NewUser",
    icon: NewUserIcon
  },
  {
    id: 11,
    text: "SearchUser",
    icon: searchUserIcon
  },
  {
    id: 2,
    text: "NewOrder",
    icon: NewOrderIcon
  },
  {
    id: 3,
    text: "OrderHistory",
    icon: OldOrderIcon
  },
  {
    id: 4,
    text: "Tickets",
    icon: TicketIcon
  },
  {
    id: 5,
    text: "ContactSorin",
    icon: ContactUsMessageIcon
  },
  {
    id: 6,
    text: "AreasActivity",
    icon: AreasActivityIcon
  },
  {
    id: 7,
    text: "SiteType",
    icon: TypeSiteIcon
  },
  {
    id: 8,
    text: "GraphicType",
    icon: AllGraphicIcon
  },
  {
    id: 9,
    text: "ProjectStatus",
    icon: StageIcon
  },
  {
    id: 10,
    text: "logout",
    icon: LogoutIcon
  },
]
