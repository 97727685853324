import { SvgIcon } from '@mui/material';
import React from 'react';
const PersonIcon = ({ color }) => {
  return (
    <SvgIcon component="object" sx={{ height: "30px" }}>
      <svg height="17" width="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 14.7333V17H0V14.7333C0 14.7333 0 10.2 7 10.2C14 10.2 14 14.7333 14 14.7333ZM10.5 3.96671C10.5 3.18218 10.2947 2.41527 9.91014 1.76296C9.52556 1.11065 8.97893 0.60223 8.33939 0.302003C7.69985 0.0017767 6.99612 -0.0767763 6.31718 0.0762779C5.63825 0.229332 5.01461 0.607119 4.52513 1.16187C4.03564 1.71661 3.7023 2.4234 3.56725 3.19286C3.4322 3.96231 3.50152 4.75987 3.76642 5.48468C4.03133 6.2095 4.47993 6.829 5.0555 7.26486C5.63108 7.70073 6.30777 7.93337 7 7.93337C7.92826 7.93337 8.8185 7.51545 9.47487 6.77156C10.1313 6.02767 10.5 5.01873 10.5 3.96671ZM13.94 10.2C14.5547 10.7392 15.0578 11.4245 15.4137 12.2077C15.7696 12.9909 15.9697 13.853 16 14.7333V17H20V14.7333C20 14.7333 20 10.6194 13.94 10.2ZM13 5.95893e-05C12.3117 -0.00428414 11.6385 0.228942 11.07 0.668724C11.6774 1.63062 12.0041 2.78384 12.0041 3.96671C12.0041 5.14958 11.6774 6.3028 11.07 7.2647C11.6385 7.70448 12.3117 7.93771 13 7.93337C13.9283 7.93337 14.8185 7.51545 15.4749 6.77156C16.1313 6.02767 16.5 5.01873 16.5 3.96671C16.5 2.91469 16.1313 1.90576 15.4749 1.16187C14.8185 0.417974 13.9283 5.95893e-05 13 5.95893e-05Z" fill={color} />
      </svg>
    </SvgIcon>
  );
};
export default PersonIcon;