import { Grid, TextField, Typography, FormControl, Select, MenuItem, Button, Input, IconButton, CircularProgress, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useTranslation } from "react-i18next";
import AlertMessage from "./modules/AlertMessage";
import axios from "axios";
import { baseUrl } from "./modules/general";
import Resizer from "react-image-file-resizer";
import { setCanonicalTag } from "./modules/general";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

let infoUser = null;
let imageFile = null;
let imageResize = null;
const PersonalInformation = () => {
  const { t, i18n } = useTranslation();
  const [panel, setPanel] = useState(null);
  const [textFieldSize, setTextFiledSize] = useState(window.innerWidth <= 900 ? "small" : "medium");
  const [nameOrganization, setNameOrganization] = useState("");
  const [nameOfCEO, setNameOfCEO] = useState("");
  const [nameOrganizationLegalRepresentative, setNameOrganizationLegalRepresentative] = useState("");
  const [address, setAddress] = useState("");
  const [activity, setActivity] = useState("");
  const [logo, setLogo] = useState("");
  const [alert, setAlert] = useState({ alertCheck: false, message: "", severity: "" });
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("")
  const [btnSubmit, setBtnSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataActivity, setDataActivity] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    document.title = "اطلاعات کاربری در سایت سورین | اطلاعات کاربر سفارش دهنده سایت | اطلاعات درخواست کننده طراحی سایت | وب گستران سورین";
    document.getElementById("main-heading").innerHTML = "اطلاعات کاربری در سایت سورین | اطلاعات کاربر سفارش دهنده سایت | اطلاعات درخواست کننده طراحی سایت | وب گستران سورین";
    document.getElementsByTagName('meta')["description"].content = "این صفحه مخصوص کاربرانی از وب گستران سورین می باشد که درخواست هایی نظیر : طراحی سایت، برنامه نویسی پیشرفته، طراحی رابط کاربری، طراحی تجربه کاربری، طراحی UI/UX، طراحی اپلیکیشن موبایل، سئو سایت و طراحی انواع محتوای گرافیکی مثل طراحی لوگو، موشن، بنر،کاتالوگ دارند.";
    document.getElementsByTagName('meta')["keywords"].content = "اطلاعات کاربر در بهترین شرکت طراحی سایت در تهران | ";
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین";
    setCanonicalTag()
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => resizeTextFiled());
    return (() => window.removeEventListener('resize', () => resizeTextFiled()));
  }, [window.innerWidth]);

  useEffect(() => {
    infoUser = JSON.parse(localStorage.getItem('sorinInfoUser'));
    setPanel(infoUser !== null ? infoUser.type.id : null);

    if ((infoUser !== null) && (infoUser.type.id === 1)) {
      setName(infoUser.name)
      setLastName(infoUser.lastname === null ? "" : infoUser.lastname)
      setAddress(infoUser.address === null ? "" : infoUser.address)
      setActivity(infoUser.activity === null ? "" : infoUser.activity.id)
      setLogo(infoUser.picture_path === null ? "" : infoUser.picture_path)
      setAddress(infoUser.address === null ? "" : infoUser.address)
    }
    else if ((infoUser !== null) && (infoUser.type.id === 2)) {
      setNameOrganization(infoUser.organ_name === null ? "" : infoUser.organ_name)
      setNameOfCEO(infoUser.organ_manager_name === null ? "" : infoUser.organ_manager_name)
      setNameOrganizationLegalRepresentative(infoUser.organ_legal_representative_name === null ? "" : infoUser.organ_legal_representative_name)
      setLogo(infoUser.picture_path === null ? "" : infoUser.picture_path)
      setActivity(infoUser.activity === null ? "" : infoUser.activity.id)
      setAddress(infoUser.address === null ? "" : infoUser.address)
    }
  }, []);

  useEffect(() => {
    getActivity();
  }, [])

  const resizeTextFiled = () => {
    if (window.innerWidth < 900 && textFieldSize === 'medium') {
      setTextFiledSize('small')
    }
    else if (window.innerWidth > 900 && textFieldSize === 'small') {
      setTextFiledSize('medium')
    }
  }


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };

  const handeleChangeActivity = (event) => {
    setActivity(event.target.value)
  }

  const getActivity = () => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/activity/get`,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setDataActivity(res.data.data)
      })
  }

  const onChangeLicence = (e) => {
    if (e.target.files[0]) {
      imageFile = e.target.files[0];
      if (imageFile.size > 10000) {
        setAlert({ alertCheck: true, message: t("alert_sizeImageLarge"), severity: "error" })
      }
      else {
        setLogo(imageFile.name)
        imageResizer()
      }
    }
  }

  const imageResizer = () => {
    try {
      Resizer.imageFileResizer(
        imageFile,
        400,
        400,
        "png",
        100,
        0,
        (uri) => {
          let reader = new FileReader();
          reader.readAsDataURL(uri);
          reader.onload = () => {
            setImageSrc(reader.result)
          }
          imageResize = uri;
        },
        "file",
      );
    } catch (err) {
      setAlert({ alertCheck: true, message: t("alert_notPic"), severity: "error" })
    }
  }

  const addImageAgancy = () => {
    const formData = new FormData();
    formData.append("image", imageResize);
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + "/api/v1/user/addImage",
      method: "POST",
      headers: {
        'Authorization': AuthStr,
        "Content-Type": "multipart/form-data"
      },
      data: formData
    }))
      .then((res) => {
        setAlert({ alertCheck: true, message: t("alert_PicSuccess"), severity: "success" })
        setLoading(false)
        setBtnSubmit(false)
      })
      .catch((err) => {
        setAlert({ alertCheck: true, message: t("alert_PicFaild"), severity: "error" })
        setLoading(false)
        setBtnSubmit(false)
      });
  }

  const submitData = (data) => {
    setLoading(true)
    setBtnSubmit(true)
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + '/api/v1/user/edit',
      method: "POST",
      data: data,
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setAlert({ alertCheck: true, message: t("alert_dataSuccess"), severity: "success" })
        if (imageResize !== null) {
          addImageAgancy()
        }
        else {
          setLoading(false)
          setBtnSubmit(false)
        }
      })
      .catch(
        (error) => {
          const { status } = error.response;
          switch (status) {
            case (500):
              setAlert({ alertCheck: true, message: t("AM_intervalServer"), severity: "error" })
              break;
            case (400):
              setAlert({ alertCheck: true, message: t("AM_BadRequest"), severity: "error" })
              break;
            default:
              setAlert({ alertCheck: true, message: t("alert_defualt"), severity: "error" })
          }
          if (imageResize !== null) {
            addImageAgancy()
          }
          else {
            setLoading(false)
            setBtnSubmit(false)
          }
        }
      )
  }

  const submitInfo = (type) => {
    let data;
    if (type === "O") {
      if (nameOrganization === "") {
        setAlert({ alertCheck: true, message: t("alert_nameOrganzationFaild"), severity: "error" })
      } else {
        data = {
          organ_name: nameOrganization,
          organ_activity_id: activity,
          organ_manager_name: nameOfCEO,
          organ_legal_representative_name: nameOrganizationLegalRepresentative,
          address: address
        }
        submitData(data);
      }
    } else if (type === "U") {
      if ((name === "") || (lastName === "")) {
        setAlert({ alertCheck: true, message: t("alert_FullName"), severity: "error" })
      } else {
        data = {
          name: name,
          lastname: lastName,
          organ_activity_id: activity,
          address: address
        }
        submitData(data);
      }
    }
  }

  return (
    panel === 2 ?
      <>
        <Grid sx={{ display: 'flex', width: "100%", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", direction: i18n.language === "fa" ? "rtl" : "ltr" }}>
          <Grid sx={{ display: "flex", alignItems: 'center', padding: { xs: "0", md: "40px 5% 100px 5%" }, width: { xs: "90%", md: "100%" }, justifyContent: "flex-start" }}>
            <Typography sx={{ fontSize: { xs: "16px", sm: "18px", md: "32px" }, lineHeight: "45px", fontWeight: "700", color: "#00000" }}>{t("organizationalInformation")}</Typography>
          </Grid>
          <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
            <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
            <Grid sx={{ display: "flex", alignItems: { xs: "flex-end", sm: "center" }, justifyContent: "flex-start", width: "100%" }}>
              <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "10px 5px 5px 0px", md: "5px 20px 5px 0" } }}>{t("nameOrganization")}</Typography>
              <Tooltip title={t("tooltipNameOrganization")}>
                <IconButton>
                  <HelpOutlineIcon sx={{ fontSize: { xs: "15px", md: "20px" }, color: "#FF3951" }} />
                </IconButton>
              </Tooltip>
            </Grid>
              <TextField
                className="textField"
                variant='outlined'
                value={nameOrganization}
                onChange={(e) => setNameOrganization(e.target.value)}
                size={textFieldSize}
                InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                fullWidth
                sx={{
                  width: "100%", background: "#FFFFFF",
                  boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}
              />
            </Grid>
            <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
            <Grid sx={{ display: "flex", alignItems: { xs: "flex-end", sm: "center" }, justifyContent: "flex-start", width: "100%" }}>
              <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "10px 5px 5px 0px", md: "5px 20px 5px 0" } }}>{t("nameOfCEO")}</Typography>
              <Tooltip title={t("tooltipNameOfCEO")}>
                <IconButton>
                  <HelpOutlineIcon sx={{ fontSize: { xs: "15px", md: "20px" }, color: "#FF3951" }} />
                </IconButton>
              </Tooltip>
            </Grid>
              <TextField
                className="textField"
                variant='outlined'
                value={nameOfCEO}
                onChange={(e) => setNameOfCEO(e.target.value)}
                fullWidth
                InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                size={textFieldSize}
                sx={{
                  width: "100%", background: "#FFFFFF",
                  boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}
              />
            </Grid>
          </Grid>
          <Grid sx={{ display: 'flex', alignItems: "center", marginTop: { xs: "0", md: "3%" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" }, justifyContent: "space-between" }}>
            <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
              <Grid sx={{ display: "flex", alignItems: { xs: "flex-end", sm: "center" }, justifyContent: "flex-start", width: "100%" }}>
              <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "10px 5px 5px 0px", md: "5px 20px 5px 0" } }}>{t("nameOrganizationLegalRepresentative")}</Typography>
              <Tooltip title={t("tooltipNameOrganizationLegalRepresentative")}>
                <IconButton>
                  <HelpOutlineIcon sx={{ fontSize: { xs: "15px", md: "20px" }, color: "#FF3951" }} />
                </IconButton>
              </Tooltip>
            </Grid>
              <TextField
                value={nameOrganizationLegalRepresentative}
                onChange={(e) => setNameOrganizationLegalRepresentative(e.target.value)}
                className="textField"
                variant='outlined'
                fullWidth
                InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                size={textFieldSize}
                sx={{
                  width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}
              />
            </Grid>
            <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
              <Grid sx={{ display: "flex", alignItems: { xs: "flex-end", sm: "center" }, justifyContent: "flex-start", width: "100%" }}>
                <Typography sx={{ fontSize: "16px", color: "#070707", padding: { xs: "10px 5px 5px 0px", md: "5px 20px 5px 0" } }}>{t("activity")}</Typography>
                <Tooltip title={t("tooltipActivityOrgenaize")}>
                  <IconButton>
                    <HelpOutlineIcon sx={{ fontSize: { xs: "15px", md: "20px" }, color: "#FF3951" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <FormControl sx={{
                m: 1, width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px",
                margin: { xs: "0 0 2% 0", md: "0px 10px" }
              }}
                size={textFieldSize}>
                <Select
                  value={activity}
                  onChange={handeleChangeActivity}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  sx={{ "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important" } }}

                >
                  {
                    dataActivity !== null ?
                      dataActivity.map(element => (
                        <MenuItem key={element.id} value={element.id} className="menuItem">{element.description}</MenuItem>
                      ))
                      :
                      null
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid sx={{ display: 'flex', alignItems: "center", marginTop: { xs: "0", md: "3%" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" }, justifyContent: "space-between" }}>
            <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
              <Grid sx={{ display: "flex", alignItems: { xs: "flex-end", sm: "center" }, justifyContent: "flex-start", width: "100%" }}>
                <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "10px 5px 5px 0px", md: "5px 20px 5px 0" } }}>{t("logo")}</Typography>
                <Tooltip title={t("alert_sizeImageLarge")}>
                  <IconButton>
                    <HelpOutlineIcon sx={{ fontSize: { xs: "15px", md: "20px" }, color: "#FF3951" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid sx={{
                display: "flex", width: "100%", background: "#FFFFFF", height: { xs: "40px", md: "56px" }, boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
                borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
              }}>
                <Input id="upload-logo" type="file" sx={{ display: "none" }} onChange={(e) => onChangeLicence(e)} />
                <Typography component={"label"} htmlFor="upload-logo" sx={{ display: "flex", alignItems: "center", width: "100%", padding: "0 10px" }} >
                  <IconButton component="span" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", "&:hover": { backgroundColor: "transparent" } }} >
                    <Grid sx={{
                      display: 'flex', alignItems: 'center', justifyContent: 'center', width: { xs: "69px", sm: "59px" }, margin: "0 10px",
                      borderRadius: "4px", backgroundColor: "#FFFFFF"
                    }}>
                      <Grid
                        sx={{
                          width: { xs: "40px", sm: "50px" }, height: { xs: "40px", sm: "40px" }, margin: "0 10px", borderRadius: "4px"
                          , background: `url(${imageSrc !== null ? imageSrc : infoUser.logo_path !== null ? logo : ""})`, backgroundPosition: "center", backgroundSize: "100% 100%",
                        }} ></Grid>
                    </Grid>
                    <CloudUploadOutlinedIcon sx={{ color: "#000000" }} />
                  </IconButton>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ display: "flex", flexDirection: "column", width: { xs: "95%", md: "90%" }, alignItems: 'flex-start', marginTop: { xs: "0", md: "3%" }, justifyContent: 'center', marginBottom: { xs: "5%", sm: "0" } }}>
            <Grid sx={{ display: "flex", alignItems: { xs: "flex-end", sm: "center" }, justifyContent: "flex-start", width: "100%" }}>
              <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "10px 5px 5px 0px", md: "5px 20px 5px 0" } }}>{t("fieldAddress")}</Typography>
              <Tooltip title={t("tooltipAddressOrgenaize")}>
                <IconButton>
                  <HelpOutlineIcon sx={{ fontSize: { xs: "15px", md: "20px" }, color: "#FF3951" }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <TextField
              className="textField"
              variant='outlined'
              value={address}
              onChange={(event) => setAddress(event.target.value)}
              fullWidth
              InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
              size={textFieldSize}
              sx={{
                display: { xs: "none", md: "flex" },
                width: "100%", background: "#FFFFFF",
                boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
              }}
            />
            <TextField
              className="textField"
              variant='outlined'
              InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
              value={address}
              onChange={(event) => setAddress(event.target.value)}
              fullWidth
              multiline
              rows={2}
              sx={{
                display: { xs: "flex", md: "none" },
                width: "100%", background: "#FFFFFF",
                boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
              }}
            />
          </Grid>
          <Grid sx={{ display: "flex", flexDirection: "column", width: { xs: "95%", md: "90%" }, alignItems: 'center', marginTop: "40px", justifyContent: 'center' }}>
            <Button onClick={() => submitInfo("O")} disabled={btnSubmit ? true : false} sx={{
              background: "#FF3951", boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", color: "#FFFFFF", fontSize: { xs: "12px", sm: "16px", md: "18px" },
              width: { xs: "100px", sm: "159px", md: "193px" }, height: { xs: '33px', sm: '50px', md: "60px" }, "&:hover": { backgroundColor: "#FF3951" }, textTransform: "lowercase"
            }}>
              {btnSubmit === true && loading === true ? <CircularProgress size={20} color="inherit" sx={{ color: "white" }} /> : t("submitInfo")}
            </Button>
          </Grid>
        </Grid>
        <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
      </>
      :
      panel === 1 ?
        <>
          <Grid sx={{ display: 'flex', width: "100%", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: 'flex-start' }}>
            <Grid sx={{ display: "flex", alignItems: 'center', padding: { xs: "5% 0", md: "40px 5% 150px 5%" }, width: { xs: "95%", md: "100%" }, justifyContent: "flex-start" }}>
              <Typography sx={{ fontSize: { xs: "16px", sm: "18px", md: "32px" }, lineHeight: "45px", fontWeight: "700", color: "#00000" }}>{t("UserInfo")}</Typography>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" } }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "10px 5px 5px", md: "5px 20px" } }}>{t("name")}</Typography>
                <TextField
                  className="textField"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  variant='outlined'
                  fullWidth
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "10px 5px 5px", md: "5px 20px" } }}>{t("lastName")}</Typography>
                <TextField
                  className="textField"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  variant='outlined'
                  fullWidth
                  InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                  size={textFieldSize}
                  sx={{
                    width: { md: "100%" }, background: "#FFFFFF",
                    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: "center", marginTop: { xs: "0", md: "5%" }, flexDirection: { xs: "column", md: "row" }, width: { xs: "100%", md: "90%" }, justifyContent: "space-between" }}>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: { xs: "flex-end", sm: "center" }, justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "10px 5px 5px 0px", md: "5px 20px 5px 0" } }}>{t("profilePicture")}</Typography>
                  <Tooltip title={t("alert_sizeImageLarge")}>
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: { xs: "15px", md: "20px" }, color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid sx={{
                  display: "flex", width: "100%", background: "#FFFFFF", height: { xs: "40px", md: "56px" }, boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}>
                  <Input id="upload-logo" type="file" sx={{ display: "none" }} onChange={(e) => onChangeLicence(e)} accept="image/*"
                  />
                  <Typography component={"label"} htmlFor="upload-logo" sx={{ display: "flex", alignItems: "center", width: "100%", padding: "0 10px" }} >
                    <IconButton component="span" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", "&:hover": { backgroundColor: "transparent" } }} >
                      <Grid sx={{
                        display: 'flex', alignItems: 'center', justifyContent: 'center', width: { xs: "69px", sm: "59px" }, margin: "0 10px",
                        borderRadius: "4px", backgroundColor: "#FFFFFF"
                      }}>
                        <Grid
                          sx={{
                            width: { xs: "40px", sm: "50px" }, height: { xs: "40px", sm: "40px" }, margin: "0 10px", borderRadius: "4px"
                            , background: `url(${imageSrc !== null ? imageSrc : infoUser.logo_path !== null ? logo : ""})`, backgroundPosition: "center", backgroundSize: "100% 100%",
                          }} ></Grid>
                      </Grid>
                      <CloudUploadOutlinedIcon sx={{ color: "#000000" }} />
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: 'flex-start', flexDirection: "column", width: { xs: "95%", md: "45%" } }}>
                <Grid sx={{ display: "flex", alignItems: { xs: "flex-end", sm: "center" }, justifyContent: "flex-start", width: "100%" }}>
                  <Typography sx={{ fontSize: "16px", color: "#070707", padding: { xs: "10px 5px 5px 0px", md: "5px 20px 5px 0" } }}>{t("activity")}</Typography>
                  <Tooltip title="Delete">
                    <IconButton>
                      <HelpOutlineIcon sx={{ fontSize: { xs: "15px", md: "20px" }, color: "#FF3951" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <FormControl sx={{ m: 1, width: "100%", background: "#FFFFFF", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }, }}
                  size={textFieldSize}>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={activity}
                    onChange={handeleChangeActivity}
                    sx={{
                      "& .MuiSvgIcon-root": { left: i18n.language === "fa" ? "7px !important" : "unset !important", right: i18n.language === "fa" ? "unset !important" : "7px !important" },
                      '& .Mui-selected': {
                        backgroundColor: 'red',
                        color: 'black'
                      },
                    }}
                  >
                    {
                      dataActivity !== null ?
                        dataActivity.map(element => (
                          <MenuItem key={element.id} value={element.id} className="menuItem">{element.description}</MenuItem>
                        ))
                        :
                        null
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column", width: { xs: "95%", md: "90%" }, alignItems: 'flex-start', marginTop: { xs: "0", md: "5%" }, justifyContent: 'center' }}>
              <Grid sx={{ display: "flex", alignItems: { xs: "flex-end", sm: "center" }, justifyContent: "flex-start", width: "100%" }}>
                <Typography sx={{ fontSize: { xs: "12px", sm: "16px" }, color: "#070707", padding: { xs: "10px 5px 5px 0px", md: "5px 20px 5px 0" } }}>{t("fieldAddress")}</Typography>
                <Tooltip title={t("tooltipAddress")}>
                  <IconButton>
                    <HelpOutlineIcon sx={{ fontSize: { xs: "15px", md: "20px" }, color: "#FF3951" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <TextField
                className="textField"
                variant='outlined'
                fullWidth
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                size={textFieldSize}
                sx={{
                  display: { xs: "none", md: "flex" },
                  width: { xs: "100%", md: "100%" }, background: "#FFFFFF",
                  boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}
              />
              <TextField
                className="textField"
                variant='outlined'
                InputLabelProps={{ sx: { fontSize: { xs: "12px", sm: "14px", } } }}
                fullWidth
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                multiline
                rows={3}
                sx={{
                  display: { xs: "flex", md: "none" },
                  width: { xs: "100%", md: "100%" }, background: "#FFFFFF",
                  boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)", borderRadius: "8px", margin: { xs: "0 0 2% 0", md: "0px 10px" }
                }}
              />
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "column", width: { xs: "95%", md: "90%" }, alignItems: 'center', marginTop: "40px", justifyContent: 'center' }}>
              <Button onClick={() => submitInfo("U")} disabled={btnSubmit ? true : false} sx={{
                background: "#FF3951", boxShadow: "0px 0px 16px #FF3951", borderRadius: "8px", color: "#FFFFFF", fontSize: { xs: "12px", sm: "16px", md: "18px" },
                width: { xs: i18n.language === "fa" ? "100px" : "125px", sm: "159px", md: "193px" }, height: { xs: i18n.language === "fa" ? '33px' : "40px", sm: '50px', md: "60px" }, "&:hover": { backgroundColor: "#FF3951" }, textTransform: "lowercase"
              }}>
                {btnSubmit === true && loading === true ? <CircularProgress size={20} color="inherit" sx={{ color: "white" }} /> : t("submitInfo")}
              </Button>
            </Grid>
          </Grid>
          <AlertMessage open={alert.alertCheck} message={alert.message} duration={3000} close={handleClose} severity={alert.severity} />
        </>
        :
        null
  )
}

export default PersonalInformation;