import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import { author, date, h1, headPhoto, largePhoto, linkArticle, rootArticle, tags, paragraph, timeRead, view, listItemCircle, h2, h4, smallPhoto, sourceLink } from "../articleFunction/ArticleFunction";
import axios from "axios";
import { baseUrl } from "../modules/general";
import { setCanonicalTag } from "../modules/general";
import { Articles } from "./Articles";
import ArticleSide from "../articleFunction/ArticleSide";
import { NavLink } from "react-router-dom";
//import photo
import photo1 from '../../image/articles/07/photo1.jpg';
import photo2 from '../../image/articles/07/photo2.jpg';
import photo3 from '../../image/articles/07/photo3.jpg';
import photo4 from '../../image/articles/07/photo4.jpg';
import photo5 from '../../image/articles/07/photo5.jpg';
import photo6 from '../../image/articles/07/photo6.jpg';
import photo7 from '../../image/articles/07/photo7.jpg';
import photo8 from '../../image/articles/07/photo8.jpg';
import photo9 from '../../image/articles/07/photo9.jpg';
import photo10 from '../../image/articles/07/photo10.jpg';
import Loader from "../Loader";
import PortfolioButton from "../PortfolioButton";


let articleData = null;
const Article07 = () => {

  //NOT CHANGE!!!!!!!!!
  const { t, i18n } = useTranslation();
  const [dataview, setDataView] = useState("");
  const url = window.location.href;

  useEffect(() => {
    document.title = `وب گستران سورین | ${articleData !== null && articleData.title}`
    document.getElementById("main-heading").innerHTML = articleData !== null && articleData.h1;
    document.getElementsByTagName('meta')["description"].content = articleData !== null && articleData.desc;
    document.getElementsByTagName('meta')["keywords"].content = articleData !== null && articleData.keywords;
    document.getElementsByTagName('meta')["author"].content = articleData !== null && articleData.author;
    setCanonicalTag()
  }, [articleData]);

  useEffect(() => {
    let e = window.location.href.split("/");
    let p = `/${e[4]}`;
    Articles.forEach((item) => {
      if (item.link === p) articleData = item
    })
  }, [])

  useEffect(() => {
    articleData !== null && addView(); getView();
  }, [])

  const addView = () => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v1/article/addView",
      method: "POST",
      data: { id: articleData.id }
    }))
      .then((res) => { })
  }
  const getView = () => {
    Promise.resolve(axios({
      url: `${baseUrl}/api/v1/article/getView`,
      method: "GET",
      params: { id: articleData.id }
    }))
      .then((res) => {
        setDataView(res.data.data)
      })
  }

  //change This part

  //read More
  const readMore = [
    {
      id: 1,
      title: "اپلیکیشن چیست؟ هر آنچه در مورد نرم افزار کاربردی باید بدانید",
      link: "/what-is-application"
    },
    {
      id: 2,
      title: "نکاتی که باید برای سفارش طراحی وب سایت در نظر گرفت",
      link: "/Points_to_consider_when_ordering_a_website_design"
    }

  ]
  return (
    <>
      <Grid sx={{ display: "flex", flexDirection: 'column', width: "100%", direction: "rtl", alignItems: "center" }}>
        {articleData !== null && rootArticle(articleData.title, t)}
        <Grid container sx={{ display: "flex", alignItems: { xs: "unset", sm: 'flex-start' }, justifyContent: "space-between", flexDirection: "row", flexWrap: "wrap", width: { xs: "100%", sm: "85%" } }}>
          <ArticleSide tags={articleData !== null && articleData.tags} readMore={readMore} />
          {
            articleData !== null ?
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: { xs: 'center', sm: "flex-end" }, flexDirection: "column", width: { xs: "100%", sm: "60%", md: "70%" } }} >
                {/* do not change */}

                {headPhoto(articleData.image)}
                {h1(articleData.h1, t)}
                {timeRead(articleData.timeRead, t)}
                {date(articleData.date, i18n)}
                <Grid sx={{ display: 'flex', alignItems: { xs: "center", sm: "flex-start" }, justifyContent: 'flex-start', width: { xs: "90%", sm: "100%" }, flexDirection: "column" }}>
                  {/* The text of the article */}
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    شاید شما در نظر دارید که کسب و کار آنلاین راه اندازی کنید و به شرکتی معتبر سفارش سایت دهید اما با خیلی از اصطلاحات طراحی وب سایت آشنایی ندارید. ما در این مقاله از <NavLink to="/Academy" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >آکادمی </NavLink>
                    سورین شما را با برخی از اصطلاحات پرکاربرد برنامه نویسی و طراحی سایت آشنا می کنیم.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    دامنه (domain)
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo1} alt="دامنه - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        دامین یا دامنه
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    اولین کار در طراحی سایت انتخاب نامی منحصر بفرد و پسوندی دلخواه برای دامنه می باشد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    دامنه در‌ حقیقت یک آی‌پی است که پروتکل DNS که وظیفه ی  اصلی‌آن تبدیل IP به اسم است، این کار را انجام می دهد و آدرس را ایجاد می‌کند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    domain همان آدرس سایت و یا برند کسب‌وکار شماست که در قسمت نوار آدرس قرار دارد و تنها راهی است که کاربران می توانند به سایت ورود کنند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    دامنه شامل URL  و آدرس شخصی وب‌سایت شما است. به‌عنوان‌مثال <NavLink to="https://sorinapp.com" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >www.sorinapp.com</NavLink>
                    یک دامنه یا دامین نام دارد.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    دامنه تنها راهی است که کاربران به شما متصل می‌شوند و همان کار IP ها را انجام می‌دهند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    هاست ( host) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    اطلاعات و محتوایی که ما در فضای اینترنت قرار می‌دهیم، احتیاج به بستری دارد که برای سایرین قابل‌مشاهده باشد؛ این بستر که وب‌سایت ما روی آن قرار می‌گیرد، میزبان یا هاست نام دارد. هاست ابرکامپیوتری است که اطلاعات چندین سایت با توجه به ظرفیتش بر روی آن قرار می گیرد. هاست‌ به شکل اختصاصی و اشتراکی می تواند استفاده شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    برنامه نویسی کامپیوتر
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo9} alt="برنامه نویسی - سورین " sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        برنامه نویسی کامپیوتر
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    به مجموعه اقدامات برنامه نویس ( مهندس نرم افزار) که به تولید نرم افزار کاربردی منجر می شود برنامه نویسی کامپیوتر می گویند. این برنامه نویسی به زبان ها و شاخه های مختلفی تقسیم بندی می شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    وب سایت (Web Site) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    به مجموعه ای از صفحات اینترنتی که هدف خاصی را دنبال می کنند و تماما با یک ادرس و IP شناخته می شوند و مربوط به یک کسب و کار می باشند، وب سایت یک برند می گویند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    کنترل پنل میزبانی وب :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    برای کنترل و بررسی هاست و صفحات وب، نیاز به محیطی داریم که بتوانیم مدیریت را در آن انجام دهیم. این ابزار در قالب یک فضای گرافیکی مناسب و کاربر پسند ارائه می شود که به آن کنترل پنل می گویند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start",width:"100%"}}>
                    لینک (Link) :
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo2} alt="لینک - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        لینک
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    به اتصال بین دو صفحه ی وب، لینک می گویند. با کلیک روی جمله یا کلمه‌ای که لینک شده است به صفحه ای جدید هدایت می شوید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    لینک داخلی (Interal Link) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    لینک‌های داخلی بین صفحات مختلف یک سایت ارتباط برقرار می‌کنند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start",width:"100%"}}>
                    لینک خارجی (Back Link) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    لینک خروجی یا بک‌لینک‌ها بین صفحات مختلف چندین وبسایت ارتباط برقرار می کند. لینک‌های خروجی اگر به شکل درست بکار بروند تأثیرخوبی بر سئو یک سایت دارند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    مرورگر (Browser) :
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo3} alt="مرورگر - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        مرورگر
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    مرورگرها نرم‌افزارهایی کاربردی می باشند که روی سیستم شما نصب می شوند و برای دریافت، ارسال ، نمایش و مرور اطلاعات، جستجوی وب‌سایت‌ها در وب از آن ها استفاده می شود.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    مرورگرها اطلاعات مورد نظر شما را از اینترنت جمع‌آوری می کنند و در اختیار شما قرار می‌دهند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start",width:"100%"}}>
                    آدرس سایت (URL) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    url مخفف کلمه Uniform Resource Locator آدرس اینترنتی می باشد که در قسمت بالای صفحه مشاهده می شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    دایرکتوری (directory) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    به سایت هایی که با هدف جمع آوری سایت های دیگر در دسته بندی های گوناگون تولید می شوند دایرکتوری می گویند. وب دایرکتوری‌ها، وب‌سایت‌ها را مطابق با موضوع جستجوی کاربر، نمایش می‌دهند. کاربران با ورود به وب دایرکتوری‌ها می توانند سایت های مرتبط با موضوع خود را بیابند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    پروتکل ارتباطی (http/https) :
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo4} alt="پروتکل ارتباطی - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        پروتکل ارتباطی
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    این پروتکل ارتباطی در ابتدای تمام وب‌سایت‌های معتبر قرار دارد. وجود این پروتکل ها نشان‌دهنده امنیت سایت می باشد. پروتکل‌ها مشخص می‌کنند که چگونه مرورگرها با سرور سایت برای ارسال و بازیابی اطلااعات ارتباط برقرار می کنند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    کوکی ها (Cookies) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    کوکی‌ها فایل‌هایی هستند که روی سیستم ذخیره می‌شوند و راه ارتباطی بین مرورگرها و سایت‌ها محسوب می شوند.
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    کوکی‌ها بسیار مهم هستند زیرا به کمک آنها، رفتار و علایق کاربر بررسی می شود و در بازدیدهای بعدی، بهتر آنها راهنمایی می شوند. از مزیت کوکی‌ها می توان این مورد را نام برد که اگر قبلاً در سایتی ثبت‌نام کرده باشید، در بازدیدهای بعدی نیاز به واردکردن مجدد مشخصات ندارید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    صفحه وب (Web page) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    صفحه وب، صفحه ای از وب‌سایت است که تمام اطلاعات در آنجا قرار دارد. انواع اطلاعات از جمله عنوان، متن، لیست، منو، عکس، موزیک، ویدیو می تواند در وب باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    وب سایت (Website) :
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo5} alt="وب سایت - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        وب سایت
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    به مجموعه ای از صفحات وب که بر روی یک دامنه قرار دارند وب سایت می‌گویند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    صفحۀ اصلی (Home page) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    اولین صفحه‌ای که بعد از واردکردن آدرس سایت وارد آن می‌شوید صفحه اصلی می گویند. در صفحه اصلی هر وب‌سایت، معمولاً اطلاعات کاملی در مورد کسب‌وکار، نوع فعالیت، توضیحات برند، محصولات و خدمات، آدرس و شماره‌ تلفن محل کار و موارد دیگر وجود دارد.
                  </Typography><Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    اینترو (Intro) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    به صفحه ای از سایت می گویند که قبل از ورود به صفحه اصلی به شکل یک انیمیشن جذاب برای خوش آمد گویی و تبلیغ به کاربران نمایش داده می‌شود. باید به این نکته توجه داشت که هر زمانی کاربر به سایت شما وارد می‌شود، نباید صفحه خوش آمد گویی و یا اینترو برای آن به نمایش درآید. اینترو زمانی کاربرد دارد که سایت شما دچار مشکل شده و در حال بازیابی آن هستید. ساختن یک صفحه اینترو که شامل انیمیشن، عکس یا متن جذاب است و در آن از کاربر درخواست می‌کنید که بعد از رفع مشکل به سایت وارد شود در این موارد کاربرد دارد.
                  </Typography><Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start",width:"100%"}}>
                    صفحات استاتیک (Static page):
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    به صفحاتی که اطلاعات ثابتی دارند و نیاز چندانی به تغییر آنها نیست صفحات ایستا می گویند مانند صفحات درباره ما و ارتباط با ما. در این صفحات امکان بروز رسانی و ویرایش توسط صاحب سایت وجود ندارد و تغییرات باید توسط طراح وب انجام شود. در طراحی صفحات ایستا، تنها از کدهای html و css استفاده می‌شود و در آدرس آنها تنها پسوند html دیده می شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start",width:"100%"}}>
                    صفحات پویا (Dynamic page) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    به صفحاتی که قابلیت تغییر دارند صفحات پویا گفته می شود مانند صفحات به‌اشتراک‌گذاری فایل و صفحات خرید و فروش محصول. تغییر محتوای صفحات داینامیک به‌صورت مکانیکی انجام می‌شود. در برنامه‌نویسی سایت‌ها و صفحات داینامیک از کدهای PHP و جاوا اسکریپت استفاده می شود و در آدرس آنها، پسوندهای php، asp یا jsp وجود دارد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    وب سرور (Web Server) :
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo6} alt="وب سرور - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        وب سرور
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    وب سرورها نرم افزارهای کامپیوتری هستند که اطلاعات موجود در هر سایت را که با زبان‌هایی مانند html, css. Javascript  برنامه‌نویسی شده‌اند، روی مرورگر به کاربران نمایش می دهند. وظیفه‌ی وب سرور، ارسال و ذخیره سازی اطلاعات یک وب سایت به کلاینتی که آن اطلاعات را درخواست کرده، می باشد. اطلاعات درخواستی می تواند شامل متن، رسانه و یا اطلاعات نرم افزاری باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    طراحی واکنشگرا (Responsive) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    طراحی واکنش‌گرا یا ریسپانسیو به حالتی می گویند که وب‌سایت شما در تمامی ابزارها مثل گوشی، لپ‌تاپ، تبلت و غیره به درستی نمایش داده شود و حالت به هم ریختگی در آن نباشد همچنین باید دکمه‌ها، منوها و متن‌ها بطور کامل نمایش داده شوند.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    سئو (SEO) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    <NavLink to="/Academy/what-is-seo" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >SEO </NavLink> یا Search Engine Optimization به معنی بهینه سازی یک سایت برای موتورهای جستجو می باشد. با سئو کردن سایت شما در صفحات گوگل جزو ده نمایش اول قرار می گیرد و آمار بازدید از سایت شما بالا می رود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    کلمه کلیدی (Keyword) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    به کلماتی که توسط کاربران اینترنت بیشتر از سایر کلمات جستجو می‌شود کلمه کلیدی می گویند.
                  </Typography><Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start",width:"100%"}}>
                    اسپم (Spam) :
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo7} alt="اسپم - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        مفهوم اسپم
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    به هر عملی که خلاف مقررات موتورهای جستجو جهت بالا بردن رتبه سایت انجام شود اسپم می گویند.
                  </Typography><Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    منو (Menu) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    منو انواع مختلفی دارد و در برگیرنده لینک‌های داخلی می باشد. menu بعنوان یک راهنمای خوب برای حرکت کاربر بین صفحات مختلف یک سایت می باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    ساید بار (sidebar) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    به نوارهای کناری که در سمت چپ و راست سایت‌ها قرار دارند ساید بار می گویند. این نوارها شامل جدیدترین مطالب، تبلیغات، برچسب‌ها و بسیاری از موارد مهم دیگر می‌ باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    تب (Tab) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    صفحات متعددی را که به کمک لینک‌ها در مرورگر باز می شوند تب می نامند. در مرورگرها این مورد در بالای قسمت آدرس سایت نشان داده می شود.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    فاو آیکون (Favicon) :
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo8} alt="فاو آیکون - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        فاو آیکون
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    وقتی یک تب باز می شود کنار هر تب یک تصویر کوچک که معمولا لوگوی سایت می‌باشد قرار دارد که به آن فاوآیکون می گویند. در موتورهای جستجو این مورد در کنار آدرس هر سایت می باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    سایت فلش (Flash Site) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    به وب سایت‌هایی می گویند که در بخشی از سایت یا تمام سایت از تصویر، صوت، انیمیشن استفاده شده باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start",width:"100%"}}>
                    حافظه کش (Cache):
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    زمانی که شما از صفحات یک سایت بازدید می کنید، دفعات بعد اگر به همان سایت مراجعه کنید صفحات سریع‌تر از قبل برای شما باز می‌شوند که به اصطلاح می‌گویند صفحه کش شده است.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    تولید محتوا (Content Creation) :
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1100px", margin: { xs: "10px auto", sm: "20px auto" } }}>
                    <Grid sx={{
                      display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                      borderRadius: { xs: i18n.language === "fa" ? "100px 0px 8px 26px" : "0px 100px 8px 26px", md: i18n.language === "fa" ? "100px 0px 8px 48px" : "0px 100px 8px 48px" },
                      background: "linear-gradient(90.86deg, rgba(234, 236, 255, 0.85) 1.68%, rgba(255, 245, 253, 0.85) 102.25%)"
                      , boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.18)", width: { xs: "90%", sm: "100%", md: "90%" }, height: { xs: "170px", sm: "200px", md: "280px", lg: "400px", xl: "550px" }
                    }}>
                      <Typography component={"img"} src={photo10} alt="تولید محتوا - سورین" sx={{
                        backgroundSize: "100% 100%", borderRadius: { xs: i18n.language === "fa" ? "50px 8px 0px 0px" : "8px 50px 0px 0px", md: i18n.language === "fa" ? "100px 8px 0px 0px" : "8px 100px 0px 0px" }
                        , width: "100%", minHeight: { xs: "80%", lg: "88%" }
                      }}></Typography>
                      <Typography sx={{ fontSize: { xs: "8px", sm: "10px", md: "14px" }, color: "#070707", margin: { xs: "10px 0", sm: "15px 0", lg: "20px 0" } }}>
                        تولید محتوا
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    محتوا همان چیزی است که هر روز در اینترنت و شبکه های مجازی با آن روبرو می شویم. مقاله هایی که در بلاگ مطالعه می کنیم یا کلیپ‌ها و عکس هایی که مشاهده می شود، توضیحات محصول که در سایتهای فروشگاهی قرار دارد، پادکست هایی که گوش می‌دهیم، کتاب‌های دیجیتال که در موبایل یا تبلت مطالعه می کنیم؛ همه محتوا به حساب می آید.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    پانوشت (Footer) :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    پانوشت در پایین‌ترین قسمت سایت قرار دارد و معمولا شامل کپی رایت، تصویر لایسنس ها (مانند اینماد، نشان ملی ثبت و رسانه ای دیجیتال )، آیکون‌های شبکه اجتماعی و اطلاعات تماس می باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    رابط کاربری UI :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    مجموعه ای از المان های سمعی بصری سایت که مستقیما با کاربر در ارتباط می باشد. طراحی کاربری <NavLink to="/Academy/UIUX" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >UI </NavLink>
                    مخفف کلمه User Interface می باشد که طراحی و زیباسازی صفحات وب سایت به منظور رابطه هر چه بهتر کاربر با آن می باشد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    تجربه کاربری UX :
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    تجربه کاربری شامل حالت، احساس و رفتار کاربر با محصول اینترنتی می باشد. کلمه User Experience یا به اصطلاح <NavLink to="/Academy/2023-UI-UX-design-trend" target="_blank" sx={{ color: "#6A35FF", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textDecoration: "none", borderBottom: "2px solid #6A35FF" }} >UX </NavLink>
                    به مجموعه اقداماتی می گویند که باعث ماندگاری کاربر در صفحه می شود و بستر را در راهنمایی و معماری صحیح اطلاعات در اختیار کاربر قرار می دهد.
                  </Typography>
                  <Typography component={"h2"}
                    sx={{ color: "#000000", fontSize: { xs: "16px", sm: "22px", md: "32px" }, fontWeight: '700', margin: { xs: "5px 0", md: "20px 0" }, textAlign: "start", width:"100%"}}>
                    جمع بندی
                  </Typography>
                  <Typography component={"p"}
                    sx={{
                      color: "#070707", fontSize: { xs: "10px", sm: "14px", md: "16px" }, textAlign: "justify", margin: { xs: "5px 0", md: "10px 0" }, lineHeight: "35px",width:"100%"
                    }}>
                    ما در این مقاله از سورین سعی کردیم تا شما را با مهم‌ترین اصطلاحات طراحی سایت آشنا کنیم. اگر سؤالی در زمینه طراحی سایت و موضوعات پیرامون آن برای شما پیش آمد می‌توانید با مشاورین سورین در قسمت پشتیبانی سایت در ارتباط باشید.
                  </Typography>

                  {/* do not change */}

                  {author(articleData.author, t)}
                  {tags(articleData.tags, t)}
                  {linkArticle(t, url)}
                  {sourceLink(articleData.source, t)}
                  {view(dataview, t)}
                </Grid>
              </Grid>
              :
              <Grid item xs={12} sm={7.5} md={8} lg={8.5} xl={9} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "80%", sm: "60%", md: "70%" } }}>
                <Loader />
              </Grid>
          }
        </Grid>
      </Grid>
      <PortfolioButton />
      <Footer />
    </>
  );
}
export default Article07;

